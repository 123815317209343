import React from "react";
import { DocumentPreview } from "../../../Report/new/UploadFile/UploadFile-styled";

const Documents = ({ color, files }) => {
  return (
    <div style={{ display: "flex", overflow: "auto", gap: 10, paddingTop: 8 }}>
      {files.map((file) => (
        <a href={file.url} target="_blank">
          <DocumentPreview>
            <svg
              stroke={color}
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="20px"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M416 221.25V416a48 48 0 0 1-48 48H144a48 48 0 0 1-48-48V96a48 48 0 0 1 48-48h98.75a32 32 0 0 1 22.62 9.37l141.26 141.26a32 32 0 0 1 9.37 22.62z"
              ></path>
              <path
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M256 56v120a32 32 0 0 0 32 32h120m-232 80h160m-160 80h160"
              ></path>
            </svg>
            <span style={{ fontSize: 10 }}>{file.name}</span>
          </DocumentPreview>
        </a>
      ))}
    </div>
  );
};

export default Documents;
