import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { isWidgetDisplayed } from "../../../esri/widgets/expandUtils";
import useOnboarding from "../../../hooks/useOnboarding";
import { view } from "../../../utils/API";
import {
  EMBED_VARIANTS,
  getOperationPreferences,
  getOpsColor,
} from "../../../utils/helper";
import CycleManager from "../../Dashboard/CycleManager/CycleManager/CycleManager";
import EditorContextProvider from "../../Dashboard/Editor/EditorContextProvider";
import {
  LogIEIconSmallColored,
  LogisticsClusterIcon,
  LogisticsClusterSmallIcon,
  WFPIcon,
} from "../../Icons/Header-Icons";
import bngrc from "../../Icons/img/bngrc.jpg";
import cdema_logo from "../../Icons/img/cdema_logo.png";
import Modules from "../../Modules";
import ModuleIcon from "../../Modules/ModuleIcon";
import PrintButton from "../../PrintMask/PrintButton/PrintButton";
import ReportButton from "../../Report/new/ReportButton.js/ReportButton";
import { focusSearchInput } from "../../SearchWidget/helpers";
import {
  HeaderIcon,
  ModuleIconWrapper,
  StyledDesktopHeader,
  StyledTitleWrapper,
  StyledWidgetsWrapper,
} from "../Header-styled";
import { LanguageDropdown } from "../components";
import HelpDropdown from "../components/HelpDropdown/HelpDropdown";
import LayerLoaderWrapper from "../components/LayerLoaderWrapper/LayerLoaderWrapper";
import SearchPortal from "./SearchPortal";

const Filters = lazy(() => import("../../Widgets/Filters"));

const getHeaderIcon = (config) => {
  switch (config.header.icon) {
    case "wfp":
      return <WFPIcon />;
    case "cdema":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <WFPIcon />
          <img src={cdema_logo} alt={"cdema_logo"} width={72} height={72} />
        </div>
      );
    case "bngrc":
      return <img src={bngrc} alt={"Bngrc"} height={50} width={50} />;
    default:
      return (
        <a
          href="https://logcluster.org/en"
          target="_blank"
          style={{ display: "flex" }}
        >
          <LogisticsClusterIcon key="lci" />
          <LogisticsClusterSmallIcon key="lcsi" />
        </a>
      );
  }
};

const DesktopHeader = ({ config, t, setActiveLanguage, openSnackbar }) => {
  const { printWidget, activeModule, cycleManagerReducer } = useSelector(
    (state) => state
  );

  const [showMenu, setMenuVisible] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const [show, setShow] = useState(false);
  const { onBoardingTour } = useOnboarding();
  const headerTitleRef = useRef();

  useEffect(() => {
    const languageConfig = JSON.parse(localStorage.getItem("languageConfig"));
    if (languageConfig) {
      const { showModules } = languageConfig;
      setShow(showModules);
      localStorage.removeItem("languageConfig");
    }
  }, []);

  useEffect(() => {
    if (!config) return;
    const { allPreferences: preferences, operationPreferences } =
      getOperationPreferences(config.id);

    if (
      !operationPreferences.activeModule &&
      Array.isArray(config.activeModules) &&
      config.activeModules.length > 1 &&
      config.embed !== EMBED_VARIANTS.WEBSITE
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const urlModule = searchParams.get("module");
      const dataCollection = searchParams.get("datacollection");
      if (urlModule || dataCollection) return;

      setShow(true);
    }
  }, [config]);

  const title = t("ops." + config.id + ".title", config.title) || "";

  const handleShowModules = useCallback(() => {
    setShow(true);
  }, []);

  const hasSearch = useMemo(() => {
    return isWidgetDisplayed(config, "Search");
  }, [config]);

  const hasPrint = useMemo(() => {
    return isWidgetDisplayed(config, "Print");
  }, [config]);

  const hasReport = useMemo(() => {
    return isWidgetDisplayed(config, "Report");
  }, [config]);

  const activeModuleIcons = useMemo(() => {
    if (config?.modules && activeModule) {
      return config.modules[activeModule]?.icons || [];
    }
    return [];
  }, [config, activeModule]);

  const handleStartTour = () => {
    if (!onBoardingTour) return;
    onBoardingTour.start();
  };

  const handleSearchExpand = useCallback(() => {
    setExpandSearch(true);
    focusSearchInput();
  }, []);

  const onFiltersOpen = useCallback((open) => {
    setShowFilters(open);
  }, []);

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [config, activeModule]
  );

  return (
    <>
      <div
        style={{
          position: "fixed",
          pointerEvents: "none",
          left: "8px",
          top: "8px",
          height: "calc(100% - 44px)",
          width: "70%",
          // overflow: "hidden",
          minWidth: headerTitleRef.current
            ? headerTitleRef.current.offsetWidth + 60 + "px"
            : 0,
        }}
        id="cycle-manager"
      >
        {cycleManagerReducer.open && (
          <EditorContextProvider>
            <div
              style={{
                background: "white",
                height: "100%",
                width: "100%",
                animation: `${
                  !open ? "cycleManagerLeave" : "cycleManagerEnter"
                } 0.5s linear`,
                pointerEvents: "auto",
                borderRadius: 20,
              }}
            >
              <CycleManager />
            </div>
          </EditorContextProvider>
        )}
      </div>
      {createPortal(
        <Modules
          show={show}
          setShow={setShow}
          config={config}
          isHeaderShown={false}
          onBoardingTour={onBoardingTour}
        />,
        document.querySelector("#portal")
      )}
      <StyledDesktopHeader
        id="desktop-header"
        style={{
          display: printWidget.open && !printWidget.isLoading ? "none" : "",
        }}
      >
        <StyledTitleWrapper ref={headerTitleRef}>
          <a href="/">
            <LogIEIconSmallColored />
          </a>
          <span>{title}</span>

          {!!activeModule && !cycleManagerReducer.open && (
            <LayerLoaderWrapper opsColor={opsColor}>
              <ModuleIcon
                className="onboarding-modules loader"
                onClick={handleShowModules}
                style={{ padding: 8 }}
                opsColor="transparent"
                module={activeModule}
                showTitle
              />
            </LayerLoaderWrapper>
          )}
        </StyledTitleWrapper>
        <StyledWidgetsWrapper className="top-widgets-wrapper">
          {!expandSearch && (
            <>
              {!showFilters && (
                <>
                  {hasReport && <ReportButton config={config} t={t} />}
                  {hasPrint && <PrintButton openSnackbar={openSnackbar} />}
                </>
              )}
              <Suspense>
                <Filters
                  onFiltersOpen={onFiltersOpen}
                  isMobile={false}
                  className="onboarding-filter"
                />
              </Suspense>
            </>
          )}
          {hasSearch && view && (
            <SearchPortal
              expandSearch={expandSearch}
              view={view}
              setExpandSearch={setExpandSearch}
              handleSearchExpand={handleSearchExpand}
            />
          )}
          <HelpDropdown
            onTourStart={handleStartTour}
            config={config}
            setMenuVisible={setMenuVisible}
          />
          <LanguageDropdown
            id="language-widget--header"
            className="onboarding-language"
            setActiveLanguage={setActiveLanguage}
            config={config}
          />
          {activeModuleIcons.map((icon) => (
            <ModuleIconWrapper key={icon.icon} size={icon.size}>
              <a href={icon.url} target="_blank" style={{ display: "flex" }}>
                <img
                  src={
                    process.env.PUBLIC_URL + `/assets/moduleIcons/${icon.icon}`
                  }
                  alt="module-icon"
                />
              </a>
            </ModuleIconWrapper>
          ))}
          <HeaderIcon>{getHeaderIcon(config)}</HeaderIcon>
        </StyledWidgetsWrapper>
      </StyledDesktopHeader>
    </>
  );
};

export default DesktopHeader;
