import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditableFeature,
  setEditableLayer,
  setEditorDrawActive,
  setEditorUpdateType,
} from "../../../redux/action/Dashboard-action";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  graphicsLayer,
  view,
} from "../../../utils/API";
import { ConfigContext } from "../../../utils/ConfigContext";
import { getFieldsByPattern } from "../../../utils/helper";
import { getLayerSymbology } from "../../../utils/symbologies";
import {
  ALL_STEPS,
  EditType,
  LAYER_EFFECT,
  pointSymbol,
} from "./EditorSwiper/EditorSwiper";
import { addLayerEffect, getFeatureNameField } from "./helpers";

export const baseLineColor = "#34495e";

export const INITIAL_CLICKED_POINTS = [
  {
    id: `${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
    graphic: undefined,
    coordinate: [],
    label: "",
    searchable: true,
  },
  {
    id: `${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
    graphic: undefined,
    coordinate: [],
    label: "",
    searchable: true,
  },
];

export const UpdateType = Object.freeze({
  situational: "situational",
  baseline: "baseline",
});

export const UPDATE_STATUS_TYPE = Object.freeze({
  UPDATE: "update",
  CURRENT: "current",
});

export const EditorContext = createContext(null);

const EditorContextProvider = ({ children, editType }) => {
  const { config } = useContext(ConfigContext);

  const [showWarning, setShowWarning] = useState({
    show: false,
    action: null,
  });

  const [visibleEditableLayers, setVisibleEditableLayers] = useState();
  const [step, setStep] = useState("selectLayer");
  const [editableLayers, setEditableLayers] = useState([]);
  const [airdromeType, setAirdromeType] = useState(null);
  const [addedPoints, setAddedPoints] = useState([]);
  const [highlightHandle, setHighlightHandle] = useState(null);
  const [highlightSymbol, setHighlightSymbol] = useState(null);
  const { editableFeature, editableLayer, editorDrawActive, editUpdateType } =
    useSelector((state) => state.dashboard);
  const [attachments, setAttachments] = useState({
    previews: {},
    files: [],
  });
  const [prefilledFields, setPrefilledFields] = useState([]);
  const [sketchGraphicsLayer, setSketchGraphicsLayer] = useState(null);
  const [sketchViewModel, setSketchViewModel] = useState(null);
  const [popupFeature, setPopupFeature] = useState(false);
  const [editableLayerLoaded, setEditableLayerLoaded] = useState(false);
  // const [showRoadsEditor, setShowRoadsEditor] = useState(false);
  // const [updateType, setUpdateType] = useState(editUpdateType);

  //for adding point in roadsSituational layer
  const [clickedPoints, setClickedPoints] = useState(INITIAL_CLICKED_POINTS);
  const [trimmedSituationalFeatures, setTrimmedSituationalFeatures] = useState({
    updateFeatures: [],
    deleteFeatures: [],
  });

  //for batch update
  const [situationalUpdateType, setSituationalUpdateType] = useState(
    UPDATE_STATUS_TYPE.UPDATE
  );
  const [batchUpdateFeatures, setBatchUpdateFeatures] = useState([]);
  const [editableFeatureList, setEditableFeatureList] = useState([]);
  const [editorListRefreshCount, setEditorListRefreshCount] = useState(0);
  const [editingInitialUpdateType, setEditingInitialUpdateType] =
    useState(null);

  const sketchGraphicsLayerRef = useRef(null);

  const dispatch = useDispatch();
  const setHighlightFeature = useCallback((feature) => {
    dispatch(setEditableFeature(feature));
  }, []);

  const handleSetEditableLayer = useCallback((layer) => {
    dispatch(setEditableLayer(layer));
  }, []);

  useEffect(() => {
    sketchGraphicsLayerRef.current = sketchGraphicsLayer;
  }, [sketchGraphicsLayer]);

  useEffect(() => {
    return () => {
      highlightHandle?.remove();
    };
  }, [highlightHandle]);

  const resetClickedPoints = useCallback(() => {
    setClickedPoints(INITIAL_CLICKED_POINTS);
  }, []);

  const setShowRoadsEditor = useCallback((value) => {
    dispatch(setEditorDrawActive(value));
  }, []);

  const resetHighlightFeature = useCallback(() => {
    if (highlightSymbol) {
      const graphicToRemove = graphicsLayer.graphics.find(
        (g) => g.symbol.id === highlightSymbol.id
      );
      graphicsLayer.remove(graphicToRemove);
    }
    setHighlightSymbol(null);
    setHighlightFeature(null);
    highlightHandle?.remove();
    setHighlightHandle(null);
  }, [highlightHandle, highlightSymbol]);

  const resetBatchUpdateFeatures = useCallback(() => {
    batchUpdateFeatures.forEach((item) => {
      graphicsLayer.remove(item.graphic);
    });

    setBatchUpdateFeatures([]);
  }, [batchUpdateFeatures]);

  const resetEditor = () => {
    setStep(EditType.edit + "-" + ALL_STEPS.selectLayer);
    resetBatchUpdateFeatures();
    setAirdromeType(null);
    setAttachments({
      previews: {},
      files: [],
    });
    setPrefilledFields([]);
    // removeAddedGraphics()

    graphicsLayer.removeAll();
    view.graphics.removeAll();
    if (sketchGraphicsLayerRef.current) {
      sketchGraphicsLayerRef.current?.removeAll();
      view.map.remove(sketchGraphicsLayerRef.current);
    }

    setSketchGraphicsLayer(null);
    setSketchViewModel(null);
    setPopupFeature(false);
    resetClickedPoints();
    setShowRoadsEditor(false);
    handleSetEditableLayer(null);
    // handleSetUpdateType(null);
    setHighlightFeature(null);
    setEditableFeatureList([]);
    setEditableLayerLoaded(false);
  };

  const requiredFields = useMemo(() => {
    if (!editableLayer) return [];

    // const overviewTable1Fields =
    //   editableLayer.layerConfig.customPopupOps.overviewTable1Fields || [];
    // const overviewTable2Fields =
    //   editableLayer.layerConfig.customPopupOps.overviewTable2Fields || [];

    // const symbology = getLayerSymbology(editableLayer, config) || {};
    const layerRequiredFields =
      editableLayer?.layerConfig?.requiredFields || [];

    const rFields = [...layerRequiredFields];

    // const { colorMap } = symbology;
    // if (colorMap) {
    //   if (Array.isArray(colorMap.fields)) {
    //     rFields.push(...colorMap.fields);
    //   } else if (colorMap.field) {
    //     rFields.push(colorMap.field);
    //   }
    // }

    return [...rFields];
  }, [editableLayer, config]);

  const getFields = useCallback((fields, requiredFields, filteredFields) => {
    return fields.filter(
      (f) =>
        f.editable &&
        requiredFields.includes(f.name) &&
        !filteredFields.includes(f.name)
    );
  }, []);

  const baseLineRequiredFields = useMemo(() => {
    if (!editableLayer && !editableLayer?.layerConfig) return [];

    const nameField = getFeatureNameField(editableLayer);
    const filteredFields = [
      nameField,
      ...prefilledFields.map((item) => item.fieldName),
    ];
    const symbology = getLayerSymbology(editableLayer, config) || {};
    if (symbology.iconMap?.fields) {
      const iconFields = editableLayer.fields
        .filter((field) => symbology.iconMap.fields.includes(field.name))
        .map((f) => f.name);
      filteredFields.push(...iconFields);
    }

    if (!editableLayer.layerConfig?.baselineFields) return [];

    const baselineFields = getFields(
      getFieldsByPattern(
        editableLayer,
        editableLayer.layerConfig.baselineFields
      ),
      requiredFields,
      filteredFields
    );

    return baselineFields;
  }, [editableLayer, requiredFields, config]);

  const activeColor = useMemo(
    () =>
      editUpdateType === UpdateType.baseline ? baseLineColor : config.opsColor,
    [editUpdateType, config, editType]
  );

  const handleSetUpdateType = useCallback((type) => {
    dispatch(setEditorUpdateType(type));
  }, []);

  const addGraphic = useCallback(
    (editingGraphic, editingSymbol, callback, skipAdding = false) => {
      const nGraphic = editingGraphic?.clone();
      nGraphic.symbol = editingGraphic ? editingSymbol : pointSymbol;
      setHighlightFeature(nGraphic);

      if (!skipAdding) {
        graphicsLayer.add(nGraphic);
      }

      if (callback) {
        callback();
      }
    },
    [setHighlightFeature]
  );

  const handleSelectFeature = async (clickedLayer, feature, callback) => {
    try {
      highlightHandle?.remove();

      const query = clickedLayer.createQuery();
      const objectIdField = clickedLayer.objectIdField;
      query.objectIds = [feature.attributes[objectIdField]];
      query.outFields = ["*"];
      query.returnGeometry = true;
      let editingGraphic = feature;

      let editingSymbol;
      clickedLayer.effect = LAYER_EFFECT;
      addLayerEffect([]);

      if (
        clickedLayer.layerConfig.isConops ||
        clickedLayer.layerConfig.isEpam ||
        clickedLayer.layerConfig.isEditorCurved
      ) {
        const result = await clickedLayer.queryFeatures(query);
        const graphic = result.features.find(
          (feat) => feat[objectIdField] === editingGraphic[objectIdField]
        );
        if (
          clickedLayer.layerConfig.isConops ||
          clickedLayer.layerConfig.isEpam
        ) {
          editingSymbol = pointSymbol;
          setHighlightSymbol(pointSymbol);
          if (!!graphic) {
            graphic.symbol = pointSymbol;
          }
        } else {
          const symbol = await clickedLayer.renderer?.getSymbolAsync(
            editingGraphic
          );
          editingSymbol = symbol;

          setHighlightSymbol(symbol);
        }

        if (!!graphic) {
          editingGraphic = graphic;
        }
      } else {
        const layer = editingGraphic?.sourceLayer
          ? editingGraphic?.sourceLayer
          : editingGraphic?.layer;

        if (layer) {
          const symbol = await layer.renderer?.getSymbolAsync(editingGraphic);
          editingSymbol = symbol;

          setHighlightSymbol(symbol);
        }
      }

      addGraphic(
        editingGraphic,
        editingSymbol,
        callback,
        clickedLayer?.layerConfig?.titleLabel === "roads"
      );

      return editingGraphic;
    } catch (err) {
      console.log(err);
    }
  };

  const hasEditedAttachments = useMemo(() => {
    if (!Array.isArray(attachments?.files)) return;

    const hasEditedExisting = attachments.files.some(
      (item) => item?.attachmentId && item?.isDeleted
    );

    const hasAddedNew = attachments.files.some(
      (item) => item instanceof File && !item?.isDeleted
    );

    return hasEditedExisting || hasAddedNew;
  }, [attachments]);

  const queryFields = useMemo(() => {
    if (!editableLayer?.layerConfig || !editableLayer || !editType) return [];

    let fields = [editableLayer.objectIdField];
    if (Array.isArray(editableLayer.layerConfig.baselineFields)) {
      fields.push(...editableLayer.layerConfig.baselineFields);
    }

    if (Array.isArray(editableLayer.layerConfig.situationalFields)) {
      fields.push(...editableLayer.layerConfig.situationalFields);
    }

    if (Array.isArray(editableLayer.layerConfig.batchFields)) {
      fields.push(...editableLayer.layerConfig.batchFields);
    }

    const nameField = getFeatureNameField(editableLayer);
    if (nameField && !fields.includes(nameField)) {
      fields.push(nameField);
    }

    if (!fields.includes(currAsOfDateFieldName)) {
      fields.push(currAsOfDateFieldName);
    }

    if (!fields.includes(asOfDateFieldName)) {
      fields.push(asOfDateFieldName);
    }

    const fieldsToQuery = editableLayer.fields.filter((field) =>
      fields.includes(field.name)
    );

    return fieldsToQuery.map((f) => f.name);
  }, [editUpdateType, editableLayer]);

  return (
    <EditorContext.Provider
      value={{
        editableLayer,
        visibleEditableLayers,
        setVisibleEditableLayers,
        step,
        editableLayers,
        setStep,
        setEditableLayers,
        airdromeType,
        setAirdromeType,
        addedPoints,
        setAddedPoints,
        highlightHandle,
        setHighlightHandle,
        highlightSymbol,
        setHighlightSymbol,
        highlightFeature: editableFeature,
        setHighlightFeature,
        attachments,
        setAttachments,
        prefilledFields,
        setPrefilledFields,
        sketchGraphicsLayer,
        setSketchGraphicsLayer,
        sketchViewModel,
        setSketchViewModel,
        setPopupFeature,
        popupFeature,
        showWarning,
        setShowWarning,
        clickedPoints,
        setClickedPoints,
        resetClickedPoints,
        resetHighlightFeature,
        showRoadsEditor: editorDrawActive,
        setShowRoadsEditor,
        setEditableLayer: handleSetEditableLayer,
        updateType: editUpdateType,
        setUpdateType: handleSetUpdateType,
        batchUpdateFeatures,
        setBatchUpdateFeatures,
        resetBatchUpdateFeatures,
        activeColor,
        resetEditor,
        requiredFields,
        baseLineRequiredFields,
        situationalUpdateType,
        setSituationalUpdateType,
        handleSelectFeature,
        editableFeatureList,
        setEditableFeatureList,
        editorListRefreshCount,
        setEditorListRefreshCount,
        editingInitialUpdateType,
        setEditingInitialUpdateType,
        editableLayerLoaded,
        setEditableLayerLoaded,
        editType,
        hasEditedAttachments,
        queryFields,
        setTrimmedSituationalFeatures,
        trimmedSituationalFeatures,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
