import { AgGridReact } from "ag-grid-react";
import React, { useContext, useMemo, useRef } from "react";
import {
  hasUrlPattern,
  isYesNoDomain,
  renderCell,
  ROLE_EDITOR,
} from "../../utils/helper";
import { getLayerSymbology } from "../../utils/symbologies";
import {
  CellDateRenderer,
  CellDomainRenderer,
  CellFeatureSelectRenderer,
  CellRenderer,
  CellRendererSwitch,
} from "../FeatureTable/TableGrid/CustomCells";
import { DomainFilter } from "../FeatureTable/TableGrid/CustomFilters";
import { TableGridWrapper } from "../FeatureTable/TableGrid/TableGrid-styled";
// import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-balham.css";
import { useTranslation } from "react-i18next";
import { ConopsIconAttributeMap } from "../../esri/graphicLayerUtils/Conops";
import { EpamIconAttributeMap } from "../../esri/graphicLayerUtils/Epam";
import { ConfigContext } from "../../utils/ConfigContext";
import IconHeader from "../Dashboard/CycleManager/Checklist/ChecklistTable/Headers/IconHeader/IconHeader";
import { getFeatureNameField } from "../Dashboard/Editor/helpers";

const rowSelection = "multiple";

const defaultColDef = {
  flex: 1,
};

const autoSizeStrategy = {
  type: "fitGridWidth", //fitCellContents
  skipHeader: true,
};

const autoSizeStrategyFitContent = {
  type: "fitCellContents", //fitCellContents
  skipHeader: true,
};

export const formatToDate = (time) => {
  const date = new Date(time);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // month is zero-based
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formatted = yyyy + "-" + mm + "-" + dd;
  return formatted;
};

const getRowStyle = (params) => {
  let background;

  Object.keys(params.data).forEach((k) => {
    if (params.data.rowColorMap[k]) {
      background =
        params.data.rowColorMap[k][
          params.data[k] === "NULL" ? "NULL" : params.data[k]
        ];
    }
  });
  return {};
};

const getCellRenderer = (field, params, layer) => {
  if (isYesNoDomain(field, layer)) {
    return <CellRendererSwitch {...params} layer={layer} />;
  }

  if (field.domain) {
    return <CellDomainRenderer {...params} />;
  }

  if (field.name === "featureSelect") {
    return <CellFeatureSelectRenderer layer={layer} {...params} />;
  }
  switch (field.type) {
    case "string": {
      return <CellRenderer {...params} />;
    }
    case "date": {
      return <CellDateRenderer {...params} />;
    }
    default: {
      return <CellRenderer {...params} />;
    }
  }
};

export const generateColumn = ({
  field,
  config,
  layer,
  t,
  isEditable = true,
  maxWidth,
  minWidth,
  ...props
}) => {
  const isToggleField = isYesNoDomain(field, layer);

  const defaultColumn = {
    field: field.name,
    headerName: field.alias
      ? t("layer.fieldAlias." + field.name + ".title", field.alias)
      : "",
    minWidth: minWidth,
    maxWidth: maxWidth,
    filter: !!field.alias,
    editable: config.role === ROLE_EDITOR && !isToggleField && isEditable,
    wrapText: true,
    cellDataType: field.type === "date" ? "date" : undefined,
    cellRenderer: (params) => {
      return getCellRenderer(field, params, layer);
    },
    ...props,
  };

  //showing select with values
  if (field.domain && !isToggleField) {
    defaultColumn.cellEditor = "agSelectCellEditor";
    const values = [];
    let refData = {};
    field.domain.codedValues.forEach((cv) => {
      values.push(cv.code);
      refData = {
        ...refData,
        [cv.code]: renderCell(field, cv.code, t, config),
      };
    });

    if (field.nullable) {
      refData["NULL"] = " "; //t('screen.domain.unspecified', "Unspecified");
      values.push("NULL");
    }
    //Implemented custom dropdown filter for domains
    defaultColumn.filter = (params) => (
      <DomainFilter layer={layer} field={field} {...params} />
    );

    defaultColumn.refData = refData;

    if (config.role === ROLE_EDITOR) {
      defaultColumn.cellEditorParams = {
        values,
      };
    }
  }

  if (isToggleField) {
    const refData = {
      1: renderCell(field, 1, t, config),
      2: renderCell(field, 2, t, config),
    };

    //Implemented custom dropdown filter for domains
    defaultColumn.filter = (params) => (
      <DomainFilter layer={layer} field={field} {...params} />
    );
    defaultColumn.refData = refData;
  }

  let attributesMap = {};

  if (layer?.layerConfig?.isConops) {
    attributesMap = ConopsIconAttributeMap;
  } else if (layer?.layerConfig?.isEpam) {
    attributesMap = EpamIconAttributeMap;
  }

  const icon = attributesMap[field.name];
  if (icon) {
    defaultColumn.headerComponent = (props) => {
      return (
        <IconHeader iconName={icon.iconName} color={icon.color} {...props} />
      );
    };
  }

  if (field.type === "string") {
    if (defaultColumn.cellEditorParams) {
      defaultColumn.cellEditorParams.maxLength = field.length;
    } else {
      defaultColumn.cellEditorParams = {
        maxLength: field.length,
      };
    }
  }

  return defaultColumn;
};

export const getCellValue = ({ field, value, config, t }) => {
  if (hasUrlPattern(value)) {
    return value;
  } else {
    //if field is domain we have refData to show label
    return field.domain ? value : renderCell(field, value, t, config) ?? "";
  }
};

export const generateRows = ({ features, layer, config, fields, t }) => {
  const row = [];
  const { colorMap } = getLayerSymbology(layer, config) || {};
  const rowColorMap = {};

  if (colorMap?.field) {
    rowColorMap[colorMap.field] = colorMap.default;
  }

  if (Array.isArray(colorMap?.fields)) {
    colorMap.fields.forEach((field) => {
      rowColorMap[field] = colorMap.default;
    });
  }

  features.forEach((feature) => {
    const rowData = {
      featureSelect: false,
      [layer.objectIdField]: feature.attributes[layer.objectIdField],
      objectIdField: layer.objectIdField,
      rowColorMap,
      feature: feature.clone(),
    };

    fields.forEach((field) => {
      let cellValue = getCellValue({
        field,
        value: feature.attributes[field.name],
        config,
        t,
      });

      if (field.type === "date") {
        cellValue = !feature.attributes[field.name]
          ? ""
          : feature.attributes[field.name];
      }

      if (hasUrlPattern(feature.attributes[field.name])) {
        cellValue = feature.attributes[field.name];
      }

      rowData[field.name] = cellValue === null ? "NULL" : cellValue;
    });

    row.push(rowData);
  });

  return row;
};

const LayerTable = ({
  rows,
  columns,
  layer,
  setGridApi,
  onRowDataUpdated,
  isDataTable,
  ...props
}) => {
  const { config } = useContext(ConfigContext);
  const userResizedColumns = useRef(new Set());
  const lastRow = useRef(-1);
  const { t } = useTranslation("common");

  const colorFields = useMemo(() => {
    const { colorMap = {} } = getLayerSymbology(layer, config) || {};
    const fields = [];
    if (colorMap.field) {
      fields.push(colorMap.field);
    } else if (Array.isArray(colorMap.fields)) {
      fields.push(...colorMap.fields);
    }
    return fields;
  }, [layer, config]);

  /**
   * A reference to the grid API.
   * This reference is used to interact with the grid component.
   * @type {React.MutableRefObject}
   */
  const gridApi = useRef();
  const timer = useRef(0);
  const autoSizeDescriptionColumn = () => {
    if (gridApi.current && rows.length > 0) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        const columnsToAutoSize = colorFields.filter(
          (colId) => !userResizedColumns.current.has(colId)
        );

        if (columnsToAutoSize.length > 0) {
          gridApi.current.autoSizeColumns(columnsToAutoSize, true);
        }
        // gridApi.current.autoSizeColumns([], true);
      }, 100);
    }
  };

  const firstRender = useRef(false);
  const onFirstDataRendered = (params) => {
    firstRender.current = true;
    setGridApi(params.api);
    autoSizeDescriptionColumn();
  };

  const handleRowDataUpdated = (params) => {
    setGridApi(params.api);

    if (onRowDataUpdated) {
      onRowDataUpdated(params);
    }

    // //to prevent autosize when cell value changed
    // if (!firstRender.current) return;

    // firstRender.current = false;
    autoSizeDescriptionColumn();
  };

  const onViewportChanged = (params) => {
    if (params.lastRow > lastRow.current) {
      lastRow.current = params.lastRow;
      autoSizeDescriptionColumn();
    }
  };

  const handleColumnResized = (params) => {
    if (params.finished && params.source === "uiColumnResized") {
      userResizedColumns.current.add(params.column.colId);
    }
  };

  // pinning column and making it first
  const columnDefs = useMemo(() => {
    try {
      const nameField = getFeatureNameField(layer);
      const nameFieldIndex = columns.findIndex(
        (column) => column.field === nameField
      );

      if (nameFieldIndex === -1) {
        return columns; // If nameField is not found, return the original columns
      }

      const newColumns = columns.map((column, index) => {
        if (index <= nameFieldIndex) {
          return {
            ...column,
            pinned: "left",
          };
        }
        return column;
      });

      return newColumns;
    } catch (err) {
      return columns;
    }
  }, [columns]);

  return (
    <TableGridWrapper
      className="ag-theme-balham" // applying the grid theme
      // style={{ fontSize: 10, padding: 0 }}
    >
      <AgGridReact
        key={`ag-grid-key${layer.id}`}
        rowData={rows}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        rowSelection={rowSelection}
        getRowStyle={getRowStyle}
        alwaysShowHorizontalScroll
        scrollBarWidth={8}
        onGridReady={(event) => {
          const { api } = event;

          setGridApi(api);
          gridApi.current = api;
        }}
        onFilterChanged={(event) => {
          const { api } = event;
          setGridApi(api);
          gridApi.current = api;
        }}
        onFirstDataRendered={onFirstDataRendered}
        onRowDataUpdated={handleRowDataUpdated}
        autoSizeStrategy={autoSizeStrategyFitContent}
        rowClassRules={{
          "ag-row-feature-selected": (params) => {
            return !!params.data?.featureSelect;
          },
        }}
        headerHeight={48}
        onColumnResized={handleColumnResized}
        onViewportChanged={onViewportChanged}
        {...props}
      />
    </TableGridWrapper>
  );
};

export default LayerTable;
