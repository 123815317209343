import styled from "styled-components";

export const StyledOverlay = styled.div`
  position: ${({ position }) => position ?? "fixed"};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ bg }) => bg ?? "#eeeeeed4"};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 2000ms cubic-bezier(0.5, 0, 0.1, 1);
  z-index: 8000;
  flex-direction: column;
  padding: 22px;
  box-sizing: border-box;

  .title {
    color: #525252;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    margin: 0;
  }

  .subtitle {
    color: #525252;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 4px;
  }

  p,
  span {
    line-height: normal;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    text-align: center;
  }
`;
