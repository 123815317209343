import {
  CalciteTabNav,
  CalciteTabs,
  CalciteTabTitle,
} from "@esri/calcite-components-react";
import { graphBar16 } from "@esri/calcite-ui-icons";
import { loadModules } from "esri-loader";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isWidgetDisplayed } from "../../esri/widgets/expandUtils";
import useDragSidebar from "../../hooks/useDragSidebar";
import { setEditableFeature } from "../../redux/action/Dashboard-action";
import { view } from "../../utils/API";
import { ConfigContext } from "../../utils/ConfigContext";
import { getOpsColor, ROLE_EDITOR } from "../../utils/helper";
import { CustomLoader } from "../App/App-styled";
import { SectionItem } from "../FeatureTable/FeatureTable-styled";
import { FaChevronRight } from "../Icons";
import LatestUpdates, {
  LatestUpdatesIcon,
} from "../LatestUpdates/new/LatestUpdates";
import {
  Sidebar,
  SidebarBody,
  StyledResizer,
  StyledResizerButton,
  StyledResizerWrapper,
} from "../LatestUpdates/new/LatestUpdates-styled";
import SharedInformation from "../SharedInformation/SharedInformation";
import { StyledDashboardPortal, StyledTab } from "./Dashboard-styled";
import EditorManager from "./Editor/EditorManager";
import Insights from "./Insights/Insights";
import { StyledLoaderWrapper } from "./Insights/Insights-styled";

const sharedInformationWidth = 350;
const insightsWidth = 470;

const ShareInformationIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 47.97761 48"
      fill={color}
    >
      <g>
        <g>
          <path d="M26.56,28.69l-6.18,1.72a2.02035,2.02035,0,0,1-.6.08,2.245,2.245,0,0,1-1.79-.89,2.04577,2.04577,0,0,1-.32-.6H6a1,1,0,0,1,0-2H17.79l1.11-4H6a1,1,0,0,1,0-2H19.51a2.80674,2.80674,0,0,1,.59-.86L23.24,17H6a1,1,0,0,1,0-2H25.24L30,10.24V7a.97228.97228,0,0,0-.94-1H.94A.97228.97228,0,0,0,0,7V47a.97228.97228,0,0,0,.94,1H21V42a3.00879,3.00879,0,0,1,3-3h6V25.8l-2.12,2.12A2.99629,2.99629,0,0,1,26.56,28.69ZM13,35H6a1,1,0,0,1,0-2h7a1,1,0,0,1,0,2Z" />
          <path d="M24,41a1.003,1.003,0,0,0-1,1v6l7-7H24Z" />
          <path d="M47.13,1.16A3.511,3.511,0,0,0,44.52,0a3.45971,3.45971,0,0,0-2.47,1.03L30,13.07l-3.01,3.01-.91.91-4.57,4.57a.92293.92293,0,0,0-.25.44l-1.49,5.36-.23.82a.24359.24359,0,0,0,.24005.31.19482.19482,0,0,0,.06995-.01l.03-.01,6.15-1.71a.941.941,0,0,0,.43-.25L30,22.97,46.86,6.11A3.638,3.638,0,0,0,47.13,1.16Z" />
        </g>
      </g>
    </svg>
  );
};

const WidgetToTab = {
  SharedInformation: "sharedInformation",
  Insights: "insights",
  LatestUpdates: "latestUpdates",
  Editor: "editor",
};

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const { config } = useContext(ConfigContext);
  const [_dashboardCount, setDashboardCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("");
  const { t } = useTranslation("common");
  const sidebarRef = useRef();
  const dashboardTabs = useRef(new Set());
  const dashboardCount = useRef(0);
  const userSelectedTab = useRef("");
  const [pending, setTransition] = useTransition();

  const {
    layersLoading,
    activeModule,
    showLatestUpdates,
    featureTableReducer,
    dashboard,
  } = useSelector((state) => state);
  const { editUpdateType } = dashboard;
  const visibleLayers = useRef({
    shared: {},
    insights: {},
    latestUpdates: {},
  });

  const isStockPrepositionLayer = useMemo(() => {
    return (
      view.map.layers.filter((l) => {
        if (visibleLayers.current.insights[l.id]) {
          const layerConfigName =
            l.layerConfig?.extends || l.layerConfig?.alias;
          return layerConfigName.includes("stockpreposition");
        }
        return false;
      }).length > 0
    );
  }, [_dashboardCount]);

  const {
    expand,
    setExpand,
    initDrag,
    onArrowClick,
    isMobile,
    mobileOffset,
    expandToWidth,
  } = useDragSidebar({
    defaultWidth: dashboardTabs.current.has("insights")
      ? isStockPrepositionLayer
        ? window.innerWidth / 2
        : insightsWidth
      : sharedInformationWidth, //todo
    ref: sidebarRef,
    show: dashboardTabs.current.size > 0,
  });

  useEffect(() => {
    if (featureTableReducer.open) {
      setExpand(false);
    } else if (dashboardTabs.current.size > 0 && !!selectedTab) {
      setExpand(true);
    }
  }, [featureTableReducer.open]);

  // for editor
  const dispatch = useDispatch();
  useEffect(() => {
    let handler;

    if (editUpdateType) return;

    if (
      config.role === ROLE_EDITOR &&
      (config.newEditorEnabled || config.cycleUpdateFrequency)
    ) {
      loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
        /** Push report an update button on the popup */
        handler = reactiveUtils.on(
          () => view.popup,
          "trigger-action",
          (event) => {
            if (event.action.id === "edit-action") {
              setSelectedTab("editor");
              userSelectedTab.current = "editor";

              const feature = view.popup.selectedFeature;
              dispatch(setEditableFeature(feature));
              setTimeout(() => {
                setExpand(true);
              }, 100);
            }
          }
        );
      });
    }

    return () => {
      handler?.remove();
    };
  }, [editUpdateType]);

  useEffect(() => {
    if (!isMobile) return;
    const viewDiv = document.querySelector("#viewDiv");
    if (show) {
      viewDiv.style.height = `calc(100% - ${mobileOffset}px)`;
    } else {
      viewDiv.style.height = ``;
      if (sidebarRef.current) {
        sidebarRef.current.style.flexBasis = "";
      }
    }
  }, [show]);

  const handleShowSidebar = useCallback(() => {
    setShow(true);
  }, []);

  const handleCloseSidebar = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    userSelectedTab.current = "";
    setSelectedTab("");
  }, []);

  const processTab = ({ tab, visible }) => {
    if (visible) {
      dashboardTabs.current.add(tab);
      if (!isMobile) {
        setExpand(true);
      }
    } else {
      dashboardTabs.current.delete(tab);
    }
    dashboardCount.current += 1;
    setDashboardCount(dashboardCount.current);
  };

  const handleInsightsLayer = (layer, visible) => {
    const insights = config.insights || {};
    // const insightsLayer = config.role === ROLE_EDITOR ? insights.editorLayer : insights.publicLayer;
    const hasInsightsLayer =
      config.role === ROLE_EDITOR
        ? layer.layerConfig?.isShownInEditorInsights
        : layer.layerConfig?.isShownInPublicInsights;

    if (!hasInsightsLayer || !isWidgetDisplayed(config, "Insights")) return;

    const tab = "insights";
    visibleLayers.current.insights[layer.id] = visible;
    const arrayVisibleLayers = Object.values(
      visibleLayers.current.insights
    ).filter((visible) => visible);
    processTab({ tab, visible: arrayVisibleLayers.length > 0 });
  };

  const watchHandlers = useRef([]);

  useEffect(() => {
    userSelectedTab.current = "";
    setSelectedTab("");
    dashboardCount.current++;
    setDashboardCount(dashboardCount.current);
    dashboardCount.current = 0;
    //for editor
    if (
      config.role === ROLE_EDITOR &&
      (config.newEditorEnabled || config.cycleUpdateFrequency)
    ) {
      requestAnimationFrame(() => {
        dashboardTabs.current.add("editor");
        setExpand(true);
        setSelectedTab("editor");
        dashboardCount.current++;
        setDashboardCount(dashboardCount.current);
      });
    }
  }, [activeModule]);

  let expandTimer;
  useEffect(() => {
    clearTimeout(expandTimer);
    expandTimer = setTimeout(() => {
      if (dashboardTabs.current.size === 0) {
        setExpand(false);
      }
    }, 250);
  }, [_dashboardCount]);

  const processLayer = (layer, visible) => {
    if (!layer.layerConfig) return;

    handleInsightsLayer(layer, visible);
    if (
      layer.layerConfig?.isShownInReportManager &&
      isWidgetDisplayed(config, "ReportManager")
    ) {
      const tab = "sharedInformation";
      visibleLayers.current.shared[layer.id] = visible;
      const arrayVisibleLayers = Object.values(
        visibleLayers.current.shared
      ).filter((visible) => visible);
      //remove for editor
      if (
        !(
          config.role === ROLE_EDITOR &&
          (config.newEditorEnabled || config.cycleUpdateFrequency)
        )
      ) {
        processTab({ tab, visible: arrayVisibleLayers.length > 0 });
      }
    }

    if (
      layer.layerConfig?.isShownInLatestUpdates &&
      isWidgetDisplayed(config, "LatestUpdates")
    ) {
      visibleLayers.current.latestUpdates[layer.id] = visible;
      const arrayVisibleLayers = Object.values(
        visibleLayers.current.latestUpdates
      ).filter((visible) => visible);
      const tab = "latestUpdates";
      processTab({ tab, visible: arrayVisibleLayers.length > 0 });
    }
  };

  useEffect(() => {
    if (watchHandlers.current.length > 0) {
      watchHandlers.current.forEach((h) => h.remove());
      watchHandlers.current = [];
    }

    const layersChangeHandler = view.map.layers.on("change", (event) => {
      if (event.added)
        event.added.forEach((layer) => {
          if (!layer.layerConfig) return;
          processLayer(layer, layer.visible);
          const visibleHandler = layer.watch("visible", (visible) => {
            processLayer(layer, visible);
          });
          watchHandlers.current.push(visibleHandler);
        });
    });
    watchHandlers.current.push(layersChangeHandler);

    view.map.layers.forEach((layer) => {
      processLayer(layer, layer.visible);
      const visibleHandler = layer.watch("visible", (visible) => {
        processLayer(layer, visible);
      });
      watchHandlers.current.push(visibleHandler);
    });

    return () => {
      watchHandlers.current.forEach((h) => h.remove());
    };
  }, [layersLoading]);

  let timer;
  useEffect(() => {
    clearTimeout(timer);
    setTimeout(() => {
      if (!userSelectedTab.current && dashboardTabs.current.size > 0) {
        const moduleOpenWidgets = config.modules
          ? config.modules[activeModule]?.openWidgets ?? []
          : [];
        const openWidget =
          moduleOpenWidgets.length > 0 ? moduleOpenWidgets[0] : null;

        if (openWidget && dashboardTabs.current.has(WidgetToTab[openWidget])) {
          setSelectedTab(WidgetToTab[openWidget]);
        } else {
          if (dashboardTabs.current.has("sharedInformation")) {
            setSelectedTab("sharedInformation");
          } else if (dashboardTabs.current.has("latestUpdates")) {
            setSelectedTab("latestUpdates");
          } else if (dashboardTabs.current.has("insights")) {
            setSelectedTab("insights");
          } else if (dashboardTabs.current.has("editor")) {
            setSelectedTab("editor");
          }
        }
      }
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [_dashboardCount]);

  const handleTabSelection = useCallback(
    (tab) => {
      if (!expand) {
        onArrowClick();
      }
      setSelectedTab(tab);
      userSelectedTab.current = tab;
    },
    [onArrowClick, expand]
  );

  const tabs = Array.from(dashboardTabs.current);

  const renderTab = () => {
    switch (selectedTab) {
      case "latestUpdates": {
        return <LatestUpdates />;
      }
      case "insights": {
        return (
          <Insights
            expand={expand}
            showSidebar={handleShowSidebar}
            closeSidebar={handleCloseSidebar}
          />
        );
      }
      case "sharedInformation": {
        return <SharedInformation expand={expand} selectedTab={selectedTab} />;
      }
      case "editor": {
        return <EditorManager expand={expand} />;
      }
      default: {
        return null;
      }
    }
  };

  //for editor
  const isEditor = useMemo(() => {
    return (
      config.role === ROLE_EDITOR &&
      (config.newEditorEnabled || config.cycleUpdateFrequency)
    );
  }, [config]);

  const hasTopWidgets = useMemo(() => {
    const topWidgets = document.querySelector(".top-widgets-wrapper");
    if (topWidgets) {
      return topWidgets.innerHTML.length > 0;
    }
    return false;
  }, [expand, _dashboardCount]);

  const opsColor = useMemo(() => {
    return getOpsColor(config, activeModule);
  }, [config, activeModule]);

  useEffect(() => {
    const checkHeight = () => {
      requestAnimationFrame(() => {
        if (sidebarRef.current) {
          if (sidebarRef.current.offsetHeight < 150) {
            sidebarRef.current.classList.add("hidden");
          } else {
            sidebarRef.current.classList.remove("hidden");
          }
        }
      });
    };

    const resizeObserver = new ResizeObserver(checkHeight);
    if (sidebarRef.current) {
      resizeObserver.observe(sidebarRef.current);
    }

    return () => {
      if (sidebarRef.current) {
        resizeObserver.unobserve(sidebarRef.current);
      }
    };
  }, [_dashboardCount]);

  return (
    <Sidebar
      ref={sidebarRef}
      show={tabs.length > 0}
      expand={expand}
      newEditorEnabled={
        config.role === ROLE_EDITOR &&
        (config.newEditorEnabled || config.cycleUpdateFrequency)
      }
      defaultWidth={
        tabs.includes("insights")
          ? isStockPrepositionLayer
            ? window.innerWidth / 2
            : insightsWidth
          : sharedInformationWidth
      }
      className="right-sidebar"
    >
      <StyledDashboardPortal
        style={{ position: "absolute", right: 0 }}
        id="dashboard-portal"
      />

      <StyledResizerWrapper>
        <StyledResizerButton onClick={onArrowClick}>
          <SectionItem
            style={{
              borderRadius: "10px 0px 0px 10px",
              height: "100%",
              width: "30px",
              boxSizing: "border-box",
              padding: "0px",
            }}
          >
            <FaChevronRight
              style={{
                transform: `rotate(${expand ? "0deg" : "-180deg"})`,
                transition: "all 0.3s",
              }}
            />
          </SectionItem>
        </StyledResizerButton>
      </StyledResizerWrapper>

      <StyledResizer
        onMouseDown={initDrag}
        expand={expand}
        className={
          window.isSmall && tabs.includes("latestUpdates")
            ? "onboarding-latest-updates"
            : ""
        }
      />
      <SidebarBody
        hasTopWidgets={hasTopWidgets}
        style={{
          overflow: "hidden",
        }}
        expand={expand}
      >
        {selectedTab === "" ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: `calc(100% - ${isMobile ? "34px" : "44px"})`,
              top: isMobile ? "34px" : "44px",
            }}
          >
            <StyledLoaderWrapper>
              <CustomLoader fontSize="3rem" />
            </StyledLoaderWrapper>
          </div>
        ) : isEditor ? (
          <div style={{ position: "relative", height: "100%" }}>
            {renderTab()}
          </div>
        ) : (
          <CalciteTabs
            scale={window.isSmall ? "m" : "l"}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            <CalciteTabNav opsColor={opsColor} slot="title-group">
              <CalciteTabTitle
                tab="sharedInformation"
                onCalciteTabsActivate={() => {
                  handleTabSelection("sharedInformation");
                }}
                selected={selectedTab === "sharedInformation" || undefined}
                closed={tabs.includes("sharedInformation") ? undefined : true}
                key="shared-information"
              >
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#393738",
                    padding: "0px 5px",
                    display: "flex",
                    gap: 6,
                    alignItems: "center",
                  }}
                >
                  {t("screen.widget.ReportManager.title")}
                  <div
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  >
                    <ShareInformationIcon
                      height={16}
                      width={16}
                      color={opsColor}
                    />
                  </div>
                </span>
              </CalciteTabTitle>
              <CalciteTabTitle
                tab="lu"
                onCalciteTabsActivate={() => {
                  handleTabSelection("latestUpdates");
                }}
                selected={selectedTab === "latestUpdates" || undefined}
                closed={
                  tabs.includes("latestUpdates") && showLatestUpdates
                    ? undefined
                    : true
                }
                key="latestUpdates"
              >
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#393738",
                    padding: "0px 5px",
                    display: "flex",
                    gap: 6,
                    alignItems: "center",
                  }}
                >
                  {t("screen.widget.LatestUpdates.title")}
                  <div style={{ height: 16, width: 16 }}>
                    <LatestUpdatesIcon color={opsColor} />
                  </div>
                </span>
              </CalciteTabTitle>
              <CalciteTabTitle
                tab="insights"
                onCalciteTabsActivate={() => {
                  handleTabSelection("insights");
                }}
                selected={selectedTab === "insights" || undefined}
                closed={tabs.includes("insights") ? undefined : true}
                key="insights"
              >
                <span
                  style={{
                    fontSize: 18,
                    color: "#393738",
                    fontWeight: 500,
                    padding: "0px 5px",
                    display: "flex",
                    gap: 6,
                  }}
                >
                  <span>{t("screen.widget.Insights.title")}</span>
                  <div
                    style={{
                      height: 16,
                      width: 16,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill={opsColor}
                    >
                      <path d={graphBar16} />
                    </svg>
                  </div>
                </span>
              </CalciteTabTitle>
            </CalciteTabNav>
            <StyledTab
              selected={selectedTab === "sharedInformation" || undefined}
              tab="sharedInformation"
              style={{ width: "100%", height: "100%" }}
            >
              {selectedTab === "sharedInformation" ? (
                <SharedInformation expand={expand} selectedTab={selectedTab} />
              ) : (
                <StyledLoaderWrapper>
                  <CustomLoader fontSize="3rem" />
                </StyledLoaderWrapper>
              )}
            </StyledTab>
            <StyledTab
              selected={selectedTab === "latestUpdates" || undefined}
              tab="lu"
              style={{ width: "100%", height: "100%" }}
            >
              {showLatestUpdates ? (
                <LatestUpdates />
              ) : (
                <StyledLoaderWrapper>
                  <CustomLoader fontSize="3rem" />
                </StyledLoaderWrapper>
              )}
            </StyledTab>
            <StyledTab
              selected={selectedTab === "insights" || undefined}
              tab="insights"
              style={{ width: "100%", height: "100%" }}
            >
              {selectedTab === "insights" ? (
                <Insights
                  expand={expand}
                  showSidebar={handleShowSidebar}
                  closeSidebar={handleCloseSidebar}
                />
              ) : (
                <StyledLoaderWrapper>
                  <CustomLoader fontSize="3rem" />
                </StyledLoaderWrapper>
              )}
            </StyledTab>
          </CalciteTabs>
        )}
      </SidebarBody>
      {/*</div>*/}
    </Sidebar>
  );
};

export default Dashboard;
