import React, { useCallback, useState } from "react";

const FeatureSelectHeader = ({
  api,
  editedFeatures,
  editedFeatureIds,
  ...params
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleChange = useCallback(() => {
    const newSelectedState = !isSelected;
    setIsSelected(newSelectedState);
    const rows = [];
    api.forEachNode((node) => {
      const objectIdField = node.data.objectIdField;
      const objectId = node.data[objectIdField];
      const isEdited = Array.isArray(editedFeatureIds)
        ? editedFeatureIds.includes(objectId)
        : false;

      const updatedData = {
        ...node.data,
        featureSelect: isEdited || newSelectedState,
      };

      node.setData(updatedData);
      rows.push(node);
    });

    api.applyTransaction({
      update: rows,
    });
  }, [api, isSelected, editedFeatures, editedFeatureIds]);

  return (
    <div>
      <input
        readOnly
        type="radio"
        checked={isSelected}
        onClick={handleChange}
      />
    </div>
  );
};

export default FeatureSelectHeader;
