import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { StyledSearchBox } from "../../Dashboard/Editor/Editor-styled";
import Loader from "../../Dashboard/Editor/Loader/Loader";
import { ArrowUpIcon } from "../../Icons/index";
import {
  ArrowIconWrapper,
  CoordinatesContent,
  CoordinatesHeader,
  NoResultsContainer,
  StyledLoader,
  StyledSuggestGroup,
  StyledSuggestions,
  StyledSuggestItem,
  SuggestionContainer,
} from "../SearchWidget-styled";
import SuggestionItem from "./SuggestionItem";

const Suggestions = forwardRef((props, ref) => {
  const {
    showSuggestions,
    loading,
    showNoResults,
    suggestions,
    showCoordinates,
    showDropdown,
    selectedResultKey,
    suggestionsLength,
    handleSuggestionClick,
    t,
    config,
    children,
    fontSize,
    color,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(510);
  const suggestionsRef = useRef(null);
  const toggleAccordion = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!showDropdown && isExpanded) {
      setIsExpanded(false);
    }
  }, [showDropdown]);

  const updateMaxHeight = useCallback(() => {
    if (suggestionsRef.current) {
      const rect = suggestionsRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const bottomPosition = rect.top + 510;

      if (bottomPosition > windowHeight) {
        const availableSpace = windowHeight - rect.top - 20; // 20px padding from bottom
        setMaxHeight(Math.max(200, availableSpace)); // minimum height of 200px
      } else {
        setMaxHeight(510); // default height
      }
    }
  }, []);

  useEffect(() => {
    if (showSuggestions) {
      updateMaxHeight();
      window.addEventListener("resize", updateMaxHeight);
      return () => window.removeEventListener("resize", updateMaxHeight);
    }
  }, [showSuggestions, updateMaxHeight]);

  return (
    <StyledSearchBox fontSize={fontSize} ref={ref}>
      <StyledSuggestions
        ref={suggestionsRef}
        style={{
          display: showSuggestions ? "flex" : "none",
          maxHeight: `${maxHeight}px`,
        }}
      >
        {loading && !showNoResults && suggestionsLength === 0 && (
          <Loader
            position="absolute"
            size={
              suggestionsLength > 0 && suggestionsLength < 4
                ? "60px"
                : suggestionsLength === 0 && !isExpanded
                ? "40px"
                : undefined
            }
            loaderStyles={{
              top: 10,
              position: suggestionsLength === 0 ? "relative" : "static",
            }}
            color={color}
          />
        )}
        <SuggestionContainer>
          {showNoResults ? (
            <NoResultsContainer>
              <StyledSuggestItem>
                <p>No results found</p>
              </StyledSuggestItem>
            </NoResultsContainer>
          ) : (
            showDropdown &&
            (suggestions.length > 0 || showCoordinates) && (
              <>
                {!!suggestions.length
                  ? suggestions.map((suggestion) => (
                      <StyledSuggestGroup key={suggestion.key}>
                        <div>
                          {suggestion.results.map((result) => (
                            <SuggestionItem
                              key={result.key}
                              result={result}
                              suggestion={suggestion}
                              selectedResultKey={selectedResultKey}
                              handleSuggestionClick={handleSuggestionClick}
                              config={config}
                              t={t}
                            />
                          ))}
                        </div>
                      </StyledSuggestGroup>
                    ))
                  : showCoordinates && (
                      <div>
                        <CoordinatesHeader onClick={toggleAccordion}>
                          <span>
                            {t("screen.widget.Search.seeCoordinates")}
                          </span>
                          <ArrowIconWrapper isExpanded={isExpanded}>
                            <ArrowUpIcon />
                          </ArrowIconWrapper>
                        </CoordinatesHeader>
                        <CoordinatesContent isExpanded={isExpanded}>
                          {children}
                        </CoordinatesContent>
                      </div>
                    )}
              </>
            )
          )}
          {showDropdown &&
            loading &&
            suggestionsLength > 0 &&
            !showNoResults && <StyledLoader color={color ?? config.opsColor} />}
        </SuggestionContainer>
      </StyledSuggestions>
    </StyledSearchBox>
  );
});

Suggestions.displayName = "Suggestions";

export default Suggestions;
