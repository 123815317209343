import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../../../utils/ConfigContext";
import {
  StyledBackButton,
  StyledButtonContainer,
  StyledChecklistHeader,
  StyledConfirmButton,
  StyledHeaderContainer,
} from "../../CycleManager-styled";
import { useSelector } from "react-redux";
import { formatIntlDateTime, timeAgo } from "../../../../../utils/helper";
import { EditorContext } from "../../../Editor/EditorContextProvider";
import Message from "../../../Editor/Message/Message";
import { CloseIcon, EditorIcon, SaveIcon } from "../../../../Icons";
import { getLayerTitle } from "../../../../../esri/custom-popup-content";
import ActionButton from "../ChecklistTable/ActionButton";
import Tooltip from "../../../../UI/Tooltip";
import { ConfirmButton } from "../../../Editor/EditorSwiper/Footer";

const ChecklistHeader = ({
  editedFeatures,
  toggleBatchUpdate,
  handleUpdateAll,
  handleClose,
  confirmUpdateCount,
  saveEnabled,
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const { layersUpdates } = useSelector((state) => state.cycleManagerReducer);
  const { config } = useContext(ConfigContext);
  const { t, i18n } = useTranslation("common");
  const { editableLayer, batchUpdateFeatures } = useContext(EditorContext);

  const calculatedPercent = useMemo(() => {
    let featuresCount = 0,
      outdatedCount = 0;
    Object.values(layersUpdates).forEach((layerUpdate) => {
      outdatedCount += layerUpdate.outdated.length;
      featuresCount += layerUpdate.features.length;
    });

    const percent = Math.floor(
      ((featuresCount - outdatedCount) * 100) / featuresCount
    );
    return percent;
  }, [layersUpdates]);

  const progressColor = useMemo(() => {
    // let color = 'red';
    // if (calculatedPercent >= 85) {
    //   color = 'green';
    // } else if (calculatedPercent >= 50) {
    //   color = 'orange';
    // }

    return config.opsColor;
  }, [calculatedPercent]);

  const format = (value) => {
    if (!value) return "";
    return timeAgo(new Date(value), i18n.language, false);
  };

  function formatTime(time) {
    if (!time) return "";

    const utcDate = new Date(time);
    const utcNow = new Date();
    const diffTime = Math.abs(utcNow.getTime() - utcDate.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(
      (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const hasTimeLeft = utcNow.getTime() - utcDate.getTime() < 0;

    if (diffDays === 1) {
      return hasTimeLeft ? "tomorrow" : "yesterday";
    }

    if (diffDays > 1) {
      const options = {
        timeZone: "UTC",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const formattedDate = formatIntlDateTime({
        languageCode: i18n.language,
        options: options,
        date: utcDate,
      });

      return formattedDate + " UTC";
    }

    let result = "";
    if (diffHours > 0) {
      result += `${diffHours} h`;
    }
    if (diffMinutes > 0) {
      if (result) {
        result += `, `;
      }
      result += `${diffMinutes} m`;
    }
    if (!result) {
      result = "just now";
    } else {
      result += hasTimeLeft ? "" : " ago";
    }
    return result;
  }

  const editedFeaturesCount = useMemo(
    () => Object.keys(editedFeatures).length,
    [editedFeatures]
  );

  const layerTitle = useMemo(() => {
    if (!editableLayer) return "";

    return getLayerTitle(editableLayer, t);
  }, [editableLayer, t]);

  const confirmStatusEnabled = useMemo(() => {
    return confirmUpdateCount > 0;
  }, [confirmUpdateCount]);

  const hasBatchUpdateFields = useMemo(() => {
    const batchUpdateFields = editableLayer?.layerConfig?.batchUpdateFields;
    const situationalFields = editableLayer?.layerConfig?.situationalFields;

    if (!Array.isArray(batchUpdateFields) || !Array.isArray(situationalFields))
      return false;

    return (
      situationalFields.filter(
        (fieldName) => !batchUpdateFields.includes(fieldName)
      ).length > 0
    );
  }, [editableLayer]);

  return (
    <>
      {/* {showMessage && (
        <Message
          title={t("cycleManager.areYouSure.confirmStatus.title", {
            1: editableLayer.getLayerTitle(t),
          })}
          subtitle={t("cycleManager.areYouSure.confirmStatus.description")}
          onSubmit={() => {
            setShowMessage(false);
            handleUpdateAll();
          }}
          onCancel={() => {
            setShowMessage(false);
          }}
          type="warning"
          position="absolute"
        />
      )} */}
      <StyledChecklistHeader>
        <StyledHeaderContainer>
          <span style={{ fontSize: 16 }}>
            {t("cycleManager.title", { 1: layerTitle })}
          </span>
          <StyledBackButton onClick={handleClose}>
            <CloseIcon width="18" height="18" />
          </StyledBackButton>
        </StyledHeaderContainer>
        <StyledHeaderContainer>
          <span style={{ fontSize: 14 }}>{t("cycleManager.subtitle")}</span>
          <StyledButtonContainer>
            {hasBatchUpdateFields && (
              <Tooltip
                disabled={batchUpdateFeatures.length === 0}
                placement="left"
                showOnHover
                content={t("cycleManager.tooltip.edit")}
              >
                <ActionButton
                  onClick={toggleBatchUpdate}
                  color={config.opsColor}
                  count={batchUpdateFeatures.length}
                  Icon={EditorIcon}
                />
              </Tooltip>
            )}
            {/* <Tooltip
              showOnHover
              content={t("cycleManager.tooltip.save")}
              placement="left"
              maxWidth={190}
              disabled={!saveEnabled}
            >
              <ActionButton
                color={config.opsColor}
                count={editedFeaturesCount}
                Icon={SaveIcon}
                onClick={handleSave}
                disabled={!saveEnabled}
              />
            </Tooltip> */}
            <ConfirmButton
              count={confirmUpdateCount}
              color={config.opsColor}
              onClick={handleUpdateAll}
              disabled={!confirmStatusEnabled}
              title={
                saveEnabled ? (
                  <SaveIcon width="14" height="14" fill={config.opsColor} />
                ) : (
                  ""
                )
              }
              showConfirmTitle={true}
            />
            {/* <Tooltip
              showOnHover
              placement="right"
              content={t("cycleManager.tooltip.confirm")}
              disabled={!confirmStatusEnabled}
            >
              <ActionButton
                count={confirmUpdateCount}
                color={config.opsColor}
                onClick={() => {
                  setShowMessage(true);
                }}
                disabled={!confirmStatusEnabled}
              >
                <span>
                  {false ? "Save and " : ""}
                  {t("cycleManager.confirmStatus")}{" "}
                </span>
              </ActionButton>
            </Tooltip> */}
            {/* <Tooltip
              showOnHover
              placement="right"
              content={t("cycleManager.tooltip.confirm")}
              disabled={!confirmStatusEnabled}
            >
              <StyledConfirmButton
                onClick={() => {
                  setShowMessage(true);
                }}
                disabled={!confirmStatusEnabled}
              >
                <span style={{ color: "#fff", fontWeight: 400 }}>
                  {t("cycleManager.confirmStatus")}{" "}
                  {confirmUpdateCount > 0 ? `(${confirmUpdateCount})` : ""}
                </span>
              </StyledConfirmButton>
            </Tooltip> */}
          </StyledButtonContainer>
        </StyledHeaderContainer>
      </StyledChecklistHeader>
    </>
  );
};

export default ChecklistHeader;
