import React from "react";
import styled from "styled-components";

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
