import { loadModules } from "esri-loader";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { view } from "../../../../../utils/API";
import { ConfigContext } from "../../../../../utils/ConfigContext";
import {
  StyledMapContainer,
  StyledMapFocus,
  StyledTitle,
  StyledTitleContainer,
  StyledViewContainer,
} from "../NotificationMenu-styled";

const MdPhonelinkRing = (props) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="16px"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0V0z"></path>
    <path d="m20.1 7.7-1 1c1.8 1.8 1.8 4.6 0 6.5l1 1c2.5-2.3 2.5-6.1 0-8.5zM18 9.8l-1 1c.5.7.5 1.6 0 2.3l1 1c1.2-1.2 1.2-3 0-4.3zM14 1H4c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 19H4V4h10v16z"></path>
  </svg>
);

const NotificationMap = ({ currentView, setCurrentView }) => {
  const mapRef = useRef(null);
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);

  useEffect(() => {
    let currView;
    if (!view || !mapRef.current) return;

    loadModules(["esri/views/MapView", "esri/widgets/Zoom"]).then(
      ([MapView, Zoom]) => {
        if (mapRef.current) {
          currView = new MapView({
            container: mapRef.current,
            map: view.map,
            center: view.center,
            zoom: view.zoom,
            ui: {
              components: [],
            },
            constraints: view.constraints,
            popup: null,
          });

          currView.when(() => {
            setCurrentView(currView);
            setTimeout(() => {
              const zoom = new Zoom({
                view: currView,
              });

              currView.ui.add(zoom, {
                position: "bottom-right",
              });
            }, 250);
          });
        }
      }
    );

    return () => {
      // if (currentView) {
      //   currentView.destroy();
      // }
    };
  }, []);

  return (
    <StyledMapContainer>
      <StyledTitleContainer>
        <StyledTitle>
          {t("screen.widget.Editor.notifications.map.title")}
        </StyledTitle>
      </StyledTitleContainer>

      <StyledViewContainer>
        <div ref={mapRef} />
        <StyledMapFocus>
          <div />
        </StyledMapFocus>
      </StyledViewContainer>
    </StyledMapContainer>
  );
};

export default NotificationMap;
