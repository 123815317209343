import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { graphicsLayer, view } from "../../../../utils/API";
import { EditorContext } from "../EditorContextProvider";
import { EditType, pointSymbol } from "../EditorSwiper/EditorSwiper";
import BatchFieldsHeader from "./BatchFieldsHeader";
import EditableFields from "./EditableFields/EditableFields";
import EditorSituationalFields from "./EditorSituationalFields";

export const filledInColor = "#16a34a";

const featureConformsDef = (feature, fields, value) => {
  const values = value.split(",");
  if (fields.length !== values.length) return false;

  return (
    values.filter((value, idx) => {
      if (value === "*") return true;

      const fieldName = fields[idx].name;
      const fieldValue = feature.attributes[fieldName];

      const range = value.split("-");
      if (range.length === 2) {
        //Range specified
        return (
          typeof fieldValue === "number" &&
          fieldValue >= range[0] &&
          fieldValue < range[1]
        );
      }
      //Single value specified
      else
        return typeof fieldValue === "number"
          ? fieldValue === Number(value)
          : fieldValue === value;
    }).length === values.length
  );
};

export const getFeatureIcon = (feature, symbology, layer = undefined) => {
  let iconName = symbology.defaultIcon;

  if (symbology?.iconMap) {
    const values = [];
    symbology.iconMap.fields.map((fieldName) => {
      const value = feature.attributes[fieldName];
      values.push(value || "*");
    });

    iconName = symbology?.iconMap.default[values.join(",")];
    if (!iconName) {
      const featureLayer = layer || feature.layer;
      const iconDef = featureLayer.renderer
        .getIconDefinitions()
        .find((iconDef) =>
          featureConformsDef(feature, iconDef.field, iconDef.value)
        );

      return iconDef?.icon || symbology.defaultIcon;
    }
  }

  return iconName;
};

//remove
export const getFeatureNameField = (editableLayer) => {
  const titleTemplate = editableLayer?.layerConfig?.titleTemplate;
  const name = titleTemplate
    ?.substring(1, titleTemplate.length - 1)
    ?.replace("feature.", "");
  if (name) {
    return name;
  }

  return null;
};

const EditorFields = ({
  editableLayer,
  config,
  editType,
  isRequired,
  updateType,
  initialUpdateType,
  handleSubmit,
  getIsDeletedField,
  doDelete,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showBaseline, setShowBaseline] = useState(true);
  const [dragActive, setDragActive] = useState(false);
  const dragHandle = useRef();

  const {
    highlightSymbol,
    highlightFeature,
    setHighlightFeature,
    setAddedPoints,
    popupFeature,
    batchUpdateFeatures,
    activeColor,
    setHighlightSymbol,
    situationalUpdateType,
    setSituationalUpdateType,
    requiredFields,
    setUpdateType,
    addedPoint,
  } = useContext(EditorContext);

  const { setValue } = useFormContext();

  useEffect(() => {
    if (
      editType === EditType.create &&
      editableLayer?.layerConfig.titleLabel === "roadsSituational" &&
      graphicsLayer
    ) {
      const iso3 = new Set();
      graphicsLayer.graphics.forEach((graphic) => {
        if (graphic?.id === "route-combined" && graphic?.attributes?.iso3) {
          iso3.add(graphic.attributes.iso3);
        }
      });

      if (iso3.size > 1) {
        setShowBaseline(false);
      } else if (graphicsLayer.graphics.length > 0) {
        const graphic = graphicsLayer.graphics.toArray()[0];
        if (graphic) {
          Object.keys(graphic.attributes).forEach((key) => {
            const value = graphic.attributes[key];

            if (value) {
              setValue(key, value);
            }
          });
        }
      }
    }
    setIsActive(true);
  }, []);

  useEffect(() => {
    if (initialUpdateType && updateType !== initialUpdateType) {
      setUpdateType(initialUpdateType);
    }
  }, []),
    useEffect(() => {
      let timer;
      return;
      if (!highlightFeature || editableLayer?.geometryType !== "point") return;
      dragHandle.current?.remove();
      dragHandle.current = view.on("drag", async (event) => {
        if (event.action === "start") {
          //Drag starting, search for graphics around the mouse
        } else if (event.action === "update" || event.action === "end") {
          //Dragging: adjust position
          highlightFeature.geometry = view.toMap({ x: event.x, y: event.y });
          const nGraphic = highlightFeature?.clone();
          nGraphic.symbol = highlightSymbol ? highlightSymbol : pointSymbol;

          graphicsLayer.removeAll();
          graphicsLayer.add(nGraphic);
          clearTimeout(timer);
          timer = setTimeout(() => {
            setAddedPoints([nGraphic.geometry]);
            setHighlightFeature(nGraphic);
          }, 250);

          event.stopPropagation();
        }
      });
      return () => {
        clearTimeout(timer);
        dragHandle.current?.remove();
      };
    }, [editableLayer, highlightSymbol, highlightFeature]);

  const handleChange = useCallback(
    ({ name, value }) => {
      if (highlightFeature) {
        const clonedFeature = highlightFeature.clone();
        clonedFeature.attributes[name] = value;

        setHighlightFeature(clonedFeature);
      }
    },
    [highlightFeature]
  );

  if (!editableLayer || !isActive) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: 6,
        boxSizing: "border-box",
      }}
    >
      {/* {highlightFeature && (
        <FeatureHeader
          editableLayer={editableLayer}
          highlightFeature={highlightFeature}
          symbol={highlightSymbol}
          handleChange={handleChange}
          config={config}
          requiredFields={requiredFields}
          setHighlightSymbol={setHighlightSymbol}
          updateType={updateType}
        />
      )} */}

      {!highlightFeature && batchUpdateFeatures.length > 0 && (
        <BatchFieldsHeader
          editableLayer={editableLayer}
          features={batchUpdateFeatures}
          config={config}
          activeColor={activeColor}
        />
      )}

      <div
        style={{
          overflow: "hidden",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {batchUpdateFeatures.length > 0 && !highlightFeature ? (
          <EditorSituationalFields
            editableLayer={editableLayer}
            requiredFields={requiredFields}
            highlightFeature={highlightFeature}
            activeColor={activeColor}
            batchUpdateFeatures={batchUpdateFeatures}
            situationalUpdateType={setSituationalUpdateType}
            setSituationalUpdateType={setSituationalUpdateType}
            updateType={updateType}
            editType={editType}
          />
        ) : (
          <EditableFields
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            editType={editType}
            getIsDeletedField={getIsDeletedField}
            doDelete={doDelete}
            showBaseline={showBaseline}
          />
        )}
        {/* <EditableFields
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          editType={editType}
        /> */}
        {/* {updateType === UpdateType.situational ||
        (batchUpdateFeatures.length > 0 && !highlightFeature) ? ( //(editType === EditType.create && editableLayer.layerConfig.titleLabel === 'roadsSituational' && updateType === UpdateType.situational)
          <EditorSituationalFields
            editableLayer={editableLayer}
            requiredFields={requiredFields}
            highlightFeature={highlightFeature}
            activeColor={activeColor}
            batchUpdateFeatures={batchUpdateFeatures}
            situationalUpdateType={setSituationalUpdateType}
            setSituationalUpdateType={setSituationalUpdateType}
            updateType={updateType}
            editType={editType}
          />
        ) : (
          <EditorDefaultFields
            requiredFields={requiredFields}
            editableLayer={editableLayer}
            isRequired={isRequired}
            editType={editType}
            handleChange={handleChange}
            dragActive={dragActive}
          />
        )} */}
      </div>
    </div>
  );
};

export default EditorFields;
