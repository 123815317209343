import styled from "styled-components";
import { mutedColor } from "../../../../utils/Theme";
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  margin: ${({ margin, noMargin }) =>
    noMargin ? "10px 0px 0px 0px" : margin ?? "0 0 15px 0"};
  transition: all 0.3s;

  & input {
    box-sizing: border-box;
    display: block;
    padding: 6px 8px;
    cursor: auto;
    color: #000000;
    border: ${({ borderColor, isFocused = false, isError }) =>
      isError
        ? "1px solid red"
        : `1px solid ${borderColor ?? "#C0392B"}${isFocused ? "" : "4D"}`};
    outline: 0;
    font-size: 16px !important;
    line-height: 2em;
    text-overflow: ellipsis;
    transition: all 0.5s;
    border-radius: 8px;
  }

  @media screen and (min-width: 769px) {
    font-size: 14px;
  }

  & input::placeholder {
    color: #cbcbcb;
    opacity: ${({ label }) => (label ? 0 : 1)};
    transition: all 0.7s;
  }

  & input:focus {
    border: ${({ borderColor, isError, focusColor }) =>
      `1px solid ${isError ? "red" : focusColor ?? borderColor ?? "#C0392B"}`};

    ::placeholder {
      opacity: 1;
    }
  }

  input:not(:placeholder-shown) + label {
    top: -9px;
    font-size: 10px;
  }

  & input:focus + label {
    top: -9px;
    font-size: 10px;
    //overflow: visible;
    //white-space: normal;
    max-width: calc(100% - 8px);
  }

  & input:disabled {
    background: #eee;
    cursor: not-allowed;
  }
`;
export const InputLabel = styled.label`
  font-family: "Open Sans", sans-serif !important;
  padding: 0px 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5em;
  color: ${mutedColor};
  text-align: left;
  border-radius: 4px;
  pointer-events: none;
  transition: all 0.5s;
  position: absolute;
  top: 10px;
  left: 8px;
  background: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: calc(100% - 18px);
`;

export const WarningMessage = styled.span`
  position: absolute;
  bottom: -15px;
  left: 0px;
  font-size: 10px !important;
  color: red;
  opacity: ${({ isError }) => (isError ? 1 : 0)};
  transform: ${({ isError }) =>
    isError ? "translateY(0px)" : "translateY(5px)"};
  transition: all 0.5s;
  width: 100%;
  text-align: end;
`;

export const DescriptionWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  cursor: pointer;
`;

export const MaxLength = styled.p`
  margin: 0;
  position: absolute;
  right: 10px;
  bottom: -8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5em;
  color: ${mutedColor};
  background: #ffffff;
  border-radius: 4px;
  pointer-events: none;
  padding: 2px 2px;
`;
