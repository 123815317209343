import styled from "styled-components";

export const TableGridWrapper = styled.div`
  width: 100%;
  height: 100%;

  --ag-font-family: "Open Sans", sans-serif;
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-row-border-style: solid !important;
  --ag-row-border-width: 1px;
  --ag-row-border-color: #eee;
  /* and between columns */
  --ag-cell-horizontal-border: solid #eee !important;

  --ag-header-height: 40px !important;

  //--ag-icon-font-code-aggregation: url('/assets/brush.svg');
  //--ag-icon-font-code-arrows: "\\f0b2";
  //--ag-icon-font-code-asc: "\\f062";

  //hiding filter icon
  --ag-icon-image-filter: url("/assets/filter.svg");
  --ag-icon-font-display-filter: none;
  --ag-selected-row-background-color: none;
  --ag-row-hover-color: none; //#ecf0f180;

  //--ag-header-column-separator-display: block;
  //--ag-header-column-separator-height: 100%;
  //--ag-header-column-separator-width: 2px;
  //--ag-header-column-separator-color: purple;
  //
  //.ag-filter-icon {
  //  content: url('/assets/brush.svg'); /* Use the path relative to public */
  //  width: 20px; /* Adjust size as needed */
  //  height: 20px;
  //}

  .ag-overlay-no-rows-wrapper {
    span {
      font-size: 16px;
      color: rgb(75, 75, 75);
    }
  }

  .ag-root-wrapper {
    border-radius: 8px;
  }
  .ag-header-cell-label {
    font-size: 12px;
    font-weight: 400;
    color: #212121;
    text-align: center;
    justify-content: center;
  }

  .ag-body-horizontal-scroll-container,
  .ag-body-horizontal-scroll,
  .ag-body-horizontal-scroll.ag-apple-scrollbar {
    height: 8px !important;
    max-height: 8px !important;
    min-height: 8px !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 8px !important;
    max-height: 8px !important;
    min-height: 8px !important;

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 2px #ffffff, 0 0 2px #ffffff;
      border-radius: 2px;
      width: 4px;
    }

    ::-webkit-scrollbar {
      height: 8px;
    }
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border-color: transparent;
  }

  .ag-row-feature-selected {
    background: rgba(0, 145, 234, 0.28) !important;
  }import { baseLineColor } from '../../Dashboard/Editor/EditorContextProvider';


  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    border-right: 1px var(--ag-cell-horizontal-border);
  }

  .ag-grid-baseline {
    /* border: 1px solid #34495e; */
    background: var(--baselineColor);

    --ag-header-cell-hover-background-color: #34495e;

    --ag-icon-image-filter: url("/assets/filter-white.svg");

    span {
      color: #fff;
    }
  }

  .ag-header-cell-label {
  display: flex;
  align-items: center; /* Center single-line text vertically */
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  height: auto;
  line-height: normal;
}

.ag-header-cell-label > span {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

  .ag-grid-situational {
    border: 1px solid var(--opsColor);
    background: var(--opsColor);

    --ag-header-cell-hover-background-color: var(--opsColor);

    --ag-icon-image-filter: url("/assets/filter-white.svg");

    span {
      color: #ffffff;
    }
  }
`;
