import {
  setAnimateFeatureTable_actionType,
  setEditableFeature_actionType,
  setEditableLayer_actionType,
  setEditorAddPointActive_actionType,
  setEditorDrawActive_actionType,
  setEditorUpdateType_actionType,
} from "../constants";

const initialState = {
  animateFeatureTable: false,
  editableFeature: null,
  editableLayer: null,
  editorDrawActive: false,
  editorAddPointActive: false,
  editUpdateType: null,
  editableLayerVisible: false,
};
const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case setAnimateFeatureTable_actionType: {
      const { payload } = action;
      return {
        ...state,
        animateFeatureTable: payload,
      };
    }
    case setEditableFeature_actionType: {
      const { payload } = action;
      return {
        ...state,
        editableFeature: payload,
      };
    }
    case setEditableLayer_actionType: {
      const { payload } = action;
      let layerVisible = state.editableLayerVisible;

      if (payload) {
        layerVisible = payload.visible;
        if (!payload.visible) {
          payload.visible = true;
        }
      } else if (state.editableLayer) {
        state.editableLayer.visible = layerVisible;
      }

      return {
        ...state,
        editableLayer: payload,
        editableLayerVisible: layerVisible,
      };
    }
    case setEditorDrawActive_actionType: {
      const { payload } = action;
      return {
        ...state,
        editorDrawActive: payload,
      };
    }
    case setEditorAddPointActive_actionType: {
      const { payload } = action;
      return {
        ...state,
        editorAddPointActive: payload,
      };
    }
    case setEditorUpdateType_actionType: {
      const { payload } = action;
      return {
        ...state,
        editUpdateType: payload,
      };
    }
    default:
      return state;
  }
};

export default dashboard;
