import styled from "styled-components";

export const SelectionBox = styled.div`
  /* width: 100%; */
  border: 1px solid
    ${({ borderColor, isError }) => (isError ? "red" : `${borderColor}4d`)};
  padding: 10px 6px;
  border-radius: 8px;
  position: relative;
  margin-top: 4px;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "15px")};
  /* pointer-events: ${({ isDisabled }) =>
    isDisabled ? "none" : undefined}; */
  background: ${({ isDisabled }) => (isDisabled ? "#eee" : "")};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "default")};
`;

export const SelectionBoxDisabled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  top: 0;
  left: 0;
`;

export const SelectionBoxLabel = styled.p`
  position: absolute;
  top: -13px;
  background: ${({ isDisabled }) => (isDisabled ? "#eee" : "#fff")};
  padding: 2px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: rgb(76, 76, 76);
  margin: 0;
  border-radius: 4px;
  margin-bottom: ${({ noMargin }) => (!noMargin ? "4px" : "")};
  span {
    color: red;
  }
`;

export const DescriptionWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  cursor: pointer;
`;

export const SelectionTagItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  padding: 4px 8px;
  transition: all 0.1s;

  span {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    padding: 0;
  }

  p {
    background: ${({ selected, color }) =>
      color ? (selected ? color ?? "#808080" : `#8080801a`) : "#8080801a"};
    color: ${({ selected }) => (selected ? "#ffffff" : "#4c4c4c")};
    margin: 0;
    font-size: ${({ selected }) => (selected ? "12px" : "11px")};
    transition: all 0.1s;
    border-radius: 100px;
    font-weight: 500;
    padding: ${({ selected }) => (selected ? "6px 10px" : "4px 6px")};
  }

  :hover {
    p {
      color: #ffffff;
      background: ${({ color = "#8080801a" }) => color};
    }
  }
`;

export const FieldLabel = styled.p`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: rgb(76, 76, 76);
  margin: 0;
  margin-bottom: ${({ noMargin }) => (!noMargin ? "4px" : "")};
`;

export const FieldWrapper = styled.div`
  input + label {
    left: -4px;
    font-size: 14px;
  }
`;

export const FeatureHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  padding: 10px 14px 10px 14px;

  .content__box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: auto;
    flex: 1;

    textarea {
      font-size: 24px;
      color: ${({ active }) =>
        active ? "rgb(76,76,76, 1)" : "rgb(76,76,76, 0.5)"};
      outline: none;
      padding: 2px;
      max-width: calc(100% - 24px);
      max-height: 70px;
      border: none;
      resize: none;

      ::placeholder {
        color: rgb(76, 76, 76, 0.2);
      }
    }
  }

  .icon__box {
    /* cursor: pointer; */
    img {
      width: 48px;
      height: 48px;
    }
  }
`;

export const StyledEditableTitle = styled.p`
  width: auto !important;
  font-size: 16px !important;
  text-align: left !important;
  /*color: ${({ showPublicActive }) =>
    showPublicActive ? "rgb(76,76,76, 1)" : "rgb(76,76,76, 0.5)"}; */
  outline: none;
  /* padding: 0px 2px; */
  max-width: calc(100% - 24px);
  position: relative;
  overflow: auto;
  max-height: 38px;
  line-height: normal;
  min-width: 4px;
`;
