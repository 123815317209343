import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { standardizeColor } from "../../../../utils/helper";
import CustomSwitch from "../../../CustomSwitch/CustomSwitch";
import { InformationIcon } from "../../../Icons";
import { WarningMessage } from "../../../Report/new/Input/Input-styled";
import { Tooltip } from "../../../UI";
import {
  DescriptionWrapper,
  FieldLabel,
  SelectionBox,
  SelectionBoxDisabled,
  SelectionBoxLabel,
  SelectionTagItem,
} from "./EditorFields-styled";

export const SelectionTags = ({
  id,
  options,
  colors,
  label,
  value,
  field,
  information,
  onSelect,
  isRequired,
  borderColor,
  isDisabled,
}) => {
  const [show, setShow] = useState(false);
  const [selectedCode, setSelectedCode] = useState(value);
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation("common");

  useEffect(() => {
    const fieldValue = getValues(field.name);

    if (!fieldValue) {
      setSelectedCode(value);
      setValue(field.name, value, { shouldValidate: false });
    } else {
      //some values are numbers and some are strings
      const convertedValue = field.type.includes("integer")
        ? Number(fieldValue)
        : fieldValue;
      const val = !Number.isNaN(convertedValue) ? convertedValue : fieldValue;
      setSelectedCode(val);
    }
  }, [value]);

  const handleSelect = useCallback(
    (option) => {
      setSelectedCode(option.code);
      setValue(field.name, option.code, {
        shouldDirty: true,
      });
      if (onSelect) {
        onSelect(option);
      }
    },
    [onSelect]
  );

  const filledInColor = useMemo(
    () => borderColor, //isRequired ? '#16a34a'  : borderColor
    [isRequired]
  );

  const errorMessage =
    errors[field.name]?.type === "required"
      ? t("screen.widget.common.fieldIsRequired")
      : "";

  return (
    // <Tooltip
    //   wrapperStyles={{
    //     width: "100%",
    //   }}
    //   showOnHover
    //   content="Text for editing baseline"
    // >
    <SelectionBox
      borderColor={filledInColor}
      noMargin={!errorMessage}
      isDisabled={isDisabled}
      isError={!!errorMessage}
      // borderColor={
      //   !selectedCode && selectedCode !== 0 && isRequired
      //     ? "#FF0000"
      //     : filledInColor
      // }
    >
      {isDisabled && <SelectionBoxDisabled />}
      {label && (
        <SelectionBoxLabel>
          {label} {isRequired ? <span> *</span> : ""}
        </SelectionBoxLabel>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 6,
        }}
      >
        <select
          {...register(field.name, { required: isRequired })}
          readOnly
          id={id}
          data-field-name={field.name}
          style={{ display: "none" }}
          value={selectedCode}
          data-alias={field.alias}
        >
          {field.nullable && <option key={null} value={""}></option>}
          {options.map((cv) => (
            <option key={cv.code} value={cv.code}>
              {cv.name}
            </option>
          ))}
        </select>
        {options.map((option) => (
          <SelectionTagItem
            selected={selectedCode === option.code}
            key={option.code}
            color={standardizeColor(colors[option.code] ?? "#808080")}
            onClick={() => handleSelect(option)}
            style={{
              padding: 0,
            }}
          >
            <p>{option.name}</p>
          </SelectionTagItem>
        ))}
        {information && (
          <DescriptionWrapper>
            <Tooltip showOnClick content={information}>
              <InformationIcon width="18" height="18" />
            </Tooltip>
          </DescriptionWrapper>
        )}
      </div>
      <WarningMessage isError={!!errorMessage}>{errorMessage}</WarningMessage>
    </SelectionBox>
    // </Tooltip>
  );
};

export const EditorSwitch = ({
  id,
  t,
  label,
  value,
  field,
  information,
  onChange,
  isRequired,
  disabled,
}) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(value || 2);
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    const fieldValue = getValues(field.name);
    if (!fieldValue) {
      setSelected(value || 2);
      setValue(field.name, value || 2);
    } else {
      const val = field.type.includes("integer")
        ? Number(fieldValue)
        : fieldValue;
      setSelected(val);
    }
  }, [value]);

  const onToggle = useCallback((e) => {
    const value = e ? 1 : 2;
    setValue(field.name, value, {
      shouldDirty: true,
    });
    if (onChange) {
      onChange({ name: field.name, value });
    }
    setSelected(e ? 1 : 2);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        padding: "0px 1px",
      }}
    >
      <select
        {...register(field.name, { required: isRequired })}
        readOnly
        id={id}
        style={{ display: "none" }}
        data-field-name={field.name}
        className="esri-input esri-feature-form__input"
        value={selected}
        data-alias={field.alias}
        disabled={disabled}
      >
        {field.nullable && <option key={null} value={""}></option>}
        <option key={1} value={1}>
          {1}
        </option>
        <option key={2} value={2}>
          {2}
        </option>
      </select>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 6,
        }}
      >
        <FieldLabel style={{ fontSize: 14 }} noMargin>
          {label}
        </FieldLabel>
        {information && (
          <Tooltip showOnClick content={information}>
            <InformationIcon width="18" height="18" />
          </Tooltip>
        )}
      </div>
      <CustomSwitch
        onChange={onToggle}
        checked={selected === 1}
        height={18}
        width={38}
        handleDiameter={14}
        disabled={disabled}
      />
    </div>
  );
};
