import { loadModules } from "esri-loader";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addLayerEffect,
  removeLayerEffects,
  zoomToFeaturesExtent,
} from "../../components/Dashboard/Editor/helpers";
import { setActiveModule } from "../../redux/action/ActiveModule-actions";
import { addLayersUpdate } from "../../redux/action/CycleManager-action";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  view,
} from "../../utils/API";
import { applyEditsInBatches } from "../../utils/helper";
import useCustomSnackbar from "../useCustomSnackbar";

const processFeatureUpdates = (features, editedFeatures, objectIdField) => {
  const updateFeatures = [];
  const columnIds = new Set();

  const newFeatures = features.map((feat) => {
    const newAttributes = editedFeatures[feat.attributes[objectIdField]] || {};

    if (Object.keys(newAttributes).length > 0) {
      const newFeature = feat.clone();
      const updateFeature = newFeature.clone();
      newAttributes[objectIdField] = feat.attributes[objectIdField];

      updateFeature.attributes = newAttributes;
      Object.keys(newAttributes).forEach((k) => {
        newFeature.attributes[k] = newAttributes[k];
        columnIds.add(k);
      });

      updateFeatures.push(updateFeature);
      return newFeature;
    }

    return feat;
  });

  return {
    updateFeatures,
    columnIds: Array.from(columnIds),
    newFeatures,
    updatedAttributesType: "",
  };
};

const updateCellStyles = (gridApi, columnIds, resetStyles = false) => {
  try {
    // Reset column styles
    columnIds.forEach((colId) => {
      const column = gridApi.getColumn(colId);
      if (!column) return;

      const colDef = column.getColDef();
      colDef.cellClass = () => null;
      column.setColDef(colDef);
    });

    return true;
  } catch (error) {
    console.error("Error updating grid after save:", error);
    return false;
  }
};

const useLayerEditTable = ({
  editableLayer,
  setEditableLayer,
  //maybe we can move this
  batchUpdateFeatures,
  setBatchUpdateFeatures,
  updateType,
  editedFeatures,
  setEditedFeatures,
}) => {
  const [requiredFieldsMessage, setRequiredFieldsMessage] = useState({
    show: false,
    fields: [],
  });
  const [graphicsLayer, setGraphicsLayer] = useState(null);
  const [updateFeaturesIds, setUpdateFeaturesIds] = useState([]);
  const [showBatchUpdate, setShowBatchUpdate] = useState(false);
  const [saveMessage, setSaveMessage] = useState({
    newModule: null,
    newLayer: null,
    show: false,
  });
  const [updatedFeatures, setUpdatedFeatures] = useState(null);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);

  const { layersUpdates } = useSelector((state) => state.cycleManagerReducer);

  const { t } = useTranslation("common");
  const [openSnackbar] = useCustomSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (editableLayer && batchUpdateFeatures.length === 0) {
      setUpdateFeaturesIds([]);
    }
  }, [editableLayer]);

  useEffect(() => {
    if (editableLayer) {
      setBatchUpdateFeatures([]);
      // graphicsLayer.removeAll();
      setUpdateFeaturesIds([]);
      if (showBatchUpdate) {
        setBatchUpdateFeatures([]);
        setShowBatchUpdate(false);
      }
    }
  }, [editableLayer]);

  //creating graphics layer
  useEffect(() => {
    let checkListLayer;
    loadModules(["esri/layers/GraphicsLayer"]).then(([GraphicsLayer]) => {
      checkListLayer = new GraphicsLayer({
        popupEnabled: false,
        popupTemplate: null,
      });
      checkListLayer.originalId = "checklist";

      setGraphicsLayer(checkListLayer);
      view.map.add(checkListLayer);

      if (checkListLayer) {
        addLayerEffect([checkListLayer]);
      }
    });

    return () => {
      removeLayerEffects();
      if (checkListLayer) {
        checkListLayer?.removeAll();
        view.map.remove(checkListLayer);
      }
    };
  }, []);

  const showSaveMessage = useCallback((layer, module) => {
    setSaveMessage({
      show: true,
      newLayer: layer,
      newModule: module,
    });
  }, []);

  const toggleBatchUpdate = useCallback(() => {
    setShowBatchUpdate((prev) => !prev);
  }, [showBatchUpdate]);

  //if we have edited features, show message
  //we can also pass module as second argument to change the module
  const handleLayerSelection = useCallback(
    (layer, module) => {
      if (
        Object.keys(editedFeatures).length > 0 ||
        updateFeaturesIds.length > 0
      ) {
        showSaveMessage(layer, module);
        return;
      }

      if (module) {
        dispatch(setActiveModule(module));
      }
      setEditableLayer(layer);
    },
    [editedFeatures, updateFeaturesIds]
  );

  const handleShowUpdateMessage = useCallback((features) => {
    setUpdatedFeatures(features);
    setShowUpdateMessage(true);

    setTimeout(() => {
      setShowUpdateMessage(false);
      setUpdatedFeatures(null);
    }, 3000);
  }, []);

  const getUpdatedFieldsTypes = useCallback(
    (columnIds, baselineFieldsArray, situationalFields) => {
      if (updateType === "situational") {
        return {
          hasBaselineField: false,
          hasSituationalField: true,
        };
      }
      const baselineFieldsSet = new Set(baselineFieldsArray);
      const situationalFieldsSet = new Set(situationalFields);
      let hasBaselineField = false;
      let hasSituationalField = false;

      for (const fieldName of columnIds) {
        if (baselineFieldsSet.has(fieldName) && !hasBaselineField) {
          hasBaselineField = true;
        }

        if (situationalFieldsSet.has(fieldName) && !hasSituationalField) {
          hasSituationalField = true;
        }

        if (hasSituationalField && hasBaselineField)
          return { hasBaselineField, hasSituationalField };
      }

      return {
        hasBaselineField,
        hasSituationalField,
      };
    },
    [updateType]
  );

  const handleUpdateAll = useCallback(async () => {
    if (!gridApi) {
      console.warn("Table not initialized");
      return;
    }
    try {
      setLoading(true);
      const layerUpdate = { ...layersUpdates[editableLayer.layerConfig.id] };

      const now = new Date().getTime();
      // Get selected rows from grid
      const selectedFeatureIds = [];
      const nodeMap = {};
      const objectIdField = editableLayer.objectIdField;

      //updating the rows
      gridApi.forEachNode((node) => {
        if (node.data.featureSelect) {
          selectedFeatureIds.push(node.data[editableLayer.objectIdField]);
          nodeMap[node.data[objectIdField]] = node;
        }
      });

      const { newFeatures: featuresWithSavedValues, columnIds } =
        processFeatureUpdates(features, editedFeatures, objectIdField);

      const featuresToSave = [];

      //if we are updating situational table we have to check for required fields
      //if we are making edits from data-table we skip it
      if (updateType === "situational") {
        const requiredFields = editableLayer.layerConfig.requiredFields ?? [];
        const allMissingFields = new Set();
        // Function to check if a feature has all required fields and collect missing fields
        const getMissingFields = (feature) => {
          const missingFields = requiredFields.filter((field) => {
            if (
              field in feature.attributes &&
              (feature.attributes[field] === null ||
                feature.attributes[field] === undefined ||
                feature.attributes[field] === "") &&
              field !== currAsOfDateFieldName
            ) {
              allMissingFields.add(field);
              return true;
            }

            return false;
          });
          return missingFields;
        };

        const missingFieldsMap = new Map();
        // Use reduce to iterate through featuresToSave only once
        featuresWithSavedValues.forEach((feature) => {
          const featId = feature.getObjectId();
          if (selectedFeatureIds.includes(featId)) {
            const missingFields = getMissingFields(feature);
            if (missingFields.length > 0) {
              missingFieldsMap.set(featId, missingFields);
            }
          }
        });

        if (missingFieldsMap.size > 0) {
          console.warn("No valid features to save");

          gridApi.forEachNode((node) => {
            const missingFields = missingFieldsMap.get(
              node.data[objectIdField]
            );

            if (missingFields) {
              const newData = {
                ...node.data,
                missingFields,
              };
              node.setData(newData);
            }
          });

          const fields = editableLayer.fields.filter((field) =>
            allMissingFields.has(field.name)
          );

          setRequiredFieldsMessage({
            show: true,
            fields: fields.map((f) =>
              t("layer.fieldAlias." + f.name + ".title", f.alias)
            ),
          });

          setTimeout(() => {
            setRequiredFieldsMessage({
              show: false,
              fields: [],
            });
          }, 3000);
          return;
        }
      }

      const baselineFields = editableLayer.layerConfig.baselineFields || [];
      const situationalFields =
        editableLayer.layerConfig.situationalFields || [];

      const { hasBaselineField, hasSituationalField } = getUpdatedFieldsTypes(
        columnIds,
        baselineFields,
        situationalFields
      );

      const newFeatures = featuresWithSavedValues.map((feat) => {
        const featId = feat.getObjectId();
        if (selectedFeatureIds.includes(featId)) {
          const clone = feat.clone();
          if (hasSituationalField) {
            clone.attributes[currAsOfDateFieldName] = now;
            nodeMap[featId].data[currAsOfDateFieldName] = now;
          }

          if (hasBaselineField && asOfDateFieldName in clone.attributes) {
            clone.attributes[asOfDateFieldName] = now;
            nodeMap[featId].data[asOfDateFieldName] = now;
          }

          featuresToSave.push(clone);
          const node = nodeMap[featId];

          const newData = JSON.parse(JSON.stringify(node.data));
          newData.featureSelect = false;
          newData.feature = clone;
          newData.missingFields = [];

          node.setData(newData);

          return clone;
        }

        return feat;
      });

      if (featuresToSave.length === 0) return;

      await applyEditsInBatches(editableLayer, featuresToSave);
      // await editableLayer.applyEdits({ updateFeatures: featuresToSave });

      updateCellStyles(gridApi, columnIds, true);

      editableLayer.refresh();

      let newOutDate = [];
      if (Object.keys(layerUpdate).length > 0) {
        newOutDate = layerUpdate.outdated.filter(
          (feat) => !selectedFeatureIds.includes(feat.getObjectId())
        );
      }

      if (Object.keys(layerUpdate).length > 0) {
        layerUpdate.features = newFeatures;
        layerUpdate.outdated = newOutDate;
        dispatch(
          addLayersUpdate({ id: editableLayer.layerConfig.id, layerUpdate })
        );
      }

      gridApi.applyTransaction({ update: Object.values(nodeMap) });
      gridApi.refreshCells({
        force: true,
      });
      gridApi.refreshHeader();

      setFeatures(newFeatures);
      setEditedFeatures({});
      setUpdateFeaturesIds([]);
      handleShowUpdateMessage(featuresToSave);
    } catch (err) {
      openSnackbar(t("screen.message.error"));
      console.warn(err);
    } finally {
      setLoading(false);
    }
  }, [
    layersUpdates,
    editableLayer,
    gridApi,
    editedFeatures,
    features,
    handleShowUpdateMessage,
  ]);

  //adding graphics to the graphics layer when selecting rows
  const onRowDataUpdated = useCallback(
    (params, zoomEnabled = true) => {
      const api = params.api;
      const featuresIdsSet = new Set(updateFeaturesIds);
      const batchData = [];

      // Check all rows for featureSelect state
      api.forEachNode((node) => {
        const objectId = node.data[editableLayer.objectIdField];

        const feature = node.data.feature;

        if (node.data?.featureSelect) {
          batchData.push({
            feature,
            graphic: null,
          });

          featuresIdsSet.add(objectId);
        } else {
          featuresIdsSet.delete(objectId);
        }
      });

      if (graphicsLayer) {
        graphicsLayer.graphics.forEach((graphic) => {
          const objectId = graphic.getObjectId();

          const isSelected = featuresIdsSet.has(objectId);

          if (isSelected) {
            const index = batchData.findIndex(
              (item) => item.feature.getObjectId() === objectId
            );
            if (index > -1) {
              batchData[index].graphic = graphic;
            }
            graphic.visible = true;
          } else {
            graphic.visible = featuresIdsSet.size === 0;
          }
        });
      }
      if (featuresIdsSet.size > 0) {
        setBatchUpdateFeatures(batchData);
        if (zoomEnabled) {
          zoomToFeaturesExtent(batchData.map((item) => item.graphic));
        }
      } else {
        if (zoomEnabled) {
          zoomToFeaturesExtent(graphicsLayer.graphics.toArray());
        }

        setBatchUpdateFeatures([]);
      }

      setUpdateFeaturesIds(Array.from(featuresIdsSet));
    },
    [editableLayer, updateFeaturesIds, graphicsLayer]
  );

  const onBatchUpdateSave = useCallback(
    (newFeatures) => {
      if (!gridApi) {
        console.warn("Table not initialized");
        return;
      }

      // Create map of new values by objectId
      const updatesMap = newFeatures.reduce((acc, feature) => {
        acc[feature.attributes[editableLayer.objectIdField]] =
          feature.attributes;
        return acc;
      }, {});

      setEditedFeatures({});
      const featuresWithNewValues = {};

      // Update grid nodes
      const rowUpdates = [];
      const columnIds = new Set();

      gridApi.forEachNode((node) => {
        const objectId = node.data[editableLayer.objectIdField];
        const newValues = updatesMap[objectId];

        if (newValues) {
          // const { [currAsOfDateFieldName]: removed, ...filteredValues } =
          //   newValues;

          Object.keys(newValues).forEach((key) => {
            const value = newValues[key];
            node.data.feature.attributes[key] = value;
            if (key === currAsOfDateFieldName) {
              newValues[key] = newValues[key];
            }
            columnIds.add(key);
          });

          const updatedData = {
            ...node.data,
            ...newValues,
            featureSelect: false,
            feature: node.data.feature.clone(),
          };
          updatedData.feature.attributes[currAsOfDateFieldName] =
            new Date().getTime();

          // updatedData.update = updateValueFormatter(
          //   updatedData,
          //   updatedData.lastCycleUpdate,
          //   updatedData.cycleUpdateDueDate
          // );

          featuresWithNewValues[objectId] = updatedData.feature;

          node.updateData(updatedData);
          rowUpdates.push(node);
        }
      });

      const updatedFeatures = features.map((feat) => {
        const objectId = feat.getObjectId();
        if (featuresWithNewValues[objectId]) {
          return featuresWithNewValues[objectId];
        } else {
          return feat;
        }
      });

      setFeatures(updatedFeatures);

      updateCellStyles(gridApi, Array.from(columnIds));
      // Apply updates to grid
      gridApi.applyTransaction({
        update: rowUpdates,
      });
      handleShowUpdateMessage(newFeatures);
    },
    [gridApi, editableLayer]
  );

  return {
    onRowDataUpdated,
    handleUpdateAll,
    onBatchUpdateSave,
    handleLayerSelection,
    toggleBatchUpdate,
    updateFeaturesIds,
    graphicsLayer,
    updatedFeatures,
    showUpdateMessage,
    requiredFieldsMessage,
    setSaveMessage,
    saveMessage,
    showBatchUpdate,
    gridApi,
    setGridApi,
    setFeatures,
    features,
    loading,
  };
};

export default useLayerEditTable;
