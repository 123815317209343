import defaultConfig from "../../../data/config.json";

export const getField = (layer, name) => {
  const layerField = layer.fields.filter((field) => field.name === name)[0];
  if (!layerField || layerField.length === 0) {
    console.warn(
      "warning: Report must refer a valid layer field. Layer: " +
        layer.title +
        ` Field: ${name} `
    );
    return [];
  }

  return layerField?.domain?.codedValues || [];
};

export const replaceURLParams = (params) => {
  if (!window.history.replaceState) return;
  const newURL = new URL(window.location.href);
  const newUrlParams = new URLSearchParams();

  params.forEach((param) => {
    newUrlParams.append(param.key, param.value.toLowerCase());
  });

  newURL.search = newUrlParams;
  // window.history.replaceState({ path: newURL.href }, '', newURL.href);
  window.history.pushState(window.history.state, "", newURL.href);
};

export const getReports = (config, activeModule) => {
  return (
    config.modules[activeModule]?.reports ??
    config.opDefaults.modules[activeModule]?.reports ??
    []
  );
};

export const getModulesWithReports = (config) => {
  const modules = new Set();
  if (!Array.isArray(config.activeModules)) return [];
  config.activeModules.forEach((module) => {
    const moduleReports =
      config.modules[module]?.reports ??
      config.opDefaults.modules[module]?.reports ??
      [];
    if (moduleReports.length > 0) {
      modules.add(module);
    }
  });

  return Array.from(modules);
};

export const getOperationsWithReports = (config) => {
  try {
    const operations = defaultConfig.ops.filter(
      (op) =>
        op.opStatus !== "inactive" &&
        Array.isArray(op.activeModules) &&
        op.activeModules.length > 0
    );
    const operationSet = new Set();
    operations.forEach((op) => {
      if (op && Array.isArray(op.activeModules)) {
        op.activeModules.forEach((module) => {
          let moduleReports = [];

          if (op.modules && op.modules[module]?.reports) {
            moduleReports = op.modules[module].reports;
          } else if (
            config.opDefaults &&
            config.opDefaults.modules[module]?.reports
          ) {
            moduleReports = config.opDefaults.modules[module].reports;
          }

          if (moduleReports.length > 0 && !op.hide) {
            operationSet.add(op.id);
          }
        });
      }
    });

    return operations.filter((op) => operationSet.has(op.id));
  } catch (err) {
    console.log(err);
  }
  return [];
};
