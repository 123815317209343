import styled from "styled-components";
import { MOBILE_SCREENS } from "../App/App";

export const HeaderContainer = styled.div`
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${(props) => (props.isEditorMode ? "#000" : "#fff")};
  align-items: flex-start;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  ${({ isEmbed }) =>
    isEmbed
      ? `
    a {
      pointer-events:none !important;
    }
  `
      : ""}

  & svg {
    path {
      fill: ${(props) => props.isEditorMode && "#fff"};
    }
  }

  @media screen and (max-width: 769px) {
    max-width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const HeaderLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: ${({ isEmbed }) => (isEmbed ? 31 : 70)}px;

  @media screen and (min-width: 769px) {
    height: 100%;
  }
`;

export const HeaderIcon = styled.div`
  display: flex;
  padding: 0.4em 0.8em;
  align-items: center;
  max-height: 54px;
  box-sizing: border-box;

  img {
    height: ${({ size }) => `${size}px` || "26px"};
    width: ${({ size }) => `${size}px` || "100%"};
  }

  & a {
    display: flex;
  }

  @media screen and (min-width: 769px) {
    svg#logisticsClusterSmallIcon {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1.1em 0.8em;
    svg {
      height: 20px;
      &#logisticsClusterIcon {
        display: none;
      }
    }
  }
  &.hiddenOnSmall {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    svg {
      width: 42px;
      height: 42px;
    }
  }
`;

export const ModuleIconWrapper = styled.div`
  display: flex;
  padding: 0.4em 0.8em;
  align-items: center;
  max-height: 54px;
  box-sizing: border-box;

  img {
    height: ${({ size }) => (size ? `${size}px` : "26px")};
    width: 100%; //${({ size }) => `${size}px` || "100%"};
  }

  & a {
    display: flex;
  }

  @media screen and (min-width: 769px) {
    svg#logisticsClusterSmallIcon {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1.1em 0.8em;
    svg {
      height: 20px;
      &#logisticsClusterIcon {
        display: none;
      }
    }
  }
  &.hiddenOnSmall {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    svg {
      width: 42px;
      height: 42px;
    }
  }
`;

export const HeaderCenter = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  height: 100%;
  gap: 0.5em;
  margin-right: ${({ hasModules }) => (hasModules ? "0.8em" : "")};

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

export const ModuleWrapper = styled.div`
  flex-grow: 4;
  display: flex;
  justify-content: flex-start;
  gap: 0.8em;
  padding-top: 0.2em;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: ${({ center }) => (center ? "center" : "flex-end")};
  align-items: center;
  gap: 4px;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;
    gap: 0.2em;
    justify-content: flex-end;

    & > h2 {
      display: ${({ isEmbed }) => (isEmbed ? "none" : "flex")};
      height: 100%;
      align-items: center;
      font-size: ${({ isLong }) => (isLong ? "18px" : "21px")};
      font-weight: 500;
    }

    & > h3,
    .subtitle {
      font-size: ${({ isEmbed, isSubtitleLong }) =>
        isEmbed || isSubtitleLong ? "12px" : "14px"};
      margin-bottom: 2px;
    }

    & > h2,
    & > h3,
    .subtitle {
      text-align: center;
    }
  }
`;

export const Title = styled.h2`
  color: ${(props) => (props.isEditorMode ? "#FFF" : "#393738")};
  line-height: 1;
  font-weight: 400;
  font-size: 24px;
  max-width: max-content;
  text-align: right;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 768px) and (max-width: 1210px) {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2em;
  }
  @media screen and (max-width: 450px) {
    font-size: 1em;
  }
`;

export const Subtitle = styled.h3`
  max-width: max-content;
  font-size: 12px;
  line-height: 1;
  color: #8c9ba5;
  margin: 0;
  font-weight: 400;
`;

export const IconVisibleWrapper = styled.div`
  padding: 0.4em 0.8em;
  display: flex;
  @media screen and (max-width: 768px) {
    & svg {
      height: ${({ isEmbed }) => (isEmbed ? "10px" : "12px")};
      path {
        fill: ${({ isEmbed, opsColor, isEditorMode }) =>
          isEditorMode ? "#fff" : isEmbed && opsColor ? opsColor : ""};
      }
    }
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const GetStartedButton = styled.button`
  padding: 4px 16px;
  gap: 8px;
  background: #cfe8ff;
  border: 1px solid #3b94d3;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #3b94d3;

  @media screen and (max-width: 1120px) {
    margin: 4px 0;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Separator = styled.div`
  height: 31px;
  left: 144px;
  top: 31.06px;
  margin: 0 24px 0;

  border: 1px solid #c5cdd2;

  @media screen and (max-width: 1120px) {
    margin: 0 8px 0;
  }
  @media screen and (max-width: 1070px) {
    margin-right: 8px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HeaderSlideContent = styled.div`
  &.subtitle {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }

  color: ${({ active }) => (active ? (props) => props.opsColor : "#6F7072")};
  font-weight: ${({ isEmbed }) => (isEmbed ? "500" : "700")};
  font-size: 0.8em;
  line-height: normal;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: ${({ isEmbed }) => (isEmbed ? "7" : "4")}px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    display: ${({ active, isEmbed }) =>
      active && !isEmbed ? "block" : "none"};
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.opsColor};
    top: calc(100% + 2px);
    left: 0;
  }

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    font-size: 0.7em;
  }
`;

export const SectionItem = styled.div`
  color: ${({ selected = true }) => (selected ? "#525252" : "#8a8a8a")};
  display: flex;
  height: 40px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  padding: 0 10px;
  gap: 12px;
  transition: all 0.2s;
  background: ${({ selected, opsColor = "#8080801a" }) =>
    selected ? `${opsColor}` : ""};
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;

  ${({ selected }) =>
    !selected
      ? `
    :hover {
     background: #8080801a;
    }
  `
      : ""};

  p {
    flex: 1;
    margin-top: 0;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    word-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: ${({ expand = true }) => (expand ? "block" : "none")};
    transition: all 0.3s;
  }
`;

export const HelpDropdownListItemIcon = styled.div`
  width: 14px;
  height: 14px;
  svg {
    path {
      //fill: #525252;
    }
  }
`;

export const HelpDropdownListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;

  & > div,
  & > span {
    pointer-events: none;
  }
`;

export const StyledMobileHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 8px;
  width: 100%;
  background: var(--opsColor);
  height: 40px;
  min-height: 40px;
  box-sizing: border-box;

  span {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledMobileHeader = styled.div`
  position: absolute;
  z-index: 6;
  top: ${({ isHeaderShown }) => (!isHeaderShown ? "8px" : "45px")};
  width: 100%;
  box-sizing: border-box;
  padding: ${({ isSearchWidgetDisplayed }) =>
    isSearchWidgetDisplayed ? "0 60px 0 8px" : "0 8px 0 8px"};
  min-height: 45px;
  display: flex;
  gap: 8px;

  .btns {
    display: flex;
    align-items: center;
    gap: 8px;
    opacity: ${({ expanded }) => (!expanded ? "1" : "0")};
    pointer-events: ${({ expanded }) => (!expanded ? "auto" : "none")};
    transition: all 0.3s;
  }
`;

export const StyledMobileButton = styled.div`
  border-radius: 6px;
  background: #fff;

  padding: 8px;
  height: 40px;
  display: flex;
  justify-content: center;
  min-width: 40px;
  align-items: center;
  gap: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  box-sizing: border-box;

  span {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%; /* 18.56px */
    margin: 0;
  }

  @media screen and (max-width: 400px) {
    &.lu__btn {
      padding: 4px;
      span {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 368px) {
    &.lu__btn {
      padding: 3px;
      span {
        font-size: 11px;
      }
    }
  }

  svg {
    transition: all 0.2s;
  }
`;

export const StyledWidgetButton = styled.div`
  border-radius: 6px;
  background: #fff;
  position: relative;
  padding: 8px;
  height: 40px;
  display: flex;
  justify-content: center;
  min-width: 40px;
  align-items: center;
  gap: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  box-sizing: border-box;
  user-select: none;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  span {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%; /* 18.56px */
    margin: 0;
    pointer-events: none;
  }

  svg {
    pointer-events: none;
  }

  @media screen and (max-width: 400px) {
    &.lu__btn {
      padding: 4px;
      span {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 368px) {
    &.lu__btn {
      padding: 3px;
      span {
        font-size: 11px;
      }
    }
  }

  svg {
    transition: all 0.2s;
  }
`;

export const StyledMobileDropdown = styled.div`
  position: relative;
  button {
    min-width: 107px;
  }
`;

export const StyledMobileDropdownBody = styled.div`
  width: max-content;
  max-width: 150px;
  min-width: 100%;
  display: flex;
  padding: 6px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Base-white, #fff);
  box-shadow: 0 2px 4px 0 rgba(146, 146, 146, 0.25);
  position: absolute;
  top: calc(100% + 9px);
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: all 0.2s;
`;

export const StyledMobileDropdownItem = styled.div`
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: ${({ selected }) => (selected ? "#3440541a" : "inherit")};
  cursor: pointer;
  transition: all 0.2s;

  :hover {
    background: #34405412;
  }
`;

export const StyledMobileSearchWrapper = styled.div`
  width: calc(100% - 16px);

  .search__box {
    input {
      background: ${({ mobileScreen }) =>
        mobileScreen === MOBILE_SCREENS.MAP ? "#fff" : "#696a6e1c"};
      color: #696a6e;
    }
  }
`;

export const StyledDesktopHeader = styled.div`
  position: fixed;
  left: 0;
  z-index: 6;
  top: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px 0 8px;
  min-height: 45px;
  display: flex;
  gap: 8px;
  pointer-events: none;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  padding: 6px 8px;
  box-sizing: border-box;
  border-radius: 100px;
  pointer-events: auto;

  > a {
    display: flex;
  }

  > span {
    color: #000000;
    font-size: 17px;
    line-height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledWidgetsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  background: #ffffff;
  border-radius: 8px;
  padding: 0px 8px;
  pointer-events: auto;
  position: relative;
  transition: all 0.3s;
  max-height: 54px;
  min-height: 54px;

  :empty {
    display: none;
  }
`;
