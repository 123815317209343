import { CalciteLoader } from "@esri/calcite-components-react";
import { loadModules } from "esri-loader";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeMask } from "../../../esri/widgets/print";
import {
  setPrintWidgetFinish,
  setPrintWidgetLoading,
  setPrintWidgetStep,
} from "../../../redux/action/PrintWidget-action";
import { view } from "../../../utils/API";
import {
  applyZoomLevelFilter,
  getOpsColor,
  removeZoomLevelFilter,
  ROLE_EDITOR,
} from "../../../utils/helper";
import { getLayerSymbology } from "../../../utils/symbologies";
import CustomSwiper, { CustomSwiperSlide } from "../../CustomSwiper";
import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../Panel/components/Pagination/helpers";
import {
  StyledArrowButton,
  StyledSubmitButton,
} from "../../Report/new/Footer/Footer-styled";
import ProgressBar from "../../Report/new/ProgressBar/ProgressBar";
import {
  ProgressLoader,
  StyledProgressBar,
} from "../../Report/new/ProgressBar/ProgressBar-styled";
import { useSnackbar } from "../../SnackBar";
import ConfigureMap from "../ExportPages/ConfigureMap";
import CustomizeMapDetails from "../ExportPages/CustomizeMapDetails";
import DisclaimerConfigurations from "../ExportPages/DisclaimerConfigurations";
import DocumentSettings from "../ExportPages/DocumentSettings";
import Drawings from "../ExportPages/Drawings";
import FinaliseExport from "../ExportPages/FinaliseExport";
import { addElementsToPDF } from "../helpers";
import {
  StyledBody,
  StyledHeader,
  StyledLoaderWrapper,
  StyledSidebarWrapper,
} from "./SidebarMenu-styled";

export const stepsValues = {
  1: 1,
  2: 2.7,
  3: 4,
};

export const transition = {
  1: 1,
  2: 5,
  3: 1.5,
};

// Create a utility function to optimize base64 images
const optimizeImageData = (imageData, maxSize = 24) => {
  // Convert base64 to image
  const img = new Image();
  img.src = `data:image/png;base64,${imageData}`;

  // Create canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Set maximum dimensions while maintaining aspect ratio
  const ratio = img.width / img.height;
  let width = maxSize;
  let height = maxSize;

  if (ratio > 1) {
    height = width / ratio;
  } else {
    width = height * ratio;
  }

  canvas.width = width;
  canvas.height = height;

  // Draw and compress
  ctx.drawImage(img, 0, 0, width, height);

  // Get compressed base64 string (0.7 quality)
  return canvas.toDataURL("image/png", 0.7).split(",")[1];
};

const SidebarMenu = ({
  config,
  mapData,
  onMapDataChange,
  page,
  setPage,
  setDpi,
  dpi,
  setZoomMap,
  getPrintableData,
  zoomMap,
  zoomOut,
  setZoomOut,
  setDrawings,
  drawings,
  // setAddPublicCustomizations,
  addPublicCustomizations,
  setSymbologyCombinations,
}) => {
  const { t } = useTranslation("common");
  const abortControllers = useRef([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPending, setTransition] = useTransition();
  const { step, isLoading, printOption, open } = useSelector(
    (state) => state.printWidget
  );
  const { activeModule } = useSelector((state) => state);

  useEffect(() => {
    return () => {
      abortControllers.current.forEach((c) => {
        c.abort();
      });
    };
  }, []);

  const [openSnackbar] = useSnackbar({
    position: "top-center",
  });
  const swiperRef = useRef();
  const zoomInView = useRef();
  const zoomOutView = useRef();

  const dispatch = useDispatch();

  const handleStepChange = useCallback(
    (step) => {
      dispatch(setPrintWidgetStep(step));
    },
    [dispatch]
  );

  const setLoading = useCallback((v) => {
    dispatch(setPrintWidgetLoading(v));
  }, []);

  const removeZoomListeners = useCallback(() => {
    if (zoomInView.current && Array.isArray(zoomInView.current.listeners)) {
      zoomInView.current.listeners.forEach((l) => {
        l.remove();
      });
    }
  }, []);

  const assignSymbologyCombinations = useCallback(async () => {
    try {
      const symbologyCombinations = {};
      for (const layer of view.map.layers) {
        if (layer.renderer) {
          const layerView = await view.whenLayerView(layer);

          if (layerView.layer.visible && layerView.createQuery) {
            const query = layerView.createQuery();
            const res = await layerView.queryFeatures(query);
            const existingValues = {};

            if (res.features.length > 0) {
              // console.log(layer);

              // const symbology = getLayerSymbology(layer, config) || {};
              // const { colorMap, iconMap } = symbology;
              // console.log(colorMap, iconMap);

              const iconDefinition = layer.renderer.getIconDefinitions();
              const colorDefinition = layer.renderer.getColorDefinitions();
              const iconFields = [];
              const colorFields = [];

              if (iconDefinition.length > 0) {
                const { field } = iconDefinition[0];
                // console.log({ iconDefinition });
                field.map((f) => iconFields.push(f.name));
              }

              if (colorDefinition.length > 0) {
                const { field } = colorDefinition[0];
                // console.log({ colorDefinition });

                field.map((f) => colorFields.push(f.name));
              }

              // let fields = [...iconFields, ...colorFields];
              const available = new Set();
              res.features.forEach((f) => {
                const attributes = f.attributes;
                const iconFieldValues = [];
                const colorFieldValues = [];
                iconFields.forEach((iconField) => {
                  const value =
                    attributes[iconField] == null
                      ? "NULL"
                      : attributes[iconField];
                  iconFieldValues.push(value);
                });

                colorFields.forEach((colorField) => {
                  const value =
                    attributes[colorField] == null
                      ? "NULL"
                      : attributes[colorField];
                  colorFieldValues.push(value);
                });

                const combinedValues = [
                  ...iconFieldValues,
                  ...colorFieldValues,
                ].filter(Boolean);
                if (combinedValues.length > 0) {
                  available.add(combinedValues.join(","));
                }
              });

              // console.log(available);

              // fields.forEach((fieldName) => {
              //   existingValues[fieldName] = new Set();
              // });
              // console.log(res.features);

              // res.features.forEach((f) => {
              //   const attributes = f.attributes;
              //   fields.forEach((fieldName) => {
              //     existingValues[fieldName].add(attributes[fieldName]);
              //   });
              // });

              // const createNewSymbolMap = (fields, map) => {
              //   if (fields.length > 0 && map?.default) {
              //     const filteredMap = {};
              //     const hasMultiple = fields.length > 1; // Check if it's an icon map (has multiple fields)

              //     if (hasMultiple) {
              //       // Handle icon map with combined keys (e.g. "0,3")
              //       const fieldValues = fields.map((field) =>
              //         Array.from(existingValues[field] || new Set())
              //       );

              //       // Check each combination in the default map
              //       Object.keys(map.default).forEach((key) => {
              //         if (key.startsWith("*")) {
              //           // Handle wildcard cases
              //           const [_, secondValue] = key.split(",");
              //           fieldValues[1].forEach((secondFieldValue) => {
              //             if (secondFieldValue.toString() === secondValue) {
              //               filteredMap[key] = map.default[key];
              //             }
              //           });
              //         } else {
              //           // Handle specific combinations
              //           const [firstValue, secondValue] = key.split(",");
              //           const firstFieldExists = fieldValues[0].some(
              //             (v) => v.toString() === firstValue
              //           );
              //           const secondFieldExists = fieldValues[1].some(
              //             (v) => v.toString() === secondValue
              //           );

              //           if (firstFieldExists && secondFieldExists) {
              //             filteredMap[key] = map.default[key];
              //           }
              //         }
              //       });
              //     } else {
              //       // Handle simple color map
              //       fields.forEach((field) => {
              //         if (existingValues[field]) {
              //           const values = existingValues[field];
              //           values.forEach((value) => {
              //             const key = value === null ? "NULL" : value.toString();
              //             if (map.default[key]) {
              //               filteredMap[key] = map.default[key];
              //             }
              //           });
              //         }
              //       });
              //     }

              //     return {
              //       ...map,
              //       default: filteredMap,
              //     };
              //   }

              //   return map;
              // };

              // const filterDefinitions = (iconDefinitions, existingValues) => {
              //   return iconDefinitions.filter((definition) => {
              //     // Get the field names from the definition
              //     const fieldNames = definition.field.map((f) => f.name);

              //     if (fieldNames.length === 1) {
              //       // Handle single field case
              //       const fieldName = fieldNames[0];
              //       const value = definition.value;

              //       // Handle NULL case
              //       if (value === "NULL") {
              //         return Array.from(
              //           existingValues[fieldName] || new Set()
              //         ).some((v) => v === null);
              //       }

              //       // Handle normal single value case
              //       return Array.from(existingValues[fieldName] || new Set()).some(
              //         (v) => v?.toString() === value
              //       );
              //     } else {
              //       // Handle multiple fields case (original logic)
              //       const [firstField, secondField] = fieldNames;
              //       const [firstValue, secondValue] = definition.value.split(",");

              //       if (firstValue === "*") {
              //         // Handle wildcard cases - only check second value
              //         const secondFieldValues = Array.from(
              //           existingValues[secondField] || new Set()
              //         );
              //         return secondFieldValues.some(
              //           (v) => v?.toString() === secondValue
              //         );
              //       } else {
              //         // Handle specific combinations
              //         const firstFieldValues = Array.from(
              //           existingValues[firstField] || new Set()
              //         );
              //         const secondFieldValues = Array.from(
              //           existingValues[secondField] || new Set()
              //         );

              //         const firstFieldExists = firstFieldValues.some(
              //           (v) => v?.toString() === firstValue
              //         );
              //         const secondFieldExists = secondFieldValues.some(
              //           (v) => v?.toString() === secondValue
              //         );

              //         return firstFieldExists && secondFieldExists;
              //       }
              //     }
              //   });
              // };

              // const newColorMap = createNewSymbolMap(colorFields, colorMap);
              // console.log("newcolormap", newColorMap);

              // const newIconMap = createNewSymbolMap(iconFields, iconMap);
              // console.log("newiconmap", newIconMap);

              // const filteredIconDefinitions = filterDefinitions(
              //   iconDefinition,
              //   existingValues
              // );
              // console.log("Filtered icon definitions:", filteredIconDefinitions);

              // const filteredColorDefinitions = filterDefinitions(
              //   colorDefinition,
              //   existingValues
              // );

              // const availableCombinations = new Set();
              // filteredIconDefinitions.forEach((iconDef) => {
              //   const iconDefValue = iconDef.value;
              //   filteredColorDefinitions.forEach((colorDef) => {
              //     const colorDefValue = colorDef.value;
              //     availableCombinations.add(`${iconDefValue},${colorDefValue}`);
              //   });
              // });
              // console.log("Filtered color definitions:", filteredColorDefinitions);
              // console.log(availableCombinations);
              symbologyCombinations[layer.id] = available;
              // console.log(existingValues);

              // const newSymbology = {
              //   ...symbology,
              //   colorMap: newColorMap,
              //   iconMap: newIconMap,
              // };

              // addSymbology(layerView.layer, config, t, newSymbology);
            }
          }
        }
      }

      setSymbologyCombinations(symbologyCombinations);
    } catch (error) {
      console.error("Error assigning symbology combinations:", error);
    }
  }, []);

  let timer;
  const onDownload = async () => {
    removeZoomListeners();
    handleStepChange(1);
    const viewDiv = document.querySelector("#viewDiv");
    try {
      const preview = document.querySelector("#pdfMap");
      if (!preview) return;
      const previewWidth = preview.getBoundingClientRect().width;
      const previewHeight = preview.getBoundingClientRect().height;

      const dims = { width: previewWidth, height: previewHeight };

      const printableData = await getPrintableData();
      const [print, PrintTemplate, PrintParameters, MapView] =
        await loadModules([
          "esri/rest/print",
          "esri/rest/support/PrintTemplate",
          "esri/rest/support/PrintParameters",
          "esri/views/MapView",
        ]);
      const promises = [];
      const { zoom } = printableData;

      if (viewDiv) {
        viewDiv.style.pointerEvents = "none";
      }
      await assignSymbologyCombinations();

      if (zoom.selected) {
        promises.push({
          key: "zoom",
          promise: fetchZoomMap({
            PrintTemplate,
            PrintParameters,
            print,
            view: zoomInView.current,
            size: zoom.size,
            orientation: zoom.orientation,
          }),
        });

        const pos = preview.getBoundingClientRect();
        const zoomDiff = view.zoom - zoomInView.current.zoom;

        if (Math.floor(zoomDiff) <= 0) {
          const zoomPoint = {
            x: zoomInView.current.extent.xmin,
            y: zoomInView.current.extent.ymax,
            spatialReference: {
              wkid: zoomInView.current.extent.spatialReference.wkid,
            },
          };

          const zoomPoint2 = {
            x: zoomInView.current.extent.xmax,
            y: zoomInView.current.extent.ymin,
            spatialReference: {
              wkid: zoomInView.current.extent.spatialReference.wkid,
            },
          };

          const zoomCoordinates = {
            x: view.toScreen(zoomPoint).x - pos.x,
            y:
              view.toScreen(zoomPoint).y -
              10 -
              (printableData.page.orientation === "Portrait" ? 55 : 0),
          };

          const helper = document.querySelector("#zoom-helper");
          if (helper) {
            helper.style.left = zoomCoordinates.x + "px";
            helper.style.top = zoomCoordinates.y + "px";
            helper.style.width =
              view.toScreen(zoomPoint2).x - view.toScreen(zoomPoint).x + "px";
            helper.style.height =
              view.toScreen(zoomPoint2).y - view.toScreen(zoomPoint).y + "px";
            helper.style.transform = "scale(1.4)";
            printableData.zoom.coordinates = {
              x: helper.getBoundingClientRect().x - pos.x,
              y:
                helper.getBoundingClientRect().y -
                10 -
                (printableData.page.orientation === "Portrait" ? 55 : 0),
              width: helper.getBoundingClientRect().width,
              height: helper.getBoundingClientRect().height,
            };
          }
        }
      }

      if (printableData.zoomOut.active) {
        promises.push({
          key: "zoomOut",
          promise: fetchZoomMap({
            PrintTemplate,
            PrintParameters,
            print,
            view: zoomOutView.current,
          }),
        });
      }

      view.map.layers.forEach((l) => {
        if (l.layerConfig && l.layerConfig.labelField) {
          l.labelingInfo.forEach((info) => {
            info.symbol.font.size = 7;
            info.symbol.haloSize = 1;
          });
        }
      });

      timer = setTimeout(() => {
        handleStepChange(2);
      }, 2000);

      if (viewDiv) {
        viewDiv.style.removeProperty("pointer-events");
      }

      //moved setting loading to request interceptor as we have to calculate view dimensions before sidebar and other widgets appear on screen
      const [main, ...rest] = await Promise.all([
        fetchMainMap({ PrintTemplate, PrintParameters, print, MapView }),
        ...promises.map((item) => item.promise),
      ]);

      clearTimeout(timer);
      view.map.layers.forEach((l) => {
        if (l.layerConfig && l.layerConfig.labelField) {
          l.labelingInfo.forEach((info) => {
            info.symbol.font.size = 9;
            info.symbol.haloSize = 2;
          });
        }

        applyZoomLevelFilter(l, config);
      });

      rest.map((item, index) => {
        printableData[promises[index].key].url = item.url;
      });

      handleStepChange(3);

      await addElementsToPDF(main.url, dims, printableData);
      openSnackbar(t("print.final.downloadComplete"), 15000);
    } catch (err) {
      // console.log(err);

      if (err.name !== "AbortError") {
        if (err.message === "Timeout exceeded") {
          openSnackbar(
            t("screen.message.timeoutError", { 1: "global.logie@wfp.org" }),
            15000
          );
        } else if (err.message && err.name) {
          openSnackbar(err.message, 15000);
        } else if (err.messages && Array.isArray(err.messages)) {
          const errorMessages = err.messages.filter(
            (err) => err.type === "error"
          );
          const descriptionErrors = Array.from(
            new Set(errorMessages.map((message) => message.description))
          );
          openSnackbar(descriptionErrors.join(" "), 15000);
        } else {
          openSnackbar(t("screen.message.error"), 15000);
        }
      }
      if (viewDiv) {
        viewDiv.style.removeProperty("pointer-events");
      }

      view.map.layers.forEach((l) => {
        if (l.layerConfig && l.layerConfig.labelField) {
          l.labelingInfo.forEach((info) => {
            info.symbol.font.size = 9;
          });
        }
        applyZoomLevelFilter(l, config);
      });
    } finally {
      handleClose();
      setLoading(false);
      clearTimeout(timer);
    }
  };

  const fetchMainMap = async ({ PrintTemplate, PrintParameters, print }) => {
    const template = new PrintTemplate({
      format: "pdf",
      attributionVisible: false,
      exportOptions: {
        dpi: 300,
      },
      layout: "",
      layoutOptions: {
        titleText: "",
        authorText: mapData.mapAuthor,
        copyright: config?.mapCpr,
        title: mapData.title,
        legendLayers: [],
      },
    });

    const promises = [];

    const params = new PrintParameters({
      view: view,
      template: template,
    });

    params.view.map.layers.forEach((layer) => {
      const layerViewPromise = params.view
        .whenLayerView(layer)
        .then(async (layerView) => {
          // If layer is updating, create a promise that resolves when updating is done
          if (layerView.updating) {
            await new Promise((resolve) => {
              const timeout = setTimeout(() => {
                clearInterval(checkUpdating);
                resolve();
              }, 45000); // 45 second timeout

              const checkUpdating = setInterval(() => {
                // console.log("checking updating");

                if (!layerView.updating) {
                  clearInterval(checkUpdating);
                  clearTimeout(timeout);
                  resolve();
                }
              }, 100); // Check every 100ms
            });
          }
          return layerView;
        });
      promises.push(layerViewPromise);
    });

    const layerViews = await Promise.all(promises);

    layerViews.forEach((lv) => {
      const sizeMap = getLayerSymbology(lv.layer, config)?.sizeMap;
      if (!!sizeMap && sizeMap.zoom && lv.layer.visible) {
        removeZoomLevelFilter(lv);
      }
    });

    const controller = new AbortController();
    abortControllers.current.push(controller);

    return await print.execute(config.printServiceUrl, params, {
      signal: controller.signal,
    });
  };

  const fetchZoomMap = async ({
    PrintTemplate,
    PrintParameters,
    print,
    view,
    size,
    orientation,
  }) => {
    let layout = "";
    if (size && orientation) {
      const layoutSize = size.substring(0, 1).toUpperCase();
      const layoutOrientation =
        orientation.substring(0, 1).toUpperCase() + orientation.substring(1);
      layout = `LogIE_LogisticsCluster_${layoutSize}_${layoutOrientation}_Zoom_Map`;
    } else {
      layout = "LogIE_LogisticsCluster_Zoom_Map";
    }

    const template = new PrintTemplate({
      format: "pdf",
      attributionVisible: false,
      exportOptions: {
        dpi: 300,
      },
      layout: layout,
      layoutOptions: {
        titleText: "",
        authorText: "",
      },
    });

    const params = new PrintParameters({
      view: view,
      template: template,
    });
    const controller = new AbortController();
    abortControllers.current.push(controller);
    return await print.execute(config.printServiceUrl, params, {
      signal: controller.signal,
    });
  };

  const handleClose = useCallback(() => {
    removeMask();
    dispatch(setPrintWidgetFinish());
    removeZoomListeners();
  }, [dispatch, removeZoomListeners]);

  const isEditor = useMemo(
    () =>
      config.role === ROLE_EDITOR || config.printMap.allowPublicPrintAsEditor,
    [config]
  );

  const handleDrawings = useCallback(
    (params) => {
      setTransition(() => {
        setDrawings((prev) => ({
          ...prev,
          ...params,
        }));
      });
    },
    [setDrawings]
  );

  const handleNext = useCallback(
    (customization) => {
      let activeIndex = swiperRef.current.swiper.activeIndex;
      const isCustom = customization || addPublicCustomizations;
      if (!isCustom && activeIndex === 2 && !isEditor) {
        swiperRef.current.swiper.slideTo(6);
      } else {
        swiperRef.current.swiper.slideNext();
      }

      activeIndex = swiperRef.current.swiper.activeIndex;
      setCurrentSlide(activeIndex);

      if (isEditor || isCustom) {
        const index = isEditor ? 2 : 3;
        if (activeIndex === index) {
          handleDrawings({ active: true });
        } else if (activeIndex > index && drawings.active) {
          handleDrawings({ viewModeEnabled: true });
        }
      }
    },
    [isEditor, handleDrawings, drawings, addPublicCustomizations]
  );

  const handlePrev = useCallback(() => {
    let activeIndex = swiperRef.current.swiper.activeIndex;
    if (!addPublicCustomizations && activeIndex === 6 && !isEditor) {
      swiperRef.current.swiper.slideTo(2);
    } else {
      swiperRef.current.swiper.slidePrev();
    }

    swiperRef.current.swiper.slidePrev();

    // if (addPublicCustomizations && activeIndex === 3 && !isEditor) {
    //   // setAddPublicCustomizations(false);
    // }

    activeIndex = swiperRef.current.swiper.activeIndex;
    setCurrentSlide(activeIndex);

    if (isEditor || addPublicCustomizations) {
      const index = isEditor ? 1 : 2;
      // console.log(
      //   swiperRef.current.swiper.activeIndex,
      //   slides,
      //   slides[swiperRef.current.swiper.activeIndex]
      // );
      // console.log(activeIndex === index, activeIndex === index + 1);
      // const currSlide = slides[swiperRef.current.swiper.activeIndex];

      if (activeIndex === index && drawings.active) {
        handleDrawings({
          active: false,
          elements: drawings.api.getSceneElements(),
        });
      } else if (activeIndex === index + 1 && drawings.active) {
        handleDrawings({ viewModeEnabled: false });
      }
    }
  }, [drawings, isEditor, handleDrawings, addPublicCustomizations]);

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [config, activeModule]
  );

  const slides = useMemo(() => {
    const allSlides = [
      {
        id: "documentSettings",
        title: t("print.documentSettings.title"),
        component: (
          <DocumentSettings
            config={config}
            dpi={dpi}
            setDpi={setDpi}
            page={page}
            setPage={setPage}
            t={t}
          />
        ),
      },
      {
        id: "setupMapView",
        title: t("print.setUpMapView.title"),
        component: (
          <ConfigureMap
            zoomViewRef={zoomInView}
            zoomMap={zoomMap}
            setZoomMap={setZoomMap}
            setZoomOut={setZoomOut}
            zoomOutViewRef={zoomOutView}
            config={config}
            zoomOut={zoomOut}
            t={t}
          />
        ),
      },
    ];

    if (isEditor) {
      allSlides.push(
        ...[
          {
            id: "drawings",
            title: t("print.drawings.title"),
            component: <Drawings t={t} drawings={drawings} config={config} />,
          },
          {
            id: "mapDetails",
            title: t("print.mapDetails.title"),
            component: (
              <CustomizeMapDetails
                mapData={mapData}
                config={config}
                isEditor={true}
                onMapDataChange={onMapDataChange}
                t={t}
                addCustomizations={addPublicCustomizations}
              />
            ),
          },
        ]
      );
    } else if (addPublicCustomizations) {
      allSlides.push(
        ...[
          {
            id: "drawings",
            title: t("print.drawings.title"),
            component: <Drawings t={t} drawings={drawings} config={config} />,
          },
          {
            id: "disclaimer",
            title: t("print.mapDetails.title"),
            component: (
              <StyledBody opsColor={opsColor}>
                <DisclaimerConfigurations
                  index={1}
                  t={t}
                  config={config}
                  isEditor={isEditor}
                  onMapDataChange={onMapDataChange}
                  mapData={mapData}
                />
              </StyledBody>
            ),
          },
        ]
      );
    }

    allSlides.push({
      id: "final",
      title: t("print.final.title"),
      component: (
        <FinaliseExport
          config={config}
          onDownload={onDownload}
          loading={isLoading}
          t={t}
        />
      ),
    });

    if (!isEditor && addPublicCustomizations) {
      return [
        ...allSlides.slice(0, 1),
        {
          id: "branding",
          title: t("print.branding.title"),
          component: (
            <CustomizeMapDetails
              mapData={mapData}
              config={config}
              isEditor={isEditor}
              onMapDataChange={onMapDataChange}
              t={t}
              addCustomizations={addPublicCustomizations}
            />
          ),
        },
        ...allSlides.slice(1),
      ];
    }

    return allSlides;
  }, [
    config,
    mapData,
    onMapDataChange,
    page,
    setPage,
    setDpi,
    dpi,
    setZoomMap,
    getPrintableData,
    zoomMap,
    setZoomOut,
    setDrawings,
    drawings,
    isLoading,
    isEditor,
    t,
    addPublicCustomizations,
    // setAddPublicCustomizations,
    handleNext,
    opsColor,
  ]);

  return (
    <StyledSidebarWrapper>
      <StyledHeader>
        <ProgressBar
          total={slides.length - 1}
          currentPosition={currentSlide}
          color={opsColor}
        />
        <p style={{ textWrap: "wrap", textAlign: "left" }}>
          {slides[currentSlide].title}
        </p>
      </StyledHeader>
      <StyledArrowButton
        radius={4}
        onClick={handleClose}
        style={{
          cursor: "pointer",
          minHeight: "auto",
          position: "absolute",
          top: "15px",
          right: "0px",
          zIndex: 2,
        }}
      >
        <LeftArrowIcon color="#ffffff" width={12} height={12} />
      </StyledArrowButton>
      <StyledLoaderWrapper loading={isLoading || step > 0} opsColor={opsColor}>
        {isLoading || step > 0 ? (
          <CalciteLoader />
        ) : (
          <div style={{ height: 192 }} />
        )}
        <div
          style={{
            width: "90%",
            height: 10,
            borderRadius: 10,
            overflow: "hidden",
            background: "#a8b1b780",
            marginTop: -30,
            position: "relative",
          }}
        >
          <ProgressLoader color={opsColor} />
          <StyledProgressBar
            style={{ transition: `all ${transition[step]}s` }}
            bgcolor={opsColor}
            progress={(100 / 4) * (stepsValues[step] ?? 0)}
          />
        </div>
        <p>{t("print.final.loadingSteps." + step)}</p>
      </StyledLoaderWrapper>
      <CustomSwiper
        ref={swiperRef}
        prevent-interaction-on-transition
        no-swiping
        allow-touch-move={false}
        prevent-clicks={false}
        simulate-touch={false}
        prevent-clicks-propagation={false}
        virtual="true"
      >
        {slides.map((slide, index) => (
          <CustomSwiperSlide key={`${slide.title}-${index}`} tabIndex={index}>
            {!isLoading &&
              step === 0 &&
              currentSlide === index &&
              slide.component}
          </CustomSwiperSlide>
        ))}
      </CustomSwiper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 10px",
          alignItems: "center",
        }}
      >
        <div
          style={{ borderRadius: "4px", overflow: "hidden", display: "flex" }}
        >
          <StyledArrowButton
            disable={currentSlide === 0}
            bg={opsColor ?? "#b21b0c"}
            style={{
              borderBottomLeftRadius: 6,
              borderTopLeftRadius: 6,
              overflow: "hidden",
            }}
            onClick={handlePrev}
          >
            <RightArrowIcon width={18} color="#FFFFFF" />
          </StyledArrowButton>
          <div
            style={{ width: "1px", height: "100%", background: "#F3F3F3" }}
          />
          <StyledArrowButton
            disable={currentSlide === slides.length - 1}
            bg={opsColor ?? "#b21b0c"}
            style={{
              borderBottomRightRadius: 6,
              borderTopRightRadius: 6,
              overflow: "hidden",
            }}
            onClick={() => handleNext()}
          >
            <LeftArrowIcon width={18} color="#FFFFFF" />
          </StyledArrowButton>
        </div>
        <StyledSubmitButton
          disable={currentSlide === slides.length - 1}
          onClick={() => handleNext()}
          bg={opsColor}
        >
          {t("screen.popup.actions.next")}
        </StyledSubmitButton>
      </div>
    </StyledSidebarWrapper>
  );
};

export default SidebarMenu;
