import styled from "styled-components";

export const StyledArrowButton = styled.button`
  min-height: 34px;
  text-decoration: none;
  border-width: 0;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${({ disable, show }) => (show ? 0 : disable ? 0.5 : 1)};
  pointer-events: ${({ disable, show }) => (show || disable ? "none" : "auto")};
  display: flex;
  align-items: center;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "")};
  background-color: ${({ bg }) => (bg ? `${bg}e6` : "transparent")};
  color: #fff;

  &:hover {
    background-color: ${({ bg }) => bg};
  }

  :disabled {
    background-color: ${({ bg }) => (bg ? `${bg}7f` : "#ffffff7f")};
  }
`;

export const StyledSubmitButton = styled.button`
  line-height: normal;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 4px;
  text-transform: none;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ color, bg }) => color || bg || "var(--opsColor)"};
  background-color: ${({ bg }) => (bg ? bg : "var(--opsColor)")};
  text-decoration: none;
  color: ${({ color }) => (color ? color : "#ffffff")};
  cursor: pointer;
  opacity: ${({ disable }) => (disable ? 0.7 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  transition: all 0.5s;
  border-radius: 9999px;
  padding: 0 16px 0 16px;
  
  ${({ height }) =>
    !!height
      ? `
      height:${height}px
    `
      : `
      height: 40px;
      min-height: 34px;
    `}};

  ${({ width }) => (!!width ? `width:${width}px` : `min-width: 78px`)};

  @media screen and (min-width: 769px) {
    background-color: ${({ bg }) => (bg ? bg : "var(--opsColor)")};
    
    &:hover {
      background-color: ${({ bg }) => (bg ? bg : "var(--opsColor)")};
    }
  }
  //for tag manager
  span {
    pointer-events: none;
  }
`;

export const StyledFooter = styled.div`
  position: sticky;
  width: 100%;
  flex: 0 0 2;
  margin-top: auto;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const StyledFooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 7px;
  height: 48px;
  border-top: 1px solid #dfe6eb;
`;
