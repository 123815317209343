import styled from "styled-components";

export const MobileListTabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px 6px;
  overflow: auto;
`;

export const MobileListTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.2s;
  border: 3px solid
    ${({ selected, color }) => (selected ? color ?? "var(--opsColor)" : "#eee")};
  border-radius: 100px;
  min-width: 40px;
  cursor: pointer;
`;

export const MobileListBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: #f5f5f5;
  padding: 6px 0;
  flex: 1;
  overflow: auto;
`;
