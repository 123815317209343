import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { view } from "../../../../../utils/API";
import { StyledTitle } from "../../Editor-styled";
import { EditorContext } from "../../EditorContextProvider";
import Loader from "../../Loader/Loader";
import EnterCoordinates from "./EnterCoordinates/EnterCoordinates";
import ManualSelection from "./EnterCoordinates/ManualSelection";
import NextButton from "./EnterCoordinates/NextButton";

const SituationalRoads = ({ handleNext, config, editableLayers }) => {
  const [loading, setLoading] = useState(false);
  const [addingPoint, setAddingPoint] = useState(false);
  const [count, setCount] = useState({});
  const { t } = useTranslation("common");
  const {
    editableLayer,
    setTrimmedSituationalFeatures,
    setAddedPoints,
    addedPoints,
  } = useContext(EditorContext);

  useEffect(() => {
    let timer;
    const addLayerToGroup = (layersByLabel, editedLayer, l) => {
      if (layersByLabel[editedLayer]) {
        layersByLabel[editedLayer].groupedIds.push(l.id);
      } else {
        const layerCandidates = view.map.layers.filter((layer) => {
          const lc = layer.layerConfig;
          return (
            layer.capabilities?.operations?.supportsAdd &&
            (lc?.id === editedLayer || lc?.alias === editedLayer)
          );
        });

        if (layerCandidates && layerCandidates.length > 0) {
          layersByLabel[editedLayer] = layerCandidates.at(0);
          layersByLabel[editedLayer].groupedIds = [l.id];
        } else {
          console.warn(
            "Batch editor configuration error on layer: " +
              l.layerConfig.alias +
              ". The edited layer is in-existent or not editable: " +
              editedLayer
          );
        }
      }
    };

    /**
     * Group visible layers for the layers that have the same batchEditor.editedLayer attribute
     */
    const groupLayers = (visibleLayers) => {
      const layersByLabel = {};
      visibleLayers
        .filter((l) => l.visible && l.layerConfig?.batchEditor?.editedLayer)
        .forEach((l) => {
          const editedLayer = l.layerConfig?.batchEditor?.editedLayer;

          if (Array.isArray(editedLayer)) {
            editedLayer.forEach((editedLayer) => {
              addLayerToGroup(layersByLabel, editedLayer, l);
            });
          } else {
            addLayerToGroup(layersByLabel, editedLayer, l);
          }
        });

      return layersByLabel;
    };

    timer = setTimeout(() => {
      try {
        groupLayers(editableLayers);
      } catch (err) {
        console.log(err);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [editableLayers]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: 14,
      }}
    >
      {loading && (
        <Loader
          textStyles={{
            position: "absolute",
            borderRadius: "8px",
            padding: "0px",
            color: config.opsColor,
            fontSize: 18,
          }}
          // text={count}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledTitle fontSize="20px" padding="0px 18px" textAlign="center">
          <span
            style={{
              minWidth: 20,
              minHeight: 20,
              marginRight: 4,
              position: "relative",
              top: 5,
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Route">
                <path d="M21.792,17.086c-.58-.58-1.16-1.17-1.75-1.75-.08-.08-.16-.17-.25-.25a.492.492,0,0,0-.7,0,.5.5,0,0,0,0,.71l1.14,1.14H9.282a2.22,2.22,0,0,1,0-4.44h3a3.215,3.215,0,1,0,0-6.43H7.012a2.5,2.5,0,1,0,0,1h5.27a2.215,2.215,0,1,1,0,4.43h-3a3.22,3.22,0,1,0,0,6.44h10.96l-.9.9c-.09.08-.17.17-.25.25a.5.5,0,0,0,0,.71.511.511,0,0,0,.7,0l1.75-1.75.25-.25A.5.5,0,0,0,21.792,17.086ZM4.562,8.066a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,4.562,8.066Z"></path>
              </g>
            </svg>
          </span>
          {t("screen.widget.Editor.manager.routeSelection.title")}
        </StyledTitle>
        <StyledTitle
          fontWeight={400}
          fontSize="12px"
          padding="0px 18px"
          textAlign="center"
        >
          {t("screen.widget.Editor.manager.routeSelection.subtitle1")}
        </StyledTitle>
        <StyledTitle
          fontWeight={400}
          fontSize="11px"
          padding="0px 18px"
          textAlign="center"
        >
          {t("screen.widget.Editor.manager.routeSelection.subtitle2")}{" "}
          <span style={{ background: "yellow", padding: "0px 2px" }}>
            {t("screen.widget.Editor.manager.routeSelection.subtitle2Color")}
          </span>
        </StyledTitle>
      </div>
      <EnterCoordinates
        editableLayer={editableLayer}
        setLoading={setLoading}
        addingPoint={addingPoint}
        setAddingPoint={setAddingPoint}
        enabled={config.editorRouteApiEnabled}
        setCount={setCount}
      />
      <ManualSelection
        editableLayer={editableLayer}
        setLoading={setLoading}
        onStart={() => {
          setAddingPoint(true);
        }}
        onComplete={() => {
          setAddingPoint(false);
        }}
      />
      <NextButton
        editableLayer={editableLayer}
        setLoading={setLoading}
        setTrimmedSituationalFeatures={setTrimmedSituationalFeatures}
        handleNext={handleNext}
        setAddedPoints={setAddedPoints}
        addedPoints={addedPoints}
      />
    </div>
  );
};

export default SituationalRoads;
