import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dropdown, { DropdownMenu, DropdownMenuItem } from "../../../../Dropdown";
import { useTranslation } from "react-i18next";
import {
  getStatusIcon,
  getStatusTranslations,
} from "../../SituationalDateStatusIcon/SituationalDateStatusIcon";
import { VscFilterFilled } from "../../../../Icons";
import { FilterButton, Checkbox } from "../../../../UI";
import styled from "styled-components";

const StyledTag = styled.span`
  /* position: absolute; */
  /* right: -5px;
  top: -7px; */
  font-size: 10px !important;
  border-radius: 100px;
  background: #f4f4f4;
  padding: 2px 7px;
  color: black;
  background: #d9ecf5;
  color: #005e8d;
`;

const FilterStatusButton = ({ onChange, color, resetDeps, options }) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation("common");

  useEffect(() => {
    setSelected([]);
    if (onChange) {
      onChange([]);
    }
  }, [resetDeps]);

  const translations = useMemo(() => {
    const statusTranslations = getStatusTranslations(t);

    const translationsObject = {};
    Object.keys(statusTranslations).forEach((key) => {
      translationsObject[key] = statusTranslations[key]?.title || "";
    });

    return translationsObject;

    // return Object.keys(options).reduce((acc, key) => {
    //   acc[key] = statusTranslations[key] ? "" : statusTranslations[key].title;
    //   return acc;
    // }, {});
  }, [t]);

  const onSelectionChange = useCallback(
    (key) => {
      let newSelected = [...selected];

      if (newSelected.includes(key)) {
        newSelected = newSelected.filter((item) => item !== key);
      } else {
        newSelected.push(key);
      }
      setSelected(newSelected);
      if (onChange) {
        onChange(newSelected);
      }
    },
    [selected]
  );

  if (Object.keys(options).length === 0) return null;

  return (
    <Dropdown
      setShow={setShow}
      show={show}
      leftOffset={0.25}
      top={8}
      hasMinWidth={false}
    >
      <FilterButton isActive={selected.length > 0}>
        <VscFilterFilled width="16" height="16" fill="currentColor" />
      </FilterButton>
      <DropdownMenu>
        {Object.keys(options).map((key) => (
          <DropdownMenuItem
            style={{ padding: "6px", userSelect: "none" }}
            key={key}
            selected={false}
            opsColor="#6c6b6b"
            fillColor="none"
            onClick={() => onSelectionChange(key)}
          >
            <Checkbox
              wrapperStyles={{ pointerEvents: "none" }}
              checked={selected.includes(key)}
              label={
                <div style={{ display: "flex", gap: 2 }}>
                  <div style={{ minWidth: 12, width: 12, height: 12 }}>
                    {getStatusIcon(key)}
                  </div>
                  <span style={{ fontSize: 12 }}>
                    {translations[key]} <StyledTag>{options[key]}</StyledTag>
                  </span>
                </div>
              }
              color={color}
              readOnly
            />
            {/* <input checked={selected.includes(key)} type="checkbox" readOnly />
            <div style={{ display: "flex", gap: 2 }}>
              <div style={{ minWidth: 12, width: 12, height: 12 }}>
                {getStatusIcon(key)}
              </div>
              <span style={{ fontSize: 12 }}>{options[key]}</span>
            </div> */}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterStatusButton;
