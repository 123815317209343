import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { currAsOfDateFieldName } from "../../../../../utils/API";
import { SaveIcon } from "../../../../Icons";
import { UpdateType } from "../../EditorContextProvider";
import { ConfirmButton } from "../../EditorSwiper/Footer";

const SaveAndConfirmButton = ({
  styles = {},
  updateType,
  editableLayer,
  activeColor,
  showSave,
  addedPoints,
  showIcon = false,
  ...props
}) => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const showSaveIcon = useMemo(() => {
    let show = isDirty || updateType === UpdateType.baseline;

    //for layers which have situational bt don't have currAsOfDateFieldName field
    if (updateType !== UpdateType.baseline) {
      show =
        editableLayer &&
        !editableLayer.fields.some(
          (field) => field.name === currAsOfDateFieldName
        );
    }

    return (
      (showSave && show) ||
      isDirty ||
      (Array.isArray(addedPoints) && addedPoints.length > 0)
    );
  }, [isDirty, updateType, editableLayer, addedPoints, showSave]);

  return (
    <ConfirmButton
      color={activeColor}
      title={
        showSaveIcon || showIcon ? (
          <SaveIcon width="14" height="14" fill={activeColor} />
        ) : (
          ""
        )
      }
      showConfirmTitle={
        updateType === UpdateType.situational &&
        editableLayer &&
        editableLayer.fields.some(
          (field) => field.name === currAsOfDateFieldName
        )
      }
      wrapperStyles={styles}
      {...props}
    />
  );
};
export default SaveAndConfirmButton;
