import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import "swiper/css";
import App from "./components/App";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import common_ar from "./translations/common_ar.json";
import common_en from "./translations/common_en.json";
import common_es from "./translations/common_es.json";
import common_fr from "./translations/common_fr.json";
import common_pt from "./translations/common_pt.json";
import common_ru from "./translations/common_ru.json";
import common_tr from "./translations/common_tr.json";
import common_zh from "./translations/common_zh.json";

import SnackbarProvider from "./components/SnackBar";

import { AuthProvider } from "react-oidc-context";
import store from "./redux/store";

// import { setAssetPath } from "@esri/calcite-components/dist/components"
// setAssetPath("https://js.arcgis.com/calcite-components/2.4.0/assets");

import "@esri/calcite-components/dist/calcite/calcite.css";

import { defineCustomElements } from "@esri/calcite-components/dist/loader";
import { APP_ENV } from "./data/constants";
// CDN hosted assets
defineCustomElements(window, {
  resourcesUrl:
    "https://cdn.jsdelivr.net/npm/@esri/calcite-components/dist/calcite/assets",
});

i18next.use(LanguageDetector);
i18next
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    resources: {
      ar: { common: common_ar },
      zh: { common: common_zh },
      en: { common: common_en },
      es: { common: common_es },
      fr: { common: common_fr },
      ru: { common: common_ru },
      pt: { common: common_pt },
      tr: { common: common_tr },
    },
    fallbackLng: "en",
    supportedLngs: ["ar", "zh", "en", "es", "fr", "ru", "pt", "tr"],
    detection: {
      lookupQuerystring: "lang",
    },
  })
  .then(() => {
    const redirectUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;

    /*
     * Azure authentication related variables
     * Openid configuration can be found here: https://login.microsoftonline.com/462ad9ae-d7d9-4206-b874-71b1e079776f/.well-known/openid-configuration
     */
    const oidcConfig = {
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/`,
      client_id: process.env.REACT_APP_AZURE_CLIENT_ID,
      redirect_uri: redirectUrl,
      response_type: "code",
      response_mode: "fragment",
      scope: `openid profile`, // add other scopes here
      post_logout_redirect_uri: redirectUrl,
    };

    /**
     * Redirect to the original url, that is stored in the user object
     * @param user
     */
    const onSigninCallback = (user) => {
      if (user.state?.url) window.location.href = user.state.url;
    };

    ReactDOM.render(
      <Provider store={store}>
        <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
          <SnackbarProvider>
            <I18nextProvider i18n={i18next}>
              <App />
            </I18nextProvider>
          </SnackbarProvider>
        </AuthProvider>
      </Provider>,
      document.getElementById("root")
    );
  });
console.log(
  `App running in APP_ENV: ${APP_ENV}, NODE_ENV: ${process.env.NODE_ENV} mode`
);

const configuration = {
  onUpdate: (registration) => {
    //New version available. Update the application (only next time the url is opened)
    //...we might give feedback also to the user
    const event = new Event("swUpdatefound");
    window.dispatchEvent(event);
    if (registration && registration.waiting)
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
  },
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister(configuration);
