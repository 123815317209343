import { loadModules } from "esri-loader";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { checkForCoordinates } from "../../components/SearchWidget/helpers";
import { countryCodes } from "../../data/countryCodes";
import { clickEventOnFeature } from "../../utils/helper";
import useORSSearch from "../Search/useORSSearch";
import useClickOutside from "../useClickOutside";

/**
 * Generates a location string from coordinates in the format "longitude, latitude"
 * @param {Object} coordinates - The coordinates object
 * @param {number} coordinates.longitude - The longitude coordinate
 * @param {number} coordinates.latitude - The latitude coordinate
 * @returns {string|undefined} The formatted location string or undefined if coordinates are null
 */
export const generateLocateString = (coordinates) => {
  if (coordinates?.longitude == null || coordinates?.latitude == null) {
    return;
  }
  return `${coordinates.longitude}, ${coordinates.latitude}`;
};

/**
 * Custom hook for search widget functionality
 */
export default function useSearchWidget(
  view,
  config,
  t,
  initialValue = "",
  resultGraphicEnabled = true,
  onResultSelect = undefined,
  onInputChange = undefined
) {
  // Core state
  const [search, setSearch] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [activeCoordinates, setActiveCoordinates] = useState(null);
  const [selectedResultKey, setSelectedResultKey] = useState(null);

  // UI state - could potentially be derived
  const [showNoResults, setShowNoResults] = useState(false);
  const [showCoordinates, setShowCoordinates] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const { fetchGeocodeSearch } = useORSSearch();
  const timer = useRef(0);
  const searchRef = useRef();
  const coordinatesFlag = useRef(false);
  const suggestionRef = useRef();
  const expandRef = useRef(true);
  const focusRef = useRef(false);

  // useEffect(() => {
  //   setValue(initialValue);
  //   setSuggestions([]);
  // }, [initialValue]);

  useClickOutside(suggestionRef, async () => {
    setTimeout(() => {
      if (!focusRef.current) {
        setShowCoordinates(false);
        setShowNoResults(false);
        hideDropdown();
      }
    }, 100);
  });

  // Derived state
  const showSuggestions = useMemo(
    () => loading || showNoResults || showCoordinates || suggestions.length > 0,
    [loading, showNoResults, showCoordinates, suggestions.length]
  );

  const suggestionsLength = useMemo(
    () => suggestions.reduce((acc, curr) => acc + curr.results.length, 0),
    [suggestions]
  );

  // Remove search graphics from map
  const removeSearchGraphic = useCallback(() => {
    if (!view) return;
    const searchGraphic = view.graphics.find(
      (g) => g?.symbol?.url && g?.symbol?.url.includes("search")
    );
    if (searchGraphic) {
      view.graphics.remove(searchGraphic);
    }
  }, [view]);

  // Reset search state
  const resetSearch = useCallback(() => {
    setValue("");
    setShowCoordinates(false);
    setShowNoResults(false);
    setLoading(false);

    setSuggestions([]);
    setActiveCoordinates(null);
    setSelectedResultKey(null);
    setShowDropdown(false);

    if (search) {
      removeSearchGraphic();
      search.clear();
    }
    clearTimeout(timer.current);
  }, [search, removeSearchGraphic]);

  // Initialize search widget
  useEffect(() => {
    const createWidget = async () => {
      const initialExtent = view.extent;

      try {
        const [Search, PictureMarkerSymbol] = await loadModules([
          "esri/widgets/Search",
          "esri/symbols/PictureMarkerSymbol",
        ]);

        const symbol = new PictureMarkerSymbol({
          url: "/assets/symbols/pin_symbol.svg",
          width: "34px",
          height: "34px",
          yoffset: "17px",
        });

        const sw = new Search({
          view,
          locationEnabled: false,
          includeDefaultSources: false,
          minSuggestCharacters: 2,
          sources: [],
          popupEnabled: false,
          resultGraphicEnabled: resultGraphicEnabled,
          resultSymbol: resultGraphicEnabled ? symbol : null,
        });

        // Add ArcGIS geocoding source
        const arcgisSource = {
          name: t(
            "screen.widget.Search.geocoding",
            "ArcGIS World Geocoding Service"
          ),
          url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
          singleLineFieldName: "SingleLine", //to search by coordinates on copy paste
          zoomScale: initialExtent?.extent?.width / 3,
          type: "arcgis-geocoder",
          resultSymbol: symbol,
        };

        const country = countryCodes.filter((country) =>
          Array.isArray(config.iso3)
            ? config.iso3.includes(country.ISO3)
            : country.ISO3 === config.iso3
        );

        if (Array.isArray(country) && country.length > 0) {
          try {
            let countryCodeString = "";
            for (const item of country) {
              const iso2 = item.ISO2;
              if (countryCodeString.length + iso2.length + 1 > 100) break;
              countryCodeString += (countryCodeString ? "," : "") + iso2;
            }

            arcgisSource.countryCode = countryCodeString;
          } catch (err) {
            console.log(err);
            arcgisSource.countryCode = country[0].ISO2;
          }
        }

        sw.sources.push(arcgisSource);

        await sw.when();
        setSearch(sw);
        searchRef.current = sw;

        // Override default goto behavior
        sw.goToOverride = (view, params) => {
          return view.goTo({
            target: params.target.target,
            scale: initialExtent.width,
            duration: 1000,
          });
        };
      } catch (err) {
        console.error("Error creating search widget:", err);
      }
    };

    if (view && !search) createWidget();

    return () => {
      removeSearchGraphic();
    };
  }, [removeSearchGraphic]);

  // Hide dropdown (for mobile)
  const hideDropdown = useCallback(() => {
    setShowDropdown(false);
  }, []);

  const handleInputChange = useCallback(
    async (e) => {
      const inputText = e.target.value;
      if (onInputChange) {
        onInputChange(inputText);
      }
      setShowNoResults(false);
      setValue(inputText);
      setSelectedResultKey(null);
      if (search) {
        removeSearchGraphic();
        search.clear();
      }

      clearTimeout(timer.current);

      const processSearchInput = () =>
        new Promise(async (resolve) => {
          if (!inputText || inputText.length < 2) {
            setShowNoResults(false);
            setSuggestions([]);
            setActiveCoordinates(null);
            setShowCoordinates(true);
            setLoading(false);
            coordinatesFlag.current = true;
            return resolve({ type: "empty" });
          }

          const result = checkForCoordinates(inputText);

          if (result && !result.error) {
            coordinatesFlag.current = true;
            handleCoordinates(result);
            setSuggestions([]);
            setShowCoordinates(true);
            setLoading(false);

            const searchString = generateLocateString({
              latitude: result.latitude,
              longitude: result.longitude,
            });

            // await search.search(searchString);
            return resolve({
              type: "coordinates",
              result,
              searchString: searchString,
            });
          }

          try {
            setLoading(true);
            if (activeCoordinates) {
              setActiveCoordinates(null);
            }
            coordinatesFlag.current = false;

            const [suggestPromise, geoSearchPromise] = [
              search.suggest(inputText),
              fetchGeocodeSearch(inputText),
            ];

            let hasProcessedGeoSearch = false;
            let hasProcessedSuggest = false;
            const availableResults = [];

            // Process results as they arrive
            const processResults = (suggestResult, geoSearchResult) => {
              if (!expandRef.current || coordinatesFlag.current) return;

              const hasGeoSearch =
                Array.isArray(geoSearchResult?.features) &&
                geoSearchResult.features.length > 0;

              // Process GeoSearch results if available
              if (hasGeoSearch && !hasProcessedGeoSearch) {
                hasProcessedGeoSearch = true;
                availableResults.push({
                  label: "GeoSearch",
                  results: geoSearchResult.features.map((feature) => ({
                    key: feature.properties.id,
                    text: feature.properties.label,
                    feature: feature,
                  })),
                  key: `${Math.random()}-GeoSearch`,
                });
              } else if (!hasGeoSearch && !hasProcessedGeoSearch) {
                hasProcessedGeoSearch = true;
              }

              // Process suggest results if available
              if (suggestResult && !hasProcessedSuggest) {
                hasProcessedSuggest = true;
                const { results = [] } = suggestResult;
                results.forEach((result) => {
                  if (
                    result.results.length === 0 ||
                    (hasGeoSearch && result?.source?.type === "arcgis-geocoder")
                  ) {
                    return;
                  }
                  availableResults.push({
                    label: result.source.name,
                    results: result.results,
                    key: `${Math.random()}-${result.source.name}`,
                    source: result.source,
                  });
                });
              }

              setShowNoResults(
                hasProcessedGeoSearch &&
                  hasProcessedSuggest &&
                  availableResults.length === 0
              );

              // setShowCoordinates(false);
              if (!coordinatesFlag.current) {
                setSuggestions(availableResults);
              }
            };

            // Handle each promise as it resolves
            let geoSearchResult = null;
            let suggestResult = null;

            geoSearchPromise
              .then((result) => {
                geoSearchResult = result;
                processResults(suggestResult, geoSearchResult);
              })
              .catch(console.error);

            suggestPromise
              .then((result) => {
                suggestResult = result;
                processResults(suggestResult, geoSearchResult);
              })
              .catch(console.error);

            await Promise.allSettled([geoSearchPromise, suggestPromise]);
            if (coordinatesFlag.current) return;
            setShowCoordinates(false);
            setLoading(false);
            return resolve({
              type: "search",
              results: availableResults,
              hasProcessedGeoSearch,
              hasProcessedSuggest,
            });
          } catch (err) {
            console.error(err);
            setLoading(false);
            return resolve({ type: "error", error: err });
          }
        });

      // Return a new Promise that resolves with the search results
      return new Promise((resolve) => {
        timer.current = setTimeout(async () => {
          const result = await processSearchInput();
          resolve(result);
        }, 500);
      });
    },
    [search, activeCoordinates, onInputChange]
  );

  // Handle input change
  // const handleInputChange = useCallback(
  //   async (e) => {
  //     const inputText = e.target.value;
  //     if (onInputChange) {
  //       onInputChange(inputText);
  //     }
  //     setShowNoResults(false);
  //     setValue(inputText);
  //     setSelectedResultKey(null);
  //     if (search) {
  //       removeSearchGraphic();
  //       search.clear();
  //     }

  //     clearTimeout(timer.current);
  //     timer.current = setTimeout(async () => {
  //       if (!inputText || inputText.length < 2) {
  //         setShowNoResults(false);

  //         setSuggestions([]);
  //         setActiveCoordinates(null);
  //         setShowCoordinates(true);
  //         setLoading(false);
  //         coordinatesFlag.current = true;
  //         return;
  //       }

  //       const result = checkForCoordinates(inputText);

  //       if (result && !result.error) {
  //         coordinatesFlag.current = true;
  //         handleCoordinates(result);

  //         setSuggestions([]);
  //         setShowCoordinates(true);
  //         setLoading(false);

  //         const searchString = generateLocateString({
  //           latitude: result.latitude,
  //           longitude: result.longitude,
  //         });
  //         await search.search(searchString);
  //       } else {
  //         setLoading(true);
  //         if (activeCoordinates) {
  //           setActiveCoordinates(null);
  //         }
  //         try {
  //           coordinatesFlag.current = false;

  //           // Start both requests simultaneously
  //           const [suggestPromise, geoSearchPromise] = [
  //             search.suggest(inputText),
  //             fetchGeocodeSearch(inputText),
  //           ];

  //           let hasProcessedGeoSearch = false;
  //           let hasProcessedSuggest = false;

  //           const availableResults = [];

  //           // Process results as they arrive
  //           const processResults = (suggestResult, geoSearchResult) => {
  //             if (!expandRef.current || coordinatesFlag.current) return;

  //             const hasGeoSearch =
  //               Array.isArray(geoSearchResult?.features) &&
  //               geoSearchResult.features.length > 0;

  //             // Process GeoSearch results if available
  //             if (hasGeoSearch && !hasProcessedGeoSearch) {
  //               hasProcessedGeoSearch = true;
  //               availableResults.push({
  //                 label: "GeoSearch",
  //                 results: geoSearchResult.features.map((feature) => ({
  //                   key: feature.properties.id,
  //                   text: feature.properties.label,
  //                   feature: feature,
  //                 })),
  //                 key: `${Math.random()}-GeoSearch`,
  //               });
  //             }

  //             // Process suggest results if available
  //             if (suggestResult && !hasProcessedSuggest) {
  //               hasProcessedSuggest = true;
  //               const { results = [] } = suggestResult;
  //               results.forEach((result) => {
  //                 if (
  //                   result.results.length === 0 ||
  //                   (hasGeoSearch && result?.source?.type === "arcgis-geocoder")
  //                 ) {
  //                   return;
  //                 }
  //                 availableResults.push({
  //                   label: result.source.name,
  //                   results: result.results,
  //                   key: `${Math.random()}-${result.source.name}`,
  //                   source: result.source,
  //                 });
  //               });
  //             }

  //             setShowNoResults(
  //               hasProcessedGeoSearch &&
  //                 hasProcessedSuggest &&
  //                 availableResults.length === 0
  //             );

  //             // setShowCoordinates(false);
  //             if (!coordinatesFlag.current) {
  //               setSuggestions(availableResults);
  //             }
  //           };

  //           // Handle each promise as it resolves
  //           let geoSearchResult = null;
  //           let suggestResult = null;

  //           geoSearchPromise
  //             .then((result) => {
  //               geoSearchResult = result;
  //               processResults(suggestResult, geoSearchResult);
  //             })
  //             .catch(console.error);

  //           suggestPromise
  //             .then((result) => {
  //               suggestResult = result;
  //               processResults(suggestResult, geoSearchResult);
  //             })
  //             .catch(console.error);

  //           // Wait for both to complete to hide loader
  //           await Promise.allSettled([geoSearchPromise, suggestPromise]);
  //           setShowCoordinates(false);
  //           setLoading(false);
  //         } catch (err) {
  //           console.log(err);
  //         } finally {
  //           console.log("loading", false);

  //           setLoading(false);
  //         }
  //       }
  //     }, 250);
  //   },
  //   [search, activeCoordinates, onInputChange]
  // );

  const handleORSResultSelect = useCallback(
    async (feature) => {
      let resultCoordinates = null;
      try {
        const { geometry } = feature || {};
        const { coordinates = [] } = geometry || {};
        if (coordinates?.length !== 2) {
          throw new Error("Invalid coordinates");
        }

        const [longitude, latitude] = coordinates;
        if (typeof longitude !== "number" || typeof latitude !== "number") {
          throw new Error("Coordinates must be numbers");
        }

        resultCoordinates = {
          longitude,
          latitude,
        };
        if (onResultSelect) {
          onResultSelect({
            latitude,
            longitude,
          });
        }
        setSelectedResultKey(feature.properties.id);
        hideDropdown();

        await search.search(`${longitude}, ${latitude}`);
      } catch (error) {
        console.error("Error selecting ORS result:", error);
      }

      return resultCoordinates;
    },
    [search, hideDropdown, onResultSelect]
  );

  // Handle standard result selection
  const handleSelectResult = useCallback(
    async (result, openPopup = true) => {
      const { key } = result;
      hideDropdown();
      let coordinates = null;

      try {
        const res = await search.search(result);

        if (res?.results?.length > 0) {
          const { results = [], source } = res.results[0] || {};

          if (results.length > 0) {
            const { feature, isLayerSearchSourceResult } = results[0];
            coordinates = {
              latitude: feature.geometry.latitude,
              longitude: feature.geometry.longitude,
            };

            setActiveCoordinates(coordinates);

            if (onResultSelect) {
              onResultSelect(coordinates);
            }

            if (feature && openPopup) {
              clickEventOnFeature(feature, {
                zoom: false,
                duration: 1000,
                showPopup:
                  !!source.layer?.layerConfig?.clickable &&
                  isLayerSearchSourceResult,
              });
            }
          }
        }
      } catch (err) {
        console.error(err);
      }

      setSelectedResultKey(key);
      return coordinates;
    },
    [search, hideDropdown, onResultSelect]
  );

  /**
   * Handles the click event on a suggestion.
   *
   * @param {string} label - The label of the suggestion.
   * @param {Object} result - The result object of the suggestion.
   * @param {Object} result.feature - The feature object of the result (used when label includes "GeoSearch").
   */
  const handleSuggestionClick = useCallback(
    async (label, result, openPopup) => {
      let resultCoordinates = null;
      if (label.includes("GeoSearch")) {
        resultCoordinates = await handleORSResultSelect(result.feature);
      } else {
        resultCoordinates = await handleSelectResult(result, openPopup);
      }

      return resultCoordinates;
    },
    [handleORSResultSelect, handleSelectResult]
  );

  // Handle input focus
  const handleFocus = useCallback(() => {
    setShowCoordinates(true);
    setShowDropdown(true);
    focusRef.current = true;
  }, []);

  const handleBlur = useCallback(() => {
    focusRef.current = false;
  }, []);

  // Handle coordinates
  const handleCoordinates = useCallback(
    (point, changeInputValue = false) => {
      setActiveCoordinates({
        latitude: point.latitude,
        longitude: point.longitude,
      });

      let inputValue = ``;

      if (point.latitude) {
        inputValue += `${point.latitude}`;
      }

      if (point.longitude) {
        inputValue += `, ${point.longitude}`;
      }

      if (changeInputValue) {
        setValue(inputValue);
      }
    },
    [search, hideDropdown]
  );

  const locateCoordinates = useCallback(
    async (coordinates) => {
      try {
        const searchString = generateLocateString(coordinates);
        if (search && searchString) {
          await search.search(searchString);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [search]
  );

  // Locate coordinates on map
  const locateButtonClick = useCallback(async () => {
    try {
      if (
        activeCoordinates?.longitude == null ||
        activeCoordinates?.latitude == null
      )
        return;

      const inputValue = `${activeCoordinates.latitude}, ${activeCoordinates.longitude}`;
      setValue(inputValue);
      await locateCoordinates(activeCoordinates);

      if (search) {
        hideDropdown();
      }
    } catch (err) {
      console.error(err);
    }
  }, [search, activeCoordinates, hideDropdown, locateCoordinates]);

  // Handle key press (Enter)
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        locateButtonClick();
      }
    },
    [locateButtonClick]
  );

  return {
    // Core state
    search,
    value,
    loading,
    suggestions,
    activeCoordinates,
    selectedResultKey,
    expandRef,

    // UI state
    showCoordinates,
    showDropdown,
    showNoResults,
    showSuggestions,
    suggestionsLength,
    suggestionRef,
    handleBlur,

    // Actions
    setValue,
    resetSearch,
    handleInputChange,
    handleFocus,
    handleSuggestionClick,
    handleCoordinates,
    handleKeyPress,
    locateCoordinates,
    locateButtonClick,
    removeSearchGraphic,
    setActiveCoordinates,
  };
}
