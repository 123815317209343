import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getFeatureTitle } from "../../../../../esri/custom-popup-content";
import { IoMdCheckmark } from "../../../../Icons";
import { UpdateType } from "../../EditorContextProvider";
import Message from "../Message";

const SaveMessage = ({
  actionType,
  position,
  updateType,
  updatedFeatures,
  highlightFeature,
  batchUpdateFeatures,
  editableLayer,
  activeColor,
  value,
}) => {
  const { t } = useTranslation("common");

  const featureNames = useMemo(() => {
    const features = updatedFeatures
      ? updatedFeatures
      : batchUpdateFeatures.length > 0
      ? batchUpdateFeatures.map((item) => item.feature)
      : highlightFeature
      ? [highlightFeature]
      : [];

    return features
      .filter((feature) => {
        const title = getFeatureTitle(feature, t);
        return typeof title === "string" && title !== "[object Promise]";
      })
      .map((feature) => getFeatureTitle(feature, t))
      .join(", ");
  }, [t, highlightFeature, batchUpdateFeatures, updatedFeatures]);

  return (
    <Message color={activeColor} position={position}>
      <p
        style={{
          color: "#525252",
          textAlign: "center",
          background: "#fff",
          padding: 2,
          borderRadius: 4,
          margin: 0,
        }}
      >
        {updateType === UpdateType.situational ? (
          <span
            style={{
              position: "relative",
              padding: 0,
              minWidth: 16,
              minHeight: 16,
              display: "inline-flex",
              marginRight: 4,
            }}
          >
            <IoMdCheckmark
              width="16"
              height="16"
              style={{ position: "absolute", top: 2 }}
            />
          </span>
        ) : null}
        <span style={{ fontWeight: 500, padding: 0, fontSize: 16 }}>
          {featureNames
            ? featureNames
            : value != null && typeof value === "string"
            ? value
            : editableLayer?.getLayerTitle(t)}
        </span>{" "}
        {t(`screen.widget.Editor.manager.messages.${actionType}`)}
      </p>
    </Message>
  );
};

export default SaveMessage;
