import {
  StyledMainBarContainer,
  StyledVerticalBarXAxisResponsiveContainer,
} from "../Insights-styled";
import CustomBarChart from "./CustomBarChart";
import { COLORS } from "../Insights";
import React from "react";
import { useSelector } from "react-redux";

const StockChart = ({ data, t, config, onSelect, mapFilters, barValues }) => {
  const { isMobileApp } = useSelector((state) => state.mobileReducer);
  return (
    <div
      style={{
        position: "relative",
        flex: 1,
      }}
    >
      <div
        style={{
          height: data.length > 10 ? "calc(100% - 15px)" : "100%",
          overflow: data.length > 10 ? "auto" : "unset",
          position: "relative",
        }}
      >
        <StyledMainBarContainer
          style={{ position: "absolute" }}
          height={data.length > 10 ? "400%" : "100%"}
        >
          <CustomBarChart
            t={t}
            config={config}
            data={data}
            onClick={onSelect}
            mapFilters={mapFilters}
            barValues={barValues}
            margin={{ left: isMobileApp ? 0 : -5 }}
            colors={{
              fill: {
                quantity: COLORS.green,
                expected: COLORS.yellow,
              },
            }}
            layout="vertical"
            showX={data.length <= 10}
            isMobileApp={isMobileApp}
          />
        </StyledMainBarContainer>
      </div>
      {data.length > 10 && (
        <StyledVerticalBarXAxisResponsiveContainer height={"100%"}>
          <CustomBarChart
            t={t}
            config={config}
            data={data}
            onClick={onSelect}
            mapFilters={mapFilters}
            barValues={barValues}
            margin={{ left: isMobileApp ? 0 : -5 }}
            colors={{
              fill: {
                quantity: COLORS.green,
                expected: COLORS.yellow,
              },
            }}
            layout="vertical"
            isMobileApp={isMobileApp}
            showX
          />
        </StyledVerticalBarXAxisResponsiveContainer>
      )}
    </div>
  );
};

export default StockChart;
