import ProgressBar from "progressbar.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { timeAgo } from "../../../../utils/helper";
import CycleInformation from "../CycleInformation/CycleInformation";
import { StyledTextContainer } from "../CycleInformation/CycleInfromation-styled";
import {
  StyledContainer,
  StyledCycleManagerProgressWrapper,
  StyledProgress,
  StyledProgressContainer,
  StyledProgressPercentContainer,
} from "./CycleManagerProgress-styled";

const CycleManagerProgress = ({ config }) => {
  const [el, setElement] = useState(null);
  const [bar, setBar] = useState(null);
  const { t, i18n } = useTranslation("common");
  const { updatePercent, cycleUpdateDueDate, lastCycleUpdate } = useSelector(
    (state) => state.cycleManagerReducer
  );

  useEffect(() => {
    if (!el) return;

    var progressBar = new ProgressBar.SemiCircle(el, {
      strokeWidth: 6,
      color: config.opsColor,
      trailColor: "#eee",
      trailWidth: 6,
      easing: "easeInOut",
      duration: 1400,
      svgStyle: {
        maxHeight: "100px",
      },
      from: { color: config.opsColor },
      to: { color: config.opsColor },
    });

    try {
      progressBar.animate(0);
    } catch (e) {
      console.log(e);
    }

    setBar(progressBar);
  }, [el]);

  useEffect(() => {
    if (!bar) return;

    try {
      bar.animate(updatePercent / 100);
    } catch (e) {
      console.log(e);
    }
  }, [updatePercent, bar]);

  const withRef = useCallback(
    (el) => {
      if (!el) return;
      setElement(el);
    },
    [config]
  );

  const format = (dueDate) => {
    if (!dueDate) return "";
    const now = new Date();
    if (lastCycleUpdate >= dueDate || now.getTime() >= dueDate)
      return t("cycleManager.overdue");

    return timeAgo(dueDate, i18n.language, false, { month: "short" });
  };

  const isOverdue = useMemo(() => {
    const now = new Date();
    return lastCycleUpdate > lastCycleUpdate || now >= lastCycleUpdate;
  }, []);

  if (!cycleUpdateDueDate) return null;

  return (
    <StyledCycleManagerProgressWrapper>
      <StyledContainer>
        <StyledProgressContainer>
          <StyledProgress ref={withRef} />
          <StyledProgressPercentContainer>
            <span>{updatePercent}%</span>
            <span>{t("cycleManager.status.upToDate.title")}</span>
          </StyledProgressPercentContainer>
          <StyledTextContainer>
            <span>{t("cycleManager.due")}:</span>{" "}
            <span
              style={{
                color: isOverdue ? config.opsColor : "",
                textDecoration: isOverdue ? "underline" : "",
                fontWeight: isOverdue ? "500" : "",
              }}
            >
              {format(cycleUpdateDueDate)}
            </span>
          </StyledTextContainer>
        </StyledProgressContainer>
        <CycleInformation />
      </StyledContainer>
    </StyledCycleManagerProgressWrapper>
  );
};

export default CycleManagerProgress;
