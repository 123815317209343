import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { StyledSubmitButton } from "../../../Report/new/Footer/Footer-styled";
import { StyledOverlay } from "./Message-styled";

const Message = ({
  color,
  onSubmit,
  onCancel,
  children,
  title,
  subtitle,
  position,
  bg,
  submitTitle,
}) => {
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  return (
    <StyledOverlay position={position} bg={bg}>
      <div
        style={{
          padding: 20,
          background: "#fff",
          borderRadius: 8,
          wordWrap: "break-word",
          // width: "100%",
          boxSizing: "border-box",
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        {title || subtitle ? (
          <>
            {title && (
              <p
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {subtitle && <p className="subtitle">{subtitle}</p>}
          </>
        ) : (
          children
        )}
        {(onSubmit || onCancel) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 20,
              marginTop: 10,
            }}
          >
            {onSubmit && (
              <StyledSubmitButton bg={color} onClick={onSubmit}>
                {submitTitle ?? t("screen.widget.common.yes")}
              </StyledSubmitButton>
            )}
            {onCancel && (
              <StyledSubmitButton
                color={color ?? config.opsColor}
                bg="#ffffff"
                borderWidth={1}
                onClick={onCancel}
              >
                {t("screen.widget.common.no")}
              </StyledSubmitButton>
            )}
          </div>
        )}
      </div>
    </StyledOverlay>
  );
};

export default Message;
