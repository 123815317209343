import React, { useMemo, useRef } from "react";
import { iconNameToIcon } from "../../../../../../Icons/layerSvg/clusterAssets";
import Tooltip from "../../../../../../UI/Tooltip";
import { Icon, IconContainer } from "./IconHeader-styled";
import { clusterRed } from "../../../../../../../utils/API";

//refer https://www.ag-grid.com/react-data-grid/column-headers/

const IconHeader = ({ iconName, color, ...props }) => {
  let filter;
  const refButton = useRef();

  const onMenuClicked = () => {
    if (refButton.current) {
      props.showColumnMenu(refButton.current);
    }
  };

  const iconSVG = useMemo(() => {

    let iconString = iconNameToIcon(iconName);
    if (color && iconString) {
      iconString = iconString.replaceAll(clusterRed, color);
      iconString = iconString.replaceAll(clusterRed.toUpperCase(), color);
    }

    if (iconString) {
      const fieldName = props.column.colId;
      iconString = iconString.replaceAll("cls-1", `${fieldName}-cls-1`);
      iconString = iconString.replaceAll("cls-2", `${fieldName}-cls-2`);
      iconString = iconString.replaceAll("st0", `${fieldName}-st0`);
    }

    return iconString;
  }, [props, iconName, color]);

  // if (props.enableFilterButton) {
  //   filter = (
  //     <>
  //       <span
  //         ref={refButton}
  //         onClick={() => onMenuClicked()}
  //         data-ref="eFilterButton"
  //         className="ag-header-icon ag-header-cell-filter-button"
  //         aria-hidden="true"
  //       >
  //         <span
  //           className="ag-icon ag-icon-filter"
  //           unselectable="on"
  //           role="presentation"
  //         ></span>
  //       </span>
  //     </>
  //   );
  // }

  if (!iconSVG) {
    return null;
  }

  return (
    <IconContainer
      ref={refButton}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
      }}
      onClick={onMenuClicked}
    >
      <Tooltip showOnHover content={props.displayName}>
        <Icon dangerouslySetInnerHTML={{ __html: iconSVG }}></Icon>
      </Tooltip>
      {/* {filter} */}
    </IconContainer>
  );
};

export default IconHeader;
