import React, { useCallback, useState } from "react";
import { FilterButton, Tooltip } from "../../../../UI";
import { EyeOffIcon, EyeOnIcon } from "../../../../Icons";

const ShowPublicFilter = ({ onClick, isActive, t }) => {
  return (
    <Tooltip showOnHover content={t("layer.fieldAlias.showpublic.title")}>
      <FilterButton isActive={isActive} onClick={onClick}>
        {isActive ? <EyeOnIcon /> : <EyeOffIcon />}
      </FilterButton>
    </Tooltip>
  );
};

export default ShowPublicFilter;
