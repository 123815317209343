import { loadModules } from "esri-loader";
import { useCallback, useEffect, useRef, useState } from "react";
import { EMBED_VARIANTS } from "../utils/helper";

export const setLayerVisibility = (layer, activeModule, config) => {
  if (layer.layerModules && layer.layerModules.length > 0) {
    layer.visible =
      layer.layerModules.includes(activeModule) &&
      !(
        config.modules[activeModule]?.optionalLayers?.includes(
          layer.layerConfig?.alias
        ) ||
        config.modules[activeModule]?.optionalLayers?.includes(
          layer.layerConfigAlias
        )
      );
  }
};

const useViewLayers = (view, config) => {
  let [localModule, setLocalModule] = useState();

  useEffect(() => {
    if (localModule) return;
    let module = config.defaultModule;
    if (
      Array.isArray(config.activeModules) &&
      !config.activeModules.includes(module)
    ) {
      module = config.activeModules
        ? config.activeModules[0]
        : config.modules[0];
    }
    setLocalModule(module);
  }, [config]);

  const setLayerVisibility = useCallback(
    (layer) => {
      if (layer.layerModules && layer.layerModules.length > 0) {
        layer.visible =
          layer.layerModules.includes(localModule) &&
          !(
            config.modules[localModule]?.optionalLayers?.includes(
              layer.layerConfig?.alias
            ) ||
            config.modules[localModule]?.optionalLayers?.includes(
              layer.layerConfigAlias
            )
          );
      }
    },
    [localModule, config]
  );

  const handleMapLayersVisibility = useCallback(
    async (layers) => {
      for (const layer of layers) {
        await new Promise((resolve) => {
          requestAnimationFrame(() => {
            setLayerVisibility(layer);
            resolve();
          });
        });
      }
    },
    [view, setLayerVisibility]
  );

  const waitForBasemapLoad = async (view) => {
    return new Promise((resolve) => {
      if (view.map.basemap.loaded) {
        resolve(true);
        return;
      }

      const watchHandle = view.watch("map.basemap.loaded", (isLoaded) => {
        if (isLoaded) {
          watchHandle.remove();
          requestAnimationFrame(() => {
            resolve(true);
          });
        }
      });
    });
  };

  const hasBasemapChanged = useRef(false);
  const assignBasemap = useCallback(async (module) => {
    try {
      const [Basemap] = await loadModules(["esri/Basemap"]);
      const moduleBasemapId = config.modules[module].basemapId;
      const moduleBasemap = config.modules[module].basemap;

      const configBasemapId = config.basemapId;
      const configBasemap = config.basemap;

      let mapBasemap = null;

      if (moduleBasemapId) {
        const isSame = configBasemapId === moduleBasemapId;
        if (!isSame) {
          mapBasemap = new Basemap({ portalItem: { id: moduleBasemapId } });
        }
      } else if (moduleBasemap) {
        const isSame = configBasemap === moduleBasemap;
        if (!isSame) {
          mapBasemap = Basemap.fromId(moduleBasemap);
        }
      }

      if (mapBasemap) {
        hasBasemapChanged.current = true;
        view.map.basemap = mapBasemap;
        return await waitForBasemapLoad(view);
      } else if (hasBasemapChanged.current) {
        hasBasemapChanged.current = false;
        view.map.basemap = configBasemapId
          ? new Basemap({ portalItem: { id: configBasemapId } })
          : Basemap.fromId(configBasemap);
        return await waitForBasemapLoad(view);
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }, []);

  useEffect(() => {
    if (!view || !localModule || !config.modules[localModule]) return;
    const changeViewConfigurations = async () => {
      try {
        const zoom = config.modules[localModule].zoom ?? config.zoom;
        const center = config.modules[localModule].center ?? config.center;
        const minZoom = config.modules[localModule].minZoom ?? config.minZoom;
        const maxZoom = config.modules[localModule].maxZoom ?? config.maxZoom;

        if (Number.isInteger(zoom) || Array.isArray(center)) {
          const targetZoom =
            zoom - (config.embed === EMBED_VARIANTS.WEBSITE ? 1 : 0);
          const targetCenter = center ?? view.center;

          await view.goTo({
            zoom: targetZoom,
            center: targetCenter,
          });
        }

        if (Number.isInteger(minZoom)) {
          view.constraints.minZoom = minZoom;
        }

        if (Number.isInteger(maxZoom)) {
          view.constraints.maxZoom = maxZoom;
        }

        await handleMapLayersVisibility(view.map.layers);

        // Handle layer added event
        view.map.layers.on("change", (event) => {
          if (event.added) {
            handleMapLayersVisibility(event.added);
            // event.added.forEach((layer) => {
            // 	setLayerVisibility(layer);
            // });
          }
        });

        await assignBasemap(localModule);
      } catch (err) {
        console.log(err);
      }
    };

    changeViewConfigurations();
  }, [localModule, config, view]);

  return { localModule, setLocalModule };
};

export default useViewLayers;
