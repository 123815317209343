import CryptoJS from "crypto-js";
import { loadModules } from "esri-loader";
import React from "react";
import ScreensData from "../../data/panel.json";
import { resetPanel } from "../../redux/constants";
import { getLayerFromId } from "../../utils/API";
import { getFieldsByPattern } from "../../utils/helper";
import {
  dangerColor,
  darkColor,
  primaryColor,
  secondaryColor,
  successColor,
  warningColor,
  whiteColor,
} from "../../utils/Theme";
import Button from "./components/Button/Button";
import Card from "./components/Card/Card";
import Checkbox from "./components/Checkbox/Checkbox";
import ComponentWrapper from "./components/ComponentWrapper/ComponentWrapper";
import Domain from "./components/Domain/Domain";
import Dropbox from "./components/Dropbox/Dropbox";
import Geometry from "./components/Geometry/Geometry";
import Group from "./components/Group/Group";
import Heading from "./components/Heading/Heading";
import Image from "./components/Image/Image";
import Input from "./components/Input/Input";
import List from "./components/List/List";
import Pagination from "./components/Pagination/Pagination";
import Select from "./components/Select/Select";
import { Paragraph } from "./PanelContainer-styled";

export const PanelDimensions = () => {
  let viewportHeight = window.innerHeight;
  const headerHeight = document.getElementById("header").offsetHeight;
  return {
    right: {
      width: 30,
      height: ((viewportHeight - headerHeight) * 100) / viewportHeight,
      left: 70,
      top: (headerHeight * 100) / viewportHeight,
    },
    left: {
      width: 30,
      height: ((viewportHeight - headerHeight) * 100) / viewportHeight,
      left: 0,
      top: (headerHeight * 100) / viewportHeight,
    },
    bottom: {
      width: 100,
      height: 40,
      left: 0,
      top: 60,
    },
    modal: {
      width: 27,
      height: 70,
      left: 6,
      top: 15,
    },
  };
};

export const RenderContent = ({
  data,
  openScreenId,
  path,
  config,
  t,
  currPageIndex = 0,
  formProps,
  defaultFieldValues = {},
}) => {
  const { isSurvey, isGroup, formData, setFormData, onChange, value } =
    formProps;
  const hasFormData = isSurvey && !isGroup;
  let defaultFormData = hasFormData ? formData : null;
  try {
    if (!data) return <></>;
    const result = [];
    const hasPagination = data.hasPagination;
    data = data.content ? data.content : data;
    const pageIndex = Object.keys(data)[currPageIndex];
    let content = hasPagination ? data[pageIndex] : data;
    const pathArr = path.split(".");
    const lastPath = pathArr.pop();
    const page = hasPagination
      ? pageIndex
      : pathArr.length > 0
      ? lastPath
      : openScreenId;
    path = hasPagination ? path : pathArr.join(".");

    const queue = [
      [
        page,
        content.content
          ? content
          : content.type
          ? content
          : { content: content },
      ],
    ];

    while (queue.length > 0) {
      const [currentNode, neighbors] = queue.shift();

      if (!neighbors) return result ?? <></>;

      if (
        neighbors.hasOwnProperty("content") &&
        !neighbors.hasOwnProperty("type")
      ) {
        content = neighbors["content"];
        path = path !== "" ? `${path}.${currentNode}` : currentNode;
        const neighborNodes = Object.keys(content);
        neighborNodes.forEach((neighbor) => {
          queue.push([neighbor, content[neighbor]]);
        });
        continue;
      }
      const newPath = path !== "" ? `${path}.${currentNode}` : currentNode;
      const componentParams = {
        specs: { ...neighbors, opsColor: config.opsColor },
        t,
        config,
        path: newPath,
        formProps: {
          isSurvey: isSurvey,
          formData: formData,
          onChange: onChange,
          value: value,
        },
      };
      if (hasFormData) {
        const newValue =
          currentNode === "rememberContacts" &&
          Object.keys(defaultFieldValues).length > 0
            ? "rememberContacts"
            : neighbors.hasOwnProperty("configAttr")
            ? Array.isArray(config[neighbors.configAttr])
              ? config[neighbors.configAttr][0]
              : config[neighbors.configAttr] ?? null
            : neighbors.value ?? defaultFieldValues[currentNode] ?? null;
        defaultFormData = setFormDataValues(
          neighbors.type,
          newPath,
          defaultFormData,
          newValue,
          neighbors.isRequired
        );
      }

      result.push(
        <ComponentWrapper
          style={{ width: isGroup ? "auto" : "100%" }}
          key={`comp_${currentNode}`}
          componentParams={componentParams}
        />
      );
    }
    if (
      hasFormData &&
      JSON.stringify(formData) !== JSON.stringify(defaultFormData)
    ) {
      setFormData(defaultFormData);
    }
    return result;
  } catch (e) {
    console.warn(e);
  }
};

export const contentComponent = ({ t, config, specs, path, formProps }) => {
  const type = specs.type ?? "unspecified";
  switch (type.toLowerCase()) {
    case "card":
      return <Card specs={specs} path={path} formProps={formProps} />;
    case "pagination":
      return <Pagination />;
    case "heading":
      return <Heading path={path} />;
    case "button":
      return <Button specs={specs} />;
    case "paragraph":
      return <Paragraph>{t(`screen.panel.${path}`, "")}</Paragraph>;
    case "list":
      return <List path={path} />;
    case "image":
      return <Image specs={specs} />;
    case "group":
      return (
        <Group
          specs={specs}
          path={path}
          formProps={formProps}
          config={config}
        />
      );
    case "geometry":
      return <Geometry specs={specs} path={path} formProps={formProps} />;
    case "dropbox":
      return <Dropbox path={path} formProps={formProps} />;
    case "datepicker":
      return <Input type={"date"} path={path} formProps={formProps} />;
    case "input":
    case "textinput":
      return (
        <Input type={"text"} specs={specs} path={path} formProps={formProps} />
      );
    case "numberinput":
      return (
        <Input
          type={"number"}
          specs={specs}
          path={path}
          formProps={formProps}
        />
      );
    case "emailinput":
      return (
        <Input type={"email"} specs={specs} path={path} formProps={formProps} />
      );
    case "checkbox":
      return <Checkbox path={path} formProps={formProps} />;
    case "domain":
      return (
        <Domain
          specs={specs}
          path={path}
          formProps={formProps}
          config={config}
        />
      );
    case "multiselect":
    case "select":
      specs = { ...specs, isMulti: type.toLowerCase() === "multiselect" };
      return <Select specs={specs} path={path} formProps={formProps} />;
    case "radio":
    default:
      return <></>;
  }
};

const getDefaultFormDataValues = (type) => {
  switch (type ? type.toLowerCase() : "") {
    case "button":
    case "card":
    case "group":
    case "input":
    case "textinput":
    case "emailinput":
    case "select":
    case "radio":
    case "numberinput":
      return "";
    case "geometry":
      return { latitude: "", longitude: "" };
    case "dropbox":
    case "datepicker":
      return null;
    case "checkbox":
      return false;
    case "multiselect":
      return [];
  }
};

export const setFormDataValues = (
  type,
  path,
  formData,
  newValue,
  isRequired = false
) => {
  const updateValue = (data, path, newValue) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    let reference = data;
    keys.forEach((key) => {
      if (!reference[key]) reference[key] = {};
      reference = reference[key];
    });
    if (!reference[lastKey])
      reference[lastKey] = { value: newValue, isRequired };
    else if (!type)
      reference[lastKey] = { ...reference[lastKey], value: newValue };
  };

  newValue = newValue ?? getDefaultFormDataValues(type);

  if (newValue === undefined) return formData;

  updateValue(formData, path, newValue);

  return formData;
};

export const getFormDataVal = (data, path) => {
  if (!data || !path) return { value: null, isRequired: false };
  const keys = path.split(".");
  const lastKey = keys.pop();
  let reference = data;
  keys.forEach((key) => {
    if (!reference[key]) reference[key] = {};
    reference = reference[key];
  });
  return reference[lastKey] ?? { value: "" };
};

export const submitData = async (formData, panelId) => {
  const layerId = panelId.id;
  try {
    let files = [];
    const layer = panelId.content ?? (await getLayer(layerId));
    if (!layer) return false;
    let attributes = {};
    let geometry = { type: "point" };
    Object.entries(formData).forEach(([key, entry]) => {
      const isValid = formValidation({ [key]: entry });
      if (!isValid) return false;
      const value = entry.value;
      const objVal =
        value && typeof value === "object" ? Object.entries(value) : [];
      if (objVal.length > 0) {
        if (value instanceof File) {
          files.push(value);
          return;
        }
        objVal.forEach(([index, entry]) => {
          if (key === "geometry") geometry = { ...geometry, [index]: entry };
          attributes = { ...attributes, [index]: entry };
        });
        return;
      }
      attributes = { ...attributes, [key]: entry.value };
    });
    const newFeature = { attributes, geometry };
    const editResults = await layer.applyEdits({ addFeatures: [newFeature] });
    if (!files || files.length < 1 || !editResults.addFeatureResults)
      return true;
    const feature = {
      attributes: {
        [layer.objectIdField]: editResults.addFeatureResults[0].objectId,
      },
    };
    for (const file of files) {
      const form = new FormData();
      form.set("attachment", file);
      await layer.addAttachment(feature, form);
    }
  } catch (e) {
    return false;
  }
};

export const formValidation = (formData) => {
  const currPageFormData = formData ?? {};
  const isValid = Object.keys(currPageFormData).map((key) => {
    const isRequired = currPageFormData[key].isRequired;
    if (!isRequired) return true;
    const value = currPageFormData[key].value;
    return (
      isRequired &&
      (typeof value === "string"
        ? value.length > 0
        : typeof value === "number"
        ? value > 0
        : value.hasOwnProperty("latitude") &&
          value.latitude.length > 0 &&
          value.hasOwnProperty("longitude") &&
          value.longitude.length > 0)
    );
  });
  return isValid.length > 0
    ? isValid.reduce((accumulator, currentValue) => accumulator && currentValue)
    : true;
};

export const getScreenId = (path) => {
  if (!path) return;
  const linkArr = path.split(".");
  return linkArr[0];
};

export const routeLink = (link = "") => {
  if (!link || link.length < 1) return;
  const linkArr = link.split(".");
  let result = ScreensData;
  for (let i = 0; i < linkArr.length; i++) {
    if (i !== 0) result = result["content"];
    result = result[linkArr[i]] ?? {};
  }
  return result;
};

export const getDataList = async (data, view) => {
  const { type, description, layerId, layer, name } = data;
  switch (type) {
    case "layers":
      return getLayers(description, view);
    case "layerFields":
      return await getLayerFields(layerId, description, view, layer);
    case "layerField":
      return await getLayerFields(layerId, description, view, layer, name);
    default:
      return [];
  }
};

export const getLayers = (desc, view) => {
  switch (desc) {
    case "EditablePointLayers":
      return view.map.layers.filter(
        (l) =>
          l.type &&
          l.type === "feature" &&
          l.layerConfig?.editable &&
          l.visible &&
          l.geometryType === "point"
      );
    default:
      return view.map.layers;
  }
};

export const getLayer = async (id, view) => {
  const layer = view.map.layers.find(
    (l) => l.layerConfig && l.layerConfig.id === id
  );
  if (layer) return layer;
  return await loadModules(["esri/layers/Layer"]).then(async ([Layer]) =>
    getLayerFromId(Layer, id)
      .then(async (layerPreLoad) => await layerPreLoad.load())
      .then((err) => {
        console.log(err);
      })
  );
};

export const getLayerFields = async (layerId, desc, view, layer, name = "") => {
  if (!layerId) return [];
  layer = layer ?? (await getLayer(layerId, view));
  if (!layer) return [];
  if (name && name.length > 0) {
    return (
      layer.fields.filter((f) => {
        return f.name === name;
      }) ?? []
    );
  }
  const lc = layer.layerConfig;
  return getFieldsByPattern(layer, lc[desc]).filter((f) => f.editable) ?? [];
};

export const fetchSavedContacts = (key) => {
  try {
    const savedContacts = localStorage.getItem("contactsSurvey");
    if (!savedContacts || !savedContacts?.length) return [];
    return JSON.parse(
      CryptoJS.AES.decrypt(savedContacts, key).toString(CryptoJS.enc.Utf8)
    );
  } catch (err) {
    console.log(err);
  }
};
export const saveContacts = (key, ContactInfo) => {
  const encryptedString = CryptoJS.AES.encrypt(
    JSON.stringify(ContactInfo),
    key
  ).toString();
  localStorage.setItem("contactsSurvey", encryptedString);
};

export const getColorValue = (variant, color = null, defColor = null) => {
  switch (variant) {
    case "primary":
      return {
        background: convertColorToRGBA(primaryColor, 1),
        tonal: convertColorToRGBA(primaryColor, 0.5),
        color: whiteColor,
      };
    case "danger":
      return {
        background: convertColorToRGBA(dangerColor, 1),
        tonal: convertColorToRGBA(dangerColor, 0.5),
        color: whiteColor,
      };
    case "success":
      return {
        background: convertColorToRGBA(successColor, 1),
        tonal: convertColorToRGBA(successColor, 0.5),
        color: whiteColor,
      };
    case "warning":
      return {
        background: convertColorToRGBA(warningColor, 1),
        tonal: convertColorToRGBA(warningColor, 0.5),
        color: darkColor,
      };
    case "secondary":
      return {
        background: convertColorToRGBA(secondaryColor, 1),
        tonal: convertColorToRGBA(secondaryColor, 0.5),
        color: darkColor,
      };
    case "dark":
      return {
        background: convertColorToRGBA(darkColor, 1),
        tonal: convertColorToRGBA(dangerColor, 0.5),
        color: whiteColor,
      };
    case "default":
      return {
        background: defColor ?? convertColorToRGBA(secondaryColor, 1),
        tonal: defColor ?? convertColorToRGBA(secondaryColor, 0.5),
        color: darkColor,
      };
    default:
      return {
        background: defColor ?? variant,
        tonal: defColor ?? variant,
        color: color ?? darkColor,
      };
  }
};

export const getColorValueByName = (name) => {
  switch (name) {
    case "primary":
      return primaryColor;
    case "secondary":
      return secondaryColor;
    case "danger":
      return dangerColor;
    case "success":
      return successColor;
    case "warning":
      return warningColor;
    case "dark":
      return darkColor;
    case "white":
      return whiteColor;
    default:
      return name;
  }
};

export const convertColorToRGBA = (color, opacity = 1) => {
  if (!color) return;
  color = getColorValueByName(color);
  const isHexColor = color.startsWith("#");
  if (isHexColor) color = convertHexToRGB(color);
  if (!color.toLowerCase().includes("rgb(")) return color;
  const rgbValues = color.slice(4, -1);
  const rgbArray = rgbValues
    .split(",")
    .map((value) => parseInt(value.trim(), 10));
  const [r, g, b] = rgbArray;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const convertHexToRGB = (hex) => {
  hex = hex.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgb(${r}, ${g}, ${b})`;
};

export const closeSlideBar = (dispatch) => {
  dispatch({ type: resetPanel });
  const mapDiv = document.getElementById("mapContainer");
  mapDiv.style.width = `${window.innerWidth}px`;
  mapDiv.style.height = `${window.innerHeight}px`;
  mapDiv.style.left = "0";
};
