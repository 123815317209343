import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { view } from "../../../utils/API";
import EditorSlides from "./EditorSwiper/EditorSlides";

const EditorPlatform = ({
  handleGoBack,
  editType,
  setEditType,
  editableLayers,
  baselineLayers,
  entryPointsLayers,
  situationalLayers,
}) => {
  const { editableLayer } = useSelector((state) => state.dashboard);

  useEffect(() => {
    // view.popupEnabledCustom = false;
    // view.popupEnabled = false;
    //close popup
    // handleClosePopup();
    // store.dispatch(closePopupSidebar());

    let layerViewFilters = {};
    view.layerViews.forEach((lv) => {
      if (lv.filter?.where) {
        layerViewFilters[lv.layer.id] = lv.filter.where;
        lv.filter.where = "";
      }
    });

    // updateLayers(view.map.layers)
    // view.map.layers.on("change", (event) => {
    //   if (event.added)
    //     updateLayers(event.added)
    // })
    //todo check when print widget is open
    return () => {
      view.layerViews.forEach((lv) => {
        if (layerViewFilters[lv.layer.id]) {
          lv.filter.where = layerViewFilters[lv.layer.id];
        }
      });
      layerViewFilters = {};
      // view.popupEnabled = true;
      // view.popupEnabledCustom = true;
    };
  }, []);

  return (
    <EditorSlides
      editableLayer={editableLayer}
      editableLayers={editableLayers}
      handleGoBack={handleGoBack}
      editType={editType}
      setEditType={setEditType}
      baselineLayers={baselineLayers}
      entryPointsLayers={entryPointsLayers}
      situationalLayers={situationalLayers}
    />
  );
};

export default EditorPlatform;
