import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { useSelector } from "react-redux";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import { copySharableURL, view } from "../../../../utils/API";
import {
  EMBED_VARIANTS,
  getExcludeFields,
  renderCell,
  standardizeColor,
  timeAgo,
} from "../../../../utils/helper";
import { getLayerSymbology } from "../../../../utils/symbologies";
import ClusterDetails from "../../../CustomPopupWrapper/components/ClusterDetails/ClusterDetails";
import getAllowedFields from "../../../CustomPopupWrapper/helpers/getAllowedFields";
import getPopupActions from "../../../CustomPopupWrapper/helpers/getPopupActions";
import { StyledFilterButton } from "../../../Dashboard/Insights/Insights-styled";

import FeatureDetailsTabs from "../FeatureDetailsTab";
import { TABS } from "../FeatureDetailsTab/FeatureDetailsTab";
import {
  StyledField,
  StyledInformation,
  StyledPopupWrapper,
} from "../MobilePopup-styled";
import Documents from "./Documents";
import FeatureHeading from "./FeatureHeading/FeatureHeading";
import FieldsSection from "./FieldsSection";
import PopupField from "./FieldsSection/PopupField";
import ImageSwiper from "./ImageSwiper/ImageSwiper";

const currSourceFieldName = "currsourcename";
const currInfoRelyFieldName = "currinforely";
const currAsOfDateFieldName = "asofdate";

const updatesInfoFields = [
  currAsOfDateFieldName,
  currSourceFieldName,
  currInfoRelyFieldName,
];

export const getLayerConfigFields = ({
  layer,
  layerConfigFields,
  symbologyFields,
  feature,
  t,
  config,
}) => {
  const fields = [];
  layerConfigFields.forEach((fieldName) => {
    if (symbologyFields.some((f) => f.field === fieldName)) return;

    const field = layer.fields.find((field) => field.name === fieldName);
    if (field) {
      fields.push(field);
    }
  });

  const results = [];
  fields.forEach((field) => {
    const attrValue = feature.attributes[field.name];

    if (attrValue !== null && attrValue !== undefined) {
      const value = renderCell(
        field,
        feature.attributes[field.name],
        t,
        config
      );
      results.push({
        title: t("layer.fieldAlias." + field.name + ".title", field.alias),
        value,
        fieldName: field.name,
      });
    }
  });

  return results;
};

const PopupBody = ({
  config,
  selectedFeature,
  expand,
  setFullScreenActive,
  t,
  i18n,
  isPopup = false,
}) => {
  const layer = selectedFeature.sourceLayer
    ? selectedFeature.sourceLayer
    : selectedFeature.layer;

  const fields = layer.fields;
  const layerConfig = layer.layerConfig;
  const layerSettings = layerConfig.customPopupOps;
  const [images, setImages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { activeModule, clusterFeature } = useSelector((state) => state);
  const [openSnackbar] = useCustomSnackbar();
  const [isPending, startTransition] = useTransition();
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef();

  useEffect(() => {
    setImages([]);
    if (!layer.capabilities.operations.supportsQueryAttachments) return;

    layer
      .queryAttachments({
        objectIds: [selectedFeature.attributes[layer.objectIdField]],
      })
      .then((response) => {
        const attachments = Object.values(response).flat(1);

        startTransition(() => {
          setImages(
            attachments?.filter((att) => att.contentType.startsWith("image")) ||
              []
          );
          setAttachments(
            attachments?.filter(
              (att) => !att.contentType.startsWith("image")
            ) || []
          );
        });
      });
  }, [selectedFeature, fields, layer]);

  const allowedFields = useMemo(() => {
    return getAllowedFields(layer, selectedFeature);
  }, [layer, selectedFeature]);

  const getFields = (filterFields) => {
    if (!Array.isArray(filterFields)) return null;

    return allowedFields.filter(
      (field) => filterFields.includes(field.name) && !!field
    );
  };

  const handleCopyLink = () => {
    const clonedFeature = selectedFeature.clone();
    if (
      view?.popup?.location &&
      clonedFeature.geometry &&
      clonedFeature.geometry?.type !== "point"
    ) {
      clonedFeature.geometry = view.popup.location;
    }

    copySharableURL({
      feature: selectedFeature,
      config,
      activeModule,
      openSnackbar,
      t,
    });
  };

  const handleEdit = () => {
    const editor = view.ui.find("Editor");

    setTimeout(() => {
      editor.expanded = true;
    }, 0);
  };

  const symbologyFields = useMemo(() => {
    const layer = selectedFeature.sourceLayer ?? selectedFeature.layer;
    const result = [];
    if (layer) {
      const symboloy = getLayerSymbology(layer, config);
      const { colorMap } = symboloy || {};

      if (colorMap) {
        const colorMapFields = colorMap.field
          ? [colorMap.field]
          : colorMap.fields;
        const layerFields = layer.fields.filter((field) =>
          colorMapFields.includes(field.name)
        );

        const colors = layerFields.map((field) => {
          const color =
            colorMap.default[selectedFeature.attributes[field.name]] ??
            symboloy.defaultColor;
          const standartColor = standardizeColor(color);
          if (color)
            return {
              value: renderCell(
                field,
                selectedFeature.attributes[field.name],
                t,
                config
              ),
              bg: standartColor.includes("#ffffff") ? "#000" : standartColor,
              color: "#fff",
              field: field.name,
              title: t(
                "layer.fieldAlias." + field.name + ".title",
                field.alias
              ),
            };
        });
        result.push(...colors);
      }
    }
    return result;
  }, [selectedFeature, config, t]);

  const overviewFields = useMemo(() => {
    return [
      ...getFields(layerSettings.overviewTable1Fields),
      ...getFields(layerSettings.overviewTable2Fields),
    ].filter((field) => !symbologyFields.some((f) => f.field === field.name));
  }, [layerSettings, symbologyFields]);

  const restFields = useMemo(() => {
    return allowedFields.filter(
      (field) =>
        !(
          overviewFields.some((f) => f.name === field.name) ||
          symbologyFields.some((f) => f.field === field.name)
        )
    );
  }, [layer, selectedFeature, overviewFields, symbologyFields]);

  const situationalFields = useMemo(() => {
    if (!Array.isArray(layer?.layerConfig.situationalFields)) return [];
    const excludeFields = getExcludeFields(layer);
    const excludeFieldsSet = new Set(excludeFields || []);

    const res = getLayerConfigFields({
      layer,
      layerConfigFields: layer.layerConfig.situationalFields.filter(
        (item) => !excludeFieldsSet.has(item)
      ),
      config,
      t,
      feature: selectedFeature,
      symbologyFields,
    });
    return res.filter((item) => !updatesInfoFields.includes(item.fieldName));
  }, [layer, symbologyFields, selectedFeature]);

  const updateFields = useMemo(() => {
    const fields = getLayerConfigFields({
      layer,
      layerConfigFields: updatesInfoFields,
      config,
      t,
      feature: selectedFeature,
      symbologyFields,
    });

    const result = {};
    fields.forEach((info) => {
      result[info.fieldName] = info;
    });

    return result;
  }, [layer, symbologyFields, selectedFeature]);

  const baseLineFields = useMemo(() => {
    if (!Array.isArray(layer?.layerConfig.baselineFields)) return [];

    const res = getLayerConfigFields({
      layer,
      layerConfigFields: layer.layerConfig.baselineFields,
      config,
      t,
      feature: selectedFeature,
      symbologyFields,
    });

    return res;
  }, [layer, symbologyFields, selectedFeature]);

  const tabs = useMemo(() => {
    const result = [];
    if (selectedFeature.attributes.lcapageid) {
      result.push(TABS.LCA);
    }
    return result;
  }, [selectedFeature, attachments]);

  const format = (value) => {
    if (!value) return;
    return timeAgo(new Date(value), i18n.language, false);
  };

  const statusColor = useMemo(() => {
    if (symbologyFields.length > 0) {
      return standardizeColor(symbologyFields[0].bg);
    }

    return "#a6a6a6";
  }, [symbologyFields]);

  const showUpdateText = useMemo(() => {
    return (
      updateFields[currAsOfDateFieldName]?.value ||
      updateFields[currSourceFieldName]?.value ||
      updateFields[currInfoRelyFieldName]?.value
    );
  }, []);

  const actionButtons = useMemo(
    () => getPopupActions({ t, layer, config }),
    [t, layer, config]
  );
  const isAppEmbed = useMemo(
    () => config.embed === EMBED_VARIANTS.APP,
    [config]
  );

  const shareButton = useMemo(() => {
    const shareUpdateButtonIndex = actionButtons.findIndex(
      (btn) => btn.id === "report-update"
    );
    if (shareUpdateButtonIndex > -1) {
      return {
        index: shareUpdateButtonIndex,
        button: actionButtons[shareUpdateButtonIndex],
      };
    }

    return null;
  }, [actionButtons]);

  const handleReportUpdate = useCallback(() => {
    if (!shareButton) return;
    view.popup.triggerAction(shareButton.index);
  }, [view, shareButton]);

  const renderUpdateText = () => {
    const [informationUpdated, on, from] = t("screen.popup.update", "")
      .split("...")
      .map((item) => item.trim());

    let title = informationUpdated;
    const date = updateFields[currAsOfDateFieldName]?.value;
    const source = updateFields[currSourceFieldName]?.value;
    const rely = updateFields[currInfoRelyFieldName]?.value;
    if (date) {
      title += ` ${on} <span>${format(date)}</span>`;
    }

    if (source) {
      title += ` ${from} <span>${source}</span>`;
    }

    if (rely) {
      if (!source) {
        title += ` ${from}`;
      }
      title += `<span> (${rely})</span>`;
    }

    return title;
  };

  // const refInit = useCallback((e)=>{
  //   if (!e) return;
  //   setTimeout(()=>{
  //     const title = e.querySelector('#popup-sticky-title');
  //     const situationalFields = e.querySelector('#popup-situational-fields');
  //
  //     const height = (title?.clientHeight || 50) + (situationalFields?.clientHeight || 0);
  //     e.style.maxHeight = height + 10 + 'px';
  //   },150)
  // },[])

  // const refPass = useCallback((el)=>{
  //   if (!el) return;
  //   setTimeout(()=>{
  //     console.log(el.clientHeight, el.offsetHeight)
  //     setHeaderHeight(el.clientHeight)
  //   },1000)
  // },[])

  const isArabic = useMemo(() => {
    return i18n.language === "ar";
  }, [i18n.language]);

  return (
    <div>
      {!!clusterFeature && (
        <div style={{ padding: "0px 8px" }}>
          <ClusterDetails
            fields={allowedFields}
            feature={selectedFeature}
            t={t}
            config={config}
            layer={layer}
          />
        </div>
      )}
      <StyledPopupWrapper isPopup={isPopup}>
        <div
          ref={headerRef}
          style={{
            position: "sticky",
            top: "0px",
            background: "#fff",
            padding: "8px 8px 0px 8px",
            zIndex: 1,
          }}
        >
          <FeatureHeading
            feature={selectedFeature}
            t={t}
            handleShare={handleCopyLink}
            actionButtons={actionButtons}
            expanded={expand}
            isPopup={isPopup}
          />
        </div>
        <div
          id="popup-situational-fields"
          style={{
            border: `1px solid ${statusColor}4d`,
            borderRadius: 8,
            margin: 4,
            padding: "2px 0px",
          }}
        >
          <div
            style={{
              padding: "0px 8px 0px",
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {symbologyFields.map((field) => (
              <StyledField
                key={field.title}
                isPopup={isPopup}
                dir={isArabic ? "rtl" : ""}
              >
                <p>
                  <span>
                    {field.title + ": "}{" "}
                    <span
                      style={{ color: field.bg, fontWeight: 600 }}
                      dangerouslySetInnerHTML={{ __html: field.value }}
                    />
                  </span>
                </p>
              </StyledField>
            ))}
            {situationalFields.length > 0 &&
              situationalFields
                .slice(
                  0,
                  expand || !!clusterFeature ? situationalFields.length : 1
                )
                .map((field) => (
                  <PopupField
                    key={field.title}
                    title={field.title}
                    value={field.value}
                    resetLimit={!expand}
                    onClick={() => {
                      setFullScreenActive(true);
                    }}
                    isPopup={isPopup}
                    i18n={i18n}
                    feature={selectedFeature}
                    field={field}
                    t={t}
                    isExpanded={expand}
                    fromPopup
                  />
                ))}
            {showUpdateText && (
              <StyledInformation
                dir={isArabic ? "rtl" : ""}
                isPopup={isPopup}
                dangerouslySetInnerHTML={{ __html: renderUpdateText() }}
              />
            )}
            {!isAppEmbed && !!shareButton && (
              <StyledFilterButton
                onClick={handleReportUpdate}
                style={{
                  margin: "auto",
                  marginBottom: 8,
                  borderRadius: 100,
                  background: statusColor,
                  borderColor: statusColor,
                  color: "#fff",
                }}
              >
                {shareButton.button.title}
              </StyledFilterButton>
            )}
          </div>
        </div>
        {expand && (
          <>
            <ImageSwiper images={images} height={"200px"} />
            <Documents attachments={attachments} t={t} />
            <div
              style={{
                padding: "8px 8px 4px",
              }}
            >
              {baseLineFields.length > 0 && (
                <FieldsSection
                  title={t("screen.popup.actions.seeMore")}
                  fields={baseLineFields}
                  show={isPopup ? false : tabs.length === 0}
                  setFullScreenActive={setFullScreenActive}
                  resetFields={expand}
                  isPopup={isPopup}
                  hasLca={!!selectedFeature.attributes.lcapageid}
                  i18n={i18n}
                  t={t}
                  feature={selectedFeature}
                />
              )}
            </div>
            <FeatureDetailsTabs
              config={config}
              feature={selectedFeature}
              layer={layer}
              attachments={attachments}
              tabs={tabs}
              t={t}
              headerRef={headerRef}
            />
          </>
        )}
      </StyledPopupWrapper>
    </div>
  );
};

export default PopupBody;
