import React, { useCallback, useContext } from "react";
import {
  getIconValueDefinitions,
  getLayerSymbology,
} from "../../../../../utils/symbologies";
import {
  iconNameToIcon,
  svgToBase,
} from "../../../../Icons/layerSvg/clusterAssets";
import { StyledList, StyledListItem, StyledTitle } from "../../Editor-styled";
import { LayerIconWrapper } from "../../../../ManualWidgets/ManualWidget-styled";
import { EditorContext } from "../../EditorContextProvider";

const icons = {
  2: "heliport",
  3: "airstrip",
};
const AerodromeType = ({ layer, config, handleSelection, t }) => {
  const { activeColor } = useContext(EditorContext);

  const renderFields = useCallback(() => {
    const layerField = layer.fields.find(
      (field) => field.name === "aerodrometype"
    );
    if (layerField && layerField.domain) {
      const symbology = getLayerSymbology(layer, config);
      return layerField.domain.codedValues
        .filter((item) => item.code !== 0)
        .map((codedValue) => {
          const iconName = icons[codedValue.code]
            ? icons[codedValue.code]
            : symbology.defaultIcon;
          return {
            icon: svgToBase(
              icons[codedValue.code]
                ? icons[codedValue.code]
                : symbology.defaultIcon,
              symbology.defaultColor
            ),
            label: codedValue.name,
            code: codedValue.code,
            iconName: iconName,
            fieldName: "aerodrometype",
          };
        });
    }
    return [];
  }, [layer, config]);

  const getCodedValueDefinition = useCallback(
    (type) => {
      const symbology = getLayerSymbology(layer, config);
      const iconValueDefinition = getIconValueDefinitions(
        symbology,
        layer,
        1,
        t
      );
      const filteredIconDefinition = iconValueDefinition.filter((item) => {
        const [aerodromeClass, aerodromeType] = item.value.split(",");
        return aerodromeType == type;
      });

      return filteredIconDefinition.length;
    },
    [layer, config, t]
  );

  return (
    <div style={{ paddingTop: "8px" }}>
      <StyledTitle>
        {t("screen.widget.Editor.manager.aerodromeType")}
      </StyledTitle>
      <StyledList>
        {renderFields().map((item) => {
          const icon = iconNameToIcon(item.iconName);
          return (
            <StyledListItem
              key={item.label}
              onClick={() => {
                handleSelection({
                  code: item.code,
                  iconName: item.iconName,
                  fieldName: item.fieldName,
                });
              }}
            >
              <LayerIconWrapper
                width={40}
                height={40}
                fill={activeColor}
                dangerouslySetInnerHTML={{ __html: icon }}
              />
              {/*<div style={{*/}
              {/*  width:'40px',*/}
              {/*  height:'40px',*/}
              {/*}}>*/}
              {/*  */}
              {/*  <img*/}
              {/*    alt={item.label}*/}
              {/*    style={{*/}
              {/*      width:'100%',*/}
              {/*      height:'100%',*/}
              {/*      objectFit:'contain'*/}
              {/*    }}*/}
              {/*    src={item.icon} */}
              {/*  />*/}
              {/*</div>*/}
              <span>{item.label}</span>
            </StyledListItem>
          );
        })}
      </StyledList>
    </div>
  );
};

export default AerodromeType;
