import {
  StyledProgressBarContainer,
  StyledProgressBar,
} from "./ProgressBar-styled";
import React from "react";

const ProgressBar = ({
  total,
  currentPosition,
  color,
  hasTopRadius,
  bgColor,
  isLoading
}) => {
  return (
    <StyledProgressBarContainer bgColor={bgColor} hasTopRadius={hasTopRadius}>
      <StyledProgressBar
        color={color}
        progress={(100 / total) * currentPosition}
        isLoading={isLoading}
      />
    </StyledProgressBarContainer>
  );
};

export default ProgressBar;
