import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { EntryPoints } from "../Platform/Platform";
import EditableFeaturesList from "../EditFeatures/EditableFeaturesList";
import { EditorContext, UpdateType } from "../EditorContextProvider";
import {
  MobileListTabs,
  MobileListTabsWrapper,
} from "../../../MobileList/MobileList-styled";
import { useTranslation } from "react-i18next";
import LayerIcon from "../../../Icons/LayerIcon";
import Tooltip from "../../../UI/Tooltip";
import { useSelector } from "react-redux";

const EntryPointsList = ({
  editableLayers,
  handleNext,
  handleClick,
  editType,
  layers,
}) => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const { setEditableLayer, editableLayer, activeColor, updateType } =
    useContext(EditorContext);
  const { layersUpdates } = useSelector((state) => state.cycleManagerReducer);

  useEffect(() => {
    if (!editableLayer && layers.length > 0) {
      setEditableLayer(layers[0]);
    }
  }, []);

  const isSituational = useMemo(
    () => updateType === UpdateType.situational,
    [updateType]
  );

  /**
   * Calculates the percentage value for a given layer.
   *
   * @param {Object} layer - The layer object containing configuration details.
   * @param {Object} layer.layerConfig - The configuration object for the layer.
   * @returns {string} The percentage value as a string with a '%' suffix.
   */
  const getLayerPercentage = useCallback(
    (layer) => {
      if (!isSituational) return 0;
      const { percent = 0 } = layersUpdates[layer.layerConfig.id] || {};
      return `${percent}%`;
    },
    [layersUpdates, isSituational]
  );

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {/* <MobileListTabsWrapper
        style={{
          paddingTop: 6,
          background: "#ffffff",
          overflow: "auto",
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        <div style={{ display: "flex", gap: 10, margin: "auto" }}>
          {layers.map((l) => {
            const updatePercent = getLayerPercentage(l);
            return (
              <Tooltip
                key={l.id}
                showOnHover
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>{l.getLayerTitle(t)}</span>
                    {isSituational && <span>{updatePercent} Up to date</span>}
                  </div>
                }
              >
                <MobileListTabs
                  key={l.id}
                  selected={editableLayer === l}
                  onClick={() => handleTabSelection(l)}
                  color={activeColor}
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  {isSituational && (
                    <div
                      style={{
                        position: "absolute",
                        width: updatePercent,
                        height: "100%",
                        background: "#b8b8b8",
                        left: 0,
                        top: 0,
                        opacity: 0.4,
                      }}
                    />
                  )}
                  <LayerIcon
                    layer={l}
                    config={config}
                    t={t}
                    opsColor={activeColor}
                    width={34}
                    height={34}
                    textColor="#344054"
                    fill="#344054"
                  />
                </MobileListTabs>
              </Tooltip>
            );
          })}
        </div>
      </MobileListTabsWrapper> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {editableLayer && (
          <EditableFeaturesList
            editableLayer={editableLayer}
            handleSelectFeature={handleClick}
            handleNext={handleNext}
            editableLayers={editableLayers}
            editType={editType}
            isEntryPoints
          />
        )}
      </div>
    </div>
  );
};

export default EntryPointsList;
