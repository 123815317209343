import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useSearchLayers, useSearchWidget } from "../../../../../../../hooks";
import { view } from "../../../../../../../utils/API";
import { ConfigContext } from "../../../../../../../utils/ConfigContext";
import Input from "../../../../../../Report/new/Input/Input";
import CoordinatesPanel from "../../../../../../SearchWidget/CoordinatesPanel";
import { checkForCoordinates } from "../../../../../../SearchWidget/helpers";
import Suggestions from "../../../../../../SearchWidget/Suggestions";
import { PortalWrapper } from "../../../../../../SharedComponents/PortalWrapper/PortalWrapper";
import { StyledSearchInputContainer } from "../../../../Editor-styled";
import { baseLineColor } from "../../../../EditorContextProvider";

const SearchInput = ({
  point,
  handleInputChange,
  isActive,
  isEdited,
  onRemove,
  onFocus,
  handleSelectSuggestion,
  onLocateCoordinates,
  onInputChange,
  activeColor,
  ...props
}) => {
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.disabled) {
      handleBlur();
    }
  }, [props.disabled]);

  // Core search functionality
  const {
    search,
    value: searchValue,
    setValue,
    loading,
    suggestions,
    activeCoordinates,
    selectedResultKey,
    showCoordinates,
    showDropdown,
    showNoResults,
    showSuggestions,
    suggestionsLength,
    resetSearch,
    handleInputChange: handleSearchInputChange,
    handleFocus: onSearchFocus,
    handleBlur,
    handleSuggestionClick,
    handleCoordinates,
    handleKeyPress,
    locateCoordinates,
    locateButtonClick,
    suggestionRef,
    setActiveCoordinates,
  } = useSearchWidget(view, config, t, point.label, false);

  useSearchLayers(search, view, t, config, []);

  useEffect(() => {
    setValue(point.label);
  }, [point]);

  const borderColor = useMemo(() => {
    if (point.coordinate.length === 0) {
      return baseLineColor;
    } else if (point.searchable) {
      return "#16a34a";
    } else {
      if (isEdited) {
        return "#fbbf24";
      } else {
        return "#ef4444";
      }
    }
  }, [point, isEdited]);

  const handleFocus = useCallback(
    (e) => {
      const { value } = e.target;

      const result = checkForCoordinates(value);
      if (result && !result.error) {
        setActiveCoordinates({
          longitude: result.longitude,
          latitude: result.latitude,
        });
      }

      if (onSearchFocus) {
        onSearchFocus();
      }

      if (onFocus) {
        onFocus();
      }
    },
    [onFocus, onSearchFocus]
  );

  const onSuggestionSelect = useCallback(
    async (label, result) => {
      search.goToOverride = () => {
        // const targetFeature = params.target.target;
        // console.log(targetFeature);

        // targetFeature.name = rest.text;
        // addPointToMap(targetFeature, activeIndex);
        // view.goTo({ target: params.target.target, zoom: 8 });
        // const { signal } = params;
        // signal.abort();
        return null;
      };

      const coordinates = await handleSuggestionClick(label, result, false);

      if (handleSelectSuggestion) {
        handleSelectSuggestion(label, result, coordinates, search);
      }
    },
    [handleSelectSuggestion, handleSuggestionClick, search]
  );

  const handleLocateCoordinates = useCallback(() => {
    locateButtonClick();

    if (!!activeCoordinates?.longitude && !!activeCoordinates?.latitude) {
      const point = {
        type: "point",
        longitude: activeCoordinates.longitude,
        latitude: activeCoordinates.latitude,
      };

      onLocateCoordinates(point);
    }
  }, [locateButtonClick, activeCoordinates, onLocateCoordinates]);
  const locateTimer = useRef(null);

  return (
    <StyledSearchInputContainer ref={inputRef}>
      <Input
        value={searchValue ?? ""}
        borderColor={borderColor}
        onChange={async (e) => {
          clearTimeout(locateTimer.current);
          const res = await handleSearchInputChange(e);
          if (res.type === "coordinates") {
            locateTimer.current = setTimeout(() => {
              const coordinates = {
                latitude: res.result.latitude,
                longitude: res.result.longitude,
              };
              locateCoordinates(coordinates);
              onLocateCoordinates(coordinates);
            }, 1000);
          }
          onInputChange(e);
        }}
        noMargin
        style={{ paddingRight: 22 }}
        isFocused={isActive}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            handleLocateCoordinates();
          }
        }}
        className="search-input"
        {...props}
      >
        <div
          onClick={() => {
            const e = {
              target: {
                value: "",
              },
            };
            handleSearchInputChange(e);
            onInputChange(e);
            if (onRemove) {
              onRemove();
            }
          }}
          style={{
            position: "absolute",
            right: 5,
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            padding: 2,
            display: "flex",
            opacity: searchValue ? 1 : 0,
            pointerEvents: searchValue ? "auto" : "none",
          }}
        >
          <svg
            stroke="currentColor"
            fill="#939393"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="14px"
            width="14px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
          </svg>
        </div>
      </Input>
      <PortalWrapper
        wrapperStyle={{
          width: "100%",
        }}
        updatePosition={showSuggestions}
      >
        <Suggestions
          ref={suggestionRef}
          showSuggestions={showSuggestions}
          loading={loading}
          showNoResults={showNoResults}
          suggestions={suggestions}
          showCoordinates={showCoordinates}
          showDropdown={showDropdown}
          selectedResultKey={selectedResultKey}
          suggestionsLength={suggestionsLength}
          handleSuggestionClick={onSuggestionSelect}
          activeCoordinates={activeCoordinates}
          handleCoordinates={handleCoordinates}
          handleKeyPress={handleKeyPress}
          locateCoordinates={locateCoordinates}
          t={t}
          config={config}
          fontSize={12}
          color={activeColor}
        >
          <CoordinatesPanel
            handleCoordinates={handleCoordinates}
            activeCoordinates={activeCoordinates}
            handleKeyPress={handleKeyPress}
            locateCoordinates={handleLocateCoordinates}
            t={t}
          />
        </Suggestions>
      </PortalWrapper>
    </StyledSearchInputContainer>
  );
};

export default SearchInput;
