import React, { useCallback, useEffect, useRef, useState } from "react";
import { Comment } from "./LatestUpdates-styled";

const UpdateComment = ({
  text,
  textLimit = 90,
  onClick,
  resetLimit = true,
  ...props
}) => {
  const ref = useRef();
  const [limit, setLimit] = useState(textLimit);

  useEffect(() => {
    setLimit(textLimit);
  }, [textLimit]);

  useEffect(() => {
    if (!resetLimit) return;
    setLimit(textLimit);
  }, [resetLimit]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      setLimit(text.length + 1);
      if (onClick) {
        onClick();
      }
    },
    [text, onClick]
  );

  function truncateOnWord(str, limit) {
    const trimmable =
      "\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF";
    const reg = new RegExp("(?=[" + trimmable + "])");
    const words = str.split(reg);
    let count = 0;

    const text = words
      .filter(function (word) {
        count += word.length;
        return count <= limit;
      })
      .join("");

    return str.length < limit ? (
      <span>{text}</span>
    ) : (
      <span>
        {text.trim()}...
        {/* <span onClick={handleClick} className="more">
          more
        </span> */}
      </span>
    );
  }

  return (
    <Comment
      ref={ref}
      // onClick={handleClick}
      {...props}
    >
      {truncateOnWord(text, limit)}
    </Comment>
  );
};

export default UpdateComment;
