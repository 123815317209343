import React, { useEffect, useState } from "react";
import { loadModules } from "esri-loader";

const useMapExtentFilter = ({ view, setFilterGeometry }) => {
  const state = useState(false);
  const [isFilterMapExtentActive, _setFilterMapExtentActive] = state;
  useEffect(() => {
    let handler;
    if (!view) return;

    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      if (isFilterMapExtentActive) {
        handler = reactiveUtils.when(
          () => !!view?.stationary,
          (stationary) => {
            if (!isFilterMapExtentActive) return;

            if (setFilterGeometry) {
              setFilterGeometry(view.extent);
            }
          },
          { initial: true }
        );
      }
    });
    return () => {
      handler?.remove();
      setFilterGeometry(null);
    };
  }, [isFilterMapExtentActive]);

  return state;
};

export default useMapExtentFilter;
