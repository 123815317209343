import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  currAsOfDateFieldName,
  showpublicFieldName,
  view,
} from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { EditorContext } from "../../Editor/EditorContextProvider";
import CycleManagerBatchUpdate from "../CycleManagerBatchUpdate/CycleManagerBatchUpdate";
import ChecklistHeader from "./ChecklistHeader/ChecklistHeader";
import ChecklistTable from "./ChecklistTable";

import ChecklistSidebar from "./CheckListSidebar/ChecklistSidebar";

import { useTranslation } from "react-i18next";
import useLayerEditTable from "../../../../hooks/LayerEditTable";
import { setActiveModule } from "../../../../redux/action/ActiveModule-actions";
import { MessagesOverlay } from "../../../LayerTable/LayerEditTable";
import {
  getFeatureNameField,
  getFeatureNameFields,
  getSituationalBatchFields,
} from "../../Editor/helpers";

const Checklist = ({
  layers,
  editedFeatures,
  setEditedFeatures,
  openSnackbar,
  handleClose,
  closeCycleManager,
  setShowCloseMessage,
  showCloseMessage,
}) => {
  const {
    editableLayer,
    setEditableLayer,
    setBatchUpdateFeatures,
    batchUpdateFeatures,
  } = useContext(EditorContext);
  const { cycleUpdateDueDate, lastCycleUpdate } = useSelector(
    (state) => state.cycleManagerReducer
  );
  const activeModule = useSelector((state) => state.activeModule);
  const { config } = useContext(ConfigContext);
  // const [loading, setLoading] = useState(false);
  // const [features, setFeatures] = useState([]);
  // const [gridApi, setGridApi] = useState(null);
  // const [showBatchUpdate, setShowBatchUpdate] = useState(false);
  // const [saveMessage, setSaveMessage] = useState({
  //   newModule: null,
  //   newLayer: null,
  //   show: false,
  // });
  // const [requiredFieldsMessage, setRequiredFieldsMessage] = useState({
  //   show: false,
  //   fields: [],
  // });
  // const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  // const [graphicsLayer, setGraphicsLayer] = useState(null);
  // const [updatedFeatures, setUpdatedFeatures] = useState(null);

  const { t } = useTranslation("common");

  // const [updateFeaturesIds, setUpdateFeaturesIds] = useState([]);
  const [saved, setSaved] = useState(false);

  // updateFeaturesIds
  // graphicsLayer
  // updatedFeatures
  // showUpdateMessage
  // requiredFieldsMessage
  // setSaveMessage
  // saveMessage
  // showBatchUpdate
  // gridApi
  // setGridApi
  // setFeatures
  // features
  // loading

  const {
    onRowDataUpdated,
    handleUpdateAll,
    onBatchUpdateSave,
    handleLayerSelection,
    toggleBatchUpdate,
    updateFeaturesIds,
    graphicsLayer,
    updatedFeatures,
    showUpdateMessage,
    requiredFieldsMessage,
    setSaveMessage,
    saveMessage,
    showBatchUpdate,
    gridApi,
    setGridApi,
    setFeatures,
    features,
    loading,
  } = useLayerEditTable({
    editableLayer,
    setEditableLayer,
    setBatchUpdateFeatures,
    batchUpdateFeatures,
    editedFeatures,
    setEditedFeatures,
    updateType: "situational",
  });

  const dispatch = useDispatch();

  //moved
  // useEffect(() => {
  //   if (editableLayer) {
  //     setBatchUpdateFeatures([]);
  //     // graphicsLayer.removeAll();
  //     setUpdateFeaturesIds([]);
  //     if (showBatchUpdate) {
  //       setBatchUpdateFeatures([]);
  //       setShowBatchUpdate(false);
  //     }
  //   }
  // }, [editableLayer]);

  useEffect(() => {
    if (layers.length > 0 && !editableLayer) {
      const moduleLayers = config.modules[activeModule]?.layers || [];
      let firstModuleLayer = layers
        .filter((l) =>
          moduleLayers.includes(l.layerConfig?.alias || l.layerConfig?.extends)
        )
        .toArray()[0];

      layers.map((layer) => {
        const layerConfig = layer.layerConfig;

        if (
          layerConfig &&
          moduleLayers.includes(layerConfig.extends || layerConfig.alias) &&
          !firstModuleLayer
        ) {
          firstModuleLayer = layer;
        }
      });

      //moved
      // loadModules(["esri/layers/GraphicsLayer"]).then(([GraphicsLayer]) => {
      //   const checkListLayer = new GraphicsLayer();
      //   checkListLayer.originalId = "checklist";
      //   setGraphicsLayer(checkListLayer);
      //   view.map.add(checkListLayer);

      //   if (checkListLayer) {
      //     addLayerEffect([checkListLayer]);
      //   }
      // });
      setEditableLayer(firstModuleLayer);
    }
  }, [layers]);

  //moved
  // useEffect(() => {
  //   if (editableLayer && batchUpdateFeatures.length === 0) {
  //     setUpdateFeaturesIds([]);
  //   }
  // }, [editableLayer]);

  //moved
  // const toggleBatchUpdate = useCallback(() => {
  //   setShowBatchUpdate((prev) => !prev);
  // }, [showBatchUpdate]);

  //moved
  // const showSaveMessage = useCallback((layer, module) => {
  //   setSaveMessage({
  //     show: true,
  //     newLayer: layer,
  //     newModule: module,
  //   });
  // }, []);

  //moved
  // const handleLayerSelection = useCallback(
  //   (layer, module) => {
  //     if (
  //       Object.keys(editedFeatures).length > 0 ||
  //       updateFeaturesIds.length > 0
  //     ) {
  //       showSaveMessage(layer, module);
  //       return;
  //     }

  //     if (module) {
  //       dispatch(setActiveModule(module));
  //     }
  //     setEditableLayer(layer);
  //   },
  //   [editedFeatures, updateFeaturesIds]
  // );

  //moved
  // const processFeatureUpdates = (features, editedFeatures, objectIdField) => {
  //   const updateFeatures = [];
  //   const columnIds = new Set();

  //   const newFeatures = features.map((feat) => {
  //     const newAttributes =
  //       editedFeatures[feat.attributes[objectIdField]] || {};

  //     if (Object.keys(newAttributes).length > 0) {
  //       const newFeature = feat.clone();
  //       const updateFeature = newFeature.clone();
  //       newAttributes[objectIdField] = feat.attributes[objectIdField];

  //       updateFeature.attributes = newAttributes;
  //       Object.keys(newAttributes).forEach((k) => {
  //         newFeature.attributes[k] = newAttributes[k];
  //         columnIds.add(k);
  //       });

  //       updateFeatures.push(updateFeature);
  //       return newFeature;
  //     }

  //     return feat;
  //   });

  //   return { updateFeatures, columnIds: Array.from(columnIds), newFeatures };
  // };

  //moved
  // const updateCellStyles = (gridApi, columnIds, resetStyles = false) => {
  //   try {
  //     // Reset column styles
  //     columnIds.forEach((colId) => {
  //       const column = gridApi.getColumn(colId);
  //       if (!column) return;

  //       const colDef = column.getColDef();
  //       colDef.cellClass = () => null;
  //       column.setColDef(colDef);
  //     });

  //     return true;
  //   } catch (error) {
  //     console.error("Error updating grid after save:", error);
  //     return false;
  //   }
  // };

  //moved
  // const handleShowUpdateMessage = useCallback((features) => {
  //   setUpdatedFeatures(features);
  //   setShowUpdateMessage(true);

  //   setTimeout(() => {
  //     setShowUpdateMessage(false);
  //     setUpdatedFeatures(null);
  //   }, 3000);
  // }, []);

  // const handleUpdateAll = useCallback(async () => {
  //   if (!gridApi) {
  //     console.warn("Table not initialized");
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const layerUpdate = { ...layersUpdates[editableLayer.layerConfig.id] };
  //     const now = new Date().getTime();
  //     // Get selected rows from grid
  //     const selectedFeatureIds = [];
  //     const nodeMap = {};
  //     const objectIdField = editableLayer.objectIdField;

  //     //updating the rows
  //     gridApi.forEachNode((node) => {
  //       if (node.data.featureSelect) {
  //         selectedFeatureIds.push(node.data[editableLayer.objectIdField]);
  //         nodeMap[node.data[objectIdField]] = node;
  //       }
  //     });

  //     const { newFeatures: featuresWithSavedValues, columnIds } =
  //       processFeatureUpdates(features, editedFeatures, objectIdField);

  //     const requiredFields = editableLayer.layerConfig.requiredFields ?? [];
  //     const allMissingFields = new Set();
  //     // Function to check if a feature has all required fields and collect missing fields
  //     const getMissingFields = (feature) => {
  //       const missingFields = requiredFields.filter((field) => {
  //         if (
  //           field in feature.attributes &&
  //           (feature.attributes[field] === null ||
  //             feature.attributes[field] === undefined ||
  //             feature.attributes[field] === "") &&
  //           field !== currAsOfDateFieldName
  //         ) {
  //           allMissingFields.add(field);
  //           return true;
  //         }

  //         return false;
  //       });
  //       return missingFields;
  //     };

  //     const missingFieldsMap = new Map();
  //     // Use reduce to iterate through featuresToSave only once
  //     const validFeaturesToSave = featuresWithSavedValues.reduce(
  //       (acc, feature) => {
  //         const featId = feature.getObjectId();
  //         if (selectedFeatureIds.includes(featId)) {
  //           const missingFields = getMissingFields(feature);

  //           if (missingFields.length === 0) {
  //             acc.push(feature);
  //           } else {
  //             missingFieldsMap.set(feature.getObjectId(), missingFields);
  //           }
  //         }
  //         return acc;
  //       },
  //       []
  //     );

  //     if (missingFieldsMap.size > 0) {
  //       console.warn("No valid features to save");

  //       gridApi.forEachNode((node) => {
  //         const missingFields = missingFieldsMap.get(node.data[objectIdField]);

  //         if (missingFields) {
  //           const newData = {
  //             ...node.data,
  //             missingFields,
  //           };
  //           node.setData(newData);
  //         }
  //       });

  //       setRequiredFieldsMessage({
  //         show: true,
  //         fields: Array.from(allMissingFields).map((fieldName) =>
  //           t("layer.fieldAlias." + fieldName + ".title", fieldName)
  //         ),
  //       });

  //       setTimeout(() => {
  //         setRequiredFieldsMessage({
  //           show: false,
  //           fields: [],
  //         });
  //       }, 3000);
  //       return;
  //     }

  //     const featuresToSave = [];

  //     const newFeatures = featuresWithSavedValues.map((feat) => {
  //       const featId = feat.getObjectId();
  //       if (selectedFeatureIds.includes(featId)) {
  //         const clone = feat.clone();
  //         clone.attributes[currAsOfDateFieldName] = now;
  //         nodeMap[featId].data[currAsOfDateFieldName] = now;
  //         featuresToSave.push(clone);
  //         const node = nodeMap[featId];

  //         const newData = JSON.parse(JSON.stringify(node.data));
  //         newData.featureSelect = false;

  //         const newDate = formatToDate(now);
  //         newData[currAsOfDateFieldName] = newDate;

  //         newData.feature = clone;
  //         newData.missingFields = [];
  //         newData.update = updateValueFormatter(
  //           newData,
  //           newData.lastCycleUpdate,
  //           newData.cycleUpdateDueDate
  //         );
  //         node.setData(newData);
  //         // node.data = newData;

  //         return clone;
  //       }

  //       return feat;
  //     });

  //     const newOutDate = layerUpdate.outdated.filter(
  //       (feat) => !selectedFeatureIds.includes(feat.getObjectId())
  //     );

  //     if (featuresToSave.length === 0) return;

  //     await editableLayer.applyEdits({ updateFeatures: featuresToSave });

  //     updateCellStyles(gridApi, columnIds, true);

  //     editableLayer.refresh();

  //     layerUpdate.features = newFeatures;
  //     layerUpdate.outdated = newOutDate;

  //     gridApi.applyTransaction({ update: Object.values(nodeMap) });
  //     gridApi.refreshCells({
  //       force: true,
  //     });
  //     gridApi.refreshHeader();

  //     dispatch(
  //       addLayersUpdate({ id: editableLayer.layerConfig.id, layerUpdate })
  //     );

  //     const newLayersUpdates = {
  //       ...layersUpdates,
  //       [editableLayer.layerConfig.id]: layerUpdate,
  //     };

  //     // validateAndSaveCycleDate(newLayersUpdates);

  //     setSaved(true);
  //     setFeatures(newFeatures);
  //     setEditedFeatures({});
  //     setUpdateFeaturesIds([]);
  //     handleShowUpdateMessage(featuresToSave);
  //   } catch (err) {
  //     openSnackbar(t("screen.message.error"));
  //     console.warn(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [
  //   layersUpdates,
  //   editableLayer,
  //   gridApi,
  //   editedFeatures,
  //   features,
  //   handleShowUpdateMessage,
  // ]);

  //moved from Checklist
  // const onRowDataUpdated = useCallback(
  //   (params) => {
  //     const api = params.api;
  //     const featuresIdsSet = new Set(updateFeaturesIds);
  //     const batchData = [];

  //     // Check all rows for featureSelect state
  //     api.forEachNode((node) => {
  //       const objectId = node.data[editableLayer.objectIdField];

  //       const feature = node.data.feature;

  //       if (node.data?.featureSelect) {
  //         batchData.push({
  //           feature,
  //           graphic: null,
  //         });

  //         featuresIdsSet.add(objectId);
  //       } else {
  //         featuresIdsSet.delete(objectId);
  //       }
  //     });

  //     graphicsLayer.graphics.forEach((graphic) => {
  //       const objectId = graphic.getObjectId();

  //       const isSelected = featuresIdsSet.has(objectId);

  //       if (isSelected) {
  //         const index = batchData.findIndex(
  //           (item) => item.feature.getObjectId() === objectId
  //         );
  //         if (index > -1) {
  //           batchData[index].graphic = graphic;
  //         }
  //         graphic.visible = true;
  //       } else {
  //         graphic.visible = featuresIdsSet.size === 0;
  //       }
  //     });

  //     if (featuresIdsSet.size > 0) {
  //       setBatchUpdateFeatures(batchData);
  //       zoomToFeaturesExtent(batchData.map((item) => item.graphic));
  //     } else {
  //       zoomToFeaturesExtent(graphicsLayer.graphics.toArray());

  //       setBatchUpdateFeatures([]);
  //     }

  //     setUpdateFeaturesIds(Array.from(featuresIdsSet));
  //   },
  //   [editableLayer, updateFeaturesIds, graphicsLayer]
  // );

  // const onBatchUpdateSave = useCallback(
  //   (newFeatures) => {
  //     if (!gridApi) {
  //       console.warn("Table not initialized");
  //       return;
  //     }

  //     // Create map of new values by objectId
  //     const updatesMap = newFeatures.reduce((acc, feature) => {
  //       acc[feature.attributes[editableLayer.objectIdField]] =
  //         feature.attributes;
  //       return acc;
  //     }, {});

  //     setEditedFeatures({});

  //     // Update grid nodes
  //     const rowUpdates = [];
  //     const columnIds = new Set();

  //     gridApi.forEachNode((node) => {
  //       const objectId = node.data[editableLayer.objectIdField];
  //       const newValues = updatesMap[objectId];

  //       if (newValues) {
  //         // const { [currAsOfDateFieldName]: removed, ...filteredValues } =
  //         //   newValues;

  //         Object.keys(newValues).forEach((key) => {
  //           const value = newValues[key];
  //           node.data.feature.attributes[key] = value;
  //           if (key === currAsOfDateFieldName) {
  //             newValues[key] = formatToDate(newValues[key]);
  //           }
  //           columnIds.add(key);
  //         });

  //         const updatedData = {
  //           ...node.data,
  //           ...newValues,
  //           featureSelect: false,
  //           feature: node.data.feature.clone(),
  //         };
  //         updatedData.feature.attributes[currAsOfDateFieldName] =
  //           new Date().getTime();

  //         updatedData.update = updateValueFormatter(
  //           updatedData,
  //           updatedData.lastCycleUpdate,
  //           updatedData.cycleUpdateDueDate
  //         );

  //         node.updateData(updatedData);
  //         rowUpdates.push(node);
  //       }
  //     });

  //     updateCellStyles(gridApi, Array.from(columnIds));
  //     // Apply updates to grid
  //     gridApi.applyTransaction({
  //       update: rowUpdates,
  //     });
  //     handleShowUpdateMessage(newFeatures);
  //   },
  //   [gridApi, editableLayer]
  // );

  const generateQueryFields = useCallback(
    (editableLayer) => {
      if (!editableLayer) return [];
      const newSituationalFields = getSituationalBatchFields({
        editableLayer: editableLayer,
        config,
      });

      const name = getFeatureNameField(editableLayer);
      const names = getFeatureNameFields(editableLayer);
      const nameFields = editableLayer.fields.filter((f) =>
        names.includes(f.name)
      );

      const showPublic = editableLayer.fields.find(
        (f) => f.name === showpublicFieldName
      );

      const currAsOfDateField = editableLayer.fields.find(
        (f) => f.name === currAsOfDateFieldName
      );

      if (
        !newSituationalFields.some((f) => f.name === currAsOfDateFieldName) &&
        currAsOfDateField
      ) {
        newSituationalFields.push(currAsOfDateField);
      }

      if (
        !newSituationalFields.some((f) => f.name === showpublicFieldName) &&
        showPublic
      ) {
        newSituationalFields.unshift(showPublic);
      }

      if (
        nameFields.length > 0 &&
        newSituationalFields.filter((f) => names.includes(f.name)).length === 0
      ) {
        nameFields.forEach((field) => {
          newSituationalFields.push(field);
        });
      }

      // Sort - date field goes last
      newSituationalFields.sort((a, b) => {
        if (names.includes(a.name)) return -1;
        if (names.includes(b.name)) return 1;
        if (a.name === currAsOfDateFieldName) return 1;
        if (b.name === currAsOfDateFieldName) return -1;
        return 0;
      });

      return newSituationalFields;
    },
    [config]
  );

  return (
    <>
      {editableLayer && showBatchUpdate && (
        <CycleManagerBatchUpdate
          isCheckList={true}
          openSnackbar={openSnackbar}
          handlePrev={toggleBatchUpdate}
          onSave={onBatchUpdateSave}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <ChecklistSidebar
          editableLayer={editableLayer}
          layers={layers}
          onLayerSelect={handleLayerSelection}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            flex: 1,
            position: "relative",
          }}
        >
          <ChecklistHeader
            editedFeatures={editedFeatures}
            toggleBatchUpdate={toggleBatchUpdate}
            handleUpdateAll={handleUpdateAll}
            handleClose={() => {
              handleClose();
              view.map.remove(graphicsLayer);
            }}
            confirmUpdateCount={showBatchUpdate ? 0 : updateFeaturesIds.length}
            saveEnabled={Object.keys(editedFeatures).length > 0}
          />
          <div style={{ flex: 1, position: "relative" }}>
            {/* {showBatchUpdate && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "#eeeeeed4",
                  zIndex: 2,
                }}
              ></div>
            )}
            {requiredFieldsMessage.show && (
              <Message
                position="absolute"
                title={t("cycleManager.saveMessages.missingFields", { 1: "" })}
                subtitle={
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "inherit",
                      color: "red",
                    }}
                  >
                    {requiredFieldsMessage.fields.join(", ")}
                  </span>
                }
              />
            )}
            {saveMessage.show && (
              <Message
                position="absolute"
                title={t("cycleManager.areYouSure.defaultTitle")}
                subtitle={t("cycleManager.areYouSure.unsavedChanges")}
                onSubmit={() => {
                  if (saveMessage.newModule) {
                    dispatch(setActiveModule(saveMessage.newModule));
                  }
                  setEditableLayer(saveMessage.newLayer);
                  setSaveMessage({
                    show: false,
                    newLayer: null,
                  });
                }}
                onCancel={() => {
                  setSaveMessage({
                    show: false,
                    newLayer: null,
                  });
                }}
                confirmText="Save"
                color={config.opsColor}
              />
            )}
            {showCloseMessage && (
              <Message
                position="absolute"
                title={t("cycleManager.areYouSure.closeTitle")}
                subtitle={t("cycleManager.areYouSure.unsavedChanges")}
                onSubmit={() => {
                  closeCycleManager();
                  view.map.remove(graphicsLayer);
                }}
                onCancel={() => {
                  setShowCloseMessage(false);
                }}
                color={config.opsColor}
              />
            )}
            {showUpdateMessage && (
              <SaveMessage
                updateType={UpdateType.situational}
                color={config.opsColor}
                editType={EditType.edit}
                actionType="updated"
                position="absolute"
                updatedFeatures={updatedFeatures}
              />
            )} */}
            <MessagesOverlay
              batchUpdateFeatures={batchUpdateFeatures}
              editableLayer={editableLayer}
              activeColor={config.opsColor}
              showBatchUpdate={showBatchUpdate}
              requiredFieldsMessage={requiredFieldsMessage}
              saveMessage={saveMessage}
              onCloseMessageCancel={() => {
                setShowCloseMessage(false);
              }}
              showCloseMessage={showCloseMessage}
              updatedFeatures={updatedFeatures}
              showUpdateMessage={showUpdateMessage}
              onCloseMessageSubmit={() => {
                closeCycleManager();
                view.map.remove(graphicsLayer);
              }}
              onSaveMessageSubmit={() => {
                if (saveMessage.newModule) {
                  dispatch(setActiveModule(saveMessage.newModule));
                }
                setEditableLayer(saveMessage.newLayer);
                setSaveMessage({
                  show: false,
                  newLayer: null,
                });
              }}
              onSaveMessageCancel={() => {
                setSaveMessage({
                  show: false,
                  newLayer: null,
                });
              }}
            />
            {editableLayer &&
              cycleUpdateDueDate &&
              lastCycleUpdate &&
              graphicsLayer && (
                <ChecklistTable
                  layer={editableLayer}
                  generateQueryFields={generateQueryFields}
                  editedFeatures={editedFeatures}
                  setEditedFeatures={setEditedFeatures}
                  loading={loading}
                  gridApi={gridApi}
                  setGridApi={setGridApi}
                  setFeatures={setFeatures}
                  features={features}
                  onRowDataUpdated={onRowDataUpdated}
                  graphicsLayer={graphicsLayer}
                  openSnackbar={openSnackbar}
                  batchUpdateFeatures={batchUpdateFeatures}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Checklist;
