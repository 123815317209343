import styled from "styled-components";
import { Button } from "../../../UI";

export const StyledInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-end;
  justify-content: center;
  flex: 2;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledButton = styled.div`
  font-size: 12px;
  justify-content: space-between;
  color: var(--opsColor);
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledTextContainer = styled.div`
  font-size: 10px;

  span:first-child {
    color: #4b4b4b;
  }
`;
