import styled from "styled-components";

export const StyledLayerCircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  transition: all 0.2s;
  border: 3px solid
    ${({ selected, color }) => (selected ? color ?? "var(--opsColor)" : "#eee")};
  border-radius: 100px;
  min-width: 34px;
  cursor: pointer;
  overflow: hidden;
  /* box-sizing: border-box; */
`;
