import React, { useContext } from "react";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { MdOutlineNotificationsActive } from "../../../Icons";
import { Button } from "../../../UI";

const NotificationMenu = ({ onClick }) => {
  const { config } = useContext(ConfigContext);
  if (!config.allowPushNotifications) return;

  return (
    <div
      style={{
        position: "absolute",
        top: -4,
        right: 10,
      }}
    >
      <Button borderRadius="100px" onClick={onClick}>
        <MdOutlineNotificationsActive
          fill="#8a8a8a"
          width="20px"
          height="20px"
        />
      </Button>
    </div>
  );
};

export default NotificationMenu;
