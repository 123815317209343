import React, { createContext, useEffect, useState } from "react";

// Create the context
export const EditorSwiperContext = createContext(null);

export const sortTypes = Object.freeze({
  ascending: "ascending",
  descending: "descending",
});

const EditorSwiperContextProvider = ({ editableLayer, children }) => {
  const [filterGeometry, setFilterGeometry] = useState(null);
  const [filterGeometryActive, setFilterGeometryActive] = useState(false);
  const [filterByExtent, setFilterByExtent] = useState(false);
  const [sortType, setSortType] = useState(sortTypes.descending);
  const [updateStatusFilters, setUpdateStatusFilters] = useState([]);
  const [showPublicActive, setShowPublicActive] = useState(false);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!editableLayer) {
      setFilterGeometry(null);
      setFilterGeometryActive(false);
      setFilterByExtent(false);
      setSortType(sortTypes.descending);
      setUpdateStatusFilters([]);
      setShowPublicActive(false);
      setFilteredFeatures([]);
      setShowSearch(false);
      setSearchText("");
    }

    return () => {};
  }, [editableLayer]);

  return (
    <EditorSwiperContext.Provider
      value={{
        filterGeometry,
        setFilterGeometry,
        filterGeometryActive,
        setFilterGeometryActive,
        filterByExtent,
        setFilterByExtent,
        sortType,
        setSortType,
        updateStatusFilters,
        setUpdateStatusFilters,
        showPublicActive,
        setShowPublicActive,
        filteredFeatures,
        setFilteredFeatures,
        appliedFilters,
        setAppliedFilters,
        showSearch,
        setShowSearch,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </EditorSwiperContext.Provider>
  );
};

export default EditorSwiperContextProvider;
