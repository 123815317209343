import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getFeatureTitle } from "../../../../esri/custom-popup-content";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { RightArrowIcon } from "../../../Panel/components/Pagination/helpers";
import { StyledEditorBreadcrumbs } from "../Editor-styled";
import { EditorContext, UpdateType } from "../EditorContextProvider";
import { ALL_STEPS, EditType } from "../EditorSwiper/EditorSwiper";
import { getFeatureNameField } from "../helpers";
import FeatureHeader from "./FeatureHeader";
import LayerHeader from "./LayerHeader/LayerHeader";

const EditorHeader = ({
  slides,
  popupFeature,
  handlePrev,
  editType,
  activeIndex,
  goBack,
}) => {
  const {
    activeColor,
    updateType,
    editableLayer,
    setShowWarning,
    highlightSymbol,
    highlightFeature,
    setHighlightFeature,
    batchUpdateFeatures,
    addedPoints,
    hasEditedAttachments,
    setAddedPoints,
    setHighlightSymbol,
  } = useContext(EditorContext);
  const { config } = useContext(ConfigContext);
  const {
    formState: { isDirty },
    reset,
  } = useFormContext();

  const nameField = useMemo(
    () => getFeatureNameField(editableLayer),
    [editableLayer]
  );

  const value = useWatch({ name: nameField }) || null;

  const { t } = useTranslation("common");

  useEffect(() => {
    const currSlide = slides[activeIndex];
    if (
      !(
        (editableLayer &&
          currSlide?.id === ALL_STEPS.fillFields &&
          (isDirty ||
            (Array.isArray(addedPoints) && addedPoints.length > 0) ||
            hasEditedAttachments)) ||
        EditType.create === editType
      )
    )
      return;

    const handleBeforeUnload = (event) => {
      return;
      event.preventDefault();
      event.returnValue = ""; // This is required for Chrome to show the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    editableLayer,
    slides,
    activeIndex,
    isDirty,
    editType,
    addedPoints,
    hasEditedAttachments,
  ]);

  const progressTitle = useMemo(() => {
    if (editType === EditType.create) {
      return t("screen.widget.Editor.manager.header.adding");
    } else {
      if (updateType === UpdateType.baseline) {
        if (editableLayer) {
          return t("screen.widget.Editor.manager.header.editing");
        } else {
          return t("screen.widget.Editor.manager.baseline.workOnDataset");
        }
      } else if (updateType === UpdateType.situational) {
        return t("screen.widget.Editor.manager.header.updating");
      }
    }
  }, [updateType, editType, editableLayer]);

  const processForm = useCallback(
    (callback, action, condition = false) => {
      try {
        const currSlide = slides[activeIndex];
        if (
          (editableLayer &&
            currSlide?.id === ALL_STEPS.fillFields &&
            (isDirty ||
              (Array.isArray(addedPoints) && addedPoints.length > 0) ||
              hasEditedAttachments)) ||
          condition
        ) {
          setShowWarning({
            show: true,
            action: action,
          });
        } else {
          // resetFields();
          callback();
        }
      } catch (error) {
        // resetFields();
        callback();
      }
    },
    [slides, activeIndex, editableLayer, isDirty, editType, addedPoints]
  );

  const featureTitle = useMemo(() => {
    let title;
    if (highlightFeature) {
      title = getFeatureTitle(highlightFeature, t);
    }

    if (value != null && typeof value === "string") {
      title = value;
    }

    return title;
  }, [highlightFeature, t, editableLayer, value]);

  return (
    <StyledEditorBreadcrumbs
      style={{
        marginBottom: 0,
        minHeight: 54,
        background: "#a8b1b7",
      }}
      hasProgress
    >
      {/* <ProgressBar
        color={activeColor}
        currentPosition={activeIndex + 1 || 1}
        total={slides.length}
      /> */}
      {!popupFeature && editableLayer && (
        <button
          style={{
            zIndex: 1,
          }}
          onClick={() => {
            processForm(handlePrev, "back");
          }}
        >
          <RightArrowIcon width={18} color="#FFFFFF" />
        </button>
      )}

      <div
        style={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "8px",
          padding: "0px 10px",
          overflow: "hidden",
        }}
      >
        {!featureTitle ? (
          <LayerHeader
            editableLayer={editableLayer}
            editType={editType}
            updateType={updateType}
            t={t}
            config={config}
            activeColor={activeColor}
            batchUpdateFeatures={batchUpdateFeatures}
          />
        ) : (
          <FeatureHeader
            highlightSymbol={highlightSymbol}
            title={featureTitle}
            t={t}
            editableLayer={editableLayer}
            setHighlightFeature={setHighlightFeature}
          />
        )}
      </div>
      <button
        style={{ marginRight: 4 }}
        onClick={() => {
          processForm(goBack, "close", EditType.create === editType);
        }}
      >
        <svg
          fillRule="evenodd"
          role="img"
          viewBox="0 0 10 10"
          aria-label="description"
          fill="#fff"
        >
          <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path>
        </svg>
      </button>
    </StyledEditorBreadcrumbs>
  );
};

export default EditorHeader;
