import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  gap: 4px;
  background: ${({ bg }) => bg || "#ffffff"};
  border-radius: ${({ borderRadius }) => borderRadius || "100px"};
  border: ${({ border }) => border || "1px solid #eee"};
  min-width: ${({ minWidth }) => minWidth || "34px"};
  height: ${({ height }) => height || "34px"};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  color: ${({ color, disabled }) => (!disabled ? color : "#000")};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  margin: ${({ margin }) => margin || "0"};
  position: ${({ position }) => position};
  outline: none;
  span {
    font-size: inherit;
  }
`;

export const StyledFilterButton = styled.div`
  border: 1px solid
    ${({ isActive, activeColor }) =>
      isActive ? activeColor ?? "#535353" : "#e3e2e2"};
  background: ${({ isActive, activeColor, bg }) =>
    isActive ? activeColor ?? "#535353" : bg ?? "none"};
  color: ${({ isActive }) => (isActive ? "#fff;" : "#535353")};
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  svg {
    width: ${({ svgSize }) => svgSize || "16px"};
    height: ${({ svgSize }) => svgSize || "16px"};
    padding: 0;
    display: flex;
  }
`;
