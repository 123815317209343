import { loadModules } from "esri-loader";
import { APP_ENV } from "../data/constants";

const API_KEY = process.env.REACT_APP_LC_API_KEY || "";
const API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? ""
    : process.env.REACT_APP_LC_API || "";

const iosDevices = [
  "c0ade502a22d7c61f072ff58034265a49a8c6f40d613562c675113e534335749",
  // "63bcb8207f97e096b7085307d00a51a7049b44af399ae7a226f359c4cac51875", //m
];
const androidDevices = [
  "eObifk8tQTySnSO8gnOioz:APA91bHEckcjS6mIadceE5CJVGkLFEuKn4SARJFuiKCh008Xa13lFmD7aqWVpWg9aFMdkTaFE_KACbuPRPmoU5F3q5d9aIXkBIG3VWoS6a2X3sy60AcoJOM",
];

export const deafaultNotificationMode = Object.freeze({
  notification_channels: ["app-push"],
  send_by: ["activity_ids"],
  activity_ids: [],
  countries: [],
  tracking_ids: [],
  user_ids: [],
  user_group_ids: [],
  test_mode: false,
  ios_devices: [],
  android_devices: [],
  activity_override: false,
});

const getEnvNotificationMode = () => {
  const newMode = JSON.parse(JSON.stringify(deafaultNotificationMode));
  switch (APP_ENV) {
    case "qa":
      break;
    case "production":
      break;
    default: //development
      newMode.test_mode = true;
      newMode.activity_override = true;
      if (process.env.REACT_APP_DEV_MODE) {
        newMode.ios_devices = iosDevices;
        newMode.android_devices = androidDevices;
      }

      break;
  }

  try {
    const urlParams = new URLSearchParams(window.location.search);
    const testMode = urlParams.get("test_mode");
    const ios_devices = urlParams.get("ios_devices");
    const android_devices = urlParams.get("android_devices");

    if (testMode != null) {
      newMode.test_mode = testMode === "true";
    }

    if (ios_devices != null) {
      newMode.ios_devices = ios_devices.split(",").map((item) => item.trim());
    }

    if (android_devices) {
      newMode.android_devices = android_devices
        .split(",")
        .map((item) => item.trim());
    }
  } catch (err) {
    console.log(err);
  }

  return Object.freeze(newMode);
};

export const notificationMode = getEnvNotificationMode();

const sendNotification = (message, mode, options = {}) => {
  const data = {
    message,
    notification_mode: {
      ...notificationMode,
      ...mode,
    },
  };

  const isTestModeActive = data.notification_mode.test_mode;

  if (
    (process.env.NODE_ENV === "development" || APP_ENV === "development") &&
    !isTestModeActive
  ) {
    throw new Error("Sending notifications in development mode");
  }
  console.log({
    api: API_ENDPOINT,
    lcapi: process.env.REACT_APP_LC_API,
  });

  return loadModules(["esri/request"]).then(([esriRequest]) => {
    return esriRequest(`${API_ENDPOINT}/notifications`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      }),
      body: JSON.stringify(data),
      ...options,
    });
  });
  // return fetch(`${API_ENDPOINT}/notifications`, {
  //   method: "POST",
  //   mode: "cors",
  //   // Include credentials if needed (may be required for CORS)
  //   credentials: "same-origin",
  //   headers: new Headers({
  //     "Content-Type": "application/json",
  //     "x-api-key": API_KEY,
  //   }),

  //   body: JSON.stringify(data),
  //   ...options,
  // });
};

const getActivities = (params) => {
  const url = new URL(API_ENDPOINT);
  const searchParams = new URLSearchParams({
    ...params,
  });
  url.search = searchParams.toString();

  return fetch(`${url}/activities`, {
    method: "GET",
    headers: new Headers({
      "x-api-key": API_KEY,
    }),
  });
};

export { getActivities, sendNotification };
