import { loadModules } from "esri-loader";
import { useEffect, useRef } from "react";
import {
  getFeatureTitle,
  getLayerTitle,
} from "../../esri/custom-popup-content";
import { composeLayerQuery } from "../../esri/widgets/search";
import useLayersChange from "../useLayersChange";

/**
 * Hook to handle search layers functionality
 */
export default function useSearchLayers(search, view, t, config, layersToAdd) {
  const { layerCount, visibleLayersIds } = useLayersChange(config);
  const addedLayers = useRef(new Set());
  useEffect(() => {
    if (!search || !view) return;
    const initialExtent = view.extent;

    const addLayerToSearch = async (layer) => {
      const [LayerSearchSource, Query, Graphic, PictureMarkerSymbol] =
        await loadModules([
          "esri/widgets/Search/LayerSearchSource",
          "esri/rest/support/Query",
          "esri/Graphic",
          "esri/symbols/PictureMarkerSymbol",
        ]);

      if (layer.type !== "feature") return null;
      const searchConfig = layer.layerConfig?.search;
      if (searchConfig && searchConfig.enabled === false) return;

      // Filter out non-feature layers and the global country layer
      if (!layer.fields || layer.title === "World Countries (Generalized)")
        return;

      let searchFields = layer.fields
        .filter((field) => field.type === "string")
        .map((field) => field.name);
      if (searchFields.length === 0) return;

      // Filter fields based on config
      if (searchConfig && searchConfig.searchFields) {
        const fields = searchConfig.searchFields;
        if (!Array.isArray(fields) || fields.length === 0) {
          console.warn(
            "Layer configuration error found. Layer: " +
              layer.title +
              " Error: searchField attribute must be an array of strings with at least one value"
          );
        } else {
          searchFields = searchFields.filter((layerField) =>
            fields.includes(layerField)
          );
        }
      }

      // Check if layer already loaded
      if (
        search.sources.filter(
          (locator) => locator.layer && locator.layer === layer
        ).length > 0
      )
        return;

      const symbol = new PictureMarkerSymbol({
        url: "/assets/symbols/pin_symbol.svg",
        width: "34px",
        height: "34px",
      });

      let layerSearchSource = new LayerSearchSource({
        layer: layer,
        name: getLayerTitle(layer, t),
        popupEnabled: false,
        enableSuggestions: true,
        getSuggestions: (params) => {
          if (!layer.visible) return;

          const query = composeLayerQuery(
            params.suggestTerm,
            Query,
            config,
            layer,
            searchFields
          );
          return layer.queryFeatures(query).then((results) => {
            return results.features.map((feature) => {
              let suggestion = getFeatureTitle(feature, t);
              if (!suggestion)
                suggestion = t("screen.widget.Search.noTitle", "No title");

              return {
                key: feature.attributes[layer.objectIdField]
                  ? feature.attributes[layer.objectIdField]
                  : "key",
                text: suggestion,
                sourceIndex: params.sourceIndex,
                feature: feature,
              };
            });
          });
        },
        getResults: (params) => {
          if (!params.suggestResult || !params.suggestResult.feature)
            return null;

          return new Promise((resolve) => {
            const feature = params.suggestResult.feature;
            const graphic = new Graphic({
              geometry: feature.geometry,
              attributes: feature.attributes,
            });

            return resolve([
              {
                feature: feature,
                target: graphic,
                isLayerSearchSourceResult: true,
              },
            ]);
          });
        },
        filter: layer.definitionExpression
          ? { where: layer.definitionExpression }
          : {},
        exactMatch: false,
        outFields: ["*"],
        resultSymbol: symbol,
      });

      if (initialExtent) layerSearchSource.zoomScale = initialExtent.width / 3;

      if (addedLayers.current.has(layer.layerConfig.id)) return;
      addedLayers.current.add(layer.layerConfig.id);

      search.sources.push(layerSearchSource);
      search.sources.sort((a, b) => {
        if (a.layer && !b.layer) return 1;
        else if (!a.layer && b.layer) return -1;

        return a.name.localeCompare(b.name);
      });
    };

    // Add visible layers to search
    const visibleLayers = view.map.layers.filter((l) => visibleLayersIds[l.id]);
    visibleLayers.forEach((l) => {
      const alias = l.layerConfig?.extends || l.layerConfig?.alias;
      if (Array.isArray(layersToAdd) && layersToAdd.length > 0) {
        if (layersToAdd.includes(alias)) {
          addLayerToSearch(l);
        }
      } else {
        addLayerToSearch(l);
      }
    });
  }, [layerCount, search, t, config]);
}
