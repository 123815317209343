import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TrashBin } from "../../../../Icons";
import ActionButton from "../../../CycleManager/Checklist/ChecklistTable/ActionButton";
import { UpdateType } from "../../EditorContextProvider";
import { EditType } from "../../EditorSwiper/EditorSwiper";
import Message from "../../Message";

const DeleteButton = ({
  editType,
  updateType,
  editableLayer,
  getIsDeletedField,
  activeColor,
  count,
  isActive,
  doDelete,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const { t } = useTranslation("common");

  return (
    <>
      {showDelete && (
        <Message
          title={t("screen.widget.Editor.deleteConfirmation")}
          onSubmit={() => {
            doDelete();
            setShowDelete(false);
          }}
          onCancel={() => setShowDelete(false)}
          color={activeColor}
        />
      )}
      {editType === EditType.edit &&
      (updateType === UpdateType.baseline ||
        (editableLayer?.layerConfig &&
          editableLayer?.layerConfig?.allowDeleteWhenSituational)) &&
      getIsDeletedField() ? (
        <ActionButton
          Icon={TrashBin}
          color={activeColor}
          onClick={() => setShowDelete(true)}
          count={count}
          isActive={isActive}
          styles={{
            padding: "0px 8px",
          }}
        />
      ) : null}
    </>
  );
};

export default DeleteButton;
