import React, { useCallback, useMemo } from "react";
import {
  getFeatureTitle,
  getLayerTitle,
} from "../../../../../esri/custom-popup-content";
import { StyledEditableTitle } from "../../EditorFields/EditorFields-styled";
import { getFeatureNameField } from "../../helpers";

const FeatureHeader = ({
  highlightSymbol,
  title,
  highlightFeature,
  t,
  editableLayer,
  setHighlightFeature,
}) => {
  // const hasNameField = useMemo(() => {
  //   const isValid = false;
  //   if (editableLayer) {
  //     const lc = editableLayer.layerConfig;
  //     if (Array.isArray(lc.baselineFields)) {
  //       return lc.baselineFields.some((field) => field.name === nameField);
  //     }
  //   }

  //   return isValid;
  // }, [editableLayer]);

  // const isRequiredField = useMemo(() => {
  //   let isRequired = false;
  //   if (editableLayer) {
  //     const lc = editableLayer.layerConfig;
  //     isRequired = lc.requiredFields.includes(nameField);
  //   }

  //   return isRequired;
  // }, [editableLayer, nameField]);

  // const handleChange = useCallback(
  //   ({ name, value }) => {
  //     if (highlightFeature) {
  //       const clonedFeature = highlightFeature.clone();
  //       clonedFeature.attributes[name] = value;

  //       setHighlightFeature(clonedFeature);
  //     }
  //   },
  //   [highlightFeature]
  // );

  return (
    <>
      {highlightSymbol?.url ? (
        <img width={34} height={34} alt="feature" src={highlightSymbol?.url} />
      ) : highlightSymbol?.type === "simple-line" ? (
        <div
          style={{
            height: 3,
            width: 20,
            background: `rgb(${highlightSymbol.color.r}, ${highlightSymbol.color.g}, ${highlightSymbol.color.b})`,
          }}
        />
      ) : null}
      {title ? <StyledEditableTitle>{title}</StyledEditableTitle> : null}
      {/* {false ? (
        <EditableTitle
          highlightFeature={highlightFeature}
          t={t}
          editableLayer={editableLayer}
          handleChange={handleChange}
          nameField={nameField}
          isRequired={isRequiredField}
          featureTitle={featureTitle}
        />
      ) : featureTitle ? (
        <StyledEditableTitle>{featureTitle}</StyledEditableTitle>
      ) : null} */}
    </>
  );
};

export default FeatureHeader;
