import { setFeatureTableOpen_actionType } from "../constants";

const initialState = {
  open: false,
};
const featureTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case setFeatureTableOpen_actionType: {
      const { type, payload } = action;
      return {
        ...state,
        open: payload,
      };
    }
    default:
      return state;
  }
};

export default featureTableReducer;
