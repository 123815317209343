import { loadModules } from "esri-loader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { view } from "../../../../../utils/API";
import { FilterButton, Tooltip } from "../../../../UI";

const FilterGeometryButton = ({
  onDelete = undefined,
  onUpdate = undefined,
  onCreate = undefined,
  onActiveChange = undefined,
  disabled = false,
  isActive = false,
  setFilterGeometry = undefined,
  filterGeometry = undefined,
}) => {
  const sketchViewModelRef = useRef(null);
  const [filterActive, setFilterActive] = useState(isActive);
  const [sketchViewModel, setSketchViewModel] = useState(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (filterActive !== isActive) {
      setFilterActive(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    let sketchViewModel;
    let graphicsLayer;
    let timer;
    let clickHandler;
    if (!filterActive) return;

    loadModules([
      "esri/widgets/Sketch/SketchViewModel",
      "esri/layers/GraphicsLayer",
      "esri/Graphic",
    ]).then(([SketchViewModel, GraphicsLayer, Graphic]) => {
      graphicsLayer = new GraphicsLayer();
      graphicsLayer.title = "filter-sketch-layer";
      graphicsLayer.popupEnabled = false;

      view.map.add(graphicsLayer);

      clickHandler = view.on("click", (event) => {
        event.stopPropagation(); // Prevent the event from bubbling up
      });

      sketchViewModel = new SketchViewModel({
        view: view,
        layer: graphicsLayer,
        defaultUpdateOptions: {
          tool: "reshape",
          // enableRotation: false,
          enableScaling: false,
          enableZ: false,
          highlightOptions: {
            enabled: false,
          },
        },

        // pointSymbol: {
        //   type: "simple-marker",
        //   style: "circle",
        //   color: "#0091ea",
        //   size: "16px",
        //   outline: {
        //     color: "#0091ea",
        //     width: 3,
        //   },
        // },
        // polylineSymbol: {
        //   type: "simple-line",
        //   color: "blue",
        //   width: "4px",
        //   style: "dash",
        // },

        polygonSymbol: {
          type: "simple-fill",
          color: "#0091ea47",
          style: "solid",
          outline: {
            color: "#0091ea",
            width: 1,
          },
        },
      });

      // Add predefined geometry if it exists
      if (filterGeometry) {
        const graphic = new Graphic({
          geometry: filterGeometry,
          symbol: sketchViewModel.polygonSymbol,
        });
        graphicsLayer.add(graphic);
        sketchViewModel.update([graphic]);
      } else {
        sketchViewModel.create("polygon");
      }

      // sketchViewModel.create("polygon");
      sketchViewModelRef.current = sketchViewModel;
      setSketchViewModel(sketchViewModel);
    });

    return () => {
      if (sketchViewModel) {
        sketchViewModel.destroy();
        sketchViewModel = null;
      }
      if (graphicsLayer) {
        view.map.remove(graphicsLayer);
        graphicsLayer = null;
      }
      if (clickHandler) {
        clickHandler.remove();
      }
      if (setFilterGeometry) {
        setFilterGeometry(null);
      }
    };
  }, [filterActive]);

  useEffect(() => {
    if (!sketchViewModel) return;
    let timer;
    let create, update, del;

    create = sketchViewModel.on("create", (event) => {
      if (event.state === "complete") {
        const geometry = event.graphic.geometry;
        if (setFilterGeometry) {
          setFilterGeometry(geometry);
        }
        if (onCreate) {
          onCreate(geometry);
        }
      }
    });

    update = sketchViewModel.on("update", (event) => {
      if (event.state === "active") {
        clearTimeout(timer);
        timer = setTimeout(() => {
          const geometry = event.graphics[0].geometry;
          if (setFilterGeometry) {
            setFilterGeometry(geometry);
          }

          if (onUpdate) {
            onUpdate(geometry);
          }
        }, 250);
      }
    });

    del = sketchViewModel.on("delete", (event) => {
      if (setFilterGeometry) {
        setFilterGeometry(null);
      }

      setFilterActive(false);
      if (onDelete) {
        onDelete();
      }
    });

    return () => {
      create?.remove();
      update?.remove();
      del?.remove();
      clearTimeout(timer);
    };
  }, [sketchViewModel, onDelete, onUpdate, onCreate]);

  const handleFilterGeometry = useCallback(() => {
    const isActive = !filterActive;

    setFilterActive(isActive);
    if (onActiveChange) {
      onActiveChange(isActive);
    }

    if (!isActive && setFilterGeometry) {
      setFilterGeometry(null);
    }
  }, [filterActive, onActiveChange]);

  return (
    <Tooltip
      content={t("screen.widget.common.filterByGeometry")}
      showOnHover
      disabled={disabled}
    >
      <FilterButton
        isActive={filterActive}
        onClick={handleFilterGeometry}
        disabled={disabled}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 256 256"
          height="16px"
          width="16px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M233.47,97.45a36,36,0,0,0-50.92-50.92h0a36.18,36.18,0,0,0-4.12,4.95l-22.55-6.15a36,36,0,0,0-61.34-22.8h0a36.05,36.05,0,0,0-7.8,39.24L57.19,88.37a36.08,36.08,0,0,0-42.66,6.17h0a36,36,0,0,0,45.73,55.21l65.28,47.87A36,36,0,1,0,182.62,180L208,108A35.91,35.91,0,0,0,233.47,97.45Zm-93.74,80.81L74.45,130.39a36.19,36.19,0,0,0-1.21-24.17L102.8,79.61a36,36,0,0,0,42.66-6.16,35.47,35.47,0,0,0,4.12-5l22.55,6.15a35.86,35.86,0,0,0,10.42,22.8A38.06,38.06,0,0,0,185.4,100L160,172A36,36,0,0,0,139.73,178.26ZM216.5,63.5a12,12,0,1,1-17,0A12,12,0,0,1,216.5,63.5Zm-105-24a12,12,0,1,1,0,17A12,12,0,0,1,111.51,39.5Zm-80,89a12,12,0,1,1,17,0A12,12,0,0,1,31.5,128.49Zm137,88a12,12,0,1,1,0-17A12,12,0,0,1,168.49,216.5Z"></path>
        </svg>
        {/* <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 256 256"
        height="14px"
        width="14px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M229.23,50.78a30,30,0,0,0-42.44,0h0a29.82,29.82,0,0,0-5.56,7.69l-31.31-8.54A30,30,0,0,0,98.78,26.77h0A30.09,30.09,0,0,0,94.13,63.2L57.83,95.87a30.07,30.07,0,0,0-39.06,2.91h0a30,30,0,0,0,41.38,43.44l72.6,53.25a30,30,0,1,0,48.47-8.68,29.65,29.65,0,0,0-5.77-4.5l28.44-80.59A31.57,31.57,0,0,0,208,102a30,30,0,0,0,21.22-51.2Zm-122-15.52a18,18,0,1,1,0,25.46A18,18,0,0,1,107.27,35.26Zm-80,97.47a18,18,0,1,1,25.46,0A18,18,0,0,1,27.26,132.73Zm145.47,88a18,18,0,1,1,0-25.46A18,18,0,0,1,172.73,220.74Zm-8.61-42.43a30,30,0,0,0-24.27,7.48L67.24,132.54a30.14,30.14,0,0,0-1.38-27.75l36.3-32.67a30,30,0,0,0,44.62-10.61l31.31,8.54a30,30,0,0,0,8.7,23.16,30.47,30.47,0,0,0,5.78,4.51Zm56.62-93.59a18,18,0,1,1,0-25.46A18,18,0,0,1,220.74,84.72Z"></path>
      </svg> */}
      </FilterButton>
    </Tooltip>
  );
};

export default FilterGeometryButton;
