import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getFieldsByPattern } from "../../../../utils/helper";
import { getLayerSymbology } from "../../../../utils/symbologies";
import { EditorContext, UpdateType } from "../EditorContextProvider";
import { EditType } from "../EditorSwiper/EditorSwiper";
import AttributeFields from "./AttributeFields/AttributeFields";
import UploadMediaFiles from "../../../Editor/UploadMediaFiles/UploadMediaFiles";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { useTranslation } from "react-i18next";
import {
  AccordionBody,
  AccordionButton,
  AccordionWrapper,
} from "./Accordion/Accordion";
import CoordinatesSelection from "../CoordinatesSelection/CoordinatesSelection";
import { getFeatureNameField } from "../helpers";
import { useFormContext } from "react-hook-form";

export const getFields = ({
  fields,
  requiredFields = [],
  filteredFields = [],
  isRequired = false,
  editableLayer,
}) => {
  const nameField = getFeatureNameField(editableLayer);

  return fields.filter(
    (f) =>
      f.editable &&
      (isRequired
        ? requiredFields.includes(f.name)
        : !requiredFields.includes(f.name)) &&
      ![...filteredFields, nameField].includes(f.name)
  );
};

const EditorDefaultFields = ({
  editableLayer,
  requiredFields,
  isRequired,
  editType,
  handleChange,
}) => {
  const [selectedId, setSelectedId] = useState(undefined);

  const {
    attachments,
    setAttachments,
    previews,
    setPreviews,
    highlightFeature,
    addedPoints,
    popupFeature,
    updateType,
    activeColor,
    prefilledFields,
  } = useContext(EditorContext);
  const { formState } = useFormContext();

  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  const getFields = useCallback(
    (fields, requiredFields, filteredFields) => {
      return fields.filter(
        (f) =>
          f.editable &&
          (isRequired
            ? requiredFields.includes(f.name)
            : !requiredFields.includes(f.name)) &&
          !filteredFields.includes(f.name)
      );
    },
    [isRequired, prefilledFields]
  );

  const accordion = useMemo(() => {
    const el = editableLayer;
    if (!el) return [];
    const lc = el.layerConfig;

    const nameField = getFeatureNameField(editableLayer);
    const filteredFields = [
      nameField,
      // ...prefilledFields.map((item) => item.fieldName),
    ];
    const symbology = getLayerSymbology(editableLayer, config) || {};

    //to hide fields that are used for icon mapping
    // if (symbology.iconMap?.fields) {
    //   const iconFields = editableLayer.fields
    //     .filter((field) => symbology.iconMap.fields.includes(field.name))
    //     .map((f) => f.name);
    //   filteredFields.push(...iconFields);
    // }

    const baselineFields = getFields(
      getFieldsByPattern(el, lc.baselineFields),
      requiredFields,
      filteredFields
    );

    const acc = [];

    acc.push({
      id: "coordinates",
      label:
        editableLayer.geometryType === "point"
          ? t("screen.widget.Editor.location.label")
          : "",
      information: t("screen.widget.Editor.location.information"),
      show: editableLayer.geometryType === "point",
      component:
        editableLayer.layerConfig.titleLabel !== "roadsSituational" ? (
          <CoordinatesSelection
            config={config}
            editableLayer={editableLayer}
            editType={editType}
            clickActive={false}
            showLocationInput={true}
          />
        ) : null,
    });

    if (
      updateType === UpdateType.baseline ||
      editType === EditType.create ||
      popupFeature
    ) {
      if (baselineFields.length > 0) {
        acc.push({
          id: "baseline",
          label:
            (isRequired ? `${t("screen.widget.common.required")} ` : "") +
            t("screen.widget.Editor.baselineFields.label"),
          information: t("screen.widget.Editor.baselineFields.information"),
          component: (
            <AttributeFields
              fields={baselineFields}
              highlightFeature={highlightFeature}
              config={config}
              t={t}
              symbology={symbology}
              onChangeHandler={handleChange}
              editableLayer={editableLayer}
              requiredFields={requiredFields}
            />
          ),
        });
      }

      // if (updateType === UpdateType.situational || !isRequired){
      //   const situationalFields = getFields(getFieldsByPattern(el, lc.situationalFields), [], filteredFields);
      //   if (situationalFields.length > 0) {
      //     acc.push({
      //       id:'situational',
      //       label:t('screen.widget.Editor.situationalFields.label'),
      //       component: (
      //         <AttributeFields
      //           highlightFeature={highlightFeature}
      //           fields={situationalFields}
      //           config={config}
      //           t={t}
      //           symbology={symbology}
      //           editableLayer={editableLayer}
      //           requiredFields={requiredFields}
      //         />
      //       )
      //     })
      //   }
      // }

      if (
        editableLayer.capabilities.operations.supportsQueryAttachments &&
        !isRequired
      ) {
        acc.push({
          id: "upload",
          label: t("screen.widget.Editor.mediaFiles.label"),
          information: t("screen.widget.Editor.mediaFiles.information"),
          component: (
            <UploadMediaFiles
              layer={editableLayer}
              feature={highlightFeature}
              t={t}
              attachments={attachments}
              setAttachments={setAttachments}
              config={config}
              previews={previews}
              setPreviews={setPreviews}
              color={activeColor}
            />
          ),
        });
      }
    }

    return acc;
  }, [
    editableLayer,
    selectedId,
    highlightFeature,
    popupFeature,
    editType,
    t,
    attachments,
    setAttachments,
    previews,
    setPreviews,
    config,
    config,
    updateType,
    prefilledFields,
    getFields,
    requiredFields,
    activeColor,
  ]);

  const handleAccordionSelection = useCallback(
    (id) => {
      setSelectedId(id);
    },
    [selectedId]
  );

  //When the form is validating, select the first accordion
  useEffect(() => {
    if (formState.errors && formState.isValidating) {
      const filtered = accordion.filter((item) => item.id !== "coordinates");

      const index = filtered.length > 0 ? 0 : -1;
      if (index >= 0) {
        const item = filtered[index];
        setSelectedId(item.id);
      }
    }
  }, [formState]);

  //When step is opened at first time, set selection
  useEffect(() => {
    if (selectedId === undefined && accordion.length > 0) {
      const filtered = accordion.filter((item) => item.id !== "coordinates");
      if (filtered.length > 0) {
        setSelectedId(filtered[0].id);
      }
    }
  }, [accordion]);

  //When a point is added, select the coordinates accordion
  useEffect(() => {
    if (
      selectedId !== undefined &&
      selectedId !== "coordinates" &&
      accordion.some((item) => item.id === "coordinates" && item.show)
    ) {
      setSelectedId("coordinates");
    }
  }, [addedPoints]);

  return (
    <>
      {accordion.map((item, index) => {
        const isSelected =
          (selectedId !== undefined ? item.id === selectedId : index === 1) ||
          accordion.length === 1;
        return (
          <AccordionWrapper
            key={item.id}
            id={item.id}
            selected={isSelected}
            show={item.show}
          >
            <>
              <AccordionButton
                selected={isSelected || accordion.length === 1}
                label={item.label}
                config={config}
                onSelect={
                  accordion.length > 1
                    ? () => handleAccordionSelection(item.id)
                    : undefined
                }
                information={item.information}
                color={activeColor}
              />
              <AccordionBody
                hasPadding={item.id !== "coordinates"}
                selected={isSelected}
              >
                {item.component ??
                  (isRequired ? null : (
                    <UploadMediaFiles
                      layer={editableLayer}
                      feature={highlightFeature}
                      t={t}
                      attachments={attachments}
                      setAttachments={setAttachments}
                      config={config}
                    />
                  ))}
              </AccordionBody>
            </>
          </AccordionWrapper>
        );
      })}
    </>
  );
};

export default EditorDefaultFields;
