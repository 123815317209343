import {
  isdeletedFieldName,
  opidFieldName,
  showpublicFieldName,
} from "../../../../utils/API";
import { getConfigISO } from "../../../../utils/helper";
import { supportedGeometryTypes } from "../../../Editor/Editor";

export const getFieldDefaultValue = (f, config) => {
  if (f.name === "iso3" || f.name === "iso3a") {
    const iso = getConfigISO(config);
    return iso ? (Array.isArray(iso) ? iso[0] : iso) : "";
  } else if (f.name === opidFieldName) {
    return config.id;
  } else if (f.name === isdeletedFieldName || f.name === showpublicFieldName) {
    return 2;
  } else if (f.defaultValue) {
    return f.defaultValue;
  } else if ("defaultValue" in f) {
    return f.defaultValue;
  }
};

export const isEditable = (l) => {
  return (
    l.type === "feature" &&
    supportedGeometryTypes.includes(l.geometryType) &&
    l.editingEnabled &&
    l.layerConfig?.editable
  );
};
