import React, { useEffect, useState, useTransition } from "react";
import ImageSwiper from "../../MobileApp/MobilePopup/PopupBody/ImageSwiper/ImageSwiper";

const CardImages = ({ feature, layer }) => {
  const [images, setImages] = useState([]);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setImages([]);
    if (!layer.capabilities.operations.supportsQueryAttachments) return;

    layer
      .queryAttachments({
        objectIds: [feature.attributes[layer.objectIdField]],
      })
      .then((response) => {
        const attachments = Object.values(response).flat(1);

        startTransition(() => {
          setImages(
            attachments?.filter((att) => att.contentType.startsWith("image")) ||
              []
          );
          //   setAttachments(
          //     attachments?.filter(
          //       (att) => !att.contentType.startsWith("image")
          //     ) || []
          //   );
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [feature, layer]);

  return images.length > 0 && <ImageSwiper images={images} />;
};

export default CardImages;
