import { loadModules } from "esri-loader";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  EditorContext,
  UpdateType,
} from "../components/Dashboard/Editor/EditorContextProvider";
import { EditType } from "../components/Dashboard/Editor/EditorSwiper/EditorSwiper";
import {
  deleteLayerUpdate,
  updateLayerUpdate,
} from "../redux/action/CycleManager-action";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  graphicsLayer,
  isdeletedFieldName,
  view,
} from "../utils/API";
import { applyEditsInBatches } from "../utils/helper";
import {
  handleAttachments,
  handleGeometryUpdates,
  processFieldsAndAttributes,
  queryUpdatedFeatures,
  updateFeatureAttributes,
  updateFeaturesList,
} from "./Editor/helpers";

const numericFields = [
  "small-integer", // Short - 16-bit integers (-32,768 to 32,767)
  "integer", // Long - 32-bit integers (-2.147B to 2.147B)
  "big-integer", // 64-bit integers (-9.223Q to 9.223Q)
  "single", // Float - 32-bit floating point
  "double", // Double - 64-bit floating point
  "float",
];

export const trimStrings = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const trimmedObj = {};
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
};

const convertNumber = (value, f) => {
  // if (
  //   numericFields.includes(f.type) &&
  //   atts[f.name] != null &&
  //   atts[f.name] !== ""
  // ) {

  // }

  let newValue = value;
  try {
    if (f.type === "big-integer") {
      newValue = BigInt(value);
    } else {
      newValue = Number(value);
    }
  } catch (err) {
    console.log(err);
  }

  return newValue;
};

const useEditor = ({ editType, config, onSave, goBack, openSnackbar }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const {
    highlightFeature,
    attachments,
    addedPoints,
    batchUpdateFeatures,
    situationalUpdateType,
    editableLayer,
    setEditableFeatureList,
    editableFeatureList,
    setEditorListRefreshCount,
    updateType,
    setAttachments,
    setAddedPoints,
    trimmedSituationalFeatures,
  } = useContext(EditorContext);
  const methods = useForm();
  const dispatch = useDispatch();
  /**
   * Returns the geometry that will be added as a new feature to the map
   * @returns {*}
   */
  const getGeometry = () => {
    if (!addedPoints || addedPoints.length === 0) return;

    if (editableLayer.geometryType === "point") {
      return addedPoints[0];
    } else if (editableLayer.geometryType === "polyline") {
      return addedPoints[0];
    } else if (editableLayer.geometryType === "polygon") {
      return addedPoints[0];
    }
  };

  const confirmStatus = async () => {
    try {
      let features = [];

      if (highlightFeature) {
        features.push(highlightFeature);
      } else {
        batchUpdateFeatures.forEach((item) => {
          features.push(item.feature);
        });
      }

      if (features.length === 0) {
        console.warn("No features to update");
        return;
      }

      const today = new Date();

      features = features.map((feat) => {
        let fieldName = currAsOfDateFieldName;
        if (updateType === UpdateType.baseline) {
          fieldName = asOfDateFieldName;
        }

        feat.attributes[fieldName] = today.getTime();
        return feat.clone();
      });

      setLoading(true);

      await applyEditsInBatches(editableLayer, features);
      // await editableLayer.applyEdits({
      //   updateFeatures: features,
      // });
      editableLayer.refresh();
      // setBatchUpdateFeatures([]);
      // for quick lookup
      const featureMap = new Map(
        features.map((feat) => [feat.getObjectId(), feat])
      );

      dispatch(
        updateLayerUpdate({
          features: features,
          id: editableLayer.layerConfig.id,
        })
      );

      // Replace features in editableFeatureList
      const updatedEditableFeatureList = editableFeatureList.map(
        (editableFeature) =>
          featureMap.has(editableFeature.getObjectId())
            ? featureMap.get(editableFeature.getObjectId())
            : editableFeature
      );

      // Update the state
      setEditableFeatureList(updatedEditableFeatureList);
      // openSnackbar(t("screen.message.batchEditorReady"), 15000);
      onEditComplete({
        actionType: "confirm",
      });
    } catch (err) {
      console.log(err);

      openSnackbar(t("screen.message.error"), 15000);
      setLoading(false);
    }
  };

  const doEdit = async (data, event) => {
    const newBatchUpdateFeatures = batchUpdateFeatures.map(
      (item) => item.feature
    );

    const { atts, fieldTypes } = processFieldsAndAttributes({
      data,
      editableLayer,
      editType,
      config,
    });

    // Update attributes
    const updatedAtts = updateFeatureAttributes(atts, fieldTypes, {
      highlightFeature,
      newBatchUpdateFeatures,
      editableLayer,
      editType,
    });

    // Handle geometry
    const geometry = getGeometry();
    if (editType === EditType.create && !geometry) return;

    if (
      editType === EditType.edit &&
      editableLayer.geometryType !== "point" &&
      highlightFeature
    ) {
      // for roads
      highlightFeature.geometry = geometry;
    }

    setLoading(true);

    let updateFeatures = [];

    if (editType === EditType.edit) {
      updateFeatures = highlightFeature
        ? [highlightFeature]
        : newBatchUpdateFeatures;
    } else if (editableLayer.layerConfig.titleLabel === "roadsSituational") {
      updateFeatures = trimmedSituationalFeatures.updateFeatures;
    }

    const addedFeatures = [];
    if (editType === EditType.create) {
      const iso3 = new Set();
      if (graphicsLayer) {
        graphicsLayer.graphics.forEach((graphic) => {
          if (graphic?.id === "route-combined" && graphic?.attributes?.iso3) {
            iso3.add(graphic.attributes.iso3);
          }
        });
      }

      if (
        editableLayer.layerConfig.titleLabel === "roadsSituational" &&
        iso3.size > 1
      ) {
        const addedGraphics = graphicsLayer.graphics.toArray();
        addedGraphics.forEach((graphic) => {
          graphic.attributes = {
            ...updatedAtts,
            ...graphic.attributes,
          };
          addedFeatures.push({
            geometry: graphic.geometry,
            attributes: graphic.attributes,
          });
        });
      } else {
        addedFeatures.push({
          geometry: geometry,
          attributes: updatedAtts,
        });
      }
    }

    const {
      updateFeatures: processedFeatures,
      addedFeatures: processedAddedFeatures,
    } = await handleGeometryUpdates(updateFeatures, addedFeatures, editType);

    const featureIdsToRemove = new Set();
    if (trimmedSituationalFeatures.deleteFeatures.length > 0) {
      trimmedSituationalFeatures.deleteFeatures.forEach((feat) => {
        const doDelete = feat.attributes[isdeletedFieldName] !== 1;
        feat.attributes[isdeletedFieldName] = doDelete ? 1 : 2;
        featureIdsToRemove.add(feat.getObjectId());
      });
      processedFeatures.push(...trimmedSituationalFeatures.deleteFeatures);
    }

    try {
      const res = await editableLayer.applyEdits({
        addFeatures: editType === EditType.create ? processedAddedFeatures : [],
      });

      if (processedFeatures.length > 0) {
        await applyEditsInBatches(editableLayer, processedFeatures);
      }

      if (EditType.create === editType && res.addFeatureResults.length > 0) {
        res.addFeatureResults.forEach((result, index) => {
          processedAddedFeatures[index].attributes[
            editableLayer.objectIdField
          ] = result.objectId;
        });
      }

      await handleAttachments({
        highlightFeature,
        addedFeatures: processedAddedFeatures,
        setAttachments,
        editType,
        attachments,
        editableLayer,
      });

      const updatedFeatures = await queryUpdatedFeatures({
        editableLayer,
        featuresToUpdate: [...processedAddedFeatures, ...processedFeatures],
      });

      editableLayer.refresh();

      if (updatedFeatures.length > 0) {
        const newFeatures = updateFeaturesList({
          editableFeatureList,
          featureIdsToRemove,
          updatedFeatures,
        });

        dispatch(
          updateLayerUpdate({
            features: newFeatures,
            id: editableLayer.layerConfig.id,
          })
        );

        setEditableFeatureList(newFeatures);
      }

      onEditComplete({
        updateFeatures:
          EditType.edit === editType ? updateFeatures : processedAddedFeatures,
        actionType: "edit",
      });
    } catch (err) {
      console.log(err);
      openSnackbar(t("screen.message.error"), 15000);
      setLoading(false);
    }
  };

  const onEditComplete = ({ updateFeatures, actionType }) => {
    view
      .whenLayerView(editableLayer)
      .then((layerView) => {
        loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
          reactiveUtils
            .whenOnce(() => !layerView.updating)
            .then(() => {
              setLoading(false);
              view.graphics.removeAll();

              // openSnackbar(t("screen.message.batchEditorReady"), 15000);
              methods.reset();
              // setEditorListRefreshCount((count) => count + 1);
              setAddedPoints([]);
              if (onSave) {
                onSave({ updateFeatures, actionType });
              }
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getIsDeletedField = () => {
    if (!editableLayer) return;

    return editableLayer.fields.filter((f) => f.name === isdeletedFieldName)[0];
  };

  const doDelete = async () => {
    try {
      // editableLayer.applyEdits({
      //   deleteFeatures: [highlightFeature],
      // });

      if (!getIsDeletedField()) {
        if (goBack) {
          goBack();
        }
        return;
      }

      let features =
        batchUpdateFeatures.length > 0
          ? [...batchUpdateFeatures.map((item) => item.feature)]
          : [highlightFeature];

      features.forEach((feat) => {
        const doDelete = feat.attributes[isdeletedFieldName] !== 1;
        feat.attributes[isdeletedFieldName] = doDelete ? 1 : 2;
      });

      // const doDelete = highlightFeature.attributes[isdeletedFieldName] !== 1;
      // highlightFeature.attributes[isdeletedFieldName] = doDelete ? 1 : 2;
      setLoading(true);
      await applyEditsInBatches(editableLayer, features);
      editableLayer.refresh();
      view.graphics.removeAll();
      graphicsLayer.removeAll();

      dispatch(
        deleteLayerUpdate({
          id: editableLayer.layerConfig.id,
          features: features,
        })
      );

      // openSnackbar(t("screen.message.batchEditorReady"), 15000);
      onEditComplete({
        actionType: "delete",
      });

      // setBatchUpdateFeatures([]);
      // setEditorListRefreshCount((count) => count + 1);
      // Remove all elements that are inside features from editableFeatureList
      const featureIdsToRemove = new Set(
        features.map((feature) => feature.getObjectId())
      );

      const updatedEditableFeatureList = editableFeatureList.filter(
        (editableFeature) =>
          !featureIdsToRemove.has(editableFeature.getObjectId())
      );
      setEditableFeatureList(updatedEditableFeatureList);
    } catch (err) {
      console.log(err);

      openSnackbar(t("screen.message.error"), 15000);
      setLoading(false);
    }
  };

  return {
    doEdit,
    loading,
    doDelete,
    setLoading,
    methods,
    confirmStatus,
    getIsDeletedField,
  };
};

export default useEditor;
