import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  ProgressLoader,
  StyledProgressBar,
} from "../../../Report/new/ProgressBar/ProgressBar-styled";

const LayerLoaderWrapper = ({ opsColor, children }) => {
  const [layerCount, setLayerCount] = useState(0);
  const {
    layersLoading,
    mapViewReducer: { view, operationLayers = [] },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!view?.map) return;
    // Initial count
    setLayerCount(view.map.layers.length);

    // Watch for changes in layers collection
    const layerWatcher = view.map.layers.watch("length", () => {
      setLayerCount(view.map.layers.length);
    });

    return () => {
      if (layerWatcher) {
        layerWatcher.remove();
      }
    };
  }, [view]);

  const progress = useMemo(() => {
    return (
      (100 / operationLayers.length) *
      (layersLoading ? layerCount : operationLayers.length)
    );
  }, [layerCount, layersLoading, operationLayers]);

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        borderRadius: 100,
        height: "100%",
      }}
    >
      <ProgressLoader
        style={{
          backgroundSize: "20px 35px",
          backgroundColor: opsColor + "B3",
          border: "none",
        }}
        color="#ffffff"
      />
      <StyledProgressBar
        progress={progress}
        color={opsColor}
        style={{
          position: "absolute",
        }}
      />
      {children}
    </div>
  );
};

export default LayerLoaderWrapper;
