import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { UpdateType } from "../EditorContextProvider";
import {
  PlatformBody,
  PlatformSection,
  PlatformSectionItem,
  PlatformSectionItemBody,
} from "./Platform-styled";

import { useDispatch, useSelector } from "react-redux";
import { setEditorUpdateType } from "../../../../redux/action/Dashboard-action";
import { InformationIcon } from "../../../Icons";
import LayerIcon from "../../../Icons/LayerIcon";
import { Tooltip } from "../../../UI";

const baseLineColor = "#34495e";

export const EntryPoints = ["aerodromes", "eep", "ports"];

const positions = {
  0: { top: -1, left: 0, zIndex: 1 },
  1: { top: -1, right: -2 },
  2: { top: 10, left: 5 },
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Platform = ({
  entryPointsLayers,
  layers,
  onLayerSelect,
  updateType,
  children,
}) => {
  const { layersUpdates } = useSelector((state) => state.cycleManagerReducer);
  const activeModule = useSelector((state) => state.activeModule);

  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const handleSelection = useCallback(
    (layer) => {
      dispatch(setEditorUpdateType(updateType));
      // if (!layer.visible) {
      //   layer.visible = true;
      // }

      if (onLayerSelect) {
        onLayerSelect(layer);
      }
    },
    [onLayerSelect, updateType]
  );

  const color = useMemo(() => {
    return UpdateType.baseline === updateType ? baseLineColor : config.opsColor;
  }, [config, updateType]);

  const filteredLayers = useMemo(() => {
    const entryPointsSet = new Set(entryPointsLayers);
    const newLayers = [...layers];
    const configLayers = config.layers || [];

    const { layers: moduleLayers = [], optionalLayers = [] } =
      config.modules[activeModule] || {};

    const layersToShow = newLayers.filter(
      (l) =>
        moduleLayers.includes(l.layerConfig.alias) ||
        optionalLayers.includes(l.layerConfig.alias) ||
        configLayers.includes(l.layerConfig.alias)
    );

    if (updateType === UpdateType.baseline) {
      return [...layersToShow].reverse();
    } else {
      return layersToShow;
    }
  }, [layers, updateType, activeModule, config]);

  const title = useMemo(() => {
    return updateType;
  }, []);

  const entryPointLayersTitle = useMemo(() => {
    if (updateType === UpdateType.baseline) {
      return "Edit Entry points";
    }

    const entryPointsTotal = entryPointsLayers.reduce((acc, layer) => {
      const { percent = 0 } = layersUpdates[layer.layerConfig.id] || {};
      return acc + percent;
    }, 0);

    return `${entryPointsTotal}% ${t("cycleManager.status.upToDate.title")}`;
  }, [entryPointsLayers, layersUpdates, updateType]);

  return (
    <>
      <PlatformBody>
        {(entryPointsLayers.length > 0 || layers.length > 0) && (
          <PlatformSection>
            {children}
            {filteredLayers.map((layer) => {
              const { percent = 0 } = layersUpdates[layer.layerConfig.id] || {};
              return (
                <PlatformSectionItem
                  key={layer.id}
                  id={layer.id}
                  onClick={() => handleSelection(layer)}
                >
                  <LayerIcon
                    config={config}
                    layer={layer}
                    t={t}
                    width={34}
                    height={34}
                    textColor="#fff"
                    opsColor={color}
                    selected
                    wrapperStyles={{
                      background: color,
                      borderRadius: 100,
                    }}
                    textStyles={{
                      background: color,
                      borderRadius: 100,
                      height: 34,
                      width: 34,
                    }}
                  />
                  <PlatformSectionItemBody>
                    <span className="title text-elipsis">
                      {UpdateType.baseline === updateType
                        ? t("screen.widget.common.edit")
                        : t("screen.widget.common.update")}{" "}
                      {layer.getLayerTitle(t)}
                    </span>
                    <span className="subtitle text-elipsis">
                      {updateType === UpdateType.situational
                        ? `${percent}% ${t(
                            "cycleManager.status.upToDate.title"
                          )}`
                        : "Edit " + layer.getLayerTitle(t)}
                    </span>
                  </PlatformSectionItemBody>
                  <Tooltip showOnHover content={layer.getLayerInformation(t)}>
                    <InformationIcon />
                  </Tooltip>
                </PlatformSectionItem>
              );
            })}
          </PlatformSection>
        )}
      </PlatformBody>
    </>
  );
};

export default Platform;
