import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const animationRule = css(["", " 2s ease-in-out"], fadeIn);

export const StyledEditorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  user-select: none;
  transition: all 0.5s;
  box-sizing: border-box;
  animation: ${({ animate }) => (animate ? animationRule : "")};
`;

export const StyledEditorBody = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  /* gap: 4px; */
  flex-direction: column;
  //padding: 0px 16px 12px 8px;
  text-align: start;
  .wfp--btn {
    :focus {
      background: transparent;
    }
  }

  swiper-container {
    height: 100%;
  }

  swiper-slide {
    //overflow: auto;
    //padding: 0 14px 12px 14px;
    box-sizing: border-box;
    outline: none;
  }
`;

export const StyledTitle = styled.p`
  line-height: normal;
  width: 100%;
  padding-bottom: 8px;
  margin: 0px;
  box-sizing: border-box;
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  font-size: ${({ fontSize }) => fontSize ?? "14px"};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  background: ${({ bg }) => bg ?? "#ffffff"};
  padding: ${({ padding }) => padding ?? "0px"};
`;

export const StyledDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 10px;
  color: #8a8a8a;
  margin: 0px;
  background: #ffffff;
`;

export const StyledButton = styled.button`
  display: flex;
  gap: 8px;
  font-size: ${({ small }) => (small ? "10px" : "12px")};
  font-style: normal;
  color: ${({ color }) => color ?? "#525252"};
  letter-spacing: 0;
  height: 1.8rem;
  padding: 0 0.35rem;
  background-color: transparent;
  /* border-width: 2px;
  border-style: solid;
  border-color: transparent; */
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  transition-duration: 50ms;
  transition-timing-function: ease-in;
  white-space: nowrap;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  /* cursor: ${({ hover = true }) => (hover ? "pointer" : "")}; */

  /* :hover {
    background: ${({ hover = true }) => (hover ? "#8080801a" : "")};
  } */

  .arrow {
    transition: all 0.5s;
    transform: rotate(${({ expand }) => (expand ? "180deg" : "360deg")});
  }
`;

export const StyledList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const StyledListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 8px;
  cursor: pointer;
  transition: all 0.5s;
  color: #525252;
  text-align: left;
  background: rgba(238, 238, 238, 0.47);

  border-radius: 14px;
  min-height: 50px;

  svg {
    width: 30px;
    height: 30px;
  }

  .image_holder {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  &:hover {
    background: #8080801a;
  }

  .tag {
    margin: 0.125rem;
    border-radius: 0.9375rem;
    white-space: nowrap;
    font-size: 10px;
    padding: 0 6px;
    height: 20px;
    width: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    background-color: #d9ecf5;
    color: #005e8d;
  }
`;

export const StyledEditorBreadcrumbs = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
  //margin-bottom: 10px;
  color: white;
  height: 54px;
  border-top-left-radius: 8px;
  overflow: hidden;
  padding: 0 10px;

  svg {
    ${({ hasIcon = true }) =>
      hasIcon
        ? `width: 14px;
    height: 14px;`
        : ""}
  }

  p {
    text-align: center;
    width: 100%;
    font-size: 22px;
    z-index: 2;
    color: ${({ hasProgress }) => (hasProgress ? "#fff" : "black")};
    font-weight: 500;
    margin: 0px;

    span {
      color: ${({ hasProgress }) => (hasProgress ? "#fff" : "#808080")};
    }
  }

  button {
    display: flex;
    height: 14px;
    z-index: 1;
    margin-left: auto;
    letter-spacing: 0;
    font-size: 0.875rem;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    transition-duration: 50ms;
    transition-timing-function: ease-in;
    white-space: nowrap;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    :hover,
    :focus,
    :active {
      background: transparent;
    }
  }
`;
// flex:1, display:'flex', flexDirection:"column", border:'1px solid #8080801a', borderRadius: 8, padding:4
export const CoordinateNotationWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #80808033;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  gap: 14px;

  p {
    font-size: 10px;
    margin: 0;
  }

  .information {
    cursor: pointer;
  }

  .wfp--content-switcher {
    //justify-content: flex-end;
    height: auto;

    button {
      padding: 0px 8px;
    }
  }
`;

export const StyledSwitch = styled.div`
  display: flex;
  flex-direction: ${({ dir }) => dir ?? "column"};
  border: 2px solid ${({ color }) => color ?? "#525252"};
  border-radius: 6px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : undefined)};

  div,
  label {
    padding: 2px 6px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    span {
      color: ${({ color }) => color ?? "#525252"};
    }
  }

  input:checked + label {
    background: ${({ color }) => color ?? "#525252"};
    span {
      color: #ffffff;
    }
  }

  input {
    display: none;
  }

  div.selected {
    background: ${({ color }) => color ?? "#525252"};
    span {
      color: #ffffff;
    }
  }

  div:not(:first-of-type) {
    ${({ dir }) =>
      dir === "column"
        ? `border-top: 2px solid ${({ color }) => color ?? "#525252"}`
        : `border-left: 2px solid ${({ color }) => color ?? "#525252"}`};
  }
`;

export const CustomSelectMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  padding: 4px 8px;
  transition: all 0.3s;

  span {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    padding: 0;
  }

  p {
    background: ${({ selected, color }) =>
      color ? (selected ? color ?? "#808080" : `#8080801a`) : "#8080801a"};
    color: ${({ selected }) => (selected ? "#ffffff" : "#4c4c4c")};
    margin: 0;
    font-size: 12px;
    transition: all 0.1s;
    border-radius: 100px;
    font-weight: 500;
    padding: 6px 12px;
  }

  :hover {
    p {
      color: #ffffff;
      background: ${({ color = "#8080801a" }) => color};
    }
  }
`;

export const FieldLabel = styled.p`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: rgb(76, 76, 76);
  margin: 0;
  margin-bottom: ${({ noMargin }) => (!noMargin ? "4px" : "")};
`;

export const StyledSwitchWrapper = styled.div``;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`;

export const StyledSearchInputContainer = styled.div`
  flex: 1;

  .search-input::placeholder {
    font-size: 13px;
    color: #4b4b4b;
  }
`;

export const StyledHelperButton = styled.button`
  background: #fff;
  color: ${({ opsColor, color }) => opsColor ?? color};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s;

  :hover {
    background: ${({ hover = true, opsColor, bgColor }) =>
      hover ? (opsColor ? `${opsColor}1d` : bgColor) : undefined};
  }
`;

export const StyledSearchBox = styled.div`
  position: relative;
  input {
    font-size: ${({ fontSize }) =>
      fontSize ? `${fontSize}px !important` : ""};
  }

  button {
    font-size: ${({ fontSize }) =>
      fontSize ? `${fontSize}px !important` : ""};
  }
`;

export const StyledSuggestions = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  transition: all 0.3s;
  margin-top: 10px;
  border-radius: 8px;
`;

export const StyledSuggestItem = styled.div`
  cursor: pointer;
  user-select: none;
  padding: 4px 8px;
  transition: all 0.3s;
  margin: 4px 0px;
  background: ${({ selected, color }) =>
    color ? (selected ? color : ``) : ""};
  color: ${({ selected }) => (selected ? "#ffffff" : "#4c4c4c")};

  span {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    padding: 0;
  }
  p {
    margin: 0;
    font-size: 12px;
    transition: all 0.1s;
    border-radius: 100px;
    font-weight: 500;
    padding: 6px 12px;
  }

  :hover {
    color: #000000;
    background: #9393931a;
  }
`;

export const StyledSearchInputWrapper = styled.div`
  position: absolute;
  width: ${({ show }) => (show ? "100%" : "1%")};
  /* width: 100%; */
  transition: all 0.3s;
  right: 0;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  padding: ${({ show }) => (show ? "0px 12px" : undefined)};
  box-sizing: border-box;
  overflow: hidden;

  input {
    border-radius: 100px;
    height: 100%;
    padding: 6px 12px;

    ::placeholder {
      font-size: 10px;
    }
  }

  label {
    font-size: 10px;
  }
`;

export const FloatingAddButton = styled.div`
  position: sticky;
  bottom: -1px;
  left: 0;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;

  button {
    min-width: 80px;
    height: 100%;
    background: ${({ color }) => `${color ?? "#b21b0c"}14`};
    border: ${({ color }) => `1px solid ${color ?? "#b21b0c"}`};
    transition: all 0.5s;
    //padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 20px;
    width: auto;
    margin-bottom: 8px;

    span {
      font-size: 14px;
      color: ${({ color }) => color ?? "#b21b0c"};
    }

    svg {
      path {
        //fill: ${({ color }) => color ?? "#b21b0c"};
      }
    }

    &:hover {
      background: ${({ color }) => `${color ?? "#b21b0c"}14`};
    }
  }
`;

export const StyledEditorManagerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
  box-sizing: border-box;
`;

export const StyledListContainer = styled.div`
  overflow: hidden;
  /* flex:1;
  overflow: auto; */
`;

export const StyledSituationalTitle = styled.p`
  line-height: normal;
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span:first-child {
    font-size: 14px;
    font-weight: 500;
  }

  span:last-child {
    font-size: 11px;
    font-weight: 400;
  }
`;

export const StyledCalculateRouteContainer = styled.div`
  background: rgba(238, 238, 238, 0.47);
  padding: 10px 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 6;
`;

export const StyledManualContainer = styled.div`
  background: rgba(238, 238, 238, 0.47);
  padding: 10px;
`;
