import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  StyledFiltersBackButton,
  StyledFiltersBody,
  StyledFiltersHeader,
} from "../MobileFilter-styled";
import { ArrowLeftIcon, FilterIcon } from "../../../Icons";
import { getLayerSymbology } from "../../../../utils/symbologies";
import FilterSection from "../FilterSection";
import FilterFieldCombo from "../../../Filter/FilterFieldCombo";
import { StyledSubmitButton } from "../../../Report/new/Footer/Footer-styled";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useLayersChange from "../../../../hooks/useLayersChange";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { getOpsColor, getVisibleFilters } from "../../../../utils/helper";
import WidgetButton from "../../../Header/components/WidgetButton";

export const areObjectsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (val1.length !== val2.length) return false;
      for (let i = 0; i < val1.length; i++) {
        if (val1[i] !== val2[i]) return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

const Filters = ({
  setShowFilters,
  showFilters,
  initialFilters,
  menuPosition,
  onFilterApply,
  filters,
  setAndApplyFilters,
  setShowMessage,
}) => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  let activeModule = useSelector((store) => store.activeModule);
  const [localFilters, setLocalFilters] = useState({});

  // const {filters, setAndApplyFilters} = useFilters({isActive:true, config},false);
  const { layerCount } = useLayersChange(config);

  const activeFilters = useMemo(() => {
    return Object.values(getVisibleFilters(config, activeModule, filters));
  }, [config, layerCount, filters, activeModule]);

  useEffect(() => {
    const moduleFilters = filters[activeModule] || {};
    const newLocalFilters = { ...localFilters };

    Object.keys(moduleFilters).forEach((k) => {
      if (
        localFilters[k] === undefined ||
        localFilters[k] !== moduleFilters[k]
      ) {
        newLocalFilters[k] = moduleFilters[k];
      }
    });

    setLocalFilters(newLocalFilters);
  }, [filters]);

  const onFilterChange = (fieldName, value) => {
    let newFilters = JSON.parse(JSON.stringify(localFilters));
    newFilters[fieldName] = value;
    setLocalFilters(newFilters);
  };

  const handleReset = () => {
    let _filters = JSON.parse(JSON.stringify(filters));

    const moduleFilters = initialFilters[activeModule];
    _filters[activeModule] = moduleFilters;
    setLocalFilters(moduleFilters);
    setAndApplyFilters(_filters);
    if (onFilterApply) {
      onFilterApply();
    }
  };

  const handleApplyFilters = useCallback(() => {
    let _filters = JSON.parse(JSON.stringify(filters));
    _filters[activeModule] = localFilters;
    setAndApplyFilters(_filters);
    if (onFilterApply) {
      onFilterApply();
    }
    setShowFilters(false);
  }, [filters, localFilters, setAndApplyFilters]);

  const handleBack = useCallback(() => {
    const areFiltersEqual = areObjectsEqual(
      localFilters,
      filters[activeModule]
    );
    if (areFiltersEqual) {
      setShowFilters(false);
    } else {
      setShowMessage(true);
    }
  }, [localFilters, filters, activeModule]);

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [activeModule, config]
  );

  return (
    <>
      <StyledFiltersHeader>
        <StyledFiltersBackButton
          className="filter__arrow-left"
          onClick={handleBack}
        >
          <ArrowLeftIcon />
        </StyledFiltersBackButton>
        <span>{t("screen.widget.Filter.title")}</span>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <StyledFiltersBackButton
            style={{ padding: "0px 18px" }}
            onClick={handleReset}
          >
            <span style={{ color: "#000" }}>
              {t("screen.widget.Filter.reset")}
            </span>
          </StyledFiltersBackButton>
          <WidgetButton>
            <FilterIcon />
          </WidgetButton>
        </div>
      </StyledFiltersHeader>
      <StyledFiltersBody>
        {showFilters &&
          activeFilters.map((item) => {
            const { field, layer } = item;
            const symbology = getLayerSymbology(layer, config);

            const { colorMap = {} } = symbology || {};
            let colors = {};

            if (
              Array.isArray(colorMap.fields) &&
              colorMap.fields.includes(field.name)
            ) {
              colors = colorMap.default;
            } else if (colorMap.field === field.name) {
              colors = colorMap.default;
            }

            const defaultValue = localFilters[field.name] ?? [];

            return (
              <FilterSection
                key={field.name}
                title={t(
                  "layer.fieldAlias." + field.name + ".title",
                  field.alias
                )}
                // layerTitle={getLayerTitle(layer, t)}
                appliedFilters={defaultValue.length}
              >
                <FilterFieldCombo
                  key={`ff_${field.name}`}
                  field={field}
                  t={t}
                  referenceKey={field.name}
                  onValuesChange={onFilterChange.bind(this)}
                  defaultValue={defaultValue}
                  multi={true}
                  isMobile={true}
                  colors={colors}
                  menuPosition={menuPosition}
                />
              </FilterSection>
            );
          })}
        <StyledSubmitButton
          onClick={handleApplyFilters}
          bg={opsColor}
          style={{
            position: "absolute",
            bottom: "20px",
            margin: "auto",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "0px 24px",
            fontSize: "16px",
          }}
        >
          {t("screen.widget.Filter.applyFilters")}
        </StyledSubmitButton>
      </StyledFiltersBody>
    </>
  );
};

export default Filters;
