import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 6px 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  > span {
    font-size: 12px;
    color: #6c6b6b;
  }
`;

export const StyledItem = styled.a`
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  text-decoration: none;
  color: #000 !important;

  svg {
    width: 16px;
    min-width: 16px;
    min-height: 16px;
  }

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
