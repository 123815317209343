import styled from "styled-components";
import { StyledFilterButton } from "../Dashboard/Insights/Insights-styled";

export const SearchWidgetWrapper = styled.div`
  width: 100%;
  position: relative;

  .search__box {
    overflow: hidden;

    input {
      padding-left: 10px;
      padding-right: 50px;
      border: none;
    }
  }

  ${({ isMobileApp }) =>
    !isMobileApp
      ? `
    @media screen and (min-width: 769px){
      width: 240px;

      .search__box {
        box-shadow: 0 1px 2px #0000004d;
        border-radius: 10px;
      }
    }
    
    @media screen and (max-width: 769px){
      .search__box{
        input {
          padding-left: 42px;
        }
      }
    
      input {
        font-size: 16px;
      }
    }
  
    @media screen and (min-width: 769px) and (max-width: 1210px) {
      .search__box {
        input {
          font-size: 12px;
        }
      }
    }
  `
      : `
    .search__box{
      input {
        padding-left: 42px;
      }
    }
    
    input {
      font-size: 16px;
    }
  `};
`;

export const StyledSuggestions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* max-height: ${({ isMobileApp }) => (!isMobileApp ? "300px" : "510px")}; */
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s;
  border-radius: 8px;
  margin-top: 4px;
  background: #ffffff;
  box-shadow: 0 1px 2px #0000004d;
  position: absolute;
  top: 0px;
  z-index: 1;
`;

export const StyledSuggestGroup = styled.div`
  :not(:first-child) {
    padding-top: 5px;
    margin-top: 5px;
    border-top: 1px solid #e0e0e0;
  }
`;

export const StyledSuggestItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  padding: 6px 8px;
  transition: all 0.3s;
  background: ${({ selected }) => (selected ? "#9b9b9b" : "#ffffff")};
  color: ${({ selected }) => (selected ? "#ffffff" : "#4c4c4c")};

  span {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    padding: 0;
  }
  p {
    margin: 0;
    font-size: 12px;
    transition: all 0.1s;
    border-radius: 100px;
    font-weight: 500;
  }

  ${({ selected }) =>
    !selected
      ? `
    :hover {
        color: #000000;
        background: #efefef
    }

    :hover .st0 {
          fill: #efefef !important;
        }
    `
      : ""}
`;

export const StyledIconBox = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 2px;
  pointer-events: none;
`;

export const StyledLocateButton = styled(StyledFilterButton)`
  /* background: var(--opsColor); */
  color: #ffffff !important;
  font-weight: 500;
  padding: 6px 14px;
  transition: all 0.3s;
  gap: 0px;
  background: #525252;
  border-radius: 100px;
  border-color: #525252;

  span {
    color: #ffffff;
  }

  ${({ disabled }) =>
    disabled
      ? `
    height: 0;
    overflow: hidden;
    padding: 0;
    border: 0;
  `
      : ""}

  svg {
    width: auto;
    height: auto;
  }
`;

export const SuggestionContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

export const NoResultsContainer = styled.div``;

export const CoordinatesContainer = styled.div`
  padding: 0px 4px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SearchLocationIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CoordinatesHeader = styled.div`
  display: flex;
  font-size: 12px;
  padding: 6px 6px 0px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  color: #4b4b4b;
`;

export const CoordinatesTitle = styled.span``;

export const ArrowIconWrapper = styled.span`
  transform: ${(props) =>
    props.isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s ease;
`;

export const CoordinatesContent = styled.div`
  max-height: ${(props) => (props.isExpanded ? "510px" : "0px")};
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

export const StyledLoader = styled.div`
  height: 3px;
  width: 100%;
  --c: no-repeat
    linear-gradient(${(props) => props.color || "var(--opsColor)"} 0 0);
  background: var(--c), var(--c),
    ${(props) => (props.color ? `${props.color}4D` : "#fff")};
  background-size: 60% 100%;
  animation: l16 3s infinite;

  @keyframes l16 {
    0% {
      background-position: -150% 0, -150% 0;
    }
    66% {
      background-position: 250% 0, -150% 0;
    }
    100% {
      background-position: 250% 0, 250% 0;
    }
  }
`;
