import { view } from "../../utils/API";
import { getLineSymbol } from "./Symbols";

export const getGraphic = (esriModules, geometry, symbol, attr) => {
  return new esriModules.Graphic({
    geometry: geometry,
    symbol: symbol,
    attributes: attr,
  });
};

const graphicMatchesFilters = (filters, attributes) => {
  return !Object.values(filters)
    .filter((filter) => filter.value?.length > 0)
    .some((filter) => filter.value.indexOf(attributes[filter.field.name]) < 0);
};

/**
 * Selected polyline feature: road inside the selection
const roadWithinSelectionSymbol = getLineSymbol("#26A59A", 1)
 */

/**
 * Selected polyline feature: road fragment inside the selection
 */
const roadFragmentInSelectionSymbol = getLineSymbol("#00FF00", 1);

export const cutFeatures = async (features, selectionPolygon, esriModules) => {
  const within = [];
  const geometryEngineAsync = esriModules.geometryEngineAsync;
  let countAsync = 0;
  let count = 0;
  for (const ft of features) {
    const contains = await geometryEngineAsync.contains(
      selectionPolygon,
      ft.geometry
    );

    if (contains) {
      within.push(
        getGraphic(
          esriModules,
          ft.geometry,
          roadFragmentInSelectionSymbol,
          ft.attributes
        )
      );
    } else {
      let fragments = [ft.geometry];
      for (const ring of selectionPolygon.rings) {
        for (const [pathIndex, path] of ring.entries()) {
          const endPoint = pathIndex === ring.length - 1 ? 0 : pathIndex + 1;
          let newFragments = [];
          for (const fragment of fragments) {
            countAsync++;
            const cut = await geometryEngineAsync.cut(
              fragment,
              new esriModules.Polyline({ paths: [path, ring[endPoint]] })
            );
            if (cut.length > 0) {
              cut.forEach((c) => newFragments.push(c));
            } else {
              newFragments.push(fragment);
            }
          }

          fragments = newFragments;
        }
      }
      for (const fragment of fragments) {
        const contains = await geometryEngineAsync.contains(
          selectionPolygon,
          fragment
        );

        if (contains) {
          within.push(
            getGraphic(
              esriModules,
              fragment,
              roadFragmentInSelectionSymbol,
              ft.attributes
            )
          );
        }
      }
    }
    count++;
    if (esriModules.setCount) {
      esriModules.setCount(`${count}/${features.length}`);
    }
  }

  //old part without async await, it blocked ui when running
  // features.forEach((ft) => {
  //     if (geometryEngine.contains(selectionPolygon, ft.geometry)) {
  //         within.push(getGraphic(esriModules, ft.geometry, roadFragmentInSelectionSymbol, ft.attributes))
  //     } else {
  //         let fragments = [ft.geometry]
  //         selectionPolygon.rings.forEach((ring) => {
  //             ring.forEach((path, pathIndex) => {
  //                 const endPoint = (pathIndex === ring.length - 1) ? 0 : pathIndex + 1
  //                 const newFragments = []
  //                 fragments.forEach((fragment) => {
  //                     count++;
  //                     const cut = geometryEngine.cut(fragment, new esriModules.Polyline({paths: [path, ring[endPoint]]}))
  //                     if (cut.length > 0)
  //                         cut.forEach((c) => newFragments.push(c))
  //                     else
  //                         newFragments.push(fragment)
  //                 })
  //                 fragments = newFragments
  //             })
  //         })
  //         fragments.filter(fragment => geometryEngine.contains(selectionPolygon, fragment)).forEach((fragment) => {
  //             within.push(getGraphic(esriModules, fragment, roadFragmentInSelectionSymbol, ft.attributes))
  //         })
  //     }
  // })

  return within;
};

export const selectFeatures = (
  editableLayer,
  selectionPolygon,
  appliedFilters,
  esriModules
) => {
  // The layers that will be queried to copy and merge the features into the editableLayer:
  // The ones that has the editableLayer property of the batchEditor is pointing to the layer we are editing
  const queryLayers = editableLayer.groupedIds;
  return view.whenLayerView(editableLayer).then(() => {
    const layerViewPromises = view.map.layers
      .filter((l) => queryLayers.includes(l.id))
      .map((l) => view.whenLayerView(l));

    return Promise.all(layerViewPromises).then((lvResults) => {
      const queryPromises = lvResults.map((lv) => {
        //Effects are present from the filter widget?
        const fe = lv.featureEffect;
        const where = fe?.filter?.where
          ? " NOT (" + fe.filter.where + ")"
          : "" + lv.layer.definitionExpression
          ? " " + lv.layer.definitionExpression
          : "" + lv.filter?.where
          ? " " + lv.filter.where
          : "";

        const query = {
          geometry: selectionPolygon,
          spatialRelationship: "intersects",
          outFields: "*",
          returnGeometry: true,
          where: where,
        };
        return lv.layer.queryFeatures(query);
      });

      return Promise.all(queryPromises)
        .then((promiseResults) => {
          const allFeatures = [];
          promiseResults.forEach((result) =>
            allFeatures.push(...result.features)
          );

          const filteredFeatures = allFeatures.filter((f) =>
            graphicMatchesFilters(appliedFilters, f.attributes)
          );
          return cutFeatures(filteredFeatures, selectionPolygon, esriModules);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  });
};

export const applyFeatureUpdates = (updateFields, features) => {
  //Update attributes
  Object.values(updateFields)
    .filter((update) => update.field)
    .forEach((update) => {
      let value =
        typeof update.value === "object" && update.field.nullable
          ? null
          : update.value;
      features.forEach((feature) => {
        //Check if value is too long
        if (
          update.field.type === "string" &&
          value &&
          update.field.length < value.length
        )
          value = value.slice(0, update.field.length);

        feature.setAttribute(update.field.name, value);
      });
    });
};

/**
 * Merge selected features. The following rules are applied when merging 2 features:
 *  - 2 features are merged when all their batchEditor.groupFields are equal
 *  - when merging 2 features, we keep the isoField values and the batchFields values,
 *      the rest of the fields will become null, apart from the batchEditor.concatFields
 *      where the following rule is applied
 *  - the batchEditor.concatFields are concatenated when merging 2 features
 */
export const mergeFeatures = (selectedFeatures, editableLayer, esriModules) => {
  const beConfig = editableLayer.layerConfig?.batchEditor;

  //When the layer to be edited is different, the following steps are applied:
  // - Merge features that needs to be added
  // - add the merged features into the editing layer
  const mergedFeatures = [];

  const batchFields = beConfig?.batchFields ? beConfig.batchFields : [];
  const groupFields = beConfig?.groupFields ? beConfig.groupFields : [];
  const isoFields = ["iso3", "iso3a", "iso3b", "iso3c"];

  //Fields that will contain concatenated values of the merged filters
  const concatFields = beConfig?.concatFields ? beConfig.concatFields : [];
  const concatLayerFields = editableLayer.fields.filter(
    (f) => f.type === "string" && concatFields.includes(f.name)
  );

  selectedFeatures.forEach((feature) => {
    const foundEqual = mergedFeatures.find(
      (mergedFeature) =>
        !groupFields.some(
          (att) => feature.attributes[att] !== mergedFeature.attributes[att]
        )
    );

    if (foundEqual) {
      foundEqual.geometry = esriModules.geometryEngine.union([
        foundEqual.geometry,
        feature.geometry,
      ]);
      //For merged features, we concate the "concatFields" param attributes
      Object.keys(feature.attributes)
        .filter(
          (att) =>
            !groupFields.includes(att) &&
            concatFields.includes(att) &&
            feature.attributes[att] != null
        )
        .forEach((att) => {
          const concatField = concatLayerFields.find((f) => f.name === att);
          if (!concatField) return;

          const val = feature.attributes[att];
          if (foundEqual.attributes[att] == null) {
            foundEqual.attributes[att] = val;
            return;
          }

          //concatenate field value
          const split = foundEqual.attributes[att].split(", ");
          if (!split || split.includes(val)) return;

          split.push(val);
          foundEqual.attributes[att] = split
            .join(", ")
            .slice(0, concatField.length);
        });
    } else {
      //For new features, we remove all attributes but the grouping and concat fields
      Object.keys(feature.attributes)
        .filter(
          (att) =>
            !groupFields.includes(att) &&
            !concatFields.includes(att) &&
            !batchFields.includes(att) &&
            !isoFields.includes(att)
        )
        .forEach((att) => {
          delete feature.attributes[att];
        });
      mergedFeatures.push(feature);
    }
  });

  const convertedFeatures = [];
  mergedFeatures.forEach((feature) => {
    try {
      if (!feature.geometry.isGeographic) {
        feature.geometry = esriModules.webMercatorUtils.webMercatorToGeographic(
          feature.geometry
        );
      }
    } catch (error) {
      console.log(error);
    }
    convertedFeatures.push(feature);
  });

  return convertedFeatures;
};
