import { geojsonToArcGIS } from "@terraformer/arcgis";
import { loadModules } from "esri-loader";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  OPEN_ROUTE_SERVICE_API,
  OPEN_ROUTE_SERVICE_API_KEY,
} from "../../../../../../data/constants";
import useCustomSnackbar from "../../../../../../hooks/useCustomSnackbar";
import useRouteCalculation from "../../../../../../hooks/useRouteCalculation";
import { graphicsLayer, view } from "../../../../../../utils/API";
import { ConfigContext } from "../../../../../../utils/ConfigContext";
import {
  StyledCalculateRouteContainer,
  StyledSituationalTitle,
} from "../../../Editor-styled";
import { EditorContext } from "../../../EditorContextProvider";
import { pointGraphicSymbol } from "../../../EditorSwiper/EditorSwiper";
import { addLayerEffect, processFeaturesByGeometry } from "../../../helpers";

import { useTranslation } from "react-i18next";
import SearchCoordinates from "./SearchCoodinates";

export const getPointGraphics = ({ graphicsLayer, point }) => {
  const coordinateId = point.coordinate.join("|");
  const graphicsArray = graphicsLayer.graphics.toArray().filter((g) => {
    if (g.id) {
      if (g.id.includes(coordinateId)) {
        return true;
      }

      if (
        Array.isArray(g.intersectedPoints) &&
        g.intersectedPoints.includes(coordinateId)
      ) {
        return true;
      }

      return false;
    }
    return false;
  });
  return graphicsArray;
};

const EnterCoordinates = ({
  editableLayer,
  setLoading,
  addingPoint,
  setAddingPoint,
  enabled,
  setCount,
}) => {
  const {
    clickedPoints,
    setClickedPoints,
    resetClickedPoints,
    setAddedPoints,
    setShowRoadsEditor,
    activeColor,
    setTrimmedSituationalFeatures,
    trimmedSituationalFeatures,
  } = useContext(EditorContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showMessage, setShowMessage] = useState({
    show: false,
    pointIndex: -1,
    loading: false,
  });
  const [matchBaselineRoads, setMatchBaselineRoads] = useState(false);

  const [manualDrawPoint, setManualDrawPoint] = useState(null);
  const [calculatedPoints, setCalculatedPoints] = useState([]);
  const [bufferNumber, setBufferNumber] = useState(0);

  const [openSnackbar] = useCustomSnackbar();
  const { t } = useTranslation("common");

  const { handleCreateRoute, startLookingPossibleRoutes, errorPoints, count } =
    useRouteCalculation(
      clickedPoints,
      setAddedPoints,
      setCount,
      matchBaselineRoads,
      {
        onSuccess: ({
          bufferNumber,
          geometry,
          trimmedSituationalFeatures,
          deleteSituationalFeatures,
          cuttedFeatures,
        }) => {
          setBufferNumber(bufferNumber);
          setCalculatedPoints(clickedPoints);
        },
        onError: () => {
          openSnackbar(
            t(
              "screen.widget.Editor.manager.routeSelection.routeApi.drawManuallyMessage"
            )
          );
          setCalculatedPoints(clickedPoints);
        },
        onFinish: () => {
          setLoading(false);
          setCount({});
          queyFeaturesLoading.current = false;
        },
      }
    );

  const { config } = useContext(ConfigContext);
  const clickHandler = useRef();
  const moveHandler = useRef();
  const queyFeaturesLoading = useRef(false);

  useEffect(() => {
    queyFeaturesLoading.current = addingPoint;
  }, [addingPoint]);

  const handleAddCoordinatePoint = useCallback(
    async (addedPoint, index = undefined) => {
      const newPoints = [...clickedPoints];
      const coordinateIndex =
        index ?? newPoints.findIndex((item) => item.coordinate.length === 0);

      const [esriRequest] = await loadModules(["esri/request"]);
      let snapPoints;
      try {
        snapPoints = await esriRequest(
          `${OPEN_ROUTE_SERVICE_API}/v2/snap/driving-car/geojson`,
          {
            method: "POST",
            responseType: "json",
            body: JSON.stringify({
              locations: [addedPoint.coordinate],
              radius: 6000,
            }),
            headers: {
              Authorization: OPEN_ROUTE_SERVICE_API_KEY,
              "Content-type": "application/json",
            },
          }
        );
      } catch (err) {
        console.log(err);
      }

      try {
        let addedIndex;
        if (coordinateIndex > -1) {
          const point = { ...newPoints[coordinateIndex] };

          if (point.graphic) {
            graphicsLayer.remove(point.graphic);
            const pointGraphics = getPointGraphics({
              graphicsLayer,
              point: point,
            });
            if (pointGraphics.length > 0) {
              graphicsLayer.removeMany(pointGraphics);
            }
          }

          addedPoint.graphic.id = `point-${point.id}`;
          addedPoint.id = point.id;
          newPoints[coordinateIndex] = addedPoint;
          addedIndex = coordinateIndex;
        } else {
          graphicsLayer.remove(newPoints[newPoints.length - 1].graphic);
          addedPoint.graphic.id = `point-${newPoints.length - 1}`;
          newPoints[newPoints.length - 1] = addedPoint;
          addedIndex = newPoints.length - 1;
        }

        if (snapPoints?.data?.features.length === 0) {
          openSnackbar(
            t(
              "screen.widget.Editor.manager.routeSelection.routeApi.noRoutablePointAround"
            )
          );
          addedPoint.searchable = false;
          // setShowMessage({
          //   show: true,
          //   pointIndex: addedIndex,
          // });
        } else {
          addedPoint.searchable = true;
        }
        setClickedPoints(newPoints);
        graphicsLayer.add(addedPoint.graphic);
      } catch (err) {
        openSnackbar("Erro while snapping point");
      } finally {
        queyFeaturesLoading.current = false;
        setAddingPoint(false);
      }
    },
    [clickedPoints]
  );

  useEffect(() => {
    graphicsLayer.removeAll();
    addLayerEffect([
      view.map.layers.find((l) => l.layerConfig?.titleLabel === "roads"),
      editableLayer,
    ]);
    if (clickedPoints.filter((item) => item.coordinate.length > 0).length > 0) {
      resetClickedPoints();
      setAddedPoints([]);
    }

    return () => {
      setShowRoadsEditor(false);
    };
  }, []);

  const updateTrimmedFeatures = useCallback(async () => {
    const [geometryEngineAsync, webMercatorUtils] = await loadModules([
      "esri/geometry/geometryEngineAsync",
      "esri/geometry/support/webMercatorUtils",
    ]);
    const graphics = graphicsLayer.graphics.filter(
      (graphic) => graphic.id && graphic.id.includes("route")
    );

    if (graphics.length === 0) return;
    try {
      const graphicsGeometries = graphics.toArray().map((g) => g.geometry);

      const unionedGeometry = await geometryEngineAsync.union(
        graphicsGeometries
      );

      let drawBuffer = await geometryEngineAsync.geodesicBuffer(
        unionedGeometry,
        30,
        "meters"
      );
      // graphicsLayer.remove(
      //   graphicsLayer.graphics.find((g) => g.id && g.id === "buffer")
      // );
      // graphicsLayer.add({
      //   symbol: selectionLineSymbol,
      //   geometry: drawBuffer,
      //   id: "buffer",
      // });

      if (!drawBuffer.spatialReference.isGeographic) {
        drawBuffer = webMercatorUtils.webMercatorToGeographic(drawBuffer);
      }

      const { trimmedFeatures, deletedFeatures } =
        await processFeaturesByGeometry(
          [
            ...trimmedSituationalFeatures.updateFeatures,
            ...trimmedSituationalFeatures.deleteFeatures,
          ],
          drawBuffer,
          {
            geometryEngineAsync,
          }
        );

      setTrimmedSituationalFeatures({
        updateFeatures: trimmedFeatures,
        deleteFeatures: deletedFeatures,
      });
    } catch (err) {
      console.log(err);
    }
  }, [bufferNumber, trimmedSituationalFeatures]);

  const removeRouteParts = useCallback(async ({ view, event }) => {
    try {
      const { results = [] } = await view.hitTest(event, {
        include: [graphicsLayer],
      });

      if (results.length === 0) return;
      const { graphic: firsGraphic } = results.at(0);

      if (firsGraphic?.id && firsGraphic.id.includes("route")) {
        graphicsLayer.remove(firsGraphic);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadModules([
      "esri/Graphic",
      "esri/geometry/geometryEngine",
      "esri/geometry/support/webMercatorUtils",
    ]).then(([Graphic, geometryEngine, webMercatorUtils]) => {
      if (clickHandler.current) {
        clickHandler.current.remove();
      }

      if (moveHandler.current) {
        moveHandler.current.remove();
      }

      moveHandler.current = view.on("pointer-move", (event) => {
        if (event.native.shiftKey || event.native.ctrlKey) {
          // view.hitTest(event, [graphicsLayer]).then((res) => {
          //   const firstRes = res?.results.at(0);
          //   if (!firstRes?.graphic) {
          //     view.container.style.cursor = "default";
          //     return;
          //   }
          //   view.container.style.cursor = "pointer";
          // });
          return;
        }

        if (!enabled) return;
        if (queyFeaturesLoading.current) return;
        view.hitTest(event).then((res) => {
          const firstRes = res?.results
            .filter(
              (res) =>
                res.graphic.layer?.layerConfig?.titleLabel === "roads" ||
                res.graphic.sourceLayer?.layerConfig?.titleLabel === "roads"
            )
            .at(0);
          if (!firstRes?.graphic) {
            view.container.style.cursor = "default";
            return;
          }
          view.container.style.cursor = "pointer";
        });
      });

      clickHandler.current = view.on("click", async (event) => {
        event.stopPropagation();
        if (event.native.shiftKey || event.native.ctrlKey) {
          removeRouteParts({ view, event });
          return;
        }

        if (!enabled) return;

        if (queyFeaturesLoading.current || activeIndex < 0) return;
        try {
          queyFeaturesLoading.current = true;
          view.container.style.cursor = "progress";
          setAddingPoint(true);
          let pointGeometry, pointGraphic;

          const hitRes = await view.hitTest(event);
          const firstRes = hitRes?.results
            .filter(
              (res) =>
                res.graphic.layer?.layerConfig?.titleLabel === "roads" ||
                res.graphic.sourceLayer?.layerConfig?.titleLabel === "roads"
            )
            .at(0);
          if (!firstRes?.graphic) {
            pointGeometry = event.mapPoint;
          } else {
            const res = await firstRes.graphic.layer.queryFeatures({
              where: `${
                firstRes.graphic.layer.definitionExpression
              } AND ${firstRes.graphic.getObjectId()} = ${
                firstRes.graphic.layer.objectIdField
              }`,
              returnGeometry: true,
            });

            if (res?.features.length > 0) {
              const feature = res.features[0];

              let geometry = feature.geometry;

              if (geometry?.spatialReference?.isGeographic) {
                geometry = webMercatorUtils.geographicToWebMercator(
                  feature.geometry
                );
              }

              const nearestCoordinate = geometryEngine.nearestCoordinate(
                geometry,
                event.mapPoint
              );

              pointGeometry = nearestCoordinate.coordinate;
            }
          }

          if (pointGeometry) {
            if (!pointGeometry.spatialReference.isGeographic) {
              pointGeometry =
                webMercatorUtils.webMercatorToGeographic(pointGeometry);
            }

            const coordinate = [
              pointGeometry.longitude,
              pointGeometry.latitude,
            ];

            const graphic = new Graphic({
              geometry: pointGeometry,
              symbol: pointGraphicSymbol,
            });

            const newPoint = {
              coordinate: coordinate,
              graphic: graphic,
              label: `${pointGeometry.latitude}, ${pointGeometry.longitude}`,
            };

            handleAddCoordinatePoint(
              newPoint,
              activeIndex >= 0 ? activeIndex : undefined
            );
            view.container.style.cursor = "default";
          } else {
            queyFeaturesLoading.current = false;
            view.container.style.cursor = "default";
            setAddingPoint(false);
          }
        } catch (err) {
          view.cursor = "default";
          console.log(err);
          queyFeaturesLoading.current = false;
        }
      });
    });

    return () => {
      clickHandler.current?.remove();
      moveHandler.current?.remove();
      view.container.style.cursor = "default";
    };
  }, [clickedPoints, activeIndex, removeRouteParts]);

  const addPointToMap = useCallback(
    async (feature, index) => {
      setAddingPoint(true);
      try {
        const [Graphic, webMercatorUtils] = await loadModules([
          "esri/Graphic",
          "esri/geometry/support/webMercatorUtils",
        ]);

        if (!feature?.geometry) {
          throw new Error("No geometry found");
        }
        let geometry = feature.geometry;
        if (geometry?.spatialReference?.isGeographic) {
          geometry = webMercatorUtils.geographicToWebMercator(feature.geometry);
        }

        if (geometry.latitude && geometry.longitude) {
          const nearestMapPoint = [geometry.longitude, geometry.latitude];
          const point = {
            type: "point",
            longitude: geometry.longitude,
            latitude: geometry.latitude,
            spatialReference: view.spatialReference,
          };

          const graphic = new Graphic({
            geometry: point,
            symbol: pointGraphicSymbol,
          });

          await handleAddCoordinatePoint(
            {
              coordinate: nearestMapPoint,
              graphic: graphic,
              label:
                feature.name ?? `${geometry.latitude}, ${geometry.longitude}`, //nearestMapPoint.join(", "),
              city: feature.city,
            },
            index
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        setAddingPoint(false);
      }
    },
    [handleAddCoordinatePoint]
  );

  const handleRemoveSearchPoint = useCallback(
    (index, removeInput) => {
      const newClickedPoints = [...clickedPoints];
      const point = newClickedPoints[index];

      if (!point) return;

      if (point.graphic) {
        graphicsLayer.remove(point.graphic);

        const routeId = point.coordinate.join("|");
        const pointsToRemove = [];

        graphicsLayer.graphics.forEach((g) => {
          if (g.id) {
            if (g.id.includes(routeId)) {
              pointsToRemove.push(g);
            } else if (
              g.id.includes("line") &&
              Array.isArray(g.intersectedPoints) &&
              g.intersectedPoints.includes(point.coordinate.join("|"))
            ) {
              pointsToRemove.push(g);
            } else if (g.id.includes(point.graphic.id)) {
              pointsToRemove.push(g);
            }
          }
        });

        if (pointsToRemove.length > 0) {
          graphicsLayer.removeMany(pointsToRemove);
        }
      }

      if (removeInput) {
        newClickedPoints.splice(index, 1);
      } else {
        newClickedPoints[index] = {
          ...newClickedPoints[index],
          coordinate: [],
          label: "",
          graphic: "",
          searchable: true,
        };
      }

      setClickedPoints(newClickedPoints);
    },
    [clickedPoints]
  );

  const handleSuggestPoint = useCallback(async () => {
    try {
      setShowMessage((prev) => ({
        ...prev,
        loading: true,
      }));
      const newClickedPoints = [...clickedPoints];
      const newPoint = { ...newClickedPoints[showMessage.pointIndex] };

      const [
        Graphic,
        Point,
        Polyline,
        Polygon,
        geometryEngineAsync,
        webMercatorUtils,
        esriRequest,
      ] = await loadModules([
        "esri/Graphic",
        "esri/geometry/Point",
        "esri/geometry/Polyline",
        "esri/geometry/Polygon",
        "esri/geometry/geometryEngineAsync",
        "esri/geometry/support/webMercatorUtils",
        "esri/request",
      ]);

      const { data } = await esriRequest(
        `${OPEN_ROUTE_SERVICE_API}/geocode/reverse?api_key=` +
          OPEN_ROUTE_SERVICE_API_KEY,
        {
          method: "GET",
          responseType: "json",
          query: {
            "point.lon": newPoint.coordinate[0],
            "point.lat": newPoint.coordinate[1],
            layers: ["street"],
            "boundary.circle.radius": 1000,
            size: 10,
          },
        }
      );

      if (data.features.length > 0) {
        const result = geojsonToArcGIS(data);
        const [nearestFeature] = result;

        // data.features.forEach(feat=>{
        //   console.log(feat);
        //   const p = new Point({
        //     latitude: feat.geometry.y,
        //     longitude: feat.geometry.x,
        //     spatialReference: feat.geometry.spatialReference
        //   })
        //
        //   const graphic = new Graphic({
        //     geometry: p,
        //     symbol: pointGraphicSymbol
        //   })
        //
        //   graphicsLayer.add(graphic);
        // })

        const p = new Point({
          latitude: nearestFeature.geometry.y,
          longitude: nearestFeature.geometry.x,
          spatialReference: nearestFeature.geometry.spatialReference,
        });

        const geometry = webMercatorUtils.geographicToWebMercator(p);

        const graphic = new Graphic({
          geometry: geometry,
          symbol: pointGraphicSymbol,
        });

        const query = {
          geometry: geometry,
          distance: 10000, // search within 10000 meters
          spatialRelationship: "intersects",
          returnGeometry: true,
          outFields: ["*"],
        };

        const results = await editableLayer.queryFeatures(query);

        newPoint.graphic.geometry = geometry;
        newPoint.coordinate = [
          nearestFeature.geometry.x,
          nearestFeature.geometry.y,
        ];
        newPoint.label = newPoint.coordinate.join(", ");
        newPoint.searchable = true;
        view.goTo(newPoint.graphic);

        newClickedPoints[showMessage.pointIndex] = newPoint;
        setClickedPoints(newClickedPoints);

        // graphicsLayer.add(graphic);
        // const polyline = new Polyline({
        //   paths: [
        //     [nearestFeature.geometry.y - 1, nearestFeature.geometry.x + 1],
        //     [addedPoint.coordinate[0], addedPoint.coordinate[1]]
        //   ]
        // })
        //
        //
        // editableLayer.queryFeatures({
        //   geometry: polyline,
        //   spatialRelationship: "intersects"
        // }).then(res=>{
        //   if (res.features.length > 0){
        //     console.log(res);
        //   } else {
        //     console.log('none');
        //   }
        // })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowMessage({
        show: false,
        pointIndex: -1,
        loading: false,
      });
    }
  }, [clickedPoints, showMessage, editableLayer]);

  const handleFindRoute = useCallback(() => {
    setLoading(true);
    queyFeaturesLoading.current = true;
    handleCreateRoute();
  }, [handleCreateRoute]);

  if (!enabled) return;

  return (
    <>
      {/* {showMessage.show && (
        <Message
          config={config}
          onCancel={() => {
            setShowMessage({
              show: false,
              pointIndex: -1,
            });
          }}
          onSubmit={handleSuggestPoint}
        >
          <>
            <p
              style={{
                color: "#525252",
                textAlign: "center",
                background: "#fff",
                padding: 2,
                borderRadius: 4,
                fontWeight: 500,
                fontSize: 16,
                marginBottom: 0,
              }}
            >
              Couldn't find routable point withing a radius of 6000 meters.
            </p>
            <p
              style={{
                color: "#525252",
                textAlign: "center",
                background: "#fff",
                padding: 2,
                borderRadius: 4,
                fontSize: 12,
                marginTop: 4,
              }}
            >
              Do you want to select a nearby routable point instead?
            </p>
            {showMessage.loading && <Loader />}
          </>
        </Message>
      )} */}
      <StyledCalculateRouteContainer>
        <StyledSituationalTitle>
          <span>
            {t("screen.widget.Editor.manager.routeSelection.routeApi.title")}
          </span>
          <span>
            {t("screen.widget.Editor.manager.routeSelection.routeApi.subtitle")}
          </span>
        </StyledSituationalTitle>

        <SearchCoordinates
          setClickedPoints={setClickedPoints}
          addPointToMap={addPointToMap}
          clickedPoints={clickedPoints}
          removeSearchPoint={handleRemoveSearchPoint}
          handleCreateRoute={handleFindRoute}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          color={activeColor}
          errorPoints={errorPoints}
          calculatedPoints={calculatedPoints}
          setManualDrawPoint={setManualDrawPoint}
          disableInputs={addingPoint}
          setMatchBaselineRoads={setMatchBaselineRoads}
          matchBaselineRoads={matchBaselineRoads}
        />
      </StyledCalculateRouteContainer>

      {/* {showRoadsEditor && (
        <RoadsSituationalManual
          handleNext={handleNext}
          handlePrev={() => {
            setShowRoadsEditor(false);
          }}
          manualDrawPoint={manualDrawPoint}
          editType={editType}
        />
      )} */}
    </>
  );
};

export default EnterCoordinates;
