import React, { useCallback, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  graphicsLayer,
  showpublicFieldName,
} from "../../../../../utils/API";
import { isYesNoDomain } from "../../../../../utils/helper";
import { createSymbol } from "../../../../../utils/symbologies";
import { Checkbox } from "../../../../UI";
import { EditorContext } from "../../EditorContextProvider";
import {
  EditType,
  getFieldDefaultValue,
} from "../../EditorSwiper/EditorSwiper";
import { getFeatureIcon } from "../EditorFields";
import { FieldWrapper } from "../EditorFields-styled";
import { EditorSwitch, SelectionTags } from "../Fields";
import InputField from "./Fields/InputField";
const borderColor = "#4c4c4c";

const AttributeFields = ({
  fields,
  config,
  t,
  symbology,
  onChangeHandler,
  editableLayer,
  requiredFields = [],
  showCheckbox = false,
  disabled = false,
  onInputBlur = undefined,
}) => {
  const [allowChange, setAllowChange] = useState(!disabled);
  const {
    highlightFeature,
    setHighlightSymbol,
    setHighlightFeature,
    batchUpdateFeatures,
    setBatchUpdateFeatures,
    updateType,
    sketchGraphicsLayer,
    activeColor,
    editType,
  } = useContext(EditorContext);
  const { getValues } = useFormContext();

  const renderField = (f, value) => {
    const label = t("layer.fieldAlias." + f.name + ".title", f.alias);

    const information = t("layer.fieldAlias." + f.name + ".information", "");
    const { colorMap = {}, iconMap = {} } = symbology || {};
    const isRequired = requiredFields.includes(f.name);
    const isDisabled =
      f.name === currAsOfDateFieldName ||
      f.name === asOfDateFieldName ||
      !allowChange;

    if (f.domain) {
      const domain = f.domain;
      const options = domain.codedValues.map((cv) => {
        return {
          code: cv.code,
          name: t("layer.domain." + f.name + "." + cv.code, cv.name),
        };
      });

      if (isYesNoDomain(f, editableLayer)) {
        const check = {
          yes: false,
          no: false,
          unknown: false,
        };

        options.forEach((cv) => {
          const lowerName = cv.name.toLowerCase();
          if (lowerName in check) {
            check[lowerName] = true;
          }
        });
        return (
          <div
            style={{
              margin: "4px 0px",
            }}
          >
            <EditorSwitch
              key={"i" + f.name}
              // id="editorFields"
              t={t}
              value={value}
              label={label}
              field={f}
              information={information}
              onChange={
                showpublicFieldName === f.name ? onChangeHandler : undefined
              }
              isRequired={isRequired}
              disabled={isDisabled}
            />
          </div>
        );
      }

      let colors = colorMap.default;
      let isColorMapField = false;
      if (Array.isArray(colorMap.fields) && colorMap.fields.includes(f.name)) {
        isColorMapField = true;
      } else if (colorMap.field === f.name) {
        isColorMapField = true;
      }

      let isIconMapField = false;
      if (Array.isArray(iconMap.fields) && iconMap.fields.includes(f.name)) {
        isIconMapField = true;
      } else if (iconMap.field === f.name) {
        isIconMapField = true;
      }

      const onSelect = (option) => {
        if (isColorMapField || isIconMapField) {
          if (
            !!highlightFeature &&
            !editableLayer.layerConfig?.isEditorCurved
          ) {
            const editGraphic = highlightFeature.clone();
            const formValues = getValues();

            Object.keys(formValues).forEach((k) => {
              editGraphic.attributes[k] = formValues[k];
            });

            const iconName = getFeatureIcon(
              editGraphic,
              symbology,
              editableLayer
            );

            let color;

            if (isColorMapField && colors) {
              color = colors[option.code];
            } else if (isIconMapField && colors) {
              let colorField = Array.isArray(colorMap.fields)
                ? colorMap.fields[0]
                : colorMap.field;

              color =
                editGraphic.attributes[colorField] == null
                  ? colors["NULL"]
                  : colors[editGraphic.attributes[colorField]];
            }

            if (!color) {
              color = symbology.defaultColor;
            }

            if (color) {
              if (editableLayer.geometryType === "point") {
                graphicsLayer.removeAll();
              } else {
                graphicsLayer.remove(highlightFeature);
              }

              editGraphic.symbol = createSymbol(
                symbology,
                editableLayer,
                iconName,
                color ?? symbology.defaultColor,
                symbology.defaultSize
              );

              if (sketchGraphicsLayer) {
                const sketchGraphic = sketchGraphicsLayer.graphics.items.find(
                  (g) =>
                    g.attributes &&
                    g.attributes[editableLayer.objectIdField] ===
                      editGraphic.attributes[editableLayer.objectIdField]
                );
                if (sketchGraphic) {
                  sketchGraphic.attributes = editGraphic.attributes;
                  sketchGraphic.symbol = editGraphic.symbol;
                }
                return;
              }
              graphicsLayer.add(editGraphic);
              setHighlightSymbol(editGraphic.symbol);
              setHighlightFeature(editGraphic);
            }
          } else if (batchUpdateFeatures.length > 0) {
            const newFeatures = [...batchUpdateFeatures];

            newFeatures.forEach((item) => {
              const iconName = getFeatureIcon(
                item.feature,
                symbology,
                editableLayer
              );

              let color;
              if (isColorMapField && colors) {
                color = colors[option.code];
              } else if (isIconMapField && colors) {
                let colorField = Array.isArray(colorMap.fields)
                  ? colorMap.fields[0]
                  : colorMap.field;
                color =
                  item.graphic.attributes[colorField] == null
                    ? colors["NULL"]
                    : colors[item.graphic.attributes[colorField]];
              }

              if (item.graphic && color) {
                item.graphic.symbol = createSymbol(
                  symbology,
                  editableLayer,
                  iconName,
                  color,
                  symbology.defaultSize
                );
              }
            });

            setBatchUpdateFeatures(newFeatures);
          } else if (
            editableLayer.layerConfig?.isEditorCurved ||
            editableLayer.layerConfig.titleLabel === "roadsSituational"
          ) {
            const symbol = createSymbol(
              symbology,
              editableLayer,
              "",
              colors[option.code],
              symbology.defaultSize
            );

            graphicsLayer.graphics.forEach((g) => {
              g.symbol = symbol;
            });
          } else if (
            editType === EditType.create &&
            editableLayer.layerConfig?.titleLabel === "roads"
          ) {
            const symbol = createSymbol(
              symbology,
              editableLayer,
              "",
              colors[option.code],
              symbology.defaultSize
            );

            sketchGraphicsLayer.graphics.forEach((g) => {
              g.symbol = symbol;
            });
          }
        }
        if (onChangeHandler) {
          onChangeHandler({ name: f.name, value: option.code });
        }
      };

      return (
        <SelectionTags
          key={"i" + f.name}
          // id="editorFields"
          label={label}
          colors={isColorMapField ? colors : {}}
          options={options}
          value={value}
          t={t}
          field={f}
          config={config}
          information={information}
          onSelect={onSelect}
          isRequired={isRequired}
          borderColor={borderColor}
          isDisabled={isDisabled}
        />
      );
    }

    switch (f.type) {
      case "double":
        return (
          <InputField
            label={label}
            type="number"
            step="any"
            defaultValue={value}
            placeholder="0.00"
            borderColor={borderColor}
            isRequired={isRequired}
            field={f}
            disabled={isDisabled}
            information={information}
          />
        );
      case "integer":
        return (
          <InputField
            label={label}
            type="number"
            step="1"
            defaultValue={value}
            placeholder="0"
            borderColor={borderColor}
            isRequired={isRequired}
            field={f}
            disabled={isDisabled}
            information={information}
          />
        );
      case "small-integer":
        return (
          <InputField
            label={label}
            type="number"
            step="1"
            defaultValue={value}
            placeholder="0"
            borderColor={borderColor}
            isRequired={isRequired}
            field={f}
            disabled={isDisabled}
            information={information}
          />
        );

      case "date":
        const today = new Date();
        const todayString = today.toISOString().split("T")[0];

        return (
          <InputField
            label={label}
            type="date"
            defaultValue={value || todayString}
            description="description"
            field={f}
            isRequired={isRequired}
            borderColor={borderColor}
            disabled={isDisabled}
            information={information}
          />
        );
      default:
        if (f.length > 60) {
          return (
            <InputField
              label={label}
              placeholder={label}
              maxLength={f.length}
              defaultValue={value}
              borderColor={borderColor}
              description="Some long text for description of this field"
              field={f}
              isRequired={isRequired}
              disabled={isDisabled}
              information={information}
              onInputBlur={onInputBlur}
            />
          );
        } else {
          return (
            <InputField
              description="description"
              noMargin
              label={label}
              placeholder={label}
              type="text"
              maxLength={f.length}
              defaultValue={value}
              borderColor={borderColor}
              field={f}
              isRequired={isRequired}
              disabled={isDisabled}
              information={information}
              onInputBlur={onInputBlur}
            />
          );
        }
    }
  };
  const getField = (f) => {
    let value = "";
    if (highlightFeature) {
      if (highlightFeature.attributes[f.name] === undefined) {
        value = getFieldDefaultValue(f, config);
      } else if (highlightFeature.attributes[f.name] != null) {
        if (f.type === "date")
          value = new Date(highlightFeature.attributes[f.name])
            .toISOString()
            .substring(0, 10);
        else value = highlightFeature.attributes[f.name];
      }
    }

    // if (showFilled && (value == null || value === "")) return null;

    return <FieldWrapper key={f.name}>{renderField(f, value)}</FieldWrapper>;
  };

  const onCheckboxChange = useCallback((e) => {
    const { checked } = e.target;
    setAllowChange(checked);
  }, []);

  return (
    <>
      {showCheckbox && (
        <div
          style={{
            padding: "4px 0px 8px 0px",
          }}
        >
          <Checkbox
            onChange={onCheckboxChange}
            label="Allow edit"
            color={activeColor}
            borderRadius="4px"
            checked={allowChange}
          />
        </div>
      )}
      {fields.map((field) => getField(field))}
    </>
  );
};

export default AttributeFields;
