import React from "react";
import { Button } from "../../../../UI";
import styled from "styled-components";

const StyledTag = styled.span`
  position: absolute;
  right: -5px;
  top: -7px;
  font-size: 10px !important;
  border-radius: 100px;
  background: #f4f4f4;
  padding: 3px 6px;
  color: black;
  background: #d9ecf5;
  color: #005e8d;
`;

const ActionButton = ({
  onClick,
  count = 0,
  showCount = false,
  Icon,
  color,
  styles,
  isActive = false,
  children,
  ...props
}) => (
  <Button
    onClick={onClick}
    color={(isActive || count > 0) && color ? color : "#4b4b4b"}
    style={{
      borderColor: isActive || count > 0 ? color : "",
      padding: "0px 8px",
      ...styles,
    }}
    disabled={!(isActive || count > 0)}
    position="relative"
    {...props}
  >
    {Icon && (
      <Icon
        fill={isActive || count > 0 ? color : "#4b4b4b"}
        width="16"
        height="16"
      />
    )}
    {children}
    {/* {count > 0 && <span>({count})</span>} */}
    {count > 0 && <StyledTag>{count}</StyledTag>}
  </Button>
);

export default ActionButton;
