import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFeatureTableOpen } from "../redux/action/FeatureTable-actions";
import useWindowSize from "./useWindowSize";

const mobileOffset = 35;

const useDragSidebar = ({
  defaultWidth,
  ref,
  expandLimit = window.innerWidth / 1.3,
}) => {
  const { open: featureTableOpen } = useSelector(
    (state) => state.featureTableReducer
  );
  const [expand, setExpand] = useState(false);
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  useEffect(() => {
    if (!expand && ref.current) {
      ref.current.style.flexBasis = "";
    }
  }, [expand]);

  const handleExpand = (isOpen) => {
    requestAnimationFrame(() => {
      setExpand(isOpen);

      if (isOpen && featureTableOpen) {
        dispatch(setFeatureTableOpen(false));
      }
    });
  };

  // useEffect(() => {
  //   if (!ref.current) return;
  //   if (isMobile) {
  //     ref.current.style = '';
  //     const viewDiv = document.querySelector('#viewDiv');
  //     viewDiv.style.height = `calc(100% - ${mobileOffset}px)`;
  //     viewDiv.style.transition = ``;
  //   } else {
  //     const viewDiv = document.querySelector('#viewDiv');
  //     viewDiv.style.height = ``;
  //   }
  //
  // },[isMobile])

  const onArrowClick = (e) => {
    if (isMobile) {
      handleExpand(!expand);
      return;
    }
    e.stopPropagation();
    const viewDiv = document.querySelector("#viewDiv");
    viewDiv.style.transition = "all 0.5s";
    if (!expand) {
      ref.current.style.flexBasis = "";
      handleExpand(!expand);
    } else {
      ref.current.style.flexBasis = "";
      handleExpand(!expand);
    }
  };

  const doDrag = useCallback(
    (e) => {
      if (!ref.current) return;
      e.preventDefault();
      ref.current.style.transition = "none";
      const appWidth = window.innerWidth;
      const width = appWidth - e.clientX;
      const viewDiv = document.querySelector("#viewDiv");
      viewDiv.style.transition = "none";

      if (width < defaultWidth) {
        ref.current.style.removeProperty("flex-basis");
        // ref.current.style.flexBasis = `${350}px `;
        // ref.current.style.transition = "all 0.5s";
        // handleExpand(false);
        return;
      }

      // if (width < 220 && ref.current.offsetWidth >= 220) {
      //   // ref.current.style.flexBasis = ``;
      //   // ref.current.style.transition = "all 0.5s";
      //   // handleExpand(false);
      //   // stopDrag();
      //   return;
      // }

      if (width > expandLimit) {
        ref.current.style.flexBasis = `${expandLimit}px`;
        return;
      }
      ref.current.style.flexBasis = `${width}px`;
      if (!expand) {
        handleExpand(true);
      }
    },
    [expand, expandLimit]
  );

  const stopDrag = useCallback(() => {
    if (!ref.current) return;
    const viewDiv = document.querySelector("#viewDiv");

    // Reset text selection after drag
    document.body.style.userSelect = "";
    document.body.style.mozUserSelect = "";
    document.body.style.msUserSelect = "";

    viewDiv.style.transition = "all 0.5s";
    ref.current.style.transition = "all 0.5s";
    document.documentElement.removeEventListener("mousemove", doDrag, false);
    document.documentElement.removeEventListener("mouseup", stopDrag, false);
  }, [doDrag]);

  const initDrag = useCallback(
    (e) => {
      if (isMobile) return;
      // e.preventDefault();

      document.body.style.userSelect = "none";
      document.body.style.mozUserSelect = "none";
      document.body.style.msUserSelect = "none";
      // if (e.target.id !== 'sidebar-resizer') return;
      document.documentElement.addEventListener("mousemove", doDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);
    },
    [isMobile, doDrag, stopDrag]
  );

  const expandToWidth = (width) => {
    ref.current.style.flexBasis = `${width}px`;
  };

  return {
    expand,
    setExpand: handleExpand,
    initDrag,
    doDrag,
    stopDrag,
    onArrowClick,
    isMobile,
    mobileOffset,
    expandToWidth,
  };
};

export default useDragSidebar;
