import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { showpublicFieldName, view } from "../../../../../utils/API";
import { ConfigContext } from "../../../../../utils/ConfigContext";
import { getConfigISO, getFieldsByPattern } from "../../../../../utils/helper";
import { getLayerSymbology } from "../../../../../utils/symbologies";
import UploadMediaFiles from "../../../../Editor/UploadMediaFiles/UploadMediaFiles";
import { ArrowDownIcon, ArrowUpIcon } from "../../../../Icons";
import ActionButton from "../../../CycleManager/Checklist/ChecklistTable/ActionButton";
import { DeleteButton, SaveAndConfirmButton } from "../../Buttons";
import CoordinatesSelection from "../../CoordinatesSelection/CoordinatesSelection";
import { EditorContext, UpdateType } from "../../EditorContextProvider";
import { EditType } from "../../EditorSwiper/EditorSwiper";
import Message from "../../Message";
import {
  AccordionBody,
  AccordionButton,
  AccordionWrapper,
} from "../Accordion/Accordion";
import AttributeFields from "../AttributeFields/AttributeFields";
import { getFeatureNameField } from "../EditorFields";

export const getErrorTitles = (errors, t, layer) => {
  try {
    return layer.fields
      .filter((f) => f.name in errors)
      .map((f) => t("layer.fieldAlias." + f.name + ".title", f.alias));
  } catch (err) {
    return Object.keys(errors).map((fieldName) =>
      t("layer.fieldAlias." + fieldName + ".title", fieldName)
    );
  }
};

const EditableFields = ({
  handleChange,
  editType,
  handleSubmit,
  getIsDeletedField,
  doDelete,
  showBaseline,
}) => {
  const [requiredFieldsMessage, setRequiredFieldsMessage] = useState({
    show: false,
    fields: [],
  });
  const [showRest, setShowRest] = useState(false);
  const [showIsoMessage, setShowIsoMessage] = useState(false);

  const [message, setMessage] = useState({
    show: false,
    title: "",
    subtitle: "",
  });

  const [pendingAction, setPendingAction] = useState("");
  const isIsoMatching = useRef(false);
  const confirmedIsoMatch = useRef(true);
  const confirmShowPublic = useRef(false);

  const { config } = useContext(ConfigContext);
  const {
    editableLayer,
    activeColor,
    requiredFields,
    highlightFeature,
    updateType,
    editingInitialUpdateType,
    addedPoints,
    attachments,
    hasEditedAttachments,
  } = useContext(EditorContext);
  const accordionWrapperRef = useRef();

  //for baseline edits when we need to have location first but not opened
  const [selectedId, setSelectedId] = useState(
    updateType === UpdateType.baseline && editType === EditType.edit ? 1 : 0
  );

  const { t } = useTranslation("common");

  const {
    trigger,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
  } = useFormContext();

  useEffect(() => {
    let handler;
    if (!view || (editType === EditType.create && selectedId === 0)) return;

    handler = view.on("click", (e) => {
      e.stopPropagation();
    });

    // view.popupEnabled = false;
    // view.popupEnabledCustom = false;

    return () => {
      if (!view) return;
      handler?.remove();
      // view.popupEnabled = true;
      // view.popupEnabledCustom = true;
    };
  }, [selectedId]);

  const symbology = useMemo(
    () => getLayerSymbology(editableLayer, config) || {},
    [editableLayer, config]
  );

  const nameField = useMemo(
    () => getFeatureNameField(editableLayer),
    [editableLayer]
  );

  const situationalFields = useMemo(() => {
    const lc = editableLayer.layerConfig;

    const allSituationalFields = getFieldsByPattern(
      editableLayer,
      lc.situationalFields
    );

    return allSituationalFields;
  }, [editableLayer]);

  //to trigger iso check when location changes
  useEffect(() => {
    confirmedIsoMatch.current = false;
  }, [addedPoints]);

  const baselineFields = useMemo(() => {
    const lc = editableLayer.layerConfig;
    let allBaseLineFields = getFieldsByPattern(
      editableLayer,
      lc.baselineFields
    );

    if (updateType === UpdateType.baseline && lc.isShowPublicEditable) {
      const situationalHasShowPublic = situationalFields.some(
        (f) => f.name === showpublicFieldName
      );
      if (situationalHasShowPublic) {
        allBaseLineFields = allBaseLineFields.filter(
          (f) => f.name !== showpublicFieldName
        );
      } else {
        const showPublicField = editableLayer.fields.find(
          (f) => f.name === showpublicFieldName
        );
        if (showPublicField) {
          allBaseLineFields.unshift(showPublicField);
        }
      }
    }

    return !showBaseline ? [] : allBaseLineFields;
  }, [
    editableLayer,
    nameField,
    updateType,
    situationalFields,
    editType,
    showBaseline,
  ]);

  const requiredBaselineFields = useMemo(() => {
    return baselineFields.filter((f) => requiredFields.includes(f.name));
  }, [baselineFields, requiredFields]);

  const baselineOptionalFields = useMemo(() => {
    return baselineFields.filter(
      (field) => !requiredFields.includes(field.name)
    );
  }, [baselineFields, requiredFields]);

  const onInputBlur = useCallback(
    (_e, fieldName) => {
      if (fieldName === "iso3") {
        confirmedIsoMatch.current = false;
      }
    },
    [config]
  );

  const makeIsoCheck = useCallback(() => {
    let isMatch = true;
    try {
      const isoField = editableLayer.fields.find(
        (f) => f.name === "iso3" || f.name === "iso3a"
      );

      if (!confirmedIsoMatch.current) {
        if (isoField) {
          const iso3Value = getValues(isoField.name);

          if (iso3Value) {
            const isoCodes = getConfigISO(config);

            if (isoCodes.length > 0 && typeof iso3Value === "string") {
              const isMatching = isoCodes.includes(iso3Value.toUpperCase());
              // confirmedIsoMatch.current = isMatching;
              isMatch = isMatching;
            } else {
              // confirmedIsoMatch.current = true;
            }
          }
        }
      }
    } catch (error) {
      console.error("Error in makeIsoCheck:", error);
      isMatch = true; // Return true in case of any errors
    }

    return isMatch;
  }, [config, editableLayer, getValues]);

  const hasScroll = useRef(true);

  useLayoutEffect(() => {
    try {
      requestAnimationFrame(() => {
        if (accordionWrapperRef.current) {
          if (
            updateType === UpdateType.situational &&
            editType === EditType.edit
          ) {
            const { scrollHeight, clientHeight } = accordionWrapperRef.current;

            if (scrollHeight === clientHeight) {
              setShowRest(true);
              hasScroll.current = false;
            }
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [updateType, editType]);

  const renderAccordionComponent = (id, isSelected) => {
    switch (id) {
      case "coordinates":
        return (
          <CoordinatesSelection
            config={config}
            editableLayer={editableLayer}
            editType={editType}
            clickActive={false}
            showLocationInput={EditType.edit === editType && isSelected}
            disabled={EditType.edit === editType}
          />
        );

      case "baseline-required":
        return (
          <AttributeFields
            fields={requiredBaselineFields}
            config={config}
            t={t}
            symbology={symbology}
            onChangeHandler={handleChange}
            editableLayer={editableLayer}
            requiredFields={requiredFields}
            onInputBlur={onInputBlur}
          />
        );

      case "baseline-optional":
        // const baselineOptionalFields = baselineFields.filter(
        //   (field) => !requiredFields.includes(field.name)
        // );

        // const showPublicField = editableLayer.fields.find(
        //   (f) => showpublicFieldName === f.name
        // );
        // if (showPublicField) {
        //   baselineOptionalFields.unshift(showPublicField);
        // }
        return (
          <>
            <AttributeFields
              fields={baselineOptionalFields}
              config={config}
              t={t}
              symbology={symbology}
              onChangeHandler={handleChange}
              editableLayer={editableLayer}
              onInputBlur={onInputBlur}
            />
            {situationalFields.length === 0 && (
              <UploadMediaFiles
                color={activeColor}
                feature={highlightFeature}
                layer={editableLayer}
              />
            )}
          </>
        );

      case "baseline-filled":
        return (
          <AttributeFields
            fields={baselineFields}
            config={config}
            t={t}
            symbology={symbology}
            onChangeHandler={handleChange}
            editableLayer={editableLayer}
            requiredFields={requiredFields}
            onInputBlur={onInputBlur}
            disabled
            showCheckbox
          />
        );

      case "situational":
        return (
          <>
            <AttributeFields
              fields={situationalFields}
              config={config}
              t={t}
              symbology={symbology}
              onChangeHandler={handleChange}
              editableLayer={editableLayer}
              requiredFields={requiredFields}
              onInputBlur={onInputBlur}
            />
            <UploadMediaFiles
              color={activeColor}
              feature={highlightFeature}
              layer={editableLayer}
            />
          </>
        );

      default:
        break;
    }
  };

  const accordion = useMemo(() => {
    const acc = [];

    if (updateType === UpdateType.baseline) {
      acc.push({
        id: "coordinates",
        label:
          editableLayer.geometryType === "point"
            ? t("screen.widget.Editor.location.label")
            : t("screen.widget.Editor.geometry.label"),
        information:
          editableLayer.geometryType === "point"
            ? t("screen.widget.Editor.location.information")
            : t("screen.widget.Editor.geometry.information"),
        show:
          (editableLayer.geometryType === "point" ||
            editType === EditType.edit) &&
          editableLayer.layerConfig?.titleLabel !== "roadsSituational",
        color: activeColor,
      });
    }

    if (editType === EditType.create || updateType === UpdateType.baseline) {
      if (requiredBaselineFields.length > 0) {
        acc.push({
          id: "baseline-required",
          label:
            `${t("screen.widget.common.required")} ` +
            t("screen.widget.Editor.baselineFields.label"),
          information: t("screen.widget.Editor.baselineFields.information"),
          color: activeColor,
          show: true,
        });
      }

      if (baselineOptionalFields.length > 0 || situationalFields.length === 0) {
        acc.push({
          id: "baseline-optional",
          label:
            `${t("screen.widget.common.optional", "Optional")} ` +
            t("screen.widget.Editor.baselineFields.label"),
          information: t("screen.widget.Editor.baselineFields.information"),
          color: activeColor,
          show: true,
        });
      }
    }

    if (situationalFields.length > 0) {
      if (
        (editType === EditType.edit && updateType === UpdateType.situational) ||
        (editType === EditType.create &&
          editingInitialUpdateType &&
          editingInitialUpdateType == UpdateType.situational)
      ) {
        acc.push({
          id: "situational",
          label: t("screen.widget.Editor.situationalFields.label"),
          information: t("screen.widget.Editor.situationalFields.information"),
          color: config.opsColor,
          show: true,
        });
      }
    }

    if (updateType === UpdateType.situational) {
      acc.push({
        id: "coordinates",
        label:
          editableLayer.geometryType === "point"
            ? t("screen.widget.Editor.location.label")
            : t("screen.widget.Editor.geometry.label"),
        information:
          editableLayer.geometryType === "point"
            ? t("screen.widget.Editor.location.information")
            : t("screen.widget.Editor.geometry.information"),
        show: editableLayer.layerConfig?.titleLabel !== "roadsSituational",
        color: "#7b7b7b",
      });
    }

    if (EditType.edit === editType && updateType === UpdateType.situational) {
      // const filledFields = Object.keys(
      //   highlightFeature?.attributes || {}
      // ).filter(
      //   (key) =>
      //     highlightFeature.attributes[key] != null && //to filter null and undefined
      //     highlightFeature.attributes[key] !== "" &&
      //     baselineFields.some((f) => f.name === key)
      // );

      // const fields = baselineFields.filter((f) =>
      //   filledFields.includes(f.name)
      // );

      if (baselineFields.length > 0) {
        acc.push({
          id: "baseline-filled",
          label: t("screen.widget.Editor.baselineFields.label"),
          information: t("screen.widget.Editor.baselineFields.information"),
          color: "#7b7b7b",
          show: true,
        });
      }
    }

    return acc;
  }, [
    editableLayer,
    t,
    requiredFields,
    updateType,
    editType,
    requiredBaselineFields,
    baselineFields,
    highlightFeature,
    config,
    baselineOptionalFields,
  ]);

  const visibleAccordion = useMemo(() => {
    return accordion.filter((item) => item.show);
  }, [accordion]);

  const handleAccordionSelection = useCallback(
    (item) => {
      if (EditType.create === editType && addedPoints.length === 0) {
        return;
      }

      const index = visibleAccordion.findIndex(
        (visible) => visible.id === item.id
      );
      if (index > -1) {
        setSelectedId(index);
        if (showRest && hasScroll.current) {
          setShowRest(false);
        }
      }
    },
    [selectedId, showRest, visibleAccordion, editType, addedPoints]
  );

  const handleNext = useCallback(async () => {
    const isoMatch = makeIsoCheck();

    if (!isoMatch) {
      setPendingAction("next");
      setShowIsoMessage(true);
      return;
    }
    const current = visibleAccordion[selectedId];

    try {
      const showPublic = getValues(showpublicFieldName);

      if (
        current?.id !== "coordinates" &&
        String(showPublic) === "2" &&
        !confirmShowPublic.current
      ) {
        setPendingAction("next");
        setMessage({
          show: true,
          title: "Show public message",
          subtitle: "Are you sure you want to show this data to the public?",
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }

    if (selectedId < visibleAccordion.length - 1) {
      let isValid = true;
      if (current.id !== "coordinates") {
        isValid = await trigger();
      }

      if (isValid) {
        setSelectedId(selectedId + 1);
        clearErrors();
      } else {
        const errorTitles = getErrorTitles(errors, t, editableLayer);

        setRequiredFieldsMessage({
          show: true,
          fields: errorTitles,
        });
        setTimeout(() => {
          setRequiredFieldsMessage({
            show: false,
            fields: [],
          });
        }, 3000);
      }
    }
  }, [accordion, selectedId, visibleAccordion, errors, getValues]);

  const handleBack = useCallback(() => {
    // const index = accordion.findIndex((item) => item.id === selectedId);
    if (selectedId > 0) {
      setSelectedId(selectedId - 1);
    }
  }, [accordion, selectedId]);

  const timer = useRef(null);
  const initialHeight = useRef(0);
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      !(
        updateType === UpdateType.situational &&
        selectedId === 0 &&
        clientHeight > 29
      )
    )
      return;

    if (initialHeight.current <= 29) {
      initialHeight.current = clientHeight;
    }

    clearTimeout(timer.current);
    if (
      scrollTop + (initialHeight.current + 29) >= scrollHeight ||
      !hasScroll.current
    ) {
      setShowRest(true);
    } else {
      setShowRest(false);
    }
  };

  const isNextActive = useMemo(
    () =>
      selectedId !== visibleAccordion.length - 1 &&
      !(EditType.create === editType && addedPoints.length === 0),
    [accordion, selectedId, visibleAccordion, addedPoints, editType]
  );

  const isLast = useMemo(
    () => selectedId === visibleAccordion.length - 1,
    [selectedId, visibleAccordion]
  );

  const isPrevActive = useMemo(() => {
    const firstIndex = 0;
    return selectedId !== firstIndex;
  }, [selectedId, editableLayer, editType]);

  const handleSave = () => {
    const isIsoMatching = makeIsoCheck();

    if (!isIsoMatching) {
      setPendingAction("save");
      setShowIsoMessage(true);
      return;
    }

    try {
      const current = visibleAccordion[selectedId];
      const showPublic = getValues(showpublicFieldName);

      if (
        current?.id !== "coordinates" &&
        String(showPublic) === "2" &&
        !confirmShowPublic.current
      ) {
        setPendingAction("save");
        setMessage({
          show: true,
          title: t(
            "screen.widget.Editor.manager.messages.showPublicMessage.title",
            {
              1: t(
                t(
                  "layer.fieldAlias." + showpublicFieldName + ".title",
                  "Show on Public Map"
                )
              ),
            }
          ),
          subtitle: t(
            "screen.widget.Editor.manager.messages.showPublicMessage.subtitle"
          ),
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }

    trigger().then((isValid) => {
      if (isValid) {
        handleSubmit();
      } else {
        const errorTitles = getErrorTitles(errors, t);

        setRequiredFieldsMessage({
          show: true,
          fields: errorTitles,
        });
        setTimeout(() => {
          setRequiredFieldsMessage({
            show: false,
            fields: [],
          });
        }, 3000);
      }
    });
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {showIsoMessage && (
          <Message
            title={t(
              "screen.widget.Editor.manager.messages.isoMessage.inputChange"
            )}
            subtitle={t(
              "screen.widget.Editor.manager.messages.isoMessage.subtitle"
            )}
            onCancel={() => {
              setShowIsoMessage(false);
              setPendingAction("");
            }}
            onSubmit={() => {
              setShowIsoMessage(false);
              isIsoMatching.current = true;
              confirmedIsoMatch.current = true;
              setPendingAction("");
              if (pendingAction === "next") {
                handleNext();
              } else if (pendingAction === "save") {
                handleSave();
              }
            }}
          />
        )}
        {message.show && (
          <Message
            title={message.title}
            subtitle={message.subtitle}
            onCancel={() => {
              setMessage({
                show: false,
                title: "",
                subtitle: "",
              });
              setPendingAction("");
            }}
            onSubmit={() => {
              confirmShowPublic.current = true;
              if (pendingAction === "next") {
                handleNext();
              } else if (pendingAction === "save") {
                handleSave();
              }

              setMessage({
                show: false,
                title: "",
                subtitle: "",
              });
              setPendingAction("");
            }}
          />
        )}
        {accordion.map((item, _) => {
          const index = visibleAccordion.findIndex(
            (vItem) => vItem.id === item.id
          );

          const isSelected = index === selectedId;
          const hideComponent =
            selectedId < index &&
            updateType === UpdateType.baseline &&
            item.show;
          //when editing baseline and adding we use arrows and next button to navigate
          // if (
          //   selectedId < index &&
          //   updateType === UpdateType.baseline &&
          //   item.show
          // ) {
          //   return null;
          // }

          //when updating situational we show baseline and location options when user scrolls down
          let styles = {};
          if (
            index !== 0 &&
            selectedId === 0 &&
            !showRest &&
            updateType === UpdateType.situational
          ) {
            styles = {
              opacity: 0,
              minHeight: 0,
              height: 0,
            };
          }

          return (
            <AccordionWrapper
              ref={
                updateType === UpdateType.situational &&
                editType === EditType.edit &&
                index === 0
                  ? accordionWrapperRef
                  : null
              }
              key={item.id}
              id={item.id}
              selected={isSelected}
              show={item.show}
              onScroll={handleScroll}
              style={styles}
            >
              <>
                <AccordionButton
                  selected={isSelected || visibleAccordion.length === 1}
                  label={item.label}
                  config={config}
                  onSelect={
                    visibleAccordion.length > 1
                      ? () => handleAccordionSelection(item)
                      : undefined
                  }
                  information={item.information}
                  color={item.color ?? activeColor}
                />
                <AccordionBody
                  hasPadding={item.id !== "coordinates"}
                  selected={isSelected}
                >
                  {!hideComponent &&
                    renderAccordionComponent(item.id, index === selectedId)}
                </AccordionBody>
              </>
            </AccordionWrapper>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "auto",
          padding: 8,
          minHeight: 50,
          boxSizing: "border-box",
          gap: 0,
          borderTop: "1px solid #eee",
        }}
      >
        {UpdateType.baseline === updateType && (
          <>
            <ActionButton
              onClick={handleBack}
              isActive
              color={activeColor}
              isActive={isPrevActive}
              Icon={ArrowUpIcon}
              styles={{
                borderRadius: "0px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
                padding: "0px 4px",
                minWidth: 24,
                height: 24,
              }}
            >
              {/* <ArrowUpIcon
                style={{ transform: "rotate(180deg)" }}
                fill={activeColor}
                width="16"
                heigh="16"
              /> */}
            </ActionButton>
            <ActionButton
              onClick={handleNext}
              isActive={isNextActive}
              color={activeColor}
              Icon={ArrowDownIcon}
              styles={{
                borderColor: "none",
                borderRadius: "0px",
                borderTopColor: isNextActive ? activeColor : "",
                borderBottomColor: isNextActive ? activeColor : "",
                borderRightColor: isNextActive ? activeColor : "",
                borderLeftColor: isPrevActive
                  ? "transparent"
                  : isNextActive
                  ? activeColor
                  : "transparent",
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
                padding: "0px 4px",
                minWidth: 24,
                height: 24,
              }}
            >
              {/* <ArrowDownIcon width="16" heigh="16" fill={activeColor} /> */}
            </ActionButton>
          </>
        )}
        {requiredFieldsMessage.show && (
          <Message
            title={t("cycleManager.saveMessages.missingFields", { 1: "" })}
            subtitle={
              <span
                style={{ fontWeight: 400, fontSize: "inherit", color: "red" }}
              >
                {requiredFieldsMessage.fields.join(", ")}
              </span>
            }
          />
        )}
        <div
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <DeleteButton
            editType={editType}
            updateType={updateType}
            editableLayer={editableLayer}
            getIsDeletedField={getIsDeletedField}
            activeColor={activeColor}
            isActive
            doDelete={doDelete}
          />
          <SaveAndConfirmButton
            editableLayer={editableLayer}
            updateType={updateType}
            activeColor={activeColor}
            onClick={handleSave}
            isActive={UpdateType.baseline === updateType ? isLast : true}
            addedPoints={addedPoints}
            showIcon={hasEditedAttachments}
            showSave
          />
        </div>

        {UpdateType.baseline === updateType && (
          <ActionButton
            onClick={handleNext}
            isActive={isNextActive}
            color={activeColor}
            styles={{
              padding: "0px 20px",
              height: 24,
              opacity: !isLast ? 1 : 0,
            }}
          >
            <span>{t("screen.popup.actions.next")}</span>
          </ActionButton>
        )}
      </div>
    </>
  );
};

export default EditableFields;
