import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
} from "../../../../../../utils/API";
import { ConfigContext } from "../../../../../../utils/ConfigContext";
import Comment from "../../../../../Report/new/Comment/Comment";
import Input from "../../../../../Report/new/Input/Input";

const InputField = ({
  field,
  label,
  isRequired,
  borderColor,
  defaultValue,
  onInputBlur,
  ...props
}) => {
  const { register, getValues, setValue, formState } = useFormContext();
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  // const { errors } = formState;
  const value = useWatch({ name: field.name }) || "";

  useEffect(() => {
    const fieldValue = getValues(field.name);

    if (!fieldValue) {
      setValue(field.name, defaultValue || "", { shouldValidate: false });
    } else {
      setValue(field.name, fieldValue), { shouldValidate: false };
    }
  }, [defaultValue]);

  const filledInColor = useMemo(
    () => borderColor, //(isRequired ? "#16a34a" : borderColor),
    [isRequired]
  );

  const errorMessage =
    formState.errors[field.name]?.type === "required" ||
    formState.errors[field.name]?.type === "validate"
      ? t("screen.widget.common.fieldIsRequired")
      : "";

  const combinedRegister = useCallback(
    (name, options) => {
      const { onBlur, ...rest } = register(name, options);
      return {
        onBlur: (e) => {
          onBlur(e);
          if (onInputBlur) {
            onInputBlur(e, name);
          }
        },
        ...rest,
      };
    },
    [register, onInputBlur]
  );

  return field.length > 60 ? (
    <Comment
      // id="editorFields"
      data-field-name={field.name}
      key={"i" + field.name}
      isRequired={isRequired}
      register={combinedRegister(field.name, {
        required: isRequired,
      })}
      borderColor={filledInColor}
      focusColor={config.opsColor}
      defaultValue={defaultValue}
      noMargin
      title={label}
      error={errorMessage}
      height="100%"
      resizable
      maxHeight="300px"
      data-alias={field.alias}
      {...props}
    />
  ) : (
    <Input
      // id="editorFields"
      data-field-name={field.name}
      key={"i" + field.name}
      register={combinedRegister(field.name, {
        required: isRequired,
      })}
      noMargin
      borderColor={filledInColor}
      focusColor={config.opsColor}
      defaultValue={defaultValue}
      label={label}
      isRequired={isRequired}
      errorMessage={errorMessage}
      data-alias={field.alias}
      inputTooltipText={
        props.disabled &&
        (field.name === currAsOfDateFieldName ||
          field.name === asOfDateFieldName)
          ? t("screen.widget.common.fieldDisabled")
          : ""
      }
      {...props}
    />
  );
};

export default InputField;
