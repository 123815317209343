import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { BaselineIcon } from "../../../../Icons";
import LayerIcon from "../../../../Icons/LayerIcon";
import { UpdateType } from "../../EditorContextProvider";
import { EditType } from "../../EditorSwiper/EditorSwiper";
import { getFeatureNameField } from "../../helpers";
import { PlatformIconHolder } from "../../Platform/Platform-styled";

const LayerHeader = ({
  editableLayer,
  editType,
  updateType,
  t,
  config,
  activeColor,
  batchUpdateFeatures,
}) => {
  const nameField = useMemo(
    () => getFeatureNameField(editableLayer),
    [editableLayer]
  );

  const value = useWatch({ name: nameField }) || null;

  const progressTitle = useMemo(() => {
    if (editType === EditType.create) {
      return t("screen.widget.Editor.manager.header.adding");
    } else {
      if (updateType === UpdateType.baseline) {
        if (editableLayer) {
          return t("screen.widget.Editor.manager.header.editing");
        } else {
          return t("screen.widget.Editor.manager.baseline.workOnDataset");
        }
      } else if (updateType === UpdateType.situational) {
        return t("screen.widget.Editor.manager.header.updating");
      }
    }
  }, [updateType, editType, editableLayer]);

  const title = useMemo(() => {
    return editableLayer ? (
      <p style={{ width: "auto", fontSize: 14 }}>
        {value != null && typeof value === "string" ? (
          value
        ) : (
          <>
            {editableLayer.getLayerTitle(t)}{" "}
            {batchUpdateFeatures.length > 0
              ? `(${batchUpdateFeatures.length})`
              : ""}
          </>
        )}
      </p>
    ) : (
      <p style={{ width: "auto", fontSize: 14 }}>{progressTitle}</p>
    );
  }, [editableLayer, value, batchUpdateFeatures, t, progressTitle]);

  if (editableLayer) {
    return (
      <>
        <LayerIcon
          t={t}
          layer={editableLayer}
          config={config}
          width={34}
          height={34}
          opsColor={activeColor}
          textColor="#fff"
          selected
          wrapperStyles={{
            background: activeColor,
            borderRadius: 100,
            overflow: "hidden",
          }}
          textStyles={{
            background: activeColor,
            borderRadius: 100,
            height: 34,
            width: 34,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 4,
          }}
        >
          <p style={{ width: "auto", fontSize: 16, textAlign: "left" }}>
            {value != null && typeof value === "string" ? (
              value
            ) : (
              <>
                {editableLayer.getLayerTitle(t)}{" "}
                {batchUpdateFeatures.length > 0
                  ? `(${batchUpdateFeatures.length})`
                  : ""}
              </>
            )}
          </p>
        </div>
      </>
    );
  } else {
    return (
      <>
        <PlatformIconHolder color="#34495e">
          <BaselineIcon />
        </PlatformIconHolder>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 4,
          }}
        >
          <p style={{ width: "auto", fontSize: 16, textAlign: "left" }}>
            {progressTitle}
          </p>
        </div>
      </>
    );
  }
};

export default LayerHeader;
