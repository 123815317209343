import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CardBody,
  CardHeader,
  Footer,
  InformationCard,
  Tag,
} from "./SharedInformationCard-styled";
import {
  UpdateDate,
  UpdatesTitle,
} from "../../LatestUpdates/new/LatestUpdates-styled";
import {
  clickEventOnFeature,
  renderCell,
  timeAgo,
} from "../../../utils/helper";
import { currAsOfDateFieldName, view } from "../../../utils/API";
import getAllowedFields from "../../CustomPopupWrapper/helpers/getAllowedFields";
import {
  generateTitle,
  getFeatureTitle,
  getLayerTitle,
  getTitleFromTitleTemplate,
} from "../../../esri/custom-popup-content";
import useClickOutside from "../../../hooks/useClickOutside";
import { createSymbol, getLayerSymbology } from "../../../utils/symbologies";
import { useTranslation } from "react-i18next";
import { getFeatureIcon } from "../../Dashboard/Editor/EditorFields/EditorFields";
import PopupField from "../../MobileApp/MobilePopup/PopupBody/FieldsSection/PopupField";
import { LineSymbol } from "../../ReportManager/symbols";
import ReactDOMServer from "react-dom/server";

const SharedInformationCard = ({
  feature,
  t,
  config,
  statusField,
  template,
  onClick,
  selected,
  children,
  handleCancel,
  style,
  showLayerTitle = true,
  visibleFields = [],
  mobileStyles = false,
}) => {
  const [iconUrl, setIconUrl] = useState("");
  const [symbolSvg, setSymbolSvg] = useState("");
  const [expand, setExpand] = useState(false);
  const [title, setTitle] = useState();
  const ref = useRef();
  const { i18n } = useTranslation("common");

  useClickOutside(ref, (e) => {
    if (expand) {
      setExpand(false);
    }

    if (selected && handleCancel) {
      handleCancel(e);
    }
  });

  useEffect(() => {
    const setCardIcon = (symbol) => {
      if (symbol.url) {
        setIconUrl(symbol.url);
      } else if (symbol.type === "simple-line" && symbol.color) {
        const color = symbol.color ? symbol.color.toHex() : "grey";
        setIconUrl(
          "data:image/svg+xml;base64," +
            btoa(
              ReactDOMServer.renderToStaticMarkup(
                <LineSymbol stroke={color} dash={symbol.style === "dash"} />
              )
            )
        );
      }
    };

    const { reportedlayerid, reportedfeatureid } = feature.attributes;
    const layer = !!reportedlayerid
      ? view.map.layers.find(
          (l) =>
            l.layerConfig &&
            l.visible &&
            (l.layerConfig.viewer === reportedlayerid ||
              l.layerConfig.id === reportedlayerid)
        )
      : undefined;

    if (layer) {
      const query = layer.createQuery();
      const objectIdField = layer.objectIdField;
      query.where = `${objectIdField} = ${reportedfeatureid}`;
      layer.queryFeatures(query).then((res) => {
        const features = res?.features;
        if (features.length > 0) {
          const reportedFeature = features[0];

          setTitle(getTitleFromTitleTemplate(reportedFeature, t));

          layer?.renderer?.getSymbolAsync(reportedFeature).then((symbol) => {
            setCardIcon(symbol);
          });
        }
      });
    } else {
      const layer = feature.sourceLayer || feature.layer;

      let title = generateTitle(template, feature, layer, t) || "";

      if (!title.trim()) {
        title = getFeatureTitle(feature, t) || getLayerTitle(layer, t);
      }

      setTitle(title);
      try {
        const symbology = getLayerSymbology(layer, config);
        const colorMap = symbology.colorMap;
        const colors = colorMap?.default;
        if (Array.isArray(colorMap.fields) && colorMap.fields.length > 0) {
          const colorSymbologyValue =
            feature.attributes[colorMap.fields[0]] === null
              ? "NULL"
              : feature.attributes[colorMap.fields[0]];
          const color = colors[colorSymbologyValue] ?? symbology.defaultColor;
          let iconName = getFeatureIcon(feature, symbology);
          const symbol = createSymbol(
            symbology,
            layer,
            iconName,
            color,
            symbology
          );
          setCardIcon(symbol);
        }
      } catch (err) {
        layer?.renderer?.getSymbolAsync(feature).then((symbol) => {
          if (iconUrl) return;
          setCardIcon(symbol);
        });
      }
    }
  }, [feature]);

  const format = (value) => {
    return timeAgo(new Date(value), i18n.language, false);
  };

  const openPopup = (e) => {
    if (onClick) {
      onClick(feature, e);
    } else {
      clickEventOnFeature(feature, { zoom: false, duration: 500 });
    }
  };

  const allowedFields = useMemo(() => {
    const layer = feature.sourceLayer || feature.layer;
    const layerConfig = layer.layerConfig || {};
    const layerSettings = layerConfig.customPopupOps;
    if (layerSettings) {
      const overviewTable1Fields = layerSettings.overviewTable1Fields;

      const fields = getAllowedFields(layer, feature).filter(
        (field) => overviewTable1Fields.includes(field.name) && !!field
      );
      return fields;
    }
    return [];
  }, [feature]);

  const symbologyField = useMemo(() => {
    let field = statusField;
    let value = "";
    const symbologyLayer = feature.sourceLayer || feature.layer;
    const symbology = getLayerSymbology(symbologyLayer, config);
    if (symbology) {
      const colorMap = symbology?.colorMap || {};
      if (Array.isArray(colorMap.fields) && colorMap.fields.length > 0) {
        field = colorMap.fields[0];
      }
    }

    const featureField = feature.layer.fields.find((f) => f.name === field);
    if (featureField && field) {
      const cellValue = renderCell(
        featureField,
        feature.attributes[field],
        t,
        config
      );
      if (String(cellValue).trim()) {
        value = cellValue;
      }
    }

    return {
      field,
      value,
      title: t(
        "layer.fieldAlias." + featureField?.name + ".title",
        featureField?.alias
      ),
    };
  }, [feature, statusField]);

  const firstVisibleField = useMemo(() => {
    let field = allowedFields.find((f) => f.name === "currcustomsremarken");
    if (!field) {
      field = allowedFields[0];
    }

    if (symbologyField?.field === field?.name) return null;

    const stringValue =
      allowedFields.length > 0
        ? String(renderCell(field, feature.attributes[field.name], t, config))
        : "";

    if (!!stringValue.length) {
      return {
        title: t("layer.fieldAlias." + field.name + ".title", field.alias),
        value: stringValue,
        fieldName: field.name,
      };
    }

    return null;
  }, [allowedFields, t, feature, config, symbologyField]);

  const editFieldName = useMemo(() => {
    const layer = feature.layer;
    if (
      layer.fields.some((f) => f.name === currAsOfDateFieldName) &&
      feature.attributes[currAsOfDateFieldName]
    ) {
      return currAsOfDateFieldName;
    }

    return layer?.editFieldsInfo?.editDateField;
  }, [feature]);

  const featureVisibleFields = useMemo(() => {
    const fieldsWithValue = [];
    visibleFields.map((field) => {
      if (
        feature.attributes[field.name] === null ||
        feature.attributes[field.name] === undefined ||
        field.name === symbologyField?.field ||
        editFieldName === field.name
      )
        return;

      const value = renderCell(
        field,
        feature.attributes[field.name],
        t,
        config
      );
      const stringValue = value ? String(value) ?? "" : "";

      if (!!stringValue.length) {
        fieldsWithValue.push({
          title: t("layer.fieldAlias." + field.name + ".title", field.alias),
          value: stringValue,
          fieldName: field.name,
        });
      }
    });

    return fieldsWithValue;
  }, [visibleFields, symbologyField, editFieldName]);

  const hiddenFieldsArray = useMemo(() => {
    if (!allowedFields.length) return [];

    return allowedFields
      .slice(1)
      .filter(
        (field) =>
          !!String(renderCell(field, feature.attributes[field.name], t, config))
            .length
      )
      .map((field) => {
        return {
          title: t("layer.fieldAlias." + field.name + ".title", field.alias),
          value: renderCell(field, feature.attributes[field.name], t, config),
        };
      });
  }, [allowedFields, feature, t, config]);

  const symbologyColors = useMemo(() => {
    const symbologyLayer = feature.sourceLayer || feature.layer;
    const symbology = getLayerSymbology(symbologyLayer, config);

    if (symbology) {
      const colorMap = symbology?.colorMap || {};
      const colors = colorMap.default ? { ...colorMap.default } : {};
      if (symbology.defaultColor) {
        colors.defaultColor = symbology.defaultColor;
      }

      return colors;
    }

    return {};
  }, [feature]);

  const showFooter = useMemo(() => {
    return feature.attachments?.length > 0 || hiddenFieldsArray.length > 0;
  }, [feature, hiddenFieldsArray]);

  return (
    <InformationCard
      ref={ref}
      style={style}
      onClick={openPopup}
      selected={selected}
    >
      <CardHeader>
        <div
          style={{
            position: "absolute",
            left: "100%",
            top: "-14px",
            transform: "translateX(-100%)",
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
        ></div>
      </CardHeader>
      <CardBody
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: mobileStyles ? 4 : 0,
            }}
          >
            <UpdatesTitle style={{ fontSize: mobileStyles ? 18 : undefined }}>
              {title}
            </UpdatesTitle>
            <UpdateDate
              style={{
                columnGap: 4,
                display: "flex",
                alignItems: "center",
                fontSize: 11,
                flexWrap: "wrap",
              }}
            >
              <Tag
                bg={symbologyColors[feature.attributes[symbologyField.field]]}
                status={feature.attributes[symbologyField.field]}
              >
                {symbologyField.value}
              </Tag>
              {editFieldName && feature.attributes[editFieldName]
                ? format(feature.attributes[editFieldName])
                : ""}
            </UpdateDate>
            {showLayerTitle && (
              <UpdateDate
                style={{
                  gap: 4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {getLayerTitle(feature.layer, t)}
              </UpdateDate>
            )}
          </div>
          {iconUrl && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: 38,
                minWidth: 38,
                maxHeight: 38,
                position: "relative",
              }}
            >
              <img
                style={{ width: "100%", height: "auto" }}
                alt="feature-symbol"
                src={iconUrl}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 2,
          }}
        >
          {!!firstVisibleField && featureVisibleFields.length === 0 && (
            <PopupField
              value={firstVisibleField.value}
              field={firstVisibleField}
              feature={feature}
              t={t}
              i18n={i18n}
              style={{
                fontWeight: 400,
                color: "#393738",
                fontSize: 12,
                fontStyle: "normal",
              }}
              isPopup={true}
            />
          )}
          {featureVisibleFields.map((option) => (
            <PopupField
              value={option.value}
              field={option}
              title={option.title}
              feature={feature}
              t={t}
              i18n={i18n}
              isPopup={true}
            />
          ))}
        </div>
        {children && <Footer show={selected}>{children}</Footer>}
        {/*{showFooter && (*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      display:'flex',*/}
        {/*      width:'100%',*/}
        {/*      justifyContent:'center',*/}
        {/*      alignItems:'center',*/}
        {/*      position:'relative',*/}
        {/*      height:18*/}
        {/*    }}>*/}
        {/*    /!*{hiddenFieldsArray.length > 0 && <button*!/*/}
        {/*    /!*  style={{*!/*/}
        {/*    /!*    cursor: "pointer",*!/*/}
        {/*    /!*    border: "none",*!/*/}
        {/*    /!*    background: "none",*!/*/}
        {/*    /!*    display: "flex",*!/*/}
        {/*    /!*    alignItems: "center",*!/*/}
        {/*    /!*    position: "absolute",*!/*/}
        {/*    /!*  }}*!/*/}
        {/*    /!*  onClick={(e) => {*!/*/}
        {/*    /!*    e.stopPropagation();*!/*/}
        {/*    /!*    setExpand(prev => !prev);*!/*/}
        {/*    /!*  }}>*!/*/}
        {/*    /!*  <svg*!/*/}
        {/*    /!*    style={{*!/*/}
        {/*    /!*      transform: `rotate(${expand ? "180deg" : "0deg"})`,*!/*/}
        {/*    /!*      transition: "all 0.3s"*!/*/}
        {/*    /!*    }}*!/*/}
        {/*    /!*    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black">*!/*/}
        {/*    /!*    <path d={chevronDown16}/>*!/*/}
        {/*    /!*  </svg>*!/*/}
        {/*    /!*</button>}*!/*/}
        {/*    /!*{feature.attachments?.length > 0 && (*!/*/}
        {/*    /!*  <div style={{*!/*/}
        {/*    /!*    display:'flex',*!/*/}
        {/*    /!*    alignItems:'center',*!/*/}
        {/*    /!*    gap: 4,*!/*/}
        {/*    /!*    marginLeft:'auto'*!/*/}
        {/*    /!*  }}>*!/*/}
        {/*    /!*    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="20" viewBox="0 0 12 23" fill="none">*!/*/}
        {/*    /!*      <path*!/*/}
        {/*    /!*        d="M10 5.5V17C10 19.21 8.21 21 6 21C3.79 21 2 19.21 2 17V4.5C2 3.12 3.12 2 4.5 2C5.88 2 7 3.12 7 4.5V15C7 15.55 6.55 16 6 16C5.45 16 5 15.55 5 15V5.5H3.5V15C3.5 16.38 4.62 17.5 6 17.5C7.38 17.5 8.5 16.38 8.5 15V4.5C8.5 2.29 6.71 0.5 4.5 0.5C2.29 0.5 0.5 2.29 0.5 4.5V17C0.5 20.04 2.96 22.5 6 22.5C9.04 22.5 11.5 20.04 11.5 17V5.5H10Z"*!/*/}
        {/*    /!*        fill="#1D2D35"/>*!/*/}
        {/*    /!*    </svg>*!/*/}
        {/*    /!*    <span style={{fontSize: 12}}>{feature.attachments?.length}</span>*!/*/}
        {/*    /!*  </div>*!/*/}
        {/*    /!*)}*!/*/}
        {/*  </div>*/}
        {/*)}*/}
      </CardBody>
    </InformationCard>
  );
};

export default SharedInformationCard;
