import styled, { css } from "styled-components";

const arrowStyles = {
  top: css`
    &:before {
      bottom: -7px;
      left: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateX(-50%);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
    }
    &:after {
      bottom: -6px;
      left: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateX(-50%);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #ffffff;
      filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.1));
    }
  `,
  bottom: css`
    &:before {
      top: -7px;
      left: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateX(-50%);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1));
    }
    &:after {
      top: -6px;
      left: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateX(-50%);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #ffffff;
      filter: drop-shadow(0 -2px 1px rgba(0, 0, 0, 0.1));
    }
  `,
  left: css`
    &:before {
      right: -7px;
      top: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateY(-50%);
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid rgba(0, 0, 0, 0.1);
      filter: drop-shadow(2px 0 2px rgba(0, 0, 0, 0.1));
    }
    &:after {
      right: -6px;
      top: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateY(-50%);
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #ffffff;
      filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.1));
    }
  `,
  right: css`
    &:before {
      left: -7px;
      top: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateY(-50%);
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid rgba(0, 0, 0, 0.1);
      filter: drop-shadow(-2px 0 2px rgba(0, 0, 0, 0.1));
    }
    &:after {
      left: -6px;
      top: ${(props) => `calc(${props.arrowOffset}px)`};
      transform: translateY(-50%);
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid #ffffff;
      filter: drop-shadow(-2px 0 1px rgba(0, 0, 0, 0.1));
    }
  `,
};

export const TooltipContainer = styled.div`
  position: fixed;
  padding: 4px 8px;
  background: #ffffff;
  color: #333;
  border-radius: 4px;
  font-size: 10px;
  z-index: 1000;
  min-width: 10px;
  max-width: 250px;
  word-wrap: break-word;
  pointer-events: none;
  line-height: 16px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? "all" : "none")};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
  }

  ${({ placement }) => arrowStyles[placement]}
`;

export const TriggerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "inherit")};
`;
