import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  span {
    font-size: 10px;
    font-weight: 400;
    color: #6c6b6b;
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  min-width: 12px;
`;
