import { loadModules } from "esri-loader";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useORSReverseSearch from "../../../../../../../hooks/Search/useORSReverseSearch";
import useCustomSnackbar from "../../../../../../../hooks/useCustomSnackbar";
import { graphicsLayer, view } from "../../../../../../../utils/API";
import { ConfigContext } from "../../../../../../../utils/ConfigContext";
import ActionButton from "../../../../../CycleManager/Checklist/ChecklistTable/ActionButton";
import {
  concatRouteAttributes,
  processIntersectingFeatures,
} from "../../../../helpers";
import Message from "../../../../Message";
import { selectionLineSymbol } from "../../../RoadsCoordinates";
import { divideRoutesByCountries, generateOrsComment } from "./helpers";

const NextButton = ({
  editableLayer,
  setLoading,
  setTrimmedSituationalFeatures,
  setAddedPoints,
  handleNext,
  addedPoints,
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const { reset } = useFormContext();
  const [openSnackbar] = useCustomSnackbar();
  const { fetchReverseGeocode } = useORSReverseSearch();

  useEffect(() => {
    let handler, timer;
    clearTimeout(timer);
    if (!graphicsLayer) return;
    view
      .whenLayerView(graphicsLayer)
      .then((lv) => {
        handler = lv.watch("updating", (updating) => {
          if (!updating) {
            clearTimeout(timer);
            timer = setTimeout(() => {
              const hasAnySegment = graphicsLayer.graphics.some(
                (g) => g.id && g.id.includes("route")
              );
              setIsActive(hasAnySegment);
            }, 100);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      if (handler) {
        handler.remove();
      }
      clearTimeout(timer);
    };
  }, []);

  const assignOrsRoutesIsoCodes = useCallback(
    async (routeGraphics) => {
      const [geometryEngineAsync] = await loadModules([
        "esri/geometry/geometryEngineAsync",
      ]);

      const newRouteGraphics = [...routeGraphics];

      try {
        const promises = [];
        newRouteGraphics.map(async (g) => {
          const path = g.geometry.paths[0];
          const middleIndex = Math.floor(path.length / 2);
          const [longitude, latitude] = path[middleIndex];

          promises.push(fetchReverseGeocode({ latitude, longitude }));
        });

        const results = await Promise.allSettled(promises);
        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            const { features } = result.value || {};

            if (features && features.length > 0) {
              const feature = features[0];
              const iso3 = feature.properties?.country_a;

              const graphic = newRouteGraphics[index];

              let origin, destination;
              if (Array.isArray(graphic.cities)) {
                if (graphic.cities.length > 0) {
                  const firstCity = graphic.cities[0];
                  if (firstCity.iso3 === iso3) {
                    origin = firstCity.name;
                  }
                }

                if (graphic.cities.length > 1) {
                  const lastCity = graphic.cities[graphic.cities.length - 1];
                  if (lastCity.iso3 == iso3) {
                    destination = lastCity.name;
                  }
                }
              }

              graphic.attributes = {
                ...graphic.attributes,
                iso3: iso3,
                location_origin: origin,
                location_destination: destination,
              };
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
      return newRouteGraphics;
    },
    [fetchReverseGeocode]
  );

  const goNext = useCallback(
    async (combinedRoute) => {
      try {
        const [geometryEngineAsync, Graphic, webMercatorUtils] =
          await loadModules([
            "esri/geometry/geometryEngineAsync",
            "esri/Graphic",
            "esri/geometry/support/webMercatorUtils",
          ]);
        const routeGraphics = [];
        const orsRouteGraphics = [];
        const pointGraphics = [];

        graphicsLayer.graphics.forEach((g) => {
          if (!g.id) return;
          if (g.id.includes("route")) {
            if (g.orsFeature) {
              orsRouteGraphics.push(g);
            } else {
              routeGraphics.push(g);
            }
          }

          if (!g.id.includes("route") && g.id.includes("point")) {
            pointGraphics.push(g);
          }
        });
        let distance_km = 0;
        const isoCodeComment = {};
        const isoCodeMap = {};
        const isoCodeLocations = {};

        if (routeGraphics.length > 0) {
          routeGraphics.forEach((g) => {
            const iso3 = g.attributes.iso3;
            if (g.attributes.iso3) {
              if (isoCodeMap[iso3]) {
                isoCodeMap[iso3].features.push(g);
              } else {
                isoCodeMap[iso3] = {
                  distance_km: 0,
                  features: [g],
                  commentgenen: "",
                };
              }
            }
          });

          const geometries = routeGraphics.map((g) => g.geometry);
          const combinedGeometries = await geometryEngineAsync.union(
            geometries
          );
          const routeLength = await geometryEngineAsync.geodesicLength(
            combinedGeometries,
            "kilometers"
          );
          distance_km += routeLength;
        }

        if (orsRouteGraphics.length > 0) {
          const countryRouteGraphics = await divideRoutesByCountries(
            orsRouteGraphics
          );

          const countryGraphicsWithIsoCode = await assignOrsRoutesIsoCodes(
            countryRouteGraphics
          );

          const isoCodeExtras = {};
          countryGraphicsWithIsoCode.forEach((g) => {
            const iso3 = g.attributes.iso3;
            if (!isoCodeLocations[iso3]) {
              isoCodeLocations[iso3] = {
                origin: g.attributes.location_origin,
                destination: g.attributes.location_destination,
              };
            }

            if (!isoCodeExtras[iso3]) {
              isoCodeExtras[iso3] = {};
            }
            Object.keys(g.extrasInfo).forEach((key) => {
              if (isoCodeExtras[iso3][key]) {
                Object.keys(g.extrasInfo[key]).forEach((extraValue) => {
                  const value = g.extrasInfo[key][extraValue];
                  if (isoCodeExtras[iso3][key][extraValue]) {
                    isoCodeExtras[iso3][key][extraValue] += value;
                  } else {
                    isoCodeExtras[iso3][key][extraValue] = value;
                  }
                });
              } else {
                isoCodeExtras[iso3][key] = g.extrasInfo[key];
              }
            });
          });

          Object.keys(isoCodeExtras).forEach((iso3) => {
            const extrasInfo = isoCodeExtras[iso3];
            const commentgenen = generateOrsComment({
              editableLayer,
              extrasInfo,
              t,
            });
            isoCodeComment[iso3] = commentgenen;
          });

          countryGraphicsWithIsoCode.forEach((g) => {
            const iso3 = g.attributes.iso3;
            if (iso3) {
              if (isoCodeMap[iso3]) {
                isoCodeMap[iso3].features.push(g);
              } else {
                isoCodeMap[iso3] = {
                  distance_km: 0,
                  features: [g],
                };
              }
            }
          });
        }

        // const { location_origin, location_destination } =
        //   getCities(routeGraphics);

        graphicsLayer.removeAll();
        for (const iso3 in isoCodeMap) {
          const { features } = isoCodeMap[iso3];

          const geometries = features.map((g) => {
            if (!g.geometry.spatialReference.isGeographic) {
              return webMercatorUtils.webMercatorToGeographic(g.geometry);
            }
            return g.geometry;
          });

          const combinedGeometries = await geometryEngineAsync.union(
            geometries
          );
          const routeLength = await geometryEngineAsync.geodesicLength(
            combinedGeometries,
            "kilometers"
          );

          const distance_km = routeLength;
          const roundedKm = Math.round(distance_km * 100) / 100;
          const commentgenen = isoCodeComment[iso3];
          const locations = isoCodeLocations[iso3] || {};

          const attributes = concatRouteAttributes({
            features: features,
            editableLayer,
          });

          const routeGraphic = new Graphic({
            id: "route-combined",
            geometry: combinedGeometries,
            symbol: selectionLineSymbol,
            attributes: {
              ...(attributes || {}),
              distance_km: roundedKm,
              location_origin: locations.origin,
              location_destination: locations.destination,
              commentgenen,
              iso3,
            },
          });
          graphicsLayer.add(routeGraphic);
        }

        handleNext();
      } catch (err) {
        console.log(err);
        openSnackbar(t("screen.message.error"));
      }
    },
    [handleNext, assignOrsRoutesIsoCodes, t, openSnackbar, editableLayer]
  );

  const handleButtonClick = useCallback(async () => {
    setLoading(true);
    try {
      if (!graphicsLayer) return;
      const [webMercatorUtils, geometryEngineAsync] = await loadModules([
        "esri/geometry/support/webMercatorUtils",
        "esri/geometry/geometryEngineAsync",
      ]);
      const routeGraphicsGeometry = [];
      const routeGraphics = [];

      graphicsLayer.graphics.forEach((g) => {
        if (!g.id) return;

        if (g.id.includes("route")) {
          let geometry = g.geometry;
          if (!g.geometry.spatialReference.isGeographic) {
            geometry = webMercatorUtils.webMercatorToGeographic(g.geometry);
          }

          routeGraphicsGeometry.push(geometry);
          routeGraphics.push(g);
        }
      });

      if (routeGraphicsGeometry.length === 0) return;
      let combinedRoute = await geometryEngineAsync.union(
        routeGraphicsGeometry
      );

      if (!combinedRoute.spatialReference.isGeographic) {
        combinedRoute = webMercatorUtils.webMercatorToGeographic(combinedRoute);
      }

      const roadsSituationalRes = await editableLayer.queryFeatures({
        where: editableLayer.definitionExpression,
        geometry: combinedRoute,
        spatialRelationship: "intersects",
        returnGeometry: true,
        outFields: [editableLayer.objectIdField],
      });

      const selection = await geometryEngineAsync.geodesicBuffer(
        combinedRoute,
        30,
        "meters"
      );

      const { trimmedFeatures, deletedFeatures } =
        await processIntersectingFeatures(
          roadsSituationalRes.features,
          selection,
          {
            geometryEngineAsync,
          }
        );

      setAddedPoints([combinedRoute]);
      reset();
      if (trimmedFeatures.length > 0 || deletedFeatures.length > 0) {
        setTrimmedSituationalFeatures({
          updateFeatures: trimmedFeatures,
          deleteFeatures: deletedFeatures,
        });
        setShowMessage(true);
      } else {
        await goNext(combinedRoute);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [editableLayer, goNext]);

  return (
    <div
      style={{
        borderTop: "1px solid rgb(238, 238, 238)",
        minHeight: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "auto",
      }}
    >
      {showMessage && (
        <Message
          title="This route overlaps existing ones. Continuing will overwrite those sections, possibly shortening or removing them. Proceed?"
          onSubmit={() => {
            setShowMessage(false);
            goNext(addedPoints.length > 0 ? addedPoints[0] : null);
          }}
          onCancel={() => {
            setTrimmedSituationalFeatures({
              updateFeatures: [],
              deleteFeatures: [],
            });
            setAddedPoints([]);
            setShowMessage(false);
          }}
        />
      )}
      <ActionButton
        onClick={handleButtonClick}
        isActive={isActive}
        color={config.opsColor}
        styles={{ padding: "0px 20px" }}
      >
        <span>{t("screen.popup.actions.next")}</span>
      </ActionButton>
    </div>
  );
};

export default NextButton;
