import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useMemo } from "react";
import { StyledChartTooltip } from "../Insights-styled";
import { COLORS } from "../Insights";

const CustomTooltip = ({ active, payload, label, initialData, ...rest }) => {
  if (active && payload && payload.length) {
    if (payload[0].dataKey === "value") {
      return (
        <StyledChartTooltip>
          <span style={{ fontSize: 12, fontWeight: 500 }}>
            {payload[0].payload.name}
          </span>
          <span style={{ fontSize: 12 }}>
            Storage capacity (m2):
            <span style={{ fontSize: 18, fontWeight: 500 }}>
              {payload[0].value}
            </span>
          </span>
        </StyledChartTooltip>
      );
    } else {
      const hscode = payload[0].payload?.hscode || "";

      return (
        <StyledChartTooltip>
          <span style={{ fontSize: 12, fontWeight: 500, marginBottom: 4 }}>
            {payload[0].payload.name}
            {hscode && (
              <span style={{ fontSize: 10, color: "grey" }}> ({hscode})</span>
            )}
          </span>
          {payload.map((item) => {
            const title = Array.isArray(item.payload[`${item.dataKey}-title`])
              ? item.payload[`${item.dataKey}-title`].join(", ")
              : item.payload[`${item.dataKey}-title`];
            const description = Array.isArray(
              item.payload[`${item.dataKey}-description`]
            )
              ? item.payload[`${item.dataKey}-description`].join(", ")
              : item.payload[`${item.dataKey}-description`];
            const value = item.payload[`original-${item.dataKey}`];

            return (
              <span
                key={item.dataKey}
                style={{
                  fontSize: 12,
                  color: item.color === "#E8E8E8" ? "#393738" : item.color,
                }}
              >
                {title}:
                <span style={{ fontSize: 18, fontWeight: 500 }}> {value} </span>
                {description}
              </span>
            );
          })}
        </StyledChartTooltip>
      );
    }
  }

  return null;
};

const RenderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 6;

  const fontSize = props.payload.value.length > 10 ? 10 : 10;

  return (
    <g>
      <text {...props}>
        {props.payload.value.split(" ").map((item) => {
          return (
            <tspan x={props.x} dy="10">
              {item}
            </tspan>
          );
        })}
      </text>
    </g>
  );
};

const CustomBarChart = ({
  config,
  t,
  data,
  mapFilters,
  onClick,
  barValues = [],
  stackIds = {},
  colors = {},
  layout,
  children,
  showX,
  isMobileApp,
  ...props
}) => {
  const selectedIndexes = useMemo(() => {
    if (!data.length) return [];
    const selectedKeyArray = data.filter(
      (item) => mapFilters[item.key]?.length > 0
    );
    let indexes = [];
    if (selectedKeyArray.length === 1) {
      indexes = selectedKeyArray.map((item) =>
        data.findIndex((i) => i.key === item.key)
      );
    } else {
      indexes = selectedKeyArray
        .filter((item) => mapFilters[item.key].includes(item.codedValue))
        .map((item) => data.findIndex((i) => i.codedValue === item.codedValue));
    }
    return indexes;
  }, [mapFilters, data]);

  const barsWithValues = useMemo(() => {
    const set = new Set();
    data.forEach((item) => {
      barValues.forEach((barValue) => {
        // if (!!item[barValue]){
        //
        // }
        set.add(barValue);
      });
    });

    return Array.from(set);
  }, [data]);

  const renderedData = data.map((item) => {
    const logQuantity =
      item.quantity > 1 ? Math.log(item.quantity) : item.quantity;
    const logExpected =
      item.expected > 1 ? Math.log(item.expected) : item.expected;
    return {
      ...item,
      quantity: logQuantity * 10,
      expected: logExpected * 10,
      "original-quantity": item.quantity,
      "original-expected": item.expected,
    };
  });

  return (
    <>
      <BarChart
        data={renderedData}
        layout={layout}
        onClick={(params) => {
          if (onClick) {
            onClick(params, data);
          }
        }}
        {...props}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {layout === "vertical" ? (
          <>
            <XAxis
              fontSize={10}
              type="number"
              allowDataOverflow
              hide={!showX}
              tickFormatter={(v, n) => {
                let value = Math.round(Math.exp(v / 10));
                if (value > 1000) {
                  v = Math.round(value / 1000) * 1000;
                }
                return v;
              }}
            />
            <YAxis
              interval={0}
              orientation="left"
              width={isMobileApp ? 65 : 120}
              fontSize={9}
              type="category"
              dataKey="name"
              // domain={['auto', 'auto']}
              tick={{
                fill: "#393738",
              }}
              // tick={<RenderCustomizedLabel />}
            />
          </>
        ) : (
          <>
            <XAxis interval={0} fontSize={10} dataKey="name" />
            <YAxis width={30} fontSize={10} />
          </>
        )}
        <Tooltip
          content={(params) => {
            return <CustomTooltip {...params} initialData={data} />;
          }}
          cursor={{ fill: COLORS.green + "1a" }}
          wrapperStyle={{ cursor: "pointer" }}
        />
        {barsWithValues.map((barValue) => (
          <Bar
            key={barValue}
            dataKey={barValue}
            fill={colors.fill[barValue]}
            stackId={stackIds[barValue]}
          >
            {renderedData.map((item, index) => (
              <Cell
                key={item.name}
                fill={
                  selectedIndexes.length > 0 && !selectedIndexes.includes(index)
                    ? COLORS.grey
                    : colors.fill[barValue]
                }
              />
            ))}
          </Bar>
        ))}
        ,
      </BarChart>
    </>
  );
};

export default CustomBarChart;
