import { loadModules } from "esri-loader";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphicsLayer, view } from "../../../../../../utils/API";
import ActionButton from "../../../../CycleManager/Checklist/ChecklistTable/ActionButton";
import { EditorContext } from "../../../EditorContextProvider";

const CalculateRouteButton = ({
  config,
  clickedPoints,
  handleCreateRoute,
  isActive,
}) => {
  const [disabled, setDisabled] = useState(true);
  const { activeColor } = useContext(EditorContext);
  const { t } = useTranslation("common");

  useEffect(() => {
    const getButtonState = () => {
      const possibleRouteIds = [];
      const pointsWithoutCoordinates = [];
      clickedPoints.forEach((point, index, array) => {
        if (index < array.length - 1) {
          const currPoint = array[index];
          const nextPoint = array[index + 1];
          if (
            currPoint &&
            nextPoint &&
            currPoint.searchable &&
            nextPoint.searchable &&
            currPoint.coordinate.length > 0 &&
            nextPoint.coordinate.length > 0
          ) {
            possibleRouteIds.push(
              `route-${currPoint.coordinate.join(
                "|"
              )}-${nextPoint.coordinate.join("|")}`
            );
          }
        }

        if (point.coordinate.length === 0) {
          pointsWithoutCoordinates.push(point);
        }
      });
      const graphicRoutes = graphicsLayer.graphics
        .toArray()
        .filter((g) => possibleRouteIds.includes(g.id));

      return (
        pointsWithoutCoordinates.length > 0 ||
        possibleRouteIds.length === 0 ||
        graphicRoutes.length === possibleRouteIds.length
      );
    };

    let handler;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      view.whenLayerView(graphicsLayer).then((lv) => {
        handler = reactiveUtils.watch(
          () => lv.updating,
          (updating) => {
            if (!updating) {
              const buttonState = getButtonState();
              setDisabled(buttonState);
            }
          }
        );
      });
    });

    const buttonState = getButtonState();
    setDisabled(buttonState);

    return () => {
      handler?.remove();
    };
  }, [clickedPoints]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "18px auto auto auto",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <ActionButton
        isActive={isActive}
        onClick={handleCreateRoute}
        color={config.opsColor}
      >
        {t("screen.widget.Editor.manager.routeSelection.routeApi.findRoute")}
      </ActionButton>
    </div>
  );
};

export default CalculateRouteButton;
