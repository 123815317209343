// ImageSwiper.js
import React, { useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { FaChevronRight } from "../../../../Icons";
import {
  FeatureImageSwiperWrapper,
  ImageWrapper,
  NavigationButtonsWrapper,
  NavigationCount,
  SkeletonPlaceholder,
  StyledImage,
} from "./ImageSwiper-styled";

const LazyImage = ({ src, alt }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <ImageWrapper>
      <SkeletonPlaceholder loaded={loaded} />
      <StyledImage
        src={src}
        alt={alt}
        loading="lazy"
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
    </ImageWrapper>
  );
};

const ImageSwiper = ({ images, height }) => {
  const [count, setCount] = useState(0);
  const swiperRef = useRef(null);

  const showNext = useMemo(() => {
    if (swiperRef.current) {
      return !swiperRef.current.isEnd;
    }
  }, [count]);
  const showPrev = useMemo(() => {
    if (swiperRef.current) {
      return !swiperRef.current.isBeginning;
    }
  }, [count]);

  const currSlide = useMemo(() => {
    if (swiperRef.current) {
      return swiperRef.current.activeIndex + 1;
    }

    return 1;
  }, [count]);

  if (images.length === 0) {
    return null;
  }

  return (
    <FeatureImageSwiperWrapper height={height}>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          setCount((prev) => prev + 1);
        }}
        onSlideChange={() => {
          setCount((prev) => prev + 1);
        }}
        slidesPerView="1"
      >
        {images.map((image) => (
          <SwiperSlide key={image.id}>
            <LazyImage src={image.url} alt={`Image-${image.id}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <NavigationButtonsWrapper>
        <button
          style={{ opacity: showPrev ? 1 : 0 }}
          onClick={(e) => {
            e.stopPropagation();
            swiperRef.current.slidePrev();
          }}
        >
          <FaChevronRight />
        </button>
        <button
          style={{ opacity: showNext ? 1 : 0 }}
          onClick={(e) => {
            e.stopPropagation();
            swiperRef.current.slideNext();
          }}
        >
          <FaChevronRight />
        </button>
        <NavigationCount>
          <span>
            {currSlide} / {images.length}
          </span>
        </NavigationCount>
      </NavigationButtonsWrapper>
    </FeatureImageSwiperWrapper>
  );
};

export default ImageSwiper;
