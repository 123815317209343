import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../UI";
import { EditType } from "../EditorSwiper/EditorSwiper";
import CurvedPolyline from "./CurvedPolyline";
import SketchCoordinates from "./SketchCoordinates";

export const selectionLineSymbol = {
  type: "simple-line",
  color: [255, 240, 0, 1],
  width: 3,
};

const RoadsCoordinates = ({
  editableLayer,
  clickActive,
  editType,
  handleNext,
  showLocationInput,
  disabled,
  activeColor,
}) => {
  const { t } = useTranslation("common");
  const [locationDisabled, setLocationDisabled] = useState(disabled);

  //to disabled input when accordion is closed
  useEffect(() => {
    if (!showLocationInput && disabled) {
      setLocationDisabled(true);
    }
  }, [showLocationInput]);

  if (
    editType === EditType.edit &&
    editableLayer.layerConfig.titleLabel === "roadsSituational"
  )
    return null;

  // if (editableLayer.layerConfig?.isEditorCurved) {
  //   return <CurvedPolyline handleNext={handleNext} isLastStep={!clickActive} />;
  // }

  const onLocationInputChange = (e) => {
    const { checked } = e.target;

    setLocationDisabled(!checked);
  };

  return (
    <>
      {showLocationInput && (
        <div
          style={{
            padding: "4px 0px 8px 0px",
          }}
        >
          <Checkbox
            onChange={onLocationInputChange}
            label={t("screen.widget.Editor.manager.geometry.allowEditGeometry")}
            color={activeColor}
            borderRadius="4px"
            checked={!locationDisabled}
          />
        </div>
      )}
      {editableLayer.layerConfig?.isEditorCurved ? (
        <CurvedPolyline
          handleNext={handleNext}
          isLastStep={!clickActive}
          disabled={locationDisabled}
        />
      ) : (
        <SketchCoordinates
          editableLayer={editableLayer}
          clickActive={clickActive}
          editType={editType}
          handleNext={handleNext}
          disabled={locationDisabled}
        />
      )}
    </>
  );
};

export default RoadsCoordinates;
