import { loadModules } from "esri-loader";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { graphicsLayer, view } from "../../../../../../../utils/API";

import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../../../../../utils/ConfigContext";
import {
  getLineSymbol,
  getPolygonSymbol,
} from "../../../../../../BatchEditor/Symbols";
import { selectFeatures } from "../../../../../../BatchEditor/helper";
import ActionButton from "../../../../../CycleManager/Checklist/ChecklistTable/ActionButton";
import {
  StyledManualContainer,
  StyledSituationalTitle,
} from "../../../../Editor-styled";
import { selectionLineSymbol } from "../../../RoadsCoordinates";

const PolylineIcon = React.memo((props) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="16px"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="m344 280 88-88m-200 24 64 64M80 320l104-104"
    />
    <circle
      cx="456"
      cy="168"
      r="24"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="320"
      cy="304"
      r="24"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="208"
      cy="192"
      r="24"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    ></circle>
    <circle
      cx="56"
      cy="344"
      r="24"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    ></circle>
  </svg>
));

const PolygonIcon = React.memo((props) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 256 256"
    height="16px"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M233.47,97.45a36,36,0,0,0-50.92-50.92h0a36.18,36.18,0,0,0-4.12,4.95l-22.55-6.15a36,36,0,0,0-61.34-22.8h0a36.05,36.05,0,0,0-7.8,39.24L57.19,88.37a36.08,36.08,0,0,0-42.66,6.17h0a36,36,0,0,0,45.73,55.21l65.28,47.87A36,36,0,1,0,182.62,180L208,108A35.91,35.91,0,0,0,233.47,97.45Zm-93.74,80.81L74.45,130.39a36.19,36.19,0,0,0-1.21-24.17L102.8,79.61a36,36,0,0,0,42.66-6.16,35.47,35.47,0,0,0,4.12-5l22.55,6.15a35.86,35.86,0,0,0,10.42,22.8A38.06,38.06,0,0,0,185.4,100L160,172A36,36,0,0,0,139.73,178.26ZM216.5,63.5a12,12,0,1,1-17,0A12,12,0,0,1,216.5,63.5Zm-105-24a12,12,0,1,1,0,17A12,12,0,0,1,111.51,39.5Zm-80,89a12,12,0,1,1,17,0A12,12,0,0,1,31.5,128.49Zm137,88a12,12,0,1,1,0-17A12,12,0,0,1,168.49,216.5Z"></path>
  </svg>
));

const polyLineSymbol = getLineSymbol("#00FF00", 0.5);
const polygonSymbol = getPolygonSymbol();

const ManualSelection = ({
  editableLayer,
  onStart,
  onComplete,
  setLoading,
}) => {
  const [activeDrawTool, setActiveDrawTool] = useState(null);
  const drawRef = useRef(null);
  const drawActionRef = useRef(null);
  const drawGraphicsLayerRef = useRef(null);
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  // Add escape key handler
  const handleEscapeKey = useCallback(
    (event) => {
      const isEscapePressed =
        event.key === "Escape" ||
        event.key === "Esc" || // For older browsers
        event.keyCode === 27; // For legacy browser support
      if (isEscapePressed && drawActionRef.current) {
        // Remove the event listener
        document.removeEventListener("keydown", handleEscapeKey);

        // Cancel the draw action without processing
        drawActionRef.current.destroy();
        drawActionRef.current = null;

        // Clean up graphics layer
        if (drawGraphicsLayerRef.current) {
          view.map.remove(drawGraphicsLayerRef.current);
          drawGraphicsLayerRef.current = null;
        }

        setActiveDrawTool(null);
        onComplete();
      }
    },
    [onComplete]
  );

  useEffect(() => {
    // Clean up draw tool when component unmounts
    return () => {
      if (drawRef.current) {
        drawRef.current.destroy();
      }

      if (drawGraphicsLayerRef.current) {
        view.map.remove(drawGraphicsLayerRef.current);
      }

      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const getCombinedRoutes = async (newGraphics, existingGraphics) => {
    const [geometryEngineAsync, Graphic, Polyline, webMercatorUtils] =
      await loadModules([
        "esri/geometry/geometryEngineAsync",
        "esri/Graphic",
        "esri/geometry/Polyline",
        "esri/geometry/support/webMercatorUtils",
      ]);

    // Track which existing geometries should be kept
    const geometriesToKeep = [...existingGraphics.map((g) => g.geometry)];
    const graphicsToKeep = [...existingGraphics];

    // Check each new geometry against existing ones
    for (const newGraphic of newGraphics) {
      const newGeometry = newGraphic.geometry;
      let isExactMatch = false;

      // Look for exact matches with existing geometries
      for (let i = 0; i < existingGraphics.length; i++) {
        const existingGeometry = existingGraphics[i].geometry;

        const equals = await geometryEngineAsync.equals(
          newGeometry,
          existingGeometry
        );

        if (equals) {
          // If exact match found, remove it (toggle off)
          graphicsToKeep.splice(i, 1);
          isExactMatch = true;
          break;
        }
      }

      // If no exact match found, add this as a new geometry to keep
      if (!isExactMatch) {
        graphicsToKeep.push(newGraphic);
      }
    }

    // If no geometries to keep, we're done
    if (graphicsToKeep.length === 0) {
      return;
    }

    // Create a union of all geometries we're keeping
    let union = await geometryEngineAsync.union(
      graphicsToKeep.map((g) => g.geometry)
    );
    // if (!union.spatialReference.isGeographic) {
    //   union = webMercatorUtils.webMercatorToGeographic(union);
    // }

    return {
      lineGeometry: union,
      graphics: graphicsToKeep,
    };
  };

  const completeRef = useRef(false);
  const onDrawComplete = useCallback(
    async (selectionPolygon) => {
      if (completeRef.current) return;
      try {
        completeRef.current = true;
        setActiveDrawTool(null);
        setLoading(true);
        const [
          geometryEngineAsync,
          Graphic,
          geometryEngine,
          Polyline,
          webMercatorUtils,
        ] = await loadModules([
          "esri/geometry/geometryEngineAsync",
          "esri/Graphic",
          "esri/geometry/geometryEngine",
          "esri/geometry/Polyline",
          "esri/geometry/support/webMercatorUtils",
        ]);

        const res = await selectFeatures(
          editableLayer,
          selectionPolygon,
          {},
          {
            geometryEngineAsync,
            Graphic,
            geometryEngine,
            Polyline,
            webMercatorUtils,
          }
        );

        const newGraphics = res.map((graphic) => {
          graphic.id = "route-manual-new";
          graphic.symbol = selectionLineSymbol;
          return graphic;
        });

        const existingManualRouteGraphics = [];
        const existingPointsGraphicsId = [];
        const existingPointGraphics = [];

        graphicsLayer.graphics.forEach((g) => {
          if (!g.id) return;
          if (g.id.includes("route-manual")) {
            existingManualRouteGraphics.push(g);
          } else if (g.id.includes("point")) {
            // const {latitude, longitude} = g.geometry;
            existingPointsGraphicsId.push(g.id);
            existingPointGraphics.push(g);
          }
        });

        // const existingManualRouteGraphics = graphicsLayer.graphics
        //   .toArray()
        //   .filter((g) => g.id && g.id === "route-manual");

        // const existingPointsGraphics = graphicsLayer.graphics.toArray.filter(g=>g.id &&)
        const { lineGeometry, graphics } = await getCombinedRoutes(
          newGraphics,
          existingManualRouteGraphics
        );

        // const selectionGeographic = await geometryEngineAsync.geodesicBuffer(
        //   webMercatorUtils.webMercatorToGeographic(lineGeometry),
        //   50,
        //   "meters"
        // );

        const selectionGeographic =
          webMercatorUtils.webMercatorToGeographic(selectionPolygon);

        const connectedPointIds = [];
        for (const point of existingPointGraphics) {
          const intersects = await geometryEngineAsync.intersects(
            selectionGeographic,
            point.geometry
          );
          const contains = await geometryEngineAsync.contains(
            selectionGeographic,
            point.geometry
          );
          if (contains || intersects) {
            connectedPointIds.push(point.id);
          }
        }

        // Remove existing graphics with id "route-manual"
        graphicsLayer.graphics.removeMany(existingManualRouteGraphics);
        graphics.forEach((g) => {
          if (g.id && g.id.includes("route-manual-new")) {
            g.id = `route-manual-${connectedPointIds.join("|")}`;
          }
        });
        // Add the graphics to the layer
        graphicsLayer.addMany(graphics);
      } catch (err) {
        console.log(err);
      } finally {
        if (drawRef.current) {
          drawActionRef.current = null;
        }

        if (drawGraphicsLayerRef.current) {
          view.map.remove(drawGraphicsLayerRef.current);
          drawGraphicsLayerRef.current = null;
        }
        setLoading(false);
        onComplete();
        completeRef.current = false;
      }
    },
    [onComplete, editableLayer]
  );

  const createPolylineDraw = useCallback(
    ({ drawAction, drawGraphicsLayer, geometryEngineAsync }) => {
      drawAction.on(["vertex-add", "vertex-remove", "cursor-update"], (evt) => {
        const geometry = {
          type: "polyline",
          paths: evt.vertices,
          spatialReference: view.spatialReference,
        };
        drawGraphicsLayer.removeAll();
        drawGraphicsLayer.add({
          symbol: polyLineSymbol,
          geometry: geometry,
        });
      });

      drawAction.on("draw-complete", async () => {
        try {
          if (drawGraphicsLayer.graphics.items.length > 0) {
            const selection = await geometryEngineAsync.geodesicBuffer(
              drawGraphicsLayer.graphics.items[0].geometry,
              editableLayer.layerConfig.batchEditor.bufferFactor * view.scale,
              "meters"
            );
            drawGraphicsLayer.removeAll();
            drawGraphicsLayer.add({
              symbol: polygonSymbol,
              geometry: selection,
            });

            onDrawComplete(selection);
          }
        } catch (e) {
          console.error(e);
        }
      });
    },
    [onDrawComplete, editableLayer]
  );

  const createPolygonDraw = useCallback(
    ({ drawAction, drawGraphicsLayer }) => {
      drawAction.on(["vertex-add", "vertex-remove", "cursor-update"], (evt) => {
        let geometry;
        if (evt.vertices.length === 2) {
          geometry = {
            type: "polyline",
            paths: [evt.vertices],
            spatialReference: view.spatialReference,
          };
        } else {
          geometry = {
            type: "polygon",
            rings: evt.vertices,
            spatialReference: view.spatialReference,
          };
        }

        drawGraphicsLayer.removeAll();
        drawGraphicsLayer.add({
          symbol: polygonSymbol,
          geometry: geometry,
        });
      });

      drawAction.on("draw-complete", async () => {
        try {
          const geometry = drawGraphicsLayer.graphics.items[0].geometry;
          onDrawComplete(geometry);
        } catch (e) {
          console.log(e);
        }
      });
    },
    [onDrawComplete]
  );

  useEffect(() => {
    const drawComplete = () => {
      if (drawRef.current) {
        drawRef.current.complete();
      }
    };

    if (!activeDrawTool) return;
    addEventListener("dblclick", drawComplete);

    return () => {
      removeEventListener("dblclick", drawComplete);
    };
  }, [activeDrawTool]);

  const activateDrawTool = useCallback(
    async (geometryType) => {
      // If same button clicked, deactivate tool
      if (activeDrawTool === geometryType) {
        if (drawActionRef.current) {
          drawActionRef.current.complete();
          drawActionRef.current = null;
        }

        if (drawRef.current) {
          drawRef.current.destroy();
          drawRef.current = null;
        }

        if (drawGraphicsLayerRef.current) {
          view.map.remove(drawGraphicsLayerRef.current);
          drawGraphicsLayerRef.current = null;
        }
        setActiveDrawTool(null);
        onComplete();
        return;
      }

      // Cancel any active drawing
      if (drawActionRef.current) {
        drawRef.current.destroy();
        drawRef.current = null;
      }

      try {
        //Sometimes draw doesn't stop, so do it with javascript event listener:

        onStart();
        // Load Draw module from ArcGIS API
        const [Draw, GraphicsLayer, geometryEngineAsync] = await loadModules([
          "esri/views/draw/Draw",
          "esri/layers/GraphicsLayer",
          "esri/geometry/geometryEngineAsync",
        ]);
        let drawGraphicsLayer = drawGraphicsLayerRef.current;

        if (!drawGraphicsLayer) {
          drawGraphicsLayer = new GraphicsLayer({
            id: "manualDrawGraphics",
          });
          drawGraphicsLayerRef.current = drawGraphicsLayer;
          view.map.add(drawGraphicsLayer);
        }

        // Create draw tool if it doesn't exist
        if (!drawRef.current) {
          drawRef.current = new Draw({
            view: view,
          });
        }

        // Set active tool
        setActiveDrawTool(geometryType);

        // Add the event listener
        document.addEventListener("keydown", handleEscapeKey);

        // Start drawing
        let drawAction;
        if (geometryType === "polyline") {
          drawAction = drawRef.current.create("polyline", { mode: "click" });
          createPolylineDraw({
            drawAction,
            drawGraphicsLayer,
            geometryEngineAsync,
          });
        } else {
          drawAction = drawRef.current.create("polygon", { mode: "click" });
          createPolygonDraw({ drawAction, drawGraphicsLayer });
        }

        drawActionRef.current = drawAction;
      } catch (error) {
        console.error("Error creating drawing tool:", error);
        setActiveDrawTool(null);
      }
    },
    [
      createPolylineDraw,
      activeDrawTool,
      createPolygonDraw,
      onStart,
      onComplete,
      handleEscapeKey,
    ]
  );

  return (
    <StyledManualContainer>
      <StyledSituationalTitle>
        <span>
          {t("screen.widget.Editor.manager.routeSelection.manualAdd.title")}
        </span>
        <span>
          {t("screen.widget.Editor.manager.routeSelection.manualAdd.subtitle")}
        </span>
      </StyledSituationalTitle>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: 10,
          justifyContent: "center",
        }}
      >
        <ActionButton
          color={config.opsColor}
          isActive={activeDrawTool === "polyline"}
          onClick={() => activateDrawTool("polyline")}
          disabled={activeDrawTool && activeDrawTool !== "polyline"}
          Icon={PolylineIcon}
        >
          {t("screen.widget.Editor.manager.routeSelection.manualAdd.polyline")}
        </ActionButton>
        <ActionButton
          color={config.opsColor}
          isActive={activeDrawTool === "polygon"}
          onClick={() => activateDrawTool("polygon")}
          disabled={activeDrawTool && activeDrawTool !== "polygon"}
          Icon={PolygonIcon}
        >
          {t("screen.widget.Editor.manager.routeSelection.manualAdd.polygon")}
        </ActionButton>
      </div>
    </StyledManualContainer>
  );
};

export default ManualSelection;
