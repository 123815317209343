import {
  StyledSectionDescription,
  StyledSectionTitle,
} from "../Report/new/ScrollSection/ScrollSection-styled";
import {
  DocumentPreview,
  FileDelete,
  FilePreview,
  FilePreviewContainer,
  FileUpload,
  FileUploadText,
} from "../Report/new/UploadFile/UploadFile-styled";
import Button from "../Report/new/Button/Button";
import { CircleClose } from "../Icons";
import React from "react";
import { useDropzone } from "react-dropzone";

const Uploader = ({
  title,
  description,
  onDrop,
  accept = "image/jpeg,image/jpg,image/png,application/pdf",
  color,
  buttonTitle,
  fileDescription,
  previews,
  handleDelete,
  files,
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept,
  });

  return (
    <>
      {title && (
        <StyledSectionTitle style={{ margin: "5px 0px 0px 0px", fontSize: 12 }}>
          {title}
        </StyledSectionTitle>
      )}
      {description && (
        <StyledSectionDescription style={{ margin: 0 }}>
          {description}
        </StyledSectionDescription>
      )}
      <FileUpload
        style={{ padding: 0, alignItems: "center" }}
        color="#4c4c4c4d"
        isDragActive={isDragActive}
        {...getRootProps()}
      >
        <input accept="image/*" {...getInputProps()} />
        {files.length === 0 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 8,
            }}
          >
            {/*<Button style={{margin:'auto'}} onClick={open}>*/}
            {/*  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path></svg>*/}
            {/*  /!*{buttonTitle && <span>{buttonTitle}</span>}*!/*/}
            {/*</Button>*/}
            <Button
              type="button"
              onClick={open}
              bg="#fff"
              style={{
                // border: "none",
                marginBottom: "0px",
                minWidth: 100,
                height: 100,
              }}
            >
              <svg
                stroke="currentColor"
                fill="rgb(76,76,76)"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path>
              </svg>
            </Button>
            <FileUploadText isDragActive={isDragActive}>
              {fileDescription}
            </FileUploadText>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              alignItems: "center",
            }}
          >
            {files.map((file, index) => {
              if (file.isDeleted) return null;

              return (
                <FilePreviewContainer
                  key={file.name + index}
                  style={{
                    width: 98,
                    height: 98,
                    boxSizing: "border-box",
                    borderRadius: 8,
                  }}
                  heightAuto={previews[file.name + index] === "pdf"}
                >
                  {file.url === "pdf" ? (
                    <DocumentPreview>
                      <svg
                        stroke="rgb(76,76,76)"
                        fill="rgb(76,76,76)"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        height="40px"
                        width="40px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="none"
                          strokeLinejoin="round"
                          strokeWidth="32"
                          d="M416 221.25V416a48 48 0 0 1-48 48H144a48 48 0 0 1-48-48V96a48 48 0 0 1 48-48h98.75a32 32 0 0 1 22.62 9.37l141.26 141.26a32 32 0 0 1 9.37 22.62z"
                        ></path>
                        <path
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="32"
                          d="M256 56v120a32 32 0 0 0 32 32h120m-232 80h160m-160 80h160"
                        ></path>
                      </svg>
                      <span className="text-elipsis" style={{ width: "100%" }}>
                        {file.name}
                      </span>
                    </DocumentPreview>
                  ) : (
                    <FilePreview src={file.url} alt={file.name} />
                  )}
                  <FileDelete onClick={() => handleDelete(file, index)}>
                    <CircleClose color={color} />
                  </FileDelete>
                </FilePreviewContainer>
              );
            })}
            <Button
              type="button"
              onClick={open}
              bg="#fff"
              style={{
                // border: "none",
                marginBottom: "0px",
                minWidth: 100,
                height: 100,
              }}
            >
              <svg
                stroke="currentColor"
                fill="rgb(76,76,76)"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path>
              </svg>
            </Button>
          </div>
        )}
      </FileUpload>
    </>
  );
};

export default Uploader;
