import styled from "styled-components";

export const StyledButton = styled.button`
	background-color: ${({bg})=> bg ?? '#b21b0c'};
	min-height: 40px;
	text-decoration: none;
	color: #fff;
	border-width: 0;
  	border-radius: 8px;
	font-size: 16px;
	cursor: pointer;
	width: 100%;
	opacity: ${({ disabled }) => (!disabled ? 1 : 0.5)};
`;