export const APP_ENV = process.env.REACT_APP_ENV || "development";

const OPEN_ROUTE_SERVICE_API = "https://api.openrouteservice.org";
const OPEN_ROUTE_SERVICE_API_KEY = process.env.REACT_APP_ORS_API_KEY;

export const LOGIE_WEBSITE = "https://logie.logcluster.org";

export const ORS_WAY_TYPES = Object.freeze({
  0: {
    name: "Unknown",
    tags: [],
  },
  1: {
    name: "State Road",
    tags: [
      "primary",
      "primary_link",
      "motorway",
      "motorway_link",
      "trunk",
      "trunk_link",
    ],
  },
  2: {
    name: "Road",
    tags: [
      "secondary",
      "secondary_link",
      "tertiary",
      "tertiary_link",
      "road",
      "unclassified",
    ],
  },
  3: {
    name: "Street",
    tags: ["residential", "service", "living_street"],
  },
  4: {
    name: "Path",
    tags: ["path"],
  },
  5: {
    name: "Track",
    tags: ["track"],
  },
  6: {
    name: "Cycleway",
    tags: ["cycleway"],
  },
  7: {
    name: "Footway",
    tags: ["footway", "pedestrian", "crossing"],
  },
  8: {
    name: "Steps",
    tags: ["steps"],
  },
  9: {
    name: "Ferry",
    tags: ["route=shuttle_train", "route=ferry"],
  },
  10: {
    name: "Construction",
    tags: ["construction"],
  },
});

export const ORS_SURFACE_TYPES = Object.freeze({
  0: {
    name: "Unknown",
    tags: [],
  },
  1: {
    name: "Paved",
    tags: ["paved"],
  },
  2: {
    name: "Unpaved",
    tags: ["unpaved", "woodchips", "rock", "rocks", "stone", "shells", "salt"],
  },
  3: {
    name: "Asphalt",
    tags: ["asphalt", "chipseal", "bitmac", "tarmac"],
  },
  4: {
    name: "Concrete",
    tags: ["concrete", "cement"],
  },
  5: {
    name: "Cobblestone",
    tags: [],
  },
  6: {
    name: "Metal",
    tags: ["metal"],
  },
  7: {
    name: "Wood",
    tags: ["wood"],
  },
  8: {
    name: "Compacted Gravel",
    tags: ["compacted", "pebblestone"],
  },
  9: {
    name: "Fine Gravel",
    tags: [],
  },
  10: {
    name: "Gravel",
    tags: ["gravel", "fine_gravel"],
  },
  11: {
    name: "Dirt",
    tags: ["dirt", "earth", "soil"],
  },
  12: {
    name: "Ground",
    tags: ["ground", "mud"],
  },
  13: {
    name: "Ice",
    tags: ["ice", "snow"],
  },
  14: {
    name: "Paving Stones",
    tags: [
      "paving_stones",
      "paved_stones",
      "sett",
      "cobblestone",
      "unhewn_cobblestone",
      "bricks",
      "brick",
    ],
  },
  15: {
    name: "Sand",
    tags: ["sand"],
  },
  16: {
    name: "Woodchips",
    tags: ["woodchips"],
  },
  17: {
    name: "Grass",
    tags: ["grass"],
  },
  18: {
    name: "Grass Paver",
    tags: ["grass_paver"],
  },
});

export const ORS_TO_LOGIE_ROAD_TYPES = Object.freeze({
  0: 0,
  1: 2,
  2: 3,
  3: 5,
  4: 7,
  5: 7,
  6: 6,
  7: 6,
  8: 6,
  9: 0,
  10: 0,
});

export { OPEN_ROUTE_SERVICE_API, OPEN_ROUTE_SERVICE_API_KEY };
