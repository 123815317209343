import styled from "styled-components";

export const SortButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  padding: 4px 6px;
  border: 1px solid #e3e2e2;
  border-radius: 8px;

  span {
    font-size: 10px;
    color: #6a6a6a;
    margin-right: 4px;
  }

  div {
    position: relative;
    width: 8px;
    height: 16px;
  }

  svg {
    top: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.3s;
  }
`;

export const StyledFilterWrapper = styled.div`
  .react-select__control {
    border-radius: 100px;
    margin: 0px 4px;
  }

  .react-select__multi-value {
    padding: 0;
  }

  .react-select__multi-value__label {
    font-size: 10px;
    padding: 4px 0 4px 6px;
    line-height: 1;
  }

  .react-select__multi-value__remove {
    padding-left: 2px;
  }

  .react-select__multi-value__remove:hover {
    background: transparent;
    color: #ffffff;
  }

  .react-select__indicators {
    align-items: center;
  }

  .react-select__indicator {
    padding: 2px;
  }

  .react-select__indicator-separator {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
