import { loadModules } from "esri-loader";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  CalciteButton,
  CalciteList,
  CalciteProgress,
} from "@esri/calcite-components-react";
import {
  Container,
  ListItemStyled,
  Step,
  StepField,
  StepHead,
} from "./BatchEditor-styled";

import { view } from "../../utils/API";
import FilterFieldCombo from "../Filter/FilterFieldCombo";
import { getLineSymbol, getPolygonSymbol } from "./Symbols";

import { StyledLoader } from "../App/App-styled";
import {
  applyFeatureUpdates,
  getGraphic,
  mergeFeatures,
  selectFeatures,
} from "./helper";

/**
 * RoadsLayer must be loaded
 */
const BatchEditor = ({ config, expand, t, openSnackbar, reactiveUtils }) => {
  /**
   * steps:
   * 0: Select layer to be edited
   * 1: Select mode of selection
   * 2: apply filter
   * 3: apply changes
   */
  const [step, setStep] = useState(0);

  /**
   * All editable layers
   */
  const [editableLayers, setEditableLayers] = useState([]);

  const [editableLayer, setEditableLayer] = useState();
  const [visibleLayers, setVisibleLayers] = useState([]);

  const [polyLineSelected, setPolyLineSelected] = useState(false);
  const [polygonSelected, setPolygonSelected] = useState(false);
  const [selectionLayer, setSelectionLayer] = useState();
  const [draw, setDraw] = useState();
  const [esriModules, setEsriModules] = useState(null);
  const [selectionPolygon, setSelectionPolygon] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState(null);
  const [queryInProgress, setQueryInProgress] = useState(false);
  const [drawActive, setDrawActive] = useState(false);

  /**
   * Remember the point of the last click
   */
  const [clickEvent, setClickEvent] = useState();

  const [appliedFilters, setAppliedFilters] = useState({});
  const [appliedUpdates, setAppliedUpdates] = useState({});
  const appliedFiltersRef = useRef();
  const appliedUpdatesRef = useRef();
  appliedFiltersRef.current = appliedFilters;
  appliedUpdatesRef.current = appliedUpdates;

  const layerVisibilityChangeEvent = () => {
    setVisibleLayers(editableLayers.filter((layer) => layer.visible));
  };

  /**
   * For step 2 we allow shift + click events to unselect certain features
   */
  useEffect(() => {
    if (
      step !== 2 ||
      !clickEvent ||
      !(clickEvent.native.shiftKey || clickEvent.native.ctrlKey)
    )
      return;

    view
      .hitTest(clickEvent, { include: [...selectedFeatures] })
      .then((response) => {
        const newSelectedFeatures = selectedFeatures.filter(
          (f) => !response.results.some((res) => res.graphic === f)
        );

        selectionLayer.removeAll();
        selectionLayer.add(
          getGraphic(esriModules, selectionPolygon, polygonSymbol)
        );
        selectionLayer.addMany(newSelectedFeatures);

        setSelectedFeatures(newSelectedFeatures);
      });
  }, [clickEvent]);

  useEffect(() => {
    loadModules([
      "esri/layers/GraphicsLayer",
      "esri/views/draw/Draw",
      "esri/Graphic",
      "esri/geometry/geometryEngine",
      "esri/geometry/Polyline",
      "esri/geometry/geometryEngineAsync",
      "esri/geometry/support/webMercatorUtils",
    ]).then(
      ([
        GraphicsLayer,
        Draw,
        Graphic,
        geometryEngine,
        Polyline,
        geometryEngineAsync,
        webMercatorUtils,
      ]) => {
        setEsriModules({
          Graphic,
          geometryEngine,
          Polyline,
          geometryEngineAsync,
          webMercatorUtils,
        });
        const grLayer = new GraphicsLayer({ id: "BatchEditorGraphicsLayer" });

        setSelectionLayer(grLayer);
        const draw = new Draw({ view });
        setDraw(draw);
        let layerViewFilters = {};

        reactiveUtils.watch(
          () => expand.viewModel.expanded,
          () => {
            if (expand.viewModel.expanded) {
              view.layerViews.forEach((lv) => {
                if (lv.filter?.where) {
                  layerViewFilters[lv.layer.id] = lv.filter.where;
                  lv.filter.where = "";
                }
              });

              grLayer.load().then((layer) => {
                view.map.add(layer);
              });
            } else {
              setStep(0);
              grLayer.removeAll();
              view.map.remove(grLayer);
              if (draw) {
                draw.complete();
              }
              setDrawActive(false);

              view.layerViews.forEach((lv) => {
                if (layerViewFilters[lv.layer.id]) {
                  lv.filter.where = layerViewFilters[lv.layer.id];
                }
              });
              layerViewFilters = {};
            }
          }
        );

        view.map.layers.on("change", (event) => {
          if (event.added.length > 0) updateLayers(event.added);
        });

        view.on("click", (event) => {
          setClickEvent(event);
        });
        updateLayers(view.map.layers);
      }
    );
  }, []);

  const updateLayers = useCallback(
    (newLayers) => {
      const els = view.map.layers.filter((l) => isEditable(l));
      setEditableLayers(els);
      setVisibleLayers(els.filter((layer) => layer.visible));

      newLayers
        .filter((l) => isEditable(l))
        .forEach((newLayer) => {
          newLayer.watch("visible", () => {
            layerVisibilityChangeEvent();
          });
        });
    },
    [editableLayers, visibleLayers]
  );

  /**
   * Layer is editable by the batch editor if it fas a batchEditor configuration and the editedLayer attribute is set
   */
  const isEditable = (layer) => {
    if (
      !layer.type ||
      layer.type !== "feature" ||
      !layer.layerConfig ||
      !layer.layerConfig.editable
    )
      return false;

    const lc = layer.layerConfig;
    return (
      lc?.batchEditor?.editedLayer &&
      (Array.isArray(lc?.batchEditor?.editedLayer)
        ? !lc?.batchEditor?.editedLayer.includes(lc.alias)
        : !lc?.batchEditor?.editedLayer !== lc.alias)
    );
  };

  const getFieldConfiguration = (el, fields, onValueChange, isMulti) => {
    const fieldConfig = {};
    fields?.forEach((field, index) => {
      const layerField = el.fields.find((f) => f.name === field);
      if (!layerField) return;

      const newFilterId = `filterFields_${new Date().getTime()}_${index}`;
      fieldConfig[newFilterId] = {
        id: newFilterId,
        field: layerField,
        value: [],
        component: (
          <FilterFieldCombo
            key={`ff_${layerField.name}`}
            field={layerField}
            t={t}
            multi={isMulti}
            referenceKey={newFilterId}
            onValuesChange={onValueChange.bind(this)}
          />
        ),
      };
    });

    return fieldConfig;
  };

  /**
   * Selection tool symbols
   */
  const polygonSymbol = getPolygonSymbol();
  const polyLineSymbol = getLineSymbol("#00FF00", 0.5);

  /** Query layer for features and apply filters **/
  const displaySelection = useCallback(() => {
    if (!editableLayer || !selectionPolygon) return;

    setQueryInProgress(true);
    selectFeatures(
      editableLayer,
      selectionPolygon,
      appliedFilters,
      esriModules
    ).then((res) => {
      selectionLayer.removeAll();
      selectionLayer.add(
        getGraphic(esriModules, selectionPolygon, polygonSymbol)
      );
      selectionLayer.addMany(res);

      setSelectedFeatures(res);
      setQueryInProgress(false);
    });
  }, [selectionLayer, editableLayer, selectionPolygon, appliedFilters]);

  useEffect(() => {
    if (step === 0) {
      setEditableLayer(null);
    }
  }, [step]);

  useEffect(() => {
    if (step === 0) {
      setPolyLineSelected(false);
      setPolygonSelected(false);
      setSelectionPolygon(null);
      setSelectedFeatures(null);

      if (selectionLayer) selectionLayer.removeAll();
    } else if (step === 2) {
      setPolygonSelected(false);
      setPolygonSelected(false);
      displaySelection();
    }
  }, [step, selectionLayer, displaySelection, visibleLayers]);

  //useEffect(() => {
  //displaySelection()
  //}, [appliedFilters, displaySelection])

  const activatePolylineSelection = () => {
    onDrawStart(false);
    let action = draw.create("polyline", { mode: "click" });
    action.on(["vertex-add", "vertex-remove", "cursor-update"], (evt) => {
      const geometry = {
        type: "polyline",
        paths: evt.vertices,
        spatialReference: view.spatialReference,
      };

      selectionLayer.removeAll();
      selectionLayer.add(getGraphic(esriModules, geometry, polyLineSymbol));
    });

    //Sometimes draw doesn't stop, so do it with javascript event listener:
    endDrawOnDoubleClick(draw);
    action.on("draw-complete", () => {
      try {
        const selection = esriModules.geometryEngine.geodesicBuffer(
          selectionLayer.graphics.items[0].geometry,
          editableLayer.layerConfig.batchEditor.bufferFactor * view.scale,
          "meters"
        );

        selectionLayer.removeAll();
        selectionLayer.add(getGraphic(esriModules, selection, polygonSymbol));
        onDrawComplete(selection);
      } catch (e) {
        console.error(e);
      }
    });
  };

  const activatePolygonSelection = () => {
    onDrawStart(true);

    let action = draw.create("polygon", { mode: "click" });
    action.on(["vertex-add", "vertex-remove", "cursor-update"], (evt) => {
      let geometry;
      if (evt.vertices.length === 2) {
        geometry = {
          type: "polyline",
          paths: [evt.vertices],
          spatialReference: view.spatialReference,
        };
      } else {
        geometry = {
          type: "polygon",
          rings: evt.vertices,
          spatialReference: view.spatialReference,
        };
      }

      selectionLayer.removeAll();

      selectionLayer.add(getGraphic(esriModules, geometry, polygonSymbol));
    });

    //Sometimes draw doesn't stop, so do it with javascript event listener:
    endDrawOnDoubleClick(draw);
    action.on("draw-complete", () => {
      try {
        const geometry = selectionLayer.graphics.items[0].geometry;
        onDrawComplete(geometry);
      } catch (e) {
        console.log(e);
      }
    });
  };

  const drawCompleteListener = (draw) => {
    removeEventListener("dblclick", drawCompleteListener);
    draw.complete();
    setDrawActive(false);
  };

  const endDrawOnDoubleClick = (draw) => {
    addEventListener("dblclick", () => drawCompleteListener(draw));
  };

  const onDrawStart = (isPolygon) => {
    selectionLayer.removeAll();
    setPolygonSelected(isPolygon);
    setPolyLineSelected(!isPolygon);
    setDrawActive(true);
  };

  const onDrawComplete = (selection) => {
    setDrawActive(false);
    setPolygonSelected(false);
    setPolyLineSelected(false);
    setSelectionPolygon(selection);
    setStep(2);
  };

  const commitChanges = (expand, openSnackbar, t) => {
    setStep(3);

    applyFeatureUpdates(appliedUpdates, selectedFeatures);

    const mergedFeatures = mergeFeatures(
      selectedFeatures,
      editableLayer,
      esriModules
    );

    editableLayer
      .applyEdits({ addFeatures: mergedFeatures })
      .then(() => {
        editableLayer.refresh();
        view.whenLayerView(editableLayer).then((lv) => {
          reactiveUtils
            .once(() => !lv.updating)
            .then(() => {
              setStep(0);
              expand.collapse();
              openSnackbar(t("screen.message.batchEditorReady"), 15000);
            });
        });
      })
      .catch((err) => {
        openSnackbar(t("screen.message.error"), 15000);
        console.error("Batch editor error occured", err);
      });
  };

  const onAppliedFiltersValueChange = (referenceKey, value) => {
    const workingFilters = { ...appliedFiltersRef.current };
    workingFilters[referenceKey].value = value;
    setAppliedFilters(workingFilters);
  };

  const onAppliedUpdatesValueChange = (referenceKey, value) => {
    const workingUpdates = { ...appliedUpdatesRef.current };
    workingUpdates[referenceKey].value = value;
    setAppliedUpdates(workingUpdates);
  };

  const selectEditableLayer = (el) => {
    setEditableLayer(el);

    const bc = el.layerConfig.batchEditor;
    setAppliedUpdates(
      getFieldConfiguration(
        el,
        bc.batchFields,
        onAppliedUpdatesValueChange,
        false
      )
    );
    setAppliedFilters(
      getFieldConfiguration(
        el,
        bc.filterFields,
        onAppliedFiltersValueChange,
        true
      )
    );
  };

  const getCalciteButton = (
    key,
    label,
    icon,
    onClick,
    disabled,
    appearance = "solid"
  ) => {
    return disabled ? (
      <CalciteButton
        scale="s"
        key={key}
        iconStart={icon}
        disabled
        appearance={appearance}
      >
        {label}
      </CalciteButton>
    ) : (
      <CalciteButton
        scale="s"
        key={key}
        iconStart={icon}
        onClick={onClick}
        appearance={appearance}
      >
        {label}
      </CalciteButton>
    );
  };

  const addLayerToGroup = (layersByLabel, editedLayer, l) => {
    if (layersByLabel[editedLayer]) {
      layersByLabel[editedLayer].groupedIds.push(l.id);
    } else {
      const layerCandidates = view.map.layers.filter((layer) => {
        const lc = layer.layerConfig;
        return (
          layer.capabilities?.operations?.supportsAdd &&
          (lc?.id === editedLayer || lc?.alias === editedLayer)
        );
      });

      if (layerCandidates && layerCandidates.length > 0) {
        layersByLabel[editedLayer] = layerCandidates.at(0);
        layersByLabel[editedLayer].groupedIds = [l.id];
      } else {
        console.warn(
          "Batch editor configuration error on layer: " +
            l.layerConfig.alias +
            ". The edited layer is in-existent or not editable: " +
            editedLayer
        );
      }
    }
  };

  /**
   * Group visible layers for the layers that have the same batchEditor.editedLayer attribute
   */
  const groupLayers = (visibleLayers) => {
    const layersByLabel = {};
    visibleLayers
      .filter((l) => l.visible && l.layerConfig?.batchEditor?.editedLayer)
      .forEach((l) => {
        const editedLayer = l.layerConfig?.batchEditor?.editedLayer;

        if (Array.isArray(editedLayer)) {
          editedLayer.forEach((editedLayer) => {
            addLayerToGroup(layersByLabel, editedLayer, l);
          });
        } else {
          addLayerToGroup(layersByLabel, editedLayer, l);
        }
      });

    return layersByLabel;
  };

  const groupedLayers = useMemo(
    () => groupLayers(visibleLayers),
    [visibleLayers]
  );

  return (
    <Container className="esri-widget esri-widget--panel">
      <header>{t("screen.widget.BatchEditor.title")}</header>
      {(!selectionLayer && <StyledLoader scale="s" />) ||
        (step === 0 && (
          <Step key="step_0">
            <h3>{t("screen.widget.BatchEditor.selectLayer")}</h3>
            <CalciteList>
              {Object.values(groupedLayers)
                .filter((layer) => layer.visible)
                .map((layer) => (
                  <ListItemStyled
                    key={"li_" + layer.id}
                    label={layer?.getLayerTitle(t)}
                    onClick={() => selectEditableLayer(layer)}
                    selected={editableLayer && layer === editableLayer}
                  />
                ))}
            </CalciteList>

            {getCalciteButton(
              "next",
              t("screen.widget.BatchEditor.next"),
              "arrow-bold-right",
              () => setStep(1),
              !editableLayer
            )}
          </Step>
        )) ||
        (step === 1 && (
          <Step key="step_1">
            <h3>
              {t("screen.widget.BatchEditor.selectedLayer")}:{" "}
              {editableLayer?.getLayerTitle(t)}
            </h3>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <CalciteButton
                scale="s"
                iconStart="arrow-bold-left"
                onClick={() => setStep(0)}
                disabled={drawActive ? drawActive : undefined}
              >
                {t("screen.widget.BatchEditor.back")}
              </CalciteButton>

              {getCalciteButton(
                "pl",
                t("screen.widget.BatchEditor.polyline"),
                "freehand",
                () => activatePolylineSelection(),
                polygonSelected || polyLineSelected,
                polyLineSelected ? "solid" : "transparent"
              )}

              {getCalciteButton(
                "pg",
                t("screen.widget.BatchEditor.polygon"),
                "polygon",
                () => activatePolygonSelection(),
                polygonSelected || polyLineSelected,
                polygonSelected ? "solid" : "transparent"
              )}
            </div>
          </Step>
        )) ||
        (step === 2 && [
          <h3 key="step_2_h">
            {t("screen.widget.BatchEditor.selectedLayer")}:{" "}
            {editableLayer?.getLayerTitle(t)}
          </h3>,

          Object.keys(appliedFilters).length > 0 && (
            <Step key="step_2">
              <StepHead>{t("screen.widget.BatchEditor.filters")}</StepHead>
              <StepField>
                {Object.values(appliedFilters).map(
                  (appliedFilter) => appliedFilter.component
                )}
              </StepField>
            </Step>
          ),

          queryInProgress && (
            <CalciteProgress key="prog" type="indeterminate" />
          ),
          !queryInProgress && selectedFeatures?.length > 0 && (
            <Step key="updates_step">
              {selectedFeatures.length && (
                <StepField>
                  {Object.values(appliedUpdates).map(
                    (appliedUpdate) => appliedUpdate.component
                  )}
                </StepField>
              )}
            </Step>
          ),

          !queryInProgress && (
            <Step borderless key="commit_step">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <CalciteButton
                  scale="s"
                  iconStart="arrow-bold-left"
                  iconPosition="before"
                  onClick={() => setStep(1)}
                >
                  {t("screen.widget.BatchEditor.back")}
                </CalciteButton>

                {getCalciteButton(
                  "commit",
                  t("screen.widget.BatchEditor.commit"),
                  "check-circle",
                  () => commitChanges(expand, openSnackbar, t),
                  !selectedFeatures?.length
                )}
              </div>
            </Step>
          ),
        ]) ||
        (step === 3 && [
          <StyledLoader
            text={t("screen.widget.BatchEditor.updating")}
            key="batch-loader"
          />,
        ])}
    </Container>
  );
};

export default BatchEditor;
