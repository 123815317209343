import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;

export const CheckboxLabel = styled.label`
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  /* padding: 6px 8px; */
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3f3f3f;
  gap: 4px;

  &:not(:last-child) {
    /* margin-right: 6px; */
  }

  &:hover {
    /* background: rgba(0, 119, 255, 0.06); */
  }

  > span:first-child {
    margin: 1px;
  }

  > span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);

    &:first-child {
      position: relative;
      width: ${({ size }) => size || "14px"};
      height: ${({ size }) => size || "14px"};
      border-radius: ${({ borderRadius }) => borderRadius || "2px"};
      transform: scale(1);
      border: 1px solid #cccfdb;
      transition: all 0.2s ease;
      box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);

      svg {
        position: absolute;
        top: 2px;
        left: 1px;
        width: 10px;
        height: 8px;
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: ${({ size }) =>
          size ? `calc(${size} * 0.9)` : "16px"};
        stroke-dashoffset: ${({ size }) =>
          size ? `calc(${size} * 0.9)` : "16px"};
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
    }

    &:last-child {
      /* padding-left: 8px; */
      line-height: ${({ size }) => size || "18px"};
    }
  }

  &:hover span:first-child {
    border-color: ${({ color = "#07f" }) => color};
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  visibility: hidden;

  &:checked + ${CheckboxLabel} > span:first-child {
    background: ${({ color = "#07f" }) => color};
    border-color: ${({ color = "#07f" }) => color};
    animation: wave-4 0.4s ease;

    svg {
      stroke-dashoffset: 0;
    }
  }
`;

export const InlineSvg = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
`;
