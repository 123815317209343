import { loadModules } from "esri-loader";
import React, {
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  graphicsLayer,
  showpublicFieldName,
  view,
} from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import {
  clickEventOnFeature,
  getFeatureLatestUpdateDate,
  renderCell,
} from "../../../../utils/helper";
import { getLayerSymbology } from "../../../../utils/symbologies";
import { getField } from "../../../Report/new/helpers";
import Loader from "../Loader/Loader";

import { arrowDown16, arrowUp16 } from "@esri/calcite-ui-icons";
import useFilters from "../../../../hooks/useFilters";
import Input from "../../../Report/new/Input/Input";
import { SortButton } from "../../../SharedInformation/SharedInformation-styled";

import { useSelector } from "react-redux";
import ReportCard from "../../../SharedInformation/ReportCard";
import { FilterButton, Tooltip } from "../../../UI";
import { StyledSearchInputWrapper } from "../Editor-styled";
import { EditorContext, UpdateType } from "../EditorContextProvider";
import { LAYER_EFFECT } from "../EditorSwiper/EditorSwiper";
import {
  addLayerEffect,
  createFeatureGraphic,
  zoomToFeaturesExtent,
} from "../helpers";
import FilterGeometryButton from "./Filters/FilterGeometryButton";

import { FilterMapExtentIcon } from "../../../Icons";
import { getFeatureNameField } from "../EditorFields/EditorFields";
import { EditorSwiperContext } from "../EditorSwiper/EditorSwiperContextProvider/EditorSwiperContextProvider";
import { getStatus } from "../SituationalDateStatusIcon/SituationalDateStatusIcon";
import FilterStatusButton from "./Filters/FilterStatusButton";
import ShowPublicFilter from "./Filters/ShowPublicFilter";

function elementInViewport(element, { height = 0, width = 0 }) {
  const bounding = element.getBoundingClientRect();

  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= width &&
    bounding.bottom <= height
  ) {
    console.log("Element is in the viewport!");
  } else {
    console.log("Element is NOT in the viewport!");
  }
}

const compareFeaturesByDate = (a, b, sort = "descending", fieldName) => {
  const editDateA = getFeatureLatestUpdateDate(a, fieldName);
  const editDateB = getFeatureLatestUpdateDate(b, fieldName);

  if (!editDateA && !editDateB) {
    return 0;
  }

  if (!editDateA) {
    return sort === "ascending" ? 1 : -1;
  }
  if (!editDateB) {
    return sort === "ascending" ? -1 : 1;
  }

  if (sort === "ascending") {
    return editDateA > editDateB ? -1 : 1;
  } else {
    return editDateA < editDateB ? -1 : 1;
  }
};

const getFeatureGraphics = async ({
  editableLayer,
  batchUpdateFeatures,
  features,
}) => {
  const graphics = [];
  const newBatchUpdateFeatures = [];

  //map for quick lookup
  const featureMap = new Map(
    features.map((feat) => [feat.getObjectId(), feat])
  );

  for (let item of batchUpdateFeatures) {
    const objectId = item.feature.getObjectId();
    const newFeature = featureMap.get(objectId);

    if (newFeature) {
      const graphic = await createFeatureGraphic(newFeature, editableLayer);

      newBatchUpdateFeatures.push({
        feature: newFeature,
        graphic,
      });
      graphics.push(graphic);
    }
  }

  return {
    graphics,
    newBatchUpdateFeatures,
  };
};

const EditableFeaturesList = ({
  editableLayer,
  handleSelectFeature,
  handleNext,
}) => {
  // const [show, setShow] = useState(false);
  // const [showMessage, setShowMessage] = useState(false);
  // const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  // const [filterDropdownOptions, setFilterDropdownOptions] = useState([]);
  // const [filterFieldName, setFilterFieldName] = useState("");

  // const [updateStatusFilters, setUpdateStatusFilters] = useState([]);

  // const [sortType, setSortType] = useState("descending");
  // const [filterByExtent, setFilterByExtent] = useState(false);
  // const [filterGeometry, setFilterGeometry] = useState(null);
  // const [filterGeometryActive, setFilterGeometryActive] = useState(false);

  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  const {
    filterByExtent,
    setFilterByExtent,
    filterGeometry,
    setFilterGeometry,
    filterGeometryActive,
    setFilterGeometryActive,
    sortType,
    setSortType,
    updateStatusFilters,
    setUpdateStatusFilters,
    showPublicActive,
    setShowPublicActive,
    setFilteredFeatures,
    filteredFeatures,
    setAppliedFilters,
    appliedFilters,
    showSearch,
    setShowSearch,
    searchText,
    setSearchText,
  } = useContext(EditorSwiperContext);
  const [popupId, setPopupId] = useState(null);

  const { filters, setFilters, setAndApplyFilters } = useFilters({
    isActive: false,
    config,
  });
  const initialFilters = useRef(filters);
  const {
    highlightFeature,
    resetHighlightFeature,
    batchUpdateFeatures,
    setBatchUpdateFeatures,
    updateType,
    activeColor,
    editableFeatureList: features,
    setEditableFeatureList: setFeatures,
    editorListRefreshCount,
    highlightSymbol,
    setEditableLayerLoaded,
    editableLayerLoaded,
    queryFields,
  } = useContext(EditorContext);
  const { activeModule } = useSelector((state) => state);
  const { lastCycleUpdate, cycleUpdateDueDate, cycleUpdateFrequency } =
    useSelector((state) => state.cycleManagerReducer);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  // const [highlightedFeatures, setHighlightedFeatures] = useState([]);
  // const cardRef = useRef();
  const abortControllerRef = useRef(null);
  const [statusOptions, setStatusOptions] = useState({});
  const zoomAbortRef = useRef(null);
  const featureRefs = useRef(new Map());

  useEffect(() => {
    if (!filters || !initialFilters.current) return;

    const moduleFilters = filters[activeModule];

    const initialModuleFilters = initialFilters.current[activeModule];

    const arraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    };

    const nameField = getFeatureNameField(editableLayer);

    Object.keys(moduleFilters).forEach((key) => {
      if (nameField === key) return;
      const initialFiltersEqualWithModuleFilters = arraysEqual(
        moduleFilters[key],
        initialModuleFilters[key]
      );

      if (!initialFiltersEqualWithModuleFilters) {
        initialFilters.current[activeModule][key] = moduleFilters[key];
      }
    });
  }, [filters]);

  useEffect(() => {
    if (!editableLayer) return;
    resetHighlightFeature();
    let layerEffectLayers = [editableLayer];
    if (editableLayer.layerConfig.titleLabel === "roadsSituational") {
      const roadsLayers = view.map.layers.filter(
        (l) => l?.layerConfig?.titleLabel === "roads"
      );
      if (roadsLayers.length > 0) {
        layerEffectLayers.push(...roadsLayers.toArray());
      }
    }

    addLayerEffect(layerEffectLayers);
    if (
      editableLayer.fields.some((f) => f.name === showpublicFieldName) &&
      showPublicActive
    ) {
      let _filters = JSON.parse(JSON.stringify(filters));
      _filters[activeModule][showpublicFieldName] = [1];

      setAndApplyFilters(_filters);
    }

    return () => {
      setAndApplyFilters(initialFilters.current);

      // setFilterGeometry(null);
      // setFilterGeometryActive(false);
      // setEditableLayerLoaded(false);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [editableLayer]);

  useEffect(() => {
    let clickHandler;
    // view.popupEnabled = false;
    // view.popupEnabledCustom = false;
    //disable clicking on the feature if filter geometry is set
    if (filterGeometryActive) return;

    clickHandler = view.on("click", (event) => {
      try {
        const batchEditorWidget = view.ui.find("BatchEditor");
        if (
          (batchEditorWidget && batchEditorWidget.expanded) ||
          event.button === 2
        ) {
          return;
        }
      } catch (err) {
        console.log(err);
      }

      view.hitTest(event, { include: view.map.layers }).then((res) => {
        const firstRes = res?.results
          .filter(
            (res) =>
              res.graphic.layer === editableLayer ||
              res.graphic.sourceLayer === editableLayer
          )
          .at(0);

        if (!firstRes) return;

        const clickedLayer = firstRes.layer;
        const feature = firstRes.graphic;
        if (!feature) return;

        const graphic = feature.clone();

        let center = graphic.geometry;

        if (center?.type === "polyline") {
          center = event.mapPoint;
        }

        graphic.geometry = feature.originalGeometry || feature.geometry;

        if (clickedLayer?.layerConfig) {
          graphic.sourceLayer = clickedLayer;
        } else {
          graphic.sourceLayer = editableLayer;
        }

        if (graphic.sourceLayer?.layerConfig?.clickable) {
          view.openPopup({
            location: center,
            features: [graphic],
          });
          return;
        }

        if (clickedLayer?.layerConfig) {
          handleSelectFeature(clickedLayer, graphic, handleNext);
        } else {
          handleSelectFeature(editableLayer, graphic, handleNext);
        }
      });
      event.stopPropagation();
    });
    return () => {
      clickHandler.remove();
    };
  }, [
    editableLayer,
    highlightSymbol,
    handleNext,
    handleSelectFeature,
    filterGeometryActive,
  ]);

  const filterTimer = useRef(0);
  useEffect(() => {
    if (!showSearch) return;
    if (editableLayer.layerConfig.titleTemplate) {
      const res = editableLayer.layerConfig.titleTemplate.replace(
        "{feature.",
        ""
      );
      const fieldName = res.replace("}", "");
      let _filters = JSON.parse(JSON.stringify(filters));
      let filterArray = [];
      filterArray.push(searchText);
      if (searchText.length > 0) {
        editableLayer.effect = undefined;
      } else if (highlightFeature || batchUpdateFeatures.length > 0) {
        editableLayer.effect = LAYER_EFFECT;
      }

      setAppliedFilters({ ...appliedFilters, [fieldName]: filterArray });

      _filters[activeModule][fieldName] = filterArray;
      clearTimeout(filterTimer.current);
      filterTimer.current = setTimeout(() => {
        setAndApplyFilters(_filters);
      }, 500);
    }
  }, [searchText]);

  useEffect(() => {
    if (features.length === 0 && editableLayer) {
      queryLayer();
    }
  }, [editableLayer]);

  useEffect(() => {
    let watcher, timer, layerViewUpdate, abortController;
    const layerOutFields = editableLayer.outFields;
    if (!filterGeometry) {
      if (filteredFeatures.length > 0) {
        assignAvailableStatuses(features);
        setFilteredFeatures([]);
        graphicsLayer.removeAll();
      }
      return;
    }

    setLoading(true);
    const fetchLayerView = async (layerView) => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        try {
          if (abortController) {
            abortController.abort();
          }
          abortController = new AbortController();

          const query = layerView.createQuery();

          query.geometry = filterGeometry;
          query.spatialRelationship = "intersects";
          query.returnGeometry = true;
          query.outFields = queryFields;

          const result = await layerView.queryFeatures(query, {
            signal: abortController.signal,
          });
          // Load the required modules
          const [webMercatorUtils] = await loadModules([
            "esri/geometry/support/webMercatorUtils",
          ]);

          if (!Array.isArray(result.features)) {
            throw new Error("No features found");
          }

          // Project the geometries of the features
          const insideFeatures = await Promise.all(
            result.features.map(async (feature) => {
              feature.geometry = webMercatorUtils.webMercatorToGeographic(
                feature.geometry
              );
              return feature;
            })
          );

          setFilteredFeatures(insideFeatures);
          if (filterGeometryActive) {
            let batchFeatures = insideFeatures.map((feat) => ({
              feature: feat,
              graphic: null,
            }));

            if (updateStatusFilters.length > 0 && batchFeatures.length > 0) {
              batchFeatures = batchFeatures.filter((item) =>
                updateStatusFilters.includes(item.feature.updateStatus)
              );
            }

            getFeatureGraphics({
              batchUpdateFeatures: batchFeatures,
              features: insideFeatures,
              editableLayer,
            }).then((res) => {
              const { newBatchUpdateFeatures, graphics } = res;

              graphicsLayer.removeAll();
              graphicsLayer.addMany(graphics);

              setBatchUpdateFeatures(newBatchUpdateFeatures);
            });
          }
          assignAvailableStatuses(insideFeatures);
          return insideFeatures;
        } catch (err) {
          console.log(err);
          setFilteredFeatures([]);
          return null;
        } finally {
          setLoading(false);
        }
      }, 500);
    };

    editableLayer.outFields = queryFields;
    view
      .whenLayerView(editableLayer)
      .then((layerView) => {
        fetchLayerView(layerView);

        layerViewUpdate = layerView.watch("updating", (isUpdating) => {
          clearTimeout(timer);
          abortController?.abort();
          if (!isUpdating) {
            fetchLayerView(layerView);
            layerViewUpdate?.remove();
          }
        });

        watcher = editableLayer.watch("definitionExpression", () => {
          const update = layerView.watch("updating", (isUpdating) => {
            if (!isUpdating) {
              fetchLayerView(layerView);
              update.remove();
            }
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      watcher?.remove();
      layerViewUpdate?.remove();
      if (abortController) {
        abortController.abort();
      }
      clearTimeout(timer);
      if (editableLayer) {
        editableLayer.outFields = layerOutFields;
      }
    };
  }, [filterGeometry]);

  useEffect(() => {
    const watcher = editableLayer.watch("definitionExpression", () => {
      queryLayer();
    });

    return () => {
      watcher?.remove();
      clearTimeout(timer.current);
      if (zoomAbortRef.current) {
        zoomAbortRef.current?.abort();
      }

      if (abortControllerRef.current) {
        abortControllerRef.current?.abort();
      }
    };
  }, [filterByExtent, editableLayer, filterGeometry]);

  useEffect(() => {
    let handler;
    let timer;
    clearTimeout(timer);
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      if (filterByExtent) {
        handler = reactiveUtils.when(
          () => !!view?.stationary,
          (stationary) => {
            clearTimeout(timer);

            if (!filterByExtent || !stationary) return;
            // queryLayer();
            timer = setTimeout(() => {
              setFilterGeometry(view.extent);
            }, 300);
          },
          { initial: true }
        );
      }
    });
    return () => {
      clearTimeout(timer);
      handler?.remove();
    };
  }, [filterByExtent, editableLayer]);

  useEffect(() => {
    if (batchUpdateFeatures.length > 0) {
      getFeatureGraphics({ batchUpdateFeatures, features, editableLayer }).then(
        (res) => {
          const { newBatchUpdateFeatures, graphics } = res;
          if (graphicsLayer.graphics.length < newBatchUpdateFeatures.length) {
            graphicsLayer.removeAll();
            graphicsLayer.addMany(graphics);

            setBatchUpdateFeatures(batchUpdateFeatures);
          }
        }
      );
    } else {
      graphicsLayer.removeAll();
    }
  }, [features]);

  const assignAvailableStatuses = useCallback(
    async (features) => {
      if (
        updateType === UpdateType.situational &&
        lastCycleUpdate &&
        cycleUpdateDueDate &&
        cycleUpdateFrequency
      ) {
        const availableStatuses = {};
        for (let feature of features) {
          const status = await getStatus({
            feature,
            config,
            lastUpdate: lastCycleUpdate,
            dueDate: cycleUpdateDueDate,
            filterShowPublic: false,
            cycleUpdateFrequency,
          });
          feature.updateStatus = status;
          if (availableStatuses[status]) {
            availableStatuses[status] += 1;
          } else {
            availableStatuses[status] = 1;
          }
        }
        setStatusOptions(availableStatuses);
      }
    },
    [updateType, lastCycleUpdate, cycleUpdateDueDate]
  );

  const timer = useRef(0);

  const queryLayer = async () => {
    setEditableLayerLoaded(false);
    clearTimeout(timer.current);
    if (zoomAbortRef.current) {
      zoomAbortRef.current.abort();
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    timer.current = setTimeout(async () => {
      try {
        setLoading(true);

        const query = {
          where: editableLayer.definitionExpression,
          spatialRelationship: "intersects",
          returnGeometry: true,
          outFields: queryFields,
          num: 200,
        };

        const res = await editableLayer.queryFeatures(query, { signal });

        if (Array.isArray(res.features)) {
          assignAvailableStatuses(res.features);

          startTransition(() => {
            setFeatures(res.features);
          });

          if (filterGeometryActive && res.features.length > 0) {
            let batchFeatures = res.features.map((feat) => ({
              feature: feat,
              graphic: null,
            }));

            if (updateStatusFilters.length > 0 && batchFeatures.length > 0) {
              batchFeatures = batchFeatures.filter((item) =>
                updateStatusFilters.includes(item.feature.updateStatus)
              );
            }

            getFeatureGraphics({
              batchUpdateFeatures: batchFeatures,
              features: res.features,
              editableLayer,
            }).then((res) => {
              const { newBatchUpdateFeatures, graphics } = res;

              graphicsLayer.removeAll();
              graphicsLayer.addMany(graphics);

              setBatchUpdateFeatures(newBatchUpdateFeatures);
            });
          }

          const symbology = getLayerSymbology(editableLayer, config) || {};

          let fieldName;
          if (symbology.colorMap) {
            if (symbology.colorMap.field) {
              fieldName = symbology.colorMap.field;
            } else if (symbology.colorMap.fields) {
              fieldName = symbology.colorMap.fields[0];
            }
          }

          const field = editableLayer.fields.find(
            (field) => field.name === fieldName
          );

          if (field) {
            const options = getField(editableLayer, fieldName).map(
              (codedValue) => ({
                name: renderCell(field, codedValue.code, t, config),
                code: codedValue.code,
                color: symbology.colorMap.default[codedValue.code],
              })
            );

            // setFilterDropdownOptions(options);
            // setFilterFieldName(fieldName);
          }
        }
        if (res.features.length >= 200 && !filterByExtent) {
          setFilterByExtent(true);
        }
        // zoomToFeaturesExtent(res.features);
        setEditableLayerLoaded(true);
      } catch (err) {
        if (err.name !== "AbortError") {
          console.log(err);
        }
      } finally {
        setLoading(false);
      }
    }, 250);
  };

  const handleClick = async (feature, e) => {
    // if (batchUpdateFeatures.length === 1) {
    //   setBatchUpdateFeatures([]);
    //   graphicsLayer.removeAll();
    // } else {
    //   // batchUpdateFeatures.forEach((item) => {
    //   //   item.graphic.visible =
    //   //     item.feature.getObjectId() === feature.getObjectId();
    //   // });

    // }

    if (graphicsLayer) {
      graphicsLayer.removeAll();
    }
    view.closePopup();
    setBatchUpdateFeatures([]);

    setTimeout(async () => {
      setSelectedFeatureId(feature.getObjectId());
      clickEventOnFeature(feature, { showPopup: false, duration: 500 }).then(
        (res) => {}
      );

      document.body.style.cursor = "wait";
      await handleSelectFeature(editableLayer, feature);
      document.body.style.cursor = "default";
      handleNext();
    }, 0);
  };

  const handleSwitchChange = useCallback(() => {
    const isActive = !filterByExtent;

    if (isActive && filterGeometryActive) {
      setFilterGeometryActive(false);
      setFilterGeometry(null);
      setBatchUpdateFeatures([]);
    }

    if (!isActive) {
      setFilterGeometry(null);
    }

    setFilterByExtent(isActive);
  }, [filterByExtent, filterGeometryActive]);

  const handleSearch = useCallback(
    (e) => {
      const { value } = e.target;
      setSearchText(value);
    },
    [features]
  );

  const sortedFeatures = useMemo(() => {
    const fieldName =
      updateType === UpdateType.situational
        ? currAsOfDateFieldName
        : asOfDateFieldName;

    const featuresToSort = filterGeometry ? filteredFeatures : features;

    if (updateStatusFilters.length > 0) {
      return featuresToSort
        .filter((feature) => {
          const status = feature.updateStatus;
          return updateStatusFilters.includes(status);
        })
        .sort((a, b) => compareFeaturesByDate(a, b, sortType, fieldName));
    }

    return featuresToSort.sort((a, b) =>
      compareFeaturesByDate(a, b, sortType, fieldName)
    );
  }, [
    features,
    sortType,
    updateType,
    updateStatusFilters,
    filteredFeatures,
    filterGeometry,
  ]);

  const handleSort = useCallback(() => {
    const sort = sortType === "descending" ? "ascending" : "descending";
    setSortType(sort);
  }, [sortType]);

  const updateFeatures = useCallback(
    (newFeatures) => {
      zoomToFeaturesExtent(newFeatures.map((item) => item.feature));
      setBatchUpdateFeatures(newFeatures);
    },
    [setBatchUpdateFeatures, features]
  );

  const handleShiftKeySelection = useCallback(
    async (firstSelectedFeature, index) => {
      const firstIndex = sortedFeatures.findIndex(
        (feat) => feat.getObjectId() === firstSelectedFeature.getObjectId()
      );
      const lastIndex = index;

      const start = Math.min(firstIndex, lastIndex);
      const end = Math.max(firstIndex, lastIndex);

      const newFeatures = [...batchUpdateFeatures];

      for (let i = start; i <= end; i++) {
        const feat = sortedFeatures[i];
        const featIndex = newFeatures.findIndex(
          (item) => item.feature.getObjectId() === feat.getObjectId()
        );

        if (featIndex === -1) {
          try {
            const graphic = await createFeatureGraphic(feat, editableLayer);
            graphicsLayer.add(graphic);
            newFeatures.push({
              feature: feat,
              graphic,
            });
          } catch (error) {
            console.error("Error creating feature graphic:", error);
          }
        }
      }

      updateFeatures(newFeatures);
    },
    [sortedFeatures, batchUpdateFeatures, editableLayer, updateFeatures]
  );

  const handleSingleSelection = useCallback(
    async (feature, checked) => {
      try {
        if (!checked) {
          const newFeatures = [...batchUpdateFeatures];
          // setHighlightedFeatures(newFeatures);

          try {
            const graphic = await createFeatureGraphic(feature, editableLayer);
            graphicsLayer.add(graphic);
            newFeatures.push({
              feature,
              graphic,
            });

            updateFeatures(newFeatures);
          } catch (error) {
            console.error("Error creating feature graphic:", error);
          }
        } else {
          const newFeatures = batchUpdateFeatures.filter((item) => {
            if (item.feature.getObjectId() === feature.getObjectId()) {
              graphicsLayer.remove(item.graphic);
            }
            return item.feature.getObjectId() !== feature.getObjectId();
          });

          updateFeatures(newFeatures);
        }
      } catch (error) {
        console.error("Error handling single selection:", error);
      }
    },
    [
      batchUpdateFeatures,
      editableLayer,
      graphicsLayer,
      // setHighlightedFeatures,
      updateFeatures,
    ]
  );

  const handleInputChange = useCallback(
    async (e, feature, checked) => {
      const index = sortedFeatures.findIndex(
        (feat) => feat.getObjectId() === feature.getObjectId()
      );

      const firstSelectedFeature = sortedFeatures.find((feat) =>
        batchUpdateFeatures.some(
          (item) => item.feature.getObjectId() === feat.getObjectId()
        )
      );

      if (e.shiftKey && firstSelectedFeature) {
        await handleShiftKeySelection(firstSelectedFeature, index);
      } else {
        await handleSingleSelection(feature, checked);
      }
    },
    [
      sortedFeatures,
      batchUpdateFeatures,
      handleShiftKeySelection,
      handleSingleSelection,
    ]
  );

  useEffect(() => {
    if (batchUpdateFeatures.length > 0) {
      editableLayer.effect = LAYER_EFFECT;
    } else {
      editableLayer.effect = undefined;
    }
  }, [batchUpdateFeatures]);

  const [visibleCount, setVisibleCount] = useState(10);
  const listRef = useRef(null);

  const handleScroll = useCallback(() => {
    const list = listRef.current;
    if (!list) return;

    const scrollTop = list.scrollTop;
    const scrollHeight = list.scrollHeight;
    const clientHeight = list.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      if (visibleCount < sortedFeatures.length) {
        setVisibleCount((prevCount) => prevCount + 10);
      }
    }
  }, [sortedFeatures, visibleCount]);

  useEffect(() => {
    const list = listRef.current;

    if (list) {
      list.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (list) {
        list.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, visibleCount]);

  useEffect(() => {
    let isMounted = true;
    const incrementVisibleCount = () => {
      if (isMounted) {
        setVisibleCount((prevCount) => {
          const newCount = prevCount + 10;
          if (newCount < sortedFeatures.length && newCount <= 50) {
            requestAnimationFrame(incrementVisibleCount);
          }
          return newCount;
        });
      }
    };
    requestAnimationFrame(incrementVisibleCount);
    return () => {
      isMounted = false;
    };
  }, [sortedFeatures]);

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0, 0);
    }
  }, [editableLayer]);

  const batchFeaturesIds = useMemo(() => {
    return new Set(
      batchUpdateFeatures.map((item) => item.feature.getObjectId())
    );
  }, [batchUpdateFeatures]);

  const onCreateFilterGeometry = useCallback((geometry) => {
    setFilterGeometry(geometry);
  }, []);

  const onUpdateFilterGeometry = useCallback((geometry) => {
    setFilterGeometry(geometry);
  }, []);

  const onDeleteFilterGeometry = useCallback(() => {
    if (batchUpdateFeatures.length > 0) {
      setBatchUpdateFeatures([]);
    }
    setFilterGeometry(null);
    setFilterGeometryActive(false);
  }, [batchUpdateFeatures]);

  const onFilterActiveChange = useCallback(
    (isActive) => {
      if (!isActive) {
        setFilterGeometry(null);
        setBatchUpdateFeatures([]);
      }

      if (isActive && filterByExtent) {
        setFilterByExtent(false);
        setFilterGeometry(null);
      }
      setFilterGeometryActive(isActive);
    },
    [filterByExtent]
  );

  const onUpdateStatusFilterChange = useCallback((selected) => {
    setUpdateStatusFilters(selected);
  }, []);

  useEffect(() => {
    if (updateType !== UpdateType.situational) return;

    let lv;
    view
      .whenLayerView(editableLayer)
      .then((layerView) => {
        lv = layerView;

        const newFeatures = features.filter((item) =>
          updateStatusFilters.includes(item.updateStatus)
        );

        if (updateStatusFilters.length === 0) {
          setTimeout(() => {
            try {
              if (lv.filter) {
                lv.filter.where = undefined;
              }
            } catch (err) {
              console.log(err);
            }
          }, 100);
        } else {
          setTimeout(() => {
            try {
              if (lv.filter) {
                lv.filter.where = `${
                  editableLayer.objectIdField
                } IN (${newFeatures
                  .map((item) => item.getObjectId())
                  .join(",")})`;
              } else {
                lv.filter = {
                  where: `${editableLayer.objectIdField} IN (${newFeatures
                    .map((item) => item.getObjectId())
                    .join(",")})`,
                };
              }
            } catch (err) {
              console.log(err);
            }
          }, 100);
        }
      })
      .catch((err) => console.log(err));

    return () => {
      if (lv?.filter?.where) {
        lv.filter.where = undefined;
      }
    };
  }, [updateStatusFilters]);

  const handleShowPublicFilter = useCallback(() => {
    try {
      let _filters = JSON.parse(JSON.stringify(filters));

      if (filters[activeModule][showpublicFieldName].length > 0) {
        _filters[activeModule][showpublicFieldName] = [];
        setShowPublicActive(false);
        setAppliedFilters((prev) => ({ ...prev, [showpublicFieldName]: [] }));
      } else {
        _filters[activeModule][showpublicFieldName] = [1];
        setShowPublicActive(true);
        setAppliedFilters((prev) => ({ ...prev, [showpublicFieldName]: [1] }));
      }
      // initialFilters.current = _filters;
      setAndApplyFilters(_filters);
    } catch (error) {
      console.log(error);
    }
  }, [filters, activeModule]);

  const isShowPublicActive = useMemo(() => {
    try {
      if (Array.isArray(filters[activeModule][showpublicFieldName])) {
        return filters[activeModule][showpublicFieldName].includes(1);
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }, [filters]);

  const hasShowPublicField = useMemo(() => {
    if (!editableLayer) return false;
    return editableLayer.fields.some((f) => f.name === showpublicFieldName);
  }, [editableLayer]);

  const scrollToFeature = useCallback(
    (featureId) => {
      const index = sortedFeatures.findIndex(
        (feature) => feature.getObjectId() === featureId
      );

      if (index === -1) return;

      // Ensure the feature is within the visible count
      if (index >= visibleCount) {
        setVisibleCount(index + 10); // Add buffer to ensure feature is loaded
      }

      setTimeout(() => {
        requestAnimationFrame(() => {
          try {
            const featureElement = featureRefs.current.get(featureId);
            const listElement = listRef.current;

            if (featureElement && listElement) {
              // Calculate element's position relative to the list
              const elementTop = featureElement.offsetTop;
              const elementHeight = featureElement.offsetHeight;
              const listHeight = listElement.clientHeight;

              // Calculate scroll position to center the element
              const scrollPosition =
                elementTop - listHeight / 2 + elementHeight / 2;

              listElement.scrollTo({
                top: scrollPosition,
                behavior: "smooth",
              });
            }
          } catch (error) {
            console.log(error);
          }
        });
      }, 100);
    },
    [sortedFeatures, visibleCount]
  );

  useEffect(() => {
    let watchHandler, timer, selectedFeatureHandler;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      watchHandler = reactiveUtils.watch(
        () => view.popup.visible,
        (visible) => {
          if (visible) {
            requestAnimationFrame(() => {
              const id = view.popup.selectedFeature?.getObjectId();
              scrollToFeature(id);
              setPopupId(id);
            });
            // setFeature(view.popup.selectedFeature);
            selectedFeatureHandler?.remove();
            selectedFeatureHandler = reactiveUtils.watch(
              () => view.popup.selectedFeature,
              (graphic) => {
                if (!graphic) return;
                clearTimeout(timer);
                timer = setTimeout(() => {
                  requestAnimationFrame(() => {
                    const id = graphic?.getObjectId();
                    scrollToFeature(id);
                    setPopupId(id);
                  });
                }, 250);
              }
            );
          } else {
            selectedFeatureHandler?.remove();
            setPopupId(null);
          }
        },
        { initial: true }
      );
    });

    return () => {
      watchHandler?.remove();
      selectedFeatureHandler?.remove();
      clearTimeout(timer);
    };
  }, [scrollToFeature]);

  return (
    <>
      {/* start of filters */}
      <div
        style={{
          position: "sticky",
          top: 0,
          background: "#fff",
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "4px",
            position: "relative",
            gap: 20,
            minHeight: 52,
            transition: "all 0.3s",
            padding: "10px 10px 10px 28px",
            boxSizing: "border-box",
          }}
        >
          {updateType === UpdateType.situational && (
            <FilterStatusButton
              onChange={onUpdateStatusFilterChange}
              color={activeColor}
              resetDeps={editableLayer}
              options={statusOptions}
            />
          )}
          <FilterGeometryButton
            filterGeometry={filterGeometry}
            isActive={filterGeometryActive}
            onCreate={onCreateFilterGeometry}
            onUpdate={onUpdateFilterGeometry}
            onDelete={onDeleteFilterGeometry}
            onActiveChange={onFilterActiveChange}
            // disabled={filterByExtent}
          />
          <StyledSearchInputWrapper show={showSearch}>
            <Input
              borderColor="#8a8a8a"
              noMargin
              onChange={handleSearch}
              label={`${t(
                "screen.widget.common.filterSearch.label"
              )} ${editableLayer.getLayerTitle(t)}`}
              placeholder={t("screen.widget.common.filterSearch.placeholder")}
              value={searchText}
              className="feature__search-input"
            >
              <div
                onClick={() => {
                  setSearchText("");
                  setTimeout(() => {
                    setShowSearch(false);
                  }, 100);
                }}
                style={{
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  padding: 2,
                  display: "flex",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="#939393"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="14px"
                  width="14px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
                </svg>
              </div>
            </Input>
          </StyledSearchInputWrapper>
          {!showSearch && (
            <Tooltip
              content={t("screen.widget.common.filterByExtent")}
              showOnHover
              // disabled={filterGeometryActive}
            >
              <FilterButton
                isActive={filterByExtent}
                onClick={handleSwitchChange}
                // disabled={filterGeometryActive}
              >
                <FilterMapExtentIcon />
              </FilterButton>
            </Tooltip>
          )}
          {!showSearch && (
            <Tooltip content={t("screen.widget.common.sortByDate")} showOnHover>
              <SortButton onClick={handleSort}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill={sortType === "descending" ? activeColor : "#cdcbcb"}
                  >
                    <path d={arrowDown16} />
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill={sortType === "ascending" ? activeColor : "#cdcbcb"}
                  >
                    <path d={arrowUp16} />
                  </svg>
                </div>
              </SortButton>
            </Tooltip>
          )}
          {hasShowPublicField && (
            <ShowPublicFilter
              t={t}
              onClick={handleShowPublicFilter}
              isActive={isShowPublicActive}
            />
          )}
          {!showSearch && (
            <FilterButton
              style={{
                marginLeft: "auto",
                width: "34px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100px",
              }}
              // activeColor={activeColor}
              svgSize={"22px"}
              onClick={() => {
                const input = document.querySelector(".feature__search-input");
                if (input) {
                  input.focus();
                }

                setShowSearch(true);
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M337.509 305.372h-17.501l-6.571-5.486c20.791-25.232 33.922-57.054 33.922-93.257C347.358 127.632 283.896 64 205.135 64 127.452 64 64 127.632 64 206.629s63.452 142.628 142.225 142.628c35.011 0 67.831-13.167 92.991-34.008l6.561 5.487v17.551L415.18 448 448 415.086 337.509 305.372zm-131.284 0c-54.702 0-98.463-43.887-98.463-98.743 0-54.858 43.761-98.742 98.463-98.742 54.7 0 98.462 43.884 98.462 98.742 0 54.856-43.762 98.743-98.462 98.743z"></path>
              </svg>
            </FilterButton>
          )}
        </div>
      </div>
      {/* end of filters */}
      <div
        ref={listRef}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          paddingTop: "8px",
          paddingLeft: "8px",
          flex: "1",
          overflow: "auto",
          height: "100%",
        }}
      >
        {loading && (
          <Loader position="absolute" color={activeColor} bg="#eeeeeed4" />
        )}

        {selectedFeatureId !== null && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              zIndex: 2,
            }}
          />
        )}
        {sortedFeatures.slice(0, visibleCount).map((feature) => {
          const featureId = feature.getObjectId();
          const key = Math.random() + featureId;

          const isSelected = batchFeaturesIds.has(featureId);
          return (
            <div
              key={featureId}
              ref={(el) => featureRefs.current.set(featureId, el)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                position: "relative",
              }}
            >
              <div
                onClick={(e) => handleInputChange(e, feature, isSelected)}
                style={{ maxWidth: "calc(100%)", width: "100%" }}
              >
                <ReportCard
                  key={feature.getObjectId()}
                  selectedFeature={feature}
                  onEdit={(e) => {
                    e.stopPropagation();
                    handleClick(feature);
                  }}
                  isSelected={isSelected}
                  showImages={false}
                  fieldsType={updateType}
                  showUpdate={true}
                  style={{
                    borderColor: featureId === popupId ? config.opsColor : "",
                    borderRadius: 8,
                    transition: "border 0.3s",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default EditableFeaturesList;
