import React, { useCallback } from "react";
import {
  getIconValueDefinitions,
  getLayerSymbology,
} from "../../../../../utils/symbologies";
import { svgToBase } from "../../../../Icons/layerSvg/clusterAssets";
import { StyledList, StyledListItem } from "../../Editor-styled";

const Annotations = ({ layer, config, t, handleSelection }) => {
  const renderFields = useCallback(() => {
    const symbology = getLayerSymbology(layer, config);

    const iconValueDefinition = getIconValueDefinitions(symbology, layer, 1, t);

    return iconValueDefinition.map((item) => ({
      icon: svgToBase(item.icon, symbology.defaultColor),
      iconName: item.icon,
      label: item.label,
      fieldName: "type_ann",
      code: Number.isFinite(Number(item.value)) ? Number(item.value) : 0,
    }));
  }, [layer, config, t]);

  return (
    <StyledList style={{ paddingTop: "8px" }}>
      {renderFields().map((item) => (
        <StyledListItem
          key={item.label}
          onClick={() => {
            handleSelection(item);
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
            }}
          >
            <img
              alt={item.label}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={item.icon}
            />
          </div>
          <span>{item.label}</span>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default Annotations;
