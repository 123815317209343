import { LoaderOverlay, LoaderWrapper } from "./Loader-styled";

const Loader = ({
  position,
  text,
  size,
  color,
  bg,
  loaderStyles = {},
  textStyles = {},
  ...props
}) => {
  return (
    <LoaderOverlay position={position} bg={bg} {...props}>
      <LoaderWrapper style={loaderStyles} size={size} color={color}>
        <svg viewBox="-75 -75 150 150">
          <circle cx="0" cy="0" r="37.5"></circle>
        </svg>
      </LoaderWrapper>
      <span style={textStyles}>{text}</span>
    </LoaderOverlay>
  );
};

export default Loader;
