import styled from "styled-components";

//border color #d4d4d4
//bg #f3f3f3

//circle bc #949494

export const StyledSwitchWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;

  .react-switch-bg {
    box-shadow: 0 0 0 1px #d4d4d4;
    background: ${({ checked }) =>
      checked ? " var(--opsColor) !important" : ""};
  }

  .react-switch-handle {
    //box-shadow: 0 0 0 2px #949494;
  }

  //input[type=checkbox]:checked ~ .react-switch-handle {
  //  margin: 10px !important;
  //}

  span {
    font-size: ${({ small }) => (small ? "10px" : "12px")};
    color: #4c4c4c;
  }
`;
