import { loadModules } from "esri-loader";
import {
  ORS_SURFACE_TYPES,
  ORS_TO_LOGIE_ROAD_TYPES,
  ORS_WAY_TYPES,
} from "../../../../../../../data/constants";
import { selectionLineSymbol } from "../../../RoadsCoordinates";

const divideRouteByExtras = async ({ originalRoute, values, modules }) => {
  const { routeStartIndex, routeEndIndex, originalPath, spatialReference } =
    originalRoute;
  const { Polyline, geometryEngineAsync } = modules;

  const result = {};
  for (const surfaceValue of values) {
    const [sStart, sEnd, surfaceId] = surfaceValue;

    // Check if surface segment overlaps with current country segment
    if (sStart < routeEndIndex && sEnd > routeStartIndex) {
      // Calculate overlap
      const overlapStart = Math.max(routeStartIndex, sStart);
      const overlapEnd = Math.min(routeEndIndex, sEnd);
      const distance = await measureExtrasSegment({
        pathIndexes: [overlapStart, overlapEnd],
        originalPath,
        spatialReference,
        Polyline,
        geometryEngineAsync,
      });

      if (result[surfaceId] !== undefined) {
        result[surfaceId] += distance;
      } else {
        result[surfaceId] = distance;
      }
    }
  }

  return result;
};

const measureExtrasSegment = async ({
  pathIndexes,
  originalPath,
  spatialReference,
  Polyline,
  geometryEngineAsync,
}) => {
  let result = 0;
  try {
    const [start, end] = pathIndexes;
    const path = originalPath.slice(start, end + 1);
    const polyline = new Polyline({
      paths: [path],
      spatialReference: spatialReference,
    });

    result = await geometryEngineAsync.geodesicLength(polyline, "kilometers");
  } catch (err) {
    console.log(err);
  }

  return result;
};

const divideRoutesByCountries = async (routeGraphics) => {
  const [Polyline, geometryEngineAsync, Graphic] = await loadModules([
    "esri/geometry/Polyline",
    "esri/geometry/geometryEngineAsync",
    "esri/Graphic",
  ]);

  const newGraphics = [];
  const countries = {};
  for (const graphic of routeGraphics) {
    let fclass = 0;
    const { orsFeature, cities } = graphic;

    const {
      extras: { countryinfo, waytypes, surface },
    } = orsFeature.attributes;
    if (countryinfo && Array.isArray(countryinfo.values)) {
      // First process countries
      for (const countryValue of countryinfo.values) {
        const [startIndex, endIndex, countryId] = countryValue;
        const originalPath = orsFeature.geometry.paths[0];
        // Initialize country object if it doesn't exist
        const extrasInfo = {
          surface: {},
          waytypes: {},
        };

        // Process surface segments for this country
        if (waytypes && Array.isArray(waytypes.values)) {
          extrasInfo.waytypes = await divideRouteByExtras({
            originalRoute: {
              routeStartIndex: startIndex,
              routeEndIndex: endIndex,
              originalPath,
              spatialReference: orsFeature.spatialReference,
            },
            values: waytypes.values,
            modules: {
              Polyline,
              geometryEngineAsync,
            },
          });

          const wayTypeKeys = Object.keys(extrasInfo.waytypes)
            .map(Number)
            .filter((key) => key > 0);

          if (wayTypeKeys.length > 0) {
            fclass = Math.min(...wayTypeKeys);
          }
        }

        // Process surface segments for this country
        if (surface && Array.isArray(surface.values)) {
          extrasInfo.surface = await divideRouteByExtras({
            originalRoute: {
              routeStartIndex: startIndex,
              routeEndIndex: endIndex,
              originalPath,
              spatialReference: orsFeature.spatialReference,
            },
            values: surface.values,
            modules: {
              Polyline,
              geometryEngineAsync,
            },
          });
        }

        const countryRoute = new Polyline({
          paths: [originalPath.slice(startIndex, endIndex + 1)],
          spatialReference: orsFeature.spatialReference,
        });

        const routeDistance = await geometryEngineAsync.geodesicLength(
          countryRoute,
          "kilometers"
        );

        const graphic = new Graphic({
          id: "route-country",
          geometry: countryRoute,
          symbol: selectionLineSymbol,
          attributes: {
            fclass: ORS_TO_LOGIE_ROAD_TYPES[fclass],
          },
          cities,
          extrasInfo,
        });
        newGraphics.push(graphic);

        countries[countryId] = {
          geometry: new Polyline({
            paths: [originalPath.slice(startIndex, endIndex + 1)],
            spatialReference: orsFeature.spatialReference,
          }),
          routeDistance,
          extras: extrasInfo,
        };
      }
    }
  }

  return newGraphics;

  // return countries;
};

const generateOrsComment = ({ editableLayer, extrasInfo, t }) => {
  try {
    let commentgenen = "";

    const commentField = editableLayer.fields.find(
      (f) => f.name === "commentgenen"
    );
    const { waytypes = {}, surface = {} } = extrasInfo || {};

    if (commentField) {
      const maxLength = commentField.length;

      Object.keys(waytypes).forEach((key, index) => {
        const name = ORS_WAY_TYPES[key].name;
        const newLine =
          index === 0 ? `${t("layer.fieldAlias.fclass.title")}: ` : "";
        const wayTypeText = `${name} (${
          Math.round(waytypes[key] * 100) / 100
        } km)`;

        // Check if adding new content would exceed maxLength
        if ((commentgenen + newLine + wayTypeText).length <= maxLength) {
          if (index === 0) {
            commentgenen += newLine;
            commentgenen += `${wayTypeText}`;
          } else {
            commentgenen += `, ${wayTypeText}`;
          }
        }
      });

      Object.keys(surface).forEach((key, index) => {
        const name = ORS_SURFACE_TYPES[key].name;
        const newLine =
          index === 0 ? `\n\n${t("layer.fieldAlias.roadsurface.title")}: ` : "";
        const surfaceText = `${name} (${
          Math.round(surface[key] * 100) / 100
        } km)`;

        // Check if adding new content would exceed maxLength
        if ((commentgenen + newLine + surfaceText).length <= maxLength) {
          if (index === 0) {
            commentgenen += newLine;
            commentgenen += `${surfaceText}`;
          } else {
            commentgenen += `, ${surfaceText}`;
          }
        }
      });
    }

    return commentgenen;
  } catch (err) {
    console.log(err);
  }
};

export { divideRoutesByCountries, generateOrsComment };
