import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useClickOutside from "../../../../hooks/useClickOutside";
import { ConfigContext } from "../../../../utils/ConfigContext";
import {
  ROLE_EDITOR,
  createObserver,
  formatDate,
  parseTextWithLinks,
} from "../../../../utils/helper";
import CustomSwitch from "../../../CustomSwitch/CustomSwitch";
import {
  CellRendererWrapper,
  StyledCellCheckboxWrapper,
} from "../../FeatureTable-styled";

export const CellRendererSwitch = ({ layer, ...params }) => {
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);

  const handleUpdate = useCallback(
    (toggled) => {
      const { node, colDef } = params;
      const value = toggled ? 1 : 2;

      const feature = params.data.feature;
      feature.attributes[colDef.field] = value;
      node.setDataValue(colDef.field, value);
    },
    [params]
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <CustomSwitch
        id={`${Math.random()}-${layer.id}-${
          params.data.feature.attributes[layer.objectIdField]
        }`}
        labelA={
          params.node.data.showToggleLabels
            ? t(`layer.domain.showpublic.2`)
            : null
        }
        labelB={
          params.node.data.showToggleLabels
            ? t(`layer.domain.showpublic.1`)
            : null
        }
        onChange={handleUpdate}
        checked={params.data[params.colDef.field] === 1}
        small
        disabled={config.role !== ROLE_EDITOR}
      />
    </div>
  );
};

//when we have url as value to show a tag
export const CellRenderer = ({ layer, ...params }) => {
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const spanRef = useRef();
  const observerRef = useRef();

  useEffect(() => {
    return () => {
      observerRef.current?.disconnect();
    };
  }, []);

  useClickOutside(spanRef, () => {
    if (show) {
      setTimeout(() => {
        setShow(false);
        params.node.setRowHeight();
        params.api.onRowHeightChanged();
      }, 50);
    }
  });

  let timer;
  const handleButton = useCallback(
    (e) => {
      if (!spanRef.current) return;
      setShow(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        e.target.style.whiteSpace = "";
        params.node.setRowHeight(spanRef.current.offsetHeight);
        params.api.onRowHeightChanged();
      }, 100);
    },
    [setShow]
  );

  let observerTimer;
  const withNode = (ref) => {
    spanRef.current = ref;
    if (!params.value) return;

    if (!ref) return;
    clearTimeout(observerTimer);
    observerTimer = setTimeout(() => {
      observerRef.current = createObserver(() => {
        if (!!ref && ref?.scrollWidth > ref.parentNode?.scrollWidth - 22) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });

      observerRef.current.observe(ref);
    }, 1000);
  };

  return (
    <CellRendererWrapper
      ref={(ref) => withNode(ref)}
      style={{
        display: "block",
        lineHeight: 2,
        whiteSpace: !show ? "nowrap" : "",
        overflow: !show ? "hidden" : "",
        textOverflow: !show ? "ellipsis" : "",
        paddingRight: 4,
      }}
    >
      {parseTextWithLinks(params.value)}
      {showButton && !show && (
        <button
          onClick={handleButton}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: 20,
            height: 20,
            display: "flex",
            padding: 4,
            marginTop: "2px",
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="16px"
            width="16px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="m112 184 144 144 144-144"
            ></path>
          </svg>
        </button>
      )}
    </CellRendererWrapper>
  );
};

export const CellDateRenderer = ({ ...params }) => {
  const { config } = useContext(ConfigContext);
  const renderValue = useCallback(() => {
    if (typeof params.value === "object") {
      return "";
    } else {
      return params.value ? formatDate(config, params.value) : "";
    }
  }, [params.value]);

  return renderValue();
};

export const CellDomainRenderer = ({ ...params }) => {
  const color = useMemo(() => {
    if (params.data.rowColorMap[params.colDef.field]) {
      return (
        params.data.rowColorMap[params.colDef.field][
          params.value === "-1" ? "NULL" : params.value
        ] ?? ""
      );
    }

    return "";
  }, [params]);

  if (color && params.valueFormatted && !!params.valueFormatted.trim()) {
    return (
      <span
        style={{
          padding: "2px 8px",
          background: color,
          color: color ? "#fff" : "#000",
          borderRadius: "100px",
          fontSize: 10,
          fontWeight: 400,
        }}
      >
        {params.valueFormatted}
      </span>
    );
  } else {
    return <span>{params.valueFormatted}</span>;
  }
};

export const CellFeatureSelectRenderer = ({ layer, ...params }) => {
  return (
    <StyledCellCheckboxWrapper>
      <input type="radio" checked={params.value} readOnly />
    </StyledCellCheckboxWrapper>
  );
};
