import { ConopsIconAttributeMap } from "../../../esri/graphicLayerUtils/Conops";
import { EpamIconAttributeMap } from "../../../esri/graphicLayerUtils/Epam";
import {
  calculateUpToDateFeaturesPercentage,
  subtractTimeFromDate,
} from "../../../redux/reducers/cycleManager";
import { currAsOfDateFieldName, showpublicFieldName } from "../../../utils/API";
import { formatIntlDateTime } from "../../../utils/helper";
import DefaultHeader from "./Checklist/ChecklistTable/Headers/DefaultHeader";
import IconHeader from "./Checklist/ChecklistTable/Headers/IconHeader/IconHeader";

/**
 * Determines if a feature is outdated based on the provided arguments.
 *
 * @param {Object} args - The arguments object.
 * @param {Object} args.feature - The feature object containing attributes.
 * @param {number} args.startDateTime - The start date and time for the comparison.
 * @param {number} args.endDateTime - The end date and time for the comparison.
 * @param {string} args.cycleUpdateFrequency - The end date and time for the comparison.
 * @param {boolean} [args.filterShowPublic=true] - Flag to filter by public visibility.
 * @param {string} [args.dateField=currAsOfDateFieldName] - The field name for the date attribute.
 * @returns {boolean} - Returns true if the feature is outdated, otherwise false.
 */
export const isOutdated = (args) => {
  const {
    feature,
    startDateTime,
    endDateTime,
    filterShowPublic = true,
    dateField = currAsOfDateFieldName,
    cycleUpdateFrequency = "",
  } = args;

  const updateDateTime = feature.attributes[dateField];

  let isPublic = true;
  if (showpublicFieldName in feature.attributes && filterShowPublic) {
    isPublic = feature.attributes[showpublicFieldName] === 1;
  }

  const now = new Date().getTime();

  let isOutdated = updateDateTime == null;

  if (updateDateTime != null) {
    if (startDateTime >= endDateTime || now >= endDateTime) {
      const reverseDueDate = subtractTimeFromDate(now, cycleUpdateFrequency);
      isOutdated = updateDateTime >= reverseDueDate && updateDateTime <= now;
    } else {
      isOutdated =
        (updateDateTime >= startDateTime && updateDateTime <= endDateTime) ||
        updateDateTime > endDateTime;
    }
  }

  return isPublic && (updateDateTime == null || !isOutdated);
};

export const calculateMissingFeatures = (
  features,
  lastDateTime,
  dueDateTime,
  cycleUpdateFrequency
) => {
  const frequency =
    typeof cycleUpdateFrequency === "string"
      ? cycleUpdateFrequency.toLowerCase()
      : "";

  const missingFeatures = features.filter((f) => {
    return isOutdated({
      feature: f,
      startDateTime: lastDateTime,
      endDateTime: dueDateTime,
      cycleUpdateFrequency: frequency,
    });
  });

  return missingFeatures;
};

/**
 * Maps situational fields to their corresponding icons and updates the column headers with these icons.
 *
 * @param {Array} columns - The array of column objects to be updated.
 * @param {Array} newSituationalFields - The array of situational field objects containing field names.
 * @param {Object} ConopsIconAttributeMap - A mapping of field names to their corresponding icon attributes
 * @returns {Array} The updated array of column objects with icon headers.
 */
export const iconsToHeaders = (columns, newSituationalFields, layer) => {
  const iconsNamesMap = {};
  let attributesMap = {};

  if (layer?.layerConfig?.isConops) {
    attributesMap = ConopsIconAttributeMap;
  } else if (layer?.layerConfig?.isEpam) {
    attributesMap = EpamIconAttributeMap;
  }

  newSituationalFields.forEach((field) => {
    const iconName = attributesMap[field.name];
    if (iconName) {
      iconsNamesMap[field.name] = iconName;
    }
  });

  columns.forEach((column) => {
    if (iconsNamesMap[column.field]) {
      column.headerComponent = (props) => {
        const icon = iconsNamesMap[props.column.colId];
        return <IconHeader iconName={icon.iconName} {...props} />;
      };
    } else {
      column.headerComponent = (props) => {
        <DefaultHeader layer={layer} {...props} />;
      };
    }
  });

  return columns;
};

export function formatCycleTime(time, languageCode = "en") {
  if (!time) return "";

  const utcDate = new Date(time);
  const utcNow = new Date();
  const diffTime = Math.abs(utcNow.getTime() - utcDate.getTime());
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
  const hasTimeLeft = utcNow.getTime() - utcDate.getTime() < 0;

  if (diffDays === 1) {
    return hasTimeLeft ? "tomorrow" : "yesterday";
  }

  if (diffDays > 1) {
    const options = {
      timeZone: "UTC",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const formattedDate = formatIntlDateTime({
      languageCode: languageCode,
      options: options,
      date: utcDate,
    });

    return formattedDate;
  }

  let result = "";
  if (diffHours > 0) {
    result += `${diffHours} h`;
  }
  if (diffMinutes > 0) {
    if (result) {
      result += `, `;
    }
    result += `${diffMinutes} m`;
  }
  if (!result) {
    result = "just now";
  } else {
    result += hasTimeLeft ? "" : " ago";
  }
  return result;
}

export const generateLayerUpdate = ({
  features,
  lastCycleUpdate,
  cycleUpdateDueDate,
  cycleUpdateFrequency,
}) => {
  const outdatedFeatures = calculateMissingFeatures(
    features,
    lastCycleUpdate,
    cycleUpdateDueDate,
    cycleUpdateFrequency
  );
  const layerUpdate = {
    features: features,
    outdated: outdatedFeatures,
    percent: 0,
  };

  const layerPercent = calculateUpToDateFeaturesPercentage({
    percent: layerUpdate,
  });

  layerUpdate.percent = layerPercent;

  return layerUpdate;
};
