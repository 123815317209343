import {
  isdeletedFieldName,
  opidFieldName,
  showpublicFieldName,
  view,
} from "../../utils/API";
import { getConfigISO, ROLE_EDITOR } from "../../utils/helper";

/**
 * Apply filters on all layers. This must be called when a layer is loaded
 * the filter has 3 components:
 * - original definition expression: this is a filtering condition defined on layer level
 * - iso3 (and optionally iso3a, iso3b, iso3c) filtering: this condition is only applied to layers that
 *  set the filtered=true parameter and the op configuration has one or more iso3 codes
 * - custom filters: we apply the default values from the filterFields operational level parameter upon layer loading
 *  if the filter widget is active, the filters can be changed runtime as well
 */
export const applyCurrentFilters = (filters, config) => {
  view?.map?.layers.forEach((layer) => {
    applyCurrentFiltersOnLayer(layer, filters, config);
  });
};

/**
 * Apply filters on layer level, see @applyCurrentFilters for the rules
 */
export const applyCurrentFiltersOnLayer = (layer, filters, config) => {
  if (!layer.fields || !layer?.layerConfig?.filtersApplied) return;

  const whereCondition = composeWhereCondition(layer, filters);
  view
    .whenLayerView(layer)
    .then((lv) => {
      applyWhereCondition(lv, whereCondition, config);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const composeWhereCondition = (layer, filters) => {
  let whereConditions = [];
  filters &&
    Object.entries(filters).forEach(([fieldName, value]) => {
      if (layer.fields.filter((field) => field.name === fieldName).length === 0)
        return;

      if (Array.isArray(value)) {
        const fieldConditions = value.map(
          (value) => `${getFieldCond(fieldName, value)}`
        );
        whereConditions.push(queryConcat(fieldConditions, Logic.OR));
      } else whereConditions.push(getFieldCond(fieldName, value));
    });

  return queryConcat(whereConditions, Logic.AND);
};

const getFieldCond = (fieldName, value) => {
  if (value === undefined) return "";
  else if (typeof value === "number" && isFinite(value))
    return `${fieldName}=${value}`;
  else if (typeof value === "string" && value.trim().length === 0) return "";
  else if (value === null) return `${fieldName} is null`;
  else if (value.includes("<") || value.includes(">") || value.includes("="))
    return `${fieldName} ${value}`;
  else return `lower(${fieldName}) LIKE '%${value.toLowerCase()}%'`;
};

/**
 * Apply a where condition on the layer. For clustered views we use the view filter, otherwise use the definitionExpression of the layer
 */
const applyWhereCondition = (layerView, whereCondition, config) => {
  let trimmedWhere = whereCondition.trim();
  const isFilteringCondition = whereCondition.trim().length > 0;
  const layer = layerView.layer;
  if (!layer) return;
  //Save the original definition expression
  if (layer.layerConfig.originalDefinitionExpression === undefined)
    layer.layerConfig.originalDefinitionExpression = layer.definitionExpression;

  let isoCondition = "";
  if (layer.layerConfig) {
    isoCondition = composeIsoAndIsDeletedWhereCondition(layer, config);
    if (isoCondition === layer.layerConfig.originalDefinitionExpression)
      isoCondition = "";
  }

  const newDefinitionExpression = queryConcat(
    [
      layer.layerConfig.originalDefinitionExpression,
      isoCondition,
      trimmedWhere,
    ],
    Logic.AND
  );
  if (layer.definitionExpression !== newDefinitionExpression)
    layer.definitionExpression = newDefinitionExpression;
};

/**
 * Composes an ISO query condition for a layer.
 * @param {__esri.FeatureLayer} layer - The layer object to query.
 * @param {string|string[]} iso3 - The ISO3 code(s) to filter by.
 * @param {string[]} isoFields - The field names to check for ISO codes.
 * @returns {string} The composed query condition.
 */
const composeIsoQueryCondition = (layer, iso3, isoFields) => {
  const queryElements = layer.fields
    .filter((field) => isoFields.includes(field.name))
    .map((field) => {
      if (Array.isArray(iso3)) {
        const fieldQuery = iso3.map((iso3) => `${field.name} = '${iso3}'`);
        return queryConcat(fieldQuery, Logic.OR);
      } else {
        return `${field.name} = '${iso3}'`;
      }
    });

  return (
    (layer.layerConfig?.inverted ? "not " : "") +
    queryConcat(queryElements, Logic.OR)
  );
};

/**
 * Composes an OPID query condition for a layer.
 * @param {__esri.FeatureLayer} layer - The ArcGIS JS API FeatureLayer.
 * @param {string} configId - The configuration ID to filter by.
 * @returns {string|null} The composed query condition or null if no matching field is found.
 */
const composeOpidQueryCondition = (layer, configId) => {
  const queryElements = layer.fields
    .filter((field) => opidFieldName === field.name)
    .map((field) => `${field.name} = '${configId}'`);

  return queryElements.length > 0
    ? (layer.layerConfig?.inverted ? "not " : "") +
        queryConcat(queryElements, Logic.OR)
    : null;
};

const isoFields = ["iso3", "iso3a", "iso3b", "iso3c"];

/**
 * Get query expression for the ISO code or codes. This query expression will be used for layer filtering
 * @returns iso3 = 'SOM' like expressions
 */
export const composeIsoAndIsDeletedWhereCondition = (layer, config) => {
  /**
   * Array to store query conditions for layer filtering.
   * This array will be populated with various conditions based on ISO codes,
   * configuration settings, and other filtering criteria.
   * @type {string[]}
   */
  const queryCond = [];
  const iso3 = getConfigISO(config);
  if (iso3 && layer.layerConfig.filtered) {
    const queryString = composeIsoQueryCondition(layer, iso3, isoFields);
    queryCond.push(queryString);
    // const queryElements = layer.fields
    //     .filter((field) => isoFields.includes(field.name))
    //     .map((field) => {
    //         if (Array.isArray(iso3)) {
    //             const fieldQuery = iso3.map(
    //                 (iso3) => `${field.name} = '${iso3}'`
    //             );
    //             return queryConcat(fieldQuery, Logic.OR);
    //         } else {
    //             return `${field.name} = '${iso3}'`;
    //         }
    //     });

    // queryCond.push(
    //     (layer.layerConfig?.inverted ? "not " : "") +
    //         queryConcat(queryElements, Logic.OR)
    // );
  }

  if (layer.layerConfig.filteredForOpid) {
    const queryString = composeOpidQueryCondition(layer, config.id);
    if (queryString) {
      queryCond.push(queryString);
    }

    // const queryElements = layer.fields
    //     .filter((field) => opidFieldName === field.name)
    //     .map((field) => {
    //         return `${field.name} = '${config.id}'`;
    //     });

    // if (queryElements.length > 0)
    //     queryCond.push(
    //         (layer.layerConfig?.inverted ? "not " : "") +
    //             queryConcat(queryElements, Logic.OR)
    //     );
  }

  if (iso3 && layer.layerConfig.filteredForOpidAndIso) {
    /**
     * @type {string[]}
     */
    const combinedCondition = [];

    const queryStringIso = composeIsoQueryCondition(layer, iso3, isoFields);
    combinedCondition.push(queryStringIso);

    const queryStringOpId = composeOpidQueryCondition(layer, config.id);
    if (queryStringOpId) {
      combinedCondition.push(queryStringOpId);
    }

    queryCond.push(`(${queryConcat(combinedCondition, Logic.OR)})`);
  }

  if (config.role !== ROLE_EDITOR) {
    const isDeletedField = layer.fields.filter(
      (field) => field.name === isdeletedFieldName
    )[0];
    if (isDeletedField)
      queryCond.push(
        `${isdeletedFieldName} IS NULL or ${isdeletedFieldName} <> 1`
      );

    const showpublicField = layer.fields.filter(
      (field) => field.name === showpublicFieldName
    )[0];
    if (showpublicField) queryCond.push(`${showpublicFieldName} = 1`);
  }

  return queryConcat(queryCond, Logic.AND);
};

const Logic = {
  AND: " and ",
  OR: " or ",
};

/**
 * Create a query string by concatenating elements with a logical operator (and, or)
 * @param {string[]} queryElements - Array of query elements to concatenate.
 * @param {string} logic - The logical operator to use ('AND' or 'OR').
 * @returns {string} The concatenated query string.
 */
const queryConcat = (queryElements, logic) => {
  let res = "";
  for (const key in queryElements) {
    const val = queryElements[key];
    if (!val || val.trim().length === 0) continue;

    if (res.length > 0) res += logic;

    res += "(" + val + ")";
  }

  return res;
};
