import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useTranslation } from "react-i18next";
import { graphicsLayer, view } from "../../../../../../../utils/API";
import { ConfigContext } from "../../../../../../../utils/ConfigContext";
import { dangerColor } from "../../../../../../../utils/Theme";
import { SearchInputWrapper } from "../../../../Editor-styled";
import { EditorContext } from "../../../../EditorContextProvider";
import CalculateRouteButton from "../../EnterCoordinates/CalculateRouteButton";
import SegmentButton from "../../EnterCoordinates/SegmentButton";
import SearchInput from "./SearchInput";

const PointIcon = ({ isFilled }) => (
  <svg
    style={{
      width: "40px",
      height: "40px",
      position: "absolute",
      left: "-9px",
      top: "12px",
    }}
    stroke="#fff"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="#939393"
      strokeWidth={3}
      d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"
    />
    <path
      fill={isFilled ? dangerColor : "#939393"}
      d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"
    />
  </svg>
);

const AddIcon = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="#8a8a8ab5"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="22px"
      width="22px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M346.5 240H272v-74.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V240h-74.5c-8.8 0-16 6-16 16s7.5 16 16 16H240v74.5c0 9.5 7 16 16 16s16-7.2 16-16V272h74.5c8.8 0 16-7.2 16-16s-7.2-16-16-16z"></path>
      <path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path>
    </svg>
  );
};

const DotsIcon = () => {
  return (
    <svg
      stroke=""
      fill="#939393"
      strokeWidth="0"
      viewBox="0 0 256 256"
      height="16px"
      width="16px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M112,60a16,16,0,1,1,16,16A16,16,0,0,1,112,60Zm16,52a16,16,0,1,0,16,16A16,16,0,0,0,128,112Zm0,68a16,16,0,1,0,16,16A16,16,0,0,0,128,180Z" />
    </svg>
  );
};

const SearchCoordinates = ({
  addPointToMap,
  removeSearchPoint,
  handleCreateRoute,
  setActiveIndex,
  activeIndex,
  color,
  calculatedPoints,
  setManualDrawPoint,
  disableInputs,
  setMatchBaselineRoads,
  matchBaselineRoads,
}) => {
  const {
    setClickedPoints,
    clickedPoints,
    setShowRoadsEditor,
    activeColor,
    setAddedPoints,
  } = useContext(EditorContext);
  const [showOtherCoordinates, setShowOtherCoordinates] = useState(true);
  // const [showNoResults, setShowNoResults] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [suggestions, setSuggestions] = useState([]);
  // const [search, setSearch] = useState(null);
  const [editedItems, setEditedItems] = useState([]);
  const [message, setMessage] = useState({
    show: false,
    index: -1,
    removeInput: false,
  });
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const isFocused = useRef(false);
  // const ref = useRef();
  const searchWrapper = useRef();

  // Core search functionality
  // const {
  //   search,
  //   value,
  //   loading,
  //   suggestions,
  //   activeCoordinates,
  //   selectedResultKey,
  //   showCoordinates,
  //   showDropdown,
  //   showNoResults,
  //   showSuggestions,
  //   suggestionsLength,
  //   resetSearch,
  //   handleInputChange: handleSearchInputChange,
  //   handleFocus: onFocus,
  //   handleSuggestionClick,
  //   handleCoordinates,
  //   handleKeyPress,
  //   locateCoordinates,
  //   suggestionRef,
  //   setActiveCoordinates,
  // } = useSearchWidget({
  //   view,
  //   // initialValue: '',
  //   // onResultSelect,
  //   // onInputChange,
  //   resultGraphicEnabled: false,
  //   onOutsideClick: () => {
  //     return new Promise((resolve) => {
  //       setTimeout(() => {
  //         if (!isFocused.current) {
  //           setFocusedIndex(-1);
  //           resetSearch();
  //         }
  //         resolve(!isFocused.current); // Prevents the default outside click behavior
  //       }, 100);
  //     });
  //   },
  //   config,
  //   t,
  // });

  // useSearchLayers({
  //   search,
  //   view,
  //   t,
  //   config,
  //   layersToAdd: [],
  // });

  const handleAdd = useCallback(
    (index = undefined) => {
      const newElement = {
        id: `${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
        graphic: undefined,
        coordinate: [],
        searchable: true,
      };

      if (index !== undefined) {
        setActiveIndex(index + 1);
        const newPoints = [
          ...clickedPoints.slice(0, index + 1),
          newElement,
          ...clickedPoints.slice(index + 1, clickedPoints.length),
        ];

        const currPoint = clickedPoints[index];
        const nextPoint = clickedPoints[index + 1];
        const routeId = `route-${currPoint.coordinate.join(
          "|"
        )}-${nextPoint.coordinate.join("|")}`;

        const graphicsToRemove = [];
        graphicsLayer.graphics.forEach((g) => {
          if (g.id) {
            if (g.id === routeId) {
              graphicsToRemove.push(g);
            }
            if (g.id.includes(`line-${currPoint.coordinate.join("|")}`)) {
              graphicsToRemove.push(g);
            }

            if (
              Array.isArray(g.intersectedPoints) &&
              g.intersectedPoints.includes(currPoint.coordinate.join("|"))
            ) {
              graphicsToRemove.push(g);
            }
          }
        });

        graphicsLayer.removeMany(graphicsToRemove);

        setClickedPoints(newPoints);
      } else {
        // setActiveIndex(clickedPoints.length);
        const newPoints = [...clickedPoints, newElement];
        setClickedPoints(newPoints);
      }
    },
    [clickedPoints]
  );

  const handleSubmitMessage = useCallback(
    (index, removeInput) => {
      removeSearchPoint(index, removeInput);
      // setSuggestions([]);
      // setLoading(false);
      setMessage({
        show: false,
        index: -1,
        removeInput: false,
      });
    },
    [removeSearchPoint]
  );

  const resetInput = useCallback(
    (index, removeInput = false) => {
      // const point = clickedPoints[index];
      // const pointGraphics = getPointGraphics({graphicsLayer, point});
      //
      // if (pointGraphics.length > 0 && (index > 0 && index < clickedPoints.length - 1)) {
      //   setMessage(({
      //     show: true,
      //     index,
      //     removeInput
      //   }));
      // } else {
      //   handleSubmitMessage(index, removeInput);
      // }

      handleSubmitMessage(index, removeInput);
      // resetSearch();
    },
    [removeSearchPoint, handleSubmitMessage, clickedPoints]
  );

  const handleInputChange = useCallback(
    async (e, index) => {
      if (!e.target.value) {
        resetInput(index);
        return;
      }
    },
    [resetInput]
  );

  const handleLocateCoordinates = useCallback(
    (point) => {
      addPointToMap({ geometry: point }, activeIndex);
    },
    [activeIndex, addPointToMap]
  );

  const handleSelectSuggestion = useCallback(
    (label, result, coordinates, search) => {
      const { feature, ...rest } = result;
      if (!coordinates) return;

      let targetFeature = null;
      // Check if it's an ArcGIS feature (has attributes and geometry.x/y)
      if (feature?.attributes) {
        targetFeature = {
          name: rest.text,
          geometry: {
            longitude: feature.geometry.x,
            latitude: feature.geometry.y,
          },
        };
      }
      // Check if it's a GeoJSON feature (has properties and geometry.coordinates)
      else if (feature?.geometry?.coordinates) {
        targetFeature = {
          name: feature.properties.name || rest.text,
          geometry: {
            longitude: feature.geometry.coordinates[0],
            latitude: feature.geometry.coordinates[1],
          },
          city: undefined,
        };

        if (feature.properties.city || feature.properties.locality) {
          targetFeature.city = {
            name: feature.properties.city || feature.properties.locality,
            iso3: feature.properties.country_a,
          };
        }
      }
      // Fallback for other formats
      else {
        targetFeature = {
          name: rest.text,
          geometry: coordinates,
        };
      }

      search.clear();
      addPointToMap(targetFeature, activeIndex);
      view.goTo({
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 8,
      });
    },
    [activeIndex, addPointToMap]
  );

  const handleFocus = useCallback(
    (e, index) => {
      const point = clickedPoints[index];

      if (point.graphic) {
        view.goTo(point.graphic);
      }
      isFocused.current = true;
      setFocusedIndex(index);
    },
    [clickedPoints]
  );

  const handleRemoveInput = useCallback(
    (index) => {
      setActiveIndex(-1);
      resetInput(index, true);
    },
    [resetInput, activeIndex]
  );

  useEffect(() => {
    if (searchWrapper.current) {
      const nodeList =
        searchWrapper.current.querySelectorAll("input.search-input");
      if (nodeList.length > 0) {
        setTimeout(() => {
          nodeList[0].focus();
        }, 600);
      }
    }
  }, []);

  const onCalculateRoute = useCallback(() => {
    setActiveIndex(-1);
    handleCreateRoute();
  }, [handleCreateRoute]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        overflow: "hidden",
        flex: 1,
      }}
    >
      {/* {message.show && (
        <Message
          title="Are your sure?"
          subtitle="Routes to/from this point will be removed"
          onCancel={() => {
            setMessage({
              show: false,
              index: -1,
              removeInput: false,
            });
          }}
          onSubmit={() =>
            handleSubmitMessage(message.index, message.removeInput)
          }
          color={config.opsColor}
        />
      )} */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            overflow: "auto",
            padding: "0px 8px",
            flex: 1,
          }}
          ref={searchWrapper}
        >
          {clickedPoints.map((item, index) => {
            let nextPoint, isRoutable;
            if (index < clickedPoints.length) {
              nextPoint = clickedPoints[index + 1];
              isRoutable = nextPoint?.searchable && item?.searchable;
            }

            return (
              <SearchInputWrapper
                style={{ position: "relative" }}
                key={item.id}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 43,
                    left: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {clickedPoints.length - 1 !== index && (
                    <>
                      <DotsIcon width="16px" height="16px" />
                      <div
                        onClick={() => {
                          handleAdd(index);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <AddIcon width="18px" height="18px" />
                      </div>
                    </>
                  )}
                  {index < clickedPoints.length - 1 && (
                    <DotsIcon width="16px" height="16px" />
                  )}
                </div>
                <div style={{ width: 22, height: 22 }}>
                  <PointIcon isFilled={item.coordinate.length > 0} />
                </div>
                <SearchInput
                  point={item}
                  isActive={activeIndex === index}
                  className="search-input"
                  onRemove={() => {
                    setActiveIndex(index);
                  }}
                  placeholder={
                    index === 0
                      ? t(
                          "screen.widget.Editor.manager.routeSelection.routeApi.inputPlaceholders.default"
                        )
                      : index < clickedPoints.length - 1
                      ? t(
                          "screen.widget.Editor.manager.routeSelection.routeApi.inputPlaceholders.intermediatePoint"
                        )
                      : t(
                          "screen.widget.Editor.manager.routeSelection.routeApi.inputPlaceholders.default"
                        )
                  }
                  onFocus={(e) => {
                    setActiveIndex(index);
                    handleFocus(e, index);
                  }}
                  handleSelectSuggestion={handleSelectSuggestion}
                  onLocateCoordinates={handleLocateCoordinates}
                  onInputChange={(e) => handleInputChange(e, index)}
                  activeColor={activeColor}
                  disabled={disableInputs}
                />
                {item.coordinate.length > 0 &&
                  nextPoint?.coordinate?.length > 0 && (
                    <SegmentButton
                      point={item}
                      nextPoint={nextPoint}
                      editedItems={editedItems}
                      isRoutable={isRoutable}
                      index={index}
                      calculatedPoints={calculatedPoints}
                      // onClick={() => {
                      //   const newClickedPoints = [...clickedPoints];
                      //   const newItem = { ...item };
                      //   newItem.edited = true;
                      //   newClickedPoints[index] = newItem;
                      //   setEditedItems((prev) => prev.concat(index));

                      //   setManualDrawPoint(item);
                      //   setShowRoadsEditor(true);
                      // }}
                    />
                  )}
                {index !== 0 && index !== clickedPoints.length - 1 && (
                  <button
                    onClick={() => {
                      handleRemoveInput(index);
                    }}
                    style={{
                      border: "none",
                      background: "none",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                  >
                    <svg
                      stroke="#939393"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="24px"
                      width="24px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="none"
                        strokeMiterlimit="10"
                        strokeWidth="32"
                        d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                      ></path>
                      <path
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                        d="M320 320 192 192m0 128 128-128"
                      ></path>
                    </svg>
                  </button>
                )}
              </SearchInputWrapper>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          {/* <Tooltip
            wrapperStyles={{
              position: "absolute",
              left: 6,
              top: 0,
            }}
            content="Match baseline roads"
            showOnHover
          >
            <FilterButton
              svgSize="14px"
              bg="#fff"
              isActive={matchBaselineRoads}
              onClick={() => {
                setMatchBaselineRoads((prev) => !prev);
              }}
              disabled={disableInputs}
            >
              <GoGitCompare />
            </FilterButton>
          </Tooltip> */}
          <CalculateRouteButton
            config={config}
            handleCreateRoute={onCalculateRoute}
            clickedPoints={clickedPoints}
            isActive={!disableInputs}
          />
        </div>
      </div>
      {/* <StyledSuggestions
        ref={ref}
        style={{
          opacity: showOtherCoordinates ? 1 : 0,
          pointerEvents: showOtherCoordinates ? "auto" : "none",
        }}
      >
        {loading && <Loader position="absolute" />}
        {showNoResults ? (
          <div>
            <StyledTitle>No results found</StyledTitle>
          </div>
        ) : (
          showOtherCoordinates &&
          (!!suggestions.length ? (
            suggestions.map((suggestion) => (
              <div key={suggestion.label}>
                <div
                  style={{
                    padding: "8px 8px",
                    fontSize: 12,
                    background: "#9393934D",
                    borderRadius: 2,
                  }}
                >
                  <span>{suggestion.label}</span>
                </div>
                <div style={{ padding: "4px 0px" }}>
                  {suggestion.results.map((result) => (
                    <StyledSuggestItem
                      key={result.key}
                      onClick={() => handleSelectResult(result)}
                      color="#ffffff"
                    >
                      <p>{result.text}</p>
                    </StyledSuggestItem>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div>
              <CoordinatesInputs
                showAll={true}
                showTitle={false}
                addPointToNewFeature={handleCoordinates}
                geometry={activeCoordinates}
                color={color}
                t={t}
              />
            </div>
          ))
        )}
      </StyledSuggestions> */}
    </div>
  );
};

export default SearchCoordinates;
