import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderOverlay = styled.div`
  position: ${({ position }) => position ?? "fixed"};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ bg }) => bg ?? "rgba(255, 255, 255, 0.8)"};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 2000ms cubic-bezier(0.5, 0, 0.1, 1);
  z-index: 8000;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    text-align: center;
  }
`;

export const LoaderWrapper = styled.div`
  animation-name: ${rotate};
  animation-duration: 690ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  width: ${({ size }) => size ?? "10.5rem"};
  height: ${({ size }) => size ?? "10.5rem"};

  svg {
    fill: transparent;
    stroke: ${({ color }) => color ?? "var(--opsColor)"};
    stroke-width: 7;
    stroke-linecap: butt;
    stroke-dasharray: 240;
    stroke-dashoffset: 40;
  }
`;
