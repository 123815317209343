import React from "react";
import Switch from "react-switch";
import { StyledSwitchWrapper } from "./CustomSwitch-styled";

const CustomSwitch = ({
  labelA,
  labelB,
  small = false,
  checked,
  disabled,
  ...props
}) => {
  return (
    <StyledSwitchWrapper disabled={disabled} checked={checked} small={small}>
      {disabled && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
            cursor: "not-allowed",
          }}
        />
      )}
      {labelA && <span>{labelA}</span>}
      <Switch
        height={18}
        width={38}
        handleDiameter={14}
        offColor="#f3f3f3"
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow={!checked ? "0 0 0 2px #949494" : "0 0 0 0px #fff"}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      {labelB && <span>{labelB}</span>}
    </StyledSwitchWrapper>
  );
};

export default CustomSwitch;
