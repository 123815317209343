import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { InformationIcon } from "../Icons";
import { WarningMessage } from "../Report/new/Input/Input-styled";
import { Tooltip } from "../UI";
import {
  CustomResizer,
  DescriptionWrapper,
  MaxLength,
  StyledLabel,
  TextAreaStyled,
  TextAreaWrapper,
} from "./TextArea-styled";

const TextArea = ({
  borderColor,
  label,
  height,
  noMargin,
  onChange,
  information,
  register = {},
  error,
  isRequired,
  resizable = false,
  maxHeight,
  focusColor,
  wrapperStyles,
  ...props
}) => {
  const [userResized, setUserResized] = useState(false);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(props.value ?? props.defaultValue ?? "");
  const inputRef = useRef(null);
  const textAreaRef = useRef(null);
  const resizerRef = useRef(null);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      let v = props.value ?? props.defaultValue ?? "";

      if (v === "") {
        v = inputRef.current?.value ?? "";
      }

      if (v) {
        setValue(v);
      }
    });
  }, [props.value, props.defaultValue]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;

      if (value.length === 0) {
        setUserResized(false);
      }

      setValue(value);
      // Call the react-hook-form onChange handler if it exists
      if (register && register.onChange) {
        register.onChange(e);
      }

      // Call the custom onChange handler if it exists
      if (onChange) {
        onChange(e);
      }
    },
    [onChange, register]
  );

  const adjustHeight = useCallback(() => {
    if (textAreaRef.current && textAreaRef.current.firstChild) {
      const firstChild = textAreaRef.current.firstChild;

      if (firstChild) {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = `${firstChild.scrollHeight}px`;
      }
    }
  }, []);

  useEffect(() => {
    if (!resizable || userResized) return;
    adjustHeight();
  }, [value]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (textAreaRef.current) {
      setUserResized(true);

      setUserResized(true);
      const newHeight =
        e.clientY - textAreaRef.current.getBoundingClientRect().top;
      textAreaRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <TextAreaWrapper
      error={error}
      borderColor={borderColor}
      focusColor={focusColor}
      noMargin={!error && noMargin}
      ref={textAreaRef}
      resizable={resizable}
      maxHeight={maxHeight}
      style={wrapperStyles}
    >
      <TextAreaStyled
        height={height}
        borderColor={borderColor}
        error={error}
        {...register}
        ref={(e) => {
          // Connect react-hook-form's ref
          if (register.ref) {
            register.ref(e);
          }

          // Connect our local ref
          inputRef.current = e;
        }}
        onChange={handleChange}
        {...props}
      />
      {label && (
        <StyledLabel>
          {label}
          {isRequired ? <span style={{ color: "red" }}>*</span> : ""}
        </StyledLabel>
      )}
      {resizable && value.length > 0 && (
        <CustomResizer ref={resizerRef} onMouseDown={handleMouseDown} />
      )}
      {props.maxLength && (
        <MaxLength className="">
          {value.length} / {props.maxLength}
        </MaxLength>
      )}
      <WarningMessage isError={error}>{error}</WarningMessage>
      {information && (
        <DescriptionWrapper>
          <Tooltip content={information} showOnClick>
            <InformationIcon width="18" height="18" />
          </Tooltip>
        </DescriptionWrapper>
      )}
    </TextAreaWrapper>
  );
};

export default TextArea;
