import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { getLayerSymbology } from "../../../../utils/symbologies";
import { IoMdCheckmark } from "../../../Icons";
import { Tooltip } from "../../../UI";
import { isOutdated } from "../../CycleManager/helper";
import {
  StyledContainer,
  StyledIconContainer,
} from "./SituationalDateStatusIcon-styled";

export const Outdated = (props) => (
  <svg
    stroke="#FF6900"
    fill="#FF6900"
    strokeWidth={0}
    viewBox="0 0 192 512"
    height="14px"
    width="14px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z" />
  </svg>
);

export const SITUATIONAL_STATUS_ICON_UPDATE_TYPES = Object.freeze({
  MISSING: "up-to-date-but-missing",
  OUTDATED: "outdated",
  UP_TO_DATE: "up-to-date",
  STATUS_UNKNOWN: "up-to-date-but-status-unknown",
});

/**
 * Generates a field color map based on the provided layer and configuration.
 *
 * @param {Object} layer - The layer object to get symbology from.
 * @param {Object} config - The configuration object for the layer.
 * @returns {Object} fieldColorMap - An object mapping fields to their respective colors.
 */
const getFieldColorMap = (layer, config) => {
  const { colorMap } = getLayerSymbology(layer, config) || {};

  const fieldColorMap = {};

  if (colorMap?.field) {
    fieldColorMap[colorMap.field] = colorMap.default;
  }

  if (Array.isArray(colorMap?.fields)) {
    colorMap.fields.forEach((field) => {
      fieldColorMap[field] = colorMap.default;
    });
  }

  return fieldColorMap;
};

export const getStatus = ({
  feature,
  config,
  lastUpdate,
  dueDate,
  filterShowPublic = true,
  cycleUpdateFrequency,
}) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const layer = feature.layer || feature.sourceLayer;

        const colorMap = getFieldColorMap(layer, config);
        const requiredFields = layer.layerConfig?.requiredFields || [];
        const situationalFields = layer.layerConfig?.situationalFields || [];

        const rowColorMapFields = Object.keys(colorMap);
        const emptyValues = rowColorMapFields.filter(
          (fieldName) => !feature.attributes[fieldName]
        );

        const hasStatus = emptyValues.length === 0;

        const outdated = isOutdated({
          feature: feature,
          startDateTime: lastUpdate,
          endDateTime: dueDate,
          filterShowPublic: filterShowPublic,
          cycleUpdateFrequency,
        });

        // Checking if we have the fields and they are not empty
        const missingFields = requiredFields.filter((field) => {
          return (
            feature.attributes.hasOwnProperty(field) &&
            situationalFields.includes(field) &&
            !feature.attributes[field] &&
            feature.attributes[field] !== 0
          );
        });

        if (outdated) {
          resolve(SITUATIONAL_STATUS_ICON_UPDATE_TYPES.OUTDATED);
        } else {
          if (missingFields.length > 0) {
            resolve(SITUATIONAL_STATUS_ICON_UPDATE_TYPES.MISSING);
          } else if (hasStatus) {
            resolve(SITUATIONAL_STATUS_ICON_UPDATE_TYPES.UP_TO_DATE);
          } else {
            resolve(SITUATIONAL_STATUS_ICON_UPDATE_TYPES.STATUS_UNKNOWN);
          }
        }
      } catch (error) {
        console.error("Error in getStatus:", error);
        reject(error);
      }
    }, 0);
  });
};

export const getStatusIcon = (status) => {
  switch (status) {
    case SITUATIONAL_STATUS_ICON_UPDATE_TYPES.OUTDATED: {
      return <Outdated width="100%" height="100%" />;
    }

    case SITUATIONAL_STATUS_ICON_UPDATE_TYPES.UP_TO_DATE: {
      return <IoMdCheckmark width="100%" height="100%" />;
    }

    case SITUATIONAL_STATUS_ICON_UPDATE_TYPES.MISSING: {
      return <IoMdCheckmark width="100%" height="100%" fill="#FF6900" />;
    }
    case SITUATIONAL_STATUS_ICON_UPDATE_TYPES.STATUS_UNKNOWN: {
      return <IoMdCheckmark width="100%" height="100%" fill="#FF6900" />;
    }
  }
};

/**
 * Returns an object containing translations for situational status icons.
 *
 * @param {Function} t - Translation function.
 * @returns {Object} An object with keys corresponding to situational status icon update types and values as translated strings.
 */
export const getStatusTranslations = (t) => {
  return {
    [SITUATIONAL_STATUS_ICON_UPDATE_TYPES.OUTDATED]: {
      title: t("cycleManager.status.outdated.title"),
      information: t("cycleManager.status.outdated.information"),
    },
    [SITUATIONAL_STATUS_ICON_UPDATE_TYPES.UP_TO_DATE]: {
      title: t("cycleManager.status.upToDate.title"),
      information: t("cycleManager.status.upToDate.title"),
    },
    [SITUATIONAL_STATUS_ICON_UPDATE_TYPES.MISSING]: {
      title: t("cycleManager.status.missing.title"),
      information: t("cycleManager.status.missing.information"),
    },
    [SITUATIONAL_STATUS_ICON_UPDATE_TYPES.STATUS_UNKNOWN]: {
      title: t("cycleManager.status.statusUnknown.title"),
      information: t("cycleManager.status.statusUnknown.information"),
    },
  };
};

const SituationalDateStatusIcon = ({
  feature,
  showTitle = false,
  filterShowPublic = true,
}) => {
  const [status, setStatus] = useState(null);
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const { lastCycleUpdate, cycleUpdateDueDate, cycleUpdateFrequency } =
    useSelector((state) => state.cycleManagerReducer);

  useEffect(() => {
    if (!feature || !lastCycleUpdate || !cycleUpdateDueDate) return;

    getStatus({
      feature,
      config,
      filterShowPublic,
      lastUpdate: lastCycleUpdate,
      dueDate: cycleUpdateDueDate,
      cycleUpdateFrequency,
    })
      .then((status) => {
        setStatus(status);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    feature,
    lastCycleUpdate,
    cycleUpdateDueDate,
    config,
    filterShowPublic,
    cycleUpdateFrequency,
  ]);

  const renderIcon = useCallback((status) => {
    return getStatusIcon(status);
  }, []);

  const translations = useMemo(() => {
    return getStatusTranslations(t);
  }, [t]);

  if (!status || !translations[status]) return null;

  return (
    <div>
      <Tooltip
        showOnHover
        placement="top"
        content={translations[status].information}
      >
        <StyledContainer>
          <StyledIconContainer>{renderIcon(status)}</StyledIconContainer>
          {showTitle && <span>{translations[status].title}</span>}
        </StyledContainer>
      </Tooltip>
    </div>
  );
};

export default SituationalDateStatusIcon;
