import React, { useContext, useMemo, useRef } from "react";
import { EditorContext, UpdateType } from "../EditorContextProvider";
import EntryPointsList from "../EntryPointsList/EntryPointsList";
import { EntryPoints } from "../Platform/Platform";

const EditFeatures = ({ editableLayers, handleNext, editType }) => {
  const { editableLayer, handleSelectFeature, updateType } =
    useContext(EditorContext);
  const selectedLayer = useRef(editableLayer);

  const isEntryPointsLayer = useMemo(() => {
    const alias =
      editableLayer?.layerConfig?.extends || editableLayer?.layerConfig?.alias;
    return updateType !== UpdateType.baseline && EntryPoints.includes(alias);
  }, [editableLayer, updateType]);

  const entryPointsLayers = useMemo(
    () =>
      editableLayers
        .filter((l) => EntryPoints.includes(l?.layerConfig.titleLabel))
        .toArray(),
    [editableLayers]
  );

  return !selectedLayer.current || isEntryPointsLayer ? (
    <EntryPointsList
      handleNext={handleNext}
      editableLayers={editableLayers}
      layers={entryPointsLayers}
      handleClick={handleSelectFeature}
      editType={editType}
    />
  ) : (
    <EntryPointsList
      handleNext={handleNext}
      editableLayers={editableLayers}
      layers={[editableLayer]}
      handleClick={handleSelectFeature}
      editType={editType}
    />
  );
  // ) : (
  //   <div
  //     style={{
  //       height: "100%",
  //       display: "flex",
  //       flexDirection: "column",
  //     }}
  //   >
  //     <StyledTitle style={{ padding: "8px" }}>
  //       Which do you want to edit?
  //     </StyledTitle>
  //     <StyledDescription style={{ padding: "0px 8px 14px" }}>
  //       Select an element on the map or choose from the options below
  //     </StyledDescription>
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         flex: 1,
  //         overflow: "hidden",
  //       }}
  //     >
  //       <EditableFeaturesList
  //         editableLayer={editableLayer}
  //         handleNext={handleNext}
  //         handleSelectFeature={handleSelectFeature}
  //         isEntryPoints={false}
  //         editableLayers={editableLayers}
  //         editType={editType}
  //       />
  //     </div>
  //   </div>
  // );
};

export default EditFeatures;
