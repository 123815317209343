import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../../../utils/ConfigContext";
import {
  InformationIcon,
  MdOutlineNotificationsActive,
} from "../../../../Icons";
import { Tooltip } from "../../../../UI";
import { StyledEditorBreadcrumbs } from "../../Editor-styled";
import {
  StyledHeaderIconContainer,
  StyledNotificationBody,
  StyledNotificationWrapper,
  StyledPageHeaderContainer,
  StyledTitle,
} from "../NotificationMenu-styled";
import NotificationMap from "./NotificationMap";
import NotificationMessage from "./NotificationMessage";

const NotificationPage = ({ goBack }) => {
  const [currentView, setCurrentView] = useState(null);
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);

  return (
    <StyledNotificationWrapper>
      <StyledEditorBreadcrumbs
        hasIcon={false}
        style={{
          minHeight: 54,
          background: config.opsColor,
        }}
        hasProgress
      >
        <StyledPageHeaderContainer>
          <StyledHeaderIconContainer>
            <MdOutlineNotificationsActive
              fill="#ffffff"
              width="100%"
              height="100%"
            />
          </StyledHeaderIconContainer>
          <span>{t("screen.widget.Editor.notifications.headerTitle")}</span>
        </StyledPageHeaderContainer>
        <button
          onClick={() => {
            goBack();
          }}
          style={{ marginRight: 4 }}
        >
          <svg
            fillRule="evenodd"
            height="10"
            role="img"
            viewBox="0 0 10 10"
            width="14px"
            height="14px"
            aria-label="description"
            fill="#fff"
          >
            <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path>
          </svg>
        </button>
      </StyledEditorBreadcrumbs>
      <StyledNotificationBody>
        <StyledTitle
          style={{
            border: "2px dashed #eaeaea",
            padding: "8px",
            borderRadius: "8px",
            fontWeight: 400,
            fontSize: "12px",
            marginBottom: "4px",
          }}
        >
          {t("screen.widget.Editor.notifications.title")}
          <Tooltip
            showOnHover
            content={t("screen.widget.Editor.notifications.information")}
          >
            <InformationIcon width="14px" height="14px" />
          </Tooltip>
        </StyledTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "10px",
          }}
        >
          <NotificationMap
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
          <NotificationMessage currentView={currentView} goBack={goBack} />
        </div>
      </StyledNotificationBody>
    </StyledNotificationWrapper>
  );
};

export default NotificationPage;
