import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import useClickOutside from "../../../hooks/useClickOutside";
import { TooltipContainer, TriggerWrapper } from "./Tooltip-styled";

const Tooltip = ({
  children,
  content,
  onClick = undefined,
  placement = "top",
  offset = 8,
  showOnHover = false,
  showOnClick = false,
  disabled = false,
  className = "",
  wrapperStyles = {},
  ...styles
}) => {
  const [currentPlacement, setCurrentPlacement] = useState(placement);
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [arrowOffset, setArrowOffset] = useState(0);

  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const timeoutRef = useRef(null);

  useClickOutside(tooltipRef, (e) => {
    if (showOnClick && isVisible) {
      setTimeout(() => {
        setIsVisible(false);
      }, 100);
    }
  });

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    return () => clearTimeoutRef();
  }, []);

  const calculatePosition = useCallback(() => {
    if (!tooltipRef.current || !triggerRef.current) return;

    const tooltip = tooltipRef.current;
    const trigger = triggerRef.current;
    const triggerRect = trigger.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();

    let top = 0;
    let left = 0;
    let newPlacement = placement;
    let newArrowOffset = 0;

    const placements = ["top", "bottom", "left", "right"];
    const fits = {
      top: triggerRect.top - tooltipRect.height - 10 > 10,
      bottom:
        triggerRect.bottom + tooltipRect.height + 10 < window.innerHeight - 10,
      left: triggerRect.left - tooltipRect.width - 10 > 10,
      right:
        triggerRect.right + tooltipRect.width + 10 < window.innerWidth - 10,
    };

    // Calculate base position
    const calculateBasePosition = (placement) => {
      switch (placement) {
        case "top":
          return {
            top: triggerRect.top - tooltipRect.height - 10,
            left:
              triggerRect.left + (triggerRect.width - tooltipRect.width) / 2,
          };
        case "bottom":
          return {
            top: triggerRect.bottom + 10,
            left:
              triggerRect.left + (triggerRect.width - tooltipRect.width) / 2,
          };
        case "left":
          return {
            top:
              triggerRect.top + (triggerRect.height - tooltipRect.height) / 2,
            left: triggerRect.left - tooltipRect.width - 10,
          };
        case "right":
          return {
            top:
              triggerRect.top + (triggerRect.height - tooltipRect.height) / 2,
            left: triggerRect.right + 10,
          };
        default:
          return { top: 0, left: 0 };
      }
    };

    // Check if the current placement fits
    if (!fits[placement]) {
      // Find the first placement that fits
      for (const p of placements) {
        if (fits[p]) {
          newPlacement = p;
          break;
        }
      }
    }

    // Calculate position based on the new placement
    const position = calculateBasePosition(newPlacement);
    top = position.top;
    left = position.left;

    // Viewport adjustments
    if (left < 10) {
      if (newPlacement === "top" || newPlacement === "bottom") {
        newArrowOffset = Math.max(20, triggerRect.left + triggerRect.width / 2);
      }
      left = 10;
    } else if (left + tooltipRect.width > window.innerWidth - 10) {
      if (newPlacement === "top" || newPlacement === "bottom") {
        newArrowOffset = Math.min(
          tooltipRect.width - 20,
          triggerRect.left +
            triggerRect.width / 2 -
            (window.innerWidth - tooltipRect.width - 10)
        );
      }
      left = window.innerWidth - tooltipRect.width - 10;
    } else if (newPlacement === "top" || newPlacement === "bottom") {
      newArrowOffset = tooltipRect.width / 2;
    }

    if (top < 10) {
      if (newPlacement === "left" || newPlacement === "right") {
        newArrowOffset = Math.max(20, triggerRect.top + triggerRect.height / 2);
      }
      if (newPlacement === "top") {
        newPlacement = "bottom";
        top = triggerRect.bottom + 10;
      } else {
        top = 10;
      }
    } else if (top + tooltipRect.height > window.innerHeight - 10) {
      if (newPlacement === "left" || newPlacement === "right") {
        newArrowOffset = Math.min(
          tooltipRect.height - 20,
          triggerRect.top +
            triggerRect.height / 2 -
            (window.innerHeight - tooltipRect.height - 10)
        );
      }
      if (newPlacement === "bottom") {
        newPlacement = "top";
        top = triggerRect.top - tooltipRect.height - 10;
      } else {
        top = window.innerHeight - tooltipRect.height - 10;
      }
    } else if (newPlacement === "left" || newPlacement === "right") {
      newArrowOffset = tooltipRect.height / 2;
    }

    setPosition({ top, left });
    setCurrentPlacement(newPlacement);
    setArrowOffset(newArrowOffset);
  }, [placement]);

  useEffect(() => {
    if (isVisible) {
      calculatePosition();

      const closeTooltip = () => {
        if (!isVisible) return;
        setIsVisible(false);
      };

      const root = document.getElementById("root");

      if (!root) return;
      root.addEventListener("scroll", closeTooltip, true);
      root.addEventListener("resize", closeTooltip, true);

      return () => {
        if (!root) return;
        root.removeEventListener("scroll", closeTooltip, true);
        root.removeEventListener("resize", closeTooltip, true);
      };
    }
  }, [isVisible, calculatePosition]);

  const handleMouseEnter = (e) => {
    clearTimeoutRef();

    if (showOnHover) {
      timeoutRef.current = window.setTimeout(() => {
        setIsVisible(true);
      }, 100);
    }
  };

  const handleMouseLeave = () => {
    if (showOnHover && !showOnClick) {
      clearTimeoutRef();
      timeoutRef.current = window.setTimeout(() => {
        setIsVisible(false);
      }, 100);
    }
  };

  const handleClick = (e) => {
    if (showOnClick) {
      e.stopPropagation();
      clearTimeoutRef();
      setIsVisible(!isVisible);
    }

    if (onClick) {
      onClick(e);
    }
  };

  const onTooltipContainerClick = () => {
    if (showOnClick) {
      setIsVisible(false);
    }
  };

  if (!content) return null;

  return (
    <>
      <TriggerWrapper
        ref={triggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        disabled={disabled}
        style={{ ...wrapperStyles }}
        className={className}
      >
        {children}
      </TriggerWrapper>
      {isVisible &&
        !disabled &&
        createPortal(
          <TooltipContainer
            ref={tooltipRef}
            style={{
              top: position.top,
              left: position.left,
              ...styles,
            }}
            onClick={onTooltipContainerClick}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            placement={currentPlacement}
            isVisible={isVisible}
            arrowOffset={arrowOffset}
          >
            {content}
          </TooltipContainer>,
          document.querySelector("#tooltip")
        )}
    </>
  );
};

export default Tooltip;
