import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  graphicsLayer,
  view,
} from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { EditorIcon, PlusIcon } from "../../../Icons";
import { Tooltip } from "../../../UI";
import ActionButton from "../../CycleManager/Checklist/ChecklistTable/ActionButton";
import { DeleteButton, SaveAndConfirmButton } from "../Buttons";
import { EditorContext, UpdateType } from "../EditorContextProvider";
import { getErrorTitles } from "../EditorFields/EditableFields/EditableFields";
import Message from "../Message";
import { ALL_STEPS, EditType, LAYER_EFFECT } from "./EditorSwiper";

export const ConfirmButton = ({
  onClick,
  wrapperStyles = {},
  count,
  color,
  title,
  tooltipProps = {},
  showConfirmTitle,
  ...props
}) => {
  const { t } = useTranslation("common");

  return (
    <Tooltip
      showOnHover
      content={t("cycleManager.tooltip.confirm")}
      wrapperStyles={wrapperStyles}
      disabled={count === 0 || !showConfirmTitle}
      {...tooltipProps}
    >
      <ActionButton count={count} color={color} onClick={onClick} {...props}>
        <div
          style={{
            display: "flex",
            gap: 4,
            alignItems: "center",
          }}
        >
          {typeof title === "string" ? <span>{title}</span> : title}
          {showConfirmTitle && (
            <span>
              {title ? "& " : ""} {t("cycleManager.confirmStatus")}{" "}
            </span>
          )}
        </div>
      </ActionButton>
    </Tooltip>
  );
};

const Footer = ({
  isNextDisabled,
  handleNext,
  handleSubmit,
  doEdit,
  getIsDeletedField,
  doDelete,
  isEnd,
  currentSlide,
  setEditType,
  editType,
  confirmStatus,
}) => {
  const {
    batchUpdateFeatures,
    activeColor,
    editableLayer,
    updateType,
    highlightFeature,
    setHighlightFeature,
    setBatchUpdateFeatures,
    setUpdateType,
    editableLayerLoaded,
    setEditingInitialUpdateType,
    trimmedSituationalFeatures,
  } = useContext(EditorContext);

  const { config } = useContext(ConfigContext);
  const { trigger, formState, clearErrors } = useFormContext();
  const { errors, isDirty } = formState;
  const { t } = useTranslation("common");
  const [requiredFieldsMessage, setRequiredFieldsMessage] = useState({
    show: false,
    fields: [],
  });

  const makeRoadsSituationalCheck = () => {
    const { updateFeatures, deleteFeatures } = trimmedSituationalFeatures;
  };

  const NextButton = (
    <ActionButton
      onClick={() => {
        if (currentSlide?.id === ALL_STEPS.fillFields) {
          trigger();
          setTimeout(() => {
            if (Object.keys(errors).length === 0) {
              clearErrors();
              handleNext();
            }
          }, 100);
        } else {
          handleNext();
        }
      }}
      isActive={!isNextDisabled}
      color={activeColor}
      styles={{ padding: "0px 20px" }}
    >
      <span>{t("screen.popup.actions.next")}</span>
    </ActionButton>
  );

  const AddButton = editableLayerLoaded ? (
    <Tooltip
      content={t("screen.widget.common.add")}
      placement="top"
      showOnHover
    >
      <ActionButton
        Icon={PlusIcon}
        onClick={() => {
          setEditType(EditType.create);
          setBatchUpdateFeatures([]);
          editableLayer.effect = LAYER_EFFECT;
          graphicsLayer.removeAll();
          setEditingInitialUpdateType(updateType);
          handleNext();
          if (updateType !== UpdateType.baseline) {
            setUpdateType(UpdateType.baseline);
          }
        }}
        isActive={true}
        color="#fff"
        styles={{
          padding: "0px 8px",
          backgroundColor: activeColor,
        }}
      />
    </Tooltip>
  ) : null;

  const DeleteBtn = (
    <DeleteButton
      editType={editType}
      updateType={updateType}
      editableLayer={editableLayer}
      getIsDeletedField={getIsDeletedField}
      activeColor={activeColor}
      count={
        currentSlide?.id === ALL_STEPS.entryPoints
          ? batchUpdateFeatures.length
          : 0
      }
      isActive={
        currentSlide?.id === ALL_STEPS.entryPoints
          ? batchUpdateFeatures.length > 0
          : true
      }
      doDelete={doDelete}
    />
  );
  // editType === EditType.edit &&
  // (updateType === UpdateType.baseline ||
  //   (editableLayer?.layerConfig &&
  //     editableLayer?.layerConfig?.allowDeleteWhenSituational)) &&
  // getIsDeletedField() ? (
  //   <ActionButton
  //     Icon={TrashBin}
  //     color={activeColor}
  //     onClick={() => setShowDelete(true)}
  //     count={
  //       currentSlide?.id === ALL_STEPS.entryPoints
  //         ? batchUpdateFeatures.length
  //         : 0
  //     }
  //     isActive={
  // currentSlide?.id === ALL_STEPS.entryPoints
  //   ? batchUpdateFeatures.length > 0
  //   : true
  //     }
  //     styles={{
  //       padding: "0px 8px",
  //     }}
  //   />
  // ) : null;

  const leftButton = () => {
    if (!currentSlide || !editableLayer) return null;
    const geometryType = editableLayer.geometryType; //layerConfig?.extends || editableLayer.layerConfig?.alias;

    if (currentSlide.id === ALL_STEPS.entryPoints) {
      return (
        <>
          {AddButton}
          {DeleteBtn}
          {UpdateType.situational === updateType &&
            editableLayer.fields.some(
              (field) => field.name === currAsOfDateFieldName
            ) && (
              <SaveAndConfirmButton
                onClick={confirmStatus}
                updateType={updateType}
                editableLayer={editableLayer}
                activeColor={activeColor}
                showSave={currentSlide?.id === ALL_STEPS.fillFields}
                count={!highlightFeature ? batchUpdateFeatures.length : 0}
              />
            )}
        </>
      );
    }

    if (
      currentSlide?.id === ALL_STEPS.selectLocation &&
      geometryType !== "point" &&
      editType === EditType.edit
    ) {
      return AddButton;
    }
  };

  const rightButton = () => {
    if (!currentSlide || !editableLayer) return null;
    const geometryType = editableLayer.geometryType;
    const batchUpdateFields = Array.isArray(
      editableLayer.layerConfig?.batchUpdateFields
    )
      ? editableLayer.layerConfig.batchUpdateFields.filter(
          (fieldName) =>
            fieldName !== currAsOfDateFieldName ||
            fieldName !== asOfDateFieldName
        )
      : [];

    const baselineFields = editableLayer.layerConfig?.baselineFields
      ? editableLayer.layerConfig.baselineFields
      : [];

    const situationalFields = editableLayer.layerConfig?.situationalFields
      ? editableLayer.layerConfig.situationalFields
      : [];

    const fields =
      updateType === UpdateType.baseline ? baselineFields : situationalFields;

    // Use a Set for efficient lookup
    const batchUpdateFieldsSet = new Set(batchUpdateFields);

    // Filter fields using the Set
    const matchingFields = fields.filter((field) =>
      batchUpdateFieldsSet.has(field)
    );

    const showSaveAndConfirm = isEnd;

    if (showSaveAndConfirm) {
      if (currentSlide.id === ALL_STEPS.EntryPoints) {
        return (
          <SaveAndConfirmButton
            onClick={handleSubmit(doEdit)}
            isActive
            styles={{ margin: "auto" }}
            updateType={updateType}
            editableLayer={editableLayer}
            activeColor={activeColor}
            showSave={currentSlide?.id === ALL_STEPS.fillFields}
            count={!highlightFeature ? batchUpdateFeatures.length : 0}
          />
        );
      }
    }

    if (currentSlide.id === ALL_STEPS.fillFields) {
      return (
        <>
          {DeleteBtn}
          {showSaveAndConfirm ? (
            <SaveAndConfirmButton
              updateType={updateType}
              editableLayer={editableLayer}
              activeColor={activeColor}
              showSave={currentSlide?.id === ALL_STEPS.fillFields}
              onClick={(data) => {
                trigger().then((isValid) => {
                  if (isValid) {
                    handleSubmit(doEdit)(data);
                  } else {
                    const titles = getErrorTitles(errors, t, editableLayer);
                    setRequiredFieldsMessage({
                      show: true,
                      fields: titles,
                    });
                    setTimeout(() => {
                      setRequiredFieldsMessage({
                        show: false,
                        fields: [],
                      });
                    }, 3000);
                  }
                });
              }}
              count={!highlightFeature ? batchUpdateFeatures.length : 0}
              isActive
            />
          ) : (
            NextButton
          )}
        </>
      );
    }

    if (
      (currentSlide.id === ALL_STEPS.selectLocation &&
        editType === EditType.create &&
        geometryType === "point") ||
      currentSlide.id === ALL_STEPS.enterRoadsSituationalCoordinates
    ) {
      return NextButton;
    }

    if (
      currentSlide.id === ALL_STEPS.entryPoints &&
      batchUpdateFields.length > 0
    ) {
      return (
        <ActionButton
          color={activeColor}
          count={matchingFields.length > 0 ? batchUpdateFeatures.length : 0}
          Icon={EditorIcon}
          onClick={() => {
            if (batchUpdateFeatures.length === 1) {
              setHighlightFeature(batchUpdateFeatures[0].feature);
              setBatchUpdateFeatures([]);
            }
            if (view) {
              view.closePopup();
            }

            handleNext();
          }}
          styles={{
            padding: "0px 8px",
          }}
        />
      );
    }
  };

  if (!leftButton && !rightButton) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "auto",
        padding: 8,
        minHeight: 50,
        boxSizing: "border-box",
        gap: 8,
        borderTop: "1px solid #eee",
      }}
    >
      {requiredFieldsMessage.show && (
        <Message
          title={t("cycleManager.saveMessages.missingFields", { 1: "" })}
          subtitle={
            <span
              style={{ fontWeight: 400, fontSize: "inherit", color: "red" }}
            >
              {requiredFieldsMessage.fields.join(", ")}
            </span>
          }
        />
      )}
      {leftButton()}
      {rightButton()}
    </div>
  );
};

export default Footer;
