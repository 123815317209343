import styled from "styled-components";

export const StyledCycleManagerProgressWrapper = styled.div`
  padding: 10px 26px 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const StyledProgressContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 110px;

  .progressbar-text {
    transform: translate(-50%, -50%) !important;
    font-size: 16px !important;
    bottom: auto !important;
    top: 70% !important;
  }
`;

export const StyledProgress = styled.div`
  width: 100%;
  max-width: 200px;
`;

export const StyledProgressPercentContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  gap: 2px;

  span {
    white-space: nowrap;
  }

  span:first-child {
    font-size: 18px;
    color: var(--opsColor);
  }

  span:last-child {
    font-size: 10px;
    color: #4b4b4b;
  }
`;
