import React, { forwardRef, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useLayerEditTable from "../../../hooks/LayerEditTable";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { setFeatureTableOpen } from "../../../redux/action/FeatureTable-actions";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  showpublicFieldName,
} from "../../../utils/API";
import { ConfigContext } from "../../../utils/ConfigContext";
import { getFieldsByPattern, ROLE_EDITOR } from "../../../utils/helper";
import ChecklistTable from "../../Dashboard/CycleManager/Checklist/ChecklistTable";
import {
  getFeatureNameField,
  getFeatureNameFields,
} from "../../Dashboard/Editor/helpers";
import { MessagesOverlay } from "../../LayerTable/LayerEditTable";
import DownloadButton from "../DownloadButton/DownloadButton";
import TableButtons from "./TableButtons";
import TableHeader from "./TableHeader";

const generateFields = (currLayer, config) => {
  const { layerConfig } = currLayer || {};
  if (!layerConfig) return [];

  const situationalFields = getFieldsByPattern(
    currLayer,
    layerConfig.situationalFields,
    config.role !== ROLE_EDITOR
  ).sort((a, b) => {
    if (a.name === currAsOfDateFieldName) return 1;
    if (b.name === currAsOfDateFieldName) return -1;
    return 0;
  });

  const baselineFields = getFieldsByPattern(
    currLayer,
    layerConfig.baselineFields,
    config.role !== ROLE_EDITOR
  ).sort((a, b) => {
    if (a.name === asOfDateFieldName) return 1;
    if (b.name === asOfDateFieldName) return -1;
    return 0;
  });

  const fields = [...situationalFields, ...baselineFields];

  const outFields = fields.map((field) => field.name);
  if (layerConfig.featureTable.exportCoordinates) {
    outFields.push("latitude", "longitude");
  }
  return outFields;
};

const TableGrid = forwardRef(
  (
    {
      editableLayer,
      setEditableLayer,
      visibleLayers,
      featureTableFullScreenActive,
      setShowCloseMessage,
      showCloseMessage,
      editedFeatures,
      setEditedFeatures,
      openFullScreen,
      exitFullScreen,
    },
    ref
  ) => {
    const { config } = useContext(ConfigContext);
    const { t } = useTranslation("common");

    const [openSnackbar] = useCustomSnackbar();
    const [batchUpdateFeatures, setBatchUpdateFeatures] = useState([]);
    const [filterGeometry, setFilterGeometry] = useState(null);
    const [filtersActive, setFiltersActive] = useState(true);
    const dispatch = useDispatch();

    // useEffect(() => {
    //   if (editedFeatures && Object.keys(editedFeatures).length === 0) return;

    //   const handleBeforeUnload = (event) => {
    //     event.preventDefault();
    //     event.returnValue = ""; // This is required for Chrome to show the confirmation dialog
    //   };

    //   window.addEventListener("beforeunload", handleBeforeUnload);

    //   return () => {
    //     window.removeEventListener("beforeunload", handleBeforeUnload);
    //   };
    // }, [editedFeatures]);

    const {
      onRowDataUpdated,
      handleUpdateAll,
      handleLayerSelection,
      graphicsLayer,
      updatedFeatures,
      showUpdateMessage,
      requiredFieldsMessage,
      setSaveMessage,
      saveMessage,
      showBatchUpdate,
      gridApi,
      setGridApi,
      setFeatures,
      features,
      loading,
    } = useLayerEditTable({
      editableLayer,
      setEditableLayer,
      batchUpdateFeatures,
      setBatchUpdateFeatures,
      editedFeatures,
      setEditedFeatures,
      updateType: "both",
    });

    const handleRowDataUpdated = useCallback(
      (args) => {
        onRowDataUpdated(args, false);
      },
      [filterGeometry, onRowDataUpdated]
    );

    const generateQueryFields = useCallback(
      (editableLayer) => {
        if (!editableLayer) return [];
        let result = [];
        const name = getFeatureNameField(editableLayer);
        const names = getFeatureNameFields(editableLayer);
        const nameField = editableLayer.fields.find((f) => f.name === name);

        const fields = generateFields(editableLayer, config);
        result = editableLayer.fields.filter(
          (field) =>
            fields.includes(field.name) &&
            !(field.name === "latitude" || field.name === "longitude")
        );
        result = result.sort((a, b) => {
          if (names.includes(a.name)) return -1;
          if (names.includes(b.name)) return 1;
          if (a.name === showpublicFieldName) return -1;
          if (b.name === showpublicFieldName) return 1;
          return fields.indexOf(a.name) - fields.indexOf(b.name);
        });

        return result;
      },
      [config]
    );

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            padding: "0px 10px",
            justifyContent: "space-between",
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <div style={{ flex: 1 }}>
            <DownloadButton
              config={config}
              layer={editableLayer}
              t={t}
              gridApi={gridApi}
              generateQueryFields={generateQueryFields}
            />
          </div>
          <TableHeader
            editableLayer={editableLayer}
            handleLayerSelection={handleLayerSelection}
            visibleLayers={visibleLayers}
          />
          <TableButtons
            confirmUpdateCount={Object.keys(editedFeatures).length}
            handleUpdateAll={handleUpdateAll}
            filterGeometry={filterGeometry}
            setFilterGeometry={setFilterGeometry}
            setBatchUpdateFeatures={setBatchUpdateFeatures}
            featureTableFullScreenActive={featureTableFullScreenActive}
            openFullScreen={openFullScreen}
            exitFullScreen={exitFullScreen}
            filtersActive={filtersActive}
          />
        </div>
        <div
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          <MessagesOverlay
            batchUpdateFeatures={batchUpdateFeatures}
            editableLayer={editableLayer}
            activeColor={config.opsColor}
            showBatchUpdate={showBatchUpdate}
            requiredFieldsMessage={requiredFieldsMessage}
            saveMessage={saveMessage}
            setSaveMessage={setSaveMessage}
            showCloseMessage={showCloseMessage}
            updatedFeatures={updatedFeatures}
            showUpdateMessage={showUpdateMessage}
            onCloseMessageSubmit={() => {
              dispatch(setFeatureTableOpen(false));
              setShowCloseMessage(false);
              setEditedFeatures({});
            }}
            onSaveMessageSubmit={() => {
              setEditableLayer(saveMessage.newLayer);
              setSaveMessage({
                show: false,
                newLayer: null,
              });
            }}
            onCloseMessageCancel={() => {
              setShowCloseMessage(false);
              // if (filterGeometry) {
              //   setFilterGeometry(null);
              // }
              setFiltersActive(false);
            }}
            onSaveMessageCancel={() => {
              setSaveMessage({
                show: false,
                newLayer: null,
              });
              // if (filterGeometry) {
              //   setFilterGeometry(null);
              // }
              setFiltersActive(false);
            }}
            setShowCloseMessage={setShowCloseMessage}
          />
          {graphicsLayer && editableLayer && (
            <ChecklistTable
              layer={editableLayer}
              generateQueryFields={generateQueryFields}
              editedFeatures={editedFeatures}
              setEditedFeatures={setEditedFeatures}
              loading={loading}
              gridApi={gridApi}
              setGridApi={setGridApi}
              setFeatures={setFeatures}
              features={features}
              onRowDataUpdated={handleRowDataUpdated}
              graphicsLayer={graphicsLayer}
              openSnackbar={openSnackbar}
              filterGeometry={filterGeometry}
              batchUpdateFeatures={batchUpdateFeatures}
              isDataTable
            />
          )}
        </div>
      </div>
      // <LayerTable
      //   layer={layer}
      //   columns={columns}
      //   rows={row}
      //   setGridApi={setGridApi}
      //   onCellClicked={cellClicked}
      //   onCellValueChanged={onCellValueChanged}
      // />
    );
  }
);

export default TableGrid;
