import styled from "styled-components";

export const StyledPrintButton = styled.button`
  font-size: 14px;
  background-color: #fff;
  color: #6e6e6e;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition: background-color 125ms ease-in-out;
  width: 40px !important;
  height: 40px !important;
  border-radius: 8px !important;
  outline: none !important;
  border: none;

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    width: 32px !important;
    height: 32px !important;
    span {
      font-size: 14px;
    }
  }
`;

export const PrintLoadingOverlay = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  background: #ffffff;
  width: 200px;
  z-index: 2;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  pointer-events: none;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  text-align: center;

  span {
    font-size: 10px;
    line-height: normal;
  }

  :before {
    content: "";
    background: #ffffff;
    top: -5px;
    left: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: -1;
    transform: translateX(-50%) rotate(45deg);
  }
`;

export const PrintWarningContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const PrintWarningIconContainer = styled.div`
  min-width: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
