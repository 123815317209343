import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import useEditableLayers from "../../../../hooks/useEditableLayers";
import { setCycleManagerOpen } from "../../../../redux/action/CycleManager-action";
import { view } from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { EditorContext } from "../../Editor/EditorContextProvider";
import { removeLayerEffects } from "../../Editor/helpers";
import Checklist from "../Checklist/Checklist";

const CycleManager = () => {
  const [editedFeatures, setEditedFeatures] = useState({});
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  const { resetEditor, batchUpdateFeatures } = useContext(EditorContext);
  const { config } = useContext(ConfigContext);

  const { situationalLayers: layers } = useEditableLayers(config, false);
  const animationFrame = useRef(-1);
  const [openSnackbar] = useCustomSnackbar();

  const dispatch = useDispatch();
  // Function to animate the padding smoothly
  function animatePadding(targetPadding) {
    const currentPadding = view.padding.left || 0;
    const step = (targetPadding - currentPadding) / 20; // Adjust the divisor for smoothness

    function animate() {
      const newPadding = view.padding.left + step;

      // Stop animation when close enough to the target
      if (
        (step > 0 && newPadding >= targetPadding) ||
        (step < 0 && newPadding <= targetPadding)
      ) {
        view.padding = { left: targetPadding };
        cancelAnimationFrame(animationFrame.current);
        return;
      }

      // Update padding and request the next frame
      view.padding = { left: newPadding };
      animationFrame.current = requestAnimationFrame(animate);
    }

    animate();
  }

  useEffect(() => {
    // view.popupEnabled = false;

    const handler = view.on("click", (event) => {
      event.stopPropagation();
    });

    const initialLeftPadding = view.padding.left;
    const cycleManager = document.getElementById("cycle-manager");
    if (cycleManager) {
      const { right = 0 } = cycleManager?.getBoundingClientRect() || {};
      animatePadding(right);
    }

    return () => {
      // view.popupEnabled = true;
      view.padding = initialLeftPadding;
      handler?.remove();
    };
  }, []);

  const closeCycleManager = useCallback(() => {
    removeLayerEffects();
    dispatch(setCycleManagerOpen(false));

    resetEditor();
  }, [resetEditor]);

  const handleClose = useCallback(() => {
    if (
      Object.values(editedFeatures).length > 0 ||
      batchUpdateFeatures.length > 0
    ) {
      setShowCloseMessage(true);
    } else {
      closeCycleManager();
    }
  }, [editedFeatures, batchUpdateFeatures, closeCycleManager]);

  /**
   * layersUpdates is an object that includes properties features and missing which are Array of arcgis features
   * @param {Object<string, {missing: Array<Graphic>, features: Array<Graphic>}>} payload - The object with the layer id as key and
   */
  // const handleSetLayersUpdates = useCallback((layersUpdates)=>{
  //   dispatch(setLayersUpdates(layersUpdates))
  // },[])

  // if (Object.keys(layersUpdates).length === 0)
  //   return <Loader position="absolute" />;

  return (
    <>
      <div
        style={{
          height: "100%",
          padding: "0px 0px 2px 14px",
        }}
      >
        <Checklist
          layers={layers}
          editedFeatures={editedFeatures}
          setEditedFeatures={setEditedFeatures}
          openSnackbar={openSnackbar}
          handleClose={handleClose}
          closeCycleManager={closeCycleManager}
          setShowCloseMessage={setShowCloseMessage}
          showCloseMessage={showCloseMessage}
        />
      </div>
    </>
  );
};

export default CycleManager;
