import React, { useContext, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { LayerCircle } from "../../../UI";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { StyledCircleWrapper } from "./TableHeader-styled";

const TableHeader = ({
  editableLayer,
  handleLayerSelection,
  visibleLayers,
}) => {
  const [count, setCount] = useState(0);
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const swiperRef = useRef(null);

  const showNext = useMemo(() => {
    if (swiperRef.current) {
      return !swiperRef.current.isEnd;
    }
  }, [count]);
  const showPrev = useMemo(() => {
    if (swiperRef.current) {
      return !swiperRef.current.isBeginning;
    }
  }, [count]);

  const currSlide = useMemo(() => {
    if (swiperRef.current) {
      return swiperRef.current.activeIndex + 1;
    }

    return 1;
  }, [count]);

  return (
    <div
      style={{
        display: "flex",
        flex: 3,
        minHeight: 40,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <StyledCircleWrapper>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            setCount((prev) => prev + 1);
          }}
          onSlideChange={() => {
            setCount((prev) => prev + 1);
          }}
          slidesPerView="auto"
          freeMode={true}
          modules={[FreeMode]}
        >
          {visibleLayers.map((layer) => (
            <SwiperSlide
              key={layer.id}
              style={{
                width: "auto",
              }}
            >
              <LayerCircle
                selected={editableLayer === layer}
                layer={layer}
                config={config}
                t={t}
                opsColor={config.opsColor}
                onClick={() => handleLayerSelection(layer)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledCircleWrapper>
    </div>
  );
};

export default TableHeader;
