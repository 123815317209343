import {
  addOperationLayer_actionType,
  setErrorLayers_actionType,
  setMapView_actionType,
  setOperationLayers_actionType,
  setRejectedLayers_actionType,
  updateOperationLayer_actionType,
} from "../constants";

const initialState = {
  view: null,
  op: [],
  errorLayers: [],
  operationLayers: [],

  rejectedLayers: [],
};

export const LAYER_STATES = {
  LOADING: "loading",
  LOADED: "loaded",
  ERROR: "error",
};

const mapViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case setMapView_actionType: {
      const { payload } = action;
      return {
        ...state,
        view: payload,
      };
    }
    case setOperationLayers_actionType: {
      const { payload } = action;

      return {
        ...state,
        operationLayers: payload,
      };
    }
    case setRejectedLayers_actionType: {
      const { payload } = action;
      return {
        ...state,
        rejectedLayers: payload,
      };
    }
    case setErrorLayers_actionType: {
      const { payload } = action;

      return {
        ...state,
        errorLayers: payload,
      };
    }
    case updateOperationLayer_actionType: {
      const { id, alias, values } = action.payload;
      // let updatedOperationLayers = [...state.operationLayers];

      const updatedOperationLayers = state.operationLayers.map((layer) =>
        layer.id === id ? { ...layer, ...values } : layer
      );

      return {
        ...state,
        operationLayers: updatedOperationLayers,
      };
    }
    case addOperationLayer_actionType: {
      const { payload } = action;

      return {
        ...state,
        operationLayers: [...state.operationLayers, payload],
      };
    }
  }

  return state;
};

export default mapViewReducer;
