import styled from "styled-components";
import { Button } from "../../../UI";

export const StyledNotificationWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 4px;
  width: 100%;
  justify-content: center;
  span {
    font-size: 16px;
  }
`;

export const StyledHeaderIconContainer = styled.span`
  min-width: 18px;
  height: 18px;
`;

export const StyledNotificationBody = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow: auto;
  padding: 8px 8px;
`;

export const StyledMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 55%;
  min-height: 312px;
  max-height: 500px;
  gap: 12px;
`;

export const StyledViewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .esri-view {
    height: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
  }

  .esri-view-root {
    border-radius: 8px;
    overflow: hidden;
  }

  .esri-ui-bottom-right {
    bottom: 7px;
    right: 7px;
  }
`;

export const StyledMapFocus = styled.div`
  position: absolute;
  pointer-events: none;
  width: calc(100% - 16px);
  height: 100%;
  top: 0;
  left: 8px;
  /* box-shadow: rgb(58 58 58 / 50%) 0px 0px 7px 14px inset; */
  box-sizing: border-box;
  border-radius: 8px;
  border: 20px solid #bcbbbb8a; //var(--opsColor);

  div {
    /* position: absolute;
    left: 0px;
    top: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 2px solid var(--opsColor);
    border-radius: 0px; */
  }
`;

export const StyledBodyContainer = styled.div`
  padding: 10px 0px;

  p {
    /* padding-top: 12px;
    margin-top: 10px;
    border-top: 1px solid #e0e0e0; */
  }
`;

export const StyledForm = styled.form`
  margin-top: 12px;
  padding: 0px 8px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  /* align-items: center; */
  padding: 0px 8px;
  gap: 4px;
  /* margin-bottom: 10px; */
  justify-content: center;
`;

export const StyledTitle = styled.p`
  margin: 0;
  /* font-weight: 500; */
  font-size: 14px;
  text-align: center;
  /* margin-bottom: 12px; */
`;

export const StyledButton = styled(Button)`
  padding: 8px 16px;
  color: #fff;
  margin: auto;
`;

export const StyledSuccessMessage = styled.span`
  display: inline-flex;
  gap: 4px;
  align-items: center;
`;

export const AnimatedEllipsis = styled.span`
  display: inline-block;
  padding: 0px !important;
import { StyledMapContainer } from '../../../MapContainer/MapContainer-styled';

  &::after {
    content: "...";
    animation: ellipsis 1.5s infinite;
    display: inline-block;
    width: 12px;
    text-align: left;
  }

  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
    100% {
      content: ".";
    }
  }
`;

// Add this styled component near your other styled components
export const AnimatedIcon = styled.span`
  display: inline-flex;
  align-items: center;

  svg {
    animation: sendMessage 2s ease-in-out infinite;
    transform-origin: center;
  }

  @keyframes sendMessage {
    0% {
      transform: translateX(0px) translateY(0) rotate(0);
      opacity: 1;
    }
    30% {
      transform: translateX(1px) translateY(-2px) rotate(5deg);
      opacity: 0.9;
    }
    60% {
      transform: translateX(2px) translateY(0) rotate(-2deg);
      opacity: 0.8;
    }
    100% {
      transform: translateX(0px) translateY(0) rotate(0);
      opacity: 1;
    }
  }
`;
