import React, { useMemo } from "react";
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper,
  InlineSvg,
} from "./Checkbox-styled";

const Checkbox = ({
  color,
  label,
  size,
  id = "",
  borderRadius,
  wrapperStyles = {},
  ...props
}) => {
  const checkBoxId = useMemo(() => {
    if (id) {
      return id;
    } else {
      return `${Math.random().toString(36).substring(2, 9)}-${Date.now()}`;
    }
  }, [id]);

  return (
    <CheckboxWrapper style={wrapperStyles} color={color}>
      <CheckboxInput
        {...props}
        color={color}
        id={"checkbox-custom" + checkBoxId}
        type="checkbox"
      />
      {label && (
        <CheckboxLabel
          color={color}
          htmlFor={"checkbox-custom" + checkBoxId}
          size={size}
          borderRadius={borderRadius}
        >
          <span>
            <svg width="12px" height="10px">
              <use xlinkHref="#check-4"></use>
            </svg>
          </span>
          <span>{label}</span>
        </CheckboxLabel>
      )}
      <InlineSvg>
        <symbol id="check-4" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </InlineSvg>
    </CheckboxWrapper>
  );
};

export default Checkbox;
