import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addLayersUpdate } from "../../../../redux/action/CycleManager-action";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  showpublicFieldName,
} from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { isYesNoDomain, ROLE_EDITOR } from "../../../../utils/helper";
import { getLayerSymbology } from "../../../../utils/symbologies";
import LayerTable, {
  generateColumn,
  generateRows,
} from "../../../LayerTable/LayerTable";
import {
  createFeatureGraphic,
  getFeatureNameFields,
  zoomToFeaturesExtent,
} from "../../Editor/helpers";
import Loader from "../../Editor/Loader/Loader";
import Message from "../../Editor/Message";
import {
  StyledCheckListTableWrapper,
  StyledErrorOverlay,
} from "../CycleManager-styled";
import {
  calculateMissingFeatures,
  iconsToHeaders,
  isOutdated,
} from "../helper";
import FeatureSelectHeader from "./ChecklistTable/Headers/FeatureSelectHeader/FeatureSelectHeader";
import UpdateCellRenderer, {
  CYCLE_UPDATE_TYPES,
} from "./ChecklistTable/UpdateCellRenderer";

/**
 * Calculates the width of a text in pixels based on its length.
 *
 * @param {string} text - The text to measure.
 * @param {string} font - The font to use for the measurement.
 * @returns {number} The width of the text in pixels.
 */
function calculateTextWidth(text, font) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  return 0;
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const updateValueFormatter = (data, lastUpdate, dueDate, frequency) => {
  const rowColorMapFields = Object.keys(data.rowColorMap);
  const emptyValues = rowColorMapFields.filter(
    (fieldName) => !data.feature.attributes[fieldName]
  );

  const hasStatus = emptyValues.length === 0;

  const outdated = isOutdated({
    feature: data.feature,
    startDateTime: lastUpdate,
    endDateTime: dueDate,
    cycleUpdateFrequency: frequency,
  });

  //checking if we have the field and it is not empty
  const missing =
    (data.hasOwnProperty("currsourcename") &&
      !data.currsourcename &&
      data.currsourcename !== 0) ||
    (data.hasOwnProperty("currinforely") &&
      !data.currinforely &&
      data.currinforely !== 0);

  if (outdated) {
    return CYCLE_UPDATE_TYPES.OUTDATED;
  } else {
    if (missing) {
      return CYCLE_UPDATE_TYPES.MISSING;
    } else if (hasStatus) {
      return CYCLE_UPDATE_TYPES.UP_TO_DATE;
    } else {
      return CYCLE_UPDATE_TYPES.STATUS_UNKNOWN;
    }
  }
};

const generateUpdateColumn = ({ config, layer, t }) => {
  const defaultColumn = {
    field: "update",
    headerName: "",
    minWidth: 90,
    maxWidth: 40,
    filter: false,
    editable: false,
    cellDataType: undefined,
    pinned: undefined,
    cellRenderer: (params) => {
      return <UpdateCellRenderer layer={layer} params={params} />;
    },
  };

  // const field = {
  //   name: "update",
  //   domain: {
  //     codedValues: [
  //       {
  //         code: "outdated",
  //         name: "Outdated",
  //       },
  //       {
  //         code: "up-to-date",
  //         name: "Up to date",
  //       },
  //     ],
  //   },
  // };

  //showing select with values

  // const values = [];
  // let refData = {};
  // field.domain.codedValues.forEach((cv) => {
  //   values.push(cv.code);
  //   refData = {
  //     ...refData,
  //     [cv.code]: renderCell(field, cv.code, t, config),
  //   };
  // });

  // defaultColumn.refData = refData;

  return defaultColumn;
};

// const addMissingFields = (layer, fields) => {
//   let newFields = [...fields];
//   const name = getFeatureNameField(layer);
//   const nameField = layer.fields.find((f) => f.name === name);

//   const showPublic = layer.fields.find((f) => f.name === showpublicFieldName);

//   const currAsOfDateField = layer.fields.find(
//     (f) => f.name === currAsOfDateFieldName
//   );

//   if (
//     !newFields.some((f) => f.name === currAsOfDateFieldName) &&
//     currAsOfDateField
//   ) {
//     newFields.push(currAsOfDateField);
//   }

//   if (!newFields.some((f) => f.name === showpublicFieldName) && showPublic) {
//     newFields.unshift(showPublic);
//   }

//   if (nameField && !newFields.some((f) => f.name === nameField.name)) {
//     newFields.unshift(nameField);
//   }

//   // Sort - date field goes last
//   newFields.sort((a, b) => {
//     if (a.name === currAsOfDateFieldName) return 1;
//     if (b.name === currAsOfDateFieldName) return -1;
//     return 0;
//   });

//   return newFields;
// };

const ChecklistTable = ({
  layer,
  editedFeatures,
  generateQueryFields,
  setEditedFeatures,
  onRowDataUpdated,
  loading,
  setGridApi,
  setFeatures,
  features,
  graphicsLayer,
  openSnackbar,
  gridApi,
  batchUpdateFeatures = [],
  filterGeometry = null,
  isDataTable = false,
}) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [layerLoading, setLayerLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    show: false,
    message: "",
  });
  const [showNowRows, setShowNoRows] = useState(false);

  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const {
    layersUpdates,
    cycleUpdateDueDate,
    lastCycleUpdate,
    cycleUpdateFrequency,
  } = useSelector((state) => state.cycleManagerReducer);
  const abortControllerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editedFeatures && Object.keys(editedFeatures).length === 0) return;

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // This is required for Chrome to show the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [editedFeatures]);

  useEffect(() => {
    if (!gridApi) return;
    const columnDef = gridApi.getColumnDef("featureSelect");

    if (columnDef) {
      columnDef.headerComponentParams = {
        ...columnDef.headerComponentParams,
        editedFeatureIds: Object.keys(editedFeatures).map(Number),
      };

      gridApi.refreshHeader();
    }
  }, [editedFeatures]);

  const updateSituationalLayer = useCallback(
    (layer, features) => {
      if (
        layer.fields.some((f) => f.name.includes(currAsOfDateFieldName)) &&
        layer.layerConfig?.situationalFields?.length > 0
      ) {
        const layerUpdate = layersUpdates[layer.layerConfig.id]
          ? { ...layersUpdates[layer.layerConfig.id] }
          : {};

        layerUpdate.features = features;
        layerUpdate.outdated = calculateMissingFeatures(
          features,
          lastCycleUpdate,
          cycleUpdateDueDate,
          cycleUpdateFrequency
        );

        dispatch(
          addLayersUpdate({
            id: layer.layerConfig.id,
            layerUpdate: layerUpdate,
          })
        );
      }
    },
    [layersUpdates, lastCycleUpdate, cycleUpdateDueDate, cycleUpdateFrequency]
  );

  useEffect(() => {
    setEditedFeatures({});
    // const cluster = layer.layerConfig.cluster;
    // if (!cluster || !cluster.isClustered) return;
    // graphicsLayer.featureReduction = {
    //   type: "cluster",
    //   clusterRadius: "120px",
    //   labelingInfo: [
    //     {
    //       labelExpressionInfo: {
    //         expression: "$feature.cluster_count",
    //       },
    //       deconflictionStrategy: "none",
    //       labelPlacement: "center-center",
    //       symbol: {
    //         type: "text",
    //         color: "white",
    //         font: { size: "12px" },
    //         haloSize: 1,
    //         haloColor: "black",
    //       },
    //     },
    //   ],
    // };

    // if (cluster.clusterMaxSize)
    //   graphicsLayer.featureReduction.clusterMaxSize =
    //     cluster.clusterMaxSize + "px";

    // if (cluster.clusterMinSize)
    //   graphicsLayer.featureReduction.clusterMinSize =
    //     cluster.clusterMinSize + "px";
  }, [layer, config]);

  useEffect(() => {
    setRows([]);
    setShowNoRows(false);

    return () => {};
  }, [filterGeometry]);

  useEffect(() => {
    let timer;
    if (!layer || loading) return;

    setColumns(null);
    setShowNoRows(false);
    setRows([]);

    const newFields = generateQueryFields(layer);

    // const newFields = addMissingFields(layer, queryFields);

    const columns = [];

    const featureSelectColumn = generateColumn({
      field: { name: "featureSelect" },
      config,
      layer,
      t,
      isEditable: false,
      maxWidth: 40,
      minWidth: 40,
      pinned: undefined,
    });

    iconsToHeaders(columns, newFields, layer);
    if (!isDataTable) {
      featureSelectColumn.headerComponent = FeatureSelectHeader;
    }

    columns.push(featureSelectColumn);
    const situationalFields = layer.layerConfig?.situationalFields || [];
    const baselineFields = layer.layerConfig?.baselineFields || [];

    if (!isDataTable) {
      columns.unshift(generateUpdateColumn({ config, layer, t }));
    }

    newFields.map((field) => {
      const column = generateColumn({
        field,
        config,
        layer,
        t,
        minWidth: isYesNoDomain(field, layer) ? 66 : 120,
        maxWidth: isYesNoDomain(field, layer) ? 66 : 300,
        hide: field.name === showpublicFieldName && !isDataTable,
        isEditable:
          field.name !== currAsOfDateFieldName &&
          field.name !== asOfDateFieldName &&
          config.role === ROLE_EDITOR,
        cellStyle: (params) => {
          const hasMissingField = params.data.missingFields
            ? params.data.missingFields.includes(params.colDef.field)
            : false;

          if (
            params.colDef.field === currAsOfDateFieldName ||
            params.colDef.field === asOfDateFieldName
          ) {
            return {
              backgroundColor: "#f0f0f0",
              // params.data.update !== CYCLE_UPDATE_TYPES.OUTDATED
              //   ? "#22c55e"
              //   : "#f0f0f0",
              opacity: 0.7,
              pointerEvents: "none",
              color: "#000000",
              // params.data.update !== CYCLE_UPDATE_TYPES.OUTDATED
              //   ? "#000000"
              //   : "#00000091",
            };
          }

          return {
            borderColor: hasMissingField ? "red" : "",
          };
        },
        cellClass: (params) => {
          const objectId = params.data[params.data.objectIdField];
          const value = params.value === "NULL" ? null : params.value;

          let isDirty = false;

          if (editedFeatures[objectId]) {
            isDirty = editedFeatures[objectId][field.name] !== undefined;
          }

          return isDirty ? "ag-cell-dirty" : "";
        },
        pinned: undefined,
        headerClass: isDataTable
          ? situationalFields.includes(field.name)
            ? "ag-grid-situational"
            : baselineFields.includes(field.name)
            ? "ag-grid-baseline"
            : ""
          : "",
      });
      columns.push(column);
      return column;
    });

    //if we have name we pin all columns before it
    // const name = getFeatureNameField(layer);
    // const names = getFeatureNameFields(layer);
    // const nameFieldIndex = name
    //   ? columns.findIndex((colDef) => colDef.field === name)
    //   : -1;

    // if (nameFieldIndex > -1) {
    //   columns.forEach((column, index) => {
    //     column.pinned = index <= nameFieldIndex ? "left" : undefined;
    //   });
    // }

    //if we have names we pin all columns before the last name field
    const names = getFeatureNameFields(layer);
    if (names.length > 0) {
      // Find the index of the last name field in the columns
      const lastNameFieldIndex = Math.max(
        ...names.map((name) => columns.findIndex((col) => col.field === name))
      );

      if (lastNameFieldIndex > -1) {
        columns.forEach((column, index) => {
          column.pinned = index <= lastNameFieldIndex ? "left" : undefined;
        });
      }
    }

    timer = setTimeout(() => {
      setColumns(columns);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [layer, config]);

  useEffect(() => {
    if (!layer || loading) return;
    const newFields = generateQueryFields(layer);
    // const newFields = addMissingFields(layer, queryFields);

    let timer;
    const fetchLayer = async (fields) => {
      return new Promise((resolve, reject) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          try {
            setLayerLoading(true);
            //to include colorMap fields in outFields to generate icon
            const { iconMap = {} } = getLayerSymbology(layer, config) || {};

            const outFieldsSet = new Set();
            outFieldsSet.add(layer.objectIdField);

            if (iconMap.field) {
              outFieldsSet.add(iconMap.field);
            } else if (Array.isArray(iconMap.fields)) {
              iconMap.fields.forEach((field) => outFieldsSet.add(field));
            }

            fields.forEach((field) => outFieldsSet.add(field.name));

            const outFields = Array.from(outFieldsSet);

            if (abortControllerRef.current) {
              abortControllerRef.current.abort();
            }

            abortControllerRef.current = new AbortController();

            let hasMore = true;
            const allFeatures = [];
            let start = 0;
            const num = 50;

            while (hasMore) {
              const query = {
                outFields,
                where: layer.definitionExpression,
                spatialRelationship: "intersects",
                returnGeometry: true,
                start: start,
                num: num,
              };

              if (filterGeometry) {
                query.geometry = filterGeometry;
              }

              const result = await layer.queryFeatures(query, {
                signal: abortControllerRef.current.signal,
              });

              allFeatures.push(...result.features);

              if (result.features.length < num) {
                hasMore = false;
              } else {
                start += num;
                await delay(200);
              }
            }

            //updates situational layer outdated features if layer is situational
            updateSituationalLayer(layer, allFeatures);

            let tableFeatures = allFeatures;

            if (
              !isDataTable &&
              newFields.some((f) => f.name === showpublicFieldName)
            ) {
              const onlyPublicFeatures = allFeatures.filter((f) =>
                showpublicFieldName in f.attributes
                  ? f.attributes[showpublicFieldName] === 1
                  : true
              );
              tableFeatures = onlyPublicFeatures;
            }

            const tableRows = generateRows({
              features: tableFeatures,
              fields: fields,
              layer,
              config,
              t,
            });

            const batchUpdateFeaturesIds = batchUpdateFeatures.map(
              (item) => item.feature.attributes[layer.objectIdField]
            );

            if (batchUpdateFeaturesIds.length === 0 && !isDataTable) {
              zoomToFeaturesExtent(tableFeatures);
            }

            const rows = tableRows.map((item) => {
              const row = {
                ...item,
                featureSelect: batchUpdateFeaturesIds.includes(
                  item[layer.objectIdField]
                ),
                lastCycleUpdate: lastCycleUpdate,
                cycleUpdateDueDate: cycleUpdateDueDate,
                missingFields: [],
              };

              if (Object.values(editedFeatures).length > 0 && isDataTable) {
                const editedFeature = editedFeatures[item[layer.objectIdField]];
                if (editedFeature) {
                  row.featureSelect = true;
                  Object.keys(editedFeature).forEach((field) => {
                    row[field] = editedFeature[field];
                  });
                }
              }

              if (!isDataTable) {
                item.update = "update";
              }

              return row;
            });

            graphicsLayer.removeAll();

            const addGraphics = async () => {
              const graphics = [];
              for (const feature of tableFeatures) {
                const graphic = await createFeatureGraphic(feature, layer);
                if (
                  batchUpdateFeaturesIds.length > 0 &&
                  !batchUpdateFeaturesIds.includes(feature.getObjectId())
                ) {
                  graphic.visible = false;
                }
                graphics.push(graphic);
              }

              return graphics;
            };

            const graphics = await addGraphics();
            graphicsLayer.addMany(graphics);
            graphicsLayer.effect = undefined;
            setFeatures(allFeatures);
            setShowNoRows(rows.length === 0);

            setRows(rows);
            resolve(rows);
          } catch (err) {
            if (err.name === "AbortError") return;
            console.log(err);
            setRows([]);
            setShowNoRows(true);
          } finally {
            setLayerLoading(false);
          }
        }, 250);
      });
    };

    fetchLayer(newFields);

    const watcher = layer.watch("definitionExpression", () => {
      fetchLayer(newFields);
    });

    return () => {
      clearTimeout(timer);
      if (watcher) {
        watcher.remove();
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [layer, config, filterGeometry]);

  const onCellClicked = useCallback(
    (event) => {
      const { colDef, data, node, value, api } = event;

      if (colDef.field === "featureSelect") {
        const objectIdField = data.objectIdField;
        const objectId = data[objectIdField];
        if (editedFeatures[objectId]) {
          console.warn("Cannot deselect feature with unsaved changes");
          openSnackbar(t("cycleManager.deselectWarning"), 5000);
          return;
        }

        const newData = { ...data };

        if (!value) {
          newData[colDef.field] = true;
          node.setData(newData);
        } else {
          newData[colDef.field] = false;
          node.setData(newData);
        }

        // Use transaction update
        api.applyTransaction({
          update: [node],
        });
      }
    },
    [layer, editedFeatures]
  );

  const updateNodeSelection = useCallback((node, data, api, isSelected) => {
    //changing selection of feature for confirm update
    const newData = {
      ...data,
      featureSelect: isSelected,
    };

    node.updateData(newData);
    //need to apply transaction to update the row styles (for selecting feature)
    api.applyTransaction({
      update: [node],
      columns: ["featureSelect"],
      force: true,
    });
  }, []);

  const onCellValueChanged = useCallback(
    (event) => {
      const { colDef, data, node, column, oldValue, api } = event;

      const newData = {
        ...data,
      };
      let newValue = event.newValue === "NULL" ? null : event.newValue;

      if (colDef.cellEditorParams?.maxLength) {
        if (
          typeof newValue === "string" &&
          newValue.length > colDef.cellEditorParams?.maxLength
        ) {
          newValue = newValue.slice(0, colDef.cellEditorParams?.maxLength);
          setShowMessage({
            show: true,
            message: t("cycleManager.maxLengthWarning", {
              1: colDef.cellEditorParams?.maxLength,
            }),
          });

          setTimeout(() => {
            setShowMessage({
              show: false,
              message: "",
            });
          }, 3000);
        }
      }

      if (colDef.cellDataType === "date") {
        newValue = typeof newValue === "object" ? newValue.getTime() : "";
        newData[colDef.field] = newValue;
      }

      const objectIdField = newData.objectIdField;
      const objectId = newData[objectIdField];

      //to get the latest value of feature saved in the layer
      const feature = features.find(
        (feat) => feat.attributes[objectIdField] === objectId
      );
      const featureValue = feature.attributes[colDef.field];

      if (newValue === null) {
        newData[colDef.field] = newValue;
      }

      if (featureValue === newValue) {
        const { [objectId]: attributes, ...restEditedFeatures } =
          editedFeatures;
        const { [colDef.field]: changedField, ...restAttributes } =
          attributes || {};

        if (Object.values(restAttributes).length > 0) {
          restEditedFeatures[objectId] = restAttributes;
        }

        colDef.cellClass = (p) => {
          const isDirty = restAttributes[p.colDef.field] !== undefined;
          return isDirty ? "ag-cell-dirty" : "";
        };

        if (!restEditedFeatures[objectId]) {
          updateNodeSelection(node, newData, api, false);
        }

        setEditedFeatures(restEditedFeatures);
      } else {
        const newEditedFeatures = {
          ...editedFeatures,
          [objectId]: {
            ...editedFeatures[objectId],
            [colDef.field]: newValue,
          },
        };
        setEditedFeatures(newEditedFeatures);

        //adding border to cell to show that value changed
        colDef.cellClass = (p) => {
          const featureId = p.data[objectIdField];
          let isDirty = false;
          if (editedFeatures[featureId]) {
            isDirty = editedFeatures[featureId][p.colDef.field] !== undefined;
          }

          if (p.rowIndex.toString() === node.id) {
            isDirty = true;
          }
          return isDirty ? "ag-cell-dirty" : undefined;
        };
      }

      updateNodeSelection(node, newData, api, true);
      event.api.refreshCells({
        columns: [column.getId()],
        rowNodes: [node],
        force: true, // without this line, the cell style is not refreshed at the first time
      });
    },
    [features, editedFeatures, config]
  );

  const showLoader = useMemo(
    () =>
      loading ||
      (Object.keys(layersUpdates).length === 0 && !isDataTable) ||
      layerLoading ||
      !columns,
    [loading, layerLoading, layersUpdates, isDataTable]
  );

  if (!layer) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "0px 10px",
        flex: 1,
      }}
    >
      {showLoader && <Loader position="absolute" bg="#eeeeeed4" />}

      {showMessage.show && (
        <Message
          title={showMessage.message}
          position="absolute"
          bg="#eeeeeed4"
        />
      )}

      <StyledCheckListTableWrapper>
        {showNowRows && (
          <StyledErrorOverlay>
            <span>{t("screen.widget.FeatureTable.noData")}</span>
          </StyledErrorOverlay>
        )}
        {columns && (
          <LayerTable
            rows={rows}
            columns={columns}
            layer={layer}
            features={features}
            setGridApi={setGridApi}
            onCellClicked={onCellClicked}
            onCellValueChanged={onCellValueChanged}
            onRowDataUpdated={onRowDataUpdated}
            suppressNoRowsOverlay={true}
            isDataTable={isDataTable}
          />
        )}
      </StyledCheckListTableWrapper>
    </div>
  );
};

export default ChecklistTable;
