import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOMServer from "react-dom/server";
import { LineSymbol } from "../components/ReportManager/symbols";
import { getFeatureTitle, getLayerTitle } from "../esri/custom-popup-content";
import { currAsOfDateFieldName } from "../utils/API";
import { timeAgo } from "../utils/helper";

const useFeature = (feature, t) => {
  const [iconUrl, setIconUrl] = useState();
  const symbolCache = useRef(new Map());
  const layer = feature?.sourceLayer ? feature?.sourceLayer : feature?.layer;

  useEffect(() => {
    if (!feature || !layer?.renderer) return;

    const featureId = feature.getObjectId();
    // Check if we have cached symbol for this feature
    if (symbolCache.current.has(featureId)) {
      setIconUrl(symbolCache.current.get(featureId));
      return;
    }

    layer.renderer.getSymbolAsync(feature).then((symbol) => {
      let url;
      if (symbol.url) {
        url = symbol.url;
      } else if (symbol.type === "simple-line" && symbol.color) {
        const color = symbol.color ? symbol.color.toHex() : "grey";
        url =
          "data:image/svg+xml;base64," +
          btoa(
            ReactDOMServer.renderToStaticMarkup(
              <LineSymbol stroke={color} dash={symbol.style === "dash"} />
            )
          );
      }

      if (url) {
        // Cache the symbol URL
        symbolCache.current.set(featureId, url);
        setIconUrl(url);
      }
    });
  }, [feature]);

  const getImage = useCallback(() => {
    if (iconUrl) {
      return <img draggable={false} alt="feature-icon" src={iconUrl} />;
    } else {
      return null;
    }
  }, [iconUrl]);

  const editDateFieldName = useMemo(
    () => currAsOfDateFieldName ?? feature.layer?.editFieldsInfo?.editDateField,
    [feature]
  );

  const formatDate = useMemo(
    () =>
      feature?.attributes[editDateFieldName]
        ? timeAgo(new Date(feature.attributes[editDateFieldName]))
        : "",
    [editDateFieldName, feature]
  );
  const featureTitle = useMemo(() => {
    if (!feature) return "";

    let title = getFeatureTitle(feature, t);
    if (!title) {
      title = getLayerTitle(layer, t);
    }
    return title;
  }, [feature, layer]);

  return {
    getImage,
    editDateFieldName,
    dateTimeAgo: formatDate,
    title: featureTitle,
  };
};

export default useFeature;
