import { loadModules } from "esri-loader";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useORSReverseSearch } from "../../../../hooks";
import { graphicsLayer, view } from "../../../../utils/API";
import { getConfigISO } from "../../../../utils/helper";
import { createSymbol, getLayerSymbology } from "../../../../utils/symbologies";
import { HiCursorClick } from "../../../Icons";
import { Checkbox } from "../../../UI";
import { StyledTitle } from "../Editor-styled";
import { EditorContext } from "../EditorContextProvider";
import { EditType, pointSymbol } from "../EditorSwiper/EditorSwiper";
import Message from "../Message";
import CoordinatesInputs from "./CoordinatesInputs";

const PointCoordinates = ({
  editableLayer,
  config,
  clickActive,
  editType,
  handleNext,
  disabled = false,
  showLocationInput = false,
}) => {
  const {
    addedPoints,
    setAddedPoints,
    setHighlightSymbol,
    setHighlightFeature,
    highlightFeature,
    setHighlightHandle,
    highlightSymbol,
    highlightHandle,
    activeColor,
    prefilledFields,
    setPrefilledFields,
  } = useContext(EditorContext);
  const { t } = useTranslation("common");
  const [isoMessage, setIsoMessage] = useState({
    show: false,
    point: null,
    value: null,
    fieldName: null,
    zoom: false,
  });
  const [locationDisabled, setLocationDisabled] = useState(disabled);
  const { fetchReverseGeocode, abortRequest } = useORSReverseSearch();
  const { setValue } = useFormContext();
  // const showInputRef = useRef(showLocationInput);

  //to disabled input when accordion is closed
  useEffect(() => {
    if (!showLocationInput && disabled) {
      setLocationDisabled(true);
    }
  }, [showLocationInput]);

  const onLocationInputChange = (e) => {
    const { checked } = e.target;

    setLocationDisabled(!checked);
  };

  const handleSetIso3 = ({ value, name, point, zoom }) => {
    setValue(name, value);
    addPointToNewFeature(point, zoom);
  };

  const processPoint = async (newPoint, zoom = true) => {
    try {
      const iso3Field = editableLayer.fields.find(
        (field) => field.name === "iso3" || field.name === "iso3a"
      );

      if (iso3Field) {
        const { latitude, longitude } = newPoint;
        document.body.style.cursor = "wait";
        abortRequest();
        const result = await fetchReverseGeocode({ longitude, latitude });
        document.body.style.cursor = "default";

        if (Array.isArray(result?.features) && result.features.length > 0) {
          const feature = result.features[0];
          const iso3 = feature.properties?.country_a;
          const moduleIsoCodes = getConfigISO(config);

          handleSetIso3({
            value: iso3,
            point: newPoint,
            name: iso3Field.name,
            zoom: zoom,
          });

          // if (moduleIsoCodes.length === 0 || moduleIsoCodes.includes(iso3)) {
          //   handleSetIso3({
          //     value: iso3,
          //     point: newPoint,
          //     name: iso3Field.name,
          //     zoom: zoom,
          //   });
          // } else {
          //   setIsoMessage({
          //     show: true,
          //     point: newPoint,
          //     value: iso3,
          //     fieldName: iso3Field.name,
          //     zoom: zoom,
          //   });
          // }
        } else {
          throw new Error("openrouteservice response is empty");
        }
      } else {
        throw new Error("iso3 field not found");
      }
    } catch (error) {
      console.log(error);
      addPointToNewFeature(newPoint, zoom);
    }
  };

  const addPointToNewFeature = (newPoint, zoom = true) => {
    const newAddedPoints = [...addedPoints];
    newAddedPoints.pop();
    newAddedPoints.push(newPoint);

    if (highlightFeature && editType === EditType.edit) {
      highlightFeature.geometry.latitude = newPoint.latitude;
      highlightFeature.geometry.longitude = newPoint.longitude;

      const nGraphic = highlightFeature?.clone();
      nGraphic.symbol = highlightSymbol ? highlightSymbol : pointSymbol;

      graphicsLayer.removeAll();
      graphicsLayer.add(nGraphic);
      if (zoom) {
        view
          .goTo(nGraphic)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }

      setHighlightFeature(nGraphic);
      if (clickActive) {
        handleNext();
        // editableLayer.effect = LAYER_EFFECT;
      }
      setAddedPoints(newAddedPoints);
      return;
    }
    view.graphics.removeAll();
    const geomType = editableLayer.geometryType;
    loadModules(["esri/Graphic"]).then(([Graphic]) => {
      const symbology = getLayerSymbology(editableLayer, config);
      let iconName;
      let attributes = {};

      prefilledFields.forEach((item) => {
        if (
          item.fieldName === "aerodromeclass" ||
          item.fieldName === "type_ann"
        ) {
          iconName = item.iconName;
        }
        attributes[item.fieldName] = item.code;
      });

      let symbol = highlightSymbol ? highlightSymbol : null;
      if (symbology && !highlightSymbol) {
        symbol = createSymbol(
          symbology,
          editableLayer,
          iconName ?? symbology.defaultIcon,
          symbology.defaultColor,
          symbology.defaultSize
        );
      }

      const nGraphic = new Graphic({
        geometry: newPoint,
        attributes: attributes,
        symbol: symbol ? symbol : pointSymbol,
      });

      if (geomType === "point") {
        //for point
        graphicsLayer.removeAll();
        graphicsLayer.add(nGraphic);
        view
          .whenLayerView(editableLayer)
          .then((lv) => {
            if (clickActive) {
              // editableLayer.effect = LAYER_EFFECT;
            }
            highlightHandle?.remove();
            setHighlightHandle(lv.highlight(nGraphic));
            setHighlightFeature(nGraphic);
            setHighlightSymbol(symbol);
          })
          .catch((err) => {
            console.log(err);
          });

        if (zoom) {
          view
            .goTo(nGraphic)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        graphicsLayer.add({ geometry: newPoint, symbol: pointSymbol });
      }

      setAddedPoints(newAddedPoints);
    });
  };

  //when editor creating new feature add point symbol to the map
  useEffect(() => {
    if (locationDisabled || isoMessage.show) return;

    let clickListener;
    // if (editType !== EditType.create && !clickActive) return;
    const geomType = editableLayer.geometryType;

    clickListener = view.on("click", (event) => {
      if (geomType === "point") {
        event.stopPropagation();
        processPoint(event.mapPoint, false);
      }
    });

    return () => {
      clickListener?.remove();
    };
  }, [
    highlightHandle,
    highlightFeature,
    editType,
    highlightSymbol,
    locationDisabled,
    prefilledFields,
    isoMessage.show,
  ]);

  // if (EditType.edit === editType && clickActive) {
  //   return (
  //     <EditableFeaturesList
  //       editableLayer={editableLayer}
  //       handleSelectFeature={handleSelectFeature}
  //       handleNext={handleNext}
  //     />
  //   );
  // }
  const onReset = () => {
    if (graphicsLayer) {
      graphicsLayer.removeAll();
    }
    setAddedPoints([]);
  };

  return (
    <div>
      {isoMessage.show && (
        <Message
          title={t(
            "screen.widget.Editor.manager.messages.isoMessage.coordinateChange"
          )}
          subtitle={t(
            "screen.widget.Editor.manager.messages.isoMessage.subtitle"
          )}
          onSubmit={() => {
            handleSetIso3({
              value: isoMessage.value,
              point: isoMessage.point,
              name: isoMessage.fieldName,
              zoom: isoMessage.zoom,
            });
            setIsoMessage({
              show: false,
              point: null,
              value: null,
              fieldName: null,
              zoom: false,
            });
          }}
          onCancel={() => {
            setIsoMessage({
              show: false,
              point: null,
              value: null,
              fieldName: null,
              zoom: false,
            });
          }}
        />
      )}
      {showLocationInput && (
        <div
          style={{
            padding: "4px 0px 8px 0px",
          }}
        >
          <Checkbox
            onChange={onLocationInputChange}
            label={t("screen.widget.Editor.manager.location.allowEditLocation")}
            color={activeColor}
            borderRadius="4px"
            checked={!locationDisabled}
          />
        </div>
      )}
      {!locationDisabled && (
        <StyledTitle>
          <span
            style={{
              minWidth: 18,
              minHeight: 18,
              marginRight: 4,
              position: "relative",
              top: 2,
            }}
          >
            <HiCursorClick width="18" height="18" />
          </span>
          {t("screen.widget.Editor.manager.location.title")}
        </StyledTitle>
      )}
      <CoordinatesInputs
        clickActive={clickActive}
        addPointToNewFeature={processPoint}
        editType={editType}
        geometry={highlightFeature?.geometry}
        showAll={true}
        color={activeColor}
        t={t}
        showConversion
        disabled={locationDisabled}
        onReset={onReset}
      />
    </div>
  );
};

export default PointCoordinates;
