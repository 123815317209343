import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { InformationIcon } from "../../../Icons";
import { Tooltip } from "../../../UI";
import {
  DescriptionWrapper,
  InputContainer,
  InputLabel,
  MaxLength,
  WarningMessage,
} from "./Input-styled";

const Input = ({
  label,
  register = {},
  name,
  errorMessage,
  borderColor,
  margin,
  noMargin,
  information,
  children,
  isFocused,
  isRequired,
  inputTooltipText,
  onChange,
  focusColor,
  type = "text",
  ...rest
}) => {
  const [value, setValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;

      const handleWheel = (e) => {
        // Only prevent default if input is focused
        if (document.activeElement === input) {
          // Stop only the number spinner behavior
          // e.preventDefault();
          input.blur();
        }
      };

      // Remove capture and only listen for wheel events normally
      input.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        input.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      let v = rest.value ?? rest.defaultValue ?? inputRef.current?.value ?? "";

      if (v === "") {
        v = inputRef.current?.value ?? "";
      }

      if (v) {
        setValue(v);
      }
    });
  }, [rest.value, rest.defaultValue]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      setValue(value);

      // Call the react-hook-form onChange handler if it exists
      if (register && register.onChange) {
        register.onChange(e);
      }

      // Call the custom onChange handler if it exists
      if (onChange) {
        onChange(e);
      }
    },
    [onChange, register]
  );

  return (
    <InputContainer
      label={label}
      noMargin={!errorMessage && noMargin}
      margin={margin}
      borderColor={borderColor}
      focusColor={focusColor}
      isFocused={isFocused}
      isError={!!errorMessage}
    >
      {inputTooltipText && (
        <Tooltip
          wrapperStyles={{
            cursor: rest.disabled ? "not-allowed" : "pointer",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
          content={inputTooltipText}
          showOnHover
        >
          <div />
        </Tooltip>
      )}
      <input
        name={name}
        {...register}
        ref={(e) => {
          // Connect react-hook-form's ref
          if (register.ref) {
            register.ref(e);
          }

          // Connect our local ref
          inputRef.current = e;
        }}
        onChange={handleChange}
        type={type}
        {...rest}
      />
      {label && label.length > 0 && (
        <InputLabel>
          {label}
          {isRequired ? <span style={{ color: "red" }}>*</span> : ""}
        </InputLabel>
      )}
      {rest.maxLength && (
        <MaxLength>
          {value.length} / {rest.maxLength}
        </MaxLength>
      )}
      <WarningMessage isError={!!errorMessage}>{errorMessage}</WarningMessage>
      {information && (
        <DescriptionWrapper>
          <Tooltip showOnClick content={information}>
            <InformationIcon width="18" height="18" />
          </Tooltip>

          {/* <Dropdown
            setShow={setShow}
            show={show}
            leftOffset={0.3}
            hasMinWidth={false}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <InformationIcon width="18" height="18" />
            </div>
            <DropdownBody type="tooltip" style={{ display: "flex" }}>
              {description}
            </DropdownBody>
          </Dropdown> */}
        </DescriptionWrapper>
      )}
      {children}
    </InputContainer>
  );
};

export default Input;
