import React, { useCallback, useContext } from "react";
import {
  getIconValueDefinitions,
  getLayerSymbology,
} from "../../../../../utils/symbologies";
import {
  iconNameToIcon,
  svgToBase,
} from "../../../../Icons/layerSvg/clusterAssets";
import { StyledList, StyledListItem, StyledTitle } from "../../Editor-styled";
import { LayerIconWrapper } from "../../../../ManualWidgets/ManualWidget-styled";
import { EditorContext } from "../../EditorContextProvider";

const AerodromeClass = ({ layer, config, handleSelection, t, type }) => {
  const { activeColor } = useContext(EditorContext);
  const renderFields = useCallback(() => {
    if (!type) return [];

    const symbology = getLayerSymbology(layer, config);
    const iconValueDefinition = getIconValueDefinitions(symbology, layer, 1, t);
    const filteredIconDefinition = iconValueDefinition.filter((item) => {
      const [aerodromeClass, aerodromeType] = item.value.split(",");
      return aerodromeType == type;
    });

    return filteredIconDefinition.map((item) => {
      const [code] = item.value.split(",").map((value) => Number(value));
      return {
        code: Number.isFinite(code) ? code : 0,
        icon: svgToBase(item.icon, symbology.defaultColor),
        label: item.label,
        iconName: item.icon,
        fieldName: "aerodromeclass",
      };
    });
  }, [layer, type, config, t]);

  return (
    <div style={{ paddingTop: "8px" }}>
      <StyledTitle>Select Aerodrome class</StyledTitle>
      <StyledList>
        {renderFields().map((item, index) => {
          const icon = iconNameToIcon(item.iconName);
          return (
            <StyledListItem
              key={`${item.label}-${index}`}
              onClick={() => {
                handleSelection(item);
              }}
            >
              <LayerIconWrapper
                width={40}
                height={40}
                fill={activeColor}
                dangerouslySetInnerHTML={{ __html: icon }}
              />
              {/*<div style={{*/}
              {/*  width:'40px',*/}
              {/*  height:'40px',*/}
              {/*}}>*/}
              {/*  <img*/}
              {/*    alt={item.label}*/}
              {/*    style={{*/}
              {/*      width:'100%',*/}
              {/*      height:'100%',*/}
              {/*      objectFit:'contain'*/}
              {/*    }}*/}
              {/*    src={item.icon} />*/}
              {/*</div>*/}
              <span>{item.label}</span>
            </StyledListItem>
          );
        })}
      </StyledList>
    </div>
  );
};

export default AerodromeClass;
