import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LOGIE_API_ENDPOINT } from "../../../../../utils/API";
import { parseTextWithLinks } from "../../../../../utils/helper";
import { StyledProgress } from "../../../../App/App-styled";
import Tooltip from "../../../../UI/Tooltip";
import {
  StyledField,
  StyledFieldValue,
  StyledLearnMore,
  StyledTranslationText,
  StyledTranslationWrapper,
} from "../../MobilePopup-styled";

const TranslationIcon = () => (
  <svg
    aria-hidden="true"
    className="svg"
    fill="currentColor"
    height="12"
    viewBox="0 0 16 16"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.176 3L13.5 5.058 11.824 3H13v-.5A1.502 1.502 0 0 0 11.5 1H11V0h.5A2.503 2.503 0 0 1 14 2.5V3zM5 9H1.833A.834.834 0 0 1 1 8.167V1.833A.834.834 0 0 1 1.833 1h6.334A.834.834 0 0 1 9 1.833V5h1V1.833A1.835 1.835 0 0 0 8.167 0H1.833A1.835 1.835 0 0 0 0 1.833v6.334A1.835 1.835 0 0 0 1.833 10H5zm1.733-3.956A6.467 6.467 0 0 0 7.179 4H8V3H5.519l.128-.129L4.59 1.813l-.707.707.48.48H2v1h.768a6.176 6.176 0 0 0 1.418 2.414 5.84 5.84 0 0 1-1.79.768l.242.97A6.723 6.723 0 0 0 4.97 7.086c.011.008.025.014.036.022a2.167 2.167 0 0 1 1.727-2.064zM3.83 4h2.284a5.056 5.056 0 0 1-1.148 1.777A4.957 4.957 0 0 1 3.83 4zm10.362 12H7.808A1.81 1.81 0 0 1 6 14.192V7.808A1.81 1.81 0 0 1 7.808 6h6.384A1.81 1.81 0 0 1 16 7.808v6.384A1.81 1.81 0 0 1 14.192 16zM7.808 7A.809.809 0 0 0 7 7.808v6.384a.809.809 0 0 0 .808.808h6.384a.809.809 0 0 0 .808-.808V7.808A.809.809 0 0 0 14.192 7zm5.342 6.94l.899-.439L11 7.261l-3.049 6.24.899.44.46-.941h3.38zM9.798 12L11 9.54 12.202 12zM5 15v1h-.5A2.503 2.503 0 0 1 2 13.5V13H.824L2.5 10.942 4.176 13H3v.5A1.502 1.502 0 0 0 4.5 15z"></path>
  </svg>
);

const encodeForTranslation = (value) => {
  if (typeof value !== "string" || value === "")
    return { encodedText: value, specialCharsMap: [] };
  // Create a map to store original positions of special characters
  const specialCharsMap = [];

  return {
    encodedText: value.replace(/[\n\t]/g, (match, offset) => {
      const token = `[${match === "\n" ? "NL" : "TAB"}_${offset}]`;
      specialCharsMap.push({ token, char: match });
      return token;
    }),
    specialCharsMap,
  };
};

const decodeFromTranslation = (value, specialCharsMap) => {
  if (specialCharsMap.length === 0) {
    return value;
  }

  let decodedText = value;

  // Sort by token length (longest first) to avoid partial replacements
  specialCharsMap.sort((a, b) => b.token.length - a.token.length);

  // Replace each token with its original character
  specialCharsMap.forEach(({ token, char }) => {
    decodedText = decodedText.replace(token, char);
  });

  return decodedText;
};

const PopupField = ({
  title,
  value,
  resetLimit,
  onClick,
  textLimit = 90,
  isPopup,
  feature,
  field,
  i18n,
  isExpanded = true,
  t,
  style = {},
  fromPopup,
}) => {
  const [translation, setTranslation] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);
  const [showTranslation, setShowTranslation] = useState(false);
  const [isFullText, setIsFullText] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    setIsFullText(!!fromPopup && isExpanded);

    return () => {};
  }, [fromPopup, isExpanded]);

  useEffect(() => {
    let timer;
    const translatedFields =
      feature?.layer?.layerConfig?.customPopupOps?.translatedFields;
    let controller;

    if (
      !translatedFields ||
      !Array.isArray(translatedFields) ||
      !translatedFields.includes(field?.fieldName) ||
      (feature.attributes && feature.attributes[field.fieldName] === "")
    )
      return;

    timer = setTimeout(() => {
      const layer = feature?.layer;
      setIsTranslating(true);
      controller = new AbortController();
      const signal = controller.signal;

      const value = feature.attributes[field.fieldName];
      const { encodedText: encoded, specialCharsMap } =
        encodeForTranslation(value);

      fetch(
        LOGIE_API_ENDPOINT +
          "/getTranslation?" +
          new URLSearchParams({
            layerId: layer.originalId,
            objectId: feature.attributes[layer.objectIdField],
            fieldName: field.fieldName,
            value: encoded,
            lang: i18n.language === "en" ? "en-US" : i18n.language,
          }),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_LOGIE_API_KEY,
          },
          signal,
        }
      )
        .then((response) => {
          if (response.ok) return response.text();
        })
        .then((data) => {
          setIsTranslating(false);
          const trans = JSON.parse(data);

          if (
            trans.statusCode === 200 &&
            trans.detectedSourceLang !== i18n.language
          ) {
            const decodeValue = decodeFromTranslation(
              trans.body,
              specialCharsMap
            );

            setTranslation(decodeValue);
            setShowTranslation(true);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            console.warn("Translations Fetch aborted");
          } else {
            setIsTranslating(false);
            console.error("Error occurred fetching translations:", error);
          }
        });
    }, 250);

    return () => {
      clearTimeout(timer);
      if (controller) {
        controller.abort();
      }
    };
  }, [value]);

  const toggleTranslation = (e) => {
    e.stopPropagation();
    setShowTranslation((prev) => !prev);
  };

  const text = useMemo(() => {
    if (showTranslation && translation) {
      return translation;
    } else {
      return value;
    }
  }, [value, showTranslation, translation]);

  const translationText = useMemo(() => {
    if (showTranslation) {
      const text = t("screen.popup.actions.translation.seeOriginal");
      const [autoTranslated, restText] = text.split(")");

      return (
        <StyledTranslationWrapper onClick={toggleTranslation}>
          <span style={{ fontStyle: "italic" }}>{autoTranslated}</span>
          <span>&nbsp;{restText}</span>
        </StyledTranslationWrapper>
      );
    } else {
      return (
        <StyledTranslationWrapper onClick={toggleTranslation}>
          <span>{t("screen.popup.actions.translation.seeTranslated")}</span>
        </StyledTranslationWrapper>
      );
    }
  }, [showTranslation, t, toggleTranslation]);

  const isArabic = useMemo(() => {
    return i18n.language === "ar";
  }, []);

  useEffect(() => {
    if (fromPopup && !isExpanded) return;
    const element = textRef.current;
    setTimeout(() => {
      if (element) {
        const isTruncated = element.scrollWidth > element.clientWidth; // || splittedText.length > 1;

        setIsTruncated(isTruncated);
      }
    }, 100);
  }, [text, fromPopup, isExpanded]);

  const handleClick = (e) => {
    if (isTruncated) {
      e.stopPropagation();
      setIsFullText((prev) => !prev);
    }
  };

  const renderText = useCallback(() => {
    let text = value;

    if (showTranslation && translation) {
      try {
        const textArray = parseTextWithLinks(translation, true);
        text = textArray.join(" ");
      } catch (err) {
        console.log("Error parsing translation text:", err);
      }
    }

    if (typeof text !== "string" || !isFullText) {
      return <StyledFieldValue dangerouslySetInnerHTML={{ __html: text }} />;
    }

    let splittedText = text.split("\n");
    const formattedText = splittedText.map((item, index) => (
      <React.Fragment key={index}>
        <StyledFieldValue dangerouslySetInnerHTML={{ __html: item }} />
        {isFullText && <br />}
      </React.Fragment>
    ));

    return <StyledFieldValue>{formattedText}</StyledFieldValue>;
  }, [isFullText, isExpanded, showTranslation, translation, value]);

  return (
    <StyledField
      dir={isArabic ? "rtl" : ""}
      isArabic={isArabic}
      isPopup={isPopup}
      isFullText={isFullText}
    >
      <p>
        <span
          style={{
            cursor: isTruncated ? "pointer" : "",
          }}
          ref={textRef}
          className={`text-elipsis ${isFullText ? "text-expanded" : ""}`}
          onClick={handleClick}
        >
          {title && (
            <span dir={isArabic ? "rtl" : ""}>{title + ":"}&nbsp;</span>
          )}
          {/* {typeof value !== "string" || value.includes('target="_blank"') ? (
            <span
              style={style}
              dangerouslySetInnerHTML={{ __html: value }}
            ></span>
          ) : (
            // <UpdateComment
            //   text={text}
            //   textLimit={
            //     typeof text == "string"
            //       ? isExpanded
            //         ? text.length + 1
            //         : 70
            //       : 70
            //   }
            //   style={style}
            //   resetLimit={resetLimit}
            //   onClick={onClick}
            // />
            // <StyledFieldValue>{text}</StyledFieldValue>
            
          )} */}
          {renderText(text)}
        </span>
      </p>
      {(isExpanded || isPopup) && (
        <span
          style={{
            display: "flex",
            direction: isArabic ? "rtl" : "",
            flexDirection: "column",
          }}
        >
          {isTranslating && <StyledProgress type="indeterminate" />}
          {translation && (
            <StyledTranslationText isArabic={isArabic}>
              {translationText}
              <Tooltip
                placement="right"
                content={t(
                  "screen.popup.actions.translation.learnMore.tooltip"
                )}
                showOnClick
              >
                <StyledLearnMore>
                  ({t("screen.popup.actions.translation.learnMore.title")})
                </StyledLearnMore>
              </Tooltip>
            </StyledTranslationText>
          )}
        </span>
      )}
    </StyledField>
  );
};

export default PopupField;
