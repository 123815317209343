import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import SearchWidget from "../../../SearchWidget/SearchWidget";
import { ConfigContext } from "../../../../utils/ConfigContext";
import WidgetButton from "../../components/WidgetButton";

const SearchPortal = ({
  expandSearch,
  view,
  setExpandSearch,
  handleSearchExpand,
}) => {
  const [positions, setPositions] = useState({ top: 0, right: 0 });
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const [parentReady, setParentReady] = useState(false);
  const parentRef = useRef();

  useEffect(() => {
    const updatePositions = () => {
      if (!expandSearch) return;
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect();
        setPositions({
          top: rect.top - 7,
          right: window.innerWidth - rect.right + 4,
        });
      }
    };

    let resizeTimeout;
    const handleResize = () => {
      if (resizeTimeout) {
        cancelAnimationFrame(resizeTimeout);
      }
      resizeTimeout = requestAnimationFrame(updatePositions);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [expandSearch]);

  useEffect(() => {
    if (parentReady && parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setPositions({
        top: rect.top - 7,
        right: window.innerWidth - rect.right + 4,
      });
    }
  }, [parentReady]);

  const withNode = useCallback((el) => {
    if (!el) return;
    parentRef.current = el;
    setParentReady(true);
  }, []);

  return (
    <div
      ref={withNode}
      style={{ position: "relative" }}
      className="onboarding-search"
    >
      <WidgetButton
        className="gtag"
        data-tag-name="Search"
        id="search-widget"
        title={t("screen.widget.Search.name")}
        onClick={handleSearchExpand}
        type="SearchWidget"
      />
      {createPortal(
        <div
          style={{
            width: expandSearch ? 270 : 0,
            transition: "all 0.3s",
            position: "fixed",
            top: positions.top,
            right: positions.right,
            height: 54,
            background: "#fff",
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            opacity: expandSearch ? 1 : 0,
            pointerEvents: expandSearch ? "auto" : "none",
            zIndex: 2,
          }}
        >
          {view && expandSearch && (
            <SearchWidget
              config={config}
              t={t}
              view={view}
              isMobileApp={true}
              onBackClick={() => setExpandSearch(false)}
              rotate="rotate(180deg)"
              isExpanded={expandSearch}
            />
          )}
        </div>,
        document.body
      )}
    </div>
  );
};

export default SearchPortal;
