import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnimateFeatureTable,
  setEditableLayer,
} from "../../redux/action/Dashboard-action";
import { setFeatureTableOpen } from "../../redux/action/FeatureTable-actions";
import { showpublicFieldName } from "../../utils/API";
import { ConfigContext } from "../../utils/ConfigContext";
import {
  getFieldsByPattern,
  getOpsColor,
  isYesNoDomain,
  ROLE_EDITOR,
} from "../../utils/helper";
import { FaChevronRight } from "../Icons";
import { generateColumn } from "../LayerTable/LayerTable";
import {
  FeatureTableContainer,
  FeatureTableTooltip,
  SectionItem,
  StyledResizerButton,
  StyledTableContentWrapper,
  StyledTableTopBar,
} from "./FeatureTable-styled";
import TableGrid from "./TableGrid/TableGrid";

const TableIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16px"
    height="16px"
    {...props}
  >
    <path d="M0 1v14h16V1zm1 5h2v2H1zm8 2H4V6h5zm0 1v2H4V9zM1 9h2v2H1zm0 5v-2h2v2zm3 0v-2h5v2zm11 0h-5v-2h5zm0-3h-5V9h5zm0-3h-5V6h5zm0-3H1V2h14z" />
  </svg>
);
export const getColumnTemplate = (label, fieldSet, t, layer, config) => {
  const columns = {
    headerName: label,
    children: [],
  };

  columns.children = fieldSet.map((field) => {
    const defaultColumn = generateColumn({
      field,
      config,
      layer,
      t,
      isEditable: field.name !== "featureSelect",
      minWidth:
        field.name === showpublicFieldName
          ? 180
          : isYesNoDomain(field, layer)
          ? 140
          : field.alias
          ? 200
          : 50,
      maxWidth: undefined,
    });
    return defaultColumn;
  });

  return columns;
};

const generateFields = (currLayer, config) => {
  const { layerConfig } = currLayer || {};
  if (!layerConfig) return [];

  const visibilityFields = getFieldsByPattern(
    currLayer,
    layerConfig.visibilityFields,
    config.role !== ROLE_EDITOR
  );
  const situationalFields = getFieldsByPattern(
    currLayer,
    layerConfig.situationalFields,
    config.role !== ROLE_EDITOR
  );
  const baselineFields = getFieldsByPattern(
    currLayer,
    layerConfig.baselineFields,
    config.role !== ROLE_EDITOR
  );

  const efi = currLayer.editFieldsInfo;
  const editFields = efi
    ? getFieldsByPattern(
        currLayer,
        [
          efi.creationDateField,
          efi.creatorField,
          efi.editDateField,
          efi.editorField,
        ],
        config.role !== ROLE_EDITOR
      )
    : [];

  const fields = [
    ...situationalFields,
    ...baselineFields,
    ...visibilityFields,
    ...editFields,
  ];
  const outFields = fields.map((field) => field.name);
  return outFields;
};

const FeatureTable = ({ layersIds, count, view }) => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  const [visibleLayers, setVisibleLayers] = useState([]);
  const [currLayer, setCurrLayer] = useState();

  // const [columns, setColumns] = useState(null);
  // const [row, setRow] = useState(null);
  // const [features, setFeatures] = useState([]);
  // const [outFields, setOutFields] = useState([]);

  // const [filterByExtent, setFilterByExtent] = useState(true);

  // const [featureTableExpanded, setFeatureTableExpanded] = useState(false);
  const [featureTableFullScreenActive, setFeatureTableFullScreenActive] =
    useState(false);
  const { dashboard, featureTableReducer } = useSelector((state) => state);
  const { open: featureTableExpanded } = featureTableReducer;
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  const [editedFeatures, setEditedFeatures] = useState({});

  // const [gridApi, setGridApi] = useState();
  // const [activeIndex, setActiveIndex] = useState(0);

  // const gridRef = useRef();
  const featureTableRef = useRef();
  const visibleTimer = useRef(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!featureTableExpanded && featureTableRef.current) {
      featureTableRef.current.style.removeProperty("flex-basis");
    }
  }, [featureTableExpanded]);

  const toggleFeatureTable = useCallback((isOpen) => {
    requestAnimationFrame(() => {
      dispatch(setFeatureTableOpen(isOpen));
      if (!isOpen) {
        dispatch(setEditableLayer(null));
        setCurrLayer(null);
      }
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("datatable") === "open") {
      toggleFeatureTable(true);
      // dispatch(setFeatureTableOpen(true));
      openFullScreen();
      return;
    }

    if (
      Array.isArray(config.openWidgets) &&
      config.openWidgets.includes("FeatureTable") &&
      !config.embed
    ) {
      toggleFeatureTable(true);
      // dispatch(setFeatureTableOpen(true));
    }
  }, [t]);

  useEffect(() => {
    clearTimeout(visibleTimer.current);
    visibleTimer.current = setTimeout(() => {
      let layers = view.map.layers
        .filter((layer) => layersIds[layer.id])
        .reverse();
      const layerArray = layers.toArray();

      let selectedLayerIndex = layerArray.findIndex(
        (l) => l.id === currLayer?.id
      );

      if (dashboard.editableLayer) {
        const editableLayer = dashboard.editableLayer;
        selectedLayerIndex = layerArray.findIndex(
          (l) => l.id === editableLayer?.id
        );
      }

      const layerIndex = selectedLayerIndex > -1 ? selectedLayerIndex : 0;

      setVisibleLayers(layers.toArray());
      setCurrLayer(layers.toArray()[layerIndex]);
      // setActiveIndex(layerIndex);
    }, 250);
  }, [layersIds, count, dashboard.editableLayer]);

  // const defExpressionRef = useRef();
  // useEffect(() => {
  //   if (!featureTableExpanded || !currLayer) return;
  //   queryLayer(currLayer, filterByExtent);
  //   defExpressionRef.current?.remove();
  //   defExpressionRef.current = currLayer.watch("definitionExpression", () => {
  //     queryLayer(currLayer, filterByExtent);
  //   });

  //   return () => {
  //     defExpressionRef.current?.remove();
  //   };
  // }, [currLayer, config, filterByExtent, featureTableExpanded, filters]);

  useEffect(() => {
    if (featureTableExpanded) {
      closeTooltip();
    }
  }, [featureTableExpanded]);

  // const queryLayer = async (layer, filterByExtent) => {
  //   const { layerConfig } = layer || {};
  //   if (!layerConfig) {
  //     setColumns([]);
  //     setRow([]);
  //     return;
  //   }

  //   const visibilityFields = getFieldsByPattern(
  //     currLayer,
  //     layerConfig.visibilityFields,
  //     config.role !== ROLE_EDITOR
  //   );
  //   const situationalFields = getFieldsByPattern(
  //     currLayer,
  //     layerConfig.situationalFields,
  //     config.role !== ROLE_EDITOR
  //   );
  //   const baselineFields = getFieldsByPattern(
  //     currLayer,
  //     layerConfig.baselineFields,
  //     config.role !== ROLE_EDITOR
  //   );

  //   const efi = currLayer.editFieldsInfo;
  //   const editFields = efi
  //     ? getFieldsByPattern(
  //         currLayer,
  //         [
  //           efi.creationDateField,
  //           efi.creatorField,
  //           efi.editDateField,
  //           efi.editorField,
  //         ],
  //         config.role !== ROLE_EDITOR
  //       )
  //     : [];

  //   const fields = [
  //     ...situationalFields,
  //     ...baselineFields,
  //     ...visibilityFields,
  //     ...editFields,
  //   ];
  //   const outFields = fields.map((field) => field.name);

  //   const tableTemplate = {
  //     columnTemplates: [
  //       getColumnTemplate(
  //         "",
  //         [
  //           {
  //             name: "featureSelect",
  //           },
  //         ],
  //         t,
  //         layer,
  //         config
  //       ),
  //       getColumnTemplate(
  //         t("screen.widget.Editor.visibilityFields.label"),
  //         visibilityFields,
  //         t,
  //         layer,
  //         config
  //       ),
  //       getColumnTemplate(
  //         t("screen.widget.Editor.baselineFields.label"),
  //         baselineFields,
  //         t,
  //         layer,
  //         config
  //       ),
  //       getColumnTemplate(
  //         t("screen.widget.Editor.situationalFields.label"),
  //         situationalFields,
  //         t,
  //         layer,
  //         config
  //       ),
  //       getColumnTemplate(
  //         t("screen.widget.Editor.editFields.label"),
  //         editFields,
  //         t,
  //         layer,
  //         config
  //       ),
  //     ],
  //   };

  //   const columns = [];
  //   tableTemplate.columnTemplates.forEach((item) => {
  //     columns.push(...item.children);
  //   });

  //   setColumns(columns);

  //   if (layerConfig.featureTable.exportCoordinates) {
  //     outFields.push("latitude", "longitude");
  //   }
  //   setOutFields(outFields);
  //   const query = {
  //     outFields: [...fields.map((field) => field.name), layer.objectIdField],
  //     where: layer.definitionExpression,
  //     returnGeometry: layerConfig.featureTable.exportCoordinates,
  //   };

  //   if (filterByExtent) {
  //     query.geometry = filterByExtent ? view.extent : undefined;
  //     query.spatialRelationship = "intersects";
  //   }

  //   try {
  //     const result = await layer.queryFeatures(query);
  //     const row = [];
  //     const { colorMap } = getLayerSymbology(layer, config) || {};
  //     const rowColorMap = {};

  //     if (colorMap?.field) {
  //       rowColorMap[colorMap.field] = colorMap.default;
  //     }

  //     if (Array.isArray(colorMap?.fields)) {
  //       colorMap.fields.forEach((field) => {
  //         rowColorMap[field] = colorMap.default;
  //       });
  //     }

  //     result.features.forEach((feature) => {
  //       const rowData = {
  //         showToggleLabels: true,
  //         featureSelect: false,
  //         [layer.objectIdField]: feature.attributes[layer.objectIdField],
  //         objectIdField: layer.objectIdField,
  //         rowColorMap,
  //         feature,
  //       };

  //       fields.forEach((field) => {
  //         let cellValue = getCellValue({
  //           field,
  //           value: feature.attributes[field.name],
  //           config,
  //           t,
  //         });
  //         if (field.type === "date" && !feature.attributes[field.name]) {
  //           cellValue = "";
  //         }

  //         if (hasUrlPattern(feature.attributes[field.name])) {
  //           cellValue = feature.attributes[field.name];
  //         }

  //         rowData[field.name] = cellValue === null ? "NULL" : cellValue;
  //       });

  //       row.push(rowData);
  //     });

  //     setFeatures(result.features);
  //     setRow(row);
  //   } catch (err) {
  //     console.log(err);
  //     setColumns([]);
  //     setRow([]);
  //   }
  // };

  // const viewMovingRef = useRef({
  //   when: undefined,
  //   timer: undefined,
  // });

  // useEffect(() => {
  //   viewMovingRef.current.when?.remove();
  //   clearTimeout(viewMovingRef.current.timer);

  //   if (!filterByExtent) return;
  //   loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
  //     viewMovingRef.current.timer = setTimeout(() => {
  //       viewMovingRef.current.when = reactiveUtils.when(
  //         () => !!view?.stationary,
  //         () => {
  //           // Get the new extent of view/map whenever map is updated.
  //           if (view.extent) {
  //             // Filter out and show only the visible features in the feature table.
  //             queryLayer(currLayer, filterByExtent);
  //           }
  //         },
  //         { initial: false }
  //       );
  //     }, 250);
  //   });
  //   return () => {
  //     viewMovingRef.current.when?.remove();
  //     clearTimeout(viewMovingRef.current.timer);
  //   };
  // }, [currLayer, filterByExtent, config]);

  // const handleSwitchChange = useCallback((value) => {
  //   setFilterByExtent(value);
  // }, []);

  let mapDiv = document.getElementById("mapContainer");

  const onArrowClick = useCallback(() => {
    if (!featureTableRef.current) return;
    featureTableRef.current.style.transition = "all 0.5s";
    if (!featureTableExpanded) {
      toggleFeatureTable(true);
      // dispatch(setFeatureTableOpen(true));
      // setFilterByExtent(true);
    } else {
      if (Object.values(editedFeatures).length > 0) {
        setShowCloseMessage(true);
      } else {
        featureTableRef.current.style.removeProperty("flex-basis");
        toggleFeatureTable(false);
        setFeatureTableFullScreenActive(false);
      }
    }
  }, [featureTableRef, featureTableExpanded, editedFeatures]);

  const doDrag = (e) => {
    e.preventDefault();
    if (!featureTableRef.current) return;

    const appHeight = window.innerHeight;
    const headerHeight =
      document.getElementById("desktop-header")?.offsetHeight + 8 || 8; //8 is padding from top

    const disclaimerHeight =
      document.getElementById("disclaimer").offsetHeight || 0;

    const mapHeight = appHeight - headerHeight - disclaimerHeight - 20;

    if (!mapDiv) mapDiv = document.getElementById("mapContainer");
    mapDiv.style.cursor = "n-resize";
    featureTableRef.current.style.transition = "none";

    const tableFlexBasis = appHeight - e.clientY - disclaimerHeight;
    const isFullScreen = tableFlexBasis >= mapHeight;

    if (tableFlexBasis > 13 && !featureTableRef.current.style.flexBasis) {
      toggleFeatureTable(true);
      // dispatch(setFeatureTableOpen(true));
    }

    //when table height is near the bottom
    if (tableFlexBasis <= window.innerHeight / 2.8) {
      featureTableRef.current.style.removeProperty("flex-basis");
      // // setFeatureTableExpanded(false);
      // dispatch(setFeatureTableOpen(false));
      // setFeatureTableFullScreenActive(false);
      return;
    }

    if (isFullScreen) {
      openFullScreen();
    } else {
      featureTableRef.current.style.flexBasis = `${tableFlexBasis}px`;
    }

    setFeatureTableFullScreenActive(isFullScreen);
  };

  const openFullScreen = useCallback(() => {
    requestAnimationFrame(() => {
      const appHeight = window.innerHeight;
      const headerHeight =
        document.getElementById("desktop-header")?.offsetHeight + 8 || 8; //8 is padding from top

      const disclaimerHeight =
        document.getElementById("disclaimer").offsetHeight || 0;

      const mapHeight = appHeight - headerHeight - disclaimerHeight - 20;
      featureTableRef.current.style.flexBasis = `${mapHeight}px`;
      setFeatureTableFullScreenActive(true);
    });
  }, []);

  const exitFullScreen = useCallback(() => {
    requestAnimationFrame(() => {
      featureTableRef.current.style.removeProperty("flex-basis");
      setFeatureTableFullScreenActive(false);
    });
  }, []);

  // useEffect(() => {
  //   setFilterByExtent(!featureTableFullScreenActive);
  // }, [featureTableFullScreenActive]);

  const stopDrag = () => {
    if (mapDiv) mapDiv.style.cursor = "default";
    if (featureTableRef.current) {
      featureTableRef.current.style.cursor = "default";
      featureTableRef.current.style.removeProperty("transition");
    }
    document.documentElement.removeEventListener("mousemove", doDrag, false);
    document.documentElement.removeEventListener("mouseup", stopDrag, false);
  };

  const initDrag = (e) => {
    e.preventDefault();
    if (e.target.id !== "resizer") return;
    document.documentElement.addEventListener("mousemove", doDrag, false);
    document.documentElement.addEventListener("mouseup", stopDrag, false);
  };

  // const getTabTitle = (layer) => {
  //   return (
  //     <LayerIcon
  //       config={config}
  //       layer={layer}
  //       t={t}
  //       opsColor={opsColor}
  //       width={22}
  //       height={22}
  //       selected={false}
  //       textColor="#000"
  //     />
  //   );
  // };

  const closeTooltip = useCallback(() => {
    dispatch(setAnimateFeatureTable(false));
  }, []);

  const showTooltip = useMemo(() => {
    return dashboard.animateFeatureTable && !featureTableExpanded;
  }, [featureTableExpanded, dashboard.animateFeatureTable]);

  // const onTabSelectionChange = useCallback(
  //   (index) => {
  //     setActiveIndex(index);
  //     const layer = visibleLayers[index];
  //     setRow(null);
  //     setColumns(null);
  //     setFeatures([]);
  //     setCurrLayer(layer);
  //   },
  //   [visibleLayers]
  // );

  const opsColor = useMemo(() => getOpsColor(config), [config]);

  return (
    <FeatureTableContainer
      isExpanded={featureTableExpanded}
      isDisclaimerShown={true}
      ref={featureTableRef}
      id="ftc"
      opsColor={opsColor}
      animate={showTooltip}
    >
      <div
        style={{
          position: "absolute",
          top: featureTableExpanded ? "-12px" : "0px",
          left: 0,
          width: "100%",
          height: featureTableExpanded ? "calc(100% + 12px)" : "100%",
        }}
      >
        {showTooltip && (
          <FeatureTableTooltip>
            <span>You can also use data table to edit multiple features</span>
            <button onClick={closeTooltip}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="10"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
              </svg>
            </button>
          </FeatureTableTooltip>
        )}
        <StyledResizerButton onClick={onArrowClick}>
          <SectionItem
            className="gtag"
            data-tag-name="Data-Table"
            style={{
              width: 56,
              height: 30,
              padding: "4px",
              borderRadius: "0px 0px 0px 0px",
              boxSizing: "border-box",
            }}
          >
            {featureTableExpanded ? (
              <FaChevronRight
                style={{
                  transform: `rotate(${"90deg"})`,
                  transition: "all 0.3s",
                }}
              />
            ) : (
              <TableIcon />
            )}
          </SectionItem>
        </StyledResizerButton>
        <StyledTableContentWrapper
          opsColor="var(--opsColor)"
          isExpanded={featureTableExpanded}
        >
          {featureTableExpanded && (
            <StyledTableTopBar
              isExpanded={featureTableExpanded}
              key="resizer"
              id="resizer"
              onMouseDown={(e) => {
                initDrag(e);
              }}
              style={{
                minHeight: 4,
                background: "#fff",
              }}
            />
          )}

          {featureTableExpanded && (
            <TableGrid
              visibleLayers={visibleLayers}
              setEditableLayer={setCurrLayer}
              editableLayer={currLayer}
              featureTableFullScreenActive={featureTableFullScreenActive}
              showCloseMessage={showCloseMessage}
              setShowCloseMessage={setShowCloseMessage}
              editedFeatures={editedFeatures}
              setEditedFeatures={setEditedFeatures}
              openFullScreen={openFullScreen}
              exitFullScreen={exitFullScreen}
            />
          )}
        </StyledTableContentWrapper>
      </div>
    </FeatureTableContainer>
  );
};

export default FeatureTable;
