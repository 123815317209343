import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCycleManagerOpen } from "../../../../redux/action/CycleManager-action";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { timeAgo } from "../../../../utils/helper";
import { Tooltip } from "../../../UI";
import {
  StyledButton,
  StyledContainer,
  StyledInformationContainer,
  StyledTextContainer,
} from "./CycleInfromation-styled";

const CycleInformation = () => {
  const { lastCycleUpdate } = useSelector((state) => state.cycleManagerReducer);
  const { t, i18n } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const dispatch = useDispatch();

  const formatTime = (time) => {
    if (!time) return "";

    return timeAgo(time, i18n.language, false, { month: "short" });
  };

  const cycleUpdateFrequency = useMemo(
    () => config.cycleUpdateFrequency,
    [config]
  );

  const openCycleManager = useCallback(() => {
    dispatch(setCycleManagerOpen(true));
  }, []);

  return (
    <StyledInformationContainer>
      <Tooltip showOnHover content={t("cycleManager.open.information")}>
        <StyledButton onClick={openCycleManager}>
          <span>{t("cycleManager.open.title")}</span>
        </StyledButton>
      </Tooltip>
      <StyledContainer>
        <StyledTextContainer>
          <span>{t("cycleManager.frequency.title")}:</span>{" "}
          <span>
            {t(`cycleManager.frequency.options.${cycleUpdateFrequency}`)}
          </span>
        </StyledTextContainer>
      </StyledContainer>
      <StyledContainer>
        <StyledTextContainer>
          <span>{t("cycleManager.last")}: </span>
          <span>{formatTime(lastCycleUpdate)}</span>
        </StyledTextContainer>
      </StyledContainer>
    </StyledInformationContainer>
  );
};

export default CycleInformation;
