import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Message, { SaveMessage } from "../../Dashboard/Editor/Message";
import { ConfigContext } from "../../../utils/ConfigContext";
import { UpdateType } from "../../Dashboard/Editor/EditorContextProvider";

const MessagesOverlay = ({
  showBatchUpdate,
  requiredFieldsMessage,
  saveMessage,
  showCloseMessage,
  updatedFeatures,
  showUpdateMessage,
  onCloseMessageSubmit,
  onCloseMessageCancel,
  onSaveMessageSubmit,
  onSaveMessageCancel,
  setSaveMessage,
  batchUpdateFeatures,
  highlightFeature,
  activeColor,
  editableLayer,
}) => {
  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);

  return (
    <>
      {showBatchUpdate && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "#eeeeeed4",
            zIndex: 2,
          }}
        ></div>
      )}
      {requiredFieldsMessage.show && (
        <Message
          position="absolute"
          title={t("cycleManager.saveMessages.missingFields", { 1: "" })}
          subtitle={
            <span
              style={{
                fontWeight: 400,
                fontSize: "inherit",
                color: "red",
              }}
            >
              {requiredFieldsMessage.fields.join(", ")}
            </span>
          }
        />
      )}
      {saveMessage.show && (
        <Message
          position="absolute"
          title={t("cycleManager.areYouSure.defaultTitle")}
          subtitle={t("cycleManager.areYouSure.unsavedChanges")}
          onSubmit={onSaveMessageSubmit}
          onCancel={onSaveMessageCancel}
          confirmText="Save"
          color={config.opsColor}
        />
      )}
      {showCloseMessage && (
        <Message
          position="absolute"
          title={t("cycleManager.areYouSure.closeTitle")}
          subtitle={t("cycleManager.areYouSure.unsavedChanges")}
          onSubmit={onCloseMessageSubmit}
          onCancel={onCloseMessageCancel}
          color={config.opsColor}
        />
      )}
      {showUpdateMessage && (
        <SaveMessage
          updateType={UpdateType.situational}
          actionType="updated"
          position="absolute"
          updatedFeatures={updatedFeatures}
          batchUpdateFeatures={batchUpdateFeatures}
          highlightFeature={highlightFeature}
          activeColor={activeColor}
          editableLayer={editableLayer}
        />
      )}
    </>
  );
};

export default MessagesOverlay;
