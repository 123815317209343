import { loadModules } from "esri-loader";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphicsLayer, view } from "../../../../../../utils/API";
import { StyledButton } from "../../../Editor-styled";

const SegmentButton = ({
  point,
  nextPoint,
  onClick,
  isRoutable,
  onAutoSegmentDelete,
  editedItems,
  index,
}) => {
  const [hasSegment, setHasSegment] = useState(false);
  const [hasManualSegment, setHasManualSegment] = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    let handler;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      view.whenLayerView(graphicsLayer).then((lv) => {
        handler = reactiveUtils.watch(
          () => lv.updating,
          (updating) => {
            if (!updating) {
              if (!nextPoint) return;

              const searchableSegmentCoordinates =
                point.coordinate.join("|") +
                "-" +
                nextPoint.coordinate.join("|");

              const hasSearchableSegment =
                nextPoint.searchable &&
                point.searchable &&
                graphicsLayer.graphics.some(
                  (g) => g.id && g.id.includes(searchableSegmentCoordinates)
                );

              const nextPointId = nextPoint.graphic.id;
              const currPointId = point.graphic.id;

              const hasManualSegment = graphicsLayer.graphics.some((g) => {
                let hasConnectedRoute = false;
                if (
                  g.id &&
                  g.id.includes("route-manual") &&
                  !hasSearchableSegment
                ) {
                  hasConnectedRoute =
                    g.id.includes(nextPointId) && g.id.includes(currPointId);
                }

                // const isStartingWithPoint =
                //   g.id && g.id === `line-${point.coordinate.join("|")}`;

                // if (isStartingWithPoint) {
                //   return true;
                // }
                // const isStartingWithNextPoint =
                //   g.id && g.id === `line-${nextPoint.coordinate.join("|")}`;
                // if (
                //   Array.isArray(g.intersectedPoints) &&
                //   (g.intersectedPoints.includes(
                //     nextPoint.coordinate.join("|")
                //   ) ||
                //     isStartingWithNextPoint) &&
                //   g.intersectedPoints.includes(point.coordinate.join("|"))
                // ) {
                //   return true;
                // }

                return hasConnectedRoute; //false
              });
              setHasManualSegment(hasManualSegment);

              if (nextPoint.searchable && point.searchable) {
                const segmentId =
                  point.coordinate.join("|") +
                  "-" +
                  nextPoint.coordinate.join("|");

                const hasSegment = graphicsLayer.graphics.some(
                  (g) => g.id && g.id === `route-${segmentId}`
                );
                setHasSegment(hasSegment);
              }
            }
          }
        );
      });
    });

    return () => {
      handler?.remove();
    };
  }, [point, nextPoint]);

  const handleDeleteAutoSegment = useCallback(() => {
    if (hasSegment) {
      const pointId = point.coordinate.join("|");
      const nextPointId = nextPoint.coordinate.join("|");
      const routeId = `route-${pointId}-${nextPointId}`;
      const routeGraphics = graphicsLayer.graphics.filter(
        (g) => g.id && g.id === routeId
      );

      if (routeGraphics.length > 0) {
        graphicsLayer.removeMany(routeGraphics);
      }
      if (onAutoSegmentDelete) {
        onAutoSegmentDelete(point);
      }
    } else if (hasManualSegment) {
      const manualGraphics = graphicsLayer.graphics.filter(
        (g) => g.id && g.id.includes("route-manual")
      );
      graphicsLayer.removeMany(
        manualGraphics.filter(
          (g) =>
            g.id.includes(point.graphic.id) &&
            g.id.includes(nextPoint.graphic.id)
        )
      );
    }
  }, [point, nextPoint, onAutoSegmentDelete, hasSegment, hasManualSegment]);

  if (isRoutable && !hasSegment) return;

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        bottom: "-26px",
        left: "50%",
        transform: "translateX(-50%)",
        alignItems: "center",
      }}
    >
      <StyledButton
        type="ghost"
        hover={!(isRoutable && hasSegment && !hasManualSegment)}
        disabled={isRoutable && hasSegment && !hasManualSegment}
        color={
          hasManualSegment ? "#fbbf24" : !isRoutable ? "#ef4444" : "#16a34a"
        }
        style={{
          fontSize: 10,
        }}
        onClick={onClick}
      >
        {!isRoutable
          ? hasManualSegment
            ? t(
                "screen.widget.Editor.manager.routeSelection.routeApi.segments.manuallyEdited"
              )
            : t(
                "screen.widget.Editor.manager.routeSelection.routeApi.segments.needsManualEdit"
              )
          : hasManualSegment
          ? t(
              "screen.widget.Editor.manager.routeSelection.routeApi.segments.manuallyEdited"
            )
          : hasSegment
          ? t(
              "screen.widget.Editor.manager.routeSelection.routeApi.segments.calculated"
            )
          : ""}
      </StyledButton>
      {(hasSegment || hasManualSegment) && (
        <StyledButton
          type="ghost"
          color={
            hasManualSegment ? "#fbbf24" : !isRoutable ? "#ef4444" : "#16a34a"
          }
          style={{
            fontSize: 10,
            height: "max-content",
            padding: 0,
            cursor: "pointer",
          }}
          onClick={handleDeleteAutoSegment}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="12px"
            width="12px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              d="M296 64h-80a7.91 7.91 0 0 0-8 8v24h96V72a7.91 7.91 0 0 0-8-8z"
            ></path>
            <path d="M432 96h-96V72a40 40 0 0 0-40-40h-80a40 40 0 0 0-40 40v24H80a16 16 0 0 0 0 32h17l19 304.92c1.42 26.85 22 47.08 48 47.08h184c26.13 0 46.3-19.78 48-47l19-305h17a16 16 0 0 0 0-32zM192.57 416H192a16 16 0 0 1-16-15.43l-8-224a16 16 0 1 1 32-1.14l8 224A16 16 0 0 1 192.57 416zM272 400a16 16 0 0 1-32 0V176a16 16 0 0 1 32 0zm32-304h-96V72a7.91 7.91 0 0 1 8-8h80a7.91 7.91 0 0 1 8 8zm32 304.57A16 16 0 0 1 320 416h-.58A16 16 0 0 1 304 399.43l8-224a16 16 0 1 1 32 1.14z"></path>
          </svg>
        </StyledButton>
      )}
    </div>
  );
};

export default SegmentButton;
