import { loadModules } from "esri-loader";
import * as globalVars from "../data/globalVariables";
import { createMap, createView } from "../esri/map-view";
import { createOAuth } from "../esri/oauth";
import { getSurvey123PointData } from "../esri/survey123";
import { createHeader, createWidgets } from "../esri/widgets";
import { applyZoomLevelFilter, createSharableURL, getConfig } from "./helper";

import validator from "validator";
import { replaceURLParams } from "../components/Report/new/helpers";
import { getTooltipTitle } from "../esri/custom-popup-content";
import { createLayer } from "../esri/layers";
import { setLayersLoading } from "../redux/action/LayersLoading-action";
import { setErrorLayers, setMapView } from "../redux/action/MapView-action";
import store from "../redux/store";

let navigatedFromMainPage = false;
// we change params in url using history.pushState method, when moving from main page to operation and when navigating via report
// it doesn't reload page, but when user goes back clicking on browser back button we need to refresh the page to avoid errors
window.addEventListener(
  "popstate",
  function (event) {
    const locationHref = event.target.location.href;
    if (locationHref) {
      window.location = locationHref;
    }
  },
  false
);

// Global variables
export let view;

export const isdeletedFieldName = "isdeleted";
export const showpublicFieldName = "showpublic";

export const currAsOfDateFieldName = "currasofdate";
export const asOfDateFieldName = "asofdate";

export const bannerTextFieldName = "textbanneren";
export const isShowInBannerFieldName = "showninbanner";

export const opidFieldName = "opid";

export const clusterRed = "#c03a2b";
export let initialExtent;
export let surveyPoint = globalVars.surveyPoint;
export let graphicsLayer = globalVars.graphicsLayer;

export const LOGIE_API_ENDPOINT =
  "https://ubyneuzlhl.execute-api.eu-west-1.amazonaws.com/100";

let zoomLevelFilterTimer;

export const initializeMap = async ({
  container,
  config,
  setLoading,
  setError,
  setSideBarIFrame,
  activeModule,
  setActiveLanguage,
  setFeatureTableActive,
  t,
  i18n,
  openSnackbar,
  setSideBarMobile,
  setConfig,
  openTopSnackbar,
}) => {
  // Create OAuth
  await createOAuth(setError);

  // Create Map
  let map;
  try {
    map = await createMap(config, setError);
    if (config.webmap) {
      await map.load();
    }

    let countryGeometry = null;
    // Create country Layer
    if (!config.webmap || config.iso3) {
      /*
			createCountryLayer(setError).then(countryLayer => {
				countryLayer.layerIndex = 0
				addLayerToMap(countryLayer, config, map, t, activeModule);
				// Get country center
				//if (config.iso3 && !Array.isArray(config.iso3)) {
				//	countryGeometry = getCountryGeometry(countryLayer, config, setError)
				//}
			})

			 */
    }

    if (
      !navigatedFromMainPage ||
      map.basemap.portalItem.id !== view.map.basemap.portalItem.id
    ) {
      // Create view
      view = await createView({
        container,
        map,
        config,
        countryGeometry,
        setError,
      });
    } else {
      navigatedFromMainPage = false;
      //if view.map has different basemaps
      if (map.basemap.portalItem.id !== view.map.basemap.portalItem.id) {
        view.map.basemap = map.basemap;
      }
    }

    view.when(() => {
      initialExtent = view.extent;
      view.popup.collapseButton = true;
      if (!(window.isSmall || config.embed)) {
        createHeader(view, t, config, setActiveLanguage, openTopSnackbar);
      }

      // Create and add GraphicsLayer
      loadModules(["esri/layers/GraphicsLayer"]).then(([GraphicsLayer]) => {
        graphicsLayer = new GraphicsLayer({ title: "Sketch" });
        store.dispatch(setMapView(view));
        map.layers.add(graphicsLayer);

        addWidgets(
          config,
          activeModule,
          setActiveLanguage,
          setSideBarIFrame,
          setFeatureTableActive,
          i18n,
          t,
          openSnackbar
        );

        // Create layers
        createLayers(
          config,
          setSideBarIFrame,
          t,
          i18n,
          activeModule,
          openSnackbar
        ).then(({ promises: layerPromises, layerTitles, layerAliases }) => {
          fetchBasemapZoomlevels(config);

          store.dispatch(setLayersLoading(true));
          Promise.allSettled(layerPromises).then((resolvedPromises) => {
            // const newOperationLayers =
            //   store.getState().mapViewReducer.operationLayers;
            const rejectedTitles = new Set();
            const rejectedAliases = [];

            const rejectedLayers = [];
            const rejectedPromises = resolvedPromises.filter(
              (promise, index) => {
                let isRejected = false;
                if (Array.isArray(promise.value)) {
                  const rejected = promise.value.filter((value) => !value);
                  isRejected = rejected.length > 0;
                } else {
                  isRejected = !promise.value;
                }

                if (isRejected) {
                  rejectedTitles.add(layerTitles[index]);
                  rejectedAliases.push(layerAliases[index]);
                  // newOperationLayers[index].state = LAYER_STATES.ERROR;
                } else {
                  // newOperationLayers[index].state = LAYER_STATES.LOADED;
                }

                return isRejected;
              }
            );

            // const rejectedTitles = newOperationLayers
            //   .filter((layer) => layer.state === LAYER_STATES.ERROR)
            //   .map((layer) => layer.title);

            // const rejectedAliases = newOperationLayers
            //   .filter((layer) => layer.state === LAYER_STATES.ERROR)
            //   .map((layer) => layer.alias);

            let errorMessage = t("screen.message.layerLoadError");
            const messageArray = errorMessage.split("{1}");
            const splitMail =
              messageArray.length > 1 ? messageArray[1].split("{2}") : ["", ""];

            if (rejectedPromises.length > 0) {
              store.dispatch(setErrorLayers(rejectedAliases));
              openSnackbar(
                <div className="layer-error">
                  <span>
                    {messageArray.length > 0 ? messageArray[0] : ""}
                    <span className="layer-error__names">
                      {Array.from(rejectedTitles).join(", ")}
                    </span>{" "}
                    {splitMail[0]}{" "}
                    <a href="mailto: global.logie@wpf.org">
                      global.logie@wpf.org
                    </a>{" "}
                    {splitMail[1]}
                  </span>
                </div>,
                15000
              );
            }

            // openDefaultPopup(config);
            view.map.layers.forEach((layer) => {
              applyZoomLevelFilter(layer, config);
              // if (layer.layerConfig?.isConops)
              //   new Conops(view.map, layer, config, t);

              // if (layer.layerConfig?.commentBoxOps)
              //   new CommentBoxes(view.map, layer, config, t);

              // if (layer.layerConfig?.isEpam)
              //   new Epam(view.map, layer, config, t);
            });

            //When all layers finished loading to avoid issues on module or operation change
            store.dispatch(setLayersLoading(false));
          });
        });
      });
    });

    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      reactiveUtils.watch(
        () => view.zoom,
        () => {
          clearTimeout(zoomLevelFilterTimer);
          zoomLevelFilterTimer = setTimeout(() => {
            view.map.layers.forEach((layer) => {
              applyZoomLevelFilter(layer, config);
            });
          }, 250);
        }
      );
    });

    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      reactiveUtils.watch(
        () => view.popup.selectedFeature,
        (graphic) => {
          if (!graphic) return;

          //collapse the report widget when a popup opens
          // collapseWidget("Report")

          // Set the action's visible property to true if the 'website' field value is not null, otherwise set it to false
          const graphicTemplate = graphic.getEffectivePopupTemplate();
          if (
            !graphicTemplate ||
            !graphicTemplate.actions ||
            !graphicTemplate.actions.items
          )
            return;

          graphicTemplate.actions.items.forEach((item) => {
            if (item.id === "show-lca" || item.id === "show-lca-mobile") {
              item.visible = graphic.attributes.lcapageid;
            } else if (item.id === "report") {
              item.visible = graphic.geometry;
            }
          });
        }
      );

      reactiveUtils.watch(
        () => view.popup?.id,
        function () {
          let alignment = "auto";

          view.popup.alignment = function () {
            const location = this.location;
            const view = this.view;

            if (location && view) {
              const viewPoint = view.toScreen(location);

              if (viewPoint.y < 567) {
                //offset from top to popup arrow
                alignment = "bottom";
                if (viewPoint.x + 350 / 2 > view.width) {
                  alignment += "-left";
                } else if (viewPoint.x - 350 / 2 < 0) {
                  alignment += "-right";
                } else {
                  alignment += "-center";
                }
              } else {
                alignment = "auto";
              }
            }
            return alignment;
          };
        }
      );

      reactiveUtils.on(
        () => view.popup,
        "trigger-action",
        (event) => {
          if (!view.popup.viewModel || !view.popup.viewModel.selectedFeature)
            return;

          const feature = view.popup.viewModel.selectedFeature;

          if (event.action.id === "show-lca-mobile") {
            setSideBarIFrame(feature.attributes.lcapageid);
            setSideBarMobile(true);
          } else if (event.action.id === "show-lca") {
            setSideBarIFrame(feature.attributes.lcapageid);
            setSideBarMobile(false);
            view.ui._components.forEach((comp) => {
              if (comp.widget?.expanded) comp.widget.expanded = false;
            });
          } else if (event.action.id === "share") {
            copySharableURL({
              feature,
              config,
              activeModule,
              openSnackbar,
              t,
            });
          } else if (event.action.id === "open-feature-menu") {
            view.popup.featureMenuOpen = !view.popup.featureMenuOpen;
          }
        }
      );
    });

    // addHoverPopups(config, t)
    addClickEventListener(config, t, setSideBarIFrame, setConfig, openSnackbar);
  } catch (err) {
    console.error("Map initialization error occurred: " + err);
    return;
  }

  setLoading(false);
  return map;
};

export const copySharableURL = ({
  feature,
  config,
  activeModule,
  openSnackbar,
  t,
}) => {
  let url = createSharableURL(feature, config, activeModule);
  const shareData = { url: url };
  if (!navigator) return;

  if (navigator.canShare && navigator.canShare(shareData)) {
    console.log("Sharing link via navigator share", shareData);
    navigator
      .share(shareData)
      .then((res) => {
        console.log("Shared successfully", shareData);
      })
      .catch((err) => {
        copyUrlToClipboard(url, openSnackbar, t);
      });
  } else {
    copyUrlToClipboard(url, openSnackbar, t);
  }
};

export const copyUrlToClipboard = (url, openSnackbar, t) => {
  console.log("Sharing link via clipboard: ", url);

  if (navigator && navigator.clipboard) {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        openSnackbar(t("screen.message.copied"), 15000);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

/**
 * Offline fetch basemap tiles for different zoom levels
 * @param config
 */
const fetchBasemapZoomlevels = (config) => {
  const offline = config.offline;
  if (
    !offline ||
    !offline.enabled ||
    !offline.cacheZoomLevelsBeyondActual ||
    offline.cacheZoomLevelsBeyondActual <= 0
  )
    return;

  const zoomLevels = offline.cacheZoomLevelsBeyondActual;
  const lon2tile = (lon, zoom) => {
    return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
  };
  const lat2tile = (lat, zoom) => {
    return Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
        ) /
          Math.PI) /
        2) *
        Math.pow(2, zoom)
    );
  };

  loadModules(["esri/geometry/support/webMercatorUtils"]).then(
    ([webMercatorUtils]) => {
      const topLeft = webMercatorUtils.xyToLngLat(
        initialExtent.xmin,
        initialExtent.ymax
      );
      const bottomRight = webMercatorUtils.xyToLngLat(
        initialExtent.xmax,
        initialExtent.ymin
      );

      for (let zl = 1; zl <= zoomLevels; zl++) {
        const cachedZoomLevel = view.zoom + zl;

        view.map.basemap?.baseLayers?.forEach((bl) => {
          if (!bl.fetchTile) return;

          for (
            let col = lon2tile(topLeft[0], cachedZoomLevel);
            col <= lon2tile(bottomRight[0], cachedZoomLevel);
            col++
          ) {
            for (
              let row = lat2tile(topLeft[1], cachedZoomLevel);
              row <= lat2tile(bottomRight[1], cachedZoomLevel);
              row++
            ) {
              bl.fetchTile(cachedZoomLevel, row, col);
            }
          }
        });
      }
    }
  );
};

/**
 * Open popup when 'oid' and 'lid' URL parameters are specified
 * @param config
 * @param layer
 */
export const openDefaultPopup = async (config, layer) => {
  try {
    if (!config.oid || !config.lid || !layer) return;
    if (!layer.originalId || layer.originalId !== config.lid) return;

    let query = layer.createQuery();
    query.objectIds = [config.oid];
    query.returnGeometry = true;
    const results = await layer.queryFeatures(query);

    if (results.features.length > 0) {
      if (!layer.visible) {
        layer.visible = true;
      }

      const feature = results.features[0];

      let location = feature.geometry;

      if (location.centroid) location = location.centroid;

      if (location.x) {
        view.openPopup({
          location: location,
          features: results.features,
        });
      } else if (feature.geometry?.extent?.center) {
        //for roads
        view.openPopup({
          location: feature.geometry.extent.center,
          features: results.features,
        });
      }
    }
  } catch (error) {
    console.warn("Error occurred while opening default popup: ", error);
  }
};

export const addWidgets = (
  config,
  activeModule,
  setActiveLanguage,
  setSideBarIFrame,
  setFeatureTableActive,
  i18n,
  t,
  openSnackbar
) => {
  // Create and add widgets
  createWidgets(
    view,
    config,
    graphicsLayer,
    activeModule,
    setActiveLanguage,
    setSideBarIFrame,
    setFeatureTableActive,
    i18n,
    t,
    openSnackbar
  ).then(() => {});
};

/**
 * Retrieves the layer title from the alias using the provided configuration.
 *
 * @param {string} alias - The alias of the layer.
 * @param {Object} config - The configuration object containing layer configurations.
 * @param {Object} config.layerConfig - The layers configuration object.
 * @returns {string} The transaction label for the layer title.
 */
export const getLayerTitleFromAlias = (alias, config) => {
  let transactionLabel = "";
  try {
    let layerConfig = config.layerConfig[alias];

    transactionLabel =
      "layer.title." +
      (layerConfig?.titleLabel ? layerConfig.titleLabel : layerConfig?.id) +
      ".title";
    return transactionLabel;
  } catch (err) {
    return transactionLabel;
  }
};

/**
 * Retrieves the layer title from the alias using the provided configuration.
 *
 * @param {string} alias - The alias of the layer.
 * @param {Object} config - The configuration object containing layer configurations.
 * @param {Object} config.layerConfig - The layers configuration object.
 * @returns {string} The transaction label for the layer information.
 */
export const getLayerInformationFromAlias = (alias, config) => {
  let transactionLabel = "";
  try {
    let layerConfig = config.layerConfig[alias];

    transactionLabel =
      "layer.title." +
      (layerConfig?.titleLabel ? layerConfig.titleLabel : layerConfig?.id) +
      ".information";

    return transactionLabel;
  } catch (err) {
    return transactionLabel;
  }
};

/**
 * Retrieves the layer ID from the given alias using the provided configuration.
 *
 * @param {string} alias - The alias of the layer.
 * @param {Object} config - The configuration object containing layer configurations.
 * @param {Object} config.layerConfig - An object mapping aliases to layer configurations.
 * @returns {string} The ID of the layer if found, otherwise an empty string.
 */
export const getLayerIdFromAlias = (alias, config) => {
  try {
    let layerConfig = config.layerConfig[alias];
    return layerConfig.id;
  } catch (err) {
    return "";
  }
};

export const generateLayers = (config) => {
  const moduleMap = {};
  let layerAliasesSet = new Set();
  if (Array.isArray(config.layers)) {
    config.layers.forEach((layer) => {
      layerAliasesSet.add(layer);
    });
  }

  config.activeModules?.forEach((module) => {
    moduleMap[module] = {
      layers: [],
      optionalLayers: [],
    };
  });

  config.activeModules?.forEach((module) => {
    config.modules[module].layers.forEach((modLayer) => {
      layerAliasesSet.add(modLayer);
      moduleMap[module].layers.unshift(modLayer);
    });

    if (Array.isArray(config.modules[module]?.optionalLayers)) {
      config.modules[module].optionalLayers.forEach((modLayer) => {
        layerAliasesSet.add(modLayer);
        moduleMap[module].optionalLayers.push(modLayer);
      });
    }
  });

  return {
    moduleMap: moduleMap,
    restLayers: config.layers || [],
  };
};

const createLayers = async (
  config,
  setSideBarIFrame,
  t,
  i18n,
  activeModule,
  openSnackbar
) => {
  /**
   * A set to store layer promise aliases.
   * @type {Set<string>}
   */
  let layerAliasesSet = new Set();
  let layerPromiseAliasSet = new Set();

  if (Array.isArray(config.layers)) {
    config.layers.forEach((layer) => {
      layerAliasesSet.add(layer);
    });
  }

  config.activeModules?.forEach((module) => {
    config.modules[module].layers.forEach((modLayer) => {
      layerAliasesSet.add(modLayer);
    });

    if (Array.isArray(config.modules[module]?.optionalLayers)) {
      config.modules[module].optionalLayers.forEach((modLayer) => {
        layerAliasesSet.add(modLayer);
      });
    }
  });

  let cnt = 1;
  let promises = [];
  const layers = [];
  const layerTitles = [];

  for (const layerAlias of layerAliasesSet) {
    await new Promise((resolve) => {
      setTimeout(() => {
        const layerPromise = createLayer(
          layerAlias,
          cnt,
          config,
          setSideBarIFrame,
          t,
          i18n,
          activeModule,
          openSnackbar
        );

        if (layerPromise) {
          promises.push(layerPromise);
          const title = getLayerTitleFromAlias(layerAlias, config);
          layerPromiseAliasSet.add(layerAlias);
          layers.push({
            title: t(title, layerAlias),
            alias: layerAlias,
          });
          layerTitles.push(t(title, layerAlias));
          cnt++;
        }
        resolve();
      }, 250);
    });
  }
  // store.dispatch(setOperationLayers(operationLayers));

  return {
    promises,
    layerAliases: Array.from(layerPromiseAliasSet),
    layerTitles,
  };
};

export const getLayerFromId = (Layer, layerId, portalUrl) => {
  try {
    switch (getFieldType(layerId)) {
      case "url":
        return Layer.fromArcGISServerUrl({ url: layerId });
      case "portalItem":
        const portalItem = {
          id: layerId,
        };

        if (portalUrl) {
          portalItem.portal = {
            url: portalUrl,
          };
        }

        return Layer.fromPortalItem({ portalItem: portalItem });
      default:
        return null;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Show popups when hovering over a feature. These popups only show the normal popup header
 */
const addHoverPopups = (config, t) => {
  let timer;
  let hoverPopupOpen = false;
  let lastHoverFeature;

  view.on("click", (event) => {
    hoverPopupOpen = false;
    setPopupOptions(event.button !== 2);
  });

  view.on("pointer-move", (event) => {
    if (!view.popupEnabled) return;

    view.container.style.cursor = "auto";
    clearTimeout(timer);
    timer = setTimeout(function () {
      view.hitTest(event, { include: view.map.layers }).then((response) => {
        //Do nothing when a popup is already opened, and it was not us to open it
        if (
          !hoverPopupOpen &&
          document.getElementsByClassName("esri-popup").length > 0
        )
          return;

        //No feature to show, close the popup if any open
        if (!response.results.length) {
          hoverPopupOpen = false;
          view.closePopup();
          return;
        }

        const features = response.results.filter(
          (result) => result?.graphic?.layer?.type === "feature"
        );
        const clickableFeatures = features.filter(
          (fea) =>
            fea.layer.layerConfig?.clickable || fea.layer.layerConfig?.onclick
        );
        if (clickableFeatures.length) view.container.style.cursor = "pointer";

        //No feature layer to show, close popup
        if (!features.length) {
          hoverPopupOpen = false;
          view.closePopup();
          return;
        }

        const feature = features[0].graphic;
        const point = features[0].mapPoint;
        if (!feature.layer.layerConfig.showTooltip) return;

        //Prevent flashing: don't open the popup if last feature id was the same as the actual one
        if (
          lastHoverFeature &&
          feature.attributes[feature.layer.objectIdField] ===
            lastHoverFeature.attributes[lastHoverFeature.layer.objectIdField]
        )
          return;

        const layer = feature.layer;
        lastHoverFeature = feature;
        hoverPopupOpen = true;

        loadModules(["esri/rest/support/Query"]).then(([Query]) => {
          layer.load().then((layer) => {
            setPopupOptions(false);
            const geometry = feature.geometry;
            view.popup.open({
              location: geometry.type === "point" ? feature.geometry : point,
              collapsed: true,
              title: "Loading..",
            });

            let query = new Query();
            query.outFields = ["*"];
            query.objectIds = [feature.attributes[layer.objectIdField]];

            layer.queryFeatures(query).then((ft) => {
              if (!ft || !ft.features) view.closePopup();

              view.popup.title = getTooltipTitle(ft.features[0], t, config);
            });
          });
        });
      });
    }, 100);
  });
};

const OnClickActionsTarget = {
  openLinkOnNewPage: "_blank",
  openLinkOnSamePage: "_self",
  openLinkInSidebar: "sidebar",
};
/**
 * Add event listener for the following configuration
 * "onclick": {
 *                 "target": "openLinkOnNewPage",
 *                 "linkAttribute": "urlpublic"
 *             },
 */
const addClickEventListener = (
  config,
  t,
  setSideBarIFrame,
  setConfig,
  openSnackbar
) => {
  view.on("click", (event) => {
    if (
      view.map.layers.filter(
        (layer) => layer.visible && layer.layerConfig?.onclick
      ).length === 0 ||
      store.getState().printWidget.open
    )
      return;

    view
      .hitTest(event, {
        include: view.map.layers.filter((l) => !!l.layerConfig),
      })
      .then((response) => {
        const { results = [] } = response;
        if (results.length > 0) {
          const result = results[0];

          const onclick = result.layer?.layerConfig?.onclick;
          if (!result.graphic || !onclick) return;

          if (!onclick.target && !OnClickActionsTarget[onclick.target]) {
            console.warn(
              "Unrecognized or unspecified onClick target: " +
                onclick.target +
                ". Possible values are: " +
                OnClickActionsTarget.openLinkOnNewPage +
                " , " +
                OnClickActionsTarget.openLinkOnSamePage
            );
            return;
          }

          const target = OnClickActionsTarget[onclick.target];
          const link = onclick.linkAttribute;
          if (!link) {
            console.warn("Unspecified onClick linkAttribute ");
            return;
          } else if (
            result.layer.fields.filter((field) => field.name === link)
              .length === 0
          ) {
            console.warn("Invalid onClick linkAttribute ");
            return;
          }

          loadModules(["esri/rest/support/Query"]).then(([Query]) => {
            let query = new Query();
            query.outFields = [onclick.linkAttribute];
            query.objectIds = [
              result.graphic.attributes[result.layer.objectIdField],
            ];

            result.layer.queryFeatures(query).then((ft) => {
              if (ft && ft.features) {
                const link = ft.features[0].attributes[onclick.linkAttribute];
                if (target === OnClickActionsTarget.openLinkInSidebar)
                  setSideBarIFrame(link);
                else {
                  if (link) {
                    const url = new URL(link);
                    const urlParams = new URLSearchParams(url.search);

                    const opId = urlParams.get("op");
                    if (opId) {
                      const embedParam = new URLSearchParams(
                        window.location.search
                      ).get("embed");

                      const editorParam = new URLSearchParams(
                        window.location.search
                      ).get("role");

                      const newParams = [
                        {
                          key: "op",
                          value: opId,
                        },
                      ];

                      if (editorParam) {
                        newParams.push({
                          key: "role",
                          value: editorParam,
                        });
                      }

                      if (embedParam) {
                        newParams.push({
                          key: "embed",
                          value: embedParam,
                        });
                      }
                      if (view) {
                        view.map.removeAll();
                      }

                      replaceURLParams(newParams, true);
                      const config = getConfig(t, openSnackbar);
                      navigatedFromMainPage = true;
                      view.container.style.cursor = "auto";
                      setConfig(config);
                    }
                    //original
                    // window.open(link, target)
                  } else {
                    openSnackbar(
                      t("screen.message.urlError").replace(
                        "{{1}}",
                        onclick.linkAttribute
                      )
                    );
                  }
                }
              }
            });
          });
        }
      });
  });
};

/**
 * @param isClick Was there a click event before the popup is opened?
 * When click happens we open a normal feature popup,
 * otherwise it is a tooltip like popup
 */
const setPopupOptions = (isClick) => {
  view.popup.visibleElements = { closeButton: isClick };
  view.popup.dockOptions = { buttonEnabled: isClick };

  const classList = view.popup.container?.classList;
  if (classList) {
    if (isClick) classList.remove("tooltip");
    else classList.add("tooltip");
  }
  view.popup.collapsed = !isClick;
};

export const handleMapViewClick = async ({
  event,
  config,
  setSideBarIFrame,
  activeLanguage,
  icon,
}) => {
  if (surveyPoint && surveyPoint.marker)
    graphicsLayer.remove(surveyPoint.marker);

  surveyPoint = await getSurvey123PointData({
    event,
    config,
    activeLanguage,
    icon,
  });
  if (surveyPoint) {
    graphicsLayer.add(surveyPoint.marker);
    if (setSideBarIFrame) setSideBarIFrame(surveyPoint.iframe);
    return surveyPoint.marker.geometry;
  }

  return false;
};

export const removePoint = () => {
  if (surveyPoint) graphicsLayer.remove(surveyPoint.marker);
};

const getFieldType = (str) => {
  if (typeof str !== "string") return;
  if (validator.isURL(str)) return "url";
  else return "portalItem";
};
