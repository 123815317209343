/**
 * Focuses on the input element inside a search box.
 * Retries every 50 milliseconds if the input is not available or is disabled.
 */
export const focusSearchInput = () => {
  setTimeout(() => {
    const searchBox = document.querySelector(".search__box");
    if (searchBox) {
      const input = searchBox.querySelector("input");

      if (input) {
        const focusInput = () => {
          if (input && !input.disabled) {
            input.focus();
          } else {
            setTimeout(focusInput, 50); // Retry every 100 ms
          }
        };

        focusInput();
      }
    }
  }, 100);
};

// Update the patterns object with more flexible regex patterns
const patterns = {
  dd: {
    regex:
      /^([NSns])?[,\s/]*(-?\d+(?:\.\d+)?)\s*°?\s*([NSns])?[\s,/.]+([EWOewo])?[,\s/]*(-?\d+(?:\.\d+)?)\s*°?\s*([EWOewo])?\s*$/,
    convert: (match, { hasNegativeLat, hasNegativeLng }) => {
      // Parse numbers directly to preserve negative signs
      const firstNum = match[2];
      const secondNum = match[5];

      let lat = parseFloat(firstNum);
      let lng = parseFloat(secondNum);

      // Only apply cardinal directions if no explicit sign exists
      if (
        hasNegativeLat ||
        match[1]?.toLowerCase() === "s" ||
        match[3]?.toLowerCase() === "s"
      ) {
        lat = -Math.abs(lat);
      }

      // For longitude, first check for explicit negative sign
      if (hasNegativeLng) {
        lng = -Math.abs(lng); // Preserve negative sign from input
      } else if (
        ["w", "o"].includes(match[4]?.toLowerCase() || match[6]?.toLowerCase())
      ) {
        lng = -Math.abs(lng); // Apply cardinal direction
      }

      return { lat, lng };
    },
  },
  dms: {
    // Include negative sign in the degree capture groups
    // regex2:
    //   /^([NSns])?[,\s]*(-?\d+)\s*°?\s*[-,;\s]?\s*(\d+)\s*['′]?\s*[-,;\s]?\s*(\d+\.?\d*)\s*["″'\\]+\s*([NSns])?[,\s/.;\\-]*([EWOewo])?[,\s]*(-?\d+)\s*°?\s*[-,;\s]?\s*(\d+)\s*['′]?\s*[-,;\s]?\s*(\d+\.?\d*)\s*["″'\\]+\s*([EWOewo])?\s*(?:\([^)]*\))?\s*$/,
    regex:
      /^([NSns])?[,\s]*(-?\d{1,3})(?:\s*°)?\s+(\d{1,2})(?:\s*['′])?\s+(\d{1,2}(?:\.\d+)?)(?:\s*["″])?\s*([NSns])?[,;\s]+([EWOewo])?[,\s]*(-?\d{1,3})(?:\s*°)?\s+(\d{1,2})(?:\s*['′])?\s+(\d{1,2}(?:\.\d+)?)(?:\s*["″])?\s*([EWOewo])?\s*$/,
    convert: (match, { hasNegativeLat, hasNegativeLng }) => {
      // Parse initial values including any negative signs
      const deg1 = parseFloat(match[2]);
      const min1 = parseFloat(match[3]);
      const sec1 = parseFloat(match[4]);
      const deg2 = parseFloat(match[7]);
      const min2 = parseFloat(match[8]);
      const sec2 = parseFloat(match[9]);

      // Validate minutes and seconds
      if (min1 >= 60 || min2 >= 60) {
        throw new Error("incorrect coordinates (>60 value for minutes)");
      }
      if (sec1 >= 60 || sec2 >= 60) {
        throw new Error("incorrect coordinates (>60 value for seconds)");
      }
      if ((min1 === 60 && sec1 > 0) || (min2 === 60 && sec2 > 0)) {
        throw new Error(
          "incorrect coordinates (60 equals 0 in next higher unit)"
        );
      }

      // Calculate the absolute values first
      let lat = Math.abs(deg1) + min1 / 60 + sec1 / 3600;
      let lng = Math.abs(deg2) + min2 / 60 + sec2 / 3600;

      // Apply sign from explicit negative or cardinal direction
      if (
        deg1 < 0 ||
        match[1]?.toLowerCase() === "s" ||
        match[5]?.toLowerCase() === "s" ||
        hasNegativeLat
      ) {
        lat = -lat;
      }
      if (
        deg2 < 0 ||
        hasNegativeLng ||
        ["w", "o"].includes(match[6]?.toLowerCase() || match[10]?.toLowerCase())
      ) {
        lng = -lng;
      }

      return { lat, lng };
    },
  },
  ddm: {
    // Degrees decimal minutes with various separators and formats
    regex:
      /^([NSns])?[,\s-]*(\d+)\s*°?\s*[\s,;]?\s*(\d+\.?\d*)\s*['′]?\s*([NSns])?[,\s/.;\\-]*([EWOewo])?[,\s-]*(\d+)\s*°?\s*[\s,;]?\s*(\d+\.?\d*)\s*['′]?\s*([EWOewo])?\s*(?:\([^)]*\))?\s*$/,
    convert: (match) => {
      const minutes1 = parseFloat(match[3]);
      const minutes2 = parseFloat(match[7]);

      // Validate minutes
      if (minutes1 >= 60 || minutes2 >= 60) {
        throw new Error("incorrect coordinates (>60 value for minutes)");
      }
      if (minutes1 === 60 || minutes2 === 60) {
        throw new Error(
          "incorrect coordinates (60 equals 0 in next higher unit)"
        );
      }

      let lat = parseFloat(match[2]) + minutes1 / 60;
      let lng = parseFloat(match[6]) + minutes2 / 60;

      if ((match[1] || match[4]) === "S" || (match[1] || match[4]) === "s")
        lat = -lat;
      if (
        (match[5] || match[8]) === "W" ||
        (match[5] || match[8]) === "w" ||
        (match[5] || match[8]) === "O" ||
        (match[5] || match[8]) === "o"
      )
        lng = -lng;
      return { lat, lng };
    },
  },
};

export const hasNegativeCoordinates = (str) => {
  const coords = str.split(",").map((coord) => coord.trim());
  if (coords.length !== 2)
    return {
      hasNegativeLat: false,
      hasNegativeLng: false,
      hasAnyNegative: false,
    };

  return {
    hasNegativeLat: coords[0].startsWith("-"),
    hasNegativeLng: coords[1].startsWith("-"),
    hasAnyNegative: coords[0].startsWith("-") || coords[1].startsWith("-"),
  };
};

export const checkForCoordinates = (text) => {
  // Enhanced text cleanup
  // const cleanText = text
  //   .trim()
  //   .replace(/\s+/g, " ")
  //   .replace(/["""]/g, '"')
  //   .replace(/[''′]/g, "'")
  //   .replace(/\\"/g, '"')
  //   .replace(/\\'/g, "'")
  //   .replace(/;/g, " ") // Convert semicolons to spaces
  //   .replace(/([NSEWnsewOo]);/g, "$1 ") // Preserve cardinal directions when followed by semicolon
  //   .replace(/\\([NSEWnsewOo])/g, "$1")
  //   .replace(/[\\]+/g, "")
  //   .replace(/\s*\([^)]*\)\s*$/, "")
  //   .replace(/\s*[,;]\s*([NSEWnsewOo])/g, " $1")
  //   .replaceAll(/\\/g, "");
  // Clean up separators before cardinal directions

  const cleanText = text
    .trim()
    .replace(/['′]/g, "'") // Normalize single quotes
    .replace(/\\"/g, "''") // Replace \" with two single quotes
    .replace(/["\\]/g, "") // Remove remaining double quotes and backslashes
    .replace(/;/g, " ") // Convert semicolons to spaces
    .replace(/([NSEWnsewOo]);/g, "$1 ") // Preserve cardinal directions followed by semicolon
    .replace(/([NSEWnsewOo])/g, "$1") // Ensure cardinal directions are properly spaced
    .replace(/\s*\([^)]*\)\s*$/, "") // Remove trailing parentheses
    .replace(/\s*[,;]\s*([NSEWnsewOo])/g, " $1") // Clean up separators before cardinal directions
    .replace(/°/g, " ")
    .replace(/”/g, " ")
    .replace(/’/g, " ")
    .replace(/'/g, " ") //replace single quotes
    .replace(/''/g, "") //replace single quotes
    .replace(/\s+/g, " "); // Replace multiple whitespace with single space

  // Check for negative values
  const { hasNegativeLat, hasNegativeLng } = hasNegativeCoordinates(cleanText);

  // Try each format
  for (const [format, pattern] of Object.entries(patterns)) {
    let match = cleanText.match(pattern.regex);

    if (match) {
      try {
        const { lat, lng } = pattern.convert(match, {
          hasNegativeLat,
          hasNegativeLng,
        });

        // Validate coordinates
        if (lat < -90 || lat > 90) {
          return { error: "incorrect coordinates (latitude out of range)" };
        }
        if (lng < -180 || lng > 180) {
          return { error: "incorrect coordinates (longitude out of range)" };
        }

        // Round to 5 decimal places
        return {
          latitude: Math.round(lat * 100000) / 100000,
          longitude: Math.round(lng * 100000) / 100000,
          format,
        };
      } catch (error) {
        return { error: error.message };
      }
    }
  }

  return null;
};

export const testCoordinateParser = (examples) => {
  examples.forEach((example, index) => {
    const input = example["Example Input "].trim();
    const expectedResult = example[" Expected Result"].trim();

    console.group(`Test Case ${index + 1}`);
    console.log("Input:", input);
    console.log("Expected:", expectedResult);

    const result = checkForCoordinates(input);
    let actualResult;

    if (!result) {
      actualResult = "No match found";
    } else if (result.error) {
      actualResult = result.error;
    } else {
      console.log(result);

      actualResult = `${result.latitude}, ${result.longitude}`;
    }

    console.log("Actual:", actualResult);
    console.log("Matches:", actualResult.trim() === expectedResult);
    console.groupEnd();
  });
};

//for debugging test cases
export const testFailedMatches = (examples) => {
  const failedCases = [];
  const workingCases = [];

  examples.Sheet1.filter((example) => {
    const input = example["Example Input "].trim();
    const expectedResult = example[" Expected Result"].trim();

    // Skip cases where we expect incorrect coordinates
    if (expectedResult.includes("incorrect coordinates")) {
      return false;
    }

    const result = checkForCoordinates(input);

    // Include cases where we got no match but should have
    if (typeof result === "string") {
      failedCases.push({
        ...example,
        returnedResult: result,
      });
      return true;
    } else {
      console.log(result);

      const actualResult = `${result.latitude}, ${result.longitude}`.trim();
      if (actualResult !== expectedResult.trim()) {
        failedCases.push({
          ...example,
          returnedResult: actualResult,
        });
        return true;
      } else {
        workingCases.push({
          ...example,
          returnedResult: actualResult,
        });
      }
    }

    return false;
  });

  if (failedCases.length > 0) {
    console.group("Failed Matches - Cases that should match but don't:");
    failedCases.forEach((example, index) => {
      console.log(
        `${index + 1}. Input: "${example[
          "Example Input "
        ].trim()}" - Expected: ${example[" Expected Result"].trim()}`
      );
    });

    console.log(failedCases);
    console.log(workingCases.filter((item) => item.Format === "DMS"));

    console.groupEnd();
  } else {
    console.log("All valid coordinate formats are matching correctly!");
  }
};
