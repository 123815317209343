import { loadModules } from "esri-loader";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import useEditor from "../../../../hooks/useEditor";
import { graphicsLayer, view } from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import {
  getIconValueDefinitions,
  getLayerSymbology,
} from "../../../../utils/symbologies";
import { svgToBase } from "../../../Icons/layerSvg/clusterAssets";
import CoordinatesSelection from "../CoordinatesSelection/CoordinatesSelection";
import SituationalRoads from "../CoordinatesSelection/SituationalRoads/SituationalRoads";
import AerodromeClass from "../EditableLayerList/Aerodromes/AerodromeClass";
import AerodromeType from "../EditableLayerList/Aerodromes/AerodromeType";
import Annotations from "../EditableLayerList/Annotations/Annotations";
import EditFeatures from "../EditFeatures/EditFeatures";
import { StyledEditorBody } from "../Editor-styled";
import { EditorContext, UpdateType } from "../EditorContextProvider";
import EditorFields from "../EditorFields/EditorFields";
import EditorHeader from "../EditorHeader";
import {
  addLayerEffect,
  getFeatureNameField,
  removeLayerEffects,
} from "../helpers";
import Loader from "../Loader/Loader";
import Message from "../Message/Message";
import SaveMessage from "../Message/SaveMessage/SaveMessage";
import Platform from "../Platform/Platform";
import { ALL_STEPS, EditType, pointSymbol } from "./EditorSwiper";
import EditorSwiperContextProvider from "./EditorSwiperContextProvider";
import Footer from "./Footer";

const baseLineColor = "#34495e";
const messageTimeout = 3000;

const EditorSlides = ({
  editableLayers,
  editableLayer,
  handleGoBack,
  editType,
  setEditType,
  baselineLayers,
}) => {
  const context = useContext(EditorContext);
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const [count, setCount] = useState(0);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  // const [showSituationalEditMessage, setShowSituationalEditMessage] =
  //   useState(false);
  // const [showReportManagerMessage, setShowReportManagerMessage] =
  //   useState(false);

  // const [initialUpdateType, setInitialUpdateType] = useState(null);

  const [openSnackbar] = useCustomSnackbar();

  const {
    setPopupFeature,
    resetHighlightFeature,
    batchUpdateFeatures,
    setEditableLayer,
    popupFeature,
    updateType,
    setUpdateType,
    activeColor,
    resetEditor,
    addedPoints,
    highlightFeature,
    situationalUpdateType,
    setHighlightFeature,
    setHighlightSymbol,
    setBatchUpdateFeatures,
    editingInitialUpdateType,
    setEditingInitialUpdateType,
    sketchGraphicsLayer,
    setSketchGraphicsLayer,
    setSketchViewModel,
    attachments,
    setAttachments,
    setEditableLayerLoaded,
    handleSelectFeature,
    setAddedPoints,
  } = context;

  const { airdromeType, setAirdromeType } = context;
  const { prefilledFields, setPrefilledFields } = context;
  const { showWarning, setShowWarning } = context;
  const { clickedPoints } = context;
  const [actionType, setActionType] = useState("");
  const { open: featureTableOpen } = useSelector(
    (state) => state.featureTableReducer
  );

  const swiperRef = useRef();

  useEffect(() => {
    const clickEventInPopup = (feature) => {
      setLoading(true);
      const layer = feature.sourceLayer ? feature.sourceLayer : feature.layer;
      feature.geometry = feature.originalGeometry
        ? feature.originalGeometry
        : feature.geometry;

      view
        .whenLayerView(layer)
        .then(() => {
          layer?.renderer?.getSymbolAsync(highlightFeature).then((symbol) => {
            const clone = feature.clone();
            if (symbol.style === "circle") {
              clone.symbol = pointSymbol;
            } else {
              clone.symbol = symbol;
            }
            graphicsLayer.add(clone);
            setHighlightSymbol(symbol);
          });

          setPopupFeature(true);
          setHighlightFeature(feature);
          setLoading(false);
          setUpdateType(UpdateType.situational);
          slideTo(2);
          view.closePopup();

          addLayerEffect([]);
          setTimeout(() => {}, 0);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (highlightFeature) {
      const layer = highlightFeature.sourceLayer
        ? highlightFeature.sourceLayer
        : highlightFeature.layer;

      document.body.style.cursor = "wait";
      highlightFeature.geometry = highlightFeature.originalGeometry
        ? highlightFeature.originalGeometry
        : highlightFeature.geometry;
      setPopupFeature(true);
      setUpdateType(UpdateType.situational);
      slideTo(2);
      handleSelectFeature(layer, highlightFeature).then(() => {
        setLoading(false);

        view.closePopup();
        addLayerEffect([]);
        document.body.style.cursor = "default";
      });
    } else {
      if (updateType === UpdateType.situational) {
        slideTo(1);
      } else {
        slideTo(0);
      }
    }
  }, []);

  useEffect(() => {
    let handler;
    if (!highlightFeature) {
      loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
        /** Push report an update button on the popup */
        handler = reactiveUtils.on(
          () => view.popup,
          "trigger-action",
          (event) => {
            if (event.action.id === "edit-action") {
              const feature = view.popup.selectedFeature;

              const layer =
                editableLayer ||
                (feature.sourceLayer ? feature.sourceLayer : feature.layer);

              document.body.style.cursor = "wait";
              setBatchUpdateFeatures([]);
              graphicsLayer.removeAll();
              handleSelectFeature(layer, feature).then(() => {
                view.closePopup();
                document.body.style.cursor = "default";
                addLayerEffect([]);

                slideTo(2);
                if (!editableLayer) {
                  if (!baselineLayers.includes(layer)) {
                    setPopupFeature(true);
                    setUpdateType(UpdateType.situational);
                  }

                  setEditableLayer(layer);
                }
              });
            }
          }
        );
      });
    }

    return () => {
      handler?.remove();
    };
  }, [highlightFeature, editableLayer, baselineLayers]);

  // const removeLayerEffect = useCallback(() => {
  //   view.map.layers.forEach((layer) => {
  //     if (graphicsLayer.id !== layer.id && layer.title !== "wld_bnd_adm0") {
  //       layer.effect = undefined;
  //     }
  //   });
  // }, [editType, editableLayer]);

  const handleNext = useCallback(() => {
    swiperRef.current.swiper.slideNext();
    setCount((prev) => prev + 1);
  }, []);

  const goBack = (slide) => {
    handleReset();
    handleGoBack(slide);
  };

  const handlePrev = useCallback(() => {
    const activeIndex = swiperRef.current?.swiper?.activeIndex;
    const currSlide = slides[activeIndex];
    const isEnd = swiperRef.current?.swiper?.isEnd;

    // if (
    //   updateType !== editingInitialUpdateType &&
    //   currSlide.id === ALL_STEPS.fillFields
    // ) {
    //   //when updating situational we have option of going to baseline fields
    //   //if we are at baseline we have to return back to situational updates
    //   const prevSlide = slides[activeIndex - 1];
    //   if (
    //     prevSlide?.id !== ALL_STEPS.fillFields &&
    //     EditType.edit === editType &&
    //     updateType === UpdateType.baseline
    //   ) {
    //     setUpdateType(editingInitialUpdateType);
    //     return;
    //   }
    // }

    // //when going back from editing/updating fields changing updateType to initial
    // if (
    //   initialUpdateType !== updateType &&
    //   currSlide.id === ALL_STEPS.fillFields
    // ) {
    //   const prevSlide = slides[activeIndex - 1];
    //   if (
    //     prevSlide?.id !== ALL_STEPS.fillFields &&
    //     EditType.edit === editType
    //   ) {
    //     setUpdateType(initialUpdateType);
    //   }
    // }

    if (editType === EditType.create) {
      // const nextSlide = slides[activeIndex - 1];
      // if (
      //   nextSlide.id === ALL_STEPS.entryPoints &&
      //   updateType !== editingInitialUpdateType
      // ) {
      //   setUpdateType(editingInitialUpdateType);
      //   setInitialUpdateType(null);
      // }
      // const nextSlide = slides[activeIndex - 1];
      // if (isEnd && updateType === UpdateType.situational) {
      //   setUpdateType(initialUpdateType);
      //   swiperRef.current.swiper.slideTo(1);
      //   return;
      // } else if (
      //   prevSlide?.id === ALL_STEPS.entryPoints &&
      //   initialUpdateType !== updateType
      // ) {
      //   setUpdateType(initialUpdateType);
      // }
    }

    if (activeIndex === 0) {
      goBack();
      return;
    }

    if (activeIndex === 1 && updateType === UpdateType.situational) {
      goBack();
    } else {
      swiperRef.current.swiper.slidePrev();
    }
    setCount((prev) => prev + 1);
  }, [updateType, editingInitialUpdateType]);

  const slideTo = useCallback((index) => {
    if (!swiperRef.current?.swiper) return;
    swiperRef.current.swiper.slideTo(index);
    setCount((prev) => prev + 1);
  }, []);

  const handleReset = useCallback(() => {
    setEditType(EditType.edit);

    if (!featureTableOpen) {
      removeLayerEffects();
    }

    resetEditor();
    setUpdateType(null);
  }, [featureTableOpen]);

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, [handleReset]);

  const handleSetEditableLayer = useCallback(
    (layer) => {
      setEditableLayer(layer);
      handleNext();
    },
    [handleNext]
  );

  const handleSelection = useCallback(
    (item) => {
      setPrefilledFields((prev) => prev.concat(item));
      handleNext();
    },
    [setPrefilledFields, handleNext]
  );

  const handleAirportTypeSelection = useCallback(
    (item) => {
      const type = item.code;
      const symbology = getLayerSymbology(editableLayer, config);
      const iconValueDefinition = getIconValueDefinitions(
        symbology,
        editableLayer,
        1,
        t
      );

      const filteredIconDefinition = iconValueDefinition.filter((item) => {
        const [aerodromeClass, aerodromeType] = item.value.split(",");
        return aerodromeType == type;
      });
      setAirdromeType(type);
      setPrefilledFields([item]);

      if (filteredIconDefinition.length === 1) {
        const airport = filteredIconDefinition[0];
        const [code] = airport.value.split(",").map((value) => Number(value));
        handleSelection({
          code: Number.isFinite(code) ? code : 0,
          icon: svgToBase(airport.icon, symbology.defaultColor),
          label: airport.label,
          iconName: airport.icon,
          fieldName: "aerodromeclass",
        });
      } else {
        handleNext();
        // setPrefilledFields(prev=>prev.concat(item));
        // goToStep(ALL_STEPS.aerodromeClass);
        // handleNext();
      }
    },
    [editableLayer, editType, handleNext]
  );

  const renderSlides = () => {
    const layerConfig = editableLayer?.layerConfig;
    const titleLabel = layerConfig?.titleLabel;

    const allSlides = [
      {
        id: ALL_STEPS.selectLayer,
        component: (
          <Platform
            entryPointsLayers={[]}
            layers={baselineLayers}
            onLayerSelect={handleSetEditableLayer}
            updateType={UpdateType.baseline}
          />
        ),
      },
    ];

    allSlides.push({
      id: ALL_STEPS.entryPoints,
      component: (
        <EditFeatures
          editableLayers={editableLayers}
          handleNext={handleNext}
          editType={editType}
        />
      ),
    });

    // if (titleLabel !== "roadsSituational") {
    //   allSlides.push({
    //     id: ALL_STEPS.entryPoints,
    //     component: (
    //       <EditFeatures
    //         editableLayers={editableLayers}
    //         handleNext={handleNext}
    //         editType={editType}
    //       />
    //     ),
    //   });
    // } else if (false && titleLabel === "roads") {
    //   allSlides.push({
    //     id: ALL_STEPS.selectLocation,
    //     component: (
    // <CoordinatesSelection
    //   editableLayer={editableLayer}
    //   t={t}
    //   config={config}
    //   clickActive={true}
    //   editType={editType}
    //   editableLayers={editableLayers}
    //   handleNext={handleNext}
    // />
    //     ),
    //   });
    // } else if (titleLabel === "roadsSituational") {
    //   allSlides.push({
    //     id: ALL_STEPS.selectLocation,
    //     component: (
    //       <CoordinatesSelection
    //         editableLayer={editableLayer}
    //         t={t}
    //         config={config}
    //         clickActive={true}
    //         editType={editType}
    //         editableLayers={editableLayers}
    //         handleNext={handleNext}
    //       />
    //     ),
    //   });

    // if (editType === EditType.create) {

    //   } else {
    //     allSlides.push({
    //       id: ALL_STEPS.roadsSituationalManual,
    //       component: (
    //         <RoadsSituationalManual
    //           editType={editType}
    //           // editableLayer={editableLayer}
    //           handleNext={handleNext}
    //           handlePrev={handlePrev}
    //         />
    //       ),
    //     });
    //   }
    // }

    if (editType === EditType.create) {
      if (!!titleLabel && titleLabel.includes("aerodromes")) {
        allSlides.push({
          id: ALL_STEPS.aerodromeType,
          component: (
            <AerodromeType
              layer={editableLayer}
              config={config}
              handleSelection={handleAirportTypeSelection}
              t={t}
            />
          ),
        });

        if (prefilledFields.length >= 1) {
          const type = prefilledFields[0].code;

          const symbology = getLayerSymbology(editableLayer, config);
          const iconValueDefinition = getIconValueDefinitions(
            symbology,
            editableLayer,
            1,
            t
          );

          const filteredIconDefinition = iconValueDefinition.filter((item) => {
            const [aerodromeClass, aerodromeType] = item.value.split(",");
            return aerodromeType == type;
          });

          if (filteredIconDefinition.length > 1) {
            allSlides.push({
              id: ALL_STEPS.aerodromeClass,
              component: (
                <AerodromeClass
                  layer={editableLayer}
                  config={config}
                  t={t}
                  type={airdromeType}
                  handleSelection={handleSelection}
                />
              ),
            });
          }
        }
      }

      if (titleLabel === "pointAnnotations") {
        allSlides.push({
          id: ALL_STEPS.annotations,
          component: (
            <Annotations
              layer={editableLayer}
              config={config}
              handleSelection={handleSelection}
              t={t}
            />
          ),
        });
      }

      if (editableLayer.geometryType !== "point") {
        if (editableLayer.layerConfig.titleLabel === "roadsSituational") {
          allSlides.push({
            id: ALL_STEPS.enterRoadsSituationalCoordinates,
            component: (
              <SituationalRoads
                handleNext={handleNext}
                config={config}
                editableLayers={editableLayers}
              />
              // <EnterCoordinates
              //   handleNext={handleNext}
              //   handlePrev={handlePrev}
              //   editType={editType}
              //   editableLayer={editableLayer}
              //   goToStep={() => {}}
              // />
            ),
          });
        } else {
          allSlides.push({
            id: ALL_STEPS.selectLocation,
            component: (
              <CoordinatesSelection
                editableLayer={editableLayer}
                config={config}
                clickActive={true}
                editType={editType}
                handleNext={handleNext}
              />
            ),
          });
        }
      }
    }

    allSlides.push({
      id: ALL_STEPS.fillFields,
      type: "situational",
      component: (
        <EditorFields
          editableLayer={editableLayer}
          config={config}
          editType={editType}
          updateType={updateType}
          handleSubmit={handleSubmit(doEdit)}
          getIsDeletedField={getIsDeletedField}
          doDelete={doDelete}
        />
      ),
    });

    // if (editType === EditType.edit && updateType === UpdateType.situational) {
    //   allSlides.push({
    //     id: ALL_STEPS.fillFields,
    //     type: "situational",
    //     component: (
    //       <EditorFields
    //         editableLayer={editableLayer}
    //         config={config}
    //         editType={editType}
    //         isRequired={true}
    //         updateType={updateType}
    //       />
    //     ),
    //   });
    // } else {
    //   const baselineFields = editableLayer?.layerConfig?.baselineFields
    //     ? editableLayer?.layerConfig?.baselineFields
    //     : [];

    //   if (
    //     baseLineRequiredFields.length > 0 &&
    //     baselineFields.length > 0 &&
    //     batchUpdateFeatures.length === 0
    //   ) {
    //     allSlides.push({
    //       id: ALL_STEPS.fillFields,
    //       type: "required",
    //       component: (
    //         <EditorFields
    //           editableLayer={editableLayer}
    //           config={config}
    //           editType={editType}
    //           isRequired={true}
    //           updateType={UpdateType.baseline}
    //         />
    //       ),
    //     });
    //   }

    // if (baselineFields.length > 0) {
    //   allSlides.push({
    //     id: ALL_STEPS.fillFields,
    //     type: "optional",
    //     component: (
    //       <EditorFields
    //         editableLayer={editableLayer}
    //         config={config}
    //         editType={editType}
    //         isRequired={false}
    //         updateType={updateType}
    //       />
    //     ),
    //   });
    // }

    // if (EditType.create) {
    //   allSlides.push({
    //     id: ALL_STEPS.fillFields,
    //     step: "create",
    //     component: (
    //       <EditorFields
    //         editableLayer={editableLayer}
    //         config={config}
    //         editType={editType}
    //         isRequired={false}
    //         updateType={updateType}
    //         initialUpdateType={initialUpdateType}
    //       />
    //     ),
    //   });
    // }
    // }

    return allSlides;
  };

  const handleSave = (hasTimeout = true) => {
    if (hasTimeout) {
      setShowSaveMessage(true);
    }

    setTimeout(
      () => {
        if (popupFeature) {
          goBack();
        } else {
          reset();
          resetHighlightFeature();

          // Clear batch updates if the edits were applied to batch features
          if (!highlightFeature && batchUpdateFeatures.length > 0) {
            setBatchUpdateFeatures([]);
          }

          // if (initialUpdateType !== updateType) {
          //   setInitialUpdateType(null);
          //   setUpdateType(initialUpdateType);
          // }

          slideTo(1);

          setShowSaveMessage(false);
        }
      },
      hasTimeout ? messageTimeout : 0
    );
  };

  const {
    doEdit,
    loading,
    doDelete,
    setLoading,
    methods,
    getIsDeletedField,
    confirmStatus,
  } = useEditor({
    editableLayer,
    editType,
    config,
    onSave: ({ updateFeatures, actionType }) => {
      setActionType(actionType);
      const activeIndex = swiperRef.current?.swiper?.activeIndex;
      const currSlide = slides[activeIndex];
      if (actionType === "confirm") {
        setShowSaveMessage(true);
        setTimeout(() => {
          setBatchUpdateFeatures([]);
          setShowSaveMessage(false);
        }, messageTimeout);
        return;
      }

      if (actionType === "delete") {
        if (currSlide.id === ALL_STEPS.fillFields) {
          handleSave();
        } else if (currSlide.id === ALL_STEPS.entryPoints) {
          setShowSaveMessage(true);
          setTimeout(() => {
            setBatchUpdateFeatures([]);
            setShowSaveMessage(false);
          }, messageTimeout);
        }
        return;
      }

      handleSave();
      return;
    },
    goBack,
    openSnackbar,
  });

  const {
    formState: { isValid },
    handleSubmit,
    getValues,
    reset,
  } = methods;

  const isNextDisabled = useMemo(() => {
    const formValues = getValues();

    const currIndex = swiperRef.current?.swiper?.activeIndex;
    const currSlide = swiperRef.current?.swiper?.slides[currIndex];

    switch (currSlide?.id) {
      case ALL_STEPS.selectLayer: {
        return !editableLayer;
      }
      case ALL_STEPS.selectLocation: {
        return editType === EditType.edit ? true : addedPoints.length === 0;
      }
      case ALL_STEPS.enterRoadsSituationalCoordinates: {
        return (
          clickedPoints.filter((item) => item.coordinate.length > 0).length <
            2 || addedPoints.length === 0
        );
      }
      case ALL_STEPS.roadsSituationalManual: {
        return addedPoints.length === 0;
      }
      case ALL_STEPS.entryPoints: {
        return batchUpdateFeatures.length === 0;
      }
      case ALL_STEPS.fillFields: {
        // const disabled =
        //   situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT
        //     ? !isValid || !formValues[currAsOfDateFieldName]
        //     : !isValid;
        return false;
      }
      default: {
        return true;
      }
    }
  }, [
    count,
    editableLayer,
    editType,
    highlightFeature,
    addedPoints,
    clickedPoints,
    batchUpdateFeatures,
    isValid,
    situationalUpdateType,
  ]);

  const slides = renderSlides();
  const currSlide = slides[swiperRef.current?.swiper?.activeIndex];

  const nameField = useMemo(() => {
    return getFeatureNameField(editableLayer);
  }, [highlightFeature]);

  const value = methods.watch(nameField);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <FormProvider {...methods}>
        <EditorHeader
          slides={slides}
          popupFeature={popupFeature}
          handlePrev={handlePrev}
          editType={editType}
          activeIndex={swiperRef.current?.swiper?.activeIndex}
          goBack={goBack}
        />
        {loading && <Loader color={activeColor} bg="#eeeeeed4" />}

        {showWarning.show && (
          <Message
            color={activeColor}
            onCancel={() => {
              setShowWarning({
                show: false,
                action: null,
              });
            }}
            onSubmit={() => {
              if (showWarning.action === "close") {
                goBack();
              } else if (showWarning.action === "back") {
                graphicsLayer.removeAll();
                setAddedPoints([]);
                resetHighlightFeature();
                reset();
                handlePrev();
              }
              setShowWarning({
                show: false,
                action: null,
              });
            }}
            title={t("cycleManager.areYouSure.defaultTitle")}
            subtitle={t("cycleManager.areYouSure.unsavedChanges")}
          />
        )}

        {showSaveMessage && (
          <SaveMessage
            updateType={updateType}
            actionType={
              actionType === "delete"
                ? "deleted"
                : UpdateType.situational === updateType
                ? "updated"
                : editType === EditType.create
                ? "added"
                : "edited"
            }
            highlightFeature={highlightFeature}
            batchUpdateFeatures={batchUpdateFeatures}
            editableLayer={editableLayer}
            activeColor={activeColor}
            value={value}
          />
        )}

        {/* {showReportManagerMessage && (
          <Message
            color={activeColor}
            onCancel={goBack}
            onSubmit={() => {
              goBack("reportManager");
            }}
            title={t(
              "screen.widget.Editor.manager.messages.reportManagerMessage"
            )}
          />
        )} */}
        <StyledEditorBody>
          {/* {showSituationalEditMessage && (
            <SituationalMessage
              onCancel={() => {
                setShowSituationalEditMessage(false);
                handleSave(false);
              }}
              onSubmit={() => {
                reset();
                setShowSituationalEditMessage(false);
                setUpdateType(UpdateType.situational);
                setEditingInitialUpdateType(UpdateType.situational);
                setEditType(EditType.edit);
              }}
            />
          )} */}
          <EditorSwiperContextProvider editableLayer={editableLayer}>
            <Swiper
              ref={swiperRef}
              preventInteractionOnTransition
              noSwiping
              allowTouchMove={false}
              preventClicks={false}
              simulateTouch={false}
              preventClicksPropagation={false}
              watchSlidesProgress
              style={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
              }}
              onSlideChange={(swiper) => {
                const currSlide = swiper.slides[swiper.activeIndex];
                if (currSlide.id === ALL_STEPS.selectLayer) {
                  setEditType(EditType.edit);
                  resetEditor();
                  removeLayerEffects();

                  setEditableLayerLoaded(false);
                }

                if (currSlide.id === ALL_STEPS.entryPoints) {
                  if (
                    editingInitialUpdateType &&
                    editingInitialUpdateType !== updateType
                  ) {
                    setUpdateType(editingInitialUpdateType);
                  } else if (editingInitialUpdateType) {
                    setEditingInitialUpdateType(null);
                  }

                  sketchGraphicsLayer?.removeAll();
                  view.map.remove(sketchGraphicsLayer);
                  setSketchGraphicsLayer(null);
                  setSketchViewModel(null);
                  reset();
                  resetHighlightFeature();
                  view.graphics.removeAll();
                  setAddedPoints([]);
                }

                if (
                  currSlide.id !== ALL_STEPS.fillFields &&
                  attachments.files.length > 0
                ) {
                  setAttachments({ files: [], previews: {} });
                }
              }}
              onSlidePrevTransitionStart={(swiper) => {
                if (swiper.activeIndex === 1) {
                  setEditType(EditType.edit);
                  reset();
                }
              }}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={slide.id + "-" + index} id={slide.id}>
                  {({ isVisible }) =>
                    isVisible && (
                      <div
                        style={{
                          overflow:
                            slide.id === ALL_STEPS.fillFields
                              ? "hidden"
                              : "auto",
                          height: "100%",
                          background:
                            slide.id === ALL_STEPS.entryPoints
                              ? "rgb(238, 238, 238)"
                              : undefined,
                          padding:
                            slide.id === ALL_STEPS.entryPoints ||
                            slide.id === ALL_STEPS.selectLayer ||
                            slide.id === ALL_STEPS.fillFields ||
                            slide.id ===
                              ALL_STEPS.enterRoadsSituationalCoordinates
                              ? "0px 0px 0px"
                              : "0px 14px 12px 14px",
                        }}
                      >
                        {slide.component}
                      </div>
                    )
                  }
                </SwiperSlide>
              ))}
            </Swiper>
          </EditorSwiperContextProvider>
          {(currSlide?.id !== ALL_STEPS.fillFields ||
            (currSlide?.id === ALL_STEPS.fillFields &&
              batchUpdateFeatures.length > 0 &&
              !highlightFeature)) &&
            currSlide?.id !== ALL_STEPS.enterRoadsSituationalCoordinates && (
              <Footer
                confirmStatus={confirmStatus}
                editType={editType}
                setEditType={setEditType}
                isNextDisabled={isNextDisabled}
                handleNext={handleNext}
                handleSubmit={handleSubmit}
                doEdit={doEdit}
                getIsDeletedField={getIsDeletedField}
                doDelete={doDelete}
                isEnd={swiperRef.current?.swiper?.isEnd}
                currentSlide={
                  slides
                    ? slides[swiperRef.current?.swiper?.activeIndex]
                    : undefined
                }
              />
            )}
        </StyledEditorBody>
      </FormProvider>
    </div>
  );
};

export default EditorSlides;
