import React from "react";
import { FaSearchLocation } from "../../Icons";
import LayerIcon from "../../Icons/LayerIcon";
import {
  SearchLocationIconWrapper,
  StyledSuggestItem,
} from "../SearchWidget-styled";

const SuggestionItem = ({
  result,
  suggestion,
  selectedResultKey,
  handleSuggestionClick,
  config,
  t,
}) => {
  return (
    <StyledSuggestItem
      key={result.key}
      onClick={() => handleSuggestionClick(suggestion.label, result)}
      color="#ffffff"
      selected={result.key === selectedResultKey}
    >
      {suggestion.source?.layer ? (
        <LayerIcon
          config={config}
          layer={suggestion.source.layer}
          t={t}
          fill="#344054"
          bgFill="#fff"
          opsColor={result.key === selectedResultKey ? "#9b9b9b" : "#fff"}
          width={20}
          height={20}
          selected={result.key === selectedResultKey}
          textStyles={{
            width: "24px",
            display: "block",
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "18px",
            textAlign: "center",
            color: result.key === selectedResultKey ? "#fff" : "#344054",
          }}
        />
      ) : (
        <SearchLocationIconWrapper>
          <FaSearchLocation width="14px" height="14px" />
        </SearchLocationIconWrapper>
      )}
      <p>{result.text}</p>
    </StyledSuggestItem>
  );
};

export default SuggestionItem;
