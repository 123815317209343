import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMapExtentFilter } from "../../../../hooks";
import { view } from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { ROLE_EDITOR } from "../../../../utils/helper";
import ActionButton from "../../../Dashboard/CycleManager/Checklist/ChecklistTable/ActionButton";
import FilterGeometryButton from "../../../Dashboard/Editor/EditFeatures/Filters/FilterGeometryButton";
import { ConfirmButton } from "../../../Dashboard/Editor/EditorSwiper/Footer";
import { FilterMapExtentIcon, SaveIcon } from "../../../Icons";
import { FilterButton, Tooltip } from "../../../UI";

const TableButtons = ({
  confirmUpdateCount,
  handleUpdateAll,
  setFilterGeometry,
  setBatchUpdateFeatures,
  featureTableFullScreenActive,
  openFullScreen,
  exitFullScreen,
  filterGeometry,
  filtersActive,
}) => {
  const [filterGeometryActive, setFilterGeometryActive] = useState(false);
  const [isFilterMapExtentActive, setFilterMapExtentActive] =
    useMapExtentFilter({
      view,
      setFilterGeometry,
    });

  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!filtersActive) {
      if (filterGeometryActive) {
        setFilterGeometryActive(false);
      }

      if (isFilterMapExtentActive) {
        setFilterMapExtentActive(false);
      }
    }

    return () => {};
  }, [filtersActive]);

  useEffect(() => {
    if (featureTableFullScreenActive) {
      // setFilterGeometryActive(false);
      // setFilterGeometry(null);
      setFilterMapExtentActive(false);
    } else {
      // setFilterMapExtentActive(true);
    }
  }, [featureTableFullScreenActive]);

  const onDeleteFilterGeometry = useCallback(() => {
    setBatchUpdateFeatures([]);
    setFilterGeometryActive(false);
  }, []);

  const onFilterActiveChange = useCallback((isActive) => {
    if (!isActive) {
      setBatchUpdateFeatures([]);
    } else {
      setFilterMapExtentActive(false);
    }
    setFilterGeometryActive(isActive);
  }, []);

  const handleMapExtentChange = useCallback(() => {
    setFilterMapExtentActive((prev) => !prev);
    setFilterGeometryActive(false);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        gap: 16,
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 1,
      }}
    >
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <span style={{ color: "#4b4b4b", fontSize: 14 }}>
          {t("screen.widget.Filter.title")}:{" "}
        </span>
        <FilterGeometryButton
          setFilterGeometry={setFilterGeometry}
          onDelete={onDeleteFilterGeometry}
          onActiveChange={onFilterActiveChange}
          isActive={filterGeometryActive}
          disabled={featureTableFullScreenActive}
        />
        <Tooltip
          content={t("screen.widget.common.filterByExtent")}
          disabled={featureTableFullScreenActive}
          showOnHover
        >
          <FilterButton
            isActive={isFilterMapExtentActive}
            onClick={handleMapExtentChange}
            disabled={featureTableFullScreenActive}
          >
            <FilterMapExtentIcon />
          </FilterButton>
        </Tooltip>
      </div>
      {config.role === ROLE_EDITOR && (
        <>
          <div
            style={{ height: "20px", width: "1px", background: "#acaaaa" }}
          />
          <ConfirmButton
            count={confirmUpdateCount}
            color={config.opsColor}
            onClick={handleUpdateAll}
            disabled={confirmUpdateCount === 0}
            title={
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <SaveIcon
                  width="14"
                  height="14"
                  fill={confirmUpdateCount !== 0 ? config.opsColor : "#4b4b4b"}
                />{" "}
                Save
              </span>
            }
            showConfirmTitle={false}
          />
        </>
      )}
      <div style={{ height: "20px", width: "1px", background: "#acaaaa" }} />
      <ActionButton
        onClick={() => {
          if (!featureTableFullScreenActive) {
            openFullScreen();
          } else {
            exitFullScreen();
          }
        }}
        isActive
      >
        {!featureTableFullScreenActive ? (
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 1024 1024"
            height="16px"
            width="16px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M685.904 383.184l275.264-273.572-.896 155.072c-.289 12.928 9.967 24.176 22.912 23.888l16.416-.016c12.944-.304 23.648-8 23.92-20.928l.672-231.008c0-.223-.88-.399-.88-.623l1.264-11.712c.128-6.496-1.391-12.288-5.584-16.431-4.143-4.176-9.951-6.72-16.4-6.544l-11.696.272c-.223 0-.399.08-.64.113L760.77.687c-12.928.288-23.632 10.976-23.92 23.92l-.032 16.417c1.967 15.248 13.952 24.16 26.88 23.872l151.248.4L640.657 337.92c-12.496 12.496-12.496 32.752 0 45.264 12.496 12.48 32.752 12.48 45.247 0zM339.088 640.812L63.825 913.532l.88-154.224c.304-12.944-9.969-24.192-22.897-23.904l-17.423.032c-12.96.32-23.649 8-23.921 20.944l-.672 231.008c0 .224.88.367.88.623l-1.264 11.68c-.144 6.496 1.376 12.32 5.584 16.433 4.128 4.192 9.952 6.72 16.384 6.56l11.712-.288c.223 0 .383-.096.64-.096l230.495 1.008c12.928-.32 23.617-11.009 23.92-23.936l.032-16.432c-1.967-15.216-13.952-24.16-26.88-23.872l-151.247-.4L384.32 686.076c12.496-12.497 12.496-32.752 0-45.248s-32.737-12.512-45.233-.016zm685.122 346.56l-.672-231.01c-.288-12.944-10.992-20.624-23.92-20.944l-16.416-.032c-12.944-.289-23.184 10.975-22.912 23.903l.896 155.072-275.28-273.552c-12.496-12.496-32.752-12.496-45.248 0s-12.496 32.752 0 45.248L914.93 958.649l-151.232.4c-12.928-.288-24.912 8.657-26.88 23.872l.032 16.432c.304 12.944 11.008 23.633 23.92 23.936l229.504-1.007c.24 0 .416.095.64.095l11.696.288c6.448.16 12.272-2.368 16.4-6.56 4.193-4.128 5.696-9.936 5.584-16.432l-1.263-11.68c0-.255.88-.399.88-.622zM110.049 65.321l151.264-.397c12.928.288 24.912-8.64 26.88-23.873l-.032-16.431C287.84 11.677 277.15.972 264.24.7l-230.512.992c-.256-.032-.416-.112-.64-.112l-11.712-.273C14.945 1.132 9.105 3.676 4.992 7.851.784 11.995-.735 17.787-.592 24.283L.672 35.995c0 .223-.88.384-.88.624l.672 231.008c.288 12.928 10.977 20.624 23.921 20.928l17.424.015c12.928.288 23.183-10.96 22.895-23.888l-.88-154.224 275.264 272.72c12.48 12.497 32.753 12.497 45.25 0s12.496-32.768 0-45.264z"></path>
          </svg>
        ) : (
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 16 16"
            height="16px"
            width="16px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z"></path>
          </svg>
        )}
      </ActionButton>
    </div>
  );
};

export default TableButtons;
