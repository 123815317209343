import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { APP_ENV, LOGIE_WEBSITE } from "../../../../../data/constants";
import { ConfigContext } from "../../../../../utils/ConfigContext";
import {
  notificationMode,
  sendNotification,
} from "../../../../../utils/LC_API";
import { FiSend, IoMdCheckmark } from "../../../../Icons";
import Input from "../../../../Report/new/Input/Input";
import TextArea from "../../../../TextArea/TextArea";
import Message from "../../Message";
import {
  AnimatedEllipsis,
  AnimatedIcon,
  StyledBodyContainer,
  StyledButton,
  StyledForm,
  StyledSuccessMessage,
  StyledTitle,
  StyledTitleContainer,
} from "../NotificationMenu-styled";

const COUNTDOWN_TIME = 10000;

const NotificationMessage = ({ currentView, goBack }) => {
  const [countdown, setCountdown] = useState(0);
  const [cancelMessage, setCancelMessage] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const activeModule = useSelector((state) => state.activeModule);
  const { config } = useContext(ConfigContext);
  const { t, i18n } = useTranslation("common");

  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);
  const finishTimeoutRef = useRef(null);

  // Cleanup on unmount
  useEffect(() => {
    console.log({ notification_mode: notificationMode, environment: APP_ENV });

    return () => {
      handleCancel();
    };
  }, []);

  const handleCancel = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (finishTimeoutRef.current) {
      clearTimeout(finishTimeoutRef.current);
    }
    setCountdown(null);
    setCancelMessage(false);
    setSendMessage(false);
    setError(null);
    setLoading(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: undefined,
      body: undefined,
    },
  });

  const handleSendNotification = useCallback(
    async (data) => {
      try {
        const { title, body } = data;

        if (!currentView) return;
        const center = currentView.center;
        const { latitude, longitude } = center || {};
        const zoom = currentView.zoom;

        const params = new URLSearchParams();
        const urlParams = new URLSearchParams(window.location.search);

        const opId = urlParams.get("op");

        if (!opId) {
          if (config.alias) {
            params.append("op", opId);
          } else {
            params.append("op", config.id);
          }
        } else {
          params.append("op", opId);
        }

        if (latitude && longitude) {
          params.append("center", `${longitude},${latitude}`);
        }

        if (zoom) {
          params.append("zoom", zoom + 0.4);
        }

        if (activeModule) {
          params.append("module", activeModule);
        }

        const url = `${LOGIE_WEBSITE}/?${params.toString()}`;
        const pageTitle = t("ops." + config.id + ".title", config.title);

        const notificationMessage = {
          title: title,
          body: body,
          langcode: i18n.language,
          data: {
            type: "logie",
            data: {
              langcode: i18n.language,
              logie_type: "operation",
              url: url,
              title: pageTitle,
            },
          },
        };

        let activities = [];

        if (config.activityId) {
          activities.push(config.activityId);
        }

        setCountdown(COUNTDOWN_TIME);
        setCancelMessage(true);

        // Start countdown
        intervalRef.current = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 0) {
              clearInterval(intervalRef.current);
              return null;
            }
            return prev - 1000;
          });
        }, 1000);

        const mode = {
          activity_ids: activities,
        };

        // Schedule notification
        scheduleNotification(notificationMessage, mode);
      } catch (err) {
        console.log(err);
        handleCancel();
      }
    },
    [currentView, activeModule, config, t, i18n]
  );

  const scheduleNotification = useCallback(
    (message, mode) => {
      timeoutRef.current = setTimeout(async () => {
        setCancelMessage(false);
        setCountdown(null);
        try {
          setLoading(true);
          setSendMessage(true);
          const res = await sendNotification(message, mode);
          console.log(res);
        } catch (err) {
          if (err) {
            if (err.details && err.details.httpStatus === 0) {
              return;
            }
          }
          console.log(err);
          setError(err);
        } finally {
          setLoading(false);
          finishTimeoutRef.current = setTimeout(() => {
            goBack();
            setSendMessage(false);
            setError(null);
          }, 3000);
        }
      }, COUNTDOWN_TIME);
    },
    [goBack]
  );

  const sendMessageTitle = useMemo(() => {
    return loading ? (
      <AnimatedIcon>
        {t("screen.widget.Editor.notifications.sendMessages.sending")}
        <AnimatedEllipsis />
        <FiSend width="18" height="18" stroke={config.opsColor} />
      </AnimatedIcon>
    ) : error ? (
      <StyledSuccessMessage>
        {t("screen.widget.Editor.notifications.sendMessages.error")}
      </StyledSuccessMessage>
    ) : (
      <StyledSuccessMessage>
        <IoMdCheckmark width="16" height="16" />
        {t("screen.widget.Editor.notifications.sendMessages.success")}
      </StyledSuccessMessage>
    );
  }, [loading, error]);

  return (
    <StyledBodyContainer>
      {cancelMessage && (
        <Message
          position="absolute"
          title={t("screen.widget.Editor.notifications.sendMessages.countdown")}
          subtitle={
            <span style={{ fontWeight: 500, fontSize: 16 }}>{`${Math.ceil(
              countdown / 1000
            )}`}</span>
          }
          submitTitle={t("screen.popup.actions.cancel")}
          onSubmit={handleCancel}
        />
      )}
      {sendMessage && <Message position="absolute">{sendMessageTitle}</Message>}
      <StyledTitleContainer>
        <StyledTitle>
          {t("screen.widget.Editor.notifications.message.title")}
        </StyledTitle>
      </StyledTitleContainer>

      <StyledForm onSubmit={handleSubmit(handleSendNotification)}>
        <Input
          label={t("screen.widget.Editor.notifications.message.inputs.title")}
          register={register("title", { required: true })}
          errorMessage={
            errors.title &&
            errors.title.type === "required" &&
            t("screen.widget.common.fieldIsRequired")
          }
          placeholder=""
          maxLength={24}
          borderColor={config.opsColor}
        />
        <TextArea
          wrapperStyles={{ marginTop: 20 }}
          label={t("screen.widget.Editor.notifications.message.inputs.body")}
          placeholder=""
          register={register("body", { required: true })}
          error={
            errors.body &&
            errors.body.type === "required" &&
            t("screen.widget.common.fieldIsRequired")
          }
          borderColor={config.opsColor}
          maxLength={110}
          height="78px"
        />
        <StyledButton type="submit" bg={config.opsColor}>
          <span>{t("screen.widget.Editor.notifications.message.button")}</span>
          <FiSend width="14" height="14" />
        </StyledButton>
      </StyledForm>
    </StyledBodyContainer>
  );
};

export default NotificationMessage;
