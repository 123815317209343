import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { view } from "../../utils/API";
import { ConfigContext } from "../../utils/ConfigContext";
import FeatureTable from "./FeatureTable";

export const geometryTypes = new Set(["point", "line", "polygon", "polyline"]);

export const checkLayerForFeatureTable = (layer, show) => {
  return (
    layer.layerConfig &&
    layer.layerConfig.editable &&
    (show ? layer.visible : !show) &&
    layer.layerConfig.featureTable.isShownInFeatureTable &&
    layer.geometryType &&
    geometryTypes.has(layer.geometryType)
  );
};

const FeatureTableWrapper = ({ openSnackbar }) => {
  const { config } = useContext(ConfigContext);
  const [layerExists, setLayerExists] = useState(false);
  const { activeModule, layersLoading, printWidget, dashboard } = useSelector(
    (state) => state
  );
  const handles = useRef({
    change: undefined,
    watchLayers: [],
  });

  const tableLayers = useRef({});
  const [_count, setCount] = useState(0);
  const count = useRef(0);

  const isShownInTable = (layer, visible) => {
    const { layers = [], optionalLayers = [] } =
      config.modules[activeModule] || {};
    const isVisible =
      layers.includes(layer.layerConfig?.alias) ||
      optionalLayers.includes(layer.layerConfig?.alias) ||
      visible;

    const editableLayer =
      dashboard.editableFeature?.sourceLayer ??
      dashboard.editableFeature?.layer;
    let isShown;

    if (editableLayer) {
      isShown = layer.id === editableLayer.id;
    } else {
      isShown = checkLayerForFeatureTable(layer, false);
    }
    tableLayers.current[layer.id] = isVisible && !!isShown;
    count.current += 1;
    setCount(count.current);
  };

  useEffect(() => {
    const featureTableLayer =
      Object.values(tableLayers.current).filter((isShown) => isShown).length >
      0;

    if (featureTableLayer && !layerExists) {
      setLayerExists(true);
    }

    if (!featureTableLayer && layerExists) {
      setLayerExists(false);
    }
  }, [_count]);

  useEffect(() => {
    tableLayers.current = {};
    setCount(0);
    setLayerExists(false);
  }, [activeModule]);

  useEffect(() => {
    view.map.layers.on("change", (event) => {
      if (event.added)
        event.added.forEach((layer) => {
          isShownInTable(layer, layer.visible);
          const visibleHandler = layer.watch("visible", (visible) => {
            isShownInTable(layer, visible);
          });
          handles.current.watchLayers.push(visibleHandler);
        });
    });

    view.map.layers.forEach((layer) => {
      isShownInTable(layer, layer.visible);
      const visibleHandler = layer.watch("visible", (visible) => {
        isShownInTable(layer, visible);
      });
      handles.current.watchLayers.push(visibleHandler);
    });

    return () => {
      handles.current.watchLayers.map((h) => h.remove());
    };
  }, [activeModule, config]);

  if (printWidget.open || !view || dashboard.editorDrawActive) return null;

  return layerExists ? (
    <FeatureTable layersIds={tableLayers.current} count={_count} view={view} />
  ) : null;
};

export default FeatureTableWrapper;
