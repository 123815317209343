import React from "react";
import { PDFAttachmentIcon } from "../../../../Icons";
import { StyledContainer, StyledItem } from "./Documents-styled";

const Documents = ({ attachments, t }) => {
  if (Array.isArray(attachments) && attachments.length === 0) {
    return null;
  }

  return (
    <StyledContainer>
      <span>{t("screen.popup.documents")}</span>
      {attachments.map((item) => (
        <StyledItem key={item.id} target="_blank" href={item.url}>
          <PDFAttachmentIcon width="100%" height="100%" />
          <span>{item.name}</span>
        </StyledItem>
      ))}
    </StyledContainer>
  );
};

export default Documents;
