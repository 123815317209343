import styled from "styled-components";

export const PlatformBody = styled.div`
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  padding-top: 4px;
`;

export const StyledSituationTitle = styled.span`
  font-size: 14px;
  padding: 0px 14px;
  margin-top: 5px;
`;

export const StyledTitle = styled.span`
  font-size: 18px;
  padding: 0px 14px;
  margin: auto;
`;

export const PlatformSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px 14px;
  overflow: auto;
`;

export const PlatformIconHolder = styled.div`
  width: 34px;
  height: 34px;
  background: ${({ color }) => color ?? "#C03A2B"};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  span {
    color: white;
    line-height: normal;
  }

  svg {
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
`;

export const PlatformSectionItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  border: 1px solid #ede9e9;
  border-radius: 100px;
  padding: 6px 8px;
  box-sizing: border-box;
  cursor: pointer;
  background: #ffffff;
`;

export const PlatformSectionItemBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  overflow: hidden;

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }

  .subtitle {
    font-size: 12px;
    color: #3f3f3f;
    line-height: normal;
  }
`;

export const PlatformTag = styled.div`
  margin: 0.125rem;
  border-radius: 0.9375rem;
  white-space: nowrap;
  font-size: 10px;
  padding: 0 6px;
  height: 20px;
  width: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  background-color: #d9ecf5;
  color: #005e8d;
  ${(props = {}) => Object.keys(props).map((key) => `${key}: ${props[key]};`)}
`;
