import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isWidgetDisplayed } from "../../../esri/widgets/expandUtils";
import useEditableLayers from "../../../hooks/useEditableLayers";
import useLayersChange from "../../../hooks/useLayersChange";
import { setCycleManagerOpen } from "../../../redux/action/CycleManager-action";
import {
  setEditableLayer,
  setEditorUpdateType,
} from "../../../redux/action/Dashboard-action";
import { view } from "../../../utils/API";
import { ConfigContext } from "../../../utils/ConfigContext";
import CustomSwiper, { CustomSwiperSlide } from "../../CustomSwiper";
import { BaselineIcon, InformationIcon } from "../../Icons";
import ProgressBar from "../../Report/new/ProgressBar/ProgressBar";
import SharedInformation from "../../SharedInformation/SharedInformation";
import { Tooltip } from "../../UI";
import CycleManagerProgress from "../CycleManager/CycleManagerProgress/CycleManagerProgress";
import {
  StyledTitle as EditorTitle,
  StyledEditorBreadcrumbs,
  StyledEditorManagerContainer,
  StyledEditorWrapper,
  StyledList,
  StyledListContainer,
  StyledListItem,
} from "./Editor-styled";
import EditorContextProvider, { UpdateType } from "./EditorContextProvider";
import EditorPlatform from "./EditorPlatform";
import { EditType } from "./EditorSwiper/EditorSwiper";
import NotificationMenu, { NotificationPage } from "./NotificationMenu";
import Platform from "./Platform/Platform";
import {
  PlatformIconHolder,
  PlatformSectionItem,
  PlatformSectionItemBody,
  PlatformTag,
  StyledTitle,
} from "./Platform/Platform-styled";

const SharedInformationIcon = ({ ...props }) => (
  <svg
    stroke="currentColor"
    fill="#ffffff"
    strokeWidth="0"
    viewBox="0 0 256 256"
    height="30px"
    width="30px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M104,152a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H96A8,8,0,0,1,104,152Zm136-36v60a16,16,0,0,1-16,16H136v32a8,8,0,0,1-16,0V192H32a16,16,0,0,1-16-16V116A60.07,60.07,0,0,1,76,56h76V24a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H168V56h12A60.07,60.07,0,0,1,240,116ZM120,176V116a44,44,0,0,0-88,0v60Zm104-60a44.05,44.05,0,0,0-44-44H168v72a8,8,0,0,1-16,0V72H116.75A59.86,59.86,0,0,1,136,116v60h88Z"></path>
  </svg>
);

const managerSelections = [
  // {
  //   id: 'create',
  //   step: 'layerEdit',
  //   editType: 'create',
  //   label : 'Add a new element',
  //   icon:  <svg stroke="currentColor" fill="#c0392b" strokeWidth="0" viewBox="0 0 512 512" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg"><path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path></svg>
  // },
  // {
  //   id: 'edit',
  //   step: 'layerEdit',
  //   editType: 'edit',
  //   label: 'Edit an existing element',
  //   icon: <svg fill="#fff" stroke="#c0392b" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path><path d="m15 5 4 4"></path></svg>
  // },
  // {
  //   id: "platform",
  //   step: "platform",
  //   editType: "",
  //   label: "Editor Platform",
  //   icon: (
  //     <svg
  //       fill="#fff"
  //       stroke="#c0392b"
  //       strokeWidth="2"
  //       viewBox="0 0 24 24"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       height="200px"
  //       width="200px"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
  //       <path d="m15 5 4 4"></path>
  //     </svg>
  //   ),
  // },
];

const reportManager = {
  id: "reportManager",
  step: "reportManager",
  editType: "",
  label: "Shared Information",
  icon: SharedInformationIcon,
  tag: 3,
};

const EditorManagerSelection = ({
  handleNext,
  situationalLayers,
  entryPointsLayers,
  baseLineLayers,
  activeModule,
}) => {
  const { open } = useSelector((state) => state.cycleManagerReducer);
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const { layerCount, visibleLayersIds } = useLayersChange(config);
  const [sharedInformation, setSharedInformation] = useState({
    show: false,
    updates: 0,
  });
  const dispatch = useDispatch();

  const timer = useRef(0);

  useEffect(() => {
    clearTimeout(timer.current);
    if (!isWidgetDisplayed(config, "ReportManager")) return;
    const reportManagerLayers = view.map.layers
      .filter(
        (l) => visibleLayersIds[l.id] && l.layerConfig?.isShownInReportManager
      )
      .toArray();

    if (reportManagerLayers.length === 0) {
      setSharedInformation((prev) => ({ ...prev, show: false, updates: 0 }));
      return;
    }

    setSharedInformation((prev) => ({ ...prev, show: true }));
    timer.current = setTimeout(async () => {
      try {
        const layerPromises = reportManagerLayers.map((l) => l.load());
        const results = await Promise.all(layerPromises);

        const queryFeatures = results.map((layer) => {
          const query = layer.createQuery();
          query.where =
            query.where.length > 0
              ? query.where + " AND currvalidationfield = 1"
              : "AND currvalidationfield = 1";
          return layer.queryFeatures(query);
        });

        let newUpdates = 0;
        const queryResults = await Promise.all(queryFeatures);
        queryResults.forEach((res) => {
          newUpdates += res.features.length;
        });

        setSharedInformation((prev) => ({ ...prev, updates: newUpdates }));
      } catch (error) {
        console.error("Error loading layers and querying features:", error);
      }
    }, 250);

    return () => {
      clearTimeout(timer.current);
    };
  }, [layerCount, config]);

  const allSelections = useMemo(() => {
    const result = [];
    if (sharedInformation.show) {
      result.push({
        ...reportManager,
        tag: sharedInformation.updates,
        label: t("screen.widget.ReportManager.editorButton"),
      });
    }

    return result;
  }, [sharedInformation, t]);

  const handleLayerSelection = useCallback(
    (layer) => {
      if (layer) {
        dispatch(setEditableLayer(layer));
      }

      handleNext("platform");
    },
    [handleNext]
  );

  const filteredBaselineLayers = useMemo(() => {
    const configLayers = config.layers || [];
    const { layers: moduleLayers = [], optionalLayers = [] } =
      config.modules[activeModule] || {};

    const layersToShow = baseLineLayers.filter(
      (l) =>
        moduleLayers.includes(l.layerConfig?.alias) ||
        optionalLayers.includes(l.layerConfig?.alias) ||
        configLayers.includes(l.layerConfig?.alias)
    );

    return layersToShow;
  }, [config, activeModule, baseLineLayers]);

  if (open) return null;

  if (!config.newEditorEnabled) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledEditorBreadcrumbs hasProgress>
          <ProgressBar color={config.opsColor} currentPosition={0} total={1} />
          <p>Editor Manager</p>
        </StyledEditorBreadcrumbs>
        <div
          style={{
            textAlign: "left",
            padding: "10px 14px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CycleManagerProgress config={config} />
          <EditorTitle>What do you want to do?</EditorTitle>
          <StyledList>
            {allSelections.map((item) => (
              <StyledListItem
                onClick={() => handleNext(item.step, item.editType)}
                key={item.id}
              >
                {Number.isFinite(item.tag) && (
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: -10,
                    }}
                  >
                    <div className="tag">{item.tag} NEW</div>
                  </div>
                )}

                {<item.icon fill="#c0392b" />}
                <span>{item.label}</span>
              </StyledListItem>
            ))}
          </StyledList>
        </div>
      </div>
    );
  } else {
    return (
      <StyledEditorManagerContainer
        style={{ padding: "0px 0px 10px", gap: 14 }}
      >
        {situationalLayers.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#eeeeee78",
              padding: "10px 0px",
              overflow: "hidden",
            }}
          >
            <div
              fontSize="18px"
              style={{
                marginBottom: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <StyledTitle fontSize="18px" style={{ marginBottom: 4 }}>
                {t("screen.widget.Editor.manager.situational.title")}
              </StyledTitle>
              <NotificationMenu
                onClick={() => handleNext("notificationMenuStep")}
              />
            </div>
            <CycleManagerProgress config={config} />
            <StyledListContainer>
              <Platform
                layers={situationalLayers}
                entryPointsLayers={entryPointsLayers}
                onLayerSelect={handleLayerSelection}
                updateType={UpdateType.situational}
              >
                {sharedInformation.show && (
                  <PlatformSectionItem
                    onClick={() =>
                      handleNext(reportManager.step, reportManager.editType)
                    }
                  >
                    <PlatformIconHolder color={config.opsColor}>
                      {<reportManager.icon fill="#fff" />}
                    </PlatformIconHolder>
                    <PlatformTag
                      style={{ textTransform: "uppercase" }}
                      position="absolute"
                      right="14px"
                      top="-12px"
                    >
                      {sharedInformation.updates}{" "}
                      {t("screen.widget.common.new")}
                    </PlatformTag>
                    <PlatformSectionItemBody>
                      <span className="title">
                        {t("screen.widget.ReportManager.editorButton")}
                      </span>
                      <span className="subtitle text-elipsis">
                        {t("screen.widget.ReportManager.editorDescription")}
                      </span>
                    </PlatformSectionItemBody>
                    <Tooltip
                      showOnHover
                      content={t(
                        "screen.widget.ReportManager.editorInformation",
                        { defaultValue: "" }
                      )}
                    >
                      <InformationIcon />
                    </Tooltip>
                  </PlatformSectionItem>
                )}
              </Platform>
            </StyledListContainer>
          </div>
        )}
        {filteredBaselineLayers.length > 0 && (
          <div
            style={{
              display: "flex",
              gap: 14,
              flexDirection: "column",
              background: "#eeeeee78",
              padding: "10px 0px",
            }}
          >
            <StyledTitle>
              {t("screen.widget.Editor.manager.baseline.title")}
            </StyledTitle>
            <div
              style={{
                padding: "0px 14px",
              }}
            >
              <PlatformSectionItem
                onClick={() => {
                  dispatch(setEditorUpdateType(UpdateType.baseline));
                  handleLayerSelection();
                }}
              >
                <PlatformIconHolder color="#34495e">
                  <BaselineIcon />
                </PlatformIconHolder>
                <PlatformSectionItemBody>
                  <span className="title">
                    {t("screen.widget.Editor.manager.baseline.workOnDataset")}
                  </span>
                </PlatformSectionItemBody>

                <Tooltip
                  showOnHover
                  content={t(
                    "screen.widget.Editor.manager.baseline.information",
                    { defaultValue: "" }
                  )}
                >
                  <InformationIcon />
                </Tooltip>
              </PlatformSectionItem>
            </div>
          </div>
        )}
      </StyledEditorManagerContainer>
    );
  }
};

const editorManager = {
  editorManager: 0,
  reportManager: 1,
  platform: 2,
  notificationMenuStep: 4,
};

const EditorManager = ({ expand }) => {
  const [step, setStep] = useState("editorManager");
  const [editType, setEditType] = useState(EditType.edit);
  const swiperRef = useRef();
  const activeModule = useSelector((state) => state.activeModule);
  const { editableFeature } = useSelector((state) => state.dashboard);
  const { config } = useContext(ConfigContext);
  const {
    editableLayers,
    situationalLayers,
    entryPointsLayers,
    baselineLayers,
  } = useEditableLayers(config, false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (step !== "editorManager") {
      handleSlideChange("editorManager");
    }
  }, [activeModule]);

  //for editor
  useEffect(() => {
    if (!config.newEditorEnabled) return;
    if (editableFeature && step !== "platform") {
      setTimeout(() => {
        const layer = editableFeature.sourceLayer
          ? editableFeature.sourceLayer
          : editableFeature.layer;
        handleSlideChange("platform", EditType.edit, 0);
        dispatch(setEditableLayer(layer));
      }, 500);
    }
  }, [editableFeature]);

  const handleSlideChange = useCallback((id, editType = "", speed = 300) => {
    swiperRef.current.swiper.slideTo(editorManager[id], speed);
    if (editType) {
      setEditType(editType);
    }
    setStep(id);
  }, []);

  useEffect(() => {
    if (!expand && !!step) {
      swiperRef.current?.swiper?.slideTo(editorManager["editorManager"]);
      setStep("editorManager");
    }
  }, [expand]);

  const handleGoBack = useCallback(
    (slide) => {
      handleSlideChange(typeof slide === "string" ? slide : "editorManager");
      dispatch(setCycleManagerOpen(false));
    },
    [handleSlideChange]
  );

  if (!expand) return;

  return (
    <StyledEditorWrapper>
      <CustomSwiper
        ref={swiperRef}
        init="true"
        initial-slide={0}
        prevent-interaction-on-transition
        no-swiping
        allow-touch-move={false}
        prevent-clicks={false}
        simulate-touch={false}
        prevent-clicks-propagation={false}
        resizeObserver={true}
        observer={true}
        virtual="true"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <CustomSwiperSlide key="selection">
          {expand && step === "editorManager" && (
            <EditorManagerSelection
              handleNext={handleSlideChange}
              situationalLayers={situationalLayers}
              entryPointsLayers={entryPointsLayers}
              baseLineLayers={baselineLayers}
              activeModule={activeModule}
            />
          )}
        </CustomSwiperSlide>
        <CustomSwiperSlide key="reportManager">
          {step === "reportManager" && (
            <SharedInformation
              expand={true}
              selectedTab="sharedInformation"
              handleGoBack={handleGoBack}
            />
          )}
        </CustomSwiperSlide>
        <CustomSwiperSlide key="platform">
          {step === "platform" && (
            <EditorContextProvider editType={editType}>
              <EditorPlatform
                setEditType={setEditType}
                handleGoBack={handleGoBack}
                editType={editType}
                editableLayers={editableLayers}
                baselineLayers={baselineLayers}
                entryPointsLayers={entryPointsLayers}
                situationalLayers={situationalLayers}
              />
            </EditorContextProvider>
          )}
        </CustomSwiperSlide>
        {config.allowPushNotifications && (
          <CustomSwiperSlide key="notificationMenuStep">
            {step === "notificationMenuStep" && (
              <NotificationPage goBack={handleGoBack} />
            )}
          </CustomSwiperSlide>
        )}
      </CustomSwiper>
    </StyledEditorWrapper>
  );
};

export default EditorManager;
