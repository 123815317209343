import { useCallback, useEffect, useRef, useState } from "react";
import { view } from "../utils/API";

const useLayersChange = (config, deps = []) => {
  const [count, setCount] = useState(0);
  const [countAdded, setCountAdded] = useState(0);
  const visibleLayers = useRef({});
  const visibleLayerConfigsIds = useRef({});
  const countRef = useRef(0);
  const addedRef = useRef(0);

  const processLayer = useCallback((layer, visible) => {
    if (!layer.layerConfig) return;
    countRef.current++;
    setCount(countRef.current);
    visibleLayers.current[layer.id] = visible;
    if (layer.layerConfig) {
      const id = layer.layerConfig.id;
      visibleLayerConfigsIds.current[id] = visible;
    }
  }, []);

  useEffect(() => {
    if (!view) return;
    const listeners = [];

    const layersChangeHandler = view.map.layers.on("change", (event) => {
      if (event.added) {
        event.added.forEach((layer) => {
          if (!layer.layerConfig) return;
          addedRef.current++;
          setCountAdded(addedRef.current);

          processLayer(layer, layer.visible);
          const visibleHandler = layer.watch("visible", (visible) => {
            processLayer(layer, visible);
          });
          listeners.push(visibleHandler);
        });
      }
    });
    listeners.push(layersChangeHandler);

    view.map.layers.forEach((layer) => {
      processLayer(layer, layer.visible);
      const visibleHandler = layer.watch("visible", (visible) => {
        processLayer(layer, visible);
      });
      listeners.push(visibleHandler);
    });

    return () => {
      listeners.forEach((h) => h.remove());
    };
  }, [config, view, ...deps]);

  return {
    layerCount: count,
    visibleLayersIds: visibleLayers.current,
    visibleLayerConfigsIds: visibleLayerConfigsIds.current,
    countAdded,
  };
};

export default useLayersChange;
