import styled from "styled-components";
import { StyledFiltersBackButton } from "../../MobileApp/MobileFilter/MobileFilter-styled";

export const StyledCheckListTableWrapper = styled.div`
  flex: 1;
  position: relative;

  .ag-root-wrapper {
    border: 1px solid #9e9e9e30;
  }

  .ag-cell-dirty.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: 1px solid rgba(0, 145, 234, 0.28);
  }

  .ag-cell-dirty {
    border: 1px solid rgba(0, 145, 234);
  }

  .ag-cell {
    text-align: center;
  }
`;

export const MonitoringBatchUpdateWrapper = styled.div`
  position: absolute;
  height: calc(100% - 50px);
  width: 320px;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 25px;
  right: 0;
  background: #fff;
  border-radius: 8px;
  padding: 10px 0px;
  box-sizing: border-box;
  pointer-events: auto;
  transform: translateX(calc(100% + 4px));
`;

export const CheckListSidebarWrapper = styled.div`
  margin-top: 45px;
  height: calc(100% - 55px);
  width: 250px;
  box-sizing: border-box;
  padding: 0px 0px 2px 0px;
  /* border-right: 1px solid #eee; */
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  //position: relative;
  //top: -8px;
  //margin: 10px;
  border-radius: 20px;
`;

export const ChecklistSidebarModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  padding: 4px 14px 4px 0px;
  flex: 1;
`;

export const ChecklistSidebarBox = styled.div`
  background: ${({ bg = "#a8b1b7" }) => bg};
  border-radius: 100px;
  padding: 4px 14px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
`;

export const ChecklistSidebarBoxContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  z-index: 1;
  /* position: relative; */

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ChecklistSidebarBoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ color = "#000000" }) => color};
  margin-right: auto;
  text-align: left;
  gap: 4px;

  p {
    z-index: 1;
    position: relative;
    transition: all 0.3s;
    font-size: 13px;
    margin: 0;
    color: ${({ color = "#000000" }) => color};
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
    line-height: normal;
    // ${({ elipsis }) =>
      elipsis
        ? `
        //     max-width: 140px;
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     width:max-content;
        // `
        : ""}

    :after {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      height: 1px;
      width: 100%;
      color: ${({ color = "#000000" }) => color};
      transform-origin: 0 0;
      transform: ${({ isCompleted }) =>
        isCompleted ? "scaleX(1)" : "scaleX(0)"};
      transition: all 0.3s ease;
    }
  }

  span {
    color: ${({ color = "#000000" }) => color};
    font-size: 10px;
    line-height: normal;
    z-index: 1;
  }
`;
export const ChecklistSidebarBoxIcon = styled.div`
  svg {
    //width: 14px;
    //height: 14px;
  }
`;

export const ChecklistSidebarOverallBox = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 0px 7px;
`;

export const ChecklistSidebarOverallHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 14px 14px 14px;

  p {
    font-size: 12px;
    margin: 0;
    text-align: left;
    color: #fff;
    //color: #FFFFFF;
    line-height: normal;
    z-index: 1;
  }

  span {
    font-size: 12px;
    z-index: 1;
    color: #fff;
    //color: #FFFFFF;
  }
`;

const lightBgColor = "#eeeeee";
const bgColor = "#909090";

export const ChecklistSidebarCheckboxWrapper = styled.div`
  pointer-events: none;
  label {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }

  label span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }

  label span:first-child {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #c6c6c6;
    transition: all 0.2s ease;
  }

  label span:first-child svg {
    width: 14px;
    height: 14px;
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 5px;
    fill: none;
    stroke: white;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }

  label span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: ${lightBgColor};
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    transition-delay: 0.2s;
  }

  label span:last-child:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    height: 1px;
    width: 100%;
    background: #b9b8c3;
    transform-origin: 0 0;
    transform: scaleX(0);
  }

  label:hover span:first-child {
    border-color: ${bgColor};
  }

  input:checked + label span:first-child {
    border-color: ${bgColor};
    background: ${bgColor};
    animation: check-15 0.6s ease;
  }

  input:checked + label span:first-child svg {
    stroke-dashoffset: 0;
  }

  input:checked + label span:first-child:before {
    transform: scale(2.2);
    opacity: 0;
    transition: all 0.6s ease;
  }

  input:checked + label span:last-child {
    color: #b9b8c3;
    transition: all 0.3s ease;
  }

  @keyframes check-15 {
    50% {
      transform: scale(1.2);
    }
  }
`;

export const StyledChecklistHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px 10px;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  text-align: left;
  gap: 4px;
  span {
    line-height: normal;
  }
`;

export const StyledButtonContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8px;
  min-width: max-content;
  margin-left: auto;
  /* opacity: ${({ showButtons }) => (showButtons ? 1 : 0)};
  pointer-events: ${({ showButtons }) => (showButtons ? "auto" : "none")}; */
`;

export const StyledConfirmButton = styled.div`
  display: flex;
  align-items: center;
  background: ${({ disabled, bg }) =>
    disabled ? "#a8b1b7" : bg ?? "var(--opsColor)"};
  border-radius: 100px;
  padding: 4px 18px;
  cursor: pointer;
  min-height: 36px;
  box-sizing: border-box;

  span {
    color: #fff;
    font-weight: 400;
  }
`;

export const StyledBackButton = styled(StyledFiltersBackButton)`
  margin-left: auto;
  min-width: 24px;
  height: 24px;
`;

export const StyledFooter = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  text-align: left;
  padding: 10px 6px;
  border-top: 1px solid rgba(67, 71, 85, 0.27);
  /* padding-right: 4px; */
  justify-content: center;
`;

export const StyledFooterTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledFooterText = styled.div`
  font-size: 12px;

  span:first-child {
    color: #4b4b4b;
  }
`;

export const StyledErrorOverlay = styled.div`
  position: absolute;
  left: 50%;
  top: calc(50% + 25px);
  z-index: 1;
  transform: translate(-50%, -50%);
`;
