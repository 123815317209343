import styled from "styled-components";
import { mutedColor } from "../../utils/Theme";

export const StyledLabel = styled.span`
  font-family: "Open Sans", sans-serif !important;
  width: auto;
  padding: 0 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5em;
  color: ${mutedColor};
  text-align: left;
  border-radius: 4px;
  pointer-events: none;
  transition: all 0.5s;
  position: absolute;
  top: 10px;
  left: 8px;
  background: #ffffff;
  margin: 0;
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  margin-bottom: ${({ noMargin }) => (noMargin ? "" : "16px")};
  width: inherit;
  ${({ resizable, maxHeight = "160px" }) =>
    resizable
      ? `
    min-height: 56px;
    height: 56px;
    max-height: ${maxHeight};
  `
      : ``}

  & textarea {
    border: ${({ borderColor, error }) =>
      `1px solid ${error ? "red" : `${borderColor}4D` ?? "#C0392B4D"}`};
  }

  & textarea::placeholder {
    opacity: 0;
    transition: all 0.7s;
    color: #cbcbcb;
  }

  & textarea:focus {
    border: ${({ borderColor, error, focusColor }) =>
      `1px solid ${error ? "red" : focusColor ?? borderColor ?? "#C0392B"}`};
    ::placeholder {
      opacity: 1;
    }
  }

  textarea:not(:placeholder-shown) + ${StyledLabel} {
    top: -9px;
    font-size: 10px;
  }

  & textarea:focus + ${StyledLabel} {
    top: -9px;
    font-size: 10px;
  }

  & textarea:disabled {
    cursor: not-allowed;
    background: #eee;
  }
`;
export const TextAreaStyled = styled.textarea`
  width: auto;
  height: ${({ height }) =>
    !isNaN(Number(height)) ? height + "px" : height ?? "160px"};
  resize: none;
  border-radius: 8px;
  line-height: normal;
  padding: 8px 14px;
  color: #25282b;
  font-size: 14px;
  outline: none;
  background: #ffffff;
  border: ${({ borderColor }) => `1px solid ${borderColor ?? "#C0392B"}4D`};
  /* border: ${({ borderColor, error }) =>
    `1px solid ${error ? "#ff0000" : borderColor ?? "#C0392B"}4D`}; */
  transition: border-color 0.7s;
  box-sizing: border-box;

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #535353;
  }
`;

export const CustomResizer = styled.div`
  width: 16px;
  height: 16px;
  background-image: url("assets/textarea-resize.svg"); /* Path to your custom icon */
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 4px;
  right: 6px;
  cursor: ns-resize;
`;

export const MaxLength = styled.p`
  margin: 0;
  position: absolute;
  right: 20px;
  bottom: -8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5em;
  color: ${mutedColor};
  background: #ffffff;
  border-radius: 4px;
  pointer-events: none;
  padding: 2px 2px;
`;

export const DescriptionWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  cursor: pointer;
`;
