import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IoMdCheckmark } from "../../../../Icons";
import SituationalDateStatusIcon from "../../../Editor/SituationalDateStatusIcon";

export const Outdated = (props) => (
  <svg
    stroke="#FF6900"
    fill="#FF6900"
    strokeWidth={0}
    viewBox="0 0 192 512"
    height="14px"
    width="14px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z" />
  </svg>
);

export const CYCLE_UPDATE_TYPES = Object.freeze({
  MISSING: "up-to-date-but-missing",
  OUTDATED: "outdated",
  UP_TO_DATE: "up-to-date",
  STATUS_UNKNOWN: "up-to-date-but-status-unknown",
});

const UpdateCellRenderer = ({ params, layer }) => {
  return <SituationalDateStatusIcon feature={params.data.feature} />;
};

export default UpdateCellRenderer;
