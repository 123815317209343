import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { currAsOfDateFieldName } from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { getFieldsByPattern } from "../../../../utils/helper";
import { getLayerSymbology } from "../../../../utils/symbologies";
import UploadMediaFiles from "../../../Editor/UploadMediaFiles/UploadMediaFiles";
import { EditorIcon } from "../../../Icons";
import { Tooltip } from "../../../UI";
import CoordinatesSelection from "../CoordinatesSelection/CoordinatesSelection";
import {
  EditorContext,
  UPDATE_STATUS_TYPE,
  UpdateType,
} from "../EditorContextProvider";
import { getFeatureNameField } from "../helpers";
import {
  AccordionBody,
  AccordionButton,
  AccordionWrapper,
} from "./Accordion/Accordion";
import AttributeFields from "./AttributeFields/AttributeFields";
import { getFields } from "./EditorDefaultFields";

const EditorSituationalFields = ({
  isCheckList = false,
  updateType,
  editType,
}) => {
  const [selectedId, setSelectedId] = useState("");
  const {
    highlightFeature,
    activeColor,
    batchUpdateFeatures,
    situationalUpdateType,
    setSituationalUpdateType,
    editableLayer,
    requiredFields,
    setUpdateType,
    setEditingInitialUpdateType,
    attachments,
    previews,
  } = useContext(EditorContext);
  const { reset } = useFormContext();

  const { t } = useTranslation("common");
  const { config } = useContext(ConfigContext);

  useEffect(() => {
    return () => {
      setSituationalUpdateType(UPDATE_STATUS_TYPE.UPDATE);
    };
  }, []);

  const symbology = useMemo(() => {
    return getLayerSymbology(editableLayer, config) || {};
  }, [config, editableLayer]);

  const situationalFields = useMemo(() => {
    if (!editableLayer && editableLayer?.layerConfig) return [];
    const nameField = getFeatureNameField(editableLayer);

    const lc = editableLayer.layerConfig;
    const batchUpdateFields = lc.batchUpdateFields || [];

    let fields = getFieldsByPattern(
      editableLayer,
      UpdateType.situational === updateType
        ? lc.situationalFields
        : lc.baselineFields
    );

    //filtering fields for batch editor
    if ((!highlightFeature && batchUpdateFeatures.length > 0) || isCheckList) {
      const { colorMap } = symbology;
      const symbologyFields = [];

      if (colorMap?.fields) {
        symbologyFields.push(...colorMap.fields);
      } else if (colorMap?.field) {
        symbologyFields.push(colorMap.field);
      }

      fields = fields.filter((field) =>
        situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT
          ? field.name === currAsOfDateFieldName
          : batchUpdateFields.includes(field.name) ||
            symbologyFields.includes(field.name)
      );

      // if (fields.length === 0) {
      //   fields = getFieldsByPattern(editableLayer, UpdateType.situational === updateType ? lc.situationalFields : lc.baselineFields);
      // }
    }

    if (nameField) {
      fields = fields.filter((field) => field.name !== nameField);
    }

    return fields;
  }, [
    editableLayer,
    situationalUpdateType,
    highlightFeature,
    batchUpdateFeatures,
    symbology,
    isCheckList,
    updateType,
  ]);

  const baseLineFields = useMemo(() => {
    const lc = editableLayer.layerConfig;
    if (!lc) return [];
    if (!Array.isArray(lc.baselineFields)) return [];
    const fields = getFieldsByPattern(editableLayer, lc.baselineFields);

    const baseLineFields = getFields({
      fields,
      editableLayer,
    });

    return baseLineFields;
  }, [editableLayer]);

  const situationalAccordion = useMemo(() => {
    if (!editableLayer) return [];
    const symbology = getLayerSymbology(editableLayer, config);
    const acc = [];

    if (situationalFields.length > 0) {
      acc.push({
        id: updateType === UpdateType.baseline ? "baseline" : "situational",
        show: true,
        label:
          updateType === UpdateType.baseline
            ? t("screen.widget.Editor.baselineFields.label")
            : t("screen.widget.Editor.situationalFields.label"),
        information:
          updateType === UpdateType.baseline
            ? t("screen.widget.Editor.baselineFields.information")
            : t("screen.widget.Editor.situationalFields.information"),
        component: (
          <AttributeFields
            highlightFeature={highlightFeature}
            fields={situationalFields}
            config={config}
            t={t}
            symbology={symbology}
            editableLayer={editableLayer}
            requiredFields={requiredFields}
          />
        ),
      });
    }

    // if (batchUpdateFeatures.length === 0) {
    //   if (editableLayer.capabilities.operations.supportsQueryAttachments) {
    //     acc.push({
    //       id: "upload",
    //       label: t("screen.widget.Editor.mediaFiles.label"),
    //       information: t("screen.widget.Editor.mediaFiles.information"),
    //       component: (
    //         <UploadMediaFiles
    //           layer={editableLayer}
    //           feature={highlightFeature}
    //           t={t}
    //           attachments={attachments}
    //           setAttachments={setAttachments}
    //           config={config}
    //           previews={previews}
    //           setPreviews={setPreviews}
    //           color={activeColor}
    //         />
    //       ),
    //     });
    //   }

    //   acc.push({
    //     id: "coordinates",
    //     label:
    //       editableLayer.geometryType === "point"
    //         ? t("screen.widget.Editor.location.label")
    //         : "",
    //     information: t("screen.widget.Editor.location.information"),
    //     show: editableLayer.geometryType === "point",
    //     component:
    //       editableLayer.layerConfig.titleLabel !== "roadsSituational" ? (
    //         <CoordinatesSelection
    //           config={config}
    //           editableLayer={editableLayer}
    //           editType={editType}
    //           clickActive={false}
    //           disabled={true}
    //           showLocationInput={true}
    //         />
    //       ) : null,
    //   });

    //   if (baseLineFields.length > 0) {
    //     acc.push({
    //       id: "baseline",
    //       show: true,
    //       label: t("screen.widget.Editor.baselineFields.label"),
    //       information: t("screen.widget.Editor.baselineFields.information"),
    //       component: (
    //         <AttributeFields
    //           highlightFeature={highlightFeature}
    //           fields={baseLineFields}
    //           config={config}
    //           t={t}
    //           symbology={symbology}
    //           editableLayer={editableLayer}
    //           requiredFields={requiredFields}
    //           disabled
    //           showFilled
    //         />
    //       ),
    //     });
    //   }
    // }

    return acc;
  }, [
    situationalFields,
    baseLineFields,
    attachments,
    previews,
    config,
    highlightFeature,
    activeColor,
    requiredFields,
    editableLayer,
    batchUpdateFeatures,
    updateType,
  ]);

  const otherOptionsAccordion = useMemo(() => {
    if (!editableLayer) return [];

    const acc = [];
    if (batchUpdateFeatures.length === 0) {
      if (editableLayer.capabilities.operations.supportsQueryAttachments) {
        acc.push({
          id: "upload",
          label: t("screen.widget.Editor.mediaFiles.label"),
          information: t("screen.widget.Editor.mediaFiles.information"),
          component: (
            <UploadMediaFiles
              layer={editableLayer}
              feature={highlightFeature}
              t={t}
              config={config}
              color={activeColor}
            />
          ),
        });
      }

      acc.push({
        id: "coordinates",
        label:
          editableLayer.geometryType === "point"
            ? t("screen.widget.Editor.location.label")
            : "",
        information: t("screen.widget.Editor.location.information"),
        show: editableLayer.geometryType === "point",
        component:
          editableLayer.layerConfig.titleLabel !== "roadsSituational" ? (
            <CoordinatesSelection
              config={config}
              editableLayer={editableLayer}
              editType={editType}
              clickActive={false}
              disabled={true}
              showLocationInput={true}
            />
          ) : null,
      });

      if (baseLineFields.length > 0) {
        acc.push({
          id: "baseline",
          show: true,
          label: t("screen.widget.Editor.baselineFields.label"),
          information: t("screen.widget.Editor.baselineFields.information"),
          component: (
            <AttributeFields
              fields={baseLineFields}
              config={config}
              t={t}
              symbology={symbology}
              editableLayer={editableLayer}
              requiredFields={requiredFields}
              disabled
              showFilled
            />
          ),
        });
      }
    }

    return acc;
  }, [
    baseLineFields,
    attachments,
    previews,
    config,
    highlightFeature,
    activeColor,
    requiredFields,
    editableLayer,
    batchUpdateFeatures,
  ]);

  const handleStatusChange = useCallback((status) => {
    setSituationalUpdateType(status);
    reset(
      {},
      {
        keepValues: true,
      }
    );
  }, []);

  const showStatusChange = useMemo(() => {
    return (
      situationalFields.some((field) => field.name === currAsOfDateFieldName) &&
      !highlightFeature &&
      batchUpdateFeatures.length > 0
    );
  }, [situationalFields, highlightFeature, batchUpdateFeatures]);

  const handleAccordionSelection = useCallback(
    (id) => {
      if (selectedId === id) {
        setSelectedId("");
      } else {
        setSelectedId(id);
      }
    },
    [selectedId]
  );

  return (
    <div
      style={{
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* {showStatusChange && (
        <div
          style={{
            width: "fit-content",
            padding: "0px 14px 4px",
            margin: "0px auto",
          }}
        >
          {!isCheckList && (
            <StyledSwitch color={activeColor} dir="hor">
              <div
                onClick={() => {
                  handleStatusChange(UPDATE_STATUS_TYPE.UPDATE);
                }}
                className={
                  situationalUpdateType === UPDATE_STATUS_TYPE.UPDATE
                    ? "selected"
                    : ""
                }
              >
                <span>Update new status</span>
              </div>
              <div
                onClick={() => {
                  handleStatusChange(UPDATE_STATUS_TYPE.CURRENT);
                }}
                className={
                  situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT
                    ? "selected"
                    : ""
                }
              >
                <span>Confirm current status</span>
              </div>
            </StyledSwitch>
          )}
        </div>
      )} */}
      {situationalAccordion.map((item, index) => {
        const isSelected = true; //item.id === selectedId;
        return (
          <AccordionWrapper
            key={item.id}
            show={item.show}
            selected={isSelected}
          >
            <AccordionButton
              selected={isSelected}
              label={item.label}
              config={config}
              information={item.information}
              color={activeColor}
              // onSelect={() => handleAccordionSelection(item.id)}
              style={{
                zIndex: 2,
              }}
            >
              {item.id === "baseline" && batchUpdateFeatures.length === 0 && (
                <Tooltip
                  onClick={() => {
                    setUpdateType(UpdateType.baseline);
                    setEditingInitialUpdateType(UpdateType.situational);
                  }}
                  showOnHover
                  content={t(
                    "screen.widget.Editor.manager.baseline.workOnDataset"
                  )}
                >
                  <EditorIcon width="12" height="12" fill={config.opsColor} />
                </Tooltip>
              )}
            </AccordionButton>
            <AccordionBody
              hasPadding={item.id !== "coordinates"}
              selected={isSelected}
            >
              {item.component}
              <div>
                {otherOptionsAccordion.map((item, index) => {
                  const isSelected = item.id === selectedId;
                  return (
                    <AccordionWrapper
                      key={item.id}
                      show={item.show}
                      selected={isSelected}
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <AccordionButton
                        selected={isSelected}
                        label={item.label}
                        config={config}
                        information={item.information}
                        color={activeColor}
                        onSelect={
                          otherOptionsAccordion.length > 1
                            ? () => handleAccordionSelection(item.id)
                            : undefined
                        }
                      >
                        {item.id === "baseline" && (
                          <Tooltip
                            onClick={() => {
                              setUpdateType(UpdateType.baseline);
                              setEditingInitialUpdateType(
                                UpdateType.situational
                              );
                            }}
                            showOnHover
                            content={t(
                              "screen.widget.Editor.manager.baseline.workOnDataset"
                            )}
                          >
                            <EditorIcon
                              width="12"
                              height="12"
                              fill={config.opsColor}
                            />
                          </Tooltip>
                        )}
                      </AccordionButton>
                      <AccordionBody
                        hasPadding={item.id !== "coordinates"}
                        selected={isSelected}
                      >
                        {item.component}
                      </AccordionBody>
                    </AccordionWrapper>
                  );
                })}
              </div>
            </AccordionBody>
          </AccordionWrapper>
        );
      })}

      {/* {otherOptionsAccordion.map((item, index) => {
        const isSelected = item.id === selectedId;
        return (
          <AccordionWrapper
            key={item.id}
            show={item.show}
            selected={isSelected}
          >
            <AccordionButton
              selected={isSelected}
              label={item.label}
              config={config}
              information={item.information}
              color={activeColor}
              onSelect={
                otherOptionsAccordion.length > 1
                  ? () => handleAccordionSelection(item.id)
                  : undefined
              }
            >
              {item.id === "baseline" && (
                <Tooltip
                  onClick={() => {
                    setUpdateType(UpdateType.baseline);
                    setEditingInitialUpdateType(UpdateType.situational);
                  }}
                  showOnHover
                  content={t(
                    "screen.widget.Editor.manager.baseline.workOnDataset"
                  )}
                >
                  <EditorIcon width="12" height="12" fill={config.opsColor} />
                </Tooltip>
              )}
            </AccordionButton>
            <AccordionBody
              hasPadding={item.id !== "coordinates"}
              selected={isSelected}
            >
              {item.component}
            </AccordionBody>
          </AccordionWrapper>
        );
      })} */}
    </div>
  );
};

export default EditorSituationalFields;
