import React from "react";
import { StyledLayerCircleContainer } from "./LayerCircle-styled";
import LayerIcon from "../../Icons/LayerIcon";
import Tooltip from "../Tooltip";

const LayerCircle = ({ layer, t, config, opsColor, selected, onClick }) => {
  return (
    <Tooltip content={layer.getLayerTitle(t)} showOnHover onClick={onClick}>
      <StyledLayerCircleContainer selected={selected}>
        <LayerIcon
          layer={layer}
          config={config}
          t={t}
          opsColor={opsColor}
          width={28}
          height={28}
          textColor="#000"
          fill="#000"
        />
      </StyledLayerCircleContainer>
    </Tooltip>
  );
};

export default LayerCircle;
