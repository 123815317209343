import { loadModules } from "esri-loader";
import { EditType } from "../../components/Dashboard/Editor/EditorSwiper/EditorSwiper";
import { getFieldDefaultValue } from "../../components/Dashboard/Editor/helpers/helpers";

const numericFields = [
  "small-integer", // Short - 16-bit integers (-32,768 to 32,767)
  "integer", // Long - 32-bit integers (-2.147B to 2.147B)
  "big-integer", // 64-bit integers (-9.223Q to 9.223Q)
  "single", // Float - 32-bit floating point
  "double", // Double - 64-bit floating point
  "float",
];

export const trimStrings = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const trimmedObj = {};
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
};

const convertNumber = (value, f) => {
  let newValue = value;
  try {
    if (f.type === "big-integer") {
      newValue = BigInt(value);
    } else {
      newValue = Number(value);
    }
  } catch (err) {
    console.log(err);
  }

  return newValue;
};

// 1. Handle field processing and attribute updates
export const processFieldsAndAttributes = ({
  data,
  editableLayer,
  editType,
  config,
}) => {
  const trimmedData = trimStrings(data);

  const atts = { ...trimmedData };

  const fieldTypes = {};

  // return;
  editableLayer.fields.forEach((f) => {
    const defaultValue = getFieldDefaultValue(f, config);

    fieldTypes[f.name] = f.type;
    //for the roads removing opid field but for others keeping it
    if (
      numericFields.includes(f.type) &&
      atts[f.name] != null &&
      atts[f.name] !== ""
    ) {
      atts[f.name] = convertNumber(atts[f.name], f);
    }

    if (
      f.name === "opid" &&
      (editableLayer.layerConfig.extends === "roads_baseline" ||
        editableLayer.layerConfig.alias === "roads_baseline")
    )
      return;

    //when creating wee need to fill empty values like iso3, showpublic, is_deleted
    if (
      editType === EditType.create &&
      (atts[f.name] == null || atts[f.name] === "")
    ) {
      atts[f.name] = defaultValue;
    } else if (
      editType === EditType.edit &&
      f.name in atts &&
      (atts[f.name] == null || atts[f.name] === "")
    ) {
      atts[f.name] = defaultValue;
    }
  });

  return { atts, fieldTypes };
};

// 2. Update feature attributes
export const updateFeatureAttributes = (
  atts,
  fieldTypes,
  { highlightFeature, newBatchUpdateFeatures, editableLayer, editType }
) => {
  Object.keys(atts).forEach((fieldName) => {
    let val = atts[fieldName];

    if (fieldTypes[fieldName] === "date" && val) {
      const today = new Date();
      val = today.getTime();
    }

    if (val === "") val = null;
    const batchUpdateFields =
      editableLayer.layerConfig?.batchUpdateFields || [];

    if (editType === EditType.edit) {
      if (highlightFeature) {
        highlightFeature.attributes[fieldName] = val;
      } else if (newBatchUpdateFeatures.length > 0) {
        newBatchUpdateFeatures.forEach((feature) => {
          if (val != null) {
            feature.attributes[fieldName] = val;
          } else if (batchUpdateFields.includes(fieldName)) {
            feature.attributes[fieldName] = val;
          }
        });
      }
    } else {
      atts[fieldName] = val;
    }
  });

  return atts;
};

// 3. Handle geometry updates
export const handleGeometryUpdates = async (
  updateFeatures,
  addedFeatures,
  editType
) => {
  const [webMercatorUtils] = await loadModules([
    "esri/geometry/support/webMercatorUtils",
  ]);
  if (updateFeatures.length > 0) {
    updateFeatures.forEach((feature) => {
      if (
        feature.geometry &&
        !feature.geometry.spatialReference?.isGeographic
      ) {
        feature.geometry = webMercatorUtils.webMercatorToGeographic(
          feature.geometry
        );
      }
    });
  }

  if (editType === EditType.create) {
    addedFeatures.forEach((feature) => {
      if (
        feature?.geometry &&
        !feature.geometry.spatialReference?.isGeographic
      ) {
        feature.geometry = webMercatorUtils.webMercatorToGeographic(
          feature.geometry
        );
      }
    });
  }

  return { updateFeatures, addedFeatures };
};

// 4. Handle attachments
export const handleAttachments = async ({
  highlightFeature,
  addedFeatures,
  setAttachments,
  editType,
  attachments,
  editableLayer,
}) => {
  if (attachments.files.length > 0) {
    const promises = [];
    const deleteIds = [];

    attachments.files.forEach((file) => {
      if (file.attachmentId) {
        if (file.isDeleted) {
          // deleteIds.push(file.attachmentId);
          deleteIds.push(
            editableLayer.deleteAttachments(highlightFeature, [
              file.attachmentId,
            ])
          );
        }
      } else {
        const formData = new FormData();
        formData.set("attachment", file);
        formData.append("f", "json");
        const promises = [];

        if (EditType.create === editType) {
          addedFeatures.forEach((feature) => {
            editableLayer.addAttachment(feature, formData);
          });
        } else {
          promises.push(
            editableLayer.addAttachment(highlightFeature, formData)
          );
        }
      }
    });
    await Promise.all(promises);

    if (deleteIds.length > 0) {
      await Promise.all(deleteIds);
    }
    setAttachments({
      files: attachments.files.filter((f) => !f.isDeleted),
      previews: {},
    });
  }
};

export const queryUpdatedFeatures = async ({
  editableLayer,
  featuresToUpdate,
}) => {
  const updatedFeatures = [];
  if (featuresToUpdate.length > 0) {
    const query = editableLayer.createQuery();
    const featureIds = featuresToUpdate.map(
      (f) => f.attributes[editableLayer.objectIdField]
    );

    const idsString = featureIds.join(", ");
    query.where = `${editableLayer.objectIdField} IN (${idsString})`;
    query.returnGeometry = true;
    query.outFields = ["*"];

    const res = await editableLayer.queryFeatures(query);

    if (Array.isArray(res?.features) && res.features.length > 0) {
      updatedFeatures.push(...res.features);
    }
  }
  return updatedFeatures;
};

// 5. Update features list
export const updateFeaturesList = ({
  editableFeatureList,
  featureIdsToRemove,
  updatedFeatures,
}) => {
  const updatedFeaturesMap = new Map(
    updatedFeatures.map((f) => [f.getObjectId(), f])
  );
  // Update existing features and remove them from the map
  let newFeatures = editableFeatureList.map((feature) => {
    const objectId = feature.getObjectId();
    if (updatedFeaturesMap.has(objectId)) {
      const updatedFeature = updatedFeaturesMap.get(objectId);
      updatedFeaturesMap.delete(objectId);
      return updatedFeature;
    }
    return feature;
  });

  // Add new features to the list
  updatedFeaturesMap.forEach((feature) => {
    newFeatures.push(feature);
  });

  newFeatures = newFeatures.filter(
    (feature) => !featureIdsToRemove.has(feature.getObjectId())
  );

  return newFeatures;
};
