import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useSearchLayers, useSearchWidget } from "../../hooks";
import Input from "../Report/new/Input/Input";
import CoordinatesPanel from "./CoordinatesPanel";
import SearchBox from "./SearchBox";
import { StyledIconBox } from "./SearchWidget-styled";
import Suggestions from "./Suggestions";

const SearchWidget = ({
  config,
  t,
  view,
  onBackClick,
  isMobileApp,
  rotate,
  isExpanded,
  disabled = false,
  isSearchWidget = true,
  searchValue = "",
  color,
  onInputChange,
  onResultSelect,
}) => {
  const activeModule = useSelector((state) => state.activeModule);

  // Core search functionality
  const {
    search,
    setValue,
    value,
    loading,
    suggestions,
    activeCoordinates,
    selectedResultKey,
    showCoordinates,
    showDropdown,
    showNoResults,
    showSuggestions,
    suggestionsLength,
    resetSearch,
    handleInputChange,
    handleFocus,
    handleSuggestionClick,
    handleCoordinates,
    handleKeyPress,
    locateCoordinates,
    locateButtonClick,
    suggestionRef,
    expandRef,
  } = useSearchWidget(
    view,
    config,
    t,
    searchValue,
    isSearchWidget,
    onResultSelect,
    onInputChange
  );

  useEffect(() => {
    if (searchValue) {
      setValue(searchValue);
    }
  }, [searchValue]);

  // Handle search layers
  useSearchLayers(search, view, t, config, isSearchWidget ? [] : ["locations"]);

  useEffect(() => {
    if (!isExpanded) {
      resetSearch();
    }
    expandRef.current = isExpanded;
  }, [isExpanded]);

  useEffect(() => {
    if (!search) return;
    resetSearch();
    if (onBackClick) {
      onBackClick();
    }
  }, [activeModule]);

  const handleBackClick = useCallback(() => {
    if (onBackClick) {
      onBackClick();
    }

    resetSearch();
  }, [resetSearch, onBackClick]);

  const locateTimer = useRef(null);
  const onSearchInputChange = useCallback(
    async (e) => {
      clearTimeout(locateTimer.current);
      const res = await handleInputChange(e);

      // if (onInputChange) {
      //   onInputChange(res);
      // }

      if (res.type === "coordinates") {
        locateTimer.current = setTimeout(() => {
          locateCoordinates(res.result);
        }, 500);
      }
    },
    [locateCoordinates, onInputChange, handleInputChange]
  );

  if (!isSearchWidget) {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <Input
          noMargin
          onChange={onSearchInputChange}
          style={{ width: "100%" }}
          label={t("screen.widget.Search.placeholder", "Search")}
          value={value}
          onFocus={handleFocus}
          borderColor={color}
          disabled={disabled}
        >
          {!disabled && (
            <StyledIconBox>
              <div
                onClick={() => {
                  handleInputChange({ target: { value: "" } });
                }}
                style={{
                  opacity: value ? 1 : 0,
                  pointerEvents: value ? "auto" : "none",
                  display: "flex",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="#939393"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  width="18px"
                  height="18px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" />
                </svg>
              </div>
            </StyledIconBox>
          )}
        </Input>
        <Suggestions
          ref={suggestionRef}
          showSuggestions={showSuggestions}
          loading={loading}
          showNoResults={showNoResults}
          suggestions={suggestions}
          showCoordinates={false}
          showDropdown={showDropdown}
          selectedResultKey={selectedResultKey}
          suggestionsLength={suggestionsLength}
          handleSuggestionClick={handleSuggestionClick}
          isSearchWidget={isSearchWidget}
          activeCoordinates={activeCoordinates}
          handleCoordinates={handleCoordinates}
          handleKeyPress={handleKeyPress}
          locateCoordinates={locateButtonClick}
          t={t}
          config={config}
        />
      </div>
    );
  } else {
    return (
      <SearchBox
        value={value}
        onChange={onSearchInputChange}
        placeholder={t("screen.widget.Search.placeholder", "Search")}
        onFocus={handleFocus}
        onBackClick={handleBackClick}
        isMobileApp={isMobileApp}
        rotate={rotate}
        disabled={!search}
        onKeyPress={handleKeyPress}
      >
        <Suggestions
          ref={suggestionRef}
          showSuggestions={showSuggestions}
          loading={loading}
          showNoResults={showNoResults}
          suggestions={suggestions}
          showCoordinates={showCoordinates}
          showDropdown={showDropdown}
          selectedResultKey={selectedResultKey}
          suggestionsLength={suggestionsLength}
          handleSuggestionClick={handleSuggestionClick}
          isSearchWidget={isSearchWidget}
          activeCoordinates={activeCoordinates}
          handleCoordinates={handleCoordinates}
          handleKeyPress={handleKeyPress}
          locateCoordinates={locateButtonClick}
          t={t}
          config={config}
        >
          <CoordinatesPanel
            handleCoordinates={handleCoordinates}
            activeCoordinates={activeCoordinates}
            handleKeyPress={handleKeyPress}
            locateCoordinates={locateButtonClick}
            t={t}
          />
        </Suggestions>
      </SearchBox>
    );
  }
};

export default SearchWidget;
