import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAnimateFeatureTable } from "../../../../redux/action/Dashboard-action";
import { graphicsLayer, view } from "../../../../utils/API";
import { HiCursorClick } from "../../../Icons";
import { Checkbox } from "../../../UI";
import { StyledTitle } from "../Editor-styled";
import { EditorContext } from "../EditorContextProvider";
import { EditType, pointSymbol } from "../EditorSwiper/EditorSwiper";
import { addLayerEffect } from "../helpers";
import PointCoordinates from "./PointCoordinates";
import RoadsCoordinates from "./RoadsCoordinates";

const CoordinatesSelection = ({
  editableLayer,
  config,
  clickActive,
  editType,
  handleNext,
  disabled = false,
  showLocationInput = false,
}) => {
  const {
    addedPoints,
    setHighlightSymbol,
    setHighlightFeature,
    highlightHandle,
    sketchGraphicsLayer,
    highlightSymbol,
    activeColor,
  } = useContext(EditorContext);
  const { t } = useTranslation("common");

  const clickListener = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (clickActive) {
      // graphicsLayer.removeAll();
      // setHighlightFeature(null);
      // setHighlightSymbol(null);
      // highlightHandle?.remove();

      if (editableLayer.layerConfig.titleLabel === "roadsSituational") {
        addLayerEffect([editableLayer]);
      }
    }
  }, [editType]);

  useEffect(() => {
    if (clickActive) {
      // editableLayer.effect = undefined;
    }

    if (editType === EditType.edit && clickActive) {
      dispatch(setAnimateFeatureTable(true));
    }

    return () => {
      dispatch(setAnimateFeatureTable(false));
    };
  }, [editType]);

  //when user is editing feature to detect and assign the clicked feature
  useEffect(() => {
    //temp
    if (
      !clickActive ||
      editType !== EditType.edit ||
      (editableLayer.layerConfig.titleLabel === "roadsSituational" &&
        EditType.create === editType)
    )
      return;
    clickListener.current?.remove();
    clickListener.current = view.on("click", (event) => {
      view.hitTest(event).then((res) => {
        const sketchGraphic = res?.results
          .filter(
            (res) =>
              res.graphic.layer === sketchGraphicsLayer ||
              res.graphic.sourceLayer === sketchGraphicsLayer
          )
          .at(0);
        if (sketchGraphic) return;

        const firstRes = res?.results
          .filter(
            (res) =>
              res.graphic.layer === editableLayer ||
              res.graphic.sourceLayer === editableLayer
          )
          .at(0);
        if (!firstRes) return;

        const clickedLayer = firstRes.layer;
        const graphic = firstRes.graphic;

        if (clickedLayer?.layerConfig) {
          handleClick(clickedLayer, graphic, handleNext);
        } else {
          handleClick(editableLayer, graphic, handleNext);
        }
      });
      event.stopPropagation();
    });

    return () => {
      clickListener.current?.remove();
    };
  }, [
    addedPoints,
    clickActive,
    editableLayer,
    highlightHandle,
    highlightSymbol,
    sketchGraphicsLayer,
    editType,
  ]);

  const handleClick = (clickedLayer, feature, callback) => {
    highlightHandle?.remove();
    view
      .whenLayerView(clickedLayer)
      .then((layerView) => {
        const query = clickedLayer.createQuery();
        const objectIdField = clickedLayer.objectIdField;
        query.objectIds = [feature.attributes[objectIdField]];
        query.outFields = ["*"];
        let editingGraphic = feature;
        let editingSymbol;
        // highlightHandle?.remove();
        if (
          editableLayer.layerConfig.titleLabel !== "roadsSituational" &&
          editableLayer.layerConfig.titleLabel !== "roads"
        ) {
          // setHighlightHandle(layerView.highlight(editingGraphic))

          if (editableLayer.layerConfig.titleLabel === "conops") {
            editingSymbol = pointSymbol;
            setHighlightSymbol(pointSymbol);
          } else {
            editingGraphic.layer?.renderer
              ?.getSymbolAsync(editingGraphic)
              .then((symbol) => {
                editingSymbol = symbol;
                setHighlightSymbol(symbol);
              });
          }
        }

        clickedLayer
          .queryFeatures(query)
          .then((result) => {
            const graphic = result.features.find(
              (feat) => feat[objectIdField] === editingGraphic[objectIdField]
            );

            if (!!graphic) {
              editingGraphic = graphic;
            }

            const nGraphic = editingGraphic?.clone();
            nGraphic.symbol = editingGraphic ? editingSymbol : pointSymbol;
            setHighlightFeature(nGraphic);
            // editableLayer.effect = LAYER_EFFECT;

            graphicsLayer.removeAll();
            graphicsLayer.add(nGraphic);

            if (callback) {
              callback();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isPoint = useMemo(() => {
    const geomType = editableLayer.geometryType;
    return geomType === "point";
  }, [editableLayer]);

  return (
    <>
      {isPoint ? (
        <div style={{ paddingTop: clickActive ? 8 : 0 }}>
          <PointCoordinates
            config={config}
            clickActive={clickActive}
            editableLayer={editableLayer}
            editType={editType}
            handleNext={handleNext}
            handleSelectFeature={handleClick}
            disabled={disabled}
            showLocationInput={showLocationInput}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100%",
            boxSizing: "border-box",
            padding: clickActive ? "8px" : "0px",
            alignItems: clickActive ? "center" : "flex-start",
            justifyContent: clickActive ? "center" : "flex-start",
          }}
        >
          {clickActive && !isPoint && (
            <StyledTitle style={{ textAlign: "center" }}>
              <>
                <span
                  style={{
                    minWidth: 18,
                    minHeight: 18,
                    marginRight: 4,
                    position: "relative",
                    top: 2,
                  }}
                >
                  <HiCursorClick width="18" height="18" />
                </span>
                {t("screen.widget.Editor.manager.polylineLocation.title")}
              </>
            </StyledTitle>
          )}
          <RoadsCoordinates
            editableLayer={editableLayer}
            clickActive={clickActive}
            editType={editType}
            handleNext={handleNext}
            showLocationInput={showLocationInput}
            disabled={disabled}
            activeColor={activeColor}
          />
        </div>
      )}
    </>
  );
};

export default CoordinatesSelection;
