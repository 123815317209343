import { geojsonToArcGIS } from "@terraformer/arcgis";
import { loadModules } from "esri-loader";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectionLineSymbol } from "../components/Dashboard/Editor/CoordinatesSelection/RoadsCoordinates";
import { queryLayer } from "../components/Dashboard/Editor/helpers";
import {
  OPEN_ROUTE_SERVICE_API,
  OPEN_ROUTE_SERVICE_API_KEY,
} from "../data/constants";
import { graphicsLayer, view } from "../utils/API";
import useCustomSnackbar from "./useCustomSnackbar";

// Add this helper function before useRouteCalculation
const generateRouteKey = (coordinates) => {
  return coordinates.map((coord) => coord.join("|")).join("-");
};

const useRouteCalculation = (
  clickedPoints,
  setAddedPoints,
  setCount,
  matchBaselineRoads,
  requestHandle
) => {
  const [loading, setLoading] = useState(false);
  // const [startLookingPossibleRoutes, setStartLookingPossibleRoutes] = useState(false);
  const [errorPoints, setErrorPoints] = useState([]);
  const { editableLayer } = useSelector((state) => state.dashboard);
  const ref = useRef(true);
  const [openSnackbar] = useCustomSnackbar();
  const { t } = useTranslation("common");
  const routeCache = useRef(new Map());

  const layerControllers = useRef({});

  const handleCreateRoute = async () => {
    setLoading(true);
    const [
      Graphic,
      geometryEngine,
      Point,
      Polyline,
      Extent,
      esriRequest,
      webMercatorUtils,
      geometryEngineAsync,
    ] = await loadModules([
      "esri/Graphic",
      "esri/geometry/geometryEngine",
      "esri/geometry/Point",
      "esri/geometry/Polyline",
      "esri/geometry/Extent",
      "esri/request",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/geometryEngineAsync",
    ]);

    if (clickedPoints.length < 2) return;

    const allPoints = graphicsLayer.graphics
      .filter((g) => g.id && g.id.includes("point"))
      .toArray();
    const pointsPolyline = new Polyline({
      paths: allPoints.map((p) => [p.geometry.longitude, p.geometry.latitude]),
    });
    if (pointsPolyline.extent) {
      await view.goTo(pointsPolyline.extent.expand(1.5), {
        duration: 1500,
      });
    }

    const routePoints = [];
    const graphics = [];
    const graphicsMap = {};
    graphicsLayer.graphics.forEach((g) => {
      if (g.id && g.id.includes("route") && !g.id.includes("route-manual")) {
        graphics.push(g);
      }
    });

    graphicsLayer.removeMany(graphics);
    clickedPoints.forEach((point, index, array) => {
      let nextPoint;
      if (index < array.length - 1) {
        nextPoint = array[index + 1];
      }

      if (nextPoint?.searchable && point.searchable) {
        const id =
          point.coordinate.join("|") + "-" + nextPoint.coordinate.join("|");

        routePoints.push({
          id: point.coordinate.join("|") + "-" + nextPoint.coordinate.join("|"),
          coordinate: [point.coordinate, nextPoint.coordinate],
          points: [point, nextPoint],
        });
      }
    });

    const routePromises = routePoints.map((route) => {
      const cacheKey = generateRouteKey(route.coordinate);

      if (routeCache.current.has(cacheKey)) {
        return Promise.resolve(routeCache.current.get(cacheKey));
      }

      return esriRequest(
        `${OPEN_ROUTE_SERVICE_API}/v2/directions/driving-car/geojson`,
        {
          method: "POST",
          responseType: "json",
          body: JSON.stringify({
            coordinates: route.coordinate,
            radiuses: [6000],
            extra_info: [
              "osmid",
              "waytype",
              "waycategory",
              "roadaccessrestrictions",
              "countryinfo",
              "suitability",
              "surface",
            ],
            preference: "shortest",
          }),
          headers: {
            Authorization: OPEN_ROUTE_SERVICE_API_KEY,
            "Content-type": "application/json",
          },
        }
      ).then((response) => {
        routeCache.current.set(cacheKey, response);
        return response;
      });
    });
    const routePolylines = [];
    try {
      const routes = await Promise.all(routePromises);
      const routeGraphics = [],
        routeGraphicPromises = [];
      const routeFeatures = [];

      routes.forEach((route, index) => {
        const id = routePoints[index].id;
        const points = routePoints[index].points;
        const { data } = route;
        if (!data) return;

        const [feature] = geojsonToArcGIS(data);
        const polyline = new Polyline({
          paths: feature.geometry.paths,
          spatialReference: feature.spatialReference,
        });
        if (!matchBaselineRoads) {
          feature.id = id;
          routeFeatures.push(feature);
          // routeGraphics.push({
          //   id: "route-" + id,
          //   symbol: selectionLineSymbol,
          //   geometry: polyline,
          //   attributes: {
          //     cities,
          //   },
          //   orsFeature: feature,
          // });
        } else {
          routeGraphicPromises.push(handleQueryLayer(polyline, id, points));
        }
      });

      if (!matchBaselineRoads) {
        await addRouteGraphic(routeFeatures, clickedPoints);
      } else {
        const result = await Promise.allSettled(routeGraphicPromises);
      }
    } catch (err) {
      setTimeout(() => {
        requestHandle?.onError();
      }, 1000);
    } finally {
      requestHandle.onFinish();
    }
  };

  const addRouteGraphic = async (
    routeFeatures,
    clickedPoints,
    routeFeature
  ) => {
    const [geometryEngineAsync, Graphic, Polyline] = await loadModules([
      "esri/geometry/geometryEngineAsync",
      "esri/Graphic",
      "esri/geometry/Polyline",
    ]);

    const cities = clickedPoints.filter((p) => !!p.city).map((p) => p.city);

    const routeGraphics = [];
    routeFeatures.forEach((feature) => {
      const polyline = new Polyline({
        paths: feature.geometry.paths,
        spatialReference: feature.spatialReference,
      });

      routeGraphics.push({
        id: "route-" + feature.id,
        symbol: selectionLineSymbol,
        geometry: polyline,
        cities,
        orsFeature: feature,
      });

      // const {
      //   extras: { countryinfo },
      // } = feature.attributes;
      // if (countryinfo && Array.isArray(countryinfo.values)) {
      //   countryinfo.values.forEach((countryValue) => {
      //     const [startIndex, endIndex, countryId] = countryValue;
      //     const originalPath = feature.geometry.paths[0];

      //     const routePath = originalPath.slice(startIndex, endIndex + 1);

      //     const polyline = new Polyline({
      //       paths: [routePath],
      //       spatialReference: feature.spatialReference,
      //     });
      //   });
      // }
    });

    graphicsLayer.addMany(routeGraphics);
  };

  const findAllLines = (clickedPoints) => {
    const possibleRoutes = [];
    const promises = [];
    clickedPoints.forEach((start, i) => {
      const allCoordinates = [];
      if (clickedPoints.length - 1 > i) {
        clickedPoints.forEach((destination, index) => {
          if (i !== index) {
            const coordinates = [start, destination];
            allCoordinates.push(coordinates);
          }
        });
      }
      possibleRoutes.push(allCoordinates);
    });

    possibleRoutes.forEach((arr) => {
      arr.forEach((coordinates) => {
        promises.push(handleRequest(coordinates));
      });
    });

    return Promise.all(promises);
  };

  const handleRequest = async (points) => {
    try {
      const [esriRequest] = await loadModules(["esri/request"]);
      const route = await esriRequest(
        `${OPEN_ROUTE_SERVICE_API}/v2/directions/driving-car/geojson`,
        {
          method: "POST",
          responseType: "json",
          body: JSON.stringify({
            coordinates: points.map((item) => item.coordinate),
            radiuses: [6000],
          }),
          headers: {
            Authorization: OPEN_ROUTE_SERVICE_API_KEY,
            "Content-type": "application/json",
          },
        }
      );
      return route;
    } catch (err) {
      return null;
    }
  };

  const handleQueryLayer = async (polyline, id, clickedPoints) => {
    let requestedPolylineGraphic;
    const layer = view.map.layers.find(
      (l) => l.layerConfig?.titleLabel === "roads"
    );

    const roadsBaselineLayers = view.map.layers.filter(
      (l) => l.layerConfig?.titleLabel === "roads"
    );

    const [
      Graphic,
      geometryEngine,
      Point,
      Polyline,
      Extent,
      esriRequest,
      webMercatorUtils,
      geometryEngineAsync,
    ] = await loadModules([
      "esri/Graphic",
      "esri/geometry/geometryEngine",
      "esri/geometry/Point",
      "esri/geometry/Polyline",
      "esri/geometry/Extent",
      "esri/request",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/geometryEngineAsync",
    ]);

    const drawBuffer = await geometryEngineAsync.geodesicBuffer(
      polyline,
      100,
      "meters"
    );

    const drawBufferClient = await geometryEngineAsync.geodesicBuffer(
      polyline,
      10000,
      "meters"
    );

    //helper
    // graphicsLayer.add({
    //   geometry: drawBuffer,
    //   symbol: {
    //     ...selectionLineSymbol,
    //     color: "red",
    //   },
    // });

    // return;

    roadsBaselineLayers.forEach((layer) => {
      layerControllers.current[layer.id] = new AbortController();
    });

    // const webmerkatorRoute = webMercatorUtils.geographicToWebMercator(polyline);

    // createMatchedRoute(webmerkatorRoute, layer, {
    //   Point,
    //   Polyline,
    //   geometryEngineAsync,
    // });
    // return;

    const queryRes = await queryLayer(
      layer,
      drawBuffer,
      0,
      polyline,
      editableLayer,
      roadsBaselineLayers,
      setCount,
      drawBufferClient,
      id,
      {
        controllers: layerControllers.current,
        geometryEngine,
        geometryEngineAsync,
        Polyline,
        Graphic,
        webMercatorUtils,
        Point,
      }
    );

    if (!queryRes) {
      openSnackbar(t("screen.message.error"));
      return;
    }

    const {
      graphic: res,
      cuttedFeatures,
      trimmedSituationalFeatures,
      deleteSituationalFeatures,
    } = queryRes;

    requestedPolylineGraphic = res;
    const geometry = webMercatorUtils.geographicToWebMercator(
      requestedPolylineGraphic.geometry
    );
    requestedPolylineGraphic.geometry = geometry;
    requestedPolylineGraphic.id = "route-" + id;
    const apiStartPoint = webMercatorUtils.geographicToWebMercator(
      polyline.getPoint(0, 0)
    );
    const apiEndPoint = webMercatorUtils.geographicToWebMercator(
      polyline.getPoint(0, polyline.paths[0].length - 1)
    );
    const points = [apiStartPoint, apiEndPoint];

    const promises = points.map((p) => {
      return geometryEngineAsync.nearestVertex(
        requestedPolylineGraphic.geometry,
        p
      );
    });

    const pointResults = await Promise.all(promises);

    const paths = [];

    pointResults.forEach((coordinateResult, index) => {
      const point = points[index];

      paths.push([
        [point.x, point.y],
        [coordinateResult.coordinate.x, coordinateResult.coordinate.y],
      ]);
    });

    const pointsPolyline = new Polyline({
      paths: paths,
      spatialReference: view.spatialReference,
    });

    const unioned = await geometryEngineAsync.union([
      pointsPolyline,
      requestedPolylineGraphic.geometry,
    ]);

    const graphics = [];
    const cities = []; //clickedPoints.filter((p) => !!p.city).map((p) => p.city);
    // console.log(cuttedFeatures);

    for (const feat of cuttedFeatures) {
      let geometry = feat.geometry;
      // if (feat.geometry.spatialReference.isGeographic) {
      //   geometry = webMercatorUtils.geographicToWebMercator(feat.geometry);
      // }
      const graphic = new Graphic({
        id: requestedPolylineGraphic.id,
        geometry: geometry,
        attributes: {
          ...(feat.attributes || {}),
          cities: cities,
        },
        symbol: requestedPolylineGraphic.symbol,
      });
      graphics.push(graphic);
    }

    graphicsLayer.addMany(graphics);
    // console.log(graphicsLayer.graphics);

    // return;
    // const graphics = await Promise.all(
    //   unioned.paths.map(async (pathArr) => {
    //     return new Graphic({
    //       id: requestedPolylineGraphic.id,
    //       geometry: new Polyline({
    //         paths: pathArr,
    //         spatialReference: unioned.spatialReference,
    //       }),
    //       attributes: requestedPolylineGraphic.attributes,
    //       symbol: requestedPolylineGraphic.symbol,
    //     });
    //   })
    // );
    // graphicsLayer.addMany(graphics);
    requestedPolylineGraphic.geometry = unioned;

    // graphicsLayer.add(requestedPolylineGraphic);

    requestHandle?.onSuccess({
      bufferNumber: bufferWidth,
      geometry,
      cuttedFeatures,
    });
  };

  // useEffect(() => {
  //   const routeGraphics = graphicsLayer.graphics.filter(
  //     (g) => g.id && g.id.includes("route")
  //   );

  //   // const clickedGraphics = [];
  //   // clickedPoints.forEach((point=>{
  //   //   const graphic = graphicsLayer.graphics.find(g=>g.id && g.id.includes(`line-${point.coordinate.join('|')}`));
  //   //   if (graphic){
  //   //     clickedGraphics.push(graphic.id);
  //   //   }
  //   // }))

  //   // const manualAddedGraphicsToRemove = graphicsLayer.graphics.filter(g=>clickedGraphics.includes(g.id));

  //   if (routeGraphics.length > 0) {
  //     // graphicsLayer.removeMany(routeGraphics);
  //   }

  //   // if (manualAddedGraphicsToRemove.length > 0){
  //   //   // graphicsLayer.removeMany(manualAddedGraphicsToRemove);
  //   // }
  // }, [clickedPoints]);

  // useEffect(() => {
  //   const getGraphics = (geometryEngine) => {
  //     try {
  //       const emptyPoints = clickedPoints.filter(
  //         (p) => p.coordinate.length === 0
  //       );
  //       if (emptyPoints.length === 0) {
  //         const graphicsArray = graphicsLayer.graphics.toArray();

  //         const graphicsIds = [];
  //         const intersectedIds = [];
  //         graphicsArray.forEach((g) => {
  //           if (!g.id || g.id.includes("point")) return;
  //           if (Array.isArray(g.intersectedPoints)) {
  //             intersectedIds.push(...g.intersectedPoints);
  //           }
  //           graphicsIds.push({
  //             id: g.id,
  //             geometry: g.geometry,
  //           });
  //         });

  //         const coordinates = clickedPoints.filter((point) =>
  //           graphicsIds.some((item) =>
  //             item.id.includes(point.coordinate.join("|"))
  //           )
  //         );
  //         const ids = clickedPoints.filter((point) =>
  //           intersectedIds.includes(point.coordinate.join("|"))
  //         );

  //         if (coordinates.length + ids.length >= clickedPoints.length) {
  //           const union = geometryEngine.union(
  //             graphicsIds.map((g) => g.geometry)
  //           );
  //           // setAddedPoints([union]);
  //         } else {
  //         }
  //       } else {
  //         // setAddedPoints([]);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       return null;
  //     }
  //   };

  //   let handler;
  //   loadModules([
  //     "esri/core/reactiveUtils",
  //     "esri/geometry/geometryEngine",
  //   ]).then(([reactiveUtils, geometryEngine]) => {
  //     view.whenLayerView(graphicsLayer).then((lv) => {
  //       handler = reactiveUtils.watch(
  //         () => lv.updating,
  //         (updating) => {
  //           if (!updating) {
  //             getGraphics(geometryEngine);
  //           }
  //         }
  //       );
  //     });
  //     getGraphics(geometryEngine);
  //   });
  //   return () => {
  //     handler?.remove();
  //   };
  // }, [clickedPoints]);

  useEffect(() => {
    return () => {
      Object.keys(layerControllers.current).forEach((key) => {
        layerControllers.current[key].abort();
      });
    };
  }, []);

  return {
    loading,
    setLoading,
    handleCreateRoute,
    errorPoints,
  };
};

export default useRouteCalculation;
