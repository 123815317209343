import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useViewLayers from "./useViewLayers";
import { view } from "../utils/API";
import { applyCurrentFilters } from "../components/Filter/ApplyFilters";
import { setActiveModule_actionType } from "../redux/constants";
import { ConfigContext } from "../utils/ConfigContext";
import { useTranslation } from "react-i18next";

const titlePlaceholder =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet sapien sed diam accumsan\n" +
  "                              tincidunt sit amet id lorem. Etiam elementum hendrerit enim, vitae dignissim elit cursus non. Mauris\n" +
  "                              laoreet auctor metus, sed mollis purus volutpat nec. Etiam luctus libero aliquam turpis euismod\n" +
  "                              malesuada.";

/**
 * @typedef {Object} ModuleInfo
 * @property {string} color - The color associated with the module
 * @property {ReactElement} icon - The icon representing the module
 * @property {array<string>} layers - An array of layers associated with the module
 * * @property {array<string>} optionalLayers - An array of layers associated with the module
 * @property {string} module - The module identifier
 * @property {string} moduleName - The human-readable name of the module
 * @property {string} title - The title of the module
 */

/**
 * Retrieves a list of modules based on the provided configuration.
 *
 * @param {Object} config - The configuration object used to determine which modules to retrieve.
 * @param {TranslationFunction} t - A translation function used to translate module names or descriptions.
 * @returns {Array<ModuleInfo>} A list of retrieved modules.
 */
export const getModules = (config, t) => {
  if (!Array.isArray(config.activeModules)) return [];

  return config.activeModules
    ?.map((module) => {
      const currModule = config.modules[module];
      //Check for configuration error, empty layers
      if (!currModule || !currModule.layers || currModule.layers.length === 0) {
        return null;
      }
      return {
        moduleName: t("screen.widget.ModuleSwitcher." + module + ".name"), // + " (" + module + ")",
        module: module,
        title: t("screen.widget.ModuleSwitcher." + module + ".title", ""),
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 6C10 5.20888 9.7654 4.43552 9.32588 3.77772C8.88635 3.11992 8.26164 2.60723 7.53073 2.30448C6.79983 2.00173 5.99556 1.92252 5.21964 2.07686C4.44372 2.2312 3.73098 2.61216 3.17157 3.17157C2.61216 3.73098 2.2312 4.44372 2.07686 5.21964C1.92252 5.99556 2.00173 6.79983 2.30448 7.53073C2.60723 8.26164 3.11992 8.88635 3.77772 9.32588C4.43552 9.7654 5.20888 10 6 10C7.06075 9.99962 8.07794 9.57806 8.828 8.828C9.57806 8.07794 9.99962 7.06075 10 6ZM6 8.8C5.92089 8.8 5.84355 8.77654 5.77777 8.73259C5.71199 8.68864 5.66072 8.62616 5.63045 8.55307C5.60017 8.47998 5.59225 8.39956 5.60769 8.32196C5.62312 8.24437 5.66122 8.1731 5.71716 8.11716C5.7731 8.06122 5.84437 8.02312 5.92196 8.00769C5.99956 7.99225 6.07998 8.00017 6.15307 8.03045C6.22617 8.06072 6.28864 8.11199 6.33259 8.17777C6.37654 8.24355 6.4 8.32089 6.4 8.4C6.3997 8.50599 6.35746 8.60756 6.28251 8.68251C6.20756 8.75746 6.10599 8.7997 6 8.8ZM6.4 6.8C6.4 6.90609 6.35786 7.00783 6.28284 7.08284C6.20783 7.15786 6.10609 7.2 6 7.2C5.89391 7.2 5.79217 7.15786 5.71716 7.08284C5.64214 7.00783 5.6 6.90609 5.6 6.8V3.6C5.6 3.49391 5.64214 3.39217 5.71716 3.31716C5.79217 3.24214 5.89391 3.2 6 3.2C6.10609 3.2 6.20783 3.24214 6.28284 3.31716C6.35786 3.39217 6.4 3.49391 6.4 3.6V6.8Z"
              fill="white"
            />
            <path
              d="M9.00627 2C9.72829 2.57011 10.291 3.30152 10.6441 4.12901C10.9973 4.9565 11.13 5.85436 11.0303 6.74254C10.9307 7.63072 10.6019 8.48163 10.0731 9.21939C9.54439 9.95715 8.83218 10.5588 8 10.9708V22H22V2H9.00627ZM15.875 19.5C15.875 19.721 15.7828 19.933 15.6187 20.0893C15.4546 20.2455 15.2321 20.3333 15 20.3333C14.7679 20.3333 14.5454 20.2455 14.3813 20.0893C14.2172 19.933 14.125 19.721 14.125 19.5V17C14.1257 16.7792 14.2181 16.5676 14.382 16.4114C14.546 16.2553 14.7681 16.1673 15 16.1667C15.2315 16.1683 15.4531 16.2566 15.6169 16.4125C15.7806 16.5684 15.8733 16.7795 15.875 17V19.5ZM15.875 13.25C15.875 13.471 15.7828 13.683 15.6187 13.8393C15.4546 13.9955 15.2321 14.0833 15 14.0833C14.7679 14.0833 14.5454 13.9955 14.3813 13.8393C14.2172 13.683 14.125 13.471 14.125 13.25V10.75C14.1257 10.5292 14.2181 10.3176 14.382 10.1614C14.546 10.0053 14.7681 9.9173 15 9.91667C15.2315 9.91827 15.4531 10.0066 15.6169 10.1625C15.7806 10.3184 15.8733 10.5295 15.875 10.75V13.25ZM15.875 7C15.875 7.22101 15.7828 7.43297 15.6187 7.58926C15.4546 7.74554 15.2321 7.83333 15 7.83333C14.7679 7.83333 14.5454 7.74554 14.3813 7.58926C14.2172 7.43297 14.125 7.22101 14.125 7V4.5C14.1257 4.27918 14.2181 4.06759 14.382 3.91145C14.546 3.7553 14.7681 3.6673 15 3.66667C15.2315 3.66827 15.4531 3.75658 15.6169 3.91251C15.7806 4.06845 15.8733 4.27948 15.875 4.5V7Z"
              fill="white"
            />
          </svg>
        ),
        color: currModule.moduleColor ?? config.opsColor,
        layers: currModule.layers,
        optionalLayers: currModule.optionalLayers || [],
      };
    })
    .filter((module) => module);
};

const useModuleSelection = () => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  const { activeModule } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { localModule, setLocalModule } = useViewLayers(view, config);

  useEffect(() => {
    if (!localModule) return;
    if (localModule !== activeModule) {
      setLocalModule(activeModule);
    }
  }, [activeModule]);

  useEffect(() => {
    if (localModule) {
      applyCurrentFilters(config.defaultFilters[localModule], config);
      if (localModule !== activeModule) {
        dispatch({ type: setActiveModule_actionType, payload: localModule });
      }

      if (
        config?.modules &&
        config.modules[localModule] &&
        config.modules[localModule].moduleColor
      ) {
        document.body.style.setProperty(
          "--opsColor",
          config.modules[localModule].moduleColor
        );
      } else {
        document.body.style.setProperty("--opsColor", config.opsColor);
      }
    }
  }, [localModule]);

  const modules = useMemo(() => getModules(config, t), [config, t]);

  const handleModuleSelection = useCallback((module) => {
    setLocalModule(module);
    view?.closePopup();
  }, []);

  return {
    modules,
    handleModuleSelection,
    activeModule,
  };
};

export default useModuleSelection;
