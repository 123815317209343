import React, { useContext, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useClickOutside from "../../../hooks/useClickOutside";
import useFeature from "../../../hooks/useFeature";
import {
  asOfDateFieldName,
  currAsOfDateFieldName,
  view,
} from "../../../utils/API";
import { ConfigContext } from "../../../utils/ConfigContext";
import {
  clickEventOnFeature,
  renderCell,
  standardizeColor,
  timeAgo,
  toggleEditorWidget,
} from "../../../utils/helper";
import { createSymbol, getLayerSymbology } from "../../../utils/symbologies";
import getAllowedFields from "../../CustomPopupWrapper/helpers/getAllowedFields";
import getPopupActions from "../../CustomPopupWrapper/helpers/getPopupActions";
import { isOutdated } from "../../Dashboard/CycleManager/helper";
import { getFeatureIcon } from "../../Dashboard/Editor/EditorFields/EditorFields";
import { StyledField } from "../../MobileApp/MobilePopup/MobilePopup-styled";
import FeatureHeading from "../../MobileApp/MobilePopup/PopupBody/FeatureHeading/FeatureHeading";
import { FeatureTitle } from "../../MobileApp/MobilePopup/PopupBody/FeatureHeading/FeatureHeading-styled";
import PopupField from "../../MobileApp/MobilePopup/PopupBody/FieldsSection/PopupField";
import { getLayerConfigFields } from "../../MobileApp/MobilePopup/PopupBody/PopupBody";
import { LineSymbol } from "../../ReportManager/symbols";
import {
  ReportCardWrapper,
  Tag,
} from "../SharedInformationCard/SharedInformationCard-styled";
import CardImages from "./CardImages";

const rotations = [0, -5, 5];

const ReportBody = ({
  config,
  layer,
  selectedFeature,
  t,
  i18n,
  fieldsType,
}) => {
  const allowedFields = useMemo(() => {
    return getAllowedFields(layer, selectedFeature);
  }, [layer, selectedFeature]);

  const getFields = (filterFields) => {
    if (!Array.isArray(filterFields)) return null;

    return allowedFields.filter(
      (field) => filterFields.includes(field.name) && !!field
    );
  };

  const symbologyFields = useMemo(() => {
    const layer = selectedFeature.sourceLayer ?? selectedFeature.layer;
    const result = [];
    if (layer) {
      const symboloy = getLayerSymbology(layer, config);
      const { colorMap } = symboloy || {};

      if (colorMap) {
        const colorMapFields = colorMap.field
          ? [colorMap.field]
          : colorMap.fields;
        const layerFields = layer.fields.filter((field) =>
          colorMapFields.includes(field.name)
        );

        const colors = layerFields.map((field) => {
          const color =
            colorMap.default[selectedFeature.attributes[field.name]] ??
            symboloy.defaultColor;
          const standartColor = standardizeColor(color);
          if (color)
            return {
              value: renderCell(
                field,
                selectedFeature.attributes[field.name],
                t,
                config
              ),
              bg: standartColor.includes("#ffffff") ? "#000" : standartColor,
              color: "#fff",
              field: field.name,
              title: t(
                "layer.fieldAlias." + field.name + ".title",
                field.alias
              ),
            };
        });

        result.push(...colors.filter((item) => item));
      }
    }

    return result;
  }, [selectedFeature, config, t]);

  const renderedFields = useMemo(() => {
    let fields = [];
    if (!layer.layerConfig)
      return {
        first: [],
        more: [],
      };

    if (
      Array.isArray(layer?.layerConfig.situationalFields) &&
      (!fieldsType || fieldsType === "situational")
    ) {
      fields.push(...layer.layerConfig.situationalFields);
      // Ensure asOfDateFieldName is first when fieldsType is situational
      const currAsOfDateIndex = fields.indexOf(currAsOfDateFieldName);
      if (currAsOfDateIndex > -1) {
        fields.splice(currAsOfDateIndex, 1);
        fields.unshift(currAsOfDateFieldName);
      }
    }

    if (
      Array.isArray(layer.layerConfig.baselineFields) &&
      (!fieldsType || fieldsType === "baseline")
    ) {
      fields.push(...layer.layerConfig.baselineFields);
      // Ensure asOfDateFieldName is first when fieldsType is baseline
      const asOfDateIndex = fields.indexOf(asOfDateFieldName);
      if (asOfDateIndex > -1) {
        fields.splice(asOfDateIndex, 1);
        fields.unshift(asOfDateFieldName);
      }
    }

    if (!!fieldsType) {
      const requiredFields = layer.layerConfig.requiredFields || [];
      fields = fields.filter((field) => requiredFields.includes(field));
    }

    const fieldsToShow = getLayerConfigFields({
      layer,
      layerConfigFields: fields,
      config,
      t,
      feature: selectedFeature,
      symbologyFields,
    });

    const first = fieldsToShow.slice(0, symbologyFields.length > 0 ? 1 : 2);
    const more = fieldsToShow.slice(2);

    return {
      first,
      more,
    };
  }, [selectedFeature, config, t, layer, symbologyFields, fieldsType]);

  const format = (value) => {
    if (!value) return;
    return timeAgo(new Date(value), i18n.language, false);
  };

  // const statusColor = useMemo(() => {
  //   if (symbologyFields.length > 0) {
  //     return standardizeColor(symbologyFields[0].bg);
  //   }

  //   return "#a6a6a6";
  // }, [symbologyFields]);
  // const isAppEmbed = useMemo(
  //   () => config.embed === EMBED_VARIANTS.APP,
  //   [config]
  // );

  const isArabic = useMemo(() => {
    return i18n.language === "ar";
  }, [i18n.language]);

  const isPopup = true;
  const expand = true;

  return (
    <>
      <div
        style={{
          marginTop: 8,
          padding: "0px 8px 4px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {symbologyFields.map((field) => (
            <StyledField
              key={field.title}
              isPopup={isPopup}
              dir={isArabic ? "rtl" : "ltr"}
            >
              <p>
                <span>
                  {field.title + ": "}{" "}
                  <span
                    style={{ color: field.bg, fontWeight: 600 }}
                    dangerouslySetInnerHTML={{ __html: field.value }}
                  />
                </span>
              </p>
            </StyledField>
          ))}

          {[...renderedFields.first, ...renderedFields.more].map((field) => (
            <PopupField
              key={field.title}
              title={field.title}
              value={field.value}
              resetLimit={!expand}
              isPopup={isPopup}
              i18n={i18n}
              feature={selectedFeature}
              field={field}
              t={t}
              isExpanded={expand}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ReportsIndicators = ({ isArabic, groupedReports }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 4,
        padding: "8px 8px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {Object.keys(groupedReports).map((k) => (
        <Tag
          dir={isArabic ? "rtl" : "ltr"}
          key={k}
          style={{
            color: "#fff", //groupedReports[k].color,
            // border:`1px solid ${groupedReports[k].color}`,
            padding: "4px 8px",
            display: "flex",
            alignItems: "center",
            background: groupedReports[k].color,
          }}
        >
          <span style={{ fontWeight: 400 }}>
            {groupedReports[k].count}&nbsp;
          </span>
          <span style={{ fontWeight: 400 }}>{groupedReports[k].title}</span>
        </Tag>
      ))}
    </div>
  );
};

const ReportCard = ({
  selectedFeature,
  onClick,
  onEdit,
  isSelected,
  showImages,
  fieldsType,
  showUpdate,
  showPublic = false,
  style = {},
}) => {
  const { i18n, t } = useTranslation("common");
  const { config } = useContext(ConfigContext);
  const { cycleUpdateDueDate, lastCycleUpdate, cycleUpdateFrequency } =
    useSelector((state) => state.cycleManagerReducer);
  const { title } = useFeature(selectedFeature, t);
  const [showImageSwiper, setShowImageSwiper] = useState(false);
  const ref = useRef();

  useClickOutside(ref, () => {
    setShowImageSwiper(false);
  });

  const layer = selectedFeature.sourceLayer
    ? selectedFeature.sourceLayer
    : selectedFeature.layer;

  const fields = layer.fields;

  const [images, setImages] = useState([]);

  const reportsCount = useMemo(() => {
    if (
      Array.isArray(selectedFeature.reports) &&
      selectedFeature.reports.length > 0
    ) {
      return selectedFeature.reports.length;
    }

    return 0;
  }, [selectedFeature]);
  const actionButtons = useMemo(
    () => getPopupActions({ t, layer, config }),
    [t, layer, config]
  );

  const handleEdit = (e, editIndex) => {
    e.stopPropagation();
    toggleEditorWidget(false);

    if (editIndex > -1) {
      clickEventOnFeature(selectedFeature, {
        zoom: false,
        duration: 1,
        showPopup: true,
      }).then((res) => {
        view.popup.visible = false;
        setTimeout(() => {
          view.popup.triggerAction(editIndex);
        }, 100);
      });
    }
  };

  const isArabic = useMemo(() => {
    return i18n.language === "ar";
  }, []);

  const editFieldName = useMemo(() => {
    const layer = selectedFeature.layer;
    if (
      layer.fields.some((f) => f.name === currAsOfDateFieldName) &&
      selectedFeature.attributes[currAsOfDateFieldName]
    ) {
      return currAsOfDateFieldName;
    }

    return layer?.editFieldsInfo?.editDateField;
  }, [selectedFeature]);

  const format = (value) => {
    return timeAgo(new Date(value), i18n.language, false);
  };

  const symbology = useMemo(() => {
    const layer = selectedFeature.sourceLayer || selectedFeature.layer;
    const symbology = getLayerSymbology(layer, config);
    if (!symbology) return;

    const iconName = getFeatureIcon(selectedFeature, symbology);
    const newSymbol = createSymbol(
      symbology,
      layer,
      iconName,
      symbology.defaultColor,
      symbology.defaultSize
    );

    if (newSymbol.url) return newSymbol.url;
    else if (newSymbol.type === "simple-line" && newSymbol.color) {
      const color = newSymbol.color ? newSymbol.color : "grey";
      return (
        "data:image/svg+xml;base64," +
        btoa(ReactDOMServer.renderToStaticMarkup(<LineSymbol stroke={color} />))
      );
    }
  }, [selectedFeature]);

  const groupedReports = useMemo(() => {
    if (!selectedFeature.reports) return null;

    const symbologyLayer =
      selectedFeature.reports[0].sourceLayer ||
      selectedFeature.reports[0].layer;
    const symbology = getLayerSymbology(symbologyLayer, config);
    const colorMap = symbology.colorMap?.default || {};

    const reportsMap = {};
    selectedFeature.reports.forEach((report) => {
      const value = report.attributes.currvalidationfield;

      if (reportsMap[value]) {
        reportsMap[value].count++;
      } else {
        reportsMap[value] = {
          color: colorMap[value] || symbology.defaultColor,
          count: 1,
          title: t("layer.domain.currvalidationfield." + value, `${value}`),
        };
      }
    });

    return reportsMap;
  }, [selectedFeature, t, config]);

  const cycleUpdateStatus = useMemo(() => {
    if (
      !lastCycleUpdate ||
      !cycleUpdateDueDate ||
      !selectedFeature ||
      !showUpdate ||
      fieldsType !== "situational"
    )
      return null;

    try {
      const isOutdatedResult = isOutdated({
        feature: selectedFeature,
        startDateTime: lastCycleUpdate,
        endDateTime: cycleUpdateDueDate,
        filterShowPublic: false,
        dateField:
          fieldsType === "situational"
            ? currAsOfDateFieldName
            : asOfDateFieldName,
        cycleUpdateFrequency,
      });

      return isOutdatedResult ? "Outdated" : "Up to date";
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [
    cycleUpdateFrequency,
    cycleUpdateDueDate,
    lastCycleUpdate,
    selectedFeature,
    fieldsType,
    showUpdate,
  ]);

  return (
    <ReportCardWrapper style={style} isSelected={isSelected} onClick={onClick}>
      {selectedFeature.reports ? (
        <div
          style={{
            padding: "8px 8px 0px 8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: 4, flex: 1, overflow: "hidden" }}>
            {symbology && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  width: 36,
                  minWidth: 30,
                  position: "relative",
                }}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  alt="symbol-url"
                  src={symbology}
                />
              </div>
            )}
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FeatureTitle
                style={{
                  width: "100%",
                }}
                size="md"
                isPopup={true}
              >
                <p className="text-elipsis">{title}</p>
              </FeatureTitle>
              <span
                style={{
                  fontSize: 12,
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                {t("screen.widget.ReportManager.clickToSeeReports")}
              </span>
            </div>
          </div>
          {selectedFeature.reports &&
            Object.keys(groupedReports).length <= 2 && (
              <ReportsIndicators
                feature={selectedFeature}
                config={config}
                t={t}
                groupedReports={groupedReports}
                isArabic={isArabic}
              />
            )}
        </div>
      ) : (
        <div style={{ padding: "8px 8px 0px 8px" }}>
          <FeatureHeading
            feature={selectedFeature}
            t={t}
            actionButtons={
              reportsCount === 0
                ? [actionButtons.find((action) => action.id === "edit-action")]
                : []
            }
            expanded={true}
            isPopup={true}
            hasClose={false}
            onEdit={onEdit ?? handleEdit}
            size="sm"
            defaultColor
            cycleUpdateStatus={cycleUpdateStatus}
            showPublic={showPublic}
          />
          {showImages && <CardImages feature={selectedFeature} layer={layer} />}
        </div>
      )}
      {!selectedFeature.reports ? (
        <ReportBody
          selectedFeature={selectedFeature}
          layer={layer}
          config={config}
          t={t}
          i18n={i18n}
          fieldsType={fieldsType}
        />
      ) : Object.keys(groupedReports).length > 2 ? (
        <ReportsIndicators
          feature={selectedFeature}
          config={config}
          t={t}
          isArabic={isArabic}
          groupedReports={groupedReports}
        />
      ) : null}
    </ReportCardWrapper>
  );
};

export default ReportCard;
