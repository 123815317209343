import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isEditable } from "../components/Dashboard/Editor/helpers/helpers";
import { currAsOfDateFieldName, view } from "../utils/API";
import useLayersChange from "./useLayersChange";

const EntryPoints = Object.freeze(new Set(["aerodromes", "eep", "ports"]));

const useEditableLayers = (config, includeOnlyVisible = true) => {
  const [editableLayers, setEditableLayers] = useState([]);
  const { visibleLayersIds, layerCount } = useLayersChange(config);
  const { operationLayers } = useSelector((state) => state.mapViewReducer);

  useEffect(() => {
    let timer;
    if (!view) return;
    timer = setTimeout(() => {
      updateLayers(view.map.layers);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [layerCount]);

  const updateLayers = (newLayers) => {
    const newEditableLayers = newLayers.filter(
      (l) =>
        isEditable(l) &&
        (includeOnlyVisible
          ? operationLayers.includes(l.layerConfig?.alias)
          : !includeOnlyVisible)
    );

    if (newEditableLayers.length) {
      setEditableLayers(newEditableLayers);
    }
  };

  const situationalLayers = useMemo(() => {
    return editableLayers.filter((l) => {
      return (
        l.fields.some((f) => f.name.includes(currAsOfDateFieldName)) &&
        l.layerConfig?.situationalFields?.length > 0
      );
    });
  }, [editableLayers]);

  const entryPointsLayers = useMemo(
    () =>
      editableLayers.filter((l) => {
        const alias = l.layerConfig.extends || l.layerConfig.alias;
        return EntryPoints.has(alias) && l.visible;
      }),
    [editableLayers]
  );

  const baselineLayers = useMemo(() => {
    return editableLayers.filter((l) => {
      const alias = l.layerConfig.extends || l.layerConfig.alias;
      return (
        alias !== "roads_situational" &&
        l.layerConfig.alias &&
        l.layerConfig.baselineFields?.length > 0
        // || l.layerConfig.situationalFields?.length > 0)
      );
    });
  }, [editableLayers, entryPointsLayers]);

  return {
    editableLayers,
    setEditableLayers,
    situationalLayers,
    baselineLayers,
    entryPointsLayers,
  };
};

export default useEditableLayers;
