import {
  addLayersUpdates_actionType,
  setCycleManagerData_actionType,
  setCycleManagerFeatures_actionType,
  setCycleManagerOpen_actionType,
  setLastCycleUpdate_actionType,
  setLayersUpdates_actionType,
  setLayersUpToDate_actionType,
  updateLayerUpdate_actionType,
} from "../constants";

/**
 * Creates an action to update the cycle manager data in the Redux store.
 *
 * @param {string} cycleUpdateFrequency - The frequency at which the cycle updates.
 * @param {Date} lastCycleUpdate - The date of the last cycle update.
 * @returns {Object} The action object with type and data properties.
 */
export const setCycleManagerData = ({
  cycleUpdateFrequency,
  lastCycleUpdate,
  deploymentStatus,
}) => ({
  type: setCycleManagerData_actionType,
  payload: {
    cycleUpdateFrequency,
    lastCycleUpdate,
    deploymentStatus,
  },
});

/**
 * Sets the cycle manager features count.
 *
 * @param {number} allFeatures The total number of features.
 * @param {number} updatedFeatures The number of updated features.
 */
export const setCycleManagerFeatures = ({ allFeatures, updatedFeatures }) => ({
  type: setCycleManagerFeatures_actionType,
  payload: {
    allFeatures,
    updatedFeatures,
  },
});

/**
 * Sets the list of layers that are up to date in the Redux store.
 *
 * @param {Array<string>} layers - The list of layers that are up to date.
 * @returns {Object} The action object with type and data properties.
 */
export const setLayersUpToDate = (layers) => ({
  type: setLayersUpToDate_actionType,
  payload: layers,
});

/**
 * Sets the updates status of layers in the Redux store.
 *
 * @param {Object<string, {missing: Array<Graphic>, features: Array<Graphic>}>} payload - The object with the layerConfig id as key and
 * array of missing features and features as a value.
 * @returns {Object} The action object with type and data properties.
 */
export const setLayersUpdates = (payload) => ({
  type: setLayersUpdates_actionType,
  payload,
});

/**
 * Creates an action object for updating a specific layer with the given `id` and `layerUpdate`.
 *
 * @param {Object} payload - An object containing the `id` of the layer to update and the `layerUpdate` object with the new layer data.
 * @param {string} payload.id - The `id` of the layer to update.
 * @param {Object} payload.layerUpdate - The new layer data to update the layer with.
 * @returns {Object} An action object with a `type` property set to `addLayersUpdates_actionType` and a `payload` property containing the `id` and `layerUpdate` from the `payload` argument.
 */
export const addLayersUpdate = (payload) => ({
  type: addLayersUpdates_actionType,
  payload,
});

/**
 * Creates an action to set the cycle manager open state.
 *
 * @param {boolean} payload - Whether the cycle manager is open or not.
 * @returns {object} An action object with type and payload properties.
 */
export const setCycleManagerOpen = (payload) => ({
  type: setCycleManagerOpen_actionType,
  payload,
});

/**
 * Action creator for setting the last cycle update in the Cycle Manager.
 *
 * @param {number} payload - The timestamp of the last cycle update.
 * @returns {Object} The action object with type and payload.
 */
export const setLastCycleUpdate = (payload) => ({
  type: setLastCycleUpdate_actionType,
  payload,
});

/**
 * Action creator for updating a layer with new features.
 *
 * @param {Object} params - The parameters for the update.
 * @param {string} params.id - The ID of the layer to update.
 * @param {Array} params.features - The features to update in the layer.
 * @returns {Object} The action object with type and payload.
 */
export const updateLayerUpdate = ({ id, features }) => ({
  type: updateLayerUpdate_actionType,
  payload: {
    id,
    features,
  },
});

/**
 * Action creator for updating a layer with new features.
 *
 * @param {Object} params - The parameters for the update.
 * @param {string} params.id - The ID of the layer to update.
 * @param {Array} params.features - The features to update in the layer.
 * @returns {Object} The action object with type and payload.
 */
export const deleteLayerUpdate = ({ id, features }) => ({
  type: updateLayerUpdate_actionType,
  payload: {
    id,
    features,
  },
});
