import React, { useContext, useEffect } from "react";
import { useNewEditSupplyRoutes } from "../../../../../hooks";
import { ConfigContext } from "../../../../../utils/ConfigContext";
import { EditorContext } from "../../EditorContextProvider";
import { EditType } from "../../EditorSwiper/EditorSwiper";

const CurvedPolyline = ({ handleNext, isLastStep, disabled }) => {
  const { config } = useContext(ConfigContext);
  const {
    highlightFeature,
    setHighlightFeature,
    editableLayer,
    highlightHandle,
    editType,
    addedPoints,
    setAddedPoints,
  } = useContext(EditorContext);

  const { activateDraw, deactivateDraw } = useNewEditSupplyRoutes(
    editableLayer,
    config,
    editType === EditType.edit
      ? highlightFeature
      : isLastStep && addedPoints.length > 0
      ? {
          geometry: addedPoints[0],
          attributes: {},
        }
      : null,
    highlightHandle,
    editType,
    disabled,
    (geometry, symbol) => {
      //   loadModules(["esri/Graphic"]).then(([Graphic]) => {
      //     const graphic = new Graphic({
      //       geometry,
      //       symbol,
      //       attributes: {},
      //     });

      //   });
      //   if (highlightFeature) {
      //     const cloned = highlightFeature.clone();
      //     cloned.geometry = geometry;
      //     setHighlightFeature(cloned);
      //   } else {

      //   }
      setAddedPoints([geometry]);

      // setHighlightFeature({ geometry, symbol, attributes: {} });
      if (handleNext) {
        handleNext();
      }
    }
  );

  useEffect(() => {
    if (editType === EditType.create && !isLastStep) {
      activateDraw();
    }

    return () => {
      deactivateDraw();
    };
  }, []);

  return <div />;
};

export default CurvedPolyline;
