import { useCallback, useEffect, useRef, useState } from "react";
import {
  OPEN_ROUTE_SERVICE_API,
  OPEN_ROUTE_SERVICE_API_KEY,
} from "../../data/constants";

const useORSReverseSearch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    return () => {
      abortRequest();
    };
  }, []);

  /**
   * Fetch reverse geocode data from OpenRouteService API.
   * @param {Object} coordinates - The coordinates object.
   * @param {number|string} coordinates.longitude - The longitude of the point.
   * @param {number|string} coordinates.latitude - The latitude of the point.
   * @param {string} [layers="country"] - The layers to use for the reverse geocode.
   * @returns {Promise<Object|null>} The reverse geocode result or null if an error occurred.
   */
  const fetchReverseGeocode = useCallback(
    async ({ longitude, latitude }, layers = "country") => {
      if (longitude == null || latitude == null) {
        throw new Error("Longitude and latitude must be provided");
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      setLoading(true);
      setError(null);

      const url = `${OPEN_ROUTE_SERVICE_API}/geocode/reverse?api_key=${OPEN_ROUTE_SERVICE_API_KEY}&point.lon=${longitude}&point.lat=${latitude}&layers=${layers}`;

      try {
        const response = await fetch(url, { signal: abortController.signal });
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        setData(result);
        return result;
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(err.message);
        }
        return null;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const abortRequest = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);

  return { data, loading, error, fetchReverseGeocode, abortRequest };
};

export default useORSReverseSearch;
