import {
  setPrintWidgetOpen_actionType,
  setPrintWidgetStep_actionType,
  setPrintWidgetLoading_actionType,
  setPrintOption_actionType,
  setPrintWidgetFinish_actionType,
} from "../constants";

export const PRINT_OPTIONS = Object.freeze({
  quick: "QUICK",
  custom: "CUSTOM",
});

const initialState = {
  open: false,
  step: 0,
  isLoading: false,
  printOption: "quick",
};
const printWidget = (state = initialState, action) => {
  switch (action.type) {
    case setPrintWidgetOpen_actionType: {
      const { payload } = action;
      return {
        ...state,
        open: payload,
      };
    }
    case setPrintWidgetStep_actionType: {
      const { payload } = action;
      return {
        ...state,
        step: payload,
      };
    }
    case setPrintWidgetLoading_actionType: {
      const { payload } = action;
      return {
        ...state,
        isLoading: payload,
      };
    }
    case setPrintOption_actionType: {
      const { payload } = action;
      return {
        ...state,
        printOption: payload,
      };
    }
    case setPrintWidgetFinish_actionType: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default printWidget;
