import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Uploader from "../../Uploader/Uploader";
import { EditorContext } from "../../Dashboard/Editor/EditorContextProvider";
import { ConfigContext } from "../../../utils/ConfigContext";

const UploadMediaFiles = ({ color, feature, layer }) => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const { attachments, setAttachments } = useContext(EditorContext);

  useEffect(() => {
    if (
      !layer.capabilities.operations.supportsQueryAttachments ||
      !feature ||
      !layer
    )
      return;
    layer
      .queryAttachments({
        objectIds: [feature.getObjectId()],
      })
      .then((response) => {
        const attachments = Object.values(response).flat(1);

        const result = attachments.reduce(
          (acc, att, i) => {
            if (att.contentType.startsWith("image")) {
              acc.previews[att.name + att.id] = att.url;
            } else {
              acc.previews[att.name + att.id] = "pdf";
            }

            acc.files.push({
              attachmentId: att.id,
              name: att.name,
              type: att.contentType,
              size: att.size,
              isDeleted: false,
              url: att.contentType.startsWith("image") ? att.url : "pdf",
            });
            return acc;
          },
          {
            previews: {},
            files: [],
          }
        );

        setAttachments((prev) => ({
          ...prev,
          previews: {
            ...prev.previews,
            ...result.previews,
          },
          files: [...prev.files, ...result.files],
        }));

        // setPreviews(
        //   attachments.reduce((acc, att) => {
        //     if (att.contentType.startsWith("image")) {
        //       acc[att.id] = att.url;
        //     } else {
        //       acc[att.id] = "pdf";
        //     }
        //     return acc;
        //   }, {})
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [feature, layer]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const previewUrls = {};
      acceptedFiles.forEach((file, i) => {
        const index = i;
        if (file.type !== "application/pdf") {
          // previewUrls[file.name + index] = URL.createObjectURL(file);
          file.url = URL.createObjectURL(file);
          file.isDeleted = false;
        } else {
          // previewUrls[file.name + index] = "pdf";
          file.url = "pdf";
          file.isDeleted = false;
        }
      });

      setAttachments((prev) => ({
        ...prev,
        files: prev.files.concat(...acceptedFiles),
        previews: {
          ...prev.previews,
          ...previewUrls,
        },
      }));
    },
    [setAttachments, attachments]
  );

  const handleRemove = useCallback(
    (file, index) => {
      const { [file.name + index]: filePreview, ...restPreviews } =
        attachments.previews;

      setAttachments((prev) => ({
        ...prev,
        files: prev.files.map((f, i) => {
          if (i === index) {
            f.isDeleted = true;
          }
          return f;
        }),
        previews: restPreviews,
      }));
    },
    [attachments]
  );

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontSize: 12,
          color: "rgb(76,76,76)",
          marginTop: 4,
          marginBottom: 10,
        }}
      >
        {t("screen.widget.Editor.mediaFiles.label")}
      </p>
      <Uploader
        onDrop={onDrop}
        handleDelete={handleRemove}
        config={config}
        fileDescription={t("screen.widget.Editor.mediaFiles.description")}
        buttonTitle="Browse"
        files={attachments.files}
        previews={attachments.previews}
        color={color}
      />
    </div>
  );
};

export default UploadMediaFiles;
