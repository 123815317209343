import React from "react";
import { StyledButton } from "./Button-styled";

const Button = ({
  children,
  bg = undefined,
  borderRadius = undefined,
  border = undefined,
  minWidth = undefined,
  height = undefined,
  disabled = undefined,
  color = undefined,
  fontSize = undefined,
  padding = undefined,
  margin = undefined,
  hoverBg = undefined,
  position = undefined,
  ...props
}) => {
  return (
    <StyledButton
      bg={bg}
      borderRadius={borderRadius}
      border={border}
      minWidth={minWidth}
      height={height}
      disabled={disabled}
      color={color}
      fontSize={fontSize}
      padding={padding}
      margin={margin}
      hoverBg={hoverBg}
      position={position}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
