import { StyledFilterWrapper } from "../SharedInformation-styled";
import FilterFieldCombo from "../../Filter/FilterFieldCombo";
import { StyledProgress } from "../../App/App-styled";
import { StyledHeaderDate } from "../../Dashboard/Dashboard-styled";
import React, { useEffect, useMemo, useState } from "react";
import useFilters from "../../../hooks/useFilters";
import { useSelector } from "react-redux";
import { getLayerSymbology } from "../../../utils/symbologies";
import { useTranslation } from "react-i18next";
import { timeAgo } from "../../../utils/helper";

const SharedInformationHeader = ({ loading, config, layer, lastDate }) => {
  const { activeModule } = useSelector((state) => state);
  const { filters, setAndApplyFilters } = useFilters({
    isActive: false,
    config,
  });

  const [time, setTime] = useState("");
  const { t, i18n } = useTranslation("common");

  let timeout;
  useEffect(() => {
    if (!lastDate) return;
    const handleTimeChange = (refreshedTime) => {
      if (refreshedTime === "now") {
        setTime(t("screen.widget.common.refreshedNow"));
      } else {
        setTime(t("screen.widget.common.refreshed") + " " + refreshedTime);
      }
    };

    const refreshedTime = timeAgo(lastDate, i18n.language);
    handleTimeChange(refreshedTime);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const refreshedTime = timeAgo(lastDate, i18n.language);
      handleTimeChange(refreshedTime);
    }, 1000 * 60);
    return () => {
      clearTimeout(timeout);
    };
  }, [lastDate]);

  const onFilterChange = (fieldName, value) => {
    let newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[activeModule][fieldName] = value;
    setAndApplyFilters(newFilters);
  };
  const selectedOptions = useMemo(
    () => filters[activeModule]["currvalidationfield"],
    [filters, activeModule]
  );

  const dropDownField = useMemo(() => {
    if (!layer) return;
    return layer.fields.find((field) => field.name === "currvalidationfield");
  }, [layer]);

  const colors = useMemo(() => {
    if (!layer) return;

    const symbology = getLayerSymbology(layer, config);
    const { default: defaultColorMap = {} } = symbology?.colorMap || {};
    return defaultColorMap;
  }, [layer, config]);

  return (
    <div>
      <div
        style={{
          position: "relative",
        }}
      >
        <StyledFilterWrapper>
          {dropDownField && (
            <FilterFieldCombo
              field={dropDownField}
              t={t}
              referenceKey={dropDownField.name}
              onValuesChange={onFilterChange}
              defaultValue={selectedOptions}
              multi
              isMobile={true}
              colors={colors}
            />
          )}
          {loading && (
            <StyledProgress
              style={{
                position: "absolute",
                bottom: "8px",
                width: "calc(100% - 40px)",
                left: "20px",
              }}
              type="indeterminate"
            />
          )}
        </StyledFilterWrapper>
      </div>
      <StyledHeaderDate
        style={{
          opacity: !!time ? 1 : 0,
        }}
      >
        {time ? `${time}` : ""}
      </StyledHeaderDate>
    </div>
  );
};

export default SharedInformationHeader;
