import React, { forwardRef } from "react";
import { InformationIcon } from "../../../../Icons";
import { Tooltip } from "../../../../UI";
import { StyledButton } from "../../Editor-styled";

export const AccordionWrapper = forwardRef(
  ({ id, style = {}, selected, children, show = true, ...props }, ref) => (
    <div
      ref={ref}
      style={{
        display: show ? "block" : "none",
        transition: "flex 0.5s, height 0.2s, min-height 0.2s",
        overflow: selected ? "auto" : "visible",
        minHeight: 29,
        height: 29,
        padding: "0px 14px",
        flex: selected ? 1 : "",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  )
);

export const AccordionButton = ({
  selected,
  color,
  label,
  onSelect,
  information,
  children,
  style = {},
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
        position: "sticky",
        top: 0,
        zIndex: 1,
        background: "#fff",
        ...style,
      }}
    >
      <StyledButton
        type="button"
        hover={false}
        style={{
          fontStyle: "normal",
          fontSize: 14,
          fontWeight: 500,
          color: color,
          cursor: onSelect ? "pointer" : "",
          padding: 0,
        }}
        expand={selected}
        kind="ghost"
        small
        onClick={onSelect}
      >
        <span>{label}</span>
        {onSelect && (
          <svg
            className="arrow"
            fill={color}
            fillRule="evenodd"
            height="7"
            role="img"
            viewBox="0 0 12 7"
            width="10"
            aria-label=""
          >
            <title></title>
            <path d="M6.002 5.55L11.27 0l.726.685L6.003 7 0 .685.726 0z"></path>
          </svg>
        )}
      </StyledButton>
      {children}
      {information && (
        <Tooltip showOnHover content={information}>
          <InformationIcon height="18" width="18" />
        </Tooltip>
      )}
    </div>
  );
};

export const AccordionBody = ({ selected, children, hasPadding = true }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: selected ? "1fr" : "0fr",
        transition: "all 0.5s",
        marginLeft: 4,
      }}
    >
      <div
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          padding: selected && hasPadding ? "10px 0px" : "0px 0px",
          transition: "all 0.5s",
          gap: 14,
        }}
      >
        {children}
      </div>
    </div>
  );
};
