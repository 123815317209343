import { generateLayerUpdate } from "../../components/Dashboard/CycleManager/helper";
import { currAsOfDateFieldName, showpublicFieldName } from "../../utils/API";
import {
  addLayersUpdates_actionType,
  deleteLayerUpdate_actionType,
  setCycleManagerData_actionType,
  setCycleManagerFeatures_actionType,
  setCycleManagerOpen_actionType,
  setLastCycleUpdate_actionType,
  setLayersUpToDate_actionType,
  setLayersUpdates_actionType,
  updateLayerUpdate_actionType,
} from "../constants";

export const LAYER_CYCLE_FIELDS = ["cycleupdate", "lastcycleupdate"];
export const DEPLOYMENT_STATUS_LAYER_ID = "c0748ea086b4490d92f5d7c8fe4298b6";

function addTimeToDate(dateTime, type) {
  const date = new Date(dateTime);
  switch (type) {
    case "daily":
      date.setDate(date.getDate() + 1);
      break;
    case "weekly":
      date.setDate(date.getDate() + 7);
      break;
    case "monthly":
      const day = date.getDate();
      date.setMonth(date.getMonth() + 1);
      if (date.getDate() !== day) {
        date.setDate(0);
      }
      break;
    default:
      console.log(
        `Invalid type:${type}. Please use 'daily', 'weekly', or 'monthly'.`
      );
  }

  return date.getTime();
}

export function subtractTimeFromDate(dateTime, type) {
  const date = new Date(dateTime);
  switch (type) {
    case "daily":
      date.setDate(date.getDate() - 1);
      break;
    case "weekly":
      date.setDate(date.getDate() - 7);
      break;
    case "monthly":
      const day = date.getDate();
      date.setMonth(date.getMonth() - 1);
      if (date.getDate() !== day) {
        date.setDate(0);
      }
      break;
    default:
      console.log(
        `Invalid type:${type}. Please use 'daily', 'weekly', or 'monthly'.`
      );
  }

  return date.getTime();
}

/**
 * Calculate the percentage of up-to-date features in a payload.
 *
 * @param {layersUpdates} payload - An object containing layer updates with outdated and features arrays.
 * @returns {number} The percentage of up-to-date features.
 */
export function calculateUpToDateFeaturesPercentage(payload) {
  if (typeof payload !== "object") return 0;

  let featuresCount = 0,
    outdatedCount = 0,
    errorOccurred = false;
  Object.values(payload).forEach((layerUpdate) => {
    if (!layerUpdate?.outdated || !layerUpdate?.features) {
      errorOccurred = true;
      return;
    }

    outdatedCount += layerUpdate.outdated.length;

    featuresCount += layerUpdate.features.reduce((count, feat) => {
      if (
        !(showpublicFieldName in feat.attributes) ||
        feat.attributes[showpublicFieldName] === 1
      ) {
        return count + 1;
      }

      return count;
    }, 0);
  });

  // console.log(featuresCount, outdatedCount, errorOccurred);

  if (errorOccurred) return 0;

  if (featuresCount === 0) return 100;

  return Math.floor(((featuresCount - outdatedCount) * 100) / featuresCount);
}

function calculateOverallPercentage(percentages) {
  if (percentages.length === 0) {
    return 0;
  }
  const sum = percentages.reduce((acc, percentage) => acc + percentage, 0);
  return sum / percentages.length;
}

const initialState = {
  cycleUpdateFrequency: null,
  lastCycleUpdate: null,
  cycleUpdateDueDate: null,
  allFeatures: 0,
  updatedFeatures: 0,
  layersUpToDate: [],
  layersUpdates: {}, //{Object<string, {missing: Array<Graphic>, features: Array<Graphic>}>, percent: number} payload - The object with the layerConfig id as key and,
  open: false,
  updatePercent: 0,
  deploymentStatus: null,
};

/**
 * The initial state of the cycle update process.
 * @typedef {Object} initialState
 * @property {number|null} cycleUpdateFrequency - The frequency of cycle updates.
 * @property {number|null} lastCycleUpdate - The timestamp of the last cycle update.
 * @property {number|null} cycleUpdateDueDate - The timestamp when the next cycle update is due.
 * @property {number} allFeatures - The total number of features.
 * @property {number} updatedFeatures - The number of features that have been updated.
 * @property {Array<string>} layersUpToDate - A list of layer IDs that are up-to-date.
 * @property {Object<string, {missing: Array<Graphic>, features: Array<Graphic>}>} layersUpdates - An object mapping layer IDs to their update status.
 * @property {boolean} open - Whether the update process is currently open.
 * @property {number} updatePercent - The percentage of completion for the update process.
 */

/**
 * Reduces the cycle manager state based on the given action.
 *
 * @param {initialState} state - The current state of the cycle update process.
 * @param {Object} action - The action to handle.
 * @returns {initialState} The updated state of the cycle update process.
 */
const cycleManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case setCycleManagerData_actionType: {
      const { payload } = action;

      if (
        !payload.lastCycleUpdate ||
        isNaN(new Date(payload.lastCycleUpdate).getTime())
      ) {
        payload.lastCycleUpdate = new Date();
      }

      const dueDate = addTimeToDate(
        payload.lastCycleUpdate,
        payload.cycleUpdateFrequency
      );
      return {
        ...state,
        cycleUpdateFrequency: payload.cycleUpdateFrequency,
        lastCycleUpdate: payload.lastCycleUpdate,
        cycleUpdateDueDate: dueDate,
        deploymentStatus: payload.deploymentStatus,
      };
    }
    case setCycleManagerFeatures_actionType: {
      const { allFeatures = 0, updatedFeatures = 0 } = action.payload || {};
      return {
        ...state,
        allFeatures,
        updatedFeatures,
      };
    }
    case setLayersUpToDate_actionType: {
      const { payload } = action;
      return {
        ...state,
        layersUpToDate: payload,
      };
    }
    case setLayersUpdates_actionType: {
      const { payload } = action;

      const percent = calculateUpToDateFeaturesPercentage(payload);

      return {
        ...state,
        layersUpdates: payload,
        updatePercent: percent,
      };
    }
    case addLayersUpdates_actionType: {
      const { id, layerUpdate } = action.payload || {};

      const layerPercent = calculateUpToDateFeaturesPercentage({
        [id]: layerUpdate,
      });
      const newState = {
        ...state,
        layersUpdates: {
          ...state.layersUpdates,
          [id]: {
            ...layerUpdate,
            percent: layerPercent,
          },
        },
      };

      const percent = calculateUpToDateFeaturesPercentage(
        newState.layersUpdates
      );
      return {
        ...newState,
        updatePercent: percent,
      };
    }
    case setCycleManagerOpen_actionType: {
      const { payload } = action;
      return {
        ...state,
        open: payload,
      };
    }
    case setLastCycleUpdate_actionType: {
      const { payload } = action;

      const dueDate = addTimeToDate(payload, state.cycleUpdateFrequency);
      return {
        ...state,
        lastCycleUpdate: payload,
        cycleUpdateDueDate: dueDate,
      };
    }
    case updateLayerUpdate_actionType: {
      const { id, features } = action.payload || {};
      const layerUpdate = state.layersUpdates[id];

      if (layerUpdate) {
        // Create a map of features by their objectId for quick lookup
        const featuresMap = new Map(
          features.map((feature) => [feature.getObjectId(), feature])
        );

        // Update existing features and add missing features
        const updatedFeatures = layerUpdate.features.map((feature) => {
          const newFeature = featuresMap.get(feature.getObjectId());
          if (newFeature) {
            // Update attributes
            feature.attributes[currAsOfDateFieldName] =
              newFeature.attributes[currAsOfDateFieldName];
            feature.attributes[showpublicFieldName] =
              newFeature.attributes[showpublicFieldName];
            // Remove the feature from the map to mark it as processed
            featuresMap.delete(feature.attributes.objectId);
            return newFeature;
          }
          return feature;
        });

        // Add remaining new features that were not in the original array
        featuresMap.forEach((newFeature) => {
          const layer = newFeature.sourceLayer || newFeature.layer;
          const cloned = newFeature.clone();
          cloned.attributes = {
            [layer.objectIdField]: newFeature.attributes[layer.objectIdField],
            [currAsOfDateFieldName]:
              newFeature.attributes[currAsOfDateFieldName],
            [showpublicFieldName]: newFeature.attributes[showpublicFieldName],
          };

          updatedFeatures.push(cloned);
        });

        const { lastCycleUpdate, cycleUpdateDueDate, cycleUpdateFrequency } =
          state;

        const newLayerUpdate = generateLayerUpdate({
          features: updatedFeatures,
          lastCycleUpdate,
          cycleUpdateDueDate,
          cycleUpdateFrequency,
        });

        const newState = {
          ...state,
          layersUpdates: {
            ...state.layersUpdates,
            [id]: newLayerUpdate,
          },
        };

        const percent = calculateUpToDateFeaturesPercentage(
          newState.layersUpdates
        );
        return {
          ...newState,
          updatePercent: percent,
        };
      } else {
        return state;
      }
    }
    case deleteLayerUpdate_actionType: {
      const { id, features } = action.payload || {};
      const layerUpdate = state.layersUpdates[id];

      if (layerUpdate) {
        // Create a set of feature IDs to remove
        const featureIdsToRemove = new Set(
          features.map((feature) => feature.getObjectId())
        );

        // Filter out the features from layerUpdate.features
        const updatedFeatures = layerUpdate.features.filter(
          (feature) => !featureIdsToRemove.has(feature.getObjectId())
        );

        // Filter out the features from layerUpdate.outdated
        const updatedOutdated = layerUpdate.outdated.filter(
          (feature) => !featureIdsToRemove.has(feature.getObjectId())
        );

        const layerPercent = calculateUpToDateFeaturesPercentage({
          [id]: layerUpdate,
        });

        const newState = {
          ...state,
          layersUpdates: {
            ...state.layersUpdates,
            [id]: {
              features: updatedFeatures,
              outdated: updatedOutdated,
              percent: layerPercent,
            },
          },
        };

        const percent = calculateUpToDateFeaturesPercentage(
          newState.layersUpdates
        );
        return {
          ...newState,
          updatePercent: percent,
        };
      }
    }
  }

  return state;
};

export default cycleManagerReducer;
