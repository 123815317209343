import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const PortalWrapper = ({ updatePosition, children, wrapperStyle }) => {
  const [positions, setPositions] = useState({ top: 0, left: 0, width: 0 });
  const [parentReady, setParentReady] = useState(false);
  const parentRef = useRef();
  const observerRef = useRef(null);

  useEffect(() => {
    const updatePositions = () => {
      if (!updatePosition) return;
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect();

        setPositions({
          top: rect.top,
          left: rect.left,
          width: rect.width,
        });
      }
    };

    let resizeTimeout;
    const handleResize = () => {
      if (resizeTimeout) {
        cancelAnimationFrame(resizeTimeout);
      }
      resizeTimeout = requestAnimationFrame(updatePositions);
    };

    // Create and setup ResizeObserver for parent element
    // observerRef.current = new ResizeObserver(updatePositions);
    // const parentElement = parentRef.current?.parentElement;
    // if (parentElement) {
    //   observerRef.current.observe(parentElement);
    // }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [updatePosition]);

  useEffect(() => {
    if (parentReady && parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setPositions({
        top: rect.top,
        left: rect.left,
        width: rect.width, // Add width to positions
      });
    }
  }, [parentReady]);

  const withNode = useCallback((el) => {
    if (!el) return;

    parentRef.current = el;
    setParentReady(true);
  }, []);

  return (
    <div style={wrapperStyle} ref={withNode}>
      {createPortal(
        <div
          style={{
            position: "fixed",
            top: positions.top,
            left: positions.left,
            width: positions.width,
            zIndex: 10,
          }}
        >
          {children}
        </div>,
        document.body
      )}
    </div>
  );
};

export default PortalWrapper;
