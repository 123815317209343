import React, { useContext, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import EditorSituationalFields from "../../Editor/EditorFields/EditorSituationalFields";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { EditorContext, UpdateType } from "../../Editor/EditorContextProvider";
import BatchFieldsHeader from "../../Editor/EditorFields/BatchFieldsHeader";
import useEditor from "../../../../hooks/useEditor";
import { EditType } from "../../Editor/EditorSwiper/EditorSwiper";
import Loader from "../../Editor/Loader/Loader";
import { MonitoringBatchUpdateWrapper } from "../CycleManager-styled";
import { CloseIcon, SaveIcon } from "../../../Icons";
import { StyledFiltersBackButton } from "../../../MobileApp/MobileFilter/MobileFilter-styled";
import SnackbarProvider from "../../../SnackBar";
import { ConfirmButton } from "../../Editor/EditorSwiper/Footer";
import { useTranslation } from "react-i18next";
import Message from "../../Editor/Message";

const CycleManagerBatchUpdate = ({
  handlePrev,
  onSave,
  openSnackbar,
  isCheckList,
}) => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const [requiredFieldsMessage, setRequiredFieldsMessage] = useState({
    show: false,
    fields: [],
  });
  const {
    batchUpdateFeatures,
    editableLayer,
    setUpdateType,
    situationalUpdateType,
    updateType,
  } = useContext(EditorContext);
  const { doEdit, loading, methods } = useEditor({
    editType: EditType.edit,
    config,
    onSave: ({ updateFeatures: newFeatures, actionType }) => {
      handlePrev();
      if (onSave) {
        onSave(newFeatures);
      }
    },
    openSnackbar,
  });
  const {
    formState: { isValid, isDirty, errors },
    handleSubmit,
    getValues,
    trigger,
  } = methods;

  useEffect(() => {
    setUpdateType(UpdateType.situational);
  }, []);

  const handleConfirmClick = async (data) => {
    const isValid = await trigger();
    const currentErrors = methods.formState.errors; // Access the errors after triggering validation

    if (isValid) {
      handleSubmit(doEdit)(data);
    } else {
      setRequiredFieldsMessage({
        show: true,
        fields: Object.keys(currentErrors).map((fieldName) =>
          t("layer.fieldAlias." + fieldName + ".title", fieldName)
        ),
      });
      setTimeout(() => {
        setRequiredFieldsMessage({
          show: false,
          fields: [],
        });
      }, 3000);
    }
  };

  return (
    <FormProvider {...methods}>
      <SnackbarProvider>
        <MonitoringBatchUpdateWrapper>
          {loading && <Loader position="absolute" bg="#eeeeeed4" />}
          {requiredFieldsMessage.show && (
            <Message
              title={t("cycleManager.saveMessages.missingFields", { 1: "" })}
              subtitle={
                <span
                  style={{ fontWeight: 400, fontSize: "inherit", color: "red" }}
                >
                  {requiredFieldsMessage.fields.join(", ")}
                </span>
              }
            />
          )}
          <div
            style={{
              display: "flex",
              gap: 4,
              padding: "0px 10px 10px 10px",
            }}
          >
            <BatchFieldsHeader
              editableLayer={editableLayer}
              features={batchUpdateFeatures}
              config={config}
              activeColor={config.opsColor}
              style={{
                padding: 0,
                flex: 1,
              }}
            />
            <StyledFiltersBackButton
              onClick={handlePrev}
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </StyledFiltersBackButton>
          </div>
          <EditorSituationalFields
            isCheckList={isCheckList}
            updateType={updateType}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "auto",
              padding: 8,
            }}
          >
            <ConfirmButton
              count={batchUpdateFeatures.length}
              color={config.opsColor}
              onClick={handleConfirmClick}
              wrapperStyles={{ marginLeft: "auto " }}
              title={
                isDirty ? (
                  <SaveIcon width="14" height="14" fill={config.opsColor} />
                ) : (
                  ""
                )
              }
              showConfirmTitle
            />
          </div>
        </MonitoringBatchUpdateWrapper>
      </SnackbarProvider>
    </FormProvider>
  );
};

export default CycleManagerBatchUpdate;
