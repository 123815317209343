import { combineReducers } from "redux";

import additionalComment from "./additionalComment";
import geometry from "./geometry";
import knowledgeOfRehabilitation from "./knowledgeOfRehabilitation";
import subCategory from "./subCategory";
import typeOfVehicle from "./typeOfVehicle";
import currentScreen from "./currentScreen";
import filters from "./filters";
import activeModule from "./activeModule";
import panelFormData from "./panelFormData";
import panelPosition from "./panelPosition";
import panelPath from "./panelPath";
import panelPageIndex from "./panelPageIndex";
import panelIsSurvey from "./panelIsSurvey";
import panelSurveyIsValid from "./panelSurveyIsValid";
import panelId from "./panelId";
import clusterFeature from "./clusterFeature";
import layersLoading from "./layersLoading";
import printWidget from "./printWidget";
import showLatestUpdates from "./latestUpdates";
import legendWidget from "./legendWidget";
import dashboard from "./dashboard";
import mobileReducer from "./mobileReducer";
import mapViewReducer from "./mapViewReducer";
import cycleManagerReducer from "./cycleManager";
import featureTableReducer from "./featureTable";

const rootReducer = combineReducers({
  additionalComment,
  geometry,
  knowledgeOfRehabilitation,
  subCategory,
  typeOfVehicle,
  currentScreen,
  filters,
  activeModule,
  panelFormData,
  panelPosition,
  panelPath,
  panelPageIndex,
  panelIsSurvey,
  panelSurveyIsValid,
  panelId,
  clusterFeature,
  layersLoading,
  printWidget,
  showLatestUpdates,
  legendWidget,
  dashboard,
  mobileReducer,
  mapViewReducer,
  cycleManagerReducer,
  featureTableReducer,
});

export default rootReducer;
