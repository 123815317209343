import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  StyledField,
  StyledFieldSection,
  StyledFieldSectionWrapper,
} from "../../MobilePopup-styled";
import FeatureDetailsTab from "../../FeatureDetailsTab";
import Documents from "../../Documents";
import UpdateComment from "../../../../LatestUpdates/new/UpdateComment";
import PopupField from "./PopupField";

const ArrowIcon = ({ ...props }) => (
  <svg
    stroke="currentColor"
    fill="#9d9b9b"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="m112 184 144 144 144-144"
    ></path>
  </svg>
);

const FieldsSection = ({
  fields,
  show,
  children,
  title,
  collapsable = true,
  files = [],
  config,
  setFullScreenActive,
  resetFields,
  isPopup,
  hasLca,
  i18n,
  t,
  feature,
}) => {
  const [expand, setExpand] = useState(show);

  useEffect(() => {
    if (show) {
      setExpand(true);
    }
  }, [show]);

  const toggleFields = useCallback(() => {
    setExpand((prev) => !prev);
  }, []);

  const isArabic = useMemo(() => {
    return i18n.language === "ar";
  }, []);

  return (
    <StyledFieldSection hasTitle={!!title} hasLca={hasLca}>
      {title && (
        <StyledFieldSectionWrapper
          isPopup={isPopup}
          onClick={collapsable ? toggleFields : undefined}
        >
          <span>{title}</span>
          {children?.length > 0 && (
            <>
              <div
                style={{
                  borderRadius: 100,
                  width: 4,
                  height: 4,
                  background: "#9d9b9b",
                }}
              />
              {children}
            </>
          )}
          {collapsable && (
            <ArrowIcon
              fill="#6c6b6b"
              style={{
                transform: expand ? "rotate(180deg)" : "rotate(360deg)",
                transition: "all 0.3s",
              }}
            />
          )}
        </StyledFieldSectionWrapper>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateRows: expand ? "1fr" : "0fr",
          transition: "all 0.5s",
          gap: 4,
        }}
      >
        <div
          style={{
            overflow: "hidden",
            paddingTop: !!title && expand ? "4px" : 0,
            display: "flex",
            flexDirection: "column",
            gap: 8,
            transition: "all 0.3s",
          }}
        >
          {fields.map((field) => (
            <PopupField
              isPopup={isPopup}
              key={field.title}
              title={field.title}
              value={field.value}
              resetLimit={resetFields}
              onClick={() => {
                setFullScreenActive(true);
              }}
              feature={feature}
              field={field}
              i18n={i18n}
              t={t}
              isExpanded={resetFields}
              fromPopup
            />
          ))}
        </div>
      </div>
      {files.length > 0 && <Documents color={config?.opsColor} files={files} />}
    </StyledFieldSection>
  );
};

export default FieldsSection;
