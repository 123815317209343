import {
  setErrorLayers_actionType,
  setMapView_actionType,
  setOperationLayers_actionType,
  updateOperationLayer_actionType,
  addOperationLayer_actionType,
} from "../constants";

export const setMapView = (mapView) => ({
  type: setMapView_actionType,
  payload: mapView,
});

/**
 * Action creator for setting operation layers.
 *
 * @param {Array<LayerData>} payload - An array of objects representing the operation layers.
 * @param {string} LayerData.title - The title of the operation layer.
 * @param {string} LayerData.alias - The alias of the operation layer.
 * @param {string} LayerData.id - The id of the operation layer.
 * @param {string} LayerData.state - The state of the operation layer.
 * @returns {Object} The action object with type and payload.
 */
export const setOperationLayers = (payload) => ({
  type: setOperationLayers_actionType,
  payload: payload,
});

export const setErrorLayers = (payload) => ({
  type: setErrorLayers_actionType,
  payload: payload,
});

export const updateOperationLayer = (payload) => ({
  type: updateOperationLayer_actionType,
  payload: payload,
});

export const addOperationLayer = (payload) => ({
  type: addOperationLayer_actionType,
  payload: payload,
});
