import { useGridFilter } from "ag-grid-react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { isYesNoDomain, renderSingleDomain } from "../../../../utils/helper";
import { getLayerSymbology } from "../../../../utils/symbologies";
import { FilterLabel } from "../../../SharedInformation/DropdownFilter/DropdownFilter-styled";

const DomainFilter = ({ field, layer, ...params }) => {
  const { onModelChange, model } = params;

  const [closeFilter, setCloseFilter] = useState();
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  //filtering the table using this function
  const doesFilterPass = useCallback(
    (p) => {
      const code = p.data[field.name];

      return Array.isArray(model) ? model.includes(code) : true;
    },
    [model]
  );
  const afterGuiAttached = useCallback(({ hidePopup }) => {
    setCloseFilter(() => hidePopup);
  }, []);

  // register filter handlers with the grid
  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  const options = useMemo(() => {
    if (isYesNoDomain(field, layer)) {
      const symbology = getLayerSymbology(layer, config);
      const { colorMap = {} } = symbology || {};
      let colors = {};

      if (
        Array.isArray(colorMap.fields) &&
        colorMap.fields.includes(field.name)
      ) {
        colors = colorMap.default;
      } else if (colorMap.field === field.name) {
        colors = colorMap.default;
      }

      const options = field.domain.codedValues.map((item) => ({
        code: item.code,
        name: renderSingleDomain(field, item.code, t),
        color: colors[item.code],
      }));

      if (field.nullable) {
        options.push({
          code: "NULL",
          name: t("screen.domain.unspecified", "Unspecified"),
          color: colors["NULL"],
        });
      }

      return options;
    } else {
      const options = [1, 2].map((code) => ({
        code: code,
        name: renderSingleDomain(field, code, t),
      }));
      return options;
    }
  }, [field, layer, config, t]);

  const onChange = useCallback(
    (e, code) => {
      const modelArray = Array.isArray(model) ? model : [];
      let newModel = [];
      if (modelArray.includes(code)) {
        newModel = modelArray.filter((item) => item !== code);
      } else {
        newModel = modelArray.concat(code);
      }

      if (onModelChange) {
        if (newModel.length > 0) {
          onModelChange(newModel);
        } else {
          onModelChange(null);
        }
      }
    },
    [onModelChange, closeFilter, model]
  );

  return (
    <div
      style={{
        display: "flex",
        gap: 6,
        flexDirection: "column",
        padding: 8,
        borderRadius: 8,
      }}
    >
      {options.map((option) => (
        <FilterLabel key={option.code}>
          <input
            name={option.name}
            type="checkbox"
            onChange={(e) => onChange(e, option.code)}
            // checked={selectedFilters.includes(option.code)}
          />
          {/*{option.color && <Tag bg={option.color} status={option.code}/>}*/}
          <p>{option.name}</p>
        </FilterLabel>
      ))}
    </div>
  );
};

export default DomainFilter;
