import styled from "styled-components";

export const Sidebar = styled.div`
  transition: all 0.5s;
  display: flex;
  flex-direction: row;
  position: relative;
  flex-basis: ${({ show, defaultWidth = 284, expand }) =>
    show ? (!expand ? "0px" : `${defaultWidth}px`) : "0px"};
  /* overflow: ${({ newEditorEnabled }) =>
    newEditorEnabled ? "" : "hidden"}; */
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "all" : "none")};
  border-left: 1px solid rgba(169, 169, 169, 0.49); /* Create space for the gradient border */

  @media screen and (max-width: 768px) {
    position: absolute;
    flex-direction: column;
    width: 100%;
    top: ${({ expand }) => (expand ? `4px` : `calc(100% - 38px)`)};
    height: ${({ expand }) => (expand ? `calc(100% - 4px)` : `38px`)};
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0 -0.01rem 0rgba (169, 169, 169, 0.79), 0 -1px 2px #ccc;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const SidebarBody = styled.div`
  position: relative;
  height: 100%;
  width: ${({ expand }) => (expand ? "calc(100% - 0px)" : "calc(100% - 20px)")};
  transition: all 0.3s;
  padding-top: ${({ hasTopWidgets }) => (hasTopWidgets ? "62px" : "")};
  --calcite-color-brand: var(--opsColor);
  --calcite-color-brand-hover: transparent;
  box-sizing: border-box;

  calcite-tabs {
    height: ${({ hasTopWidgets }) =>
      hasTopWidgets ? "calc(100% - 61px) !important" : ""};
  }import { StyledResizerButton } from './LatestUpdates-styled';


  calcite-tab-title {
    margin: auto;
    span {
      color: #393738;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: ${({ expand }) => (expand ? "100%" : "0px")};
    transition-property: height;
    transition-delay: ${({ expand }) => (expand ? "0s" : "0.2s")};
    overflow: hidden;
  }
`;

export const LatestUpdatesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  user-select: none;
  transition: all 0.5s;
`;

export const UpdateHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 6px ${({ showBorder }) => (showBorder ? "8px" : "0px")} 6px;
  border-bottom: ${({ showBorder }) => (showBorder ? "1px solid #ededed" : "")};
  position: relative;

  @media screen and (max-width: 769px) {
    padding-top: 0;
  }
`;

export const UpdateHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 14px;
  //justify-content: center;
  margin: auto;

  p {
    margin: 0;
    color: #393738;
    line-height: 1;
    font-weight: 500;
    font-size: 20px;
    text-wrap: nowrap;
  }
  span {
    text-wrap: nowrap;
  }

  @media screen and (max-width: 768px) {
    display: none;
    padding-top: 0;
  }
`;

export const UpdatesCardWrapper = styled.div`
  min-width: 224px;
  padding: 8px 0 8px 0;
  border-bottom: 1px solid #ececec;
  //border-left: 3px solid transparent;
  //border-radius: 3px;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 6px;
  transition: all 0.5s;
  cursor: pointer;

  :hover {
    background: #efefef;
  }
`;

export const UpdatesTime = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0;

  img {
    background-size: 100%;
    width: 34px;
    height: 34px;
    vertical-align: middle;
  }
  span {
    //color: #999;
    font-weight: 600;
  }
`;

export const UpdatesTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export const Comment = styled.span`
  position: relative;
  font-size: 12px;
  color: #000;
  margin: 0;
  transition: all 0.5s;
  font-style: italic;

  .more {
    color: #999;
    font-weight: 500;
    display: inline-block;
    padding: 0 2px;
  }
`;

export const UpdateMediaWrapper = styled.div`
  img {
    width: 100%;
  }
`;

export const UpdateDate = styled.span`
  font-size: 10px;
  color: #999;
  font-weight: 500;
  display: inline-block;
  text-wrap: nowrap;
  line-height: normal;
`;

export const StyledResizer = styled.div`
  background: transparent;
  left: 0px;
  position: absolute;
  z-index: 2;
  top: 0px;
  width: 8px;

  height: 100%;
  cursor: col-resize;
  /* overflow: hidden; */
  //width: ${({ expand }) => (expand ? "8px" : "20px")};
  /* width: 20px; */
  display: flex;
  align-items: center;
  /* background: #dcdbdb80; */

  .expandButton {
    display: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 35px;
    cursor: default;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0 4px;

    .expandButton {
      display: block;
      transition: all 0.5s;
      transform: ${({ expand }) =>
        expand ? "rotate(180deg)" : "rotate(0deg)"};
    }

    .expandButton:hover {
      background: #f0f0f0;
    }
  }

  &:hover {
    /* opacity: 1;
    transition: all 0.5s;
    box-shadow: -0.04rem 0 0 #a9a9a9, -3px 0 0 #cccccc4d; */
  }
`;
export const StyledResizerWrapper = styled.div`
  position: absolute;
  left: 6px;
  top: 50%;
  width: 1px;
  height: 56px;
  z-index: 2;
  transform: translateY(-50%);
  @media (max-height: 400px) {
    display: none;
  }
`;

export const StyledResizerButton = styled.div`
  display: flex;
  position: absolute;
  right: 7px;
  background: #fff;
  height: 56px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: -5px 0 5px -5px #a9a9a9, 0 -5px 5px -5px #a9a9a9,
    0 5px 5px -5px #a9a9a9;
`;

export const ResizerIcon = styled.svg`
  cursor: pointer;
  transition: all 0.5s;
  //margin-right: 2px;
  //position:absolute;
  //top: 2px;
  //z-index:0;
  transform: ${({ expand }) => (expand ? "rotate(0deg)" : "rotate(180deg)")};
  // left: ${({ expand }) => (expand ? "-3px" : "-2px")};
  position: absolute;

  @media screen and (max-width: 768px) {
    display: none;
    position: static;
    transform: ${({ expand }) => (expand ? "rotate(90deg)" : "rotate(270deg)")};
  }
`;

export const UpdateIconWrapper = styled.div`
  transition: all 0.3s;
  left: 2px;
  top: 6px;
  position: absolute;
  cursor: default;
  opacity: ${({ expand }) => (expand ? 0 : 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
    display: none;
    font-size: 10px;
  }

  @media screen and (max-width: 768px) {
    position: static;
    opacity: 1;
    flex-direction: row;
    align-items: center;
    span {
      display: inline-block;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #031c2d;
    }
  }
`;
