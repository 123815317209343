import { loadModules } from "esri-loader";
import { useCallback, useContext, useEffect } from "react";
import { view } from "../../../../utils/API";
import { EditorContext } from "../EditorContextProvider";
import {
  EditType,
  fillSymbol,
  LAYER_EFFECT,
} from "../EditorSwiper/EditorSwiper";
import { addLayerEffect } from "../helpers";
import { selectionLineSymbol } from "./RoadsCoordinates";

const SketchCoordinates = ({
  editableLayer,
  clickActive,
  editType,
  handleNext,
  disabled,
}) => {
  const {
    highlightFeature,
    setAddedPoints,
    setSketchGraphicsLayer,
    sketchGraphicsLayer,
    sketchViewModel,
    setSketchViewModel,
  } = useContext(EditorContext);

  useEffect(() => {
    // if (sketchViewModel && sketchGraphicsLayer && editType === EditType.edit) {
    //   activateCreateTool();
    // }

    if (editType === EditType.create) {
      editableLayer.effect = LAYER_EFFECT;
    } else {
      // editableLayer.effect = LAYER_EFFECT;
    }
  }, [editType]);

  useEffect(() => {
    if (editableLayer.geometryType === "point" || sketchViewModel) return;
    loadModules([
      "esri/widgets/Sketch/SketchViewModel",
      "esri/layers/GraphicsLayer",
      "esri/geometry/geometryEngineAsync",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/Polyline",
    ]).then(([SketchViewModel, GraphicsLayer]) => {
      const graphicsLayer = new GraphicsLayer();
      view.map.add(graphicsLayer);

      // create a new sketch view model set its layer
      const svm = new SketchViewModel({
        view: view,
        layer: graphicsLayer,
        defaultUpdateOptions: {
          tool: "reshape",
          enableRotation: false,
          enableScaling: false,
          enableZ: false,
          reshapeOptions: {
            shapeOperation: "none",
          },
        },
        polylineSymbol: {
          type: "simple-line",
          color: "#0091ea47",
          width: "2px",
        },

        polygonSymbol: {
          type: "simple-fill",
          color: "#0091ea47",
          style: "solid",
          outline: {
            color: "#0091ea",
            width: 1,
          },
        },
      });
      addLayerEffect([graphicsLayer]);
      setSketchGraphicsLayer(graphicsLayer);
      setSketchViewModel(svm);
    });
  }, []);

  const activateCreateTool = useCallback(() => {
    if (!clickActive) return;

    // if (editType === EditType.create) {

    // }
    sketchViewModel.create(editableLayer.geometryType);
  }, [
    editType,
    editableLayer,
    clickActive,
    sketchGraphicsLayer,
    sketchViewModel,
  ]);

  useEffect(() => {
    const listeners = [];
    let timer;

    if (!sketchViewModel || !sketchGraphicsLayer) return;

    loadModules([
      "esri/geometry/geometryEngineAsync",
      "esri/geometry/support/webMercatorUtils",
    ]).then(([geometryEngineAsync]) => {
      // if (editType === EditType.create) {

      // }

      activateCreateTool();

      try {
        if (clickActive && sketchGraphicsLayer.graphics.length !== 0) {
          sketchGraphicsLayer.removeAll();
        } else if (
          editType === EditType.create &&
          !clickActive &&
          sketchGraphicsLayer.graphics.length > 0
        ) {
          sketchViewModel.update(sketchGraphicsLayer.graphics.toArray(), {
            tool: "reshape",
          });
        }
      } catch (err) {
        console.log(err);
      }

      sketchViewModel.updateOnGraphicClick = !clickActive && !disabled;
      // if (!clickActive) {
      //   const graphics = sketchGraphicsLayer.graphics.toArray()[0];
      //   sketchViewModel.update(graphics, "reshape");
      // } else {
      //   graphicsLayer.removeAll();
      //   sketchGraphicsLayer?.removeAll();
      // }

      const createHandle = sketchViewModel.on("create", async (event) => {
        if (event.state === "complete") {
          const { graphic } = event;

          // editableLayer.effect = LAYER_EFFECT;
          if (graphic && graphic.geometry) {
            setAddedPoints([graphic.geometry]);
          }

          if (clickActive) {
            handleNext();
          }
        }
      });
      listeners.push(createHandle);

      const updateHandle = sketchViewModel.on("update", async (event) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          const { graphics } = event;

          if (graphics.length > 0 && event.state !== "start") {
            setAddedPoints([graphics[0].geometry]);
          }
        }, 250);
      });

      listeners.push(updateHandle);
    });

    return () => {
      sketchViewModel?.cancel();
      clearTimeout(timer);
      listeners.forEach((handle) => {
        handle.remove();
      });
    };
  }, [sketchViewModel]);

  useEffect(() => {
    if (!sketchViewModel) {
      return;
    }

    if (sketchGraphicsLayer) {
      sketchGraphicsLayer.effect = undefined;
    }

    //creating shapes for editing
    if (
      highlightFeature &&
      editType === EditType.edit &&
      !!sketchGraphicsLayer
    ) {
      sketchGraphicsLayer.removeAll();
      const geomType = editableLayer.geometryType;
      let graphic;
      if (geomType === "polyline") {
        const polyline = {
          type: "polyline",
          paths: highlightFeature.geometry.paths,
          spatialReference: highlightFeature.geometry.spatialReference,
        };

        const roadSymbol = {
          ...selectionLineSymbol,
          width: 2,
        };

        graphic = {
          geometry: polyline,
          symbol: highlightFeature.symbol ?? roadSymbol,
        };
      } else if (geomType === "polygon") {
        const polygon = {
          type: "polygon",
          rings: highlightFeature.geometry.rings,
          spatialReference: highlightFeature.geometry.spatialReference,
        };
        graphic = {
          geometry: polygon,
          symbol: highlightFeature.symbol ?? fillSymbol,
        };
      }

      if (graphic) {
        // sketchGraphicsLayer.effect = undefined;
        graphic.attributes = highlightFeature.attributes;
        if (sketchGraphicsLayer.graphics.length === 0) {
          sketchGraphicsLayer.add(graphic);
        }
      }
    }
  }, [sketchGraphicsLayer, sketchViewModel, highlightFeature]);

  useEffect(() => {
    let handler;

    handler = view.on("click", (event) => {
      event.stopPropagation();
    });

    if (!sketchViewModel || !sketchGraphicsLayer) return;
    if (!(highlightFeature && editType === EditType.edit)) return;

    if (disabled) {
      // Cancel any ongoing sketching operation
      sketchViewModel.cancel();
      // Disable user interaction with the sketch
      sketchViewModel.updateOnGraphicClick = false;
      sketchViewModel.isEnabled = false;
      // handler = view.on("click", (event) => {
      //   event.stopPropagation();
      // });
    } else {
      // Re-enable the sketch
      sketchViewModel.isEnabled = true;
      sketchViewModel.updateOnGraphicClick = true;

      try {
        if (sketchViewModel) {
          sketchViewModel.update(sketchGraphicsLayer.graphics.toArray(), {
            tool: "reshape",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    return () => {
      if (handler) {
        handler.remove();
      }
    };
  }, [disabled, sketchViewModel, sketchGraphicsLayer]);

  return <div />;
};

export default SketchCoordinates;
