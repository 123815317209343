import { loadModules } from "esri-loader";
import { graphicsLayer, initialExtent, view } from "../../../utils/API";
import { getFieldsByPattern } from "../../../utils/helper";
import { getLayerSymbology } from "../../../utils/symbologies";
import { getLineSymbol } from "../../BatchEditor/Symbols";
import { selectionLineSymbol } from "./CoordinatesSelection/RoadsCoordinates";
import { LAYER_EFFECT, pointSymbol } from "./EditorSwiper/EditorSwiper";

const concatFields = ["roadnameen", "osmid", "fclass"];

function calculateSegmentDirection(geometry) {
  if (!geometry.paths || !geometry.paths[0] || geometry.paths[0].length < 2) {
    return 0;
  }

  const path = geometry.paths[0];
  const start = path[0];
  const end = path[path.length - 1];

  // Calculate angle in degrees
  return (Math.atan2(end[1] - start[1], end[0] - start[0]) * 180) / Math.PI;
}

function calculateAngleDifference(angle1, angle2) {
  // Normalize both angles to 0-180 range (we don't care about direction)
  angle1 = (angle1 + 180) % 180;
  angle2 = (angle2 + 180) % 180;

  // Find the minimum difference
  let diff = Math.abs(angle1 - angle2);
  if (diff > 90) diff = 180 - diff;

  return diff;
}

// Replace filterIntersectionSegments with this improved version
export const filterIntersectionSegments = (
  features,
  bufferNumber,
  selectionPolygon,
  originalPolyline, // Add original polyline parameter
  { geometryEngine }
) => {
  if (!features || features.length < 2) return features;

  return features.filter((feature) => {
    // Calculate length
    const length = geometryEngine.geodesicLength(feature.geometry, "meters");

    // Always keep segments longer than the buffer size
    if (length > bufferNumber) return true;

    // For small segments, we need more careful analysis
    try {
      // 1. Find where this segment intersects with the selection polygon
      const intersection = geometryEngine.intersect(
        feature.geometry,
        selectionPolygon
      );

      if (!intersection) return false;

      // 2. Get the nearest point on the original polyline to this intersection
      const nearestVertex = findNearestPoint(
        intersection,
        originalPolyline,
        geometryEngine
      );

      if (!nearestVertex) return true; // Keep if we can't determine

      // 3. Get the local direction of the original polyline at this point
      const localPolylineDirection = calculateLocalDirection(
        originalPolyline,
        nearestVertex,
        geometryEngine
      );

      // 4. Compare with segment direction
      const segmentDirection = calculateSegmentDirection(feature.geometry);
      const angleDifference = calculateAngleDifference(
        localPolylineDirection,
        segmentDirection
      );

      // Keep if angle difference is small (segment aligns with local road direction)
      return angleDifference < 35; // Adjust tolerance as needed
    } catch (err) {
      console.warn("Error analyzing segment direction:", err);
      return true; // Keep on error (safer)
    }
  });
};

// Helper function to find the nearest point on a polyline to a given point
function findNearestPoint(point, polyline, geometryEngine) {
  try {
    if (!point || !polyline || !polyline.paths || polyline.paths.length === 0) {
      return null;
    }

    // Convert point to proper Point object if needed
    let pointGeom = point;
    if (point.type === "point") {
      pointGeom = {
        x: point.x,
        y: point.y,
        spatialReference: point.spatialReference,
      };
    }

    let minDistance = Infinity;
    let nearestPoint = null;
    let segmentIndex = 0;

    // For each path in the polyline
    polyline.paths.forEach((path) => {
      // For each segment in the path
      for (let i = 0; i < path.length - 1; i++) {
        const p1 = path[i];
        const p2 = path[i + 1];

        // Create a segment
        const segment = {
          type: "polyline",
          paths: [[p1, p2]],
          spatialReference: polyline.spatialReference,
        };

        // Find the nearest point on this segment
        try {
          const nearPoint = geometryEngine.nearestCoordinate(
            segment,
            pointGeom
          );

          if (
            nearPoint &&
            nearPoint.coordinate &&
            nearPoint.distance < minDistance
          ) {
            minDistance = nearPoint.distance;
            nearestPoint = {
              x: nearPoint.coordinate.x,
              y: nearPoint.coordinate.y,
              spatialReference: segment.spatialReference,
              segmentIndex: i, // Store segment index for later
            };
          }
        } catch (e) {
          console.log(`Error with segment ${i}:`, e);
        }
      }
    });

    return nearestPoint;
  } catch (err) {
    console.error("Error finding nearest point:", err);
    return null;
  }
}

// Calculate direction at a specific location on the polyline
function calculateLocalDirection(polyline, point, geometryEngine) {
  try {
    if (!point.segmentIndex || !polyline.paths || polyline.paths.length === 0) {
      return calculateSegmentDirection(polyline);
    }

    // Get the path containing the point
    const path = polyline.paths[0];
    const segmentIndex = point.segmentIndex;

    // If we have enough points, use points before and after for better direction
    let startIdx = Math.max(0, segmentIndex - 1);
    let endIdx = Math.min(path.length - 1, segmentIndex + 2);

    const start = path[startIdx];
    const end = path[endIdx];

    // Calculate direction from these points
    return (Math.atan2(end[1] - start[1], end[0] - start[0]) * 180) / Math.PI;
  } catch (err) {
    console.error("Error calculating local direction:", err);
    // Fall back to overall segment direction
    return calculateSegmentDirection(polyline);
  }
}

/**
 * Clean up road segments at intersections
 * @param {Array} features - Array of cut road features
 * @param {Number} bufferNumber - Buffer size in meters
 * @param {Object} options - Required ESRI modules
 * @returns {Array} Cleaned features without small intersection segments
 */
export const cleanIntersections = async (
  features,
  bufferNumber,
  { geometryEngine, geometryEngineAsync, Polyline }
) => {
  if (!features || features.length < 2) return features;

  try {
    // Use a smaller buffer for intersections (1/4 of the main buffer)
    const intersectionBufferSize = Math.max(2, bufferNumber / 4);

    // Find intersection points between all segments
    const intersections = [];

    for (let i = 0; i < features.length; i++) {
      for (let j = i + 1; j < features.length; j++) {
        if (!features[i].geometry?.paths || !features[j].geometry?.paths)
          continue;

        // Find intersections between these features
        const intersection = geometryEngine.intersect(
          features[i].geometry,
          features[j].geometry
        );

        if (intersection) intersections.push(intersection);
      }
    }

    if (intersections.length === 0) return features;

    // Create buffers around all intersection points
    const buffers = await Promise.all(
      intersections.map((point) =>
        geometryEngineAsync.geodesicBuffer(
          point,
          intersectionBufferSize,
          "meters"
        )
      )
    );

    // Union all buffers into one polygon
    const intersectionBuffer = geometryEngine.union(buffers);
    if (!intersectionBuffer) return features;

    // Filter out segments that are too short or mostly inside intersection zones
    const minLength = Math.max(3, bufferNumber / 3); // Minimum segment length to keep

    return features.filter((feature) => {
      if (!feature.geometry?.paths) return false;

      // Check segment length
      const length = geometryEngine.geodesicLength(feature.geometry, "meters");
      if (length < minLength) return false;

      // Check how much is outside intersection areas
      const difference = geometryEngine.difference(
        feature.geometry,
        intersectionBuffer
      );
      if (!difference) return false; // Completely within intersection

      const diffLength = geometryEngine.geodesicLength(difference, "meters");
      return diffLength / length > 0.5; // Keep if more than 50% outside intersections
    });
  } catch (err) {
    console.error("Error cleaning intersections:", err);
    return features; // Return original features if error occurs
  }
};

export const calculatePixelWidthInMeters = async (view) => {
  try {
    // Load required modules
    const [webMercatorUtils, geometryEngine, Polyline] = await loadModules([
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/geometryEngine",
      "esri/geometry/Polyline",
    ]);

    // Get the center point of the current view
    const center = view.center;

    // Convert the center point to screen coordinates
    const screenPoint = view.toScreen(center);

    // Create a point 1 pixel to the right
    const screenPoint1PixelRight = {
      x: screenPoint.x + 1,
      y: screenPoint.y,
    };

    // Convert back to map coordinates
    const mapPoint1PixelRight = view.toMap(screenPoint1PixelRight);

    // If in web mercator, convert both points to geographic
    let point1 = center;
    let point2 = mapPoint1PixelRight;

    if (!center.spatialReference.isGeographic) {
      point1 = webMercatorUtils.webMercatorToGeographic(center);
      point2 = webMercatorUtils.webMercatorToGeographic(mapPoint1PixelRight);
    }

    // Create a polyline from the two points
    const polyline = new Polyline({
      paths: [
        [
          [point1.x, point1.y],
          [point2.x, point2.y],
        ],
      ],
      spatialReference: { wkid: 4326 }, // Use geographic coordinates (WGS84)
    });

    // Calculate geodesic length in meters
    const distanceInMeters = geometryEngine.geodesicLength(polyline, "meters");

    return distanceInMeters;
  } catch (error) {
    console.log("Error calculating pixel width in meters:", error);
  }
};

export const calculateDistanceFromPixels = (tolerance, map, Point, Extent) => {
  let screenPoint = map.toScreen(map.extent.center);

  let upperLeftScreenPoint = new Point(
    screenPoint.x - tolerance,
    screenPoint.y - tolerance
  );
  let lowerRightScreenPoint = new Point(
    screenPoint.x + tolerance,
    screenPoint.y + tolerance
  );

  let upperLeftMapPoint = map.toMap(upperLeftScreenPoint);
  let lowerRightMapPoint = map.toMap(lowerRightScreenPoint);

  let ext = new Extent(
    upperLeftMapPoint.x,
    upperLeftMapPoint.y,
    lowerRightMapPoint.x,
    lowerRightMapPoint.y,
    map.spatialReference
  );
  return ext.width;
};

const roadFragmentInSelectionSymbol = getLineSymbol("#00FF00", 1);
export const cutFeaturesAsync = async (
  features,
  selectionPolygon,
  { geometryEngineAsync, Polyline, Point, Graphic, setCount }
) => {
  const containing = [];
  const notContaining = [];
  let count = 0;
  // First separate fully contained features
  for (const feat of features) {
    const contains = await geometryEngineAsync.contains(
      selectionPolygon,
      feat.geometry
    );
    if (contains) {
      containing.push(feat);
      count++;
    } else {
      notContaining.push(feat);
    }
  }

  setCount(`${Math.round((count * 100) / features.length)}%`);

  // Process features that intersect but aren't fully contained using cut operation
  const processedFeatures = await Promise.all(
    notContaining.map(async (feature) => {
      try {
        // Convert polygon boundary to polyline for cutting
        const polygonRing = selectionPolygon.rings
          ? selectionPolygon.rings[0]
          : [];
        if (!polygonRing || polygonRing.length < 3) return null;

        const boundaryPolyline = new Polyline({
          paths: [polygonRing],
          spatialReference: selectionPolygon.spatialReference,
        });

        // Cut the feature with the boundary polyline
        const cutResults = await geometryEngineAsync.cut(
          feature.geometry,
          boundaryPolyline
        );

        if (!cutResults || cutResults.length === 0) return null;

        // For each cut piece, check if it's inside the selection polygon
        const insidePieces = [];
        for (const piece of cutResults) {
          // Alternative 1: Check using a representative point instead of centroid
          if (
            piece.paths &&
            piece.paths.length > 0 &&
            piece.paths[0].length > 0
          ) {
            // Use a representative point (first point in the path)
            const midIndex = Math.floor(piece.paths[0].length / 2);
            const midPoint = piece.paths[0][midIndex];

            const point = new Point({
              x: midPoint[0],
              y: midPoint[1],
              spatialReference: piece.spatialReference,
            });

            if (await geometryEngineAsync.contains(selectionPolygon, point)) {
              insidePieces.push(piece);
              continue;
            }

            // As a fallback, check if most points are inside the polygon
            let insideCount = 0;
            for (let i = 0; i < piece.paths[0].length; i++) {
              const pt = piece.paths[0][i];
              const testPoint = new Point({
                x: pt[0],
                y: pt[1],
                spatialReference: piece.spatialReference,
              });

              const contains = await geometryEngineAsync.contains(
                selectionPolygon,
                testPoint
              );
              if (contains) {
                insideCount++;
              }
            }

            if (insideCount > piece.paths[0].length / 2) {
              insidePieces.push(piece);
            }
          }
        }

        if (insidePieces.length === 0) return null;

        // Union all inside pieces
        const insideGeometry =
          insidePieces.length === 1
            ? insidePieces[0]
            : await geometryEngineAsync.union(insidePieces);

        // Update feature geometry
        feature.geometry = insideGeometry;
        return feature;
      } catch (error) {
        console.error("Error processing feature with cut:", error);
        return null;
      } finally {
        count++;
        setCount(`${Math.round((count * 100) / features.length)}%`);
      }
    })
  );

  // Filter out null features
  const validFeatures = processedFeatures.filter(
    (f) => f && f.geometry && f.geometry.paths && f.geometry.paths.length > 0
  );

  // Add minimum length filter to remove very short segments
  const MIN_SEGMENT_LENGTH = 41; // meters
  const nonIntersectionFeatures = await Promise.all(
    validFeatures.map(async (feature) => {
      // Measure each segment's length
      const featurePaths = [];

      for (const path of feature.geometry.paths) {
        if (path.length >= 2) {
          const polyline = new Polyline({
            paths: [path],
            spatialReference: feature.geometry.spatialReference,
          });

          const length = await geometryEngineAsync.geodesicLength(
            polyline,
            "meters"
          );

          // Keep paths that are long enough
          if (length >= MIN_SEGMENT_LENGTH) {
            featurePaths.push(path);
          }
        }
      }

      // Update feature with filtered paths
      feature.geometry.paths = featurePaths;
      return feature;
    })
  );

  // Filter out features that now have no valid paths
  const filteredFeatures = nonIntersectionFeatures.filter(
    (f) => f.geometry.paths && f.geometry.paths.length > 0
  );

  // Union all resulting geometries
  const result = await geometryEngineAsync.union([
    ...containing.map((f) => f.geometry),
    ...filteredFeatures.map((f) => f.geometry),
  ]);

  return {
    result,
    features: [...filteredFeatures, ...containing],
  };
};

//original
// export const cutFeaturesAsync = (
//   features,
//   selectionPolygon,
//   { geometryEngine, Polyline, Point }
// ) => {
//   const notContaining = features.filter((f) => {
//     return !geometryEngine.contains(selectionPolygon, f.geometry);
//   });

//   const containing = features.filter((f) => {
//     return geometryEngine.contains(selectionPolygon, f.geometry);
//   });

//   const newPath = [];
//   if (notContaining.length > 0) {
//     const unionNotContaining = geometryEngine.union(
//       notContaining.map((f) => f.geometry)
//     );
//     if (unionNotContaining.paths.length > 0) {
//       unionNotContaining.paths.forEach((path) => {
//         const arr = [[]];
//         path.map(async (pointArr) => {
//           const [x, y] = pointArr;

//           const point = new Point({
//             x,
//             y,
//           });

//           const contains = geometryEngine.contains(selectionPolygon, point);
//           const intersects = geometryEngine.intersects(selectionPolygon, point);

//           if (contains || intersects) {
//             arr[arr.length - 1].push(pointArr);
//           } else if (arr[arr.length - 1].length > 0) {
//             arr.push([]);
//           }
//         });
//         arr.forEach((pathArr) => {
//           if (pathArr.length > 0) {
//             newPath.push(pathArr);
//           }
//         });
//       });
//     }

//     notContaining.forEach((f) => {
//       const newPath = [];
//       f.geometry.paths.forEach((path) => {
//         const arr = [[]];
//         path.forEach((pointArr) => {
//           const [x, y] = pointArr;

//           const point = new Point({
//             x,
//             y,
//           });

//           const contains = geometryEngine.contains(selectionPolygon, point);

//           if (contains) {
//             arr[arr.length - 1].push(pointArr);
//           } else if (arr[arr.length - 1].length > 0) {
//             arr.push([]);
//           }
//         });

//         arr.forEach((pathArr) => {
//           if (pathArr.length > 0) {
//             newPath.push(pathArr);
//           }
//         });
//       });

//       f.geometry.paths = newPath;
//       console.log(newPath, f.geometry);
//     });
//   }

//   const polyline = new Polyline({
//     paths: newPath,
//   });

//   const result = geometryEngine.union([
//     ...containing.map((f) => f.geometry),
//     ...notContaining.map((f) => f.geometry),
//   ]);

//   return {
//     result,
//     features: [...notContaining, ...containing],
//   };
// };

// Helper function to process points in chunks
const processPointsInChunks = async (points, callback, chunkSize = 100) => {
  const chunks = [];
  for (let i = 0; i < points.length; i += chunkSize) {
    chunks.push(points.slice(i, i + chunkSize));
  }

  const results = [];
  for (const chunk of chunks) {
    // Use requestAnimationFrame to yield to UI
    await new Promise((resolve) => requestAnimationFrame(resolve));
    const chunkResults = await Promise.all(chunk.map(callback));
    results.push(...chunkResults);
  }
  return results;
};

const chunkArray = (array, size = 5) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

// Helper to yield to UI thread
const yieldToUI = (timeout = 250) =>
  new Promise((resolve) =>
    requestAnimationFrame(() => setTimeout(resolve, timeout))
  );

// export const cutFeaturesAsync = async (
//   features,
//   selectionPolygon,
//   { geometryEngineAsync, Polyline, Point }
// ) => {
//   // Filter features that are not contained
//   const notContaining = [];
//   const containing = [];

//   const featureChunks = chunkArray(features, 1000);

//   for (const chunk of featureChunks) {
//     // await yieldToUI(); // Yield to UI between chunks

//     await Promise.all(
//       chunk.map(async (f) => {
//         const isContained = await geometryEngineAsync.contains(
//           selectionPolygon,
//           f.geometry
//         );
//         if (isContained) {
//           containing.push(f);
//         } else {
//           notContaining.push(f);
//         }
//       })
//     );
//   }

//   const newPath = [];
//   if (notContaining.length > 0) {
//     const processedFeatures = [];
//     const featureChunks = chunkArray(notContaining, 1000);

//     for (const chunk of featureChunks) {
//       // await yieldToUI(); // Yield to UI between feature chunks

//       await Promise.all(
//         chunk.map(async (f) => {
//           const newFeaturePath = [];

//           for (const path of f.geometry.paths) {
//             const arr = [[]];

//             await processPointsInChunks(
//               path,
//               async (pointArr) => {
//                 const [x, y] = pointArr;
//                 const point = new Point({ x, y });

//                 const contains = await geometryEngineAsync.contains(
//                   selectionPolygon,
//                   point
//                 );

//                 if (contains) {
//                   arr[arr.length - 1].push(pointArr);
//                 } else if (arr[arr.length - 1].length > 0) {
//                   arr.push([]);
//                 }
//               },
//               10000
//             ); // Smaller chunk size for points

//             newFeaturePath.push(...arr.filter((pathArr) => pathArr.length > 0));
//           }

//           f.geometry.paths = newFeaturePath;
//           processedFeatures.push(f);
//         })
//       );
//     }
//   }
//   await yieldToUI(); // Final yield before union

//   const polyline = new Polyline({
//     paths: newPath,
//   });

//   const result = await geometryEngineAsync.union([
//     ...containing.map((f) => f.geometry),
//     ...notContaining.map((f) => f.geometry),
//   ]);

//   return {
//     result,
//     features: [...notContaining, ...containing],
//   };
// };

const cutFragments = async (fragments, pathIndex, ring, esriModules, path) => {
  const endPoint = pathIndex === ring.length - 1 ? 0 : pathIndex + 1;
  const newFragments = [];
  const geometryEngineAsync = esriModules.geometryEngineAsync;

  await Promise.all(
    fragments.map(async (fragment) => {
      const cut = await geometryEngineAsync.cut(
        fragment,
        new esriModules.Polyline({ paths: [path, ring[endPoint]] })
      );
      if (cut?.length > 0) cut.forEach((c) => newFragments.push(c));
      else newFragments.push(fragment);
    })
  );
  if (newFragments?.length > 1) {
    await cutFragments(newFragments);
  }
};

/**
 * Process features that might intersect with a given geometry
 * First checks for intersection, then processes only intersecting features
 * @param {Object[]} features - Array of features to check and process
 * @param {Object} geometry - The geometry to check intersections against
 * @param {Object} options - Required ESRI modules with geometryEngine and geometryEngineAsync
 * @returns {Promise<{trimmedFeatures: Object[], deletedFeatures: Object[]}>}
 */
export const processFeaturesByGeometry = async (
  features,
  geometry,
  { geometryEngineAsync }
) => {
  if (!Array.isArray(features) || features.length === 0) {
    return {
      trimmedFeatures: [],
      deletedFeatures: [],
    };
  }

  // Filter features using async operations
  const intersectingFeatures = [];

  await Promise.all(
    features.map(async (feature) => {
      const intersects = await geometryEngineAsync.intersects(
        geometry,
        feature.geometry
      );
      const contains = await geometryEngineAsync.contains(
        geometry,
        feature.geometry
      );

      if (intersects || contains) {
        intersectingFeatures.push(feature);
      }
    })
  );

  // Process only the intersecting features
  return processIntersectingFeatures(intersectingFeatures, geometry, {
    geometryEngineAsync,
  });
};

/**
 * Process features that intersect with a given geometry
 * @param {Object[]} features - Array of features to process
 * @param {Object} geometry - The geometry to check intersections against
 * @param {Object} options - Required ESRI modules and configuration
 * @returns {Promise<{trimmedFeatures: Object[], deletedFeatures: Object[]}>}
 */
export const processIntersectingFeatures = async (
  features,
  geometry,
  { geometryEngineAsync }
) => {
  if (!Array.isArray(features) || features.length === 0) {
    return {
      trimmedFeatures: [],
      deletedFeatures: [],
    };
  }

  const deletedFeatures = [];
  const trimmedFeatures = [];

  // Process each feature
  await Promise.all(
    features.map(async (feature) => {
      // Check if feature is completely contained
      const isContained = await geometryEngineAsync.contains(
        geometry,
        feature.geometry
      );

      if (isContained) {
        deletedFeatures.push(feature);
        return null;
      }

      // Get the difference between feature and intersecting geometry
      const difference = await geometryEngineAsync.difference(
        feature.geometry,
        geometry
      );

      // If there's no difference, the feature should be deleted
      if (!difference || !difference.paths || difference.paths.length === 0) {
        deletedFeatures.push(feature);
        return null;
      }

      // Update feature geometry and keep it
      feature.geometry = difference;
      trimmedFeatures.push(feature);
      return feature;
    })
  );

  return {
    trimmedFeatures,
    deletedFeatures,
  };
};

export const concatRouteAttributes = ({ features, editableLayer }) => {
  const { concatFields, groupFields } = editableLayer.layerConfig.batchEditor;
  const outFieldsSet = new Set();

  const fieldsArray = [...concatFields, ...groupFields];
  view.map.layers.forEach((l) => {
    if (l.layerConfig?.titleLabel === "roads") {
      const outFields = l.fields.filter((f) => fieldsArray.includes(f.name));

      outFields.forEach((f) => outFieldsSet.add(f.name));
    }
  });

  const concatObject = {};

  outFieldsSet.delete("iso3");
  features.forEach((f) => {
    for (const fieldName of outFieldsSet) {
      const value = f.attributes[fieldName];
      if (value) {
        if (concatObject[fieldName]) {
          concatObject[fieldName].add(value);
        } else {
          concatObject[fieldName] = new Set([value]);
        }
      }
    }
  });

  const fieldLength = {};
  editableLayer.fields.map((f) => {
    if (outFieldsSet.has(f.name)) {
      if (f.type === "string") {
        fieldLength[f.name] = f.length;
      }
    }
  });

  const atts = {};
  Object.keys(concatObject).forEach((key) => {
    const value = concatObject[key];
    const maxLength = fieldLength[key];
    const values = Array.from(value);

    let processedValue;
    if (values.length === 0) {
      processedValue = null;
    } else if (values.length === 1) {
      // Single value - check length for strings
      const singleValue = values[0];
      if (typeof singleValue === "string" && maxLength) {
        processedValue = singleValue.length <= maxLength ? singleValue : null;
      } else {
        processedValue = singleValue;
      }
    } else {
      // Multiple values
      if (typeof values[0] === "number") {
        const nonZeroValues = values.filter((val) => val !== 0);

        if (nonZeroValues.length > 0) {
          // If we have non-zero values, use the minimum of those
          processedValue = Math.min(...nonZeroValues);
        } else {
          // If all values are zero, then use zero
          processedValue = 0;
        }
      } else {
        // For strings, join values until maxLength is reached
        if (maxLength) {
          const joinableValues = [];
          let currentLength = 0;

          for (const val of values) {
            // Calculate new length including the comma
            const newLength =
              currentLength + (currentLength > 0 ? 1 : 0) + val.length;

            if (newLength <= maxLength) {
              joinableValues.push(val);
              currentLength = newLength;
            } else {
              break; // Stop if adding next value would exceed maxLength
            }
          }

          processedValue =
            joinableValues.length > 0 ? joinableValues.join(",") : null;
        } else {
          processedValue = values.join(",");
        }
      }
    }
    atts[key] = processedValue;
  });

  return atts;
};

export const queryLayerView = async ({
  layers,
  fieldsArray,
  controllers,
  drawBufferClient,
}) => {
  const features = [];
  try {
    for (const layer of layers) {
      const lv = await view.whenLayerView(layer);
      const outFields = layer.fields.filter((f) =>
        fieldsArray.includes(f.name)
      );

      const controller = controllers[layer.id];
      const query = lv.createQuery();
      query.geometry = drawBufferClient;
      query.spatialRelationship = "intersects";

      const res = await lv.queryFeatures(query, {
        signal: controller.signal,
      });

      features.push(res.features);
      // if (res.features.length === 0) continue;
      // const objectIds = res.features.map((f) => f.getObjectId());
      // console.log({ intersectedLayerViewFeats: objectIds.length });

      // for (const id of objectIds) {
      //   const res = await layer.queryFeatures(
      //     {
      //       where: `${layer.objectIdField} = ${id}`,
      //       outFields: [layer.objectIdField, ...outFields.map((f) => f.name)],
      //       returnGeometry: true,
      //     },
      //     { signal: controller.signal }
      //   );

      //   if (res.features.length > 0) {
      //     features.push(features);
      //   }

      //   await yieldToUI();
      // }
    }
  } catch (err) {
    console.log(err);
  }

  return features;
};

export const queryLayer = async (
  layer,
  selection,
  bufferNumber,
  originalPolyline,
  editableLayer,
  roadsBaselineLayers,
  setCount,
  drawBufferClient,
  id,
  {
    controllers,
    geometryEngine,
    geometryEngineAsync,
    Polyline,
    Graphic,
    webMercatorUtils,
    Point,
  }
) => {
  const [projection, SpatialReference] = await loadModules([
    "esri/geometry/projection",
    "esri/geometry/SpatialReference",
  ]);

  try {
    // Project selection polygon to view's spatial reference if needed
    // const projectedSelection =
    //   selection.spatialReference.wkid !== view.spatialReference.wkid
    //     ? projection.project(selection, view.spatialReference)
    //     : selection;

    // graphicsLayer.add({
    //   symbol: {
    //     ...selectionLineSymbol,
    //     color: "red",
    //   },
    //   geometry: originalPolyline,
    // });

    // await yieldToUI(5000);

    // console.log({ projectedSelection });

    const { concatFields, groupFields } = editableLayer.layerConfig.batchEditor;

    const fieldsArray = [...concatFields, ...groupFields];
    // console.log({ selection });

    const outFieldsSet = new Set();
    // setCount(prev=>({...prev, { [id]: 0 }});
    // console.log(view.spatialReference);

    // const features = [];
    const roadsBaselinePromises = roadsBaselineLayers.map(async (layer) => {
      // const feat = await queryLayerView({
      //   layers: [layer],
      //   fieldsArray,
      //   controllers,
      //   drawBufferClient,
      // });
      // console.log(feat);

      // features.concat(feat);

      const lv = await view.whenLayerView(layer);
      const lvQuery = lv.createQuery();
      lvQuery.geometry = selection;
      lvQuery.spatialRelationship = "intersects";

      return lv.queryFeatures(lvQuery);

      const outFields = layer.fields.filter((f) =>
        fieldsArray.includes(f.name)
      );
      outFields.forEach((f) => outFieldsSet.add(f.name));

      const controller = controllers[layer.id];
      const query = layer.createQuery();
      query.geometry = selection;
      query.spatialRelationship = "intersects";
      query.outFields = [layer.objectIdField, ...outFields.map((f) => f.name)];
      return layer.queryFeatures(query, {
        signal: controller.signal,
      });
    });

    const settledResults = await Promise.allSettled(roadsBaselinePromises);
    // console.log(settledResults);

    const successfulQueries = [];
    const unsuccessfulQueries = [];
    settledResults.forEach((result, index) => {
      if (result.status === "fulfilled") {
        successfulQueries.push(result.value);
      } else {
        // console.log(roadsBaselineLayers.toArray(), index);
        // unsuccessfulQueries.push(roadsBaselineLayers.toArray()[index]);
      }
    });

    const features = successfulQueries.reduce((acc, res) => {
      acc.push(...res.features);
      return acc;
    }, []);

    // if (unsuccessfulQueries.length > 0) {
    //   console.log("Unsuccessful queries", unsuccessfulQueries);
    //   const resultFeatures = await queryLayerView({
    //     layers: unsuccessfulQueries,
    //     fieldsArray,
    //     controllers,
    //     drawBufferClient,
    //   });

    //   features.concat(resultFeatures);
    // }
    // console.log(features);

    // const successfulQueries = settledResults
    //   .filter((result) => result.status === "fulfilled")
    //   .map((result) => result.value);

    // console.log({
    //   queries: successfulQueries.length,
    //   unsuccessfulQueries: unsuccessfulQueries.length,
    //   features: features.length,
    // });
    // console.log(features);
    // console.log(selection);

    // graphicsLayer.removeAll();
    // const outSpatialReference = new SpatialReference({
    //   wkid: 4326,
    // });

    features.map((f) => {
      // const projectedGeometry = projection.getTransformation(
      //   f.geometry,
      //   outSpatialReference
      // );
      // console.log(projectedGeometry);
      // console.log(f.geometry);
      // const progjected = projection.project(f.geometry, outSpatialReference);
      // const geometry = webMercatorUtils.geographicToWebMercator(f.geometry);
      // graphicsLayer.add({
      //   symbol: {
      //     ...selectionLineSymbol,
      //     // color: "green",
      //   },
      //   geometry: f.geometry,
      //   // preserveGeometryShape: true, // Add this to prevent client-side simplification
      // });
    });

    // return;
    // await yieldToUI(10000);
    // return;
    if (!features.length) return;
    // features.forEach((f) => {
    //   f.geometry = webMercatorUtils.webMercatorToGeographic(f.geometry);
    // });

    // console.log("start cutting", features);
    // console.log(selection, features);
    // return;
    const { result: final, features: cuttedFeatures } = await cutFeaturesAsync(
      features,
      selection,
      {
        setCount,
        geometryEngineAsync,
        Polyline,
        Point,
        Graphic,
        // webmercatorUtils,
      }
    );
    // graphicsLayer.removeAll();

    // console.log("end cutting", cuttedFeatures);

    // const cutted = await cutFeatures(features, selection, {
    //   geometryEngine,
    //   geometryEngineAsync,
    //   Polyline,
    //   Graphic,
    //   webMercatorUtils,
    //   Point,
    //   setCount,
    // });

    // features.map((f) => {
    //   graphicsLayer.add(
    //     new Graphic({
    //       symbol: {
    //         ...selectionLineSymbol,
    //         color: "red",
    //       },
    //       geometry: f.geometry,
    //       preserveGeometryShape: true, // Add this to prevent client-side simplification
    //     })
    //   );
    // });

    // cutted.map((f) => {
    //   // const geometry = webMercatorUtils.webMercatorToGeographic(f.geometry);
    //   graphicsLayer.add(
    //     new Graphic({
    //       symbol: selectionLineSymbol,
    //       geometry: f.geometry,
    //       preserveGeometryShape: true, // Add this to prevent client-side simplification
    //     })
    //   );
    // });

    // return;

    // console.log("end cutting batch", cutted);

    // const union = await geometryEngineAsync.union(
    //   cutted.map((f) => f.geometry)
    // );
    const graphic = new Graphic({
      id: "roadsSituational",
      geometry: final,
      symbol: {
        ...selectionLineSymbol,
      },
      attributes: {},
    });

    return {
      bufferNumber,
      graphic,
      cuttedFeatures: cuttedFeatures,
    };
  } catch (err) {
    console.log(err);
    if (err.name === "AbortError") {
      return err;
    }
  }
  return null;
};

let layersWithVisibleLabels = new Set();
export const addLayerEffect = (keepLayers = []) => {
  const mapLayers = view.map.layers.filter((layer) => {
    if (layer.labelsVisible) {
      layersWithVisibleLabels.add(layer.id);
    }

    if (
      keepLayers.some((kl) =>
        !!layer.originalId
          ? kl.originalId === layer.originalId
          : kl.originalId === layer.layerConfigId
      )
    ) {
      layer.effect = undefined;
      return true;
    }
    if (layer.id === graphicsLayer.id) {
      layer.effect = undefined;
      return true;
    }

    if (layer.title === "wld_bnd_adm0") {
      layer.effect = undefined;
      return true;
    }

    if (
      layer.layerConfig &&
      (layer.layerConfig.alias === "roads_baseline" ||
        layer.layerConfig.extends === "roads_baseline")
    ) {
      layer.effect = undefined;
      return true;
    }
    layer.labelsVisible = false;
    layer.effect = LAYER_EFFECT;
    return false;
  });
};

export const removeLayerEffects = (keepLayers = []) => {
  const mapLayers = view.map.layers.filter((layer) => {
    // if (
    //   keepLayers.some((kl) =>
    //     !!layer.originalId
    //       ? kl.originalId === layer.originalId
    //       : kl.originalId === layer.layerConfigId
    //   )
    // ) {
    //   layer.effect = LAYER_EFFECT;
    //   return true;
    // }
    // if (layer.id === graphicsLayer.id) {
    //   layer.effect = LAYER_EFFECT;
    //   return true;
    // }

    // if (layer.title === "wld_bnd_adm0") {
    //   layer.effect = LAYER_EFFECT;
    //   return true;
    // }

    layer.labelsVisible = layersWithVisibleLabels.has(layer.id);
    layer.effect = undefined;
    return false;
  });
  // layersWithVisibleLabels = [];
};

export const getFeatureNameField = (editableLayer) => {
  if (
    !editableLayer?.layerConfig?.titleTemplate ||
    typeof editableLayer.layerConfig.titleTemplate !== "string"
  ) {
    return null;
  }

  const titleTemplate = editableLayer.layerConfig.titleTemplate;
  const name = titleTemplate.slice(1, -1).replace("feature.", "");

  if (!Array.isArray(editableLayer?.fields)) {
    return null;
  }

  const field = editableLayer.fields.find((f) => f.name === name);
  if (field?.type === "string") {
    return field.name;
  }

  return null;
};

/**
 * Extracts feature name fields from an editable layer based on its title template.
 *
 * @param {Object} editableLayer - The editable layer object to process
 * @param {Object} [editableLayer.layerConfig] - Configuration for the layer
 * @param {string} [editableLayer.layerConfig.titleTemplate] - Template string containing feature field references
 * @param {Array} [editableLayer.fields] - Array of field definitions for the layer
 * @returns {Array<string>} Array of fields that match the feature names in the title template
 * and are of type "string"
 *
 * @example
 */
export const getFeatureNameFields = (editableLayer) => {
  if (
    !editableLayer?.layerConfig?.titleTemplate ||
    typeof editableLayer.layerConfig.titleTemplate !== "string"
  ) {
    return [];
  }

  const titleTemplate = editableLayer.layerConfig.titleTemplate;
  // Find all matches of {feature.something}
  const featureMatches = titleTemplate.match(/\{feature\.[^}]+\}/g) || [];

  // Extract field names from matches
  const fieldNames = new Set(
    featureMatches.map(
      (match) => match.slice(9, -1) // Remove '{feature.' and '}'
    )
  );

  if (!Array.isArray(editableLayer?.fields)) {
    return [];
  }

  const nameFields = [];
  editableLayer.fields.forEach((f) => {
    if (fieldNames.has(f.name) && f.type === "string") {
      nameFields.push(f.name);
    }
  });

  return nameFields;
};

export const zoomToFeaturesExtent = async (features) => {
  try {
    const [Polyline, geometryEngineAsync] = await loadModules([
      "esri/geometry/Polyline",
      "esri/geometry/geometryEngineAsync",
    ]);

    const paths = [];
    const geometries = [];

    if (features.length === 1) {
      const feature = features[0].clone();
      const extent = feature.geometry.extent;

      const options = {
        target: extent ? extent.expand(2) : feature,
      };

      const scale = initialExtent.width / 3;
      const viewScale = view?.scale || 0;

      if (!extent && viewScale > scale) {
        options.scale = scale;
      }

      await view.goTo(options);
    } else {
      features.forEach((f) => {
        if (!f.geometry) return;

        if (f.geometry.type === "point") {
          paths.push([f.geometry.longitude, f.geometry.latitude]);
          geometries.push(f.geometry);
        } else if (f.geometry.type === "polyline") {
          geometries.push(f.geometry);
          paths.push(f.geometry.paths[0]);
        }
      });

      const union = await geometryEngineAsync.union(geometries);

      if (union && union.extent) {
        await view.goTo(union.extent.expand(2));
      }
    }
  } catch (error) {
    console.error("Error processing features:", error);
  }
};

export const createFeatureGraphic = async (feature, editableLayer) => {
  try {
    let editingSymbol;

    if (
      editableLayer.layerConfig.isConops ||
      editableLayer.layerConfig.isEpam
    ) {
      editingSymbol = pointSymbol;
    } else {
      editingSymbol = await editableLayer?.renderer?.getSymbolAsync(feature);
    }

    const nGraphic = feature.clone();

    nGraphic.symbol = editingSymbol ?? pointSymbol;

    if (
      nGraphic?.symbol &&
      nGraphic.symbol.width > 20 &&
      nGraphic.symbol.height > 20
    ) {
      nGraphic.symbol.width = 20;
      nGraphic.symbol.height = 20;
    }

    return nGraphic;
  } catch (err) {
    return feature;
  }
};

export const getSituationalBatchFields = ({ editableLayer, config }) => {
  if (!editableLayer || !editableLayer?.layerConfig) return [];
  const symbology = getLayerSymbology(editableLayer, config) || {};
  const lc = editableLayer.layerConfig;
  const batchUpdateFields = lc.batchUpdateFields || [];

  let fields = getFieldsByPattern(editableLayer, lc.situationalFields);

  //filtering fields for batch editor
  const { colorMap } = symbology;
  const symbologyFields = [];

  if (colorMap?.fields) {
    symbologyFields.push(...colorMap.fields);
  } else if (colorMap?.field) {
    symbologyFields.push(colorMap.field);
  }

  fields = fields.filter(
    (field) =>
      batchUpdateFields.includes(field.name) ||
      symbologyFields.includes(field.name)
  );

  if (fields.length === 0) {
    fields = getFieldsByPattern(editableLayer, lc.situationalFields);
  }

  return fields;
};

///////////---------------////////-----------------
/**
 * Creates a route using feature layer segments that best match an OpenRouteService route
 * @param {Polyline} orsPolyline - The polyline from OpenRouteService (already converted to ArcGIS Polyline)
 * @param {FeatureLayerView} layerView - The layerView containing polyline features
 * @param {Object} modules - ArcGIS modules (Point, Polyline, geometryEngineAsync)
 * @returns {Promise<Polyline>} - Final route composed of feature layer segments
 */
export async function createMatchedRoute(orsPolyline, layer, modules) {
  const { Point, Polyline, geometryEngineAsync } = modules;

  const layerView = await view.whenLayerView(layer);

  // 1. Extract start and end points from the ORS polyline
  const startPoint = extractStartPoint(orsPolyline, Point);
  const endPoint = extractEndPoint(orsPolyline, Point);

  // 2. Query all road segments within expanded extent of the ORS route
  const routeExtent = orsPolyline.extent.expand(1.5); // Expand by 50%
  const segmentResults = await layerView.queryFeatures({
    geometry: routeExtent,
    spatialRelationship: "intersects",
    returnGeometry: true,
    outFields: ["*"],
  });

  // 3. Build a topological network from these segments
  const network = buildTopologicalNetwork(segmentResults.features);

  // 4. Find nearest network nodes to start and end points
  const startNode = findNearestNode(network, startPoint);
  const endNode = findNearestNode(network, endPoint);

  // 5. Calculate optimal path through network that best matches the ORS route
  const path = await findOptimalPath(
    network,
    startNode,
    endNode,
    orsPolyline,
    modules
  );

  // 6. Assemble the final route from matched segments
  const resultRoute = await assembleRouteFromPath(
    path,
    network,
    segmentResults.features,
    modules
  );
  // console.log(resultRoute);

  graphicsLayer.add({
    symbol: selectionLineSymbol,
    geometry: resultRoute,
  });
}

/**
 * Extract start point from a polyline
 * @param {Polyline} polyline - The polyline geometry
 * @param {Point} PointConstructor - ArcGIS Point constructor
 * @returns {Point} Start point
 */
function extractStartPoint(polyline, PointConstructor) {
  if (
    polyline.paths &&
    polyline.paths.length > 0 &&
    polyline.paths[0].length > 0
  ) {
    const firstPoint = polyline.paths[0][0];
    return new PointConstructor({
      x: firstPoint[0],
      y: firstPoint[1],
      spatialReference: polyline.spatialReference,
    });
  }
  throw new Error("Invalid polyline: cannot extract start point");
}

/**
 * Extract end point from a polyline
 * @param {Polyline} polyline - The polyline geometry
 * @param {Point} PointConstructor - ArcGIS Point constructor
 * @returns {Point} End point
 */
function extractEndPoint(polyline, PointConstructor) {
  if (polyline.paths && polyline.paths.length > 0) {
    const lastPath = polyline.paths[polyline.paths.length - 1];
    if (lastPath.length > 0) {
      const lastPoint = lastPath[lastPath.length - 1];
      return new PointConstructor({
        x: lastPoint[0],
        y: lastPoint[1],
        spatialReference: polyline.spatialReference,
      });
    }
  }
  throw new Error("Invalid polyline: cannot extract end point");
}

/**
 * Builds a topological network representation from polyline features
 * @param {Feature[]} features - Polyline features from feature layer
 * @returns {Object} Network graph representation
 */
function buildTopologicalNetwork(features) {
  const network = {
    nodes: new Map(), // Map of nodeId -> {x, y} coordinates
    edges: new Map(), // Map of edgeId -> {fromNode, toNode, feature, cost}
    nodeConnections: new Map(), // Map of nodeId -> array of connected edgeIds
  };

  // Generate a unique node ID based on point coordinates
  const getNodeId = (point) =>
    `${Math.round(point.x * 1000)}_${Math.round(point.y * 1000)}`;

  features.forEach((feature) => {
    const polyline = feature.geometry;
    const paths = polyline.paths;

    paths.forEach((path) => {
      // For each path in the polyline
      for (let i = 0; i < path.length - 1; i++) {
        // Create nodes for start and end of segment
        const startPoint = { x: path[i][0], y: path[i][1] };
        const endPoint = { x: path[i + 1][0], y: path[i + 1][1] };

        const startNodeId = getNodeId(startPoint);
        const endNodeId = getNodeId(endPoint);

        // Add nodes to network if they don't exist
        if (!network.nodes.has(startNodeId)) {
          network.nodes.set(startNodeId, startPoint);
          network.nodeConnections.set(startNodeId, []);
        }
        if (!network.nodes.has(endNodeId)) {
          network.nodes.set(endNodeId, endPoint);
          network.nodeConnections.set(endNodeId, []);
        }

        // Create an edge between these nodes
        const edgeId = `${startNodeId}_${endNodeId}`;
        // Calculate segment length as cost
        const length = Math.sqrt(
          Math.pow(endPoint.x - startPoint.x, 2) +
            Math.pow(endPoint.y - startPoint.y, 2)
        );

        network.edges.set(edgeId, {
          fromNode: startNodeId,
          toNode: endNodeId,
          feature: feature,
          segmentIndex: i,
          cost: length,
        });

        // Add edge to node connections
        network.nodeConnections.get(startNodeId).push(edgeId);
        // Assuming bidirectional roads - remove if roads are one-way
        network.nodeConnections.get(endNodeId).push(edgeId);
      }
    });
  });

  return network;
}

/**
 * Finds the nearest node in the network to the given point
 */
function findNearestNode(network, point) {
  let minDistance = Infinity;
  let nearestNodeId = null;

  for (const [nodeId, nodeCoords] of network.nodes.entries()) {
    const distance = Math.sqrt(
      Math.pow(nodeCoords.x - point.x, 2) + Math.pow(nodeCoords.y - point.y, 2)
    );

    if (distance < minDistance) {
      minDistance = distance;
      nearestNodeId = nodeId;
    }
  }

  return nearestNodeId;
}

/**
 * Find optimal path through network that best matches the reference route
 * Using A* algorithm with a custom heuristic that considers:
 * 1. Distance to destination
 * 2. Similarity to reference route
 */
async function findOptimalPath(
  network,
  startNodeId,
  endNodeId,
  referenceRoute,
  modules
) {
  const { Point, geometryEngineAsync } = modules;
  const openSet = new PriorityQueue();
  const cameFrom = new Map();
  const gScore = new Map(); // Cost from start to current node
  const fScore = new Map(); // Estimated total cost

  // Initialize scores
  for (const nodeId of network.nodes.keys()) {
    gScore.set(nodeId, Infinity);
    fScore.set(nodeId, Infinity);
  }
  // console.log(network.nodes);

  gScore.set(startNodeId, 0);
  fScore.set(
    startNodeId,
    estimatePathCost(
      network.nodes.get(startNodeId),
      network.nodes.get(endNodeId)
    )
  );
  openSet.enqueue(startNodeId, fScore.get(startNodeId));

  while (!openSet.isEmpty()) {
    const currentNodeId = openSet.dequeue();

    if (currentNodeId === endNodeId) {
      return reconstructPath(cameFrom, currentNodeId);
    }

    // Get all connected edges
    const edges = network.nodeConnections.get(currentNodeId);

    for (const edgeId of edges) {
      const edge = network.edges.get(edgeId);
      const neighborId =
        edge.fromNode === currentNodeId ? edge.toNode : edge.fromNode;

      // Calculate cost - base cost plus penalty if far from reference route
      const edgeCost = edge.cost;
      const penaltyFactor = await calculateRouteSimilarityPenalty(
        edge,
        referenceRoute,
        network,
        modules
      );
      const totalEdgeCost = edgeCost * penaltyFactor;

      const tentativeGScore = gScore.get(currentNodeId) + totalEdgeCost;

      if (tentativeGScore < gScore.get(neighborId)) {
        cameFrom.set(neighborId, { from: currentNodeId, edge: edgeId });
        gScore.set(neighborId, tentativeGScore);
        fScore.set(
          neighborId,
          tentativeGScore +
            estimatePathCost(
              network.nodes.get(neighborId),
              network.nodes.get(endNodeId)
            )
        );

        openSet.enqueue(neighborId, fScore.get(neighborId));
      }
    }
  }

  // No path found
  return null;
}

/**
 * Calculate penalty factor based on how well the segment aligns with reference route
 */
async function calculateRouteSimilarityPenalty(
  edge,
  referenceRoute,
  network,
  modules
) {
  const { Point, geometryEngineAsync } = modules;

  // Get the midpoint of this edge
  const fromNodeId = edge.fromNode;
  const toNodeId = edge.toNode;
  const fromNode = network.nodes.get(fromNodeId);
  const toNode = network.nodes.get(toNodeId);

  const midPoint = {
    x: (fromNode.x + toNode.x) / 2,
    y: (fromNode.y + toNode.y) / 2,
  };

  // Find distance to nearest point on reference route
  const pointGeometry = new Point({
    x: midPoint.x,
    y: midPoint.y,
    spatialReference: referenceRoute.spatialReference,
  });

  const distanceToRoute = await geometryEngineAsync.distance(
    pointGeometry,
    referenceRoute,
    "meters"
  );

  // Calculate penalty - higher distance = higher penalty
  // You can adjust these values based on your needs
  const basePenalty = 1;
  const distanceFactor = 0.01;
  return basePenalty + distanceFactor * distanceToRoute;
}

/**
 * Assemble the final route from matched segments
 */
async function assembleRouteFromPath(path, network, features, modules) {
  const { Polyline, geometryEngineAsync } = modules;

  if (!path || path.length === 0) {
    return null;
  }

  const segments = [];

  for (const step of path) {
    const edge = network.edges.get(step.edge);
    const feature = edge.feature;
    segments.push(feature.geometry);
  }

  // Merge segments into a single polyline
  return await geometryEngineAsync.union(segments);
}

/**
 * Converts ORS route to ArcGIS Polyline
 */
async function convertToArcGISPolyline(orsRoute, modules) {
  const { Polyline } = modules;

  // Implementation depends on the format returned by ORS API
  // For example, if it's GeoJSON:
  const paths = orsRoute.coordinates.map((coord) => [coord[0], coord[1]]);

  return new Polyline({
    paths: [paths],
    spatialReference: { wkid: 4326 }, // Assuming WGS84
  });

  // If it's an encoded polyline, you would need to decode it first
}

/**
 * Simple priority queue implementation for A* algorithm
 */
class PriorityQueue {
  constructor() {
    this.elements = [];
  }

  enqueue(element, priority) {
    this.elements.push({ element, priority });
    this.elements.sort((a, b) => a.priority - b.priority);
  }

  dequeue() {
    return this.elements.shift().element;
  }

  isEmpty() {
    return this.elements.length === 0;
  }
}

/**
 * Reconstructs the path from the cameFrom map
 */
function reconstructPath(cameFrom, current) {
  const totalPath = [];

  while (cameFrom.has(current)) {
    const step = cameFrom.get(current);
    totalPath.unshift({ from: step.from, to: current, edge: step.edge });
    current = step.from;
  }

  return totalPath;
}

/**
 * Estimates the cost from current node to end node (heuristic function)
 */
function estimatePathCost(current, end) {
  return Math.sqrt(
    Math.pow(end.x - current.x, 2) + Math.pow(end.y - current.y, 2)
  );
}
