import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getModules } from "../../../../../hooks/useModuleSelection";
import { ConfigContext } from "../../../../../utils/ConfigContext";
import { timeAgo } from "../../../../../utils/helper";
import { CalendarIcon, MdAccessTime, TargetIcon } from "../../../../Icons";
import ProgressBar from "../../../../Report/new/ProgressBar/ProgressBar";
import {
  ChecklistSidebarModuleWrapper,
  ChecklistSidebarOverallBox,
  ChecklistSidebarOverallHeader,
  CheckListSidebarWrapper,
  StyledFooter,
  StyledFooterText,
  StyledFooterTextWrapper,
} from "../../CycleManager-styled";
import SidebarModule from "./SidebarModule";

const ChecklistSidebar = ({ onLayerSelect, layers, editableLayer }) => {
  const { layersUpdate, updatePercent, lastCycleUpdate, cycleUpdateDueDate } =
    useSelector((state) => state.cycleManagerReducer);
  const { config } = useContext(ConfigContext);
  const { t, i18n } = useTranslation("common");

  const modules = useMemo(() => getModules(config, t), [config, t]);

  const isOverdue = useMemo(() => {
    const now = new Date();
    return lastCycleUpdate > lastCycleUpdate || now >= lastCycleUpdate;
  }, []);

  const format = (value) => {
    if (!value) return "";
    const now = new Date();

    if (lastCycleUpdate > value || now.getTime() >= value)
      return t("cycleManager.overdue");
    return timeAgo(new Date(value), i18n.language, false, { month: "short" });
  };

  const formatCycleTime = (value) => {
    if (!value) return "";
    return timeAgo(new Date(value), i18n.language, false, { month: "short" });
  };

  const cycleUpdateFrequency = useMemo(
    () => config.cycleUpdateFrequency,
    [config]
  );

  return (
    <CheckListSidebarWrapper>
      <ChecklistSidebarOverallBox>
        <ChecklistSidebarOverallHeader>
          <ProgressBar
            color={config.opsColor}
            currentPosition={updatePercent}
            total={100}
            hasTopRadius={false}
          />
          <p>{t("cycleManager.progressTitle")}</p>&nbsp;
          <span>{updatePercent}%</span>
        </ChecklistSidebarOverallHeader>
      </ChecklistSidebarOverallBox>
      <ChecklistSidebarModuleWrapper>
        {modules.map((item) => (
          <SidebarModule
            key={item.module}
            module={item.module}
            moduleName={item.moduleName}
            config={config}
            moduleLayers={[...item.layers, ...item.optionalLayers]}
            editableLayers={layers}
            t={t}
            onLayerSelect={onLayerSelect}
            editableLayer={editableLayer}
          />
        ))}
      </ChecklistSidebarModuleWrapper>
      <StyledFooter>
        <StyledFooterTextWrapper>
          <TargetIcon width="16" height="16" />
          <StyledFooterText>
            <span>{t("cycleManager.due")}:</span>{" "}
            <span
              style={{
                color: isOverdue ? config.opsColor : "",
                textDecoration: isOverdue ? "underline" : "",
              }}
            >
              {format(cycleUpdateDueDate)}
            </span>
          </StyledFooterText>
        </StyledFooterTextWrapper>
        <StyledFooterTextWrapper>
          <CalendarIcon width="16" height="16" />
          <StyledFooterText
            style={{ flexDirection: "column", display: "flex" }}
          >
            <span>{t("cycleManager.last")}:</span>{" "}
            <span>{formatCycleTime(lastCycleUpdate)}</span>
          </StyledFooterText>
        </StyledFooterTextWrapper>
        <StyledFooterTextWrapper>
          <MdAccessTime width="16" height="16" />
          <StyledFooterText>
            <span>{t("cycleManager.frequency.title")}:</span>{" "}
            <span>
              {t(`cycleManager.frequency.options.${cycleUpdateFrequency}`)}
            </span>
          </StyledFooterText>
        </StyledFooterTextWrapper>
      </StyledFooter>
    </CheckListSidebarWrapper>
  );
};

export default ChecklistSidebar;
