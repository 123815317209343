import React from "react";
import CoordinatesInputs from "../../Dashboard/Editor/CoordinatesSelection/CoordinatesInputs";
import { FaSearchLocation } from "../../Icons";
import {
  CoordinatesContainer,
  StyledLocateButton,
} from "../SearchWidget-styled";

const CoordinatesPanel = ({
  handleCoordinates,
  activeCoordinates,
  handleKeyPress,
  locateCoordinates,
  t,
  showLocateButton = true,
}) => {
  return (
    <CoordinatesContainer>
      <CoordinatesInputs
        showAll={true}
        showTitle={false}
        addPointToNewFeature={handleCoordinates}
        geometry={activeCoordinates}
        color="#8c9ba5"
        t={t}
        onKeyPress={handleKeyPress}
      />
      {showLocateButton && (
        <StyledLocateButton
          disabled={
            !activeCoordinates?.longitude || !activeCoordinates?.latitude
          }
          onClick={locateCoordinates}
        >
          <span>{t("screen.widget.Search.findThisLocation")}</span>
          <FaSearchLocation fill="#000" />
        </StyledLocateButton>
      )}
    </CoordinatesContainer>
  );
};

export default CoordinatesPanel;
