import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { view } from "../../../../utils/API";
import { ConfigContext } from "../../../../utils/ConfigContext";
import Input from "../../../Report/new/Input/Input";
import { checkForCoordinates } from "../../../SearchWidget/helpers";
import SearchWidget from "../../../SearchWidget/SearchWidget";
import { StyledDescription } from "../Editor-styled";
import { EditType } from "../EditorSwiper/EditorSwiper";
import CoordinateNotation from "./CorrdinateNotation";

const formatTrailingZeros = (seconds) => {
  const formatted = seconds.toString();
  return formatted.includes(".") ? formatted.replace(/\.?0+$/, "") : formatted;
};

const convertToDMSString = ({ latitude, longitude }) => {
  return `${latitude.degrees + "°"}${latitude.minutes + "'"}${
    latitude.seconds + '"'
  } ${latitude.direction} ${longitude.degrees + "°"}${longitude.minutes + "'"}${
    longitude.seconds + '"'
  } ${longitude.direction}`;
};

/**
 * Converts a decimal degree value to degrees, minutes, and seconds.
 * @param {number} decimalDegree - The decimal degree value to convert.
 * @returns {object} An object containing degrees, minutes, and seconds.
 */
const convertToDegreesMinutesAndSeconds = (decimalDegree) => {
  const absolute = Math.abs(decimalDegree);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = (minutesNotTruncated - minutes) * 60;
  const fixedSeconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

  return {
    degrees: degrees,
    minutes: minutes > 0 ? minutes : "0",
    seconds: seconds > 0 ? formatTrailingZeros(fixedSeconds) : "0",
  };
};

/**
 * Converts a decimal degree value to degrees and decimal minutes.
 * @param {number} decimalDegree - The decimal degree value to convert.
 * @returns {object} An object containing degrees and decimal minutes.
 */
const convertToDegreesAndDecimalMinutes = (decimalDegree) => {
  const absolute = Math.abs(decimalDegree);
  const degrees = Math.floor(absolute);
  const decimalMinutes = (absolute - degrees) * 60;
  const fixed = decimalMinutes.toFixed(3);

  return {
    degrees: degrees,
    minutes: decimalMinutes > 0 ? formatTrailingZeros(fixed) : "0", // Adjust the precision as needed
  };
};

/**
 * Converts latitude and longitude to degrees, minutes, and seconds or degrees and decimal minutes.
 * @param {number} latitude - The latitude value to convert.
 * @param {number} longitude - The longitude value to convert.
 * @param {string} format - The format to convert to ("dms" or "ddm").
 * @returns {object} An object containing DMS or DDM values for latitude and longitude.
 */
const convertLatLonToDMSorDDM = (latitude, longitude, format) => {
  const latConversion =
    format === "dms"
      ? convertToDegreesMinutesAndSeconds(latitude)
      : convertToDegreesAndDecimalMinutes(latitude);
  const lonConversion =
    format === "dms"
      ? convertToDegreesMinutesAndSeconds(longitude)
      : convertToDegreesAndDecimalMinutes(longitude);

  return {
    latitude: {
      degrees: latConversion.degrees, //latitude >= 0 ? latConversion.degrees : -latConversion.degrees,
      minutes: latConversion.minutes,
      seconds: format === "dms" ? latConversion.seconds : "0",
      direction: latitude >= 0 ? "N" : "S",
    },
    longitude: {
      degrees: lonConversion.degrees, //longitude >= 0 ? lonConversion.degrees : -lonConversion.degrees,
      minutes: lonConversion.minutes,
      seconds: format === "dms" ? lonConversion.seconds : "0",
      direction: longitude >= 0 ? "E" : "W",
    },
  };
};

export function toDegreesMinutesAndSeconds(dd, type) {
  if (dd == null || dd === "") return "";

  const d = Math.floor(dd);
  const m = Math.floor((dd - d) * 60);

  if (type === "ddm") {
    return d + " " + m;
  }

  if (type === "dms") {
    const s = (dd - d - m / 60) * 3600;

    return d + " " + m + " " + Math.round(s * 10) / 10;
  }
  return "";
}

export function toDD(coordinateString) {
  const array = coordinateString.split(" ");

  let res = 0;
  if (array.length > 1 && array[0] === "") {
    return "";
  }

  if (array.length === 2) {
    const d = array[0];
    const m = array[1];

    res = Number(d) + Number(m) / 60;
  }

  if (array.length === 3) {
    const d = array[0];
    const m = array[1];
    const s = array[2];

    res = Number(d) + Number(m) / 60 + Number(s) / 3600;
  }

  return res;
}

/**
 * Converts DDM (Degrees, Decimal Minutes) to DD (Decimal Degrees).
 * @param {object} ddm - The DDM value to convert.
 * @returns {number} The converted DD value.
 */
const convertDDMtoDD = (ddm) => {
  const { degrees, minutes } = ddm;
  const dd = Math.abs(degrees) + minutes / 60;
  return degrees >= 0 ? dd : -dd;
};

/**
 * Converts DMS (Degrees, Minutes, Seconds) to DD (Decimal Degrees).
 * @param {object} dms - The DMS value to convert.
 * @returns {number} The converted DD value.
 */
const convertDMStoDD = (dms) => {
  const { degrees, minutes, seconds } = dms;
  const dd = Math.abs(degrees) + minutes / 60 + seconds / 3600;
  return degrees >= 0 ? dd : -dd;
};

const CoordinatesInputs = ({
  showTitle,
  addPointToNewFeature: addPoint,
  editType,
  showAll = false,
  geometry,
  color,
  t,
  onKeyPress,
  showConversion,
  disabled = false,
  onReset,
}) => {
  const { config } = useContext(ConfigContext);
  const [showOther, setShowOther] = useState(showAll);
  const [coordinates, setCoordinates] = useState({
    lat: "",
    lon: "",
  });

  const [ddm, setDdm] = useState({
    lat: {
      degree: "",
      minutes: "",
    },
    lon: {
      degree: "",
      minutes: "",
    },
  });

  const [dms, setDms] = useState({
    lat: {
      degree: "",
      minutes: "",
      seconds: "",
    },
    lon: {
      degree: "",
      minutes: "",
      seconds: "",
    },
  });
  const [updateType, setUpdateType] = useState("");
  const [directions, setDirections] = useState(["N", "E"]);
  const [allCoordinatesInput, setAllCoordinatesInput] = useState("");

  useEffect(() => {
    const isEmpty = () => {
      return (
        Object.values(coordinates).map((item) => item.length === 0).length > 0
      );
    };

    if (geometry) {
      updateCoordinates(geometry, updateType);
      setUpdateType("");
    } else {
      resetCoordinates();
    }
  }, [geometry, editType]);

  const addTimer = useRef(0);
  const addPointToNewFeature = useCallback((point, type) => {
    clearTimeout(addTimer.current);
    addTimer.current = setTimeout(() => {
      addPoint(point, type);
    }, 250);
  }, []);

  const resetCoordinates = () => {
    setCoordinates({
      lat: "",
      lon: "",
    });
    setDdm({
      lat: {
        degree: "",
        minutes: "",
      },
      lon: {
        degree: "",
        minutes: "",
      },
    });
    setDms({
      lat: {
        degree: "",
        minutes: "",
        seconds: "",
      },
      lon: {
        degree: "",
        minutes: "",
        seconds: "",
      },
    });
    setUpdateType("");
    setAllCoordinatesInput("");

    if (onReset) {
      onReset();
    }
  };

  const updateCoordinates = (coordinates, type) => {
    if (type !== "allCoordinates") {
      const vertical = coordinates.latitude >= 0 ? "N" : "S";
      const horizontal = coordinates.longitude >= 0 ? "E" : "W";

      const { latitude, longitude } = convertLatLonToDMSorDDM(
        coordinates.latitude,
        coordinates.longitude,
        "dms"
      );

      const dms = {
        lat: {
          degree: latitude.degree,
          minutes: latitude.minutes,
          seconds: latitude.seconds,
        },
        lon: {
          degree: longitude.degree,
          minutes: longitude.minutes,
          seconds: longitude.seconds,
        },
      };

      setAllCoordinatesInput(
        `${latitude.degrees + "°"}${latitude.minutes + "'"}${
          latitude.seconds + '"'
        } ${vertical} ${longitude.degrees + "°"}${longitude.minutes + "'"}${
          longitude.seconds + '"'
        } ${horizontal}`
      );
    }
    if (type !== "coordinates") {
      setCoordinates({
        lat: coordinates.latitude,
        lon: coordinates.longitude,
      });
    }

    if (type !== "directions") {
      const vertical = coordinates.latitude >= 0 ? "N" : "S";
      const horizontal = coordinates.longitude >= 0 ? "E" : "W";
      setDirections([vertical, horizontal]);
    }

    if (type !== "dms") {
      const { latitude, longitude } = convertLatLonToDMSorDDM(
        coordinates.latitude,
        coordinates.longitude,
        "dms"
      );

      setDms({
        lat: {
          degree: latitude.degrees,
          minutes: latitude.minutes,
          seconds: latitude.seconds,
        },
        lon: {
          degree: longitude.degrees,
          minutes: longitude.minutes,
          seconds: longitude.seconds,
        },
      });
    }

    if (type !== "ddm") {
      const { latitude, longitude } = convertLatLonToDMSorDDM(
        coordinates.latitude,
        coordinates.longitude,
        "ddm"
      );

      setDdm({
        lat: {
          degree: latitude.degrees,
          minutes: latitude.minutes,
        },
        lon: {
          degree: longitude.degrees,
          minutes: longitude.minutes,
        },
      });
    }
  };

  const timer = useRef(-1);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const cn = { ...coordinates };

    cn[name] = value;
    clearTimeout(timer.current);

    // Allow only numbers, a single decimal point, and an optional minus sign
    if (!/^-?\d*\.?\d*$/.test(value)) {
      return;
    }

    const point = {
      type: "point",
    };

    if (cn.lat) {
      point.latitude = cn.lat;
    }

    if (cn.lon) {
      point.longitude = cn.lon;
    }

    setUpdateType("coordinates");

    // / Check if the last character is a decimal point
    if (
      value.slice(-1) !== "." &&
      cn.lon &&
      cn.lat &&
      cn.lon !== "-" &&
      cn.lat !== "-"
    ) {
      addPointToNewFeature(point, "coordinates");
    }

    setCoordinates(cn);
  };

  const convertToDDAndAddPoint = (coodinates, type) => {
    const latHasEmptyValue =
      Object.values(coodinates.lat).filter((value) => !value).length > 0;
    const lonHasEmptyValue =
      Object.values(coodinates.lon).filter((value) => !value).length > 0;
    if (!latHasEmptyValue && !lonHasEmptyValue) {
    }

    const latValues = Object.values(coodinates.lat).join(" ");
    const lonValues = Object.values(coodinates.lon).join(" ");

    const geometry = {
      latitude: toDD(latValues),
      longitude: toDD(lonValues),
    };

    const point = {
      type: "point",
      longitude: geometry.longitude,
      latitude: geometry.latitude,
    };

    addPointToNewFeature(point);
  };

  const covertTimer = useRef(-1);
  const handleOtherInputChange = (e, type) => {
    const { name, value } = e.target;
    const [key, innerKey] = name.split(".");
    let local;

    if (type === "dms") {
      if (name.includes("degree") && value === "") {
        local = {
          ...dms,
          [key]: {
            degree: "",
            minutes: "",
            seconds: "",
          },
        };
      } else {
        local = {
          ...dms,
          [key]: {
            ...dms[key],
            [innerKey]: value,
          },
        };
      }
    } else if (type === "ddm") {
      if (name.includes("degree") && value === "") {
        local = {
          ...ddm,
          [key]: {
            degree: "",
            minutes: "",
          },
        };
      } else {
        local = {
          ...ddm,
          [key]: {
            ...ddm[key],
            [innerKey]: value,
          },
        };
      }
    }
    clearTimeout(covertTimer.current);
    setUpdateType(type);
    convertToDDAndAddPoint(local, type);

    if (type === "dms") {
      setDms(local);
    } else if (type === "ddm") {
      setDdm(local);
    }
  };

  const handleDirectionsChange = useCallback(
    (val, dir) => {
      const newDirections = [...directions];
      newDirections[dir === "vertical" ? 0 : 1] = val;
      if (coordinates.lat && coordinates.lon) {
        let lat = Math.abs(coordinates.lat);
        let lon = Math.abs(coordinates.lon);
        lat = newDirections[0] === "N" ? lat : -lat;
        lon = newDirections[1] === "E" ? lon : -lon;

        const point = {
          type: "point",
          latitude: lat,
          longitude: lon,
        };
        addPointToNewFeature(point, "directions");
      }

      setDirections(newDirections);
    },
    [directions, coordinates]
  );

  const handleCoordinatesChange = useCallback(
    (inputText) => {
      const result = checkForCoordinates(inputText);

      if (result && !result.error) {
        const { latitude, longitude, format } = result;
        const point = {
          type: "point",
          latitude,
          longitude,
        };

        setUpdateType("allCoordinates");
        addPointToNewFeature(point);

        setCoordinates({
          lat: point.latitude,
          lon: point.longitude,
        });
      } else {
        resetCoordinates();
      }
      setAllCoordinatesInput(inputText);
    },
    [addPointToNewFeature, addPoint]
  );

  const onSelectSearchResult = async (coordinates) => {
    const { latitude, longitude } = coordinates;

    const dmsCoordinates = convertLatLonToDMSorDDM(latitude, longitude, "dms");
    const dmsString = convertToDMSString(dmsCoordinates);
    handleCoordinatesChange(dmsString);
  };

  return (
    <div>
      {(editType === EditType.create || !showTitle) && (
        <>
          {showConversion && (
            <div>
              <SearchWidget
                config={config}
                t={t}
                view={view}
                onBackClick={undefined}
                isMobileApp={true}
                rotate={false}
                isExpanded={true}
                isSearchWidget={false}
                searchValue={allCoordinatesInput}
                color={color}
                onInputChange={handleCoordinatesChange}
                onResultSelect={onSelectSearchResult}
                disabled={disabled}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {showTitle && (
              <StyledDescription>
                Click on the map the position, or write down the coordinates
                (DD).
              </StyledDescription>
            )}
            <div
              style={{
                display: "grid",
                gridTemplateRows: "2fr",
                transition: "all 0.5s",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "8px",
                  border: "1px solid #80808033",
                  borderRadius: "8px",
                  margin: "10px 0",
                  gap: 4,
                }}
              >
                <span style={{ fontSize: 10, textAlign: "left" }}>DD</span>
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    transition: "all 0.5s",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <Input
                      noMargin
                      onChange={handleInputChange}
                      name="lat"
                      style={{ width: "100%" }}
                      placeholder={t("layer.fieldAlias.latitude.title")}
                      label={t("layer.fieldAlias.latitude.title")}
                      value={coordinates.lat}
                      borderColor={color}
                      onKeyPress={onKeyPress}
                      disabled={disabled}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Input
                      noMargin
                      onChange={handleInputChange}
                      name="lon"
                      style={{ width: "100%" }}
                      placeholder={t("layer.fieldAlias.longitude.title")}
                      label={t("layer.fieldAlias.longitude.title")}
                      value={coordinates.lon}
                      borderColor={color}
                      onKeyPress={onKeyPress}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {/* <StyledButton
              hover={!showAll}
              expand={showOther}
              small
              onClick={() => {
                if (!showAll) {
                  setShowOther((prev) => !prev);
                }
              }}
            >
              <span>{t("screen.widget.CoordinateConversion.other")}</span>
              {!showAll && (
                <svg
                  fill="#525252"
                  fillRule="evenodd"
                  height="7"
                  role="img"
                  viewBox="0 0 12 7"
                  width="10"
                  aria-label=""
                >
                  <title></title>
                  <path d="M6.002 5.55L11.27 0l.726.685L6.003 7 0 .685.726 0z"></path>
                </svg>
              )}
            </StyledButton> */}
            <div
              style={{
                display: "grid",
                gridTemplateRows: showOther ? "2fr" : "0fr",
                transition: "all 0.3s",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: showOther ? 10 : 0,
                  transition: "all 0.5s",
                }}
              >
                <CoordinateNotation
                  type="ddm"
                  coordinates={ddm}
                  handleInputChange={(e) => handleOtherInputChange(e, "ddm")}
                  onDirectionSelect={handleDirectionsChange}
                  directions={directions}
                  color={color}
                  t={t}
                  onKeyPress={onKeyPress}
                  disabled={disabled}
                />
                <CoordinateNotation
                  type="dms"
                  handleInputChange={(e) => handleOtherInputChange(e, "dms")}
                  coordinates={dms}
                  onDirectionSelect={handleDirectionsChange}
                  directions={directions}
                  color={color}
                  t={t}
                  onKeyPress={onKeyPress}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CoordinatesInputs;
