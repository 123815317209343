import React, { useMemo } from "react";
import { EyeOffIcon, EyeOnIcon } from "../../Icons";
import { showpublicFieldName } from "../../../utils/API";
import { Tooltip } from "../../UI";
import { useTranslation } from "react-i18next";

const assessFeatureVisibility = (feature) => {
  return feature.attributes[showpublicFieldName] === 1;
};

const ShowPublicIcon = ({
  feature,
  tooltipEnabled = true,
  iconProps = {},
  ...props
}) => {
  const { t } = useTranslation("common");
  const isPublic = useMemo(() => assessFeatureVisibility(feature), [feature]);

  const { width = 16, height = 16 } = iconProps;

  const hasShowPublicField = useMemo(
    () => feature && showpublicFieldName in feature.attributes,
    [feature]
  );

  if (!hasShowPublicField) return null;

  return (
    <Tooltip
      disabled={!tooltipEnabled}
      showOnHover
      content={t("layer.fieldAlias.showpublic.title")}
      wrapperStyles={{ width, height, minWidht: width, minHeight: height }}
      {...props}
    >
      {isPublic ? <EyeOnIcon {...iconProps} /> : <EyeOffIcon {...iconProps} />}
    </Tooltip>
  );
};

export default ShowPublicIcon;
