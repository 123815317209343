import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LCATableWrapper } from "../MobilePopup-styled";
import { CustomLoader, StyledDateTime } from "../../../App/App-styled";
import { formatIntlDateTime, getOpsColor } from "../../../../utils/helper";
import { useSelector } from "react-redux";

const Details = ({ config, feature, lcaPageId, setShow, headerRef }) => {
  const [lcaDetails, setLcaDetails] = useState();
  const [lcaRows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const titleRef = useRef();
  const lcaRef = useRef();
  const { activeModule } = useSelector((state) => state);

  // const firstRender = useRef(true);
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     entries => {
  //       if (!ref.current) return;
  //
  //       if (!firstRender.current) {
  //         if (entries[0].intersectionRatio === 0) {
  //           handleTitleChange({
  //             link: lcaDetails.link,
  //             name: lcaDetails.name
  //           })
  //         } else {
  //           handleTitleChange(null)
  //         }
  //       } else {
  //         firstRender.current = false;
  //
  //       }
  //     },
  //     { threshold: [0,1], rootMargin:'-100px' }
  //   );
  //
  //   if (ref.current) {
  //     observer.observe(ref.current);
  //   }
  //
  //   return () => {
  //     if (ref.current) {
  //       observer.unobserve(ref.current);
  //     }
  //   };
  // }, [ref, lcaDetails]);

  useLayoutEffect(() => {
    setLoading(true);
    const api =
      process.env.NODE_ENV === "development"
        ? ""
        : process.env.REACT_APP_LC_API;
    fetch(`${api}/lcas-content/${lcaPageId}`, {
      method: "GET",
      headers: {
        "X-API-Key": process.env.REACT_APP_LC_API_KEY,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        let lcaData;
        let rows;
        if (Array.isArray(data?.rows) && data.rows.length > 0) {
          lcaData = data.rows[0];
          rows = data.rows;
        }
        setLoading(false);

        if (!lcaData || !rows) {
          setShow(false);
        }

        setRows(rows);
        setTimeout(() => {
          if (headerRef.current && titleRef.current) {
            titleRef.current.style.top =
              headerRef.current.offsetHeight - 1 + "px";
          }
        }, 250);

        setLcaDetails({
          name: lcaData.name,
          content: JSON.parse(JSON.stringify(lcaData.content[0].text)),
          link: lcaData.link,
          last_update: lcaData.last_update,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setLcaDetails();
        setShow(false);
      });
  }, [feature, lcaPageId]);

  useEffect(() => {
    if (!lcaRef.current) return;
    const allParagraph = lcaRef.current.querySelectorAll("p") || [];
    const allLinks = lcaRef.current.querySelectorAll("a") || [];
    const allImages = lcaRef.current.querySelectorAll("img") || [];
    const allTables = lcaRef.current.querySelectorAll("table") || [];
    allTables.forEach((el) => {
      el.style = "";
      el.style.width = "100%";
      el.style.display = "block";
      el.style.overflow = "auto";
    });

    allLinks.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    // allParagraph.forEach(p=>{
    //   console.log(p.innerText.trim().length > 0, p.innerText);
    //   if (p.innerText.trim().length === 0){
    //     p.style.display = 'none'
    //     p.style.marginTop = '2px'
    //   }
    // })
  }, [lcaDetails]);

  const formatLcaDate = useCallback(() => {
    if (lcaDetails?.last_update) {
      const [y, m, d] = lcaDetails?.last_update.split("-");
      return formatIntlDateTime({
        locale: "en",
        date: new Date(y, m - 1, d),
        options: {
          month: "long",
        },
      });
    }
  }, [lcaDetails]);

  // const elInit = useCallback((el)=>{
  //   if (!el) return;
  //   setTimeout(()=>{
  //     const popupTitle = document.getElementById('popup-sticky-title');
  //     const fields = document.getElementById('popup-situational-fields');
  //     const body = document.querySelector('.sc-jfmDQi.jEGMvm');
  //
  //     if (popupTitle){
  //       console.log(popupTitle.clientHeight, fields.clientHeight, body.clientHeight)
  //       el.style.top = (popupTitle.clientHeight - 1) + 'px';
  //     }
  //   },1000)
  // },[])

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [config, activeModule]
  );

  if (loading) {
    return <CustomLoader style={{ marginTop: 10 }} scale="s" />;
  }

  return (
    lcaDetails && (
      <div style={{ animation: "fadeIn 0.3s linear" }}>
        <div
          ref={titleRef}
          style={{
            position: "sticky",
            top: 45,
            background: "#fff",
            padding: "10px 0px",
            zIndex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}
          >
            <div style={{ minWidth: 28 }}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 10.9639C6.25 9.43638 7.43711 8.479 9.20567 8.479C10.9742 8.479 12.125 9.50092 12.125 10.8455V12.6635H10.3443V11.0392C10.3443 10.4906 10.0294 9.90968 9.18144 9.90968C8.33351 9.90968 8.01856 10.3937 8.01856 11.1037V17.3643C8.01856 18.0742 8.39407 18.5583 9.18144 18.5583C9.96881 18.5583 10.3443 17.9774 10.3443 17.4288V15.6432H12.125V17.6225C12.125 18.9778 11.168 19.9998 9.20567 19.9998C7.2433 19.9998 6.25 19.0424 6.25 17.5149V10.9639Z"
                  fill="#C03A2A"
                />
                <path
                  d="M0 8.60718H1.79067V18.5547H4.875V19.9999H0V8.60718Z"
                  fill="#C03A2A"
                />
                <path
                  d="M15.3333 8.73535H17.6431L20 20.0001H18.2205L17.6902 17.0391H15.3098L14.7795 20.0001H13L15.3569 8.73535H15.3333ZM17.4074 15.6551L16.4882 10.5377L15.5926 15.6551H17.4192H17.4074Z"
                  fill="#C03A2A"
                />
                <path
                  d="M0 4.89056H1.00984C1.42518 6.6755 3.04581 8.0042 4.98405 8.0042C6.92229 8.0042 8.54293 6.6755 8.95826 4.89056H10.9779C11.3933 6.6755 13.0139 8.0042 14.9522 8.0042C16.8904 8.0042 18.511 6.6755 18.9264 4.89056H20C20 3.99409 20 3.99409 20 3.99409C20 3.11363 20 3.11363 20 3.11363H18.9264C18.511 1.3287 16.8904 0 14.9522 0C13.0139 0 11.3933 1.3287 10.9779 3.11363H8.95826C8.54293 1.3287 6.92229 0 4.98405 0C3.04581 0 1.42518 1.3287 1.00984 3.11363H0V4.89056ZM12.8755 3.10563C13.2257 2.32122 14.0238 1.76893 14.9522 1.76893C15.8806 1.76893 16.6705 2.32122 17.0288 3.10563C17.151 3.37777 17.2243 3.68193 17.2243 3.99409C17.2243 4.30626 17.151 4.61042 17.0288 4.88256C16.6787 5.66697 15.8806 6.21926 14.9522 6.21926C14.0238 6.21926 13.2338 5.66697 12.8755 4.88256C12.7533 4.61042 12.68 4.30626 12.68 3.99409C12.68 3.68193 12.7533 3.37777 12.8755 3.10563ZM2.90736 3.10563C3.25755 2.32122 4.05565 1.76893 4.98405 1.76893C5.91245 1.76893 6.70241 2.32122 7.06074 3.10563C7.1829 3.37777 7.25619 3.68193 7.25619 3.99409C7.25619 4.30626 7.1829 4.61042 7.06074 4.88256C6.71055 5.66697 5.91245 6.21926 4.98405 6.21926C4.05565 6.21926 3.26569 5.66697 2.90736 4.88256C2.78521 4.61042 2.71191 4.30626 2.71191 3.99409C2.71191 3.68193 2.78521 3.37777 2.90736 3.10563Z"
                  fill="#C03A2A"
                />
              </svg>
            </div>
            <p
              ref={ref}
              style={{
                fontWeight: 500,
                fontSize: 14,
                margin: "0px",
                color: "#575757",
                marginBottom: 0,
              }}
            >
              Logistics Capacity Assessment of
              <a
                href={lcaDetails.link}
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    margin: "10px 0px",
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  {" "}
                  {lcaDetails.name}
                </span>
              </a>
            </p>
          </div>
          <StyledDateTime style={{ fontSize: 12 }}>
            Last update: {formatLcaDate()}
          </StyledDateTime>
        </div>
        {/*<div ref={lcaRef}>*/}
        {/*  {*/}
        {/*    lcaRows.map(row=>(*/}
        {/*      row.content.map(content=>(*/}
        {/*        <LCATableWrapper opsColor={config.opsColor} className="popup-table" dangerouslySetInnerHTML={{__html:content.text}}>*/}
        {/*        </LCATableWrapper>*/}
        {/*      ))*/}
        {/*    ))*/}
        {/*  }*/}
        {/*</div>*/}
        <LCATableWrapper
          ref={lcaRef}
          opsColor={opsColor}
          className="popup-table"
          dangerouslySetInnerHTML={{ __html: lcaDetails.content }}
        ></LCATableWrapper>
      </div>
    )
  );
};

export default Details;
